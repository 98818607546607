import { ItemRarity, Trinket } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './royal_ring.png'

export const royal_ring: ItemUI<Trinket> = {
    image,
    label: 'Royal ring',
    value: 160,
    item: {
        key: 'royal_ring',
        type: 'trinket',
        rarity: ItemRarity.Epic,
        stats: {
            intelligence: 50,
            agility: 50,
            strength: 50,
            health: 50
        }
    }
}
