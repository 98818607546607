import { sample_barbarian } from 'data/user/heroes/sample_barbarian'
import { sample_user } from 'data/sample/sample_user'
import { CampaignState } from 'models/campaign/CampaignModel'

export const sample_campaign: CampaignState = {
  user: {
    ...sample_user,
    money: 500,
  },
  difficulty: 'regular',
  chapter: 'goblins_chapter',
  missions: {
    goblin_mission_1: {
      attempts: 2,
      history: {
        lineup: {
          front: [sample_barbarian.id],
          support: []
        },
        stars: 3,
        rounds: 12,
        loot: []
      }
    }
  }
}
