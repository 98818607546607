import { AttackRange, DamageType, ItemRarity, Weapon } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './witch_wand.png'

export const witch_wand: ItemUI<Weapon> = {
    image,
    label: 'Witch wand',
    value: 100,
    item: {
        key: 'witch_wand',
        type: 'weapon',
        rarity: ItemRarity.Epic,
        stats: {
            intelligence: 20,
            agility: 20,
            energy: 1
        },
        two_handed: true,
        category: 'wand',
        damageType: DamageType.Magic,
        range: AttackRange.Mixed,
        energy: 3,
        damage: 10
    }
}
