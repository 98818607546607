import { Plugins } from '@capacitor/core'
import { isPlatform } from '@ionic/react'
import { useApp } from 'AppContext'
import React, { useEffect, useState } from 'react'
import { Route, Switch } from 'react-router'
import { Ranking } from 'views/home/ranking/ranking'
import { Button } from 'views/layout/button'
import { Bg } from 'views/layout/util/bg'
import { styler } from 'views/layout/util/style'
import { Link } from 'views/link'
import { Icon } from 'views/ui/icon'
import { ImageType } from 'views/ui/ui_types'
import aboutSrc from './about_icon.png'
import campaignSrc from './campaign_icon.png'
import continueSrc from './continue_icon.png'
import endlessSrc from './endless_icon.png'
import './home.scss'
import beastiarySrc from './library_icon.png'
import bgImage from './splash_goblins.png'
import rankingSrc from './trophy_icon.png'

const style = styler('home', ['btn', 'links', 'message'])

type VersionInfo = {
    key: string
    message: string
    label: string
    link: string
}

const versionUrl = () => {
    if (isPlatform('android')) return 'https://www.battlecoach.app/api/update-android'
    if (isPlatform('ios')) return 'https://www.battlecoach.app/api/update-ios'
    return 'https://www.battlecoach.app/api/update-android'
}

const preloadImage = (src: string) => {
    return new Promise(r => {
        const image = new Image()
        image.onload = r
        image.onerror = r
        image.src = src
    })
}

export const Home: React.FC = () => {
    const { campaign, endless } = useApp()
    const [version, setVersion] = useState<VersionInfo | null>(null)
    useEffect(() => {
        let url = versionUrl()
        if (url) {
            fetch(url).then(res => res.json()).then((version: VersionInfo) => {
                if (version.key !== 'initial') {
                    setVersion(version)
                }
            })
        }
        preloadImage(bgImage).then(() => {
            setTimeout(() => {
                Plugins.SplashScreen.hide()
            }, 500)
        })
    }, [])

    return <div className={style()}>
        <Bg image={bgImage} />
        <a href="https://discord.com/invite/e34AkRBsmW" className={style('discord')}>
            <Icon icon="discord" />
        </a>
        <div className={style('title')}>
            <div className={style('title-main')}>
                Battle Coach
            </div>
            <div className={style('title-sub')}>Recruit • Adapt • Prevail</div>
        </div>
        <div className={style('menu')}>
            {campaign && <HomeBtn to={`/campaign/${campaign.chapter.key}`} label="Continue" img={continueSrc} />}
            <HomeBtn to="/chapters" label="Campaign" img={campaignSrc} />
            <HomeBtn to={endless ? "/endless" : "/endless/academy"} label="Endless Mode" img={endlessSrc} />
            <HomeBtn to="/home/ranking" label="Ranking" img={rankingSrc} />
            <HomeBtn to="/about" label="About" img={aboutSrc} />
            <HomeBtn to="/library/bestiary" label="Library" img={beastiarySrc} />
        </div>
        {/*<div className={style('feedback')}>
            Feedback? Bugs? <br /><a href="mailto:battlecoachapp@gmail.com">battlecoachapp@gmail.com</a>
        </div>*/}
        {version && <HomeMessage
            message={version.message}
            label={version.label}
            link={version.link}
        />}
        <Switch>
            <Route path="/home/ranking" component={Ranking} />
        </Switch>
    </div>
}

const HomeMessage: React.FC<{
    message: string,
    label: string,
    link: string
}> = ({ message, label, link }) => {

    return <div className={style.message()}>
        <div className={style.message('message')}>
            {message}
        </div>
        <div className={style.message('button')}>
            {link && <Button href={link}>
                {label}
            </Button>}
        </div>
    </div>
}

const HomeBtn: React.FC<{
    to: string,
    label: string,
    img: ImageType
}> = ({ to, label, img }) => {

    return <Link to={to} className={style.btn()} >
        <img src={img} className={style.btn('img')} alt={label} />
        <div className={style.btn('label')}>
            {label}
        </div>
    </Link>
}
