import { CharDamaged, DamageModifier, InjuryModifier } from 'game/core'
import { EventUI } from 'game/extended/uis/events/EventUI'
import { UIType } from 'game/extended/uis/UI'
import React from 'react'

export const char_damaged_ui: EventUI<'CharDamaged'> = {
    model: CharDamaged,
    render: (event: CharDamaged, UI) => {
        return <>
            <UI.Char character={event.state.character} />
            {' takes '}
            <UI.Injury injury={event.state.injury} />
            {` and has ${event.state.hp} hp left.`}
        </>
    },
    renderDetails: (event: CharDamaged, UI) => <UI.Log>
        <UI.LogMsg>
            Original damage: {event.injury.damage.original_amount}<br />
            {event.injury.damage.modifiers.map((mod, i) =>
                <UI.LogMod
                    char={event.injury.damage.origin}
                    before={mod.before.amount}
                    after={mod.after.amount}
                    key={i}
                >
                    {renderDamageModifier(mod, UI)}
                </UI.LogMod>
            )}
            {event.injury.modifiers.map((mod, i) =>
                <UI.LogMod
                    char={event.state.character}
                    before={mod.before}
                    after={mod.after}
                    key={i}
                >
                    {renderInjuryModifier(mod, UI)}
                </UI.LogMod>
            )}
            Damage dealt: {event.injury.amount}
        </UI.LogMsg>
    </UI.Log>
}

const renderDamageModifier = (modifier: DamageModifier, UI: UIType) => {
    switch (modifier.type) {
        case 'effect':
            return <UI.Effect effect={modifier.origin.effect.key} />
        case 'skill':
            return <UI.Skill skill={modifier.origin.skill} />
        case 'stat':
            return <UI.Stat stat={modifier.origin} amount={modifier.stat} />
    }
    return null
}

const renderInjuryModifier = (modifier: InjuryModifier, UI: UIType) => {
    switch (modifier.type) {
        case 'effect':
            return <UI.Effect effect={modifier.origin.effect.key} />
        case 'skill':
            return <UI.Skill skill={modifier.origin.skill} />
        case 'stat':
            return <UI.Stat stat={modifier.origin} amount={modifier.stat} />
    }
    return null
}