import { AttackRange, DamageType, ItemRarity, Weapon } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './dread_cleaver.png'

export const dread_cleaver: ItemUI<Weapon> = {
  image,
  label: 'Dread cleaver',
  value: 200,
  item: {
    key: 'dread_cleaver',
    type: 'weapon',
    rarity: ItemRarity.Legendary,
    stats: {
      strength: 50,
      agility: 20,
      health: 30
    },
    two_handed: false,
    category: 'axe',
    damageType: DamageType.Melee,
    range: AttackRange.Melee,
    energy: 5,
    damage: 18
  }
}
