import { missions } from 'data/campaign/missions'
import { CharDied } from 'game/core'
import { Battle, Percentage } from 'game/core/battle'
import { Line } from 'game/core/line'
import { CreatureKey, ItemKey } from 'game/extended/types'
import { addCreatureToLine, getCreatureUI } from 'game/extended/uis/types/creature_uis'
import { keysof } from 'game/util/keysof'
import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'
import { UnreachableCaseError } from 'game/util/never'
import { CampaignModel } from 'models/campaign/CampaignModel'
import { ChapterModel, ChapterPath, MissionKey } from 'models/campaign/ChapterModel'
import { ReplayModel } from 'models/replay/ReplayModel'
import { HeroModel } from 'models/user/hero/HeroModel'
import { LevelModel, LevelStatus } from 'models/user/LevelModel'
import { HeroID, UserModel } from 'models/user/UserModel'
import { keyToLabel } from 'util/keyToLabel'
import { shuffle } from 'util/shuffle'

export type LineupHistory = {
    front: Array<HeroID>
    support: Array<HeroID>
}

export interface MissionState {
    attempts: number,
    history?: MissionHistory
}

export type MissionHistory = {
    lineup: LineupHistory,
    stars: number,
    rounds: number,
    loot: ItemKey[]
}

export type MissionConfig = {
    label?: string
    front: Array<CreatureKey | MissionCreature>
    support?: Array<CreatureKey | MissionCreature>
}

export type MissionCreature = {
    type: CreatureKey,
    name: string,
    level?: number
}

export interface MissionPath extends ChapterPath {
    mission: MissionKey
}

export function creatureFromKey(key: CreatureKey): MissionCreature {
    return {
        name: keyToLabel(key),
        type: key,
        level: 1
    }
}

export class MissionModel implements LevelModel {

    constructor(readonly chapter: ChapterModel, readonly key: MissionKey, readonly state: MissionState) { }

    static byPath(campaign: CampaignModel, path: MissionPath): Nullable<MissionModel> {
        if (campaign.chapter.key !== path.chapter) return null
        return campaign.chapter.missions.find(mission => mission.key === path.mission)
    }

    @Memoize()
    get battle_id(): string {
        return this.key
    }

    @Memoize()
    get campaign(): CampaignModel {
        return this.chapter.campaign
    }

    @Memoize()
    get user(): UserModel {
        return this.chapter.user
    }

    @Memoize()
    get number(): number {
        return this.index + 1
    }

    @Memoize()
    get label(): string {
        return this.config.label || keyToLabel(this.key)
    }

    @Memoize()
    get reward(): number {
        switch (this.campaign.difficulty) {
            case 'regular': return 100
            case 'hard': return 65
            case 'insane': return 45
            default: throw new UnreachableCaseError(this.campaign.difficulty)
        }
    }

    @Memoize()
    get status(): LevelStatus {
        if (this.isLocked) return { key: 'locked' }
        if (!this.isFinished) return { key: 'open' }
        return {
            key: 'finished',
            attempts: this.attempts,
            loot: this.loot,
            reward: this.reward,
            rounds: this.state.history?.rounds || 0,
            stars: this.state.history?.stars || 0
        }
    }

    @Memoize()
    get path(): MissionPath {
        return { ...this.chapter.path, mission: this.key }
    }

    @Memoize()
    get index(): number {
        return this.chapter.missions.findIndex(mission => mission === this)
    }

    @Memoize()
    get config(): MissionConfig {
        return missions[this.key]
    }

    @Memoize()
    get stars(): undefined | number {
        return this.state.history?.stars
    }

    @Memoize()
    get speed(): Percentage {
        if (!this.state.history) return 0
        const res = Math.round((50 - this.state.history.rounds) / 50 * 100)
        return res < 0 ? 0 : res
    }

    @Memoize()
    get deaths(): number {
        if (this.isFinished) return this.state.attempts - 1
        return this.state.attempts
    }

    @Memoize()
    get isFinished(): boolean {
        return !!this.state.history
    }

    @Memoize()
    get isLocked(): boolean {
        if (!this.previousMission) return false
        return !this.previousMission.isFinished
    }

    @Memoize()
    get isOpen(): boolean {
        return !this.isFinished && !this.isLocked
    }

    @Memoize()
    get previousMission(): MissionModel | null {
        if (this.index === 0) return null
        return this.chapter.missions[this.index - 1]
    }

    @Memoize()
    get nextMission(): MissionModel | null {
        if (this.index === this.chapter.missions.length - 1) return null
        return this.chapter.missions[this.index + 1]
    }

    @Memoize()
    get attempts(): number {
        return this.state.attempts
    }

    @Memoize()
    get allies() {
        const lineup = this.state.history?.lineup

        if (!lineup) {
            return {
                front: this.user.lineup.frontSlots,
                support: this.user.lineup.supportSlots
            }
        }

        return {
            front: lineup.front
                .map(id => this.user.getHeroById(id) as HeroModel)
                .filter(hero => hero),
            support: lineup.support
                .map(id => this.user.getHeroById(id) as HeroModel)
                .filter(hero => hero)
        }
    }

    @Memoize()
    get enemies() {
        return {
            front: this.config.front.map(creature => {
                if (typeof creature === 'string') return creatureFromKey(creature)
                return creature
            }),
            support: (this.config.support || []).map(creature => {
                if (typeof creature === 'string') return creatureFromKey(creature)
                return creature
            })
        }
    }

    @Memoize()
    get loot(): Array<ItemKey> {
        return this.state.history?.loot || []
    }

    @Memoize()
    get battle(): Battle {
        let battle = new Battle(this.user.env, Battle.initialState())
        this.user.lineup.frontHeros.forEach(hero =>
            battle = hero.addToBattle(battle.left.front)
        )
        this.user.lineup.supportHeros.forEach(hero =>
            battle = hero.addToBattle(battle.left.support)
        )
        this.enemies.front.forEach(creature =>
            battle = this.addCreatureToBattle(creature, battle.right.front)
        )
        this.enemies.support.forEach(creature =>
            battle = this.addCreatureToBattle(creature, battle.right.support)
        )
        return battle
    }

    // ----- Inquiry ----- //
    addCreatureToBattle(creature: MissionCreature, line: Line) {
        return addCreatureToLine(line, creature.type, creature.level || 1, creature.name)
    }

    calculateLootFromBattle(battle: Battle): Array<ItemKey> {
        if (!battle.left.isVictorious) return []

        const charDiedEvents = battle.searchHistory(CharDied)

        let lootKey: ItemKey | null = null
        charDiedEvents.reverse().forEach(event => {
            if (lootKey) return
            if (event.character.side !== 'right') return
            const key = event.character.type as CreatureKey
            const def = getCreatureUI(key).definition(event.character.level)
            const equipments = def.equipment || {}
            const itemKeys = shuffle(keysof(equipments).map(key => equipments[key]) as Array<ItemKey>)
            itemKeys.forEach(itemKey => {
                if (lootKey) return
                if (this.user.items.find(item => item.key === itemKey)) return
                lootKey = itemKey
            })
        })

        return lootKey ? [lootKey] : []
    }

    // ----- Calculate new State ----- //
    update(newState: Partial<MissionState>): CampaignModel {
        return this.campaign.update({
            missions: {
                ...this.campaign.state.missions,
                [this.key]: { ...this.state, ...newState }
            }
        })
    }

    public fight(): CampaignModel {
        const replay = ReplayModel.createFromBattle(this.battle)
        let newCampaign = this.campaign.update({
            last_replay: {
                path: this.path,
                replay: replay
            }
        })
        const missionAfterUpdate = MissionModel.byPath(newCampaign, this.path) as MissionModel
        newCampaign = missionAfterUpdate.addResult(replay)
        newCampaign = newCampaign.updateUser(
            newCampaign.user.updateAchievements(this.battle_id, replay.result)
        )

        const missionAfterSavedRewards = MissionModel.byPath(newCampaign, this.path) as MissionModel
        newCampaign = missionAfterSavedRewards.gainLoot()

        return newCampaign.shuffleShop()
    }

    private gainLoot(): CampaignModel {
        if (!this.isFinished) return this.campaign
        return this.campaign.update({
            user: {
                ...this.campaign.user.state,
                inventory: [...this.user.state.inventory, ...this.loot],
                money: this.user.money + this.reward
            }
        })
    }

    private addResult(replay: ReplayModel): CampaignModel {
        if (!replay.result.left.isVictorious) {
            return this.update({ attempts: this.attempts + 1 })
        }
        return this.setFinished(replay)
    }

    private setFinished(replay: ReplayModel): CampaignModel {
        return this.update({
            attempts: this.attempts + 1,
            history: {
                lineup: {
                    front: this.user.lineup.frontHeros.map(hero => hero.id),
                    support: this.user.lineup.supportHeros.map(hero => hero.id)
                },
                rounds: replay.rounds.length,
                stars: Math.max(0, 3 - this.attempts),
                loot: this.calculateLootFromBattle(replay.data.finish)
            }
        })
    }
}
