import { BurpConfig } from 'game/extended/creatures/goblins/abilities/burp/burp'

export const burp_config: Array<BurpConfig> = [
    {
        available: 0,
        cooldown: 5,
        energy: 5,
        damage: 15,
        duration: 4
    }
]