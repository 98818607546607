import { Character, CharacterPath, Event, Weapon } from 'game/core'
import { Memoize } from 'game/util/memoize'
import { Nullable } from 'game/util/maybe'

export class CharWeaponSwitched extends Event<{
    previous_weapon: Weapon,
    new_weapon: Weapon,
    character: CharacterPath
}> {
    static KEY = 'CharWeaponSwitched' as const

    @Memoize()
    get previousWeapon(): Weapon {
        return this.state.previous_weapon
    }

    @Memoize()
    get newWeapon(): Weapon {
        return this.state.new_weapon
    }

    @Memoize()
    get character(): Nullable<Character> {
        return Character.byPath(this.battle, this.state.character)
    }
}
