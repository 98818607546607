import full from 'game/extended/creatures/goblins/goblin_big_momma/goblin_big_momma.svg'
import circle from 'game/extended/creatures/goblins/goblin_big_momma/goblin_big_momma_circle.svg'
import head from 'game/extended/creatures/goblins/goblin_big_momma/goblin_big_momma_head.svg'
import { CreatureUI } from 'game/extended/uis/types/creature_uis'

export const goblin_big_momma: CreatureUI = {
    label: 'Goblin mother',
    definition: lvl => ({
        stats: {
            health: 600,
            energy: 50,
            intelligence: 20,
            strength: 120,
            agility: 120,
            armor: 50,
            shielding: 60,
            resistance: 50
        },
        equipment: {
            primary: 'goblin_momma_club'
        },
        strategy: [
            {
                abilities: [
                    'Consume'
                ],
                conditions: [
                    {
                        key: 'WhenHurt',
                        percent: 20,
                        target: 'self'
                    }
                ]
            },
            'Burp',
            'BodyCheck',
            'PrimaryWeaponAttack',
            'MoveFront',
            'Punch'
        ]
    }),
    gender: 'female',
    full,
    circle: {
        src: circle,
        width: 200,
        height: 150
    },
    head
}
