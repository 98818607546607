import { Rush, RushConfig } from 'game/extended/heros/ranger/rush/rush'
import { AbilityUI } from 'game/extended/uis/abilities/AbilityUI'
import React from 'react'

import image from './rush.png'

export const rush_ui: AbilityUI<'Rush'> = {
  model: Rush,
  configs: [
    {
      available: 2,
      cooldown: 4,
      energy: 3,
      abilities: 2
    },
    {
      available: 1,
      cooldown: 3,
      energy: 4,
      abilities: 3
    }
  ],
  image,
  label: 'Rush',
  renderTargets: () => 'Self',
  renderDescription: () => <>
    Perform multiple, queued up abilities in a single turn.
  </>,
  renderImpression: (ability: Rush, UI) => <>
    <UI.Char character={ability.character} />{' '}goes into a combo frenzy, performing{' '}
    <UI.Ability ability={ability.abilitiesToCast} />{' '}in one sequence.
  </>,
  renderTableStatic: (config: RushConfig, UI) => <>
    <UI.TRow>
      <UI.TKey>Abilities</UI.TKey>
      <UI.TVal>{config.abilities}</UI.TVal>
    </UI.TRow>
  </>,
  renderTable: (ability: Rush, UI) => <>
    <UI.TRow>
      <UI.TKey>Abilities</UI.TKey>
      <UI.TVal>{ability.nrOfAbilities}</UI.TVal>
    </UI.TRow>
  </>,
}
