import image from 'game/extended/heros/mage/immolation/immolation.png'
import { ImmolationEffect } from 'game/extended/heros/mage/immolation/immolation_effect'
import { EffectUI } from 'game/extended/uis/effects/EffectUI'
import React from 'react'

export const immolation_effect_ui: EffectUI<'ImmolationEffect'> = {
    model: ImmolationEffect,
    label: 'Immolation shield',
    image,
    renderDescription: (effect: ImmolationEffect) => <>
        This character is shielded from damage and deals reverse damage to any incoming attacks, untill
        the shield is depleted.
    </>,
    renderTable: (effect: ImmolationEffect, UI) => <>
        <UI.TRow>
            <UI.TKey>Reverse damage:</UI.TKey>
            <UI.TVal><UI.Damage damage={effect.reverseDamage} /></UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Shield left:</UI.TKey>
            <UI.TVal><UI.Stat stat='health' amount={effect.shield} /></UI.TVal>
        </UI.TRow>
    </>
}
