import { barbarian } from 'game/extended/heros/barbarian/barbarian'
import { ClassUI } from 'game/extended/uis/types/class_uis'


export const tutorial_barbarian: ClassUI = {
  ...barbarian,
  definition: {
    ...barbarian.definition,
    stats: {
      energy: 25,
      health: 50,
      agility: 0,
      intelligence: 0,
      strength: 50,
      armor: 10,
      resistance: 10,
      shielding: 10
    }
  }
}