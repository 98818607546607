import 'views/ingame/user/team/achievements/achievementstab.scss'

import { HeroModel } from 'models/user/hero/HeroModel'
import React from 'react'
import { Achievement } from 'views/ingame/user/team/achievements/achievement'
import { Popuptab } from 'views/ingame/user/team/popuptab'
import { PopupInfo } from 'views/layout/popup'

export const AchievementsTab: React.FC<{
    hero: HeroModel
}> = ({ hero }) => {

    const achievements = hero.achievements.sort((a, b) => {
        if (a.level === b.level) return b.progressPercentage - a.progressPercentage
        return b.level - a.level
    })

    return <Popuptab>
        <PopupInfo>
            <p>Characters starts with a set of basis stats. They can be further
            improved by completing achievements during combat. All different
            achievements are listed here, along with the extra stats you will
            gain whenever you complete a level of a certain achievement.
            </p>

            <p>In addition to extra stats, each level of achievements unlocked
            also supplies your character with a talent token. Those can be used
            to upgrade your abilities in the Abilities-tab.
            </p>

            <p>Note that no progress is registered towards achievements when
            a character gets killed during a certain level. Even when the level
            was finished by his/her allies.</p>

            <p>Checkout the info button on the Equipment-tab for further details
            concerning the different stats.</p>
        </PopupInfo>
        <div className="achievementstab">
            {achievements.map(achievement =>
                <Achievement achievement={achievement} key={achievement.key} />
            )}
        </div>
    </Popuptab>
}
