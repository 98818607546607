import './campaignui.scss'

import React from 'react'
import { useLocation } from 'react-router'
import { useCampaign } from 'views/ingame/campaign/Campaign'
import { Gamemap } from 'views/ingame/campaign/map/gamemap'
import { useHinter } from 'views/ingame/campaign/tutorial'
import { Speeddial, SpeeddialAction } from 'views/layout/components/speeddial'
import { Header, HeaderGroup, HeaderHome, HeaderStat } from 'views/layout/header'
import { styler } from 'views/layout/util/style'
import { Icon } from 'views/ui/icon'
import { CharacterVisual } from 'views/ui/visuals/charactervisual'

const style = styler('campaignui')

export const CampaignUI: React.FC = ({ children }) => {
    const { campaign, user, badges, setBadges } = useCampaign()
    const hint = useHinter()
    const { pathname } = useLocation()

    return <div className={style()}>
        <div className={style('map')}>
            <Gamemap />
        </div>
        <Header>
            <HeaderGroup>
                <HeaderHome />
            </HeaderGroup>
            <HeaderGroup>
                <HeaderStat icon="currency" value={user.money} />
            </HeaderGroup>
        </Header>
        <div className={style('ui')}>
            <div className={style('ui-units')}>
                <Speeddial
                    icon="hero"
                    badges={user.suggestions}
                    hinted={hint.key === 'teampopup' && !pathname.includes('/team')}
                >
                    {user.heros.map(hero => (
                        <SpeeddialAction
                            to={`/team/${hero.id}/stats`}
                            key={hero.id}
                            badges={hero.suggestions}
                            hinted={hint.key === 'teampopup' && hint.char === hero.type && !pathname.includes('/team')}
                        >
                            <CharacterVisual type={hero.type} />
                        </SpeeddialAction>
                    ))}
                    {!user.academy.isEmpty && <SpeeddialAction to={`/academy`} mod="round">
                        <Icon icon="recruit" />
                    </SpeeddialAction>}
                </Speeddial>
            </div>
            <div className={style('ui-market')}>
                <Speeddial
                    mod="mirrored"
                    icon="options"
                    badges={badges.shop !== user.shop.offeringID}
                    hinted={hint.key === 'shop' && !pathname.includes('/shop')}
                >
                    <SpeeddialAction
                        to={`/shop`} mod="round"
                        badges={badges.shop !== user.shop.offeringID}
                        onClick={() => setBadges({ shop: user.shop.offeringID })}
                        hinted={hint.key === 'shop' && !pathname.includes('/shop')}
                    >
                        <Icon icon="shop" />
                    </SpeeddialAction>
                    {campaign.lastReplay && <SpeeddialAction to={`/replay`} mod="round">
                        <Icon icon="replay" />
                    </SpeeddialAction>}
                    <SpeeddialAction to={`/score`} mod="round">
                        <Icon icon="trophy" />
                    </SpeeddialAction>
                    <SpeeddialAction to={`/surrender`} mod="round">
                        <Icon icon="surrender" />
                    </SpeeddialAction>
                </Speeddial>
            </div>
        </div>
        <div className={style('children')}>
            {children}
        </div>
    </div>
}