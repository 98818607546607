import { ClassUI } from 'game/extended/uis/types/class_uis'

import full from './ranger.svg'
import circle from './ranger_circle.svg'
import head from './ranger_head.svg'

export const ranger: ClassUI = {
    label: 'Ranger',
    name: 'Yan',
    definition: {
        stats: {
            health: 90,
            energy: 23,
            strength: 25,
            intelligence: 20,
            agility: 50,
            armor: 10,
            shielding: 15,
            resistance: 12
        },
        masteries: {
            staff: 70,
            sword: 45
        },
        abilities: [
            "Volley",
            "ExplosiveArrow",
            "PushbackArrow",
            "PoisonedShot",
            "TrueSight",
            "Rush"
        ],
        skills: [
            "FrostMastery",
            "Ambusher",
            "Cover",
            "ArcedShots",
            "TrueShots",
            "Swift"
        ],
        super_ability: 'Rush',
        super_skill: 'Swift'
    },
    circle: {
        src: circle,
        width: 200,
        height: 150
    },
    full,
    head
}
