import { AmbusherConfig } from 'game/extended/heros/ranger/ambusher/ambusher'

export const ambusher_config: Array<AmbusherConfig> = [
    {
        increase: 4,
        interval: 1
    },
    {
        increase: 6,
        interval: 1
    },
    {
        increase: 8,
        interval: 1
    }
]