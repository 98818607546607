import { Stunned } from 'game/core'
import image from 'game/extended/core/effects/stunned/stunned.png'
import { EffectUI } from 'game/extended/uis/effects/EffectUI'
import React from 'react'

export const stunned_ui: EffectUI<'Stunned'> = {
    model: Stunned,
    label: 'Stunned',
    image,
    renderDescription: (effect: Stunned) => <span>
        This character is stunned and can not take any action until
        recovered
    </span>
}
