import { Battle, Percentage, Stats, StatsKey } from 'game/core'
import { EventKey, EventStates } from 'game/extended/types'
import { keysof } from 'game/util/keysof'
import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'
import { BattleHistory } from 'models/user/hero/achievements/BattleHistory'
import { GoalModel } from 'models/user/hero/achievements/GoalModel'
import { HeroModel, HeroState } from 'models/user/hero/HeroModel'
import { UserModel } from 'models/user/UserModel'

import * as achievements from 'models/user/hero/achievements/types'

export type AchievementKey = keyof typeof achievements

export interface AchievementState {
    key: AchievementKey,
    progress: number
}
export type AchievementRewardType = Partial<Record<StatsKey, number>>

export type AchievementConfig = {
    reward: AchievementRewardType
    goals: Array<number>
}

export abstract class AchievementModel<S extends AchievementState = AchievementState> {
    constructor(public hero: HeroModel, private state: S, private config: AchievementConfig) { }

    static byModel(user: UserModel, model: AchievementModel): Nullable<AchievementModel> {
        const hero = user.heros.find(h => h.id === model.hero.id)
        if (!hero) return null
        const achievement = hero.achievements.find(a => a.key === model.key)
        return achievement
    }

    @Memoize()
    get user(): UserModel {
        return this.hero.user
    }

    @Memoize()
    get rewards(): Array<{ key: StatsKey, value: number }> {
        return keysof(this.config.reward).map(key => ({
            key: key,
            value: this.config.reward[key] as number
        }))
    }

    @Memoize()
    get key(): AchievementKey {
        return this.state.key
    }

    @Memoize()
    get goals(): Array<GoalModel> {
        return this.config.goals.map(amount => new GoalModel(this, amount))
    }

    @Memoize()
    get activeGoal(): Nullable<GoalModel> {
        return this.goals.find(goal => goal.isActive)
    }

    @Memoize()
    get stats(): Partial<Stats> {
        const result: any = {}
        this.rewards.forEach(reward => {
            result[reward.key] = reward.value * this.level
        })
        return result
    }

    @Memoize()
    get level(): number {
        return this.config.goals.filter(goal => this.progress >= goal).length
    }

    @Memoize()
    get isMaxed(): boolean {
        return this.progress >= this.maxGoal
    }

    @Memoize()
    get maxLevel(): number {
        return this.config.goals.length + 1
    }

    @Memoize()
    get prevGoal(): number {
        return this.config.goals[this.level - 1] || 0
    }

    @Memoize()
    get currentGoal(): number {
        return this.config.goals[this.level] || this.maxGoal
    }

    @Memoize()
    get maxGoal(): number {
        return this.config.goals[this.config.goals.length - 1]
    }

    @Memoize()
    get progress(): number {
        return this.state.progress
    }

    @Memoize()
    get prio(): number {
        return 5
    }

    @Memoize()
    get progressPercentage(): Percentage {
        return (this.progress - this.prevGoal) / (this.currentGoal - this.prevGoal) * 100
    }

    abstract calculateProgress(history: BattleHistory): number

    applyStats(stats: Stats) {
        let result = stats
        keysof(this.stats).forEach(key => {
            result = { ...result, [key]: result[key] + (this.stats[key] || 0) }
        })
        return result
    }

    // ---- Helper methods ----- //
    searchHistory<K extends EventKey>(battle: Battle, key: K): Array<EventStates[K]> {
        return battle.history.filter(record => record.key === key) as Array<EventStates[K]>
    }

    // ----- Calculate new State ----- //
    update(newState: Partial<AchievementState>, heroState?: Partial<HeroState>): UserModel {
        return this.hero.update({
            ...(heroState || {}),
            achievements: this.hero.achievements.map(a =>
                a === this ? { ...this.state, ...newState } : a.state
            )
        })
    }

    increaseProgress(progress: number): UserModel {
        if (this.isMaxed) return this.user

        const newProgress = this.progress + progress
        if (newProgress < this.currentGoal) {
            return this.update({ progress: newProgress })
        }
        return this.update({
            progress: this.currentGoal
        }, {
            level: this.hero.level + 1,
            skill_points: this.hero.skillPoints + 1
        })
    }
}
