import { Battle, Character, ChildGameModel, GameModel, Line, LineState } from 'game/core'
import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'

export interface PlayerData {
    name: string
}

export type SidePosition = 'left' | 'right'

export interface SidePath {
    side: SidePosition
}

export interface SideState {
    position: SidePosition
    player?: PlayerData
    front: LineState
    support: LineState
}

export class Side extends ChildGameModel<Battle, SideState> {
    static byPath(battle: Battle, path: SidePath): Side {
        return battle[path.side]
    }

    public static initialState(position: SidePosition): SideState {
        return {
            position: position,
            player: null as any, //TODO: develop a better solution for this
            front: Line.initialState('front'),
            support: Line.initialState('support')
        }
    }

    @Memoize()
    get isVictorious(): boolean {
        if (!this.battle.isFinished) return this.position === 'right'
        return this.characters.length > 0
    }

    @Memoize()
    get path(): SidePath {
        return { side: this.position }
    }

    @Memoize()
    get directChildren(): Array<GameModel> {
        return [this.front, this.support]
    }

    @Memoize()
    get position(): SidePosition {
        return this.state.position
    }

    @Memoize()
    get characters(): Array<Character> {
        return [...this.front.characters, ...this.support.characters]
    }

    @Memoize()
    get front(): Line {
        return new Line(this, this.state.front)
    }

    @Memoize()
    get support(): Line {
        return new Line(this, this.state.support)
    }

    @Memoize()
    get minHpCharacter(): Nullable<Character> {
        if (!this.characters.length) return null
        const minRatio = Math.min(...this.characters.map(char => char.hpRatio))
        const minHpAlly = this.characters.find(char => char.hpRatio === minRatio)
        return minHpAlly
    }

    @Memoize()
    get enemySide(): Side {
        return this.state.position === 'left' ? this.battle.right : this.battle.left
    }

    // ----- Calculate new State ----- //
    updateLine(state: LineState): Battle {
        return this.battle.updateSide({
            ...this.state,
            [state.position]: state
        })
    }

    moveSupportToFront(): Battle {
        const paths = this.support.characters.map(ch => ch.path)
        return this.performAll(Character, paths, ch => ch.moveToFront(999))
    }
}
