import { Character, Event, Interrupt, Skill, SkillConfig, SkillTriggered, TurnEnded } from 'game/core'
import { Memoize } from 'game/util/memoize'

export interface SwiftConfig extends SkillConfig {
  recovery: number
}

export class Swift extends Skill<{}, SwiftConfig>{
    static KEY = 'Swift' as const

    @Memoize()
    get energyRecovery(): number {
        return this.currentConfig.recovery
    }

    // ----- Reactions ----- //
    reactToEvent(event: Event): Interrupt {
        if (!(event instanceof TurnEnded)) return false
        if (event.character !== this.character) return false

      return this.battle
        .startEvent(SkillTriggered, {skill: this.path})
        .perform(Character, this.character.path, char => char.changeEnergy(this.energyRecovery))
        .endEvent()

    }
}