import { AxeMastery } from 'game/extended/heros/barbarian/axe_mastery/axe_mastery'
import { Bloodthurst } from 'game/extended/heros/barbarian/bloodthurst/bloodthurst'
import bloodthurst_references from 'game/extended/heros/barbarian/bloodthurst/bloodthurst_references'
import { Rampage } from 'game/extended/heros/barbarian/rampage/rampage'
import rampage_references from 'game/extended/heros/barbarian/rampage/rampage_references'
import { Ruthless } from 'game/extended/heros/barbarian/ruthless/ruthless'
import { Taunt } from 'game/extended/heros/barbarian/taunt/taunt'

import { Berserk } from './berserk/berserk'
import berserk_references from './berserk/berserk_references'
import { DualSwing } from './dual_swing/dual_swing'
import dual_swing_references from './dual_swing/dual_swing_references'
import { PainStimulus } from './pain_stimulus/pain_stimulus'
import pain_stimulus_references from './pain_stimulus/pain_stimulus_references'
import { RecklessBlow } from './reckless_blow/reckless_blow'
import reckless_blow_references from './reckless_blow/reckless_blow_references'
import { UndyingFury } from './undying_fury/undying_fury'
import undying_fury_references from './undying_fury/undying_fury_references'
import { Warcry } from './warcry/warcry'
import warcry_references from './warcry/warcry_references'
import { Whirlwind } from './whirlwind/whirlwind'
import whirlwind_references from './whirlwind/whirlwind_references'

export default {
    abilities: {
        DualSwing,
        RecklessBlow,
        UndyingFury,
        Warcry,
        Whirlwind,
        Taunt
    },
    skills: {
        Berserk,
        PainStimulus,
        Bloodthurst,
        Rampage,
        Ruthless,
        AxeMastery
    },
    events: {
        ...dual_swing_references.events,
        ...reckless_blow_references.events,
        ...undying_fury_references.events,
        ...warcry_references.events,
        ...whirlwind_references.events,
        ...berserk_references.events,
        ...pain_stimulus_references.events,
        ...bloodthurst_references.events,
        ...rampage_references.events,
    },
    effects: {
        ...dual_swing_references.effects,
        ...reckless_blow_references.effects,
        ...undying_fury_references.effects,
        ...warcry_references.effects,
        ...whirlwind_references.effects,
        ...berserk_references.effects,
        ...pain_stimulus_references.effects,
        ...bloodthurst_references.effects,
        ...rampage_references.effects,
    },
    requirements: {
        ...dual_swing_references.requirements,
        ...reckless_blow_references.requirements,
        ...undying_fury_references.requirements,
        ...warcry_references.requirements,
        ...whirlwind_references.requirements,
        ...berserk_references.requirements,
        ...pain_stimulus_references.requirements,
        ...bloodthurst_references.requirements,
        ...rampage_references.requirements,
    }
}