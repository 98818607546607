import { Volley, VolleyConfig } from 'game/extended/heros/ranger/volley/volley'
import { volley_config } from 'game/extended/heros/ranger/volley/volley_config'
import { AbilityUI } from 'game/extended/uis/abilities/AbilityUI'
import React from 'react'

import image from './volley.png'

export const volley_ui: AbilityUI<'Volley'> = {
    model: Volley,
    configs: volley_config,
    image,
    label: 'Volley',
    renderTargets: () => 'Frontline',
    renderDescription: () => <>
        Hit every unit in the enemy frontline with an attack from your ranged weapon.
    </>,
    renderImpression: (ability: Volley, UI) => <>
        <UI.Char character={ability.character} />{' '}fires a volley of shots
        towards{' '}<UI.Char character={ability.targets} />.
    </>,
    renderTableStatic: (config: VolleyConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Damage:</UI.TKey>
            <UI.TVal>Weapon damage</UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (ability: Volley, UI) => <>
        <UI.TRow>
            <UI.TKey>Damage:</UI.TKey>
            <UI.TVal><UI.Damage damage={ability.damage} /></UI.TVal>
        </UI.TRow>
    </>
}
