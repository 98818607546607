import { tutorial_missions } from 'data/campaign/tutorial/tutorial_missions'
import { default_env } from 'game/extended/default_env'
import { tutorial_barbarian } from 'game/extended/heros/barbarian/tutorial_barbarian'
import { keysof } from 'game/util/keysof'
import { CampaignModel } from 'models/campaign/CampaignModel'
import { ChapterConfig } from 'models/campaign/ChapterModel'

import background from './tutorial_background.png'

export const tutorial_chapter: ChapterConfig = {
  label: 'Tutorial',
  background,
  missions: keysof(tutorial_missions),
  classes: ['tutorial_barbarian'],
  items: ['heaxy_axe', 'blazewing_boots'],
  initial: () => new CampaignModel({
    user: {
      counter: 0,
      heros: [{
        id: 'barbarian',
        level: 1,
        name: 'Graut',
        rewards: [],
        skill_points: 0,
        stats: tutorial_barbarian.definition.stats,
        type: 'tutorial_barbarian',
      }],
      inventory: [],
      money: 500
    },
    difficulty: 'regular',
    chapter: 'tutorial_chapter',
    missions: {}
  }, default_env).shuffleShop()
}
