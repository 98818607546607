import { CharEnergyChanged } from 'game/core'
import { EventUI } from "game/extended/uis/events/EventUI"
import React from 'react'

export const char_energy_changed_ui: EventUI<'CharEnergyChanged'> = {
    model: CharEnergyChanged,

    render: (event: CharEnergyChanged, UI) => {
        return <>
            <UI.Char character={event.character} />
            {event.energyChange >= 0 ?
                ` gains ${event.energyChange} energy` :
                ` loses ${-event.energyChange} energy`}
        </>
    }
}
