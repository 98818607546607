import { Armor, ItemRarity } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './water_adept_robe.png'

export const water_adept_robe: ItemUI<Armor> = {
    image,
    label: 'Water adept robe',
    value: 30,
    item: {
        key: 'water_adept_robe',
        type: 'armor',
        rarity: ItemRarity.Common,
        stats: {
            intelligence: 20,
            agility: 5,
            resistance: 10
        }
    }
}
