import { AttackRange, DamageType, ItemRarity, Weapon } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './short_bow.png'

export const short_bow: ItemUI<Weapon> = {
    image,
    label: 'Short bow',
    value: 85,
    item: {
        key: 'short_bow',
        type: 'weapon',
        rarity: ItemRarity.Rare,
        stats: {
            agility: 25,
            health: 10
        },
        two_handed: false,
        category: 'bow',
        damageType: DamageType.Ranged,
        range: AttackRange.Ranged,
        energy: 1,
        damage: 6
    }
}
