import { ItemRarity, Trinket } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './brutal_bracelets.png'

export const brutal_bracelets: ItemUI<Trinket> = {
    image,
    label: 'Brutal bracelets',
    value: 45,
    item: {
        key: 'brutal_bracelets',
        type: 'trinket',
        rarity: ItemRarity.Rare,
        stats: {
            armor: 20
        }
    }
}
