import { EffectRefreshed } from 'game/core'
import { EventUI } from "game/extended/uis/events/EventUI"
import React from 'react'

export const effect_refreshed_ui: EventUI<'EffectRefreshed'> = {
    model: EffectRefreshed,

    render: (event: EffectRefreshed, UI) => {

        return <>
            <UI.Effect effect={event.effect} />
            {` was refreshed on `}
            <UI.Char character={event.character} />
            {event.effect && event.effect.duration.type === 'turns' && <>
                {` and will last for another `}
                <UI.Turns turns={event.effect.duration.turns} />
            </>}
        </>
    }
}
