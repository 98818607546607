import { Whirlwind, WhirlwindConfig } from 'game/extended/heros/barbarian/whirlwind/whirlwind'
import image from 'game/extended/heros/barbarian/whirlwind/whirlwind.png'
import { AbilityUI } from 'game/extended/uis/abilities/AbilityUI'
import React from 'react'

export const whirlwind_ui: AbilityUI<'Whirlwind'> = {
    model: Whirlwind,
    configs: [
        {
            available: 2,
            cooldown: 4,
            energy: 4,
            increase: 0
        },
        {
            available: 2,
            cooldown: 3,
            energy: 4,
            increase: 20
        },
        {
            available: 1,
            cooldown: 3,
            energy: 4,
            increase: 40
        }
    ],
    image,
    label: 'Whirlwind',
    renderTargets: () => 'Melee + Neighbours',
    renderDescription: () => <>
        Attack your melee target and his neighbors, dealing your current weapon damage increased by
        an extra percentage.
    </>,
    renderImpression: (ability: Whirlwind, UI) => <>
        <UI.Char character={ability.character} />{' '}spins his weapon around with tremendous force striking{' '}<UI.Char character={ability.targets} />.
    </>,
    renderTableStatic: (config: WhirlwindConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Increase:</UI.TKey>
            <UI.TVal>{config.increase}%</UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (ability: Whirlwind, UI) => <>
        <UI.TRow>
            <UI.TKey>Increase:</UI.TKey>
            <UI.TVal>{ability.increase}%</UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Damage:</UI.TKey>
            <UI.TVal><UI.Damage damage={ability.damage} /></UI.TVal>
        </UI.TRow>
    </>
}
