import { ClassKey, CreatureKey, TypeKey } from 'game/extended/types'
import { ClassUI, getClassUI } from 'game/extended/uis/types/class_uis'
import { CreatureUI, getCreatureUI } from 'game/extended/uis/types/creature_uis'

export function getTypeUI(key: TypeKey): ClassUI | CreatureUI {
    const classUI = getClassUI(key as ClassKey)
    if (classUI) return classUI
    const creatureUI = getCreatureUI(key as CreatureKey)
    if (creatureUI) return creatureUI
    throw new Error(`Unknown type key: '${key}'`)
}
