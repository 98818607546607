import { ItemRarity, Trinket } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './protective_ring.png'

export const protective_ring: ItemUI<Trinket> = {
    image,
    label: 'Protective ring',
    value: 75,
    item: {
        key: 'protective_ring',
        type: 'trinket',
        rarity: ItemRarity.Epic,
        stats: {
            shielding: 20,
            armor: 15,
            resistance: 25
        }
    }
}
