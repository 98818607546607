import full from 'game/extended/creatures/goblins/goblin_bomber/goblin_bomber.svg'
import circle from 'game/extended/creatures/goblins/goblin_bomber/goblin_bomber_circle.svg'
import head from 'game/extended/creatures/goblins/goblin_bomber/goblin_bomber_head.svg'
import { CreatureUI } from 'game/extended/uis/types/creature_uis'

export const goblin_bomber: CreatureUI = {
    label: 'Goblin bomber',
    definition: lvl => ({
        stats: {
            health: 70,
            energy: 20,
            intelligence: 50,
            strength: 30,
            agility: 20,
            armor: 5,
            shielding: 30,
            resistance: 20
        },
        equipment: {
            boots: 'goblin_boots',
            trinket: 'bomb'
        },
        strategy: [
            {
                abilities: 'MoveSupport',
                conditions: {
                    key: 'WhenCrowd',
                    line: 'allied_front',
                    direction: 'above',
                    amount: 1
                }
            },
            ["Punch"],
            ["ChargeBomb", "BombToss"],
            ["Rest", "Rest"]
        ]
    }),
    full,
    circle: {
        src: circle,
        width: 200,
        height: 150
    },
    head
}
