import { ItemRarity, Trinket } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './fire_scroll.png'

export const fire_scroll: ItemUI<Trinket> = {
    image,
    label: 'Fire scroll',
    value: 55,
    item: {
        key: 'fire_scroll',
        type: 'trinket',
        rarity: ItemRarity.Rare,
        stats: {
            intelligence: 50
        }
    }
}
