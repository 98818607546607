import 'views/layout/sidebar/levelselector.scss'
import React from 'react'
import {styler} from 'views/layout/util/style'

const style = styler('levelselector')

export const LevelSelector: React.FC<{
    current: number
    levels: number
    onchange: (level: number) => void
}> = ({current, levels, onchange}) => {

    return <div className={style()}>
        {Array(levels).fill(null).map( (_,i) =>
            <div
                key={i}
                className={style('level').is({active: i+1 === current})}
                onClick={() => onchange(i+1)}
            >
                Lvl {i+1}
            </div>
        )}
    </div>
}
