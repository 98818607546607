import './stage.scss'

import React from 'react'
import { Leveldot } from 'views/ingame/campaign/map/leveldot'
import { useEndless } from 'views/ingame/endless/Endless'
import { styler } from 'views/layout/util/style'


const style = styler('stage')

export const Stage: React.FC = () => {
  const { endless } = useEndless()

  return <div className={style()}>
    <div className={style('levels')}>
      {/*endless.previousLevel && <Leveldot
        level={endless.previousLevel}
        link="/prev"
        style={{ transform: 'inherit' }}
      />*/}
      <Leveldot
        level={endless.nextLevel}
        link="/next"
        style={{ transform: 'inherit' }}
      />
    </div>
  </div >
}