import { TurnSkipped } from 'game/core'
import { EventUI } from 'game/extended/uis/events/EventUI'
import React from 'react'

export const turn_skipped_ui: EventUI<'TurnSkipped'> = {
    model: TurnSkipped,
    render: (event: TurnSkipped, UI) => <>
        <UI.Effect effect={event.effect} />{' '}prevents{' '}
        <UI.Char character={event.state.character} />{' '}from performing a turn.
    </>
}
