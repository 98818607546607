import './chapters.scss'

import { useApp } from 'AppContext'
import * as chapters from 'data/campaign'
import { ChapterKey, Difficulty } from 'models/campaign/ChapterModel'
import React from 'react'
import { Button, ButtonIcon } from 'views/layout/button'
import { Header, HeaderGroup, HeaderHome } from 'views/layout/header'
import { Label } from 'views/layout/label'
import { styler } from 'views/layout/util/style'
import { useLinker } from 'views/link'
import { Icon } from 'views/ui/icon'

const style = styler('chapters', ['item', 'difficulties'])

export const Chapters: React.FC = () => {
  const { difficulty } = useApp()

  return <div className={style()}>
    <Header>
      <HeaderGroup>
        <HeaderHome />
      </HeaderGroup>
      <HeaderGroup />
    </Header>
    <div className={style('items')} style={{ width: `120%` }}>
      <ChaptersItem difficulty={difficulty} chapterKey="tutorial_chapter" />
      <ChaptersItem difficulty={difficulty} chapterKey="goblins_chapter" />
      <ChaptersItem difficulty={difficulty} chapterKey="undead_chapter" />
      <ChaptersItem difficulty={difficulty} chapterKey="lava_chapter" closed />
    </div>
    <div className={style('difficulties')}>
      <ChaptersDifficulties />
    </div>
  </div>
}

export const ChaptersItem: React.FC<{
  difficulty: Difficulty,
  chapterKey: ChapterKey,
  closed?: boolean
}> = ({ chapterKey, closed, difficulty }) => {
  const { campaign, setCampaign, ranking } = useApp()
  const chapter = chapters[chapterKey]
  const { goTo } = useLinker()
  const active = campaign?.chapter.key === chapterKey
  const bestResult = ranking.getBestResultForChapter(chapterKey, difficulty)

  return <div
    className={style.item()}
    style={{ backgroundImage: `url(${chapter.background})` }}
  >
    <div className={style.item('title')}>
      {chapter.label}
    </div>
    {bestResult && <div className={style.item('score')}>
      <Icon icon="trophy" />{bestResult.score}
    </div>}
    <div className={style.item('state')}>
      {closed && <Label>Available soon</Label>}
      {!closed && !active && <Button
        mod={{ negative: !!campaign }}
        onClick={() => {
          if (closed) return
          setCampaign(null)
          goTo(`/campaign/${chapterKey}/academy`)
        }}
      >Start</Button>}
      {active && <Button
        onClick={() => {
          if (closed) return
          goTo(`/campaign/${chapterKey}`)
        }}>
        Continue
        {campaign && <ButtonIcon icon={campaign.difficulty} />}
      </Button>}
      {active && <Button mod="negative" onClick={(e) => {
        e.stopPropagation()
        setCampaign(null)
        goTo(`/campaign/${chapterKey}/academy`)
      }}>Restart</Button>}
    </div>
  </div >
}

export const ChaptersDifficulties: React.FC = () => {
  const { difficulty: active, setDifficulty } = useApp()

  return <div className={style.difficulties()}>
    <div className={style.difficulties('label')}>
      Difficulty:
    </div>
    {(['regular', 'hard', 'insane'] as const).map(difficulty => {
      return <div
        className={style.difficulties('item').is({ active: active === difficulty })}
        onClick={() => setDifficulty(difficulty)}
        key={difficulty}
      >
        <Icon
          icon={difficulty}
        />
      </div>
    })}
  </div>

}