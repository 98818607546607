import { AbilityPath, Character, Effect, EffectPath, Skill, SkillPath, Stats } from 'game/core'

export enum DamageNature {
    Melee = "MELEE", //eg. sword attack or short range magic spell
    Ranged = "RANGED", //eg. arrow attack or ranged magic spell
    Splash = "SPLASH", //eg. splash damage from an explosion
    Recurrent = "RECURRENT", //eg. poison damage
    Reverse = "REVERSE", //eg. reverse damage received in response to attacking an opponent
    Cursed = "CURSED", //eg. from a healing spell turned into damage
    Instant = "INSTANT" //eg. unreversable damage
}

export enum DamageType {
    Melee = "physical",
    Ranged = "ranged",
    Magic = "magic"
}

export interface Damage {
    origin: AbilityPath | SkillPath
    nature: DamageNature
    type: DamageType
    true_damage?: boolean
    amount: number
    original_amount: number
}

export interface Healing {
    origin: AbilityPath | SkillPath
    amount: number,
    original_amount: number
}

export type DamageModifier =
    { type: 'stat', origin: keyof Stats, stat: number, before: Damage, after: Damage } |
    { type: 'effect', origin: EffectPath, before: Damage, after: Damage } |
    { type: 'skill', origin: SkillPath, before: Damage, after: Damage }

export type FullDamage = Damage & {
    modifiers: Array<DamageModifier>
}

export type InjuryModifier =
    { type: 'stat', origin: keyof Stats, stat: number, before: number, after: number } |
    { type: 'effect', origin: EffectPath, before: number, after: number } |
    { type: 'skill', origin: SkillPath, before: number, after: number }

export interface FullInjury {
    amount: number
    damage: FullDamage
    modifiers: Array<InjuryModifier>
}

export type HealingModifier =
    { type: 'stat', origin: keyof Stats, stat: number, before: number, after: number } |
    { type: 'effect', origin: EffectPath, before: number, after: number } |
    { type: 'ability', origin: AbilityPath, before: number, after: number } |
    { type: 'skill', origin: SkillPath, before: number, after: number }

export type FullHealing = Healing & {
    modifiers: Array<HealingModifier>
}

//Calculations
export function calculateFullDamage(character: Character, originalDamage: Damage, stats: Stats): FullDamage {
    let modifiers: Array<DamageModifier> = []
    let damage: Damage = originalDamage

    switch (originalDamage.type) {
        case DamageType.Magic:
            const modMagic = Math.round(damage.amount * (stats.intelligence / 100))
            if (modMagic) {
                const after = { ...damage, amount: damage.amount + modMagic }
                modifiers = [...modifiers, {
                    type: 'stat', origin: 'intelligence', stat: stats.intelligence, before: damage, after
                }]
                damage = after
            }
            break

        case DamageType.Ranged:
            const modAgi = Math.round(damage.amount * (stats.agility / 100))
            if (modAgi) {
                const after = { ...damage, amount: damage.amount + modAgi }
                modifiers = [...modifiers, {
                    type: 'stat', origin: 'agility', stat: stats.agility, before: damage, after
                }]
                damage = after
            }
            break

        case DamageType.Melee:
            const modStr = Math.round(damage.amount * (stats.strength / 100))
            if (modStr) {
                const after = { ...damage, amount: damage.amount + modStr }
                modifiers = [...modifiers, {
                    type: 'stat', origin: 'strength', stat: stats.strength, before: damage, after
                }]
                damage = after
            }
            break
    }

    character.type.skills.forEach(skill => {
        const newDamage = skill.applyDamageModifier(damage)
        if (newDamage !== damage) {
            const newRoundedDamage = {
                ...newDamage,
                amount: Math.round(newDamage.amount)
            }
            modifiers = [...modifiers, {
                type: 'skill', origin: skill.path, before: damage, after: newRoundedDamage
            }]
            damage = newRoundedDamage
        }
    })

    character.effects.forEach(effect => {
        const newDamage = effect.applyDamageModifier(damage)
        if (newDamage !== damage) {
            const newRoundedDamage = {
                ...newDamage,
                amount: Math.round(newDamage.amount)
            }
            modifiers = [...modifiers, {
                type: 'effect', origin: effect.path, before: damage, after: newRoundedDamage
            }]
            damage = newRoundedDamage
        }
    })

    character.battle.modifiers.forEach(mod => {
        const newDamage = mod.applyGlobalDamageModifier(character, damage)
        if (newDamage !== damage) {
            const newRoundedDamage = {
                ...newDamage,
                amount: Math.round(newDamage.amount)
            }
            if (mod instanceof Skill) {
                modifiers = [...modifiers, {
                    type: 'skill', origin: mod.path, before: damage, after: newRoundedDamage
                }]
            }
            if (mod instanceof Effect) {
                modifiers = [...modifiers, {
                    type: 'effect', origin: mod.path, before: damage, after: newRoundedDamage
                }]
            }

            damage = newRoundedDamage
        }
    })

    return { ...damage, modifiers }
}

export function calculateFullDamageOnTarget(currentDamage: FullDamage, target: Character): FullDamage {
    //TODO: refactor this with the function above
    const character = Character.byPath(target.battle, currentDamage.origin)
    if (!character) return currentDamage

    let modifiers: Array<DamageModifier> = []
    let damage: Damage = currentDamage

    character.type.skills.forEach(skill => {
        const newDamage = skill.applyOnTargetDamageModifier(damage, target)
        if (newDamage !== damage) {
            const newRoundedDamage = {
                ...newDamage,
                amount: Math.round(newDamage.amount),
            }
            modifiers = [...modifiers, {
                type: 'skill', origin: skill.path, before: damage, after: newRoundedDamage
            }]
            damage = newRoundedDamage
        }
    })

    character.effects.forEach(effect => {
        const newDamage = effect.applyOnTargetDamageModifier(damage, target)
        if (newDamage !== damage) {
            const newRoundedDamage = {
                ...newDamage,
                amount: Math.round(newDamage.amount)
            }
            modifiers = [...modifiers, {
                type: 'effect', origin: effect.path, before: damage, after: newRoundedDamage
            }]
            damage = newRoundedDamage
        }
    })

    return { ...damage, modifiers: [...currentDamage.modifiers, ...modifiers] }
}

export function calculateInjury(character: Character, damage: FullDamage): FullInjury {
    let modifiers: Array<InjuryModifier> = []
    let amount: number = damage.amount

    switch (damage.type) {
        case DamageType.Ranged:
            if (!damage.true_damage && character.stats.shielding) {
                const after = Math.round(amount * 100 / (100 + character.stats.shielding))
                modifiers = [...modifiers, {
                    type: 'stat', origin: 'shielding', stat: character.stats.shielding, before: amount, after
                }]
                amount = after
            }
            break

        case DamageType.Melee:
            if (!damage.true_damage && character.stats.armor) {
                const after = Math.round(amount * 100 / (100 + character.stats.armor))
                modifiers = [...modifiers, {
                    type: 'stat', origin: 'armor', stat: character.stats.armor, before: amount, after
                }]
                amount = after
            }
            break
        case DamageType.Magic:
            if (!damage.true_damage && character.stats.resistance) {
                const after = Math.round(amount * 100 / (100 + character.stats.resistance))
                modifiers = [...modifiers, {
                    type: 'stat', origin: 'resistance', stat: character.stats.resistance, before: amount, after
                }]
                amount = after
            }
            break
    }

    character.type.skills.forEach(skill => {
        const newAmount = Math.round(skill.applyInjuryModifier(amount, damage))
        if (newAmount !== amount) {
            modifiers = [...modifiers, {
                type: 'skill', origin: skill.path, before: amount, after: newAmount
            }]
            amount = newAmount
        }
    })

    character.effects.forEach(effect => {
        const newAmount = effect.applyInjuryModifier(amount, damage)
        if (newAmount !== amount) {
            modifiers = [...modifiers, {
                type: 'effect', origin: effect.path, before: amount, after: newAmount
            }]
            amount = newAmount
        }
    })

    character.battle.modifiers.forEach(mod => {
        const newAmount = mod.applyGlobalInjuryModifier(character, amount, damage)
        if (newAmount !== amount) {
            if (mod instanceof Skill) {
                modifiers = [...modifiers, {
                    type: 'skill', origin: mod.path, before: amount, after: newAmount
                }]
            }
            if (mod instanceof Effect) {
                modifiers = [...modifiers, {
                    type: 'effect', origin: mod.path, before: amount, after: newAmount
                }]
            }
            amount = newAmount
        }
    })

    return { amount, damage, modifiers }
}

export function calculateHealing(character: Character, healing: Healing): FullHealing {
    let modifiers: Array<InjuryModifier> = []
    let amount: number = healing.amount

    const modMagic = Math.round(amount * (character.stats.intelligence / 100))
    if (modMagic) {
        const newAmount = amount + modMagic
        modifiers = [...modifiers, {
            type: 'stat', origin: 'intelligence', stat: character.stats.intelligence, before: amount, after: newAmount
        }]
        amount = newAmount
    }

    character.type.skills.forEach(skill => {
        const newAmount = skill.applyHealingModifier(amount)
        if (newAmount !== amount) {
            modifiers = [...modifiers, {
                type: 'skill', origin: skill.path, before: amount, after: newAmount
            }]
            amount = newAmount
        }
    })

    character.effects.forEach(effect => {
        const newAmount = effect.applyHealingModifier(amount)
        if (newAmount !== amount) {
            modifiers = [...modifiers, {
                type: 'effect', origin: effect.path, before: amount, after: newAmount
            }]
            amount = newAmount
        }
    })

    return { amount, modifiers, origin: healing.origin, original_amount: healing.original_amount }
}
