import { BerserkConfig } from 'game/extended/heros/barbarian/berserk/berserk'

export const berserk_config: Array<BerserkConfig> = [
    {
        duration: 2,
        increment: 4
    },
    {
        duration: 2,
        increment: 7
    },
    {
        duration: 3,
        increment: 10
    }
]