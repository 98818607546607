import { useAds } from 'ads/ads'
import React from 'react'
import { useEndless } from 'views/ingame/endless/Endless'
import { Level } from 'views/ingame/user/levels/level'
import { useLinker } from 'views/link'

export const EndlessNextLevel: React.FC = () => {
  const { endless, setEndless } = useEndless()
  const { goTo } = useLinker()
  const { timeLeft, openPopup } = useAds()

  return <Level
    label={`Mission ${endless.levelNumber}`}
    level={endless.nextLevel}
    toPrev={() => goTo('/prev')}
    onStart={() => {
      if (!timeLeft && endless.lifes <= 4) {
        openPopup()
        return
      }

      if (endless.isFinished) return
      if (endless.user.lineup.isEmpty) return
      const result = endless.fight()
      setEndless(result)
      goTo(`/replay`)
    }}
  />
}