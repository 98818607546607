import { Plugins } from '@capacitor/core'
import { default_env } from 'game/extended/default_env'
import { Nullable } from 'game/util/maybe'
import { CampaignModel, CampaignState } from 'models/campaign/CampaignModel'
import { difficulties, Difficulty } from 'models/campaign/ChapterModel'
import { EndlessModel, EndlessState } from 'models/endless/EndlessModel'
import { RankingModel, RankingState } from 'models/ranking/RankingModel'
import React, { useContext, useEffect, useState } from 'react'
import { TutorialStep } from 'views/ingame/campaign/tutorial'

type AppContextData = {
  campaign: Nullable<CampaignModel>
  difficulty: Difficulty
  endless: Nullable<EndlessModel>
  tutorial: false | TutorialStep
  ranking: RankingModel
}

//Global state used in the app, state is synced to local storage
type AppContextType = (AppContextData & {
  adsCode: string | false
  adsConsent: boolean
  setAdsCode: (code: string) => void,
  setAdsConsent: (value: boolean) => void,
  setCampaign: (newCampaign: CampaignModel | null, tuturialStep?: TutorialStep) => void,
  setDifficulty: (newDifficulty: Difficulty) => void,
  setEndless: (newEndless: EndlessModel | null) => void,
  setTutorial: (tutorialState: TutorialStep | false) => void
})

const AppContext = React.createContext<AppContextType>(null as any)

type ObjectKey = keyof AppContextData | 'ads' | 'ads_code'

async function setObject<O, K extends ObjectKey = ObjectKey>(key: K, value: O): Promise<void> {
  if (!value) return Plugins.Storage.remove({ key })

  Plugins.Storage.set({
    key: key,
    value: JSON.stringify(value)
  });
}

async function getObject<O, K extends ObjectKey = ObjectKey>(key: K): Promise<Nullable<O>> {
  return Plugins.Storage.get({ key: key }).then(result => {
    if (!result || !result.value) return null
    return JSON.parse(result.value)
  })
}

export const AppContextProvider: React.FC = ({ children }) => {
  const [adsCode, setAdsCode] = useState<string | false>(false)
  const [adsConsent, setAdsConsent] = useState<boolean>(false)
  const [data, setData] = useState<AppContextData | 'loading'>('loading')

  useEffect(() => {
    AppStorage.getStoredAdsCode().then(value => setAdsCode(value))
    AppStorage.getStoredAdsConsent().then(value => setAdsConsent(value))
    Promise.all([
      AppStorage.getStoredRanking(),
      AppStorage.getStoredEndless(),
      AppStorage.getStoredCampaign(),
      AppStorage.getStoredTutorial(),
      AppStorage.getStoredDifficulty()
    ]).then(results => {
      const [ranking, endless, campaign, tutorial, difficulty] = results
      setData({ ranking, endless, campaign, tutorial, difficulty })
    })
  }, [])

  if (data === 'loading') return <div>loading...</div>

  const value: AppContextType = {
    ...data,
    adsCode,
    setAdsCode: (code: string) => {
      setAdsCode(code)
      AppStorage.storeAdsCode(code)
    },
    adsConsent,
    setAdsConsent: (value: boolean) => {
      setAdsConsent(value)
      AppStorage.storeAdsConsent(value)
    },
    setCampaign: (newCampaign, tutorialStep) => {
      let newTutorial = data.tutorial
      let newRanking = data.ranking
      if (newCampaign?.isFinished) {
        newRanking = newRanking.addCampaignResult(newCampaign.result)
        AppStorage.storeRanking(newRanking)
      }

      if (!newCampaign || newCampaign?.chapter.key !== 'tutorial_chapter') newTutorial = false
      else if (tutorialStep) newTutorial = tutorialStep
      AppStorage.storeCampaign(newCampaign)
      AppStorage.storeTutorial(newTutorial)
      setData({ ...data, campaign: newCampaign, ranking: newRanking, tutorial: newTutorial })
    },
    setDifficulty: (newDifficulty) => {
      AppStorage.storeDifficulty(newDifficulty)
      setData({ ...data, difficulty: newDifficulty })
    },
    setEndless: (newEndless) => {
      AppStorage.storeEndless(newEndless)
      if (!newEndless) {
        setData({ ...data, endless: null })
        return
      }
      const newRanking = data.ranking.updateEndlessResult(newEndless.result)
      AppStorage.storeRanking(newRanking)
      setData({ ...data, ranking: newRanking, endless: newEndless })
    },
    setTutorial: (newTutorial) => {
      AppStorage.storeTutorial(newTutorial)
      setData({ ...data, tutorial: newTutorial })
    }
  }

  return <AppContext.Provider value={value}>
    {children}
  </AppContext.Provider>
}

export const useApp = () => useContext(AppContext)

class AppStorage {

  static async getStoredAdsCode(): Promise<string | false> {
    const state = await getObject<string>('ads_code')
    if (!state) return false
    return state
  }

  static async storeAdsCode(value: string) {
    setObject('ads_code', value)
  }

  static async getStoredAdsConsent(): Promise<boolean> {
    const state = await getObject<boolean>('ads')
    if (!state) return false
    return true
  }

  static async storeAdsConsent(value: boolean) {
    setObject('ads', value ? 'enabled' : null)
  }

  static async getStoredDifficulty(): Promise<Difficulty> {
    const diff = await getObject<string>('difficulty')
    if (diff && new Set<string>(difficulties).has(diff)) return diff as Difficulty
    return 'regular'
  }

  static async storeDifficulty(diff: Difficulty) {
    setObject('difficulty', diff)
    localStorage.setItem('difficulty', diff)
  }

  static async getStoredCampaign(): Promise<CampaignModel | null> {
    if (process.env.REACT_APP_CAMPAIGN === 'test') return null

    const state = await getObject<CampaignState>('campaign')
    if (!state) return null
    return new CampaignModel(state, default_env)
  }

  static async storeCampaign(campaign: CampaignModel | null) {
    if (!campaign) return setObject('campaign', null)
    const { last_replay, ...state } = campaign.state
    setObject('campaign', state)
  }

  static async getStoredEndless(): Promise<EndlessModel | null> {
    const state = await getObject<EndlessState>('endless')
    if (!state) return null
    return new EndlessModel(state, default_env)
  }

  static async storeEndless(endless: EndlessModel | null) {
    if (!endless) return setObject('endless', null)

    const { last_replay, ...state } = endless.state
    return setObject("endless", state)
  }

  static async getStoredTutorial(): Promise<TutorialStep | false> {
    const state = await getObject<TutorialStep>('tutorial')
    if (!state) return false
    return state
  }

  static async storeTutorial(step: TutorialStep | false) {
    const tutorial = await getObject<TutorialStep>("tutorial")
    return tutorial || false
  }

  static async getStoredRanking(): Promise<RankingModel> {
    const ranking = await getObject<RankingState>('ranking')
    if (ranking) return new RankingModel(ranking)

    return new RankingModel({
      campaigns: [],
      endlesss: {
        levels: 0,
        heroes: []
      }
    })
  }

  static async storeRanking(ranking: RankingModel) {
    setObject('ranking', ranking.state)
  }
}