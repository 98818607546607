import { Ability, Effect, EffectDuration, Turns } from 'game/core'
import { Memoize } from 'game/util/memoize'

export class SeduceEffect extends Effect<{
  duration: Turns
}>{
  static KEY = 'SeduceEffect' as const

  @Memoize()
  get duration(): EffectDuration {
    return {
      type: 'turns',
      turns: this.state.duration
    }
  }

  // ----- Reactions ----- //
  canPerform(ability: Ability): boolean {
    if (!this.origin) return true
    return !ability.targetsArray.find(char => char === this.origin)
  }
}