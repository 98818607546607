import full from 'game/extended/creatures/goblins/goblin_axeman/goblin_axeman.svg'
import circle from 'game/extended/creatures/goblins/goblin_axeman/goblin_axeman_circle.svg'
import head from 'game/extended/creatures/goblins/goblin_axeman/goblin_axeman_head.svg'
import { CreatureUI } from 'game/extended/uis/types/creature_uis'

export const goblin_axeman: CreatureUI = {
    label: 'Goblin axeman',
    definition: lvl => ({
        stats: {
            health: 60 + lvl * 10,
            energy: 12 + lvl,
            intelligence: 18 + lvl * 2,
            strength: 50 + lvl * 10,
            agility: 40 + lvl * 5,
            armor: 30,
            shielding: 20,
            resistance: 10
        },
        equipment: {
            primary: 'goblin_axe',
            secondary: 'goblin_bow',
            boots: 'goblin_boots'
        },
        abilities: [],
        strategy: [
            {
                abilities: 'MoveSupport',
                conditions: [
                    {
                        key: 'WhenHurt',
                        percent: 20,
                        target: "self"
                    },
                    {
                        key: 'WhenCrowd',
                        line: 'allied_front',
                        direction: 'above',
                        amount: 1
                    }
                ]
            },
            {
                abilities: ['SecondaryWeaponAttack'],
                conditions: {
                    key: 'WhenPositioned',
                    position: 'support'
                }
            },
            ['PrimaryWeaponAttack']
        ]
    }),
    full,
    circle: {
        src: circle,
        width: 200,
        height: 150
    },
    head
}
