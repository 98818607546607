import { EthernalFireEffect } from 'game/extended/heros/mage/ethernal_fire/ethernal_fire_effect'
import { EffectUI } from 'game/extended/uis/effects/EffectUI'
import React from 'react'

import image from './ethernal_fire.png'

export const ethernal_fire_effect_ui: EffectUI<'EthernalFireEffect'> = {
    model: EthernalFireEffect,
    label: 'Ethernal Fire',
    image,
    renderDescription: (effect) => <>
        This character is boosted with bonus intelligence, untill the effect wears of and
        {effect.target.isFemale ? ' she ' : ' he '} dies.
    </>,
    renderTable: (effect: EthernalFireEffect, UI) => <>
        <UI.TRow>
            <UI.TKey>bonus:</UI.TKey>
            <UI.TVal><UI.Stat stat="intelligence" amount={effect.bonusIntelligence} /></UI.TVal>
        </UI.TRow>
    </>
}
