import React from 'react'
import { useUser } from 'views/ingame/user/usercontext'
import { Button } from 'views/layout/button'
import { Popup, PopupActions, PopupContent, PopupFullHeader } from 'views/layout/popup'
import { useLinker } from 'views/link'

export const Surrender: React.FC = () => {
  const { goTo } = useLinker()
  const { finalize: resign } = useUser()

  return <Popup
    onClose={() => goTo('')}
    mod={["small", "variable"]}
  >
    <PopupFullHeader>
      Do you wish to surrender?
    </PopupFullHeader>
    <PopupContent>
      <PopupActions>
        <Button onClick={() => {
          goTo('')
        }}>Continue</Button>
        <Button
          onClick={resign}
          mod="negative"
        >
          Surrender
        </Button>
      </PopupActions>
    </PopupContent>
  </Popup >
}