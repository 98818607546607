import { AttackRange, DamageType, ItemRarity, Weapon } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './demon_crossbow.png'

export const demon_crossbow: ItemUI<Weapon> = {
    image,
    label: 'Demon short crossbow',
    value: 120,
    item: {
        key: 'demon_crossbow',
        type: 'weapon',
        rarity: ItemRarity.Rare,
        stats: {
            agility: 30,
            intelligence: 30
        },
        two_handed: false,
        category: 'bow',
        damageType: DamageType.Ranged,
        range: AttackRange.Ranged,
        energy: 4,
        damage: 10
    }
}
