import { CleansingLight } from 'game/extended/heros/paladin/cleansing_light/cleansing_light'
import { cleansing_light_config } from 'game/extended/heros/paladin/cleansing_light/cleansing_light_config'
import { AbilityUI } from 'game/extended/uis/abilities/AbilityUI'
import React from 'react'

import image from './cleansing_light.png'

export const cleansing_light_ui: AbilityUI<'CleansingLight'> = {
    model: CleansingLight,
    configs: cleansing_light_config,
    image,
    label: 'Cleansing light',
    renderTargets: () => 'Self + Front / Support',
    renderDescription: () => <>
        Remove all enemy effects from yourself and the ally behind or in front of you.
    </>,
    renderImpression: (ability: CleansingLight, UI) => <>
        <UI.Char character={ability.character} />{' '}calls forth a divine light, cleansing all {ability.character.isMale ? 'his' : 'her'} negative effects
        {ability.targetsBesideHimself.length > 0 &&
            <>{' '}and that of{' '}<UI.Char character={ability.targetsBesideHimself} /></>
        }.
    </>
}
