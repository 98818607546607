import { Environment } from 'game/core'
import { ability_canceled_ui } from 'game/extended/core/events/ability_canceled_ui'
import { ability_performed_ui } from 'game/extended/core/events/ability_performed_ui'
import { ability_picked_ui } from 'game/extended/core/events/ability_picked_ui'
import { char_damage_ignored_ui } from 'game/extended/core/events/char_damage_ignored_ui'
import { char_damaged_ui } from 'game/extended/core/events/char_damaged_ui'
import { char_died_ui } from 'game/extended/core/events/char_died_ui'
import { char_energy_changed_ui } from 'game/extended/core/events/char_energy_changed_ui'
import { char_finds_no_ability_ui } from 'game/extended/core/events/char_finds_no_ability_ui'
import { char_has_no_active_rule_ui } from 'game/extended/core/events/char_has_no_active_rule_ui'
import { char_healed_ui } from 'game/extended/core/events/char_healed_ui'
import { char_interrupted_ui } from 'game/extended/core/events/char_interrupted_ui'
import { char_weapon_switched_ui } from 'game/extended/core/events/char_weapon_switched_ui'
import { collect_event_ui } from 'game/extended/core/events/collect_event_ui'
import { effect_added_ui } from 'game/extended/core/events/effect_added_ui'
import { effect_finalized_ui } from 'game/extended/core/events/effect_finalized_ui'
import { effect_refreshed_ui } from 'game/extended/core/events/effect_refreshed_ui'
import { effect_triggered_ui } from 'game/extended/core/events/effect_triggered_ui'
import { rule_started_ui } from 'game/extended/core/events/rule_started_ui'
import { skill_stacks_added_ui } from 'game/extended/core/events/skill_stacks_added_ui'
import { skill_stacks_cleared_ui } from 'game/extended/core/events/skill_stacks_cleared_ui'
import { skill_triggered_ui } from 'game/extended/core/events/skill_triggered_ui'
import { turn_ended_ui } from 'game/extended/core/events/turn_ended_ui'
import { turn_skipped_ui } from 'game/extended/core/events/turn_skipped_ui'
import { EventKey, events } from 'game/extended/types'
import { keysof } from 'game/util/keysof'

import { EventUI } from './EventUI'
import { effect_stat_changed_ui } from 'game/extended/core/events/effect_stat_changed_ui'

const event_uis: {
    [K in EventKey]: EventUI<K>
} = {
    AbilityCanceled: ability_canceled_ui,
    AbilityPerformed: ability_performed_ui,
    AbilityPicked: ability_picked_ui,
    CharDamageIgnored: char_damage_ignored_ui,
    CharDamaged: char_damaged_ui,
    CharDied: char_died_ui,
    CharEnergyChanged: char_energy_changed_ui,
    CharFindsNoAbility: char_finds_no_ability_ui,
    CharHasNoActiveRule: char_has_no_active_rule_ui,
    CharHealed: char_healed_ui,
    CharInterrupted: char_interrupted_ui,
    CharWeaponSwitched: char_weapon_switched_ui,
    CollectEvent: collect_event_ui,
    EffectAdded: effect_added_ui,
    EffectFinalized: effect_finalized_ui,
    EffectRefreshed: effect_refreshed_ui,
    EffectTriggered: effect_triggered_ui,
    EffectStatChanged: effect_stat_changed_ui,
    RuleStarted: rule_started_ui,
    SkillStacksAdded: skill_stacks_added_ui,
    SkillStacksCleared: skill_stacks_cleared_ui,
    SkillTriggered: skill_triggered_ui,
    TurnEnded: turn_ended_ui,
    TurnSkipped: turn_skipped_ui
}

export function registerEvents(env: Environment) {
    keysof(events).forEach(key => {
        env.registerModel(key, events[key] as any, {})
    })
}

export function getEventUI(key: EventKey): EventUI<any> {
    return event_uis[key]
}