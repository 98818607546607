import { Condition, Rule } from 'game/core'
import { Memoize } from 'game/util/memoize'

export class Alternating extends Condition<{}> {

    public static KEY = 'Alternating'

    @Memoize()
    get isBlocking() {
        if (!this.character.lastCharge) return false
        return Rule.byPath(this.battle, this.character.lastCharge.rule) === this.rule
    }
}
