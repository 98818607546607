import { RequiredPosition } from 'game/core'
import { RequirementUI } from 'game/extended/uis/requirements/RequirementUI'
import { UnreachableCaseError } from 'game/util/never'
import React from 'react'

export const required_position_ui: RequirementUI<'RequiredPosition'> = {
    model: RequiredPosition,
    label: 'Required position',
    renderDescription: (state: RequiredPosition['state']) => {
        switch (state.pos) {
            case 'front':
                return <>Be positioned in the front line</>
            case 'support':
                return <>Be positioned in the support line</>
            case 'center':
                return <>Be positioned in the center of your team</>
            case 'side':
                return <>Be positioned on the side of your team</>
            default:
                throw new UnreachableCaseError(state.pos)
        }
    }
}
