import { Ability, AbilityConfig, Character, Turns } from 'game/core'
import { ArcaneCurseEffect } from 'game/extended/heros/mage/arcane_curse/arcane_curse_effect'
import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'

export type ArcaneCurseConfig = AbilityConfig & {
    max_energy: number
    duration: number
}

export class ArcaneCurse extends Ability<ArcaneCurseConfig> {

    static KEY = 'ArcaneCurse' as const
    static FIXED_REQUIREMENTS = []

    @Memoize()
    get targets(): Nullable<Character> {
        return this.character.rangedTarget || this.character.frontTarget
    }

    @Memoize()
    get maxEnergy(): number {
        return this.currentConfig.max_energy
    }

    @Memoize()
    get duration(): Turns {
        return this.currentConfig.duration
    }

    // ----- Calculate new State ----- //
    performActionImpl() {
        if (!this.targets) return this.battle
        return this.targets.addEffect(ArcaneCurseEffect, {
            duration: this.duration,
            max_energy: this.maxEnergy,
            origin: this.path
        })
    }
}