import { DamageType } from 'game/core'
import { ArcedShots, ArcedShotsConfig } from 'game/extended/heros/ranger/arced_shots/arced_shots'
import { arced_shots_config } from 'game/extended/heros/ranger/arced_shots/arced_shots_config'
import { SkillUI } from 'game/extended/uis/skills/SkillUI'
import React from 'react'

import image from './arced_shots.png'

export const arced_shots_ui: SkillUI<'ArcedShots'> = {
    model: ArcedShots,
    configs: arced_shots_config,
    image,
    label: 'Arced shots',
    renderDescription: (config: ArcedShotsConfig) => <span>
        Gain a damage increase whenever you deal ranged damage againt a support target.
    </span>,
    renderTableStatic: (config: ArcedShotsConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Increase:</UI.TKey>
            <UI.TVal><UI.Damage type={DamageType.Ranged} damage={config.increase} percentage /></UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (ability: ArcedShots, UI) => <>
        <UI.TRow>
            <UI.TKey>Increase:</UI.TKey>
            <UI.TVal><UI.Damage type={DamageType.Ranged} damage={ability.damageIncrease} percentage /></UI.TVal>
        </UI.TRow>
    </>
}
