import { AttackRange, DamageType, ItemRarity, Weapon } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './goblin_axe.png'

export const goblin_axe: ItemUI<Weapon> = {
    image,
    label: 'Goblin axe',
    value: 25,
    item: {
        key: 'goblin_axe',
        type: 'weapon',
        rarity: ItemRarity.Common,
        stats: {
            strength: 10,
            agility: 10,
            health: 10
        },
        two_handed: true,
        category: 'axe',
        damageType: DamageType.Melee,
        range: AttackRange.Melee,
        energy: 4,
        damage: 12
    }
}
