import { Character, Event, Skill, SkillPath } from 'game/core'
import { Memoize } from 'game/util/memoize'
import { Nullable } from 'game/util/maybe'

export class SkillTriggered extends Event<{
    skill: SkillPath
}> {

    static KEY = 'SkillTriggered' as const

    @Memoize()
    get character(): Nullable<Character> {
        return Character.byPath(this.battle, this.state.skill)
    }

    @Memoize()
    get skill(): Nullable<Skill> {
        return Skill.byPath(this.battle, this.state.skill)
    }
}
