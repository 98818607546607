import { Battle, Character, CharacterPath, Environment, ModelID, Modifier, ModifierState, Type } from 'game/core'
import { Turns } from 'game/core/battle'
import { FullHealing } from 'game/core/damage'
import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'

export type CoreSkillKey = string

export interface SkillPath extends CharacterPath {
    skill: CoreSkillKey
}

export interface SkillState extends ModifierState {
    level: number
}

export interface SkillConfig { }

export function registerSkill<S extends Skill>(
    env: Environment,
    constructor: {
        KEY: string
        registerExtra?: (env: Environment) => void
        new(parent: S['parent'], state: S['state']): S
    },
    configs: Array<S['currentConfig']>
) {
    env.registerModel(constructor.KEY, constructor, configs)
    if (constructor.registerExtra) constructor.registerExtra(env)
}

export type SkillConstructor<S extends Skill = any> = {
    KEY: string
    new(parent: S['parent'], state: S['state']): S
}

export abstract class Skill<S = {}, C extends SkillConfig = SkillConfig> extends Modifier<Type, S & SkillState, Array<C>> {

    static byPath(battle: Battle, path: SkillPath): Nullable<Skill> {
        const char = Character.byPath(battle, path)
        if (!char) return null
        return char.type.skills.find(skill => skill.key === path.skill) || null
    }
    static registerExtra(env: Environment) {
        //Extend in subclass to load extra dependencies (effects/requirements)
    }

    @Memoize()
    get id(): ModelID {
        return this.character.id + '_' + this.key
    }

    @Memoize()
    get type(): Type {
        return this.parent
    }

    @Memoize()
    get character(): Character {
        return this.type.character
    }

    @Memoize()
    get path(): SkillPath {
        return { ...this.type.character.path, skill: this.key }
    }

    @Memoize()
    get target(): Character {
        return this.type.character
    }

    @Memoize()
    get directChildren() {
        return []
    }

    @Memoize()
    get battle() {
        return this.type.character.battle
    }

    @Memoize()
    get level(): number {
        return this.state.level
    }

    @Memoize()
    get currentConfig(): C {
        return this.config[this.state.level - 1] || this.config[this.config.length - 1]
    }

    calculateHealing(amount: number): FullHealing {
        return this.character.calculateHealing({ amount, origin: this.path, original_amount: amount })
    }

    // ----- Appliers ----- //
    applyInitialAvailablityModifier(available: Turns): Turns {
        return available
    }

    // ----- Calculate new State ----- //
    update(newState: Partial<S>) {
        return this.type.updateSkill(this.state, newState)
    }
}
