import { Character, CharacterPath, Event, FullDamage } from 'game/core'
import { Memoize } from 'game/util/memoize'
import { Nullable } from 'game/util/maybe'

export class CharDamageIgnored extends Event<{
    damage: FullDamage,
    character: CharacterPath
}> {
    static KEY = 'CharDamageIgnored' as const

    @Memoize()
    get damage(): FullDamage {
        return this.state.damage
    }

    @Memoize()
    get origin(): Nullable<Character> {
        return Character.byPath(this.battle, this.state.damage.origin)
    }

    @Memoize()
    get target(): Nullable<Character> {
        return Character.byPath(this.battle, this.state.character)
    }
}
