import { PenetratingArrows, PenetratingArrowsConfig } from 'game/extended/heros/ranger/penetrating_arrows/penetrating_arrows'
import { penetrating_arrows_config } from 'game/extended/heros/ranger/penetrating_arrows/penetrating_arrows_config'
import { SkillUI } from 'game/extended/uis/skills/SkillUI'
import React from 'react'

import image from './penetrating_arrows.png'

export const penetrating_arrows_ui: SkillUI<'PenetratingArrows'> = {
    model: PenetratingArrows,
    configs: penetrating_arrows_config,
    image,
    label: 'Penetrating arrows',
    renderDescription: () => <>
        Regular, ranged weapon attacks deal splash damage to any opponent behind the target.
    </>,
    renderTableStatic: (config: PenetratingArrowsConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Splash:</UI.TKey>
            <UI.TVal>
                {config.splash}%
            </UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (skill: PenetratingArrows, UI) => <>
        <UI.TRow>
            <UI.TKey>Splash:</UI.TKey>
            <UI.TVal>
                {skill.splash}%
            </UI.TVal>
        </UI.TRow>
    </>
}
