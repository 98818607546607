import { UnitedConfig } from 'game/extended/heros/paladin/united/united'

export const united_config: Array<UnitedConfig> = [
    {
        increase: 5
    },
    {
        increase: 8
    },
    {
        increase: 12
    }
]