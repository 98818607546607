import { Environment } from 'game/core'
import { brutal_ui } from 'game/extended/creatures/goblins/abilities/brutal/brutal_ui'
import { mounted_ui } from 'game/extended/creatures/goblins/abilities/mounted/mounted_ui'
import { reincarnation_ui } from 'game/extended/creatures/goblins/abilities/reincarnation/reincarnation_ui'
import { explosive_ui } from 'game/extended/creatures/tutorial/abilities/explosive/explosive_ui'
import { prickly_ui } from 'game/extended/creatures/tutorial/abilities/prickly/prickly_ui'
import { shell_ui } from 'game/extended/creatures/tutorial/abilities/shell/shell_ui'
import { undead_ui } from 'game/extended/creatures/undead/abilities/undead/undead_ui'
import { axe_mastery_ui } from 'game/extended/heros/barbarian/axe_mastery/axe_mastery_ui'
import { berserk_ui } from 'game/extended/heros/barbarian/berserk/berserk_ui'
import { bloodthurst_ui } from 'game/extended/heros/barbarian/bloodthurst/bloodthurst_ui'
import { pain_stimulus_ui } from 'game/extended/heros/barbarian/pain_stimulus/pain_stimulus_ui'
import { rampage_ui } from 'game/extended/heros/barbarian/rampage/rampage_ui'
import { ruthless_ui } from 'game/extended/heros/barbarian/ruthless/ruthless_ui'
import { afterburn_ui } from 'game/extended/heros/mage/afterburn/afterburn_ui'
import { arcane_growth_ui } from 'game/extended/heros/mage/arcane_growth/arcane_growth_ui'
import { ethernal_fire_ui } from 'game/extended/heros/mage/ethernal_fire/ethernal_fire_ui'
import { prepared_ui } from 'game/extended/heros/mage/prepared/prepared_ui'
import { scholar_ui } from 'game/extended/heros/mage/scholar/scholar_ui'
import { staff_mastery_ui } from 'game/extended/heros/mage/staff_mastery/staff_mastery_ui'
import { dueller_ui } from 'game/extended/heros/paladin/dueller/dueller_ui'
import { enhanced_armor_ui } from 'game/extended/heros/paladin/enhanced_armor/enhanced_armor_ui'
import { penetrating_blows_ui } from 'game/extended/heros/paladin/penetrating_blows/penetrating_blows_ui'
import { self_sacrifice_ui } from 'game/extended/heros/paladin/self_sacrifice/self_sacrifice_ui'
import { shield_block_ui } from 'game/extended/heros/paladin/shield_block/shield_block_ui'
import { united_ui } from 'game/extended/heros/paladin/united/united_ui'
import { ambusher_ui } from 'game/extended/heros/ranger/ambusher/ambusher_ui'
import { arced_shots_ui } from 'game/extended/heros/ranger/arced_shots/arced_shots_ui'
import { cover_ui } from 'game/extended/heros/ranger/cover/cover_ui'
import { frost_mastery_ui } from 'game/extended/heros/ranger/frost_mastery/frost_mastery_ui'
import { penetrating_arrows_ui } from 'game/extended/heros/ranger/penetrating_arrows/penetrating_arrows_ui'
import { swift_ui } from 'game/extended/heros/ranger/swift/swift_ui'
import { true_shots_ui } from 'game/extended/heros/ranger/true_shots/true_shots_ui'
import { SkillKey, skills } from 'game/extended/types'
import { SkillUI } from 'game/extended/uis/skills/SkillUI'
import { keysof } from 'game/util/keysof'

const skill_uis: {
    [K in SkillKey]: SkillUI<K>
} = {
    //Tutorial
    Explosive: explosive_ui,
    Prickly: prickly_ui,
    Shell: shell_ui,

    //Ranger
    Ambusher: ambusher_ui,
    TrueShots: true_shots_ui,
    FrostMastery: frost_mastery_ui,
    Cover: cover_ui,
    PenetratingArrows: penetrating_arrows_ui,
    ArcedShots: arced_shots_ui,
    Swift: swift_ui,

    //Mage
    StaffMastery: staff_mastery_ui,
    ArcaneGrowth: arcane_growth_ui,
    Afterburn: afterburn_ui,
    EthernalFire: ethernal_fire_ui,
    Scholar: scholar_ui,
    Prepared: prepared_ui,

    //Barbarian
    Berserk: berserk_ui,
    ShieldBlock: shield_block_ui,
    PainStimulus: pain_stimulus_ui,
    Bloodthurst: bloodthurst_ui,
    Rampage: rampage_ui,
    Ruthless: ruthless_ui,
    AxeMastery: axe_mastery_ui,

    //Paladin
    United: united_ui,
    Dueller: dueller_ui,
    PenetratingBlows: penetrating_blows_ui,
    SelfSacrifice: self_sacrifice_ui,
    EnhancedArmor: enhanced_armor_ui,

    //Goblins
    Reincarnation: reincarnation_ui,
    Mounted: mounted_ui,
    Brutal: brutal_ui,

    //Undead
    Undead: undead_ui
}

export function registerSkills(env: Environment) {
    keysof(skill_uis).forEach(key => {
        env.registerModel(key, skills[key] as any, skill_uis[key].configs)
    })
}

export function getSkillUI(key: SkillKey): SkillUI<any> {
    return skill_uis[key]
}
