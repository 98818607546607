import { ClassKey } from 'game/extended/types'
import { Memoize } from 'game/util/memoize'
import { RecruitModel } from 'models/user/RecruitModel'
import { UserModel } from 'models/user/UserModel'

export class AcademyModel {
    constructor(readonly user: UserModel) { }

    @Memoize()
    get isEmpty(): boolean {
        return this.recruits.length === 0
    }

    @Memoize()
    get recruits(): Array<RecruitModel> {
        return this.allRecruits.filter(recruit => recruit.isAvailable)
    }

    @Memoize()
    get allRecruits(): Array<RecruitModel> {
        return this.user.config.classes.map(key => new RecruitModel(this, key))
    }

    // ----- State change ----- //
    recruit(key: ClassKey): UserModel {
        return this.user
    }
}
