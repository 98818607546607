import { Ability, AbilityConfig, Character, DamageNature, DamageType, FullDamage } from 'game/core'
import { Memoize } from 'game/util/memoize'

export type InfernoConfig = AbilityConfig & {
    damage: number
}

export class Inferno extends Ability<InfernoConfig>{

    static KEY = 'Inferno' as const
    static FIXED_REQUIREMENTS = []

    @Memoize()
    get targets(): Array<Character> {
        return this.character.side.enemySide.front.characters
    }

    @Memoize()
    get damage(): FullDamage {
        return this.calculateDamage(
            DamageType.Magic,
            DamageNature.Ranged,
            this.currentConfig.damage
        )
    }

    // ----- Calculate new State ----- //
    performActionImpl() {
        return this.performAll(
            Character,
            this.targets.map(ch => ch.path),
            char => char.takeDamage(this.damage)
        )
    }
}
