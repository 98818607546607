import { AttackRange, DamageType, ItemRarity, Weapon } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './fire_sword.png'

export const fire_sword: ItemUI<Weapon> = {
    image,
    label: 'Fire sword',
    value: 110,
    item: {
        key: 'fire_sword',
        type: 'weapon',
        rarity: ItemRarity.Common,
        stats: {
            strength: 30,
            intelligence: 40
        },
        two_handed: true,
        category: 'sword',
        damageType: DamageType.Magic,
        range: AttackRange.Melee,
        energy: 5,
        damage: 14
    }
}
