import { Character, CharacterPath, Event } from 'game/core'
import { Memoize } from 'game/util/memoize'
import { Nullable } from 'game/util/maybe'

export class CharHasNoActiveRule extends Event<{
    character: CharacterPath
}> {
    static KEY = 'CharHasNoActiveRule' as const

    @Memoize()
    get character(): Nullable<Character> {
        return Character.byPath(this.battle, this.state.character)
    }
}
