import { armors, boots, headgear, ItemKey, shields, trinkets, weapons } from 'game/extended/types'
import { getItemUI } from 'game/extended/uis/item_uis'
import { keysof } from 'game/util/keysof'
import React from 'react'
import { useParams } from 'react-router'
import { LibraryItem, LibraryLink, LibraryList, LibraryUI } from 'views/library/library'
import { Icon, IconKey } from 'views/ui/icon'
import { ItemVisual } from 'views/ui/visuals/itemvisual'

const types = ['armor', 'boots', 'headgear', 'trinket', 'weapon', 'shield'] as const
type ItemType = typeof types[number]

function mapIcon(type: ItemType): IconKey {
    switch (type) {
        case 'armor': return 'body'
        case 'boots': return 'boots'
        case 'headgear': return 'headgear'
        case 'trinket': return 'trinket'
        case 'weapon': return 'primary'
        case 'shield': return 'shield'
    }
}

export const Items: React.FC = () => {
    const { type, item } = useParams<{ type: ItemType, item: ItemKey }>()

    const itemMap = {
        armor: armors,
        boots: boots,
        headgear: headgear,
        trinket: trinkets,
        weapon: weapons,
        shield: shields
    }

    const itemsSorter = (a: ItemKey, b: ItemKey) => {
        return getItemUI(a).value - getItemUI(b).value
    }

    return <LibraryUI category="items">
        <LibraryList flex={0.2}>
            {types.map(t => (
                <LibraryLink
                    to={`/library/items/${t}`}
                    active={type === t}
                    key={t}
                >
                    <Icon icon={mapIcon(t)} />{t}
                </LibraryLink>
            ))}
        </LibraryList>
        {type && <LibraryList flex={0.3}>
            {keysof(itemMap[type]).sort(itemsSorter).map(key => {
                const ui = getItemUI(key)
                return <LibraryLink
                    to={`/library/items/${type}/${key}`}
                    active={item === key}
                    key={key}
                >
                    <ItemVisual item={ui.item} />
                    {ui.label}
                </LibraryLink>
            })}
        </LibraryList>}
        {item && <LibraryList flex={0.5}>
            <LibraryItem item={item} />
        </LibraryList>}
    </LibraryUI>
}