import { Ability, AbilityConfig, Character, FullHealing } from 'game/core'
import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'

export type ConsumeConfig = AbilityConfig

export class Consume extends Ability<ConsumeConfig>{

    static KEY = 'Consume' as const
    static FIXED_REQUIREMENTS = []

    @Memoize()
    get targets(): Nullable<Character> {
        const candidates = this.character.neighbours
        const minHp = Math.min(...candidates.map(char => char.hp))
        return candidates.find(char => char.hp === minHp)
    }

    // ----- Calculate new State ----- //
    performActionImpl() {
        if (!this.targets) return this.battle

        const healingAmount = this.character.maxHp - this.character.hp
        const healing: FullHealing = {
            amount: this.character.maxHp - this.character.hp,
            modifiers: [],
            origin: this.path,
            original_amount: healingAmount
        }

        return this.targets
            .instantKill(this.path)
            .perform(Character, this.character.path, char => char.heal(healing))
            .perform(Character, this.character.path, char => char.setEnergy(char.maxEnergy))
    }
}
