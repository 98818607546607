import { RequiredWeaponState } from 'game/core'
import { RequirementUI } from 'game/extended/uis/requirements/RequirementUI'
import { UnreachableCaseError } from 'game/util/never'
import React from 'react'

export const required_weapon_state_ui: RequirementUI<'RequiredWeaponState'> = {
    model: RequiredWeaponState,
    label: 'Required weapon',
    renderDescription: (state) => {
        switch (state.type) {
            case 'any':
                return <>'Have any weapon'</>
            case 'melee':
                return <>'Have a melee weapon'</>
            case 'ranged':
                return <>'Have a ranged weapon'</>
            case 'double_melee':
                return <>'Have a primary melee weapon and a secondary melee weapon'</>
            case 'primary':
                return <>'Have a primary weapon'</>
            case 'secondary':
                return <>'Have a secondary weapon'</>
            case 'shield':
                return <>'Have a shield equiped'</>
            default:
                throw new UnreachableCaseError(state.type)
        }
    }
}
