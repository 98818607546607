import { Swift, SwiftConfig } from 'game/extended/heros/ranger/swift/swift'
import { SkillUI } from 'game/extended/uis/skills/SkillUI'
import React from 'react'

import image from './swift.png'

export const swift_ui: SkillUI<'Swift'> = {
  model: Swift,
  configs: [
    {
      recovery: 1
    },
    {
      recovery: 2
    }
  ],
  image,
  label: 'Swift',
  renderDescription: (config: SwiftConfig) => <span>
      Recover a fixed amount of energy at the end of each turn.
  </span>,
  renderTableStatic: (config: SwiftConfig, UI) => <>
      <UI.TRow>
          <UI.TKey>Recovered:</UI.TKey>
          <UI.TVal><UI.Stat stat="energy" amount={config.recovery} /></UI.TVal>
      </UI.TRow>
  </>,
  renderTable: (ability: Swift, UI) => <>
      <UI.TRow>
          <UI.TKey>Increase:</UI.TKey>
          <UI.TVal><UI.Stat stat="energy" amount={ability.energyRecovery} /></UI.TVal>
      </UI.TRow>
  </>
}
