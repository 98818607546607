import { AttackRange, DamageType, ItemRarity, Weapon } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './vampiress_sword.png'

export const vampiress_sword: ItemUI<Weapon> = {
  image,
  label: 'Vampiress sword',
  value: 110,
  item: {
    key: 'vampiress_sword',
    type: 'weapon',
    rarity: ItemRarity.Epic,
    stats: {
      intelligence: 35,
      energy: 2
    },
    two_handed: false,
    category: 'sword',
    damageType: DamageType.Melee,
    range: AttackRange.Melee,
    energy: 4,
    damage: 12
  }
}
