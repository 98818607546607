import './finished.scss'

import React from 'react'
import { useHistory } from 'react-router'
import { useEndless } from 'views/ingame/endless/Endless'
import { useUser } from 'views/ingame/user/usercontext'
import { Button } from 'views/layout/button'
import { Popup, PopupActions, PopupContent, PopupFullHeader } from 'views/layout/popup'

export const Finished: React.FC = () => {
  const history = useHistory()
  const { endless } = useEndless()
  const { finalize } = useUser()
  const close = () => {
    finalize()
    history.push('/home')
  }

  return <Popup
    onClose={close}
    mod={["small", "variable"]}
  >
    <PopupFullHeader mod="large">
      Game over
    </PopupFullHeader>
    <PopupContent>
      <div className="finished">
        <div className="finished-result">
          <div className="finished-result-label">
            Levels finished:
        </div>
          <div className="finished-result-levels">
            {endless.levelsFinished}
          </div>
        </div>
        <PopupActions>
          <Button onClick={close}>Back to menu</Button>
        </PopupActions>
      </div>
    </PopupContent>
  </Popup >
}