import { Ability, EffectPath, Stunned, TurnData } from 'game/core'
import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'
import { ReplayRound } from 'models/replay/ReplayRound'
import { ReplayTurn } from 'models/replay/ReplayTurn'
import { TurnChar } from 'models/replay/TurnChar'

export class ReplayCharTurn extends ReplayTurn {
    state: TurnData

    @Memoize()
    get start() {
        return this.state.start
    }

    @Memoize()
    get end() {
        return this.state.end
    }

    @Memoize()
    get events() {
        return this.state.events
    }

    @Memoize()
    get key(): string {
        return `T${this.index}`
    }

    constructor(round: ReplayRound, state: TurnData) {
        super(round)
        this.state = state
    }

    @Memoize()
    get index() {
        return this.round.turns.indexOf(this)
    }

    @Memoize()
    get previousTurn(): Nullable<ReplayTurn> {
        if (this.index === 0) return this.round.setup
        return this.round.turns[this.index - 1]
    }

    @Memoize()
    get nextTurn(): Nullable<ReplayTurn> {
        if (this.index === this.round.turns.length - 1) {
            return this.round.nextRound?.setup
        }
        return this.round.turns[this.index + 1]
    }

    @Memoize()
    get isLastTurn(): boolean {
        return !this.nextTurn
    }

    @Memoize()
    get activeAbility(): Nullable<Ability> {
        return this.activeChar?.abilityStart
    }

    @Memoize()
    get interruptingEffect(): Nullable<EffectPath> {
        const char = this.activeChar?.abilityStart?.character
        if (!char) return null
        const stunnedEffect = char.effects.find(eff => eff instanceof Stunned)
        if (!stunnedEffect) return null
        return stunnedEffect.path
    }

    @Memoize()
    get activeChar(): Nullable<TurnChar> {
        return this.chars.find(
            char => char.id === this.state.character.character
        )
    }

}
