import { PainStimulusConfig } from 'game/extended/heros/barbarian/pain_stimulus/pain_stimulus'

export const pain_stimulus_config: Array<PainStimulusConfig> = [
    {
        hp: 20,
        increase: 4
    },
    {
        hp: 25,
        increase: 5
    },
    {
        hp: 30,
        increase: 6
    }
]