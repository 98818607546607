import { CreatureUI } from 'game/extended/uis/types/creature_uis'

import circle from './goblin_brute_circle.png'
import head from './goblin_brute_head.png'

export const goblin_brute: CreatureUI = {
  label: 'Goblin Brute',
  definition: lvl => ({
    stats: {
      health: 180,
      energy: 25,
      intelligence: 30,
      strength: 100,
      agility: 25,
      armor: 65,
      shielding: 30,
      resistance: 10
    },
    equipment: {
      trinket: 'brutal_bracelets'
    },
    skills: [
      'Brutal'
    ],
    strategy: [
      'MoveFront',
      'Toss',
      {
        abilities: ['Punch'],
        conditions: [
          {
            key: 'WhenEnergy',
            amount: 8,
            direction: 'above'
          }
        ]
      }
    ]
  }),
  circle: {
    src: circle,
    width: 240,
    height: 150
  },
  head
}
