import { Effect, EffectDuration, Stats } from 'game/core'
import { Memoize } from 'game/util/memoize'

export class PenetratingBlowsEffect extends Effect<{
    reduction: number
    duration: number
}> {

    static KEY = 'PenetratingBlowsEffect' as const

    @Memoize()
    get reduction(): number {
        return this.state.reduction
    }

    @Memoize()
    get duration(): EffectDuration {
        return {
            type: 'turns',
            turns: this.state.duration
        }
    }

    // ----- Appliers ----- //
    getTargetStats(currentStats: Stats): Stats {
        return {
            ...currentStats,
            resistance: Math.max(0, currentStats.resistance - this.reduction),
            armor: Math.max(0, currentStats.armor - this.reduction)
        }
    }
}
