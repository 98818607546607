import { RuthlessConfig } from 'game/extended/heros/barbarian/ruthless/ruthless'

export const ruthless_config: Array<RuthlessConfig> = [
    {
        increase_per_percent: 0.2
    },
    {
        increase_per_percent: 0.4
    },
    {
        increase_per_percent: 0.8
    }
]