import { AttackRange, DamageType, ItemRarity, Weapon } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './wolf_sword.png'

export const wolf_sword: ItemUI<Weapon> = {
    image,
    label: 'Wolf sword',
    value: 260,
    item: {
        key: 'wolf_sword',
        type: 'weapon',
        rarity: ItemRarity.Legendary,
        stats: {
            strength: 100,
            health: 100,
            armor: 35,
            resistance: 35
        },
        two_handed: true,
        category: 'sword',
        damageType: DamageType.Melee,
        range: AttackRange.Melee,
        energy: 3,
        damage: 21
    }
}
