import React from 'react'
import { useHistory } from 'react-router'
import { useCampaign } from 'views/ingame/campaign/Campaign'
import { useUser } from 'views/ingame/user/usercontext'
import { Button } from 'views/layout/button'
import { Label } from 'views/layout/label'
import { Popup, PopupActions, PopupContent, PopupFullHeader } from 'views/layout/popup'
import { useLinker } from 'views/link'
import './score.scss'

export const Score: React.FC = () => {
  const history = useHistory()
  const { campaign } = useCampaign()
  const { finalize } = useUser()
  const { goTo } = useLinker()
  const close = () => {
    if (campaign.isFinished) {
      finalize()
      history.push('/chapters')
    }
    else {
      goTo('/')
    }
  }

  return <Popup
    onClose={close}
    mod={["small", "variable"]}
  >
    <PopupFullHeader>
      {campaign.isFinished ? 'Completed' : 'Current Score'}
    </PopupFullHeader>
    <PopupContent>
      <div className="score">
        <ScoreResult />
        {campaign.isFinished && <PopupActions>
          <Button onClick={close}>Continue</Button>
        </PopupActions>}
      </div>
    </PopupContent>
  </Popup >
}

export const ScoreResult: React.FC = () => {
  const { campaign } = useCampaign()
  const result = campaign.result

  return <div className="score_result">
    <div className="score_result-result">
      <div className="score_result-result-label">
        <Label>Score</Label>
      </div>
      <div className="score_result-result-amount">
        {result.score}
      </div>
    </div>
    <div className="score_result-stats">
      <div className="score_result-stat">
        <div className="score_result-stat-label">
          Stars
        </div>
        <div className="score_result-stat-amount">
          {result.stars}
        </div>
        <div className="score_result-stat-score">
          +{result.starsScore}
        </div>
      </div>
      <div className="score_result-stat">
        <div className="score_result-stat-label">
          Speed
        </div>
        <div className="score_result-stat-amount">
          {result.speed}%
        </div>
        <div className="score_result-stat-score">
          +{result.speedScore}
        </div>
      </div>
      <div className="score_result-stat">
        <div className="score_result-stat-label">
          Achievements
        </div>
        <div className="score_result-stat-amount">
          {result.achievements}
        </div>
        <div className="score_result-stat-score">
          +{result.achievementsScore}
        </div>
      </div>
    </div>
  </div>
}