import { AchievementKey } from 'models/user/hero/achievements/Achievement'
import * as achievementIcons from 'views/ui/icons/achievements'

export type AchievementUI = {
    label: string
    description: string
    icon: keyof typeof achievementIcons
}

export const achievement_uis: Record<AchievementKey, AchievementUI> = {
    HealingAchievement: {
        label: 'Healing',
        icon: 'healing',
        description: 'Counts all healing performed by you'
    },
    AssistsAchievement: {
        label: 'Assists',
        icon: 'assists',
        description: 'Counts each enemy unit you damage, before an ally kills it'
    },
    BattlesWonAchievement: {
        label: 'Battles won',
        icon: 'battles_won',
        description: 'Counts each battle you win'
    },
    ComboKillAchievement: {
        label: 'Combo kill',
        icon: 'combo_kill',
        description: 'Counts each time you kill 2 or more enemies in the same turn'
    },
    KillsAchievement: {
        label: 'Kills',
        icon: 'kills',
        description: 'Counts every enemy you kill'
    },
    MagicDamageAchievement: {
        label: 'Magic damage dealt',
        icon: 'magic_damage_dealt',
        description: 'Counts all magic damage dealt by you'
    },
    MagicDamageReceivedAchievement: {
        label: 'Magic damage received',
        icon: 'magic_damage_received',
        description: 'All magic damage dealt to you'
    },
    MeleeDamageAchievement: {
        label: 'Melee damage dealt',
        icon: 'melee_damage_dealt',
        description: 'Counts all melee damage dealt by you'
    },
    MeleeDamageReceivedAchievement: {
        label: 'Melee damage received',
        icon: 'melee_damage_received',
        description: 'All melee damage dealt to you'
    },
    RangedDamageAchievement: {
        label: 'Ranged damage dealt',
        icon: 'ranged_damage_dealt',
        description: 'Count all ranged damage dealt by you'
    },
    RangedDamageReceivedAchievement: {
        label: 'Ranged damage received',
        icon: 'ranged_damage_received',
        description: 'All ranged damage dealt to you'
    },
    OneShotAchievement: {
        label: 'One shot kill',
        icon: 'one_shot_kill',
        description: 'Counts every full hp enemy you kill with a single damage source'
    }
}
