import { CharHealed } from 'game/core'
import { AchievementModel } from 'models/user/hero/achievements/Achievement'
import { BattleHistory } from 'models/user/hero/achievements/BattleHistory'

export class HealingAchievement extends AchievementModel {

  calculateProgress(history: BattleHistory) {
    if (!history.char || !history.victorious) return 0

    let result = 0
    history.search(CharHealed).forEach(state => {
      if (state.healing.origin.character !== history.char?.character) return
      result += state.healing.amount
    })
    return result
  }
}
