import React from 'react'
import { useEndless } from 'views/ingame/endless/Endless'
import { Level } from 'views/ingame/user/levels/level'
import { Redirect, useLinker } from 'views/link'

export const EndlessPrevLevel: React.FC = () => {
  const { endless } = useEndless()
  const { goTo } = useLinker()
  if (!endless.previousLevel) return <Redirect to="" />

  return <Level
    label={`Mission ${endless.levelNumber - 1}`}
    level={endless.previousLevel}
    toNext={() => goTo('/next')}
    onStart={() => { }}
  />
}