import { TrueSight } from 'game/extended/heros/ranger/true_sight/true_sight'
import { true_sight_config } from 'game/extended/heros/ranger/true_sight/true_sight_config'
import { AbilityUI } from 'game/extended/uis/abilities/AbilityUI'
import React from 'react'

import image from './true_sight.png'

export const true_sight_ui: AbilityUI<'TrueSight'> = {
    model: TrueSight,
    configs: true_sight_config,
    image,
    label: 'True sight',
    renderTargets: () => 'Allies',
    renderDescription: () => <>
        The next abilities cast by each member of your team will deal true damage, thereby ignoring the enemies
        resistance or armor.
    </>,
    renderImpression: (ability: TrueSight, UI) => <>
        <UI.Char character={ability.character} />{' '}inspires the team with an aura of unnatural precision.
    </>
}
