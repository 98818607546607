import { AbilityCanceled } from 'game/core'
import { EffectKey } from 'game/extended/types'
import { EventUI } from 'game/extended/uis/events/EventUI'
import React from 'react'

export const ability_canceled_ui: EventUI<"AbilityCanceled"> = {
    model: AbilityCanceled,
    render: (event: AbilityCanceled, UI) => {
        return <>
            {'The '}<UI.Effect effect={event.state.effect.effect.key as EffectKey} />
            {' effect prevents '}<UI.Char character={event.character} />
            {' from executing '}
            <UI.Ability ability={event.ability} />.
        </>
    }
}
