import {
  AbilityPath,
  Character,
  DamageNature,
  DamageType,
  Effect,
  EffectDuration,
  EffectTriggered,
  Event,
  FullDamage,
  Interrupt,
  Percentage,
  TurnEnded,
} from 'game/core'
import { Memoize } from 'game/util/memoize'

export class LifedrainEffect extends Effect<{
  ability: AbilityPath
  initial: Percentage
  increment: Percentage
}>{
  static KEY = 'LifedrainEffect' as const

  @Memoize()
  get duration(): EffectDuration {
    return { type: 'unlimited' }
  }

  @Memoize()
  get percentage(): Percentage {
    return this.state.initial + this.state.increment * this.turnsPassed
  }

  @Memoize()
  get damage(): FullDamage {
    const amount = Math.round(this.target.maxHp * this.percentage / 100)

    return {
      amount,
      original_amount: amount,
      modifiers: [],
      nature: DamageNature.Recurrent,
      type: DamageType.Magic,
      origin: this.state.ability
    }
  }

  // ----- Reactions ----- //
  reactToEvent(event: Event): Interrupt {
    if (!this.origin) {
      return this.finalize()
    }
    if (!(event instanceof TurnEnded)) return false
    if (event.character !== this.target) return false

    let result = this.battle
      .startEvent(EffectTriggered, { effect: this.path })
      .perform(Character, this.target.path, char => char.takeDamage(this.damage))
      .endEvent()

    const hpBefore = this.target.hp
    const hpAfter = Character.byPath(result, this.target.path)?.hp || 0
    const damageDealt = hpBefore - hpAfter
    const originAfter = Character.byPath(result, this.state.origin)

    if (!originAfter || damageDealt <= 0) return result

    const targets = originAfter.side.front.characters.map(char => char.path)
    return result.performAll(Character, targets, char => {
      return char.heal({
        amount: Math.floor(damageDealt / targets.length),
        original_amount: Math.floor(damageDealt / targets.length),
        modifiers: [],
        origin: this.state.ability
      })
    })
  }
}