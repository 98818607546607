import image from 'game/extended/heros/barbarian/undying_fury/undying_fury.png'
import React from 'react'
import { UndyingFuryEffect } from 'game/extended/heros/barbarian/undying_fury/undying_fury_effect'
import { EffectUI } from 'game/extended/uis/effects/EffectUI'

export const undying_fury_effect_ui: EffectUI<'UndyingFuryEffect'> = {
    model: UndyingFuryEffect,
    label: 'Undying fury aura',
    image: image,
    renderDescription: (effect: UndyingFuryEffect) => <>
        All incoming damage is reduced to 1.
    </>
}
