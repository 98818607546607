import { Battle, CharDamaged, Event, Interrupt, Skill, SkillConfig, SkillTriggered, Time, Turns } from 'game/core'
import { BerserkEffect } from 'game/extended/heros/barbarian/berserk/berserk_effect'
import { Memoize } from 'game/util/memoize'

export interface BerserkConfig extends SkillConfig {
    increment: number
    duration: Turns
}

export type BerserkState = {
    stacks?: number,
    clear_time?: Time
}

export class Berserk extends Skill<BerserkState, BerserkConfig>{

    static KEY = 'Berserk' as const

    @Memoize()
    get duration() {
        return this.currentConfig.duration
    }

    @Memoize()
    get increment() {
        return this.currentConfig.increment
    }

    // ----- Interrupts ----- //
    reactToEvent(event: Event): Interrupt {
        if (!(event instanceof CharDamaged)) return false
        if (event.target !== this.character) return false

        return this.battle
            .startEvent(SkillTriggered, { skill: this.path })
            .perform(Skill, this.path, skill => (skill as Berserk).trigger())
            .endEvent()
    }

    // ----- Calculate new State ----- //
    trigger(): Battle {
        const effect = this.character.findEffect(BerserkEffect)

        if (!effect) {
            return this.character.addEffect(BerserkEffect, {
                stacks: 1,
                increase: this.increment,
                duration: this.duration,
                origin: this.path
            })
        }
        return effect.addStack(this.increment)
    }
}
