import { UserModel } from 'models/user/UserModel'
import React, { createContext, useContext } from 'react'

export const Usercontext = createContext<{
    user: UserModel,
    setUser: (user: UserModel) => void
    finalize: () => void
}>(null as any)

export const UserContextProvider: React.FC<{
    user: UserModel,
    setUser: (user: UserModel) => void
    finalize: () => void
}> = ({ user, setUser, finalize, children }) => {

    return (
        <Usercontext.Provider value={{ user, setUser, finalize }}>
            {children}
        </Usercontext.Provider>
    )
}

export const useUser = () => {
    return useContext(Usercontext)
}
