import { Ability, AbilityConfig, DamageNature, DamageType, FullDamage } from 'game/core'
import { ChargedBomb } from 'game/extended/creatures/goblins/abilities/charge_bomb/charged_bomb'
import { Memoize } from 'game/util/memoize'

export type ChargeBombConfig = AbilityConfig & {
    explode_damage: number
}

export class ChargeBomb extends Ability<ChargeBombConfig>{

    static KEY = 'ChargeBomb' as const
    static FIXED_REQUIREMENTS = []

    @Memoize()
    get targets(): false {
        return false
    }

    @Memoize()
    get damage(): FullDamage {
        return this.calculateDamage(
            DamageType.Ranged,
            DamageNature.Melee,
            this.currentConfig.explode_damage
        )
    }

    // ----- Calculate new State ----- //
    performActionImpl() {
        return this.character.addEffect(ChargedBomb, {
            origin: this.character.path,
            damage: this.damage,
            tossed: false
        })
    }
}
