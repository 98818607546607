import { ItemRarity, Shield } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './knight_shield.png'

export const knight_shield: ItemUI<Shield> = {
  image,
  label: 'Knight shield',
  value: 110,
  item: {
    key: 'knight_shield',
    type: 'shield',
    rarity: ItemRarity.Epic,
    stats: {
      armor: 35,
      shielding: 35,
      resistance: 20
    }
  }
}
