import { CursedWeapons, CursedWeaponsConfig } from 'game/extended/creatures/goblins/abilities/cursed_weapons/cursed_weapons'
import { cursed_weapons_config } from 'game/extended/creatures/goblins/abilities/cursed_weapons/cursed_weapons_config'
import { AbilityUI } from 'game/extended/uis/abilities/AbilityUI'
import React from 'react'

import image from './cursed_weapons.png'

export const cursed_weapons_ui: AbilityUI<'CursedWeapons'> = {
    model: CursedWeapons,
    configs: cursed_weapons_config,
    image,
    label: 'Cursed weapons',
    renderTargets: () => 'Everyone',
    renderDescription: () => <>
        All enemy weapons have reduced damage, untill this spell wears off.
    </>,
    renderImpression: (ability: CursedWeapons, UI) => <>
        <UI.Char character={ability.character} />{' '}mumbles a spells, igniting all weapons
        with a purple glow.
    </>,
    renderTableStatic: (config: CursedWeaponsConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Duration:</UI.TKey>
            <UI.TVal>
                <UI.Turns turns={config.duration} />
            </UI.TVal>
            <UI.TRow>
                <UI.TKey>Reduction:</UI.TKey>
                <UI.TVal>{config.reduction}%</UI.TVal>
            </UI.TRow>
        </UI.TRow>
    </>,
    renderTable: (ability: CursedWeapons, UI) => <>
        <UI.TRow>
            <UI.TKey>Duration:</UI.TKey>
            <UI.TVal>
                <UI.Turns turns={ability.duration} />
            </UI.TVal>
            <UI.TRow>
                <UI.TKey>Reduction:</UI.TKey>
                <UI.TVal>{ability.reduction}%</UI.TVal>
            </UI.TRow>
        </UI.TRow>
    </>
}
