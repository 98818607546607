import { SkillUI } from 'game/extended/uis/skills/SkillUI'
import React from 'react'

import { Brutal, BrutalConfig } from './brutal'
import image from './brutal.png'

export const brutal_ui: SkillUI<'Brutal'> = {
    model: Brutal,
    configs: [
        {
            increase: 1
        }
    ],
    image,
    label: 'Brutal',
    renderDescription: (config: BrutalConfig) => <>
        For each missing hp the damage output of this character is increased by a percentage.
    </>,
    renderTableStatic: (config: BrutalConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Increase:</UI.TKey>
            <UI.TVal>{config.increase}% / <UI.Stat stat="health" percentage amount={1} /></UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (skill: Brutal, UI) => <>
        <UI.TRow>
            <UI.TKey>Increase:</UI.TKey>
            <UI.TVal>{skill.totalIncrease}%</UI.TVal>
        </UI.TRow>
    </>
}
