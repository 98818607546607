import { CursedWeaponsEffect } from 'game/extended/creatures/goblins/abilities/cursed_weapons/cursed_weapons_effect'
import { EffectUI } from 'game/extended/uis/effects/EffectUI'
import React from 'react'

import image from './cursed_weapons.png'

export const cursed_weapons_effect_ui: EffectUI<'CursedWeaponsEffect'> = {
    model: CursedWeaponsEffect,
    label: 'Cursed weapon',
    image,
    renderDescription: (effect) => <>
        Your weapon damage is decreased.
    </>,
    renderTable: (effect: CursedWeaponsEffect, UI) => <>
        <UI.TRow>
            <UI.TKey>Reduction:</UI.TKey>
            <UI.TVal>{effect.reduction}%</UI.TVal>
        </UI.TRow>
    </>
}
