import { Cover, CoverConfig } from 'game/extended/heros/ranger/cover/cover'
import { cover_config } from 'game/extended/heros/ranger/cover/cover_config'
import { SkillUI } from 'game/extended/uis/skills/SkillUI'
import React from 'react'

import image from './cover.png'

export const cover_ui: SkillUI<'Cover'> = {
    model: Cover,
    configs: cover_config,
    image,
    label: 'Cover',
    renderDescription: (config: CoverConfig) => <>
        When you take damage while positioned in the front line, immediately move to the
        support line. This skill has a cooldown period before it can trigger again.
    </>,
    renderTableStatic: (config: CoverConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Cooldown period:</UI.TKey>
            <UI.TVal><UI.Turns turns={config.reload} /></UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (skill: Cover, UI) => <>
        <UI.TRow>
            <UI.TKey>Cooldown period:</UI.TKey>
            <UI.TVal><UI.Turns turns={skill.reloadInterval} /></UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Turns until ready:</UI.TKey>
            <UI.TVal><UI.Turns turns={skill.cooldownTurns} /></UI.TVal>
        </UI.TRow>
    </>
}
