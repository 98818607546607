import { lava_missions } from 'data/campaign/lava/lava_missions'
import background from 'data/campaign/lava/laval_background.png'
import { default_env } from 'game/extended/default_env'
import { items } from 'game/extended/types'
import { keysof } from 'game/util/keysof'
import { CampaignModel } from 'models/campaign/CampaignModel'
import { ChapterConfig, Difficulty } from 'models/campaign/ChapterModel'

export const lava_chapter: ChapterConfig = {
  label: 'Scorched Lands',
  background,
  missions: keysof(lava_missions),
  classes: ['barbarian', 'mage', 'paladin', 'ranger'],
  items: keysof(items),
  initial: (difficulty: Difficulty) => new CampaignModel({
    user: {
      counter: 0,
      heros: [],
      inventory: [],
      money: difficulty === 'regular' ? 250 : 200
    },
    difficulty,
    chapter: 'lava_chapter',
    missions: {}
  }, default_env).shuffleShop()
}
