import { Character, CharacterPath, Event } from 'game/core'
import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'

export class TurnEnded extends Event<{
    character: CharacterPath
}> {
    static KEY = 'TurnEnded' as const

    @Memoize()
    get character(): Nullable<Character> {
        return Character.byPath(this.battle, this.state.character)
    }
}
