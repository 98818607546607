import background from 'data/campaign/undead/undead_background.png'
import { undead_missions } from 'data/campaign/undead/undead_missions'
import { default_env } from 'game/extended/default_env'
import { items } from 'game/extended/types'
import { keysof } from 'game/util/keysof'
import { CampaignModel } from 'models/campaign/CampaignModel'
import { ChapterConfig, Difficulty } from 'models/campaign/ChapterModel'

export const undead_chapter: ChapterConfig = {
    label: 'Haunted Graveyard',
    background,
    missions: keysof(undead_missions),
    classes: ['barbarian', 'mage', 'paladin', 'ranger'],
    items: keysof(items),
    initial: (difficulty: Difficulty) => new CampaignModel({
        user: {
            counter: 0,
            heros: [],
            inventory: [],
            money: difficulty === 'regular' ? 250 : 200
        },
        difficulty,
        chapter: 'undead_chapter',
        missions: {}
    }, default_env).shuffleShop()
}
