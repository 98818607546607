import { endless_data } from 'data/endless/endless_data'
import { keyToLabel } from 'util/keyToLabel'
import { CreatureKey } from 'game/extended/types'
import { keysof } from 'game/util/keysof'
import { EndlessCreature, EndlessLevel } from 'models/endless/EndlessModel'
import { shuffle } from 'util/shuffle'

type EndlessConfig = {
  key: CreatureKey,
  points: number,
  position: 'front' | 'support' | 'random'
}

const configs: EndlessConfig[] = []
keysof(endless_data).forEach(key => {
  const conf = endless_data[key]
  if (conf === false) return
  configs.push({ key, ...conf })
})

export function generateEndlessLevel(level: number): EndlessLevel {
  const points = level < 10 ? level * 1.5 + 0.5 : 13 + (level - 10) * 3.5

  const attempts: EndlessLevel[] = []
  for (var i = 1; i <= 3; i++) {
    attempts.push(internalGenerateEndlessLevel(points))
  }
  return attempts.sort((a1, a2) => a2.points - a1.points)[0]
}

function internalGenerateEndlessLevel(maxPoints: number): EndlessLevel {
  let level: EndlessLevel = {
    points: 0,
    front: [],
    support: []
  }

  while (true) {
    if (level.front.length === 4 && level.support.length === 4) break
    const filteredConfigs = configs.filter(config => {
      if (config.points + level.points > maxPoints) return false
      if (config.position === 'support' && level.front.length === 0) return false
      if (config.position === 'front' && level.front.length > 3) return false
      if (config.position === 'support' && level.support.length > 3) return false
      return true
    })
    if (filteredConfigs.length === 0) break
    const conf = shuffle(filteredConfigs)[0]
    const creature: EndlessCreature = {
      type: conf.key,
      level: 1,
      name: keyToLabel(conf.key)
    }

    level.points += conf.points
    switch (conf.position) {
      case 'front':
        level.front.push(creature)
        break
      case 'support':
        level.support.push(creature)
        break
      case 'random':
        if (level.front.length === 4) level.support.push(creature)
        else if (level.support.length === 4) level.front.push(creature)
        else if (level.front.length < level.support.length) level.front.push(creature)
        else if (level.support.length < level.front.length) level.support.push(creature)
        else if (Math.random() > 0.5) level.front.push(creature)
        else level.support.push(creature)
        break
    }
  }

  return level
}
