import { ArcaneCurseEffect } from 'game/extended/heros/mage/arcane_curse/arcane_curse_effect'
import { EffectUI } from 'game/extended/uis/effects/EffectUI'
import React from 'react'

import image from './arcane_curse.png'

export const arcane_curse_effect_ui: EffectUI<'ArcaneCurseEffect'> = {
    model: ArcaneCurseEffect,
    label: 'Arcane Curse',
    image,
    renderDescription: (effect: ArcaneCurseEffect) => <>
        This character is only able to perform abilities with an energy cost below
        a given threshold.
    </>,
    renderTable: (effect: ArcaneCurseEffect, UI) => <>
        <UI.TRow>
            <UI.TKey>Energy threshold:</UI.TKey>
            <UI.TVal><UI.Stat stat='energy' amount={effect.maxEnergy} /></UI.TVal>
        </UI.TRow>
    </>
}
