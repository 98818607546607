import { Ability, AbilityConfig, Character, DamageNature, DamageType, FullDamage } from 'game/core'
import { ImmolationEffect } from 'game/extended/heros/mage/immolation/immolation_effect'
import { Memoize } from 'game/util/memoize'

export type ImmolationConfig = AbilityConfig & {
    reverse_damage: number,
    shield: number
}

export class Immolation extends Ability<ImmolationConfig>{

    static KEY = 'Immolation' as const
    static FIXED_REQUIREMENTS = []

    @Memoize()
    get targets(): Character {
        return this.character
    }

    @Memoize()
    get reverseDamage(): FullDamage {
        return this.calculateDamage(
            DamageType.Magic,
            DamageNature.Reverse,
            this.currentConfig.reverse_damage
        )
    }

    @Memoize()
    get shield(): number {
        return this.currentConfig.shield
    }

    performActionImpl() {
        return this.targets.addEffect(ImmolationEffect, {
            origin: this.character.path,
            reverse_damage: this.reverseDamage,
            shield: this.shield
        })
    }
}