import { Armor, ItemRarity } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './tunic.png'

export const tunic: ItemUI<Armor> = {
    image,
    label: 'Tunic',
    value: 20,
    item: {
        key: 'tunic',
        type: 'armor',
        rarity: ItemRarity.Rare,
        stats: {
            health: 20,
            agility: 5
        }
    }
}
