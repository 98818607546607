import { AdsPopup } from 'ads/ads'
import { useApp } from 'AppContext'
import * as chapters from 'data/campaign'
import { sample_campaign } from 'data/sample/sample_campaign'
import { default_env } from 'game/extended/default_env'
import { CampaignModel } from 'models/campaign/CampaignModel'
import { ChapterKey } from 'models/campaign/ChapterModel'
import { ShopOfferingID } from 'models/user/ShopModel'
import { UserModel } from 'models/user/UserModel'
import React, { createContext, Fragment, useContext, useEffect, useState } from 'react'
import { DndProvider } from 'react-dnd'
import TouchBackend from 'react-dnd-touch-backend'
import { Route, Switch, useHistory, useParams } from 'react-router'
import { CampaignUI } from 'views/ingame/campaign/CampaignUI'
import { MissionPopup } from 'views/ingame/campaign/missions/missionpopup'
import { Score } from 'views/ingame/campaign/score'
import { Tutorial, TutorialStep } from 'views/ingame/campaign/tutorial'
import { Academy } from 'views/ingame/user/academy/academy'
import { ItemsPopup } from 'views/ingame/user/shop/itemspopup'
import { Surrender } from 'views/ingame/user/surrender'
import { Teampopup } from 'views/ingame/user/team/teampopup'
import { UserContextProvider } from 'views/ingame/user/usercontext'
import { Library } from 'views/library/library'
import { LinkContext } from 'views/link'
import { Mission } from 'views/replay/mission'

export const CampaignContext = createContext<{
    campaign: CampaignModel,
    badges: BadgesState
    setCampaign: (campaign: CampaignModel, tutorialStep?: TutorialStep) => void
    setBadges: (badges: BadgesState) => void
}>(null as any)

export type BadgesState = {
    shop?: ShopOfferingID
}

export const CampaignContextProvider: React.FC<{
    campaign: CampaignModel,
    setCampaign: (campaign: CampaignModel | null, tutorialStep?: TutorialStep) => void
}> = ({ campaign, setCampaign, children }) => {
    const history = useHistory()
    const [badges, setBadges] = useState<BadgesState>({})

    return (
        <CampaignContext.Provider value={{ campaign, setCampaign, badges, setBadges }}>
            <LinkContext.Provider value={{
                processTo: to => `/campaign/${campaign.chapter.key}${to}`
            }}>
                <UserContextProvider
                    user={campaign.user}
                    setUser={user => setCampaign(campaign.updateUser(user))}
                    finalize={() => {
                        setCampaign(null)
                        history.push('/')
                    }}
                >
                    {children}
                </UserContextProvider>
            </LinkContext.Provider>
        </CampaignContext.Provider >
    )
}

export const useCampaign = () => {
    const campaignContext = useContext(CampaignContext)

    return {
        ...campaignContext,
        user: campaignContext.campaign.user,
        setUser: (user: UserModel) => campaignContext.setCampaign(campaignContext.campaign.updateUser(user))
    }
}

export const Campaign: React.FC = () => {
    const { chapterKey } = useParams<{ chapterKey: ChapterKey }>()
    const { campaign, difficulty, setCampaign } = useApp()

    useEffect(() => {
        if (campaign && campaign.chapter.key === chapterKey) return
        if (process.env.REACT_APP_CAMPAIGN === 'test') {
            const initialCampaign = new CampaignModel({ ...sample_campaign, chapter: chapterKey }, default_env)
            const mission = initialCampaign.chapter.missions[0]
            setCampaign(mission.fight().shuffleShop())
            return
        }
        const chapterConfig = chapters[chapterKey]
        setCampaign(chapterConfig.initial(difficulty))
    }, [campaign, chapterKey, difficulty, setCampaign])

    const Wrapper = chapterKey === 'tutorial_chapter' ? Tutorial : Fragment

    if (!campaign) return null
    return <DndProvider backend={TouchBackend} options={{ enableHoverOutsideTarget: true }}>
        <CampaignContextProvider
            campaign={campaign}
            setCampaign={setCampaign}
        >
            <Switch>
                <Route path="/campaign/:chapterKey/replay/:round?/:turn?/:char?/:tab?" component={Mission} />
                <Route
                    render={() => <Wrapper>
                        <CampaignUI>
                            <Switch>
                                <Route path="/campaign/:chapterKey/surrender" component={Surrender} />
                                <Route path="/campaign/:chapterKey/ad" component={AdsPopup} />
                                <Route path="/campaign/:chapterKey/score" component={Score} />
                                <Route path="/campaign/:chapterKey/library" component={Library} />
                                <Route path="/campaign/:chapterKey/team/:char/:tab?" component={Teampopup} />
                                <Route path="/campaign/:chapterKey/shop/:tab?" component={ItemsPopup} />
                                <Route path="/campaign/:chapterKey/academy" component={Academy} />
                                <Route path="/campaign/:chapterKey/mission/:mission" component={MissionPopup} />
                            </Switch>
                        </CampaignUI>
                    </Wrapper>}
                >
                </Route>
            </Switch>
        </CampaignContextProvider>
    </DndProvider>
}
