import 'views/ingame/campaign/map/gamemap.scss'

import React, { useState } from 'react'
import { useCampaign } from 'views/ingame/campaign/Campaign'
import { Leveldot } from 'views/ingame/campaign/map/leveldot'

/*const positions = [
    { x: 5.0, y: 57.8 },
    { x: 14.9, y: 54.8 },
    { x: 13.8, y: 29.6 },
    { x: 30.7, y: 10.0 },
    { x: 35.0, y: 36.2 },
    { x: 26.9, y: 62.8 },
    { x: 29.3, y: 89.4 },
    { x: 38.8, y: 83.1 },
    { x: 45.5, y: 60.5 },
    { x: 55.0, y: 58.5 },
    { x: 64.6, y: 55.1 },
    { x: 63.7, y: 29.6 },
    { x: 69.8, y: 9.3 },
    { x: 80.5, y: 10.6 },
    { x: 84.8, y: 35.9 },
    { x: 76.7, y: 62.5 },
    { x: 79.1, y: 88.7 },
    { x: 88.7, y: 82.1 },
    { x: 95.4, y: 60.1 }
]*/

export const Gamemap: React.FC = ({ children }) => {
    const { campaign } = useCampaign()
    const [loaded, setLoaded] = useState(false)

    const x_width = 100 / (campaign.chapter.missions.length + 2)
    const y_base = 60

    return <div className="gamemap">
        <img
            className="gamemap-img"
            src={campaign.chapter.background}
            alt="Levels map"
            onLoad={() => setLoaded(true)}
        />
        {loaded && <div className="gamemap-levels">
            {campaign.chapter.missions.map((mission, index) => {
                const isEven = index % 2
                const x = (index + 1.5) * x_width
                const y = y_base + (isEven ? 10 : -10)

                return <Leveldot
                    key={mission.key}
                    level={mission}
                    link={`/mission/${mission.key}`}
                    style={{
                        position: 'absolute',
                        left: `${x}%`,
                        top: `${y}%`
                    }}
                />
            })}
        </div>}
    </div>
}
