import { SkillStacksCleared } from 'game/core'
import { EventUI } from "game/extended/uis/events/EventUI"
import React from 'react'

export const skill_stacks_cleared_ui: EventUI<'SkillStacksCleared'> = {
    model: SkillStacksCleared,

    render: (event: SkillStacksCleared, UI) => {
        return <>
            All stacks for
            {' '}<UI.Skill skill={event.skill} /> were cleared.
        </>
    }
}
