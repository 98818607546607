import 'views/ui/table.scss'
import { DefaultUI as UI } from 'views/ui/default_ui'

import React from 'react'

export const Table: React.FC = ({ children }) =>
    <div className="table">
        {children}
    </div>

export const TableStat: React.FC<{ label: string }> = ({ label, children }) =>
    <div className="table_row">
        <UI.TRow>
            {label}
        </UI.TRow>
        <UI.TVal>
            {children}
        </UI.TVal>
    </div>

export const TableRow: React.FC = ({ children }) =>
    <div className="table_row">
        {children}
    </div>

export const TableKey: React.FC = ({ children }) =>
    <div className="table_key">
        {children}
    </div>

export const TableValue: React.FC = ({ children }) =>
    <div className="table_value">
        {children}
    </div>

export const TableAction: React.FC = ({ children }) =>
    <div className="table_action">
        {children}
    </div>
