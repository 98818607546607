import './badge.scss'

import React from 'react'

export const Badge: React.FC<{ amount: boolean | number }> = ({ amount }) => {
    if (!amount) return null

    return <div className="badge">
        <div className="badge-amount">
            {amount === undefined || amount === true ? null : amount}
        </div>
    </div>
}

export const BadgeFloating: React.FC<{ amount: boolean | number }> = ({ amount }) => {
    if (!amount) return null

    return <div className="badge_floating">
        <Badge amount={amount} />
    </div>
}