import {
    Ability,
    AbilityConfig,
    Character,
    DamageNature,
    DamageType,
    FullDamage,
    Percentage,
    RequiredEffect,
    Requirement,
} from 'game/core'
import { ChargedBomb } from 'game/extended/creatures/goblins/abilities/charge_bomb/charged_bomb'
import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'

export type BombTossConfig = AbilityConfig & {
    damage: number
    splash: Percentage
}

export class BombToss extends Ability<BombTossConfig>{

    static KEY = 'BombToss' as const
    static FIXED_REQUIREMENTS = [
        Requirement.init(RequiredEffect, { effect: ChargedBomb.KEY })
    ]

    @Memoize()
    get targets(): Nullable<Character> {
        return this.character.frontTarget
    }

    @Memoize()
    get allTargets(): Array<Character> {
        if (!this.targets) return []
        return [this.targets, ...this.targets.alliesArround]
    }

    @Memoize()
    get damage(): FullDamage {
        return this.calculateDamage(
            DamageType.Ranged,
            DamageNature.Melee,
            this.currentConfig.damage
        )
    }

    @Memoize()
    get splashDamage(): FullDamage {
        return this.calculateDamage(
            DamageType.Ranged,
            DamageNature.Splash,
            Math.round(this.currentConfig.damage * this.currentConfig.splash / 100)
        )
    }

    // ----- Calculate new State ----- //
    performActionImpl() {
        if (!this.targets) return this.battle

        return this.battle
            .perform(Character, this.targets.path,
                char => char.takeDamage(this.damage)
            )
            .performAll(Character, this.targets.alliesArround.map(ch => ch.path),
                char => char.takeDamage(this.splashDamage)
            )
    }
}
