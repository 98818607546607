import { Armor, ItemRarity } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './forbidden_robe.png'

export const forbidden_robe: ItemUI<Armor> = {
    image,
    label: 'Forbidden robe',
    value: 140,
    item: {
        key: 'forbidden_robe',
        type: 'armor',
        rarity: ItemRarity.Legendary,
        stats: {
            intelligence: 80,
            agility: 20,
            energy: 3,
            armor: -20,
            resistance: 25
        }
    }
}
