import { DamageType } from 'game/core'
import { BombToss, BombTossConfig } from 'game/extended/creatures/goblins/abilities/bomb_toss/bomb_toss'
import { ExplosiveArrow } from 'game/extended/heros/ranger/explosive_arrow/explosive_arrow'
import { AbilityUI } from 'game/extended/uis/abilities/AbilityUI'
import React from 'react'

import image from './explosive_arrow.png'
import { explosive_arrow_config } from 'game/extended/heros/ranger/explosive_arrow/explosive_arrow_config'

export const explosive_arrow_ui: AbilityUI<'ExplosiveArrow'> = {
    model: ExplosiveArrow,
    configs: explosive_arrow_config,
    image,
    label: 'Explosive arrow',
    renderTargets: () => 'Ranged + Surrounding',
    renderDescription: () => <>
        Fires a magical arrow towards the enemy, exploding and damaging multiple enemies on impacts.
    </>,
    renderImpression: (ability: BombToss, UI) => <>
        <UI.Char character={ability.character} />{' '}fire a magical arrow towards
        {' '}<UI.Char character={ability.targets} />{' '}, causing an vast explosion
        around the target.
    </>,
    renderTableStatic: (config: BombTossConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Damage:</UI.TKey>
            <UI.TVal><UI.Damage damage={config.damage} type={DamageType.Magic} /></UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Splash:</UI.TKey>
            <UI.TVal>{config.splash}%</UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (ability: BombToss, UI) => <>
        <UI.TRow>
            <UI.TKey>Damage:</UI.TKey>
            <UI.TVal><UI.Damage damage={ability.damage} /></UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Splash:</UI.TKey>
            <UI.TVal><UI.Damage damage={ability.splashDamage} /></UI.TVal>
        </UI.TRow>
    </>
}
