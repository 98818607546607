import { Ability, AbilityConfig, Character, FullHealing } from 'game/core'
import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'

export type ChainHealConfig = AbilityConfig & {
    targets: number
    healing: number
}

export class ChainHeal extends Ability<ChainHealConfig>{

    static KEY = 'ChainHeal' as const
    static FIXED_REQUIREMENTS = []

    @Memoize()
    get targets(): Nullable<Character> {
        const target = this.character.side.minHpCharacter
        if (target?.hpRatio === 1) return null
        return target
    }

    @Memoize()
    get healing(): FullHealing {
        return this.calculateHealing(this.currentConfig.healing)
    }

    // ----- Calculate new State ----- //
    performActionImpl() {
        if (!this.targets) return this.battle

        let target = this.targets

        for (let i = 0; i < this.currentConfig.targets; i++) {
            const ability = Ability.byPath(target.battle, this.path) as Nullable<ChainHeal>
            if (!ability) break

            const battle = target.heal(this.healing)
            const targetInNewBattle = Character.byPath(battle, target.path)

            if (!targetInNewBattle) break
            if (!targetInNewBattle.alliesArround.length) break

            const surroundingAllies = targetInNewBattle.alliesArround
            const minRatio = Math.min(...surroundingAllies.map(char => char.hpRatio))
            target = surroundingAllies.find(char => char.hpRatio === minRatio) as Character
        }

        return target.battle
    }
}
