import { Ability, AbilityConfig, Percentage } from 'game/core'
import { LifedrainEffect } from 'game/extended/creatures/undead/abilities/lifedrain/lifedrain_effect'
import { Memoize } from 'game/util/memoize'

export type LifedrainConfig = AbilityConfig & {
  initial: Percentage
  increment: Percentage
}

export class Lifedrain extends Ability<LifedrainConfig> {

  static KEY = 'Lifedrain' as const
  static FIXED_REQUIREMENTS = []

  @Memoize()
  get targets() {
    return this.character.rangedTarget
  }

  @Memoize()
  get initial(): Percentage {
    return this.currentConfig.initial
  }

  @Memoize()
  get increment(): Percentage {
    return this.currentConfig.increment
  }

  // ----- Calculate new State ----- //
  performActionImpl() {
    if (!this.targets) return this.battle
    return this.targets.addEffect(LifedrainEffect, {
      origin: this.character.path,
      ability: this.path,
      increment: this.increment,
      initial: this.initial
    })
  }
}
