import { MissionConfig } from 'models/campaign/MissionModel'

const asMissionsObjects = <T>(et: { [K in keyof T]: MissionConfig }) => et

export const goblins_missions = asMissionsObjects({
    goblin_mission_1: {
        front: [
            'goblin_axeman'
        ]
    },
    goblin_mission_2: {
        front: [
            'goblin_swordman'
        ]
    },
    goblin_mission_3: {
        front: [
            'goblin_axeman',
            'goblin_swordman'
        ]
    },
    goblin_mission_4: {
        front: [
            'goblin_swordman'
        ],
        support: [
            'goblin_bomber'
        ]
    },
    goblin_mission_5: {
        front: [
            'goblin_brute',
        ]
    },
    goblin_mission_6: {
        front: [
            'goblin_swordman',
            'goblin_axeman',
            'goblin_chief'
        ]
    },
    goblin_mission_7: {
        front: [
            'goblin_shaman',
            'goblin_shaman'
        ]
    },
    goblin_mission_8: {
        front: [
            'goblin_swordman',
            'goblin_axeman'
        ],
        support: [
            'goblin_shaman',
            'goblin_bomber'
        ]
    },
    goblin_mission_9: {
        front: [
            'goblin_brute',
            'goblin_axeman',
            'goblin_swordman'
        ],
        support: [
            'goblin_shaman'
        ]
    },
    goblin_mission_10: {
        front: [
            'goblin_axeman',
            'goblin_brute',
            'goblin_axeman'
        ],
        support: [
            'goblin_chief'
        ]
    },
    goblin_mission_11: {
        front: [
            'goblin_swordman',
            'goblin_axeman',
            'goblin_brute'
        ],
        support: [
            'goblin_bomber',
            'goblin_chief'
        ]
    },
    goblin_mission_12: {
        front: [
            'goblin_swordman',
            'goblin_chief_mounted',
            'goblin_swordman'
        ],
        support: [
            'goblin_shaman'
        ]
    },
    goblin_mission_13: {
        front: [
            'goblin_swordman',
            'goblin_axeman',
            'goblin_brute'
        ],
        support: [
            'goblin_chief_mounted',
            'goblin_shaman'
        ]
    },
    goblin_mission_14: {
        front: [
            'goblin_big_momma'
        ]
    },
    goblin_mission_15: {
        front: [
            'goblin_brute',
            'goblin_swordman',
            'goblin_shaman'
        ],
        support: [
            'goblin_chief_mounted',
            'goblin_chief_mounted'
        ]
    },
    goblin_mission_16: {
        front: [
            'goblin_swordman',
            'goblin_chief_mounted',
            'goblin_brute'
        ],
        support: [
            'goblin_shaman',
            'goblin_bomber',
            'goblin_shaman'
        ]
    },
    goblin_mission_17: {
        front: [
            'goblin_chief_mounted',
            'goblin_brute',
            'goblin_brute'
        ],
        support: [
            'goblin_shaman',
            'goblin_bomber',
            'goblin_chief_mounted'
        ]
    },
    goblin_mission_18: {
        front: [
            'goblin_swordman',
            'goblin_big_momma',
            'goblin_axeman'
        ],
        support: [
            'goblin_chief',
            'goblin_brute',
            'goblin_shaman'
        ]
    }
})
