import { CleansingLight } from 'game/extended/heros/paladin/cleansing_light/cleansing_light'
import cleansing_light_references from 'game/extended/heros/paladin/cleansing_light/cleansing_light_references'
import { Cure } from 'game/extended/heros/paladin/cure/cure'
import cure_references from 'game/extended/heros/paladin/cure/cure_references'
import { Dueller } from 'game/extended/heros/paladin/dueller/dueller'
import dueller_references from 'game/extended/heros/paladin/dueller/dueller_references'
import { EnhancedArmor } from 'game/extended/heros/paladin/enhanced_armor/enhanced_armor'
import { HolyIntervention } from 'game/extended/heros/paladin/holy_intervention/holy_intervention'
import holy_intervention_references from 'game/extended/heros/paladin/holy_intervention/holy_intervention_references'
import { Immunity } from 'game/extended/heros/paladin/immunity/immunity'
import immunity_references from 'game/extended/heros/paladin/immunity/immunity_references'
import { JudgementHammer } from 'game/extended/heros/paladin/judgement_hammer/judgement_hammer'
import judgement_hammer_references from 'game/extended/heros/paladin/judgement_hammer/judgement_hammer_references'
import penetrating_blow_references from 'game/extended/heros/paladin/penetrating_blows/penetrating_blow_references'
import { PenetratingBlows } from 'game/extended/heros/paladin/penetrating_blows/penetrating_blows'
import { SelfSacrifice } from 'game/extended/heros/paladin/self_sacrifice/self_sacrifice'
import self_sacrifice_references from 'game/extended/heros/paladin/self_sacrifice/self_sacrifice_references'
import { ShieldBlock } from 'game/extended/heros/paladin/shield_block/shield_block'
import shield_block_references from 'game/extended/heros/paladin/shield_block/shield_block_references'
import { United } from 'game/extended/heros/paladin/united/united'
import united_references from 'game/extended/heros/paladin/united/united_references'
import { ShieldPush } from 'game/extended/heros/paladin/shield_push/shield_push'

export default {
    abilities: {
        CleansingLight,
        Cure,
        HolyIntervention,
        Immunity,
        JudgementHammer,
        ShieldPush
    },
    skills: {
        PenetratingBlows,
        SelfSacrifice,
        Dueller,
        United,
        ShieldBlock,
        EnhancedArmor
    },
    events: {
        ...cleansing_light_references.events,
        ...cure_references.events,
        ...holy_intervention_references.events,
        ...immunity_references.events,
        ...penetrating_blow_references.events,
        ...self_sacrifice_references.events,
        ...dueller_references.events,
        ...united_references.events,
        ...shield_block_references.events,
        ...judgement_hammer_references.events
    },
    effects: {
        ...cleansing_light_references.effects,
        ...cure_references.effects,
        ...holy_intervention_references.effects,
        ...immunity_references.effects,
        ...penetrating_blow_references.effects,
        ...self_sacrifice_references.effects,
        ...dueller_references.effects,
        ...united_references.effects,
        ...shield_block_references.effects,
        ...judgement_hammer_references.effects
    },
    requirements: {
        ...cleansing_light_references.requirements,
        ...cure_references.requirements,
        ...holy_intervention_references.requirements,
        ...immunity_references.requirements,
        ...penetrating_blow_references.requirements,
        ...self_sacrifice_references.requirements,
        ...dueller_references.requirements,
        ...united_references.requirements,
        ...shield_block_references.requirements,
        ...judgement_hammer_references.requirements
    }
}
