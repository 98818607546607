import { EthernalFireConfig } from 'game/extended/heros/mage/ethernal_fire/ethernal_fire'

export const ethernal_fire_config: Array<EthernalFireConfig> = [
    {
        intelligence: 100,
        health: 50,
        duration: 1
    },
    {
        intelligence: 200,
        health: 100,
        duration: 2
    },
    {
        intelligence: 300,
        health: 200,
        duration: 3
    }
]