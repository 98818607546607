import { CureConfig } from 'game/extended/heros/paladin/cure/cure'

export const cure_config: Array<CureConfig> = [
    {
        available: 2,
        cooldown: 4,
        energy: 3,
        healing: 15
    },
    {
        available: 2,
        cooldown: 3,
        energy: 3,
        healing: 22
    },
    {
        available: 1,
        cooldown: 3,
        energy: 2,
        healing: 30
    }
]