import { Ability, AbilityConfig, Battle, Percentage, RequiredWeaponState, Requirement, Turns } from 'game/core'
import { BurningWeaponEffect } from 'game/extended/heros/mage/burning_weapon/burning_weapon_effect'
import { Memoize } from 'game/util/memoize'

export type BurningWeaponConfig = AbilityConfig & {
    increase: Percentage
    duration: Turns
}

export class BurningWeapon extends Ability<BurningWeaponConfig>{

    static KEY = 'BurningWeapon' as const
    static FIXED_REQUIREMENTS = [
        Requirement.init(RequiredWeaponState, { type: 'any' })
    ]

    @Memoize()
    get targets(): false {
        return false
    }

    @Memoize()
    get increase(): Percentage {
        return this.currentConfig.increase
    }

    @Memoize()
    get duration(): Turns {
        return this.currentConfig.duration
    }

    // ----- Calculate new State ----- //
    performActionImpl(): Battle {
        return this.character.addEffect(BurningWeaponEffect, {
            origin: this.character.path,
            increase: this.increase,
            duration: this.duration
        })
    }
}