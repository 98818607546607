import { AttackRange, DamageType, ItemRarity, Weapon } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './sniper_bow.png'

export const sniper_bow: ItemUI<Weapon> = {
    image,
    label: 'Sniper bow',
    value: 100,
    item: {
        key: 'sniper_bow',
        type: 'weapon',
        rarity: ItemRarity.Epic,
        stats: {},
        two_handed: true,
        category: 'bow',
        damageType: DamageType.Ranged,
        range: AttackRange.Ranged,
        energy: 8,
        damage: 20
    }
}
