import {
    Ability,
    AbilityConfig,
    calculateFullDamage,
    Character,
    DamageNature,
    FullDamage,
    RequiredWeaponState,
    Requirement,
} from 'game/core'
import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'

export type ShadowStrikeConfig = AbilityConfig

export class ShadowStrike extends Ability<ShadowStrikeConfig> {

    static KEY = 'ShadowStrike' as const
    static FIXED_REQUIREMENTS = [
        Requirement.init(RequiredWeaponState, { type: 'melee' })
    ]

    @Memoize()
    get targets(): Nullable<Character> {
        return this.character.meleeTarget
    }

    @Memoize()
    get damage(): FullDamage {
        if (!this.character.meleeWeapon) return this.nullDamage
        return calculateFullDamage(
            this.character,
            {
                origin: this.path,
                type: this.character.meleeWeapon.damageType,
                nature: DamageNature.Melee,
                amount: this.character.meleeWeapon.damage,
                original_amount: this.character.meleeWeapon.damage
            },
            { ...this.character.stats, strength: this.character.stats.strength + this.bonusStrength }
        )
    }

    @Memoize()
    get bonusStrength() {
        const totalStr = this.character.allAllies.filter(char => char !== this.character)
            .reduce((acc, char) => acc + char.stats.strength, 0)
        return Math.ceil(totalStr / 2)
    }

    // ----- Calculate new State ----- //
    performActionImpl() {
        if (!this.targets) return this.battle
        return this.targets
            .takeDamage(this.damage)
    }
}