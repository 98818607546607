import { Requirement } from 'game/core/requirement'
import { RequiredWeaponState } from 'game/core/requirements'
import { Memoize } from 'game/util/memoize'

import { WeaponAttack, WeaponAttackConfig } from './weapon_attack'

export type SecondaryWeaponAttackConfig = WeaponAttackConfig

export class SecondaryWeaponAttack extends WeaponAttack {

    static KEY = 'SecondaryWeaponAttack' as const
    static FIXED_REQUIREMENTS = [
        Requirement.init(RequiredWeaponState, { type: 'secondary' }),
    ]

    @Memoize()
    get weapon() {
        return this.character.secondaryWeapon
    }
}
