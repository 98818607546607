import { Armor, ItemRarity } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './viking_armor.png'

export const viking_armor: ItemUI<Armor> = {
    image,
    label: 'Viking armor',
    value: 65,
    item: {
        key: 'viking_armor',
        type: 'armor',
        rarity: ItemRarity.Rare,
        stats: {
            agility: 20,
            strength: 40,
            armor: 20,
            shielding: 10
        }
    }
}
