import { ExplosiveArrowConfig } from 'game/extended/heros/ranger/explosive_arrow/explosive_arrow'

export const explosive_arrow_config: Array<ExplosiveArrowConfig> = [
    {
        available: 2,
        cooldown: 5,
        damage: 10,
        energy: 5,
        splash: 40
    },
    {
        available: 2,
        cooldown: 5,
        damage: 15,
        energy: 5,
        splash: 50
    },
    {
        available: 2,
        cooldown: 4,
        damage: 20,
        energy: 6,
        splash: 60
    }
]