import { Event } from 'game/core'
import { Effect, EffectPath } from 'game/core/effect'
import { StatsKey } from 'game/core/type'
import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'

export class EffectStatChanged extends Event<{
  effect: EffectPath,
  stat: StatsKey,
  previous_amount: number,
  new_amount: number
}> {
  static KEY = 'EffectStatChanged' as const

  @Memoize()
  get amountChanged(): number {
    return this.state.new_amount - this.state.previous_amount
  }

  @Memoize()
  get stat(): StatsKey {
    return this.state.stat
  }

  @Memoize()
  get effect(): Nullable<Effect> {
    return Effect.byPath(this.battle, this.state.effect)
  }
}
