import {
    Character,
    DamageNature,
    Effect,
    EffectDuration,
    EffectTriggered,
    Event,
    FullDamage,
    Interrupt,
    Stats,
    TurnEnded,
    Turns,
} from 'game/core'
import { Memoize } from 'game/util/memoize'

export class AfterburnEffect extends Effect<{
    damage: FullDamage,
    vulnerability: number,
    turns: Turns
}>{
    static KEY = 'AfterburnEffect' as const

    @Memoize()
    get duration(): EffectDuration {
        return {
            type: 'turns',
            turns: this.state.turns
        }
    }

    @Memoize()
    get damage(): FullDamage {
        return {
            ...this.state.damage,
            nature: DamageNature.Recurrent
        }
    }

    @Memoize()
    get vulnerability(): number {
        return this.state.vulnerability
    }

    // ----- Appliers ----- //
    getTargetStats(currentStats: Stats): Stats {
        return {
            ...currentStats,
            resistance: Math.max(0, currentStats.resistance - this.vulnerability)
        }
    }

    // ----- State change inquiry ----- //
    reactToEvent(event: Event): Interrupt {
        if (!(event instanceof TurnEnded)) return false
        if (event.character !== this.target) return false

        return this.battle
            .startEvent(EffectTriggered, { effect: this.path })
            .perform(Character, this.target.path, char => char.takeDamage(this.damage))
            .endEvent()
    }
}
