import { Character, Effect, EffectPath, Event } from 'game/core'
import { Memoize } from 'game/util/memoize'
import { Nullable } from 'game/util/maybe'

export class EffectAdded extends Event<{
    effect: EffectPath
}> {
    static KEY = 'EffectAdded' as const

    @Memoize()
    get character(): Nullable<Character> {
        return Character.byPath(this.battle, this.state.effect)
    }

    @Memoize()
    get effect(): Nullable<Effect> {
        return Effect.byPath(this.battle, this.state.effect)
    }
}
