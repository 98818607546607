import { CharHealed, HealingModifier } from 'game/core'
import { EventUI } from 'game/extended/uis/events/EventUI'
import { UIType } from 'game/extended/uis/UI'
import React from 'react'

export const char_healed_ui: EventUI<'CharHealed'> = {
    model: CharHealed,

    render: (event: CharHealed, UI) => {
        return <>
            <UI.Char character={event.target} />{' heals '}<UI.Healing healing={event.state.healing} />
            {` and has ${event.state.hp} hp.`}
        </>
    },
    renderDetails: (event: CharHealed, UI) => <UI.Log>
        <UI.LogMsg>
            Original healing: {event.healing.original_amount}<br />
            {event.healing.modifiers.map((mod, i) =>
                <UI.LogMod
                    char={event.healing.origin}
                    before={mod.before}
                    after={mod.after}
                    key={i}
                >
                    {renderHealingModifier(mod, UI)}
                </UI.LogMod>
            )}
            Healing applied: {event.healing.amount}
        </UI.LogMsg>
    </UI.Log>
}

const renderHealingModifier = (modifier: HealingModifier, UI: UIType) => {
    switch (modifier.type) {
        case 'effect':
            return <UI.Effect effect={modifier.origin.effect.key} />
        case 'skill':
            return <UI.Skill skill={modifier.origin.skill} />
        case 'ability':
            return <UI.Ability ability={modifier.origin} />
        case 'stat':
            return <UI.Stat stat={modifier.origin} amount={modifier.stat} />
    }
    return null
}