import './log.scss'

import { LogModType } from 'game/extended/uis/UI'
import React, { createContext, useContext, useState } from 'react'
import { styler } from 'views/layout/util/style'
import { CharacterVisual } from 'views/ui/visuals/charactervisual'

const style = styler('log', ['msg', 'children'])

export type LogContextType = {
    open: boolean,
    setOpen: (open: boolean) => void
}

export const LogContext = createContext<LogContextType>({
    open: false,
    setOpen: () => { }
})

export const Log: React.FC<{
    open?: boolean
}> = ({ open: initialOpen, children }) => {
    const [open, setOpen] = useState<boolean>(initialOpen || false)

    return <div className={style().is({ open })} >
        <LogContext.Provider value={{ open, setOpen }}>
            {children}
        </LogContext.Provider>
    </div>
}

export const LogMsg: React.FC = ({ children }) => {
    const { open, setOpen } = useContext(LogContext)

    return <div className={style.msg()} onClick={() => setOpen(!open)}>
        <div className={style.msg('pre')} >
            <div className={style.msg('pre-arrow').is({ open })} />
        </div>
        <div className={style.msg('description')} >
            {children}
        </div>
    </div>
}

export const LogChildren: React.FC = ({ children }) => {
    const { open } = useContext(LogContext)

    return <div className={style.children().is({ open })} >
        {children}
    </div>
}

export const LogMod: LogModType = ({ char, children, before, after }) => {

    return <div className="log_mod" >
        <div className="log_mod-char" >
            {char && <CharacterVisual type={char.type} />}
        </div>
        <div className="log_mod-effect" >
            {children}
        </div>
        <div className="log_mod-result" >
            {before}{' -> '}{after}
        </div>
    </div>
}