import { MissionConfig } from 'models/campaign/MissionModel'

const asMissionsObjects = <T>(et: { [K in keyof T]: MissionConfig }) => et

export const lava_missions = asMissionsObjects({
    lava_mission_1: {
        front: [
            'bat',
        ],
    },
})
