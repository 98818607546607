import 'views/ingame/user/shop/product.scss'

import { ProductModel } from 'models/user/ProductModel'
import React from 'react'
import { styler } from 'views/layout/util/style'
import { Weaponstats } from 'views/ingame/user/team/equip/weaponstats'
import { ItemStats } from 'views/ingame/user/team/itemstats'
import { ItemVisual } from 'views/ui/visuals/itemvisual'
import { Button, ButtonPrice } from 'views/layout/button'
import { useUser } from 'views/ingame/user/usercontext'
import { TutorialHint, useHinter } from 'views/ingame/campaign/tutorial'

const styles = styler('product')

export const Product: React.FC<{
    product: ProductModel
}> = ({ product }) => {
    const { setUser } = useUser()
    const hint = useHinter()
    const hinted = hint.key === 'shop' && hint.item === product.key

    const item = product.item
    return <div className={styles()}>
        {hinted && <TutorialHint />}
        <div className={styles('info')}>
            <div className={styles('label')}>
                {product.ui.label}
            </div>
            <div className={styles('top')}>
                <div className={styles('top-image')}>
                    <ItemVisual
                        item={item}
                    />
                </div>
                {item.type === 'weapon' &&
                    <div className={styles('top-stats')}>
                        <Weaponstats weapon={item} />
                    </div>
                }
            </div>
            <div className={styles('stats')}>
                <ItemStats item={item} />
            </div>
        </div>
        <div className={styles('actions')}>
            {product.amountOwned > 0 && <div className={styles('amount')}>
                Amount owned: {product.amountOwned}
            </div>}
            <Button onClick={() => {
                setUser(product.buy())
            }}>
                Buy
                <ButtonPrice
                    stat="currency"
                    amount={product.price}
                />
            </Button>
        </div>
    </div>
}
