import { Intimidate, IntimidateConfig } from 'game/extended/creatures/goblins/abilities/intimidate/intimidate'
import { intimidate_config } from 'game/extended/creatures/goblins/abilities/intimidate/intimidate_config'
import { AbilityUI } from 'game/extended/uis/abilities/AbilityUI'
import React from 'react'

import image from './intimidate.png'

export const intimidate_ui: AbilityUI<'Intimidate'> = {
    model: Intimidate,
    configs: intimidate_config,
    image,
    label: 'Intimidate',
    renderTargets: (config: IntimidateConfig) => `Weakest enemy`,
    renderDescription: () => <>
        Finds the weakest enemy and paralyzes it with fear.
        Only enemies below a certain health percentage are susceptible.
    </>,
    renderImpression: (ability: Intimidate, UI) => <>
        With terrible screams and sounds{' '}<UI.Char character={ability.targets} />{' '} is left paralyzed.
    </>,
    renderTableStatic: (config: IntimidateConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Health percentage:</UI.TKey>
            <UI.TVal><UI.Stat stat='health' amount={config.minimum_hp} percentage /></UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (ability: Intimidate, UI) => <>
        <UI.TRow>
            <UI.TKey>Health percentage:</UI.TKey>
            <UI.TVal><UI.Stat stat='health' amount={ability.minimumHp} percentage /></UI.TVal>
        </UI.TRow>
    </>
}
