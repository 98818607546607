import { Boots, ItemRarity } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './royal_guard_boots.png'

export const royal_guard_boots: ItemUI<Boots> = {
    image,
    label: 'Royal guard boots',
    value: 135,
    item: {
        key: 'royal_guard_boots',
        type: 'boots',
        rarity: ItemRarity.Epic,
        stats: {
            intelligence: 40,
            agility: 40,
            strength: 40,
            armor: 20,
            resistance: 20,
            energy: 5
        }
    }
}
