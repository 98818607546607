import { Condition, Line } from 'game/core'
import { Memoize } from 'game/util/memoize'
import { UnreachableCaseError } from 'game/util/never'

export type WhenCrowdLine = 'allied_front' | 'allied_support' | 'enemy_front' | 'enemy_support'
export type WhenCrowdDirection = 'below' | 'exactly' | 'above'

export type WhenCrowdState = {
    line: WhenCrowdLine
    direction: WhenCrowdDirection
    amount: number
}

export class WhenCrowd extends Condition<WhenCrowdState> {

    static KEY = 'WhenCrowd' as const

    @Memoize()
    get amount(): number {
        return this.state.amount
    }

    @Memoize()
    get isBlocking(): boolean {
        switch (this.state.direction) {
            case 'above':
                return this.line.characters.length <= this.amount
            case 'exactly':
                return this.line.characters.length !== this.amount
            case 'below':
                return this.line.characters.length >= this.amount
            default:
                throw new UnreachableCaseError(this.state.direction)
        }
    }

    @Memoize()
    get line(): Line {
        switch (this.state.line) {
            case 'allied_front':
                return this.character.side.front
            case 'allied_support':
                return this.character.side.support
            case 'enemy_front':
                return this.character.side.enemySide.front
            case 'enemy_support':
                return this.character.side.enemySide.support
            default:
                throw new UnreachableCaseError(this.state.line)
        }
    }
}
