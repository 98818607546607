import { AttackRange, DamageType, ItemRarity, Weapon } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './skeleton_sword.png'

export const skeleton_sword: ItemUI<Weapon> = {
  image,
  label: 'Skeleton sword',
  value: 35,
  item: {
    key: 'skeleton_sword',
    type: 'weapon',
    rarity: ItemRarity.Common,
    stats: {
      health: 15,
      resistance: -15
    },
    two_handed: false,
    category: 'sword',
    damageType: DamageType.Melee,
    range: AttackRange.Melee,
    energy: 3,
    damage: 10
  }
}
