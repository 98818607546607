import { CharDied } from 'game/core'
import { EventUI } from "game/extended/uis/events/EventUI"
import React from 'react'

export const char_died_ui: EventUI<'CharDied'> = {
    model: CharDied,

    render: (event: CharDied, UI) => {
        return <>
            <UI.Char character={event.state.character} /> is dead.
        </>
    }
}
