import { CharDamaged, Event, Interrupt, Skill, SkillConfig, TurnEnded, Turns } from 'game/core'
import { Memoize } from 'game/util/memoize'

export interface CoverConfig extends SkillConfig {
    reload: Turns
}

export interface CoverState {
    cooldownTurns?: Turns
}

export class Cover extends Skill<CoverState, CoverConfig>{

    static KEY = 'Cover' as const

    @Memoize()
    get reloadInterval(): Turns {
        return this.currentConfig.reload
    }

    @Memoize()
    get cooldownTurns(): Turns {
        return this.state.cooldownTurns || 0
    }

    // ----- Interrupts ----- //
    reactToEvent(event: Event): Interrupt {
        if (event instanceof TurnEnded) {
            if (event.character !== this.character) return false
            return this.update({ cooldownTurns: Math.max(0, this.cooldownTurns - 1) })
        }

        if (event instanceof CharDamaged) {
            if (event.target !== this.character) return false
            if (this.cooldownTurns > 0) return false
            if (!this.character.canMoveBack) return false
            return this.character
                .moveToSupport()
                .perform(Cover, this.path, (cover: Skill) =>
                    (cover as Cover).update({ cooldownTurns: this.reloadInterval })
                )
        }

        return false
    }
}
