import { Prickly } from 'game/extended/creatures/tutorial/abilities/prickly/prickly'
import { Pushback } from 'game/extended/creatures/tutorial/abilities/pushback/pushback'
import { bully } from 'game/extended/creatures/tutorial/bully/bully'
import { chicky } from 'game/extended/creatures/tutorial/chicky/chicky'
import { hedgy } from 'game/extended/creatures/tutorial/hedgy/hedgy'
import { Explosive } from './abilities/explosive/explosive'
import { Shell } from './abilities/shell/shell'
import { blowfishy } from './blowfishy/blowfishy'
import { turtly } from './turtly/turtly'

export default {
  types: {
    blowfishy,
    turtly,
    bully,
    chicky,
    hedgy
  },
  abilities: {
    Pushback
  },
  skills: {
    Prickly,
    Explosive,
    Shell
  },
  events: {
  },
  effects: {
  }
}
