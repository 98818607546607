import { ConditionState } from 'game/extended/types'
import { getConditionUI } from 'game/extended/uis/conditions/condition_uis'
import { Info, InfoCols, InfoDescription, InfoLabel, InfoVisual } from 'views/layout/info'
import React from 'react'
import { ConditionVisual } from 'views/ui/visuals/conditionvisual'

export const Conditioninfo: React.FC<{
    state: ConditionState
    update: (newState: ConditionState) => void
}> = ({ state, update }) => {

    const ui = getConditionUI(state.key)
    return <Info>
        <InfoLabel>{ui.label}</InfoLabel>
        <InfoCols>
            <InfoVisual>
                <ConditionVisual condition={state.key} />
            </InfoVisual>
            <InfoDescription>
                {ui.renderDescription(state)}
            </InfoDescription>
        </InfoCols>
        <InfoCols>
            {ui.renderOptions(state, update)}
        </InfoCols>
    </Info>
}
