import { ItemRarity, Trinket } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './magic_ring.png'

export const magic_ring: ItemUI<Trinket> = {
    image,
    label: 'Magic ring',
    value: 70,
    item: {
        key: 'magic_ring',
        type: 'trinket',
        rarity: ItemRarity.Epic,
        stats: {
            intelligence: 35,
            resistance: 30,
            health: 25
        }
    }
}
