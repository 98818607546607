import './ranking.scss'

import { useApp } from 'AppContext'
import * as chapters from 'data/campaign'
import { ChapterKey, Difficulty } from 'models/campaign/ChapterModel'
import React from 'react'
import { Popup, PopupContent, PopupFullHeader } from 'views/layout/popup'
import { useLinker } from 'views/link'
import { Icon } from 'views/ui/icon'

export const Ranking: React.FC = () => {
  const { goTo } = useLinker()

  return <Popup
    onClose={() => goTo('')}
  >
    <PopupFullHeader mod="large">
      <Icon icon="trophy" /> Ranking <Icon icon="trophy" />
    </PopupFullHeader>
    <PopupContent>
      <div className="ranking">
        <RankingEndless />
        <RankingChapter chapterKey="goblins_chapter" />
        <RankingChapter chapterKey="undead_chapter" />
      </div>
    </PopupContent>
  </Popup >
}

export const RankingColumn: React.FC<{
  label: string
}> = ({ label, children }) => {

  return <div className="ranking_column">
    <div className="ranking_column-label">
      {label}
    </div>
    {children}
  </div>
}

export const RankingEndless: React.FC = () => {
  const { ranking } = useApp()

  return <RankingColumn label="Endless Mode">
    <div className="ranking_endless">
      <div className="ranking_endless-label">
        Levels finished:
      </div>
      <div className="ranking_endless-result">
        {ranking.endlessResult.levels}
      </div>
    </div>
  </RankingColumn>
}

export const RankingChapter: React.FC<{
  chapterKey: ChapterKey
}> = ({ chapterKey }) => {
  const chapter = chapters[chapterKey]

  return <RankingColumn label={chapter.label}>
    <div className="ranking_chapter">
      <RankingResult chapterKey={chapterKey} difficulty="regular" />
      <RankingResult chapterKey={chapterKey} difficulty="hard" />
      <RankingResult chapterKey={chapterKey} difficulty="insane" />
    </div>
  </RankingColumn>
}

export const RankingResult: React.FC<{
  chapterKey: ChapterKey,
  difficulty: Difficulty
}> = ({ chapterKey, difficulty }) => {
  const { ranking } = useApp()
  const result = ranking.getBestResultForChapter(chapterKey, difficulty)

  return <div className="ranking_result">
    <Icon icon={difficulty} />
    {result ? result.score : 'n / a'}
  </div>
}