import { UndyingFury, UndyingFuryConfig } from 'game/extended/heros/barbarian/undying_fury/undying_fury'
import image from 'game/extended/heros/barbarian/undying_fury/undying_fury.png'
import { AbilityUI } from 'game/extended/uis/abilities/AbilityUI'
import React from 'react'
import { undying_fury_config } from 'game/extended/heros/barbarian/undying_fury/undying_fury_config'

export const undying_fury_ui: AbilityUI<'UndyingFury'> = {
    model: UndyingFury,
    configs: undying_fury_config,
    image,
    label: 'Undying fury',
    renderTargets: () => 'Yourself',
    renderDescription: () => <>
        All incoming damage are reduced to 1 for a certain duration.
    </>,
    renderImpression: (ability: UndyingFury, UI) => <>
        <UI.Char character={ability.character} />{' '}goes into a wild frenzy, becoming virtually immune to any damage.
    </>,
    renderTableStatic: (config: UndyingFuryConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Duration</UI.TKey>
            <UI.TVal><UI.Turns turns={config.duration} /></UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (skill: UndyingFury, UI) => <>
        <UI.TRow>
            <UI.TKey>Duration</UI.TKey>
            <UI.TVal><UI.Turns turns={skill.duration} /></UI.TVal>
        </UI.TRow>
    </>
}
