import full from 'game/extended/creatures/goblins/goblin_shaman/goblin_shaman.svg'
import circle from 'game/extended/creatures/goblins/goblin_shaman/goblin_shaman_circle.svg'
import head from 'game/extended/creatures/goblins/goblin_shaman/goblin_shaman_head.svg'
import { CreatureUI } from 'game/extended/uis/types/creature_uis'

export const goblin_shaman: CreatureUI = {
    label: 'Goblin shaman',
    definition: lvl => ({
        stats: {
            health: 100,
            energy: 20 + lvl * 2,
            intelligence: 100 + 15 * lvl,
            strength: 30,
            agility: 30 + lvl * 10,
            armor: 20,
            shielding: 10,
            resistance: 30
        },
        equipment: {
            primary: 'shaman_wand',
            boots: 'shaman_boots'
        },
        skills: [
            'Reincarnation'
        ],
        strategy: [
            'MagicInterrupt',
            'ChainLightning',
            'ChainHeal',
            'PrimaryWeaponAttack',
            ['Rest', 'Rest']
        ]
    }),
    full,
    circle: {
        src: circle,
        width: 200,
        height: 150
    },
    head
}
