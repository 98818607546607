import { Damage, Percentage, Skill } from 'game/core'
import { Memoize } from 'game/util/memoize'

export type BrutalConfig = {
    increase: Percentage
}

export class Brutal extends Skill<{}, BrutalConfig> {

    static KEY = 'Brutal' as const

    @Memoize()
    get totalIncrease(): Percentage {
        return (this.character.maxHp - this.character.hp) * this.currentConfig.increase
    }

    // ----- Appliers ----- //
    applyDamageModifier(damage: Damage): Damage {
        return {
            ...damage,
            amount: Math.round(damage.amount * (100 + this.totalIncrease) / 100)
        }
    }
}  