import { BurningWeaponConfig } from 'game/extended/heros/mage/burning_weapon/burning_weapon'

export const burning_weapon_config: Array<BurningWeaponConfig> = [
    {
        available: 1,
        cooldown: 4,
        duration: 2,
        energy: 2,
        increase: 50
    },
    {
        available: 1,
        cooldown: 3,
        duration: 3,
        energy: 2,
        increase: 100
    },
    {
        available: 1,
        cooldown: 4,
        duration: 4,
        energy: 3,
        increase: 150
    }
]