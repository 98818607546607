import { Environment } from 'game/core'
import { registerAbilities } from 'game/extended/uis/abilities/ability_uis'
import { registerEffects } from 'game/extended/uis/effects/effect_uis'
import { registerSkills } from 'game/extended/uis/skills/skill_uis'

import { registerConditions } from './uis/conditions/condition_uis'
import { registerEvents } from './uis/events/event_uis'
import { registerRequirements } from './uis/requirements/requirement_uis'

const env = new Environment()
registerAbilities(env)
registerSkills(env)
registerConditions(env)
registerRequirements(env)
registerEvents(env)
registerEffects(env)

export const default_env = env
