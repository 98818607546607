import { Rest } from 'game/core'
import image from 'game/extended/core/abilities/rest/rest.png'
import { AbilityUI } from 'game/extended/uis/abilities/AbilityUI'
import React from 'react'

export const rest_ui: AbilityUI<'Rest'> = {
    model: Rest,
    configs: [{
        available: 0,
        recovery: 3,
        cooldown: 0,
        energy: 0
    }],
    image,
    label: 'Rest',
    renderTargets: () => 'Yourself',
    renderDescription: () => <>
        Do not perform any action in order to recover energy.
    </>,
    renderImpression: (ability: Rest, UI) => <>
        <UI.Char character={ability.character} />{' '}takes a moment to catch {ability.character.isMale ? 'his' : 'her'} breath and recover some energy.
    </>,
    renderTableStatic: (conf, UI) => <>
        <UI.TRow>
            <UI.TKey>Energy recovered:</UI.TKey>
            <UI.TVal>
                <UI.Stat stat="energy" amount={conf.recovery} />
            </UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (ability, UI) => <>f
        <UI.TRow>
            <UI.TKey>Energy recovered:</UI.TKey>
            <UI.TVal>
                <UI.Stat stat="energy" amount={ability.recovery} />
            </UI.TVal>
        </UI.TRow>
    </>
}
