import { SkillKey } from 'game/extended/types';
import { getSkillUI } from 'game/extended/uis/skills/skill_uis';
import React from 'react';
import { DOMAttrs, Mod } from 'views/ui/ui_types';
import { Visual, VisualAttrs, VisualImage } from 'views/ui/visual';

export const SkillVisual: React.FC<{
    skill: SkillKey | null
    mod?: Mod<'highlight'>
    filled?: number
} & VisualAttrs & DOMAttrs> = attrs => {

    const {skill, mod, filled, children, ...rest} = attrs

    if(!skill) return <Visual {...rest} />

    const ui = getSkillUI(skill)
    return <Visual {...rest}>
        <VisualImage image={ui.image} />
        {children}
    </Visual>
}
