import { RampageConfig } from 'game/extended/heros/barbarian/rampage/rampage'

export const rampage_config: Array<RampageConfig> = [
    {
        hp: 8,
        energy: 3
    },
    {
        hp: 11,
        energy: 4
    },
    {
        hp: 16,
        energy: 4
    }
]