import { ImmunityEffect } from 'game/extended/heros/paladin/immunity/immunity_effect'
import { EffectUI } from 'game/extended/uis/effects/EffectUI'
import React from 'react'

import image from './immunity.png'

export const immunity_effect_ui: EffectUI<'ImmunityEffect'> = {
    model: ImmunityEffect,
    label: 'Immunity effect',
    image,
    renderDescription: (effect: ImmunityEffect, UI) => <>
        You are immune to any incoming damage.
    </>
}
