import { ItemRarity, Trinket } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './quiver.png'

export const quiver: ItemUI<Trinket> = {
    image,
    label: 'Arrow quiver',
    value: 15,
    item: {
        key: 'quiver',
        type: 'trinket',
        rarity: ItemRarity.Common,
        stats: {
            agility: 25
        }
    }
}
