import { EquipmentKey } from 'game/core'
import { Nullable } from 'game/util/maybe'
import { TurnChar } from 'models/replay/TurnChar'
import React, { useState } from 'react'
import { Equipslot } from 'views/ingame/user/team/equip/equipslot'
import { ItemInfo } from 'views/ingame/user/team/iteminfo'
import { Popuptab } from 'views/ingame/user/team/popuptab'
import { Sidebar, SidebarPreview, SidebarTitle } from 'views/layout/sidebar'

export const CharEquipment: React.FC<{
    char: TurnChar
}> = ({ char }) => {
    const [activeKey, setActiveKey] = useState<Nullable<EquipmentKey>>()
    const sidebarItem = activeKey && char.equipment[activeKey]
    const itemAttrs = (key: EquipmentKey) => ({
        slot: key,
        item: char.equipment[key],
        active: false,
        onClick: () => {
            setActiveKey(key === activeKey ? null : key)
        }
    })

    return <Popuptab>
        <Sidebar hidden={!activeKey} onhide={() => setActiveKey(null)}>
            <SidebarTitle>
                Item details
            </SidebarTitle>
            {sidebarItem && <SidebarPreview
                onclose={() => setActiveKey(null)}
            >
                <ItemInfo item={sidebarItem} />
            </SidebarPreview>}
        </Sidebar>
        <div className="equiptab-items">
            <div className="equiptab-items-stub" />
            <Equipslot {...itemAttrs('headgear')} label="Headgear" />
            <div className="equiptab-items-stub" />
            <Equipslot
                {...itemAttrs('primary')}
                label="Primary weapon"
            />
            <Equipslot {...itemAttrs('armor')} label="Body armor" />
            <Equipslot
                {...itemAttrs('secondary')}
                label="Secondary weapon"
                locked={char.equipment.primary?.two_handed}
            />
            <div className="equiptab-items-stub" />
            <Equipslot {...itemAttrs('boots')} label="Boots" />
            <Equipslot {...itemAttrs('trinket')} label="Trinket" />
        </div>
    </Popuptab>
}
