import { ArcaneGrowthEffect } from 'game/extended/heros/mage/arcane_growth/arcane_growth_effect'
import { EffectUI } from 'game/extended/uis/effects/EffectUI'
import React from 'react'

import image from './arcane_growth.png'

export const arcane_growth_effect_ui: EffectUI<'ArcaneGrowthEffect'> = {
    model: ArcaneGrowthEffect,
    label: 'Arcane growth bonus',
    image,
    renderDescription: (effect: ArcaneGrowthEffect) => <>
        Your intelligence is increased and each additional stack will further increase this.
    </>,
    renderTable: (effect: ArcaneGrowthEffect, UI) => <>
        <UI.TRow >
            <UI.TKey>Stacks: </UI.TKey>
            <UI.TVal>{effect.stacks}</UI.TVal>
        </UI.TRow>
        <UI.TRow >
            <UI.TKey>Increase: </UI.TKey>
            <UI.TVal><UI.Stat stat='intelligence' amount={effect.increase} /></UI.TVal>
        </UI.TRow>
    </>
}
