import { Headgear, ItemRarity } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './agile_hat.png'

export const agile_hat: ItemUI<Headgear> = {
    image,
    label: 'Agile hat',
    value: 30,
    item: {
        key: 'agile_hat',
        type: 'headgear',
        rarity: ItemRarity.Common,
        stats: {
            agility: 30,
            health: 10
        }
    }
}
