import { Weapon, weaponCategories, Wearable } from 'game/core'
import { ItemKey, items } from 'game/extended/types'
import { ItemUI } from 'game/extended/uis/item_uis'
import { Memoize } from 'game/util/memoize'
import { ProductModel, ProductState } from 'models/user/ProductModel'
import { UserModel } from 'models/user/UserModel'
import { shuffle } from 'util/shuffle'

export interface ShopState {
    products: Array<ProductState>
    offering: number
}

export type ShopOfferingID = number

export class ShopModel {
    constructor(readonly user: UserModel, readonly state: ShopState) { }

    @Memoize()
    get products(): Array<ProductModel> {
        return this.state.products.map(state => new ProductModel(this.user, state))
    }

    @Memoize()
    get weaponProducts(): Array<ProductModel & { item: Weapon }> {
        return this.products.filter(p => p.item.type === 'weapon') as any
    }

    @Memoize()
    get offeringID(): ShopOfferingID {
        return this.state.offering
    }

    @Memoize()
    get wearableProducts(): Array<ProductModel & { item: Wearable }> {
        return this.products.filter(p => p.item.type !== 'weapon') as any
    }

    // ----- State change ----- //
    update(newState: Partial<ShopState>): UserModel {
        return this.user.update({
            shop: { ...this.state, ...newState, offering: this.state.offering + 1 },
        })
    }

    shuffle(): UserModel {
        const availableKeys: Array<ItemKey> = []
        const potentialItems: Array<ItemKey> = this.user.config.items
        const allItems: Array<ItemUI> = potentialItems.map(key => items[key])

        const itemsShuffled = shuffle(allItems)

        //Add 1 type of each weapon
        weaponCategories.forEach(category => {
            const affordableItem = itemsShuffled.find(item =>
                item.item.type === 'weapon' &&
                item.item.category === category &&
                item.value <= this.user.money
            )
            if (affordableItem) {
                availableKeys.push(affordableItem.item.key as ItemKey)
            } else {
                const item = itemsShuffled.find(item =>
                    item.item.type === 'weapon' &&
                    item.item.category === category
                )
                if (item) availableKeys.push(item.item.key as ItemKey)
            }
        })

        //Add 1 type of each wearable
        const wearableTypes = ['armor', 'headgear', 'trinket', 'boots', 'shield']
        wearableTypes.forEach(type => {
            const item = itemsShuffled.find(item =>
                item.item.type === type &&
                item.value <= this.user.money
            )
            if (item) availableKeys.push(item.item.key as ItemKey)
        })

        return this.update({
            products: availableKeys.map(key => ({
                key: key,
                discount: false,
                sold: false
            }))
        })
    }
}
