import { CleansingLightConfig } from 'game/extended/heros/paladin/cleansing_light/cleansing_light'

export const cleansing_light_config: Array<CleansingLightConfig> = [
    {
        available: 2,
        cooldown: 5,
        energy: 4
    },
    {
        available: 1,
        cooldown: 3,
        energy: 3
    },
    {
        available: 0,
        cooldown: 2,
        energy: 2
    }
]