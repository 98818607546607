import { Environment } from 'game/core'
import { required_amount_allies_ui } from 'game/extended/core/requirements/required_amount_allies_ui'
import { required_cooldown_ui } from 'game/extended/core/requirements/required_cooldown_ui'
import { required_effect_ui } from 'game/extended/core/requirements/required_effect_ui'
import { required_energy_ui } from 'game/extended/core/requirements/required_energy_ui'
import { required_mobility_ui } from 'game/extended/core/requirements/required_mobility_ui'
import { required_no_blocking_modifier_ui } from 'game/extended/core/requirements/required_no_blocking_modifier_ui'
import { required_position_ui } from 'game/extended/core/requirements/required_position_ui'
import { required_room_ui } from 'game/extended/core/requirements/required_room_ui'
import { required_valid_target_ui } from 'game/extended/core/requirements/required_valid_target_ui'
import { required_weapon_state_ui } from 'game/extended/core/requirements/required_weapon_state_ui'
import { RequirementKey, requirements } from 'game/extended/types'
import { keysof } from 'game/util/keysof'

import { RequirementUI } from './RequirementUI'

const requirement_uis: {
    [K in RequirementKey]: RequirementUI<K>
} = {
    RequiredCooldown: required_cooldown_ui,
    RequiredEnergy: required_energy_ui,
    RequiredMobility: required_mobility_ui,
    RequiredPosition: required_position_ui,
    RequiredWeaponState: required_weapon_state_ui,
    RequiredEffect: required_effect_ui,
    RequiredValidTarget: required_valid_target_ui,
    RequiredRoom: required_room_ui,
    RequiredAmountAllies: required_amount_allies_ui,
    RequiredNoBlockingModifier: required_no_blocking_modifier_ui
}

export function registerRequirements(env: Environment) {
    keysof(requirements).forEach(key => {
        env.registerModel(requirements[key].KEY, requirements[key] as any, {})
    })
}

export function getRequirementUI(key: RequirementKey): RequirementUI<any> {
    return requirement_uis[key]
}