import { United, UnitedConfig } from 'game/extended/heros/paladin/united/united'
import { united_config } from 'game/extended/heros/paladin/united/united_config'
import { SkillUI } from 'game/extended/uis/skills/SkillUI'
import React from 'react'

import image from './united.png'

export const united_ui: SkillUI<'United'> = {
    model: United,
    configs: united_config,
    image,
    label: 'United',
    renderDescription: (config: UnitedConfig) => <span>
        Whenever an ally is healed, gain a united-stack. Each stack increases your
        strength. When an ally dies, all stacks are cleared.
    </span>,
    renderTableStatic: (config: UnitedConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Increase:</UI.TKey>
            <UI.TVal><UI.Stat stat='strength' amount={config.increase} /></UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (skill: United, UI) => <>
        <UI.TRow>
            <UI.TKey>Increase:</UI.TKey>
            <UI.TVal><UI.Stat stat='strength' amount={skill.increase} /></UI.TVal>
        </UI.TRow>
    </>
}
