import { Ability, AbilityConfig, Character, DamageNature, DamageType, FullDamage } from 'game/core'
import { Memoize } from 'game/util/memoize'

export type PushbackConfig = AbilityConfig & {
    damage: number
}

export class Pushback extends Ability<PushbackConfig>{

    static KEY = 'Pushback' as const
    static FIXED_REQUIREMENTS = []

    @Memoize()
    get targets() {
        return this.character.meleeTarget
    }

    @Memoize()
    get damage(): FullDamage {
        return this.calculateDamage(
            DamageType.Melee,
            DamageNature.Melee,
            this.currentConfig.damage
        )
    }

    // ----- Calculate new State ----- //
    performActionImpl() {
        if (!this.targets) return this.battle
        return this.targets
            .takeDamage(this.damage)
            .perform(Character, this.targets.path, char => char.moveToSupport())
    }
}


