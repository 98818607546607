import { Condition } from 'game/core'
import { Memoize } from 'game/util/memoize'

export type WhenSequenceReadyState = {}

export class WhenSequenceReady extends Condition<WhenSequenceReadyState> {

  public static KEY = 'WhenSequenceReady' as const

  @Memoize()
  get isBlocking() {
    if (!this.rule.abilities) return true
    if (this.rule.abilities[0].hasFailingReq) return true
    const failingAbility = this.rule.abilities.find((ability, index) =>
      ability.cooldownLeft > index
    )
    return !!failingAbility
  }
}
