import { Armor, ItemRarity } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './fire_vest.png'

export const fire_vest: ItemUI<Armor> = {
    image,
    label: 'Fire vest',
    value: 85,
    item: {
        key: 'fire_vest',
        type: 'armor',
        rarity: ItemRarity.Epic,
        stats: {
            intelligence: 40,
            armor: 25,
            resistance: 20
        }
    }
}
