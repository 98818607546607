import { Ability, AbilityConfig, Character, DamageNature, DamageType, FullDamage } from 'game/core'
import { Memoize } from 'game/util/memoize'

export type FireballConfig = AbilityConfig & {
    damage: number
    splash: boolean
}

export class Fireball extends Ability<FireballConfig>{

    static KEY = 'Fireball' as const
    static FIXED_REQUIREMENTS = []

    @Memoize()
    get targets(): Array<Character> {
        if (this.currentConfig.splash) {
            return [this.character.frontTarget, this.character.supportTarget]
                .filter(char => char) as Array<Character>
        }

        if (this.character.rangedTarget) return [this.character.rangedTarget]
        if (this.character.frontTarget) return [this.character.frontTarget]
        return []
    }

    @Memoize()
    get damage(): FullDamage {
        return this.calculateDamage(
            DamageType.Magic,
            DamageNature.Ranged,
            this.currentConfig.damage
        )
    }

    // ----- Calculate new State ----- //
    performActionImpl() {
        return this.performAll(
            Character,
            this.targets.map(ch => ch.path),
            char => char.takeDamage(this.damage)
        )
    }
}
