import { Character, CharacterPath, Event } from 'game/core'
import { Memoize } from 'game/util/memoize'
import { Nullable } from 'game/util/maybe'

export class CharEnergyChanged extends Event<{
    character: CharacterPath,
    previous_energy: number,
    new_energy: number
}> {
    static KEY = 'CharEnergyChanged' as const

    @Memoize()
    get energyChange(): number {
        return this.state.new_energy - this.state.previous_energy
    }

    @Memoize()
    get character(): Nullable<Character> {
        return Character.byPath(this.battle, this.state.character)
    }
}
