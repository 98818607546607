import { Lifedrain, LifedrainConfig } from 'game/extended/creatures/undead/abilities/lifedrain/lifedrain'
import { AbilityUI } from 'game/extended/uis/abilities/AbilityUI'
import React from 'react'

import image from './lifedrain.png'

export const lifedrain_ui: AbilityUI<'Lifedrain'> = {
  model: Lifedrain,
  configs: [
    {
      available: 3,
      cooldown: 8,
      energy: 6,
      increment: 2,
      initial: 3
    }
  ],
  image,
  label: 'Lifedrain',
  renderTargets: () => 'Ranged',
  renderDescription: () => <>
    Initialize a lifedrain effect on your target. The target takes damage for a percentage of his own
    max hp. The damage grows bigger each turn and is bestowed as healing upon the casters front allies.
  </>,
  renderImpression: (ability: Lifedrain, UI) => <>
    <UI.Char character={ability.character} />{' '}channels a dark enchantment which will slowly
    drain all the life from <UI.Char character={ability.targets} />.
  </>,
  renderTableStatic: (config: LifedrainConfig, UI) => <>
    <UI.TRow>
      <UI.TKey>Duration:</UI.TKey>
      <UI.TVal>Infinite</UI.TVal>
    </UI.TRow>
    <UI.TRow>
      <UI.TKey>Initial:</UI.TKey>
      <UI.TVal>{config.initial}% of maxhp</UI.TVal>
    </UI.TRow>
    <UI.TRow>
      <UI.TKey>Increment:</UI.TKey>
      <UI.TVal>{config.increment}% of maxhp</UI.TVal>
    </UI.TRow>
  </>,
  renderTable: (ability: Lifedrain, UI) => <>
    <UI.TRow>
      <UI.TKey>Duration:</UI.TKey>
      <UI.TVal>Infinite</UI.TVal>
    </UI.TRow>
    <UI.TRow>
      <UI.TKey>Initial:</UI.TKey>
      <UI.TVal>{ability.initial}% of maxhp</UI.TVal>
    </UI.TRow>
    <UI.TRow>
      <UI.TKey>Increment:</UI.TKey>
      <UI.TVal>{ability.increment}% of maxhp</UI.TVal>
    </UI.TRow>
  </>
}
