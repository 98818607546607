import 'views/ingame/user/team/abilities/strategytab.scss'

import { Nullable } from 'game/util/maybe'
import { HeroModel } from 'models/user/hero/HeroModel'
import { AbilitySlot } from 'models/user/hero/strategy/AbilitySlot'
import { ConditionSlot } from 'models/user/hero/strategy/ConditionSlot'
import { StrategyLine } from 'models/user/hero/strategy/StrategyLine'
import React, { useEffect, useState } from 'react'
import { TutorialHint, useHinter } from 'views/ingame/campaign/tutorial'
import { Abilityinfo } from 'views/ingame/user/team/abilities/abilityinfo'
import { Conditioninfo } from 'views/ingame/user/team/abilities/conditioninfo'
import { Popuptab } from 'views/ingame/user/team/popuptab'
import { useUser } from 'views/ingame/user/usercontext'
import { Draggable } from 'views/layout/dnd/draggable'
import { Droppable } from 'views/layout/dnd/droppable'
import { Sidebar, SidebarCollection, SidebarItem, SidebarPreview, SidebarTitle } from 'views/layout/sidebar'
import { Strategy, StrategyItems, StrategySlot } from 'views/layout/strategy/strategy'
import { styler } from 'views/layout/util/style'
import { DOMAttrs } from 'views/ui/ui_types'
import { AbilityVisual } from 'views/ui/visuals/abilityvisual'
import { ConditionVisual } from 'views/ui/visuals/conditionvisual'
import { PopupInfo } from 'views/layout/popup'

const style = styler('strategytab', ['line', 'slot'])

export const Strategytab: React.FC<{
    hero: HeroModel
}> = ({ hero }) => {
    const { setUser } = useUser()
    const [slot, setSlot] = useState<Nullable<AbilitySlot | ConditionSlot>>(null)
    const hint = useHinter()
    useEffect(() => {
        setSlot(hero.slots.find(s => s.key === slot?.key))
    }, [hero, slot])

    return <Popuptab>
        <PopupInfo>
            <p>Each row represents a sequence of actions. When your character's
            turn starts, it will firstmost attempt to continue its active sequence.
            If that is not possible a new sequence will be started.</p>

            <p>The priority in which a new sequence is selected is always top to bottom.
            A sequence will not be selected if none of the abilities are castable,
            for example because they are still in cooldown.</p>

            <p>Additionaly, you can add conditions to your sequences. A sequence
            can only be selected when all assigned conditions are met, otherwise
            it is skipped.</p>
        </PopupInfo>
        <Sidebar hidden={!slot} onhide={() => setSlot(null)}>
            <SidebarTitle>
                Select {slot?.label}
            </SidebarTitle>
            <SidebarCollection>
                {slot instanceof AbilitySlot && hero.abilities.map(abilityKey => {
                    const hinted =
                        hint.key === 'teampopup' && hint.tab === 'strategy' &&
                        hint.type === 'ability' &&
                        hint.rule === slot.strategy.index && hint.pos === slot.index &&
                        hint.ability === abilityKey
                    return <SidebarItem key={abilityKey} highlighted={hinted}>
                        <Draggable item={{ type: 'ability', key: abilityKey }} >
                            <AbilityVisual
                                ability={abilityKey}
                                visualMod={{ bw: !hero.canPerform(abilityKey) }}
                                locked={!hero.canPerform(abilityKey)}
                                selected={abilityKey === slot.abilityKey}
                                onClick={() => setUser(
                                    slot.update(abilityKey)
                                )}
                                hinted={hinted}
                            />
                        </Draggable>
                    </SidebarItem>
                })}
                {slot instanceof ConditionSlot && hero.conditions.map(conditionKey => {
                    const hinted =
                        hint.key === 'teampopup' && hint.tab === 'strategy' &&
                        hint.type === 'condition' &&
                        hint.rule === slot.strategy.index && hint.pos === slot.index &&
                        hint.condition === conditionKey
                    return <SidebarItem key={conditionKey} highlighted={hinted}>
                        <Draggable item={{ type: 'condition', key: conditionKey }} >
                            <ConditionVisual
                                condition={conditionKey}
                                selected={conditionKey === slot.conditionKey}
                                onClick={() => setUser(
                                    slot.setKey(conditionKey)
                                )}
                                hinted={hinted}
                            />
                        </Draggable>
                    </SidebarItem>
                })}
            </SidebarCollection>
            {slot && <SidebarPreview
                onclose={() => {
                    setUser(slot.remove())
                    setSlot(null)
                }}
                hintedClose={
                    hint.key === 'teampopup' && hint.tab === 'strategy' &&
                    hint.rule === slot.strategy.index && hint.pos === slot.index &&
                    hint.type === 'ability' && hint.ability === false
                }
            >
                {slot instanceof ConditionSlot && slot.state !== null && <Conditioninfo
                    state={slot.state}
                    update={(newState) => {
                        setUser(slot.update(newState))
                    }}
                />}
                {slot instanceof AbilitySlot && slot.talent && <Abilityinfo
                    talent={slot.talent}
                />}
            </SidebarPreview>}
        </Sidebar>
        {hero.strategyLines.map(strategy => (
            <StrategytabLine
                key={strategy.index}
                strategy={strategy}
                activeSlot={slot}
                setSlot={setSlot}
            />
        ))}
    </Popuptab>
}

export const StrategytabLine: React.FC<{
    strategy: StrategyLine
    activeSlot: Nullable<AbilitySlot | ConditionSlot>,
    setSlot: (slot: AbilitySlot | ConditionSlot) => void
}> = ({ strategy, activeSlot, setSlot }) => {
    const { setUser } = useUser()
    const hint = useHinter()

    let onUp = undefined
    if (strategy.index !== 0) {
        onUp = () => {
            setUser(strategy.moveUp())
        }
    }

    return <Strategy index={strategy.index} onUp={onUp}>
        <StrategyItems title="Abilities:">
            {strategy.abilities.map(slot => (
                <StrategytabAbility
                    slot={slot}
                    key={slot.key}
                    selected={activeSlot === slot}
                    onClick={() => setSlot(slot)}
                    hinted={
                        hint.key === 'teampopup' && hint.tab === 'strategy' &&
                        hint.type === 'ability' &&
                        hint.rule === strategy.index && hint.pos === slot.index
                    }
                />
            ))}
        </StrategyItems>
        <StrategyItems title="Conditions:">
            {strategy.conditions.map(slot => (
                <StrategytabCondition
                    slot={slot}
                    key={slot.key}
                    selected={activeSlot === slot}
                    onClick={() => setSlot(slot)}
                    hinted={
                        hint.key === 'teampopup' && hint.tab === 'strategy' &&
                        hint.type === 'condition' &&
                        hint.rule === strategy.index && hint.pos === slot.index
                    }
                />
            ))}
        </StrategyItems>
    </Strategy>
}

export const StrategytabSlot: React.FC<DOMAttrs> = ({ children, ...attrs }) => {
    return <div {...attrs} className={style.slot().mergeProps(attrs)}>
        {children}
    </div>
}

export const StrategytabAbility: React.FC<{
    slot: AbilitySlot
    selected: boolean
    onClick: () => void
    hinted?: boolean
}> = ({ slot, selected, hinted, ...attrs }) => {
    return <StrategySlot {...attrs} style={{ zIndex: hinted ? 10 : 0 }}>
        {hinted && !selected && <TutorialHint />}
        <Droppable
            type={["ability", "abilityslot"]}
            performDrop={(object) => {
                switch (object.type) {
                    case 'ability':
                        return slot.update(object.key)

                    case 'abilityslot':
                        const newUser = object.origin.remove()
                        const newSlot = AbilitySlot.findIn(newUser, slot)
                        if (!newSlot) return false
                        return newSlot.update(object.origin.abilityKey)
                    default:
                        return false
                }
            }}
        >
            <Draggable item={{ type: 'abilityslot', origin: slot }}>
                <AbilityVisual
                    ability={slot.abilityKey}
                    selected={selected}
                />
            </Draggable>
        </Droppable>
    </StrategySlot>
}


export const StrategytabCondition: React.FC<{
    slot: ConditionSlot
    selected: boolean
    onClick: () => void
    hinted?: boolean
}> = ({ slot, selected, hinted, ...rest }) => {

    return <StrategySlot {...rest} className={style.slot()}>
        <ConditionVisual
            condition={slot.conditionKey}
            selected={selected}
            hinted={hinted && !selected}
        />
    </StrategySlot>
}
