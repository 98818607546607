import { DamageType } from 'game/core'
import { RecklessBlow, RecklessBlowConfig } from 'game/extended/heros/barbarian/reckless_blow/reckless_blow'
import image from 'game/extended/heros/barbarian/reckless_blow/reckless_blow.png'
import { reckless_blow_config } from 'game/extended/heros/barbarian/reckless_blow/reckless_blow_config'
import { AbilityUI } from 'game/extended/uis/abilities/AbilityUI'
import React from 'react'

export const reckless_blow_ui: AbilityUI<'RecklessBlow'> = {
    model: RecklessBlow,
    configs: reckless_blow_config,
    image,
    label: 'Reckless blow',
    renderTargets: () => 'Melee',
    renderDescription: () => <>
        Attack and interrupt your melee target, dealing a percentage of your current weapon damage to
        your target and to yourself.
    </>,
    renderImpression: (ability: RecklessBlow, UI) => <>
        <UI.Char character={ability.character} />{' '}brutally swings his weapon towards{' '}
        <UI.Char character={ability.targets} />, wounding and interrupting his target while recklessly
        damaging {ability.character.isMale ? 'himself' : 'herself'} in the process.
    </>,
    renderTableStatic: (config: RecklessBlowConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Damage:</UI.TKey>
            <UI.TVal><UI.Damage type={DamageType.Melee} damage={config.target_damage} /></UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Self damage:</UI.TKey>
            <UI.TVal><UI.Damage type={DamageType.Melee} damage={config.own_damage} /></UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (ability: RecklessBlow, UI) => <>
        <UI.TRow>
            <UI.TKey>Damage:</UI.TKey>
            <UI.TVal><UI.Damage damage={ability.targetDamage} /></UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Self damage:</UI.TKey>
            <UI.TVal><UI.Damage damage={ability.ownDamage} /></UI.TVal>
        </UI.TRow>
    </>
}
