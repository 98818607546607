import 'views/ingame/user/team/popuptab.scss'
import React from 'react'

export const Popuptab: React.FC = ({ children }) => {
    return <div className="popuptab">{children}</div>
}

export const PopuptabTitle: React.FC = ({ children }) => {
    return <div className="popuptab_title">{children}</div>
}

export const PopuptabSubtitle: React.FC = ({ children }) => {
    return <div className="popuptab_subtitle">{children}</div>
}

export const PopuptabItems: React.FC = ({ children }) => {
    return <div className="popuptab_items">{children}</div>
}
