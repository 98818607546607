import { Character, Event } from 'game/core'
import { CharacterPath } from 'game/core/character'
import { ChargingProcess } from 'game/core/rule'
import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'

export class CharFindsNoAbility extends Event<{
    character: CharacterPath
    process: ChargingProcess
}> {
    static KEY = 'CharFindsNoAbility' as const

    @Memoize()
    get target(): Nullable<Character> {
        return Character.byPath(this.battle, this.state.character)
    }
}
