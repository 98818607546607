import { Berserk, BerserkConfig } from 'game/extended/heros/barbarian/berserk/berserk'
import image from 'game/extended/heros/barbarian/berserk/berserk.png'
import { berserk_config } from 'game/extended/heros/barbarian/berserk/berserk_config'
import { SkillUI } from 'game/extended/uis/skills/SkillUI'
import React from 'react'

export const berserk_ui: SkillUI<'Berserk'> = {
    model: Berserk,
    configs: berserk_config,
    image,
    label: 'Berserk',
    renderDescription: (config: BerserkConfig) => <span>
        Whenever damage is received, gain a stack. Each stack increases
        your strength. When no damage is received for a given amount of turns,
        all stacks are cleared.
    </span>,
    renderTableStatic: (config: BerserkConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Increase:</UI.TKey>
            <UI.TVal><UI.Stat stat='strength' amount={config.increment} /></UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Duration:</UI.TKey>
            <UI.TVal><UI.Turns turns={config.duration} /></UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (skill: Berserk, UI) => <>
        <UI.TRow>
            <UI.TKey>Increase:</UI.TKey>
            <UI.TVal><UI.Stat stat='strength' amount={skill.increment} /></UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Duration:</UI.TKey>
            <UI.TVal><UI.Turns turns={skill.duration} /></UI.TVal>
        </UI.TRow>
    </>
}
