import { DamageType } from 'game/core'
import { JudgementHammer, JudgementHammerConfig } from 'game/extended/heros/paladin/judgement_hammer/judgement_hammer'
import { judgement_hammer_config } from 'game/extended/heros/paladin/judgement_hammer/judgement_hammer_config'
import { AbilityUI } from 'game/extended/uis/abilities/AbilityUI'
import React from 'react'

import image from './judgement_hammer.png'

export const judgement_hammer_ui: AbilityUI<'JudgementHammer'> = {
    model: JudgementHammer,
    configs: judgement_hammer_config,
    image,
    label: 'Judgement Hammer',
    renderTargets: () => 'Ranged',
    renderDescription: () => <>
        Deal ranged damage to your target and stun if it the target is located in the support line.
    </>,
    renderImpression: (ability: JudgementHammer, UI) => <>
        <UI.Char character={ability.character} />{' '}launches a his hammer into the air which,
        guided by higher powers, strikes <UI.Char character={ability.targets} />.
    </>,
    renderTableStatic: (conf: JudgementHammerConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Damage:</UI.TKey>
            <UI.TVal>
                <UI.Damage damage={conf.damage} type={DamageType.Ranged} />
            </UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (ability: JudgementHammer, UI) => <>
        <UI.TRow>
            <UI.TKey>Damage:</UI.TKey>
            <UI.TVal><UI.Damage damage={ability.damage} /></UI.TVal>
        </UI.TRow>
    </>,
}
