import { Armor, ItemRarity } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './bandit_armor.png'

export const bandit_armor: ItemUI<Armor> = {
    image,
    label: 'Bandit armor',
    value: 30,
    item: {
        key: 'bandit_armor',
        type: 'armor',
        rarity: ItemRarity.Common,
        stats: {
            agility: 20,
            strength: 10,
            intelligence: 10,
            health: 10,
            armor: 5
        }
    }
}
