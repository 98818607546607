import { RequiredEnergy } from 'game/core'
import { RequirementUI } from 'game/extended/uis/requirements/RequirementUI'
import React from 'react'

export const required_energy_ui: RequirementUI<'RequiredEnergy'> = {
    model: RequiredEnergy,
    label: 'Required energy',
    renderDescription: () => <>
        This ability requires enough energy to be castable.
    </>
}
