import { Ability, AbilityConfig, AttackRange, DamageNature, FullDamage, Weapon } from 'game/core'
import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'

export type WeaponAttackConfig = AbilityConfig

export abstract class WeaponAttack extends Ability<WeaponAttackConfig> {

    abstract get weapon(): Nullable<Weapon>

    @Memoize()
    get energy() {
        if (!this.weapon) return 0
        return this.weapon.energy
    }

    @Memoize()
    get targets() {
        switch (this.weapon?.range) {
            case AttackRange.Melee:
                return this.character.meleeTarget
            case AttackRange.Ranged:
                return this.character.rangedTarget
            case AttackRange.Mixed:
                return this.character.meleeTarget || this.character.rangedTarget
            default:
                return null
        }
    }

    @Memoize()
    get damage(): FullDamage {
        if (!this.weapon) return this.nullDamage
        const damage = this.calculateDamage(
            this.weapon.damageType,
            DamageNature.Melee,
            this.weapon.damage
        )

        return {
            ...damage,
            true_damage: damage.true_damage || this.weapon.true_damage
        }
    }

    // ----- Calculate new State ----- //
    performActionImpl() {
        if (!this.targets) return this.battle
        return this.targets.takeDamage(this.damage)
    }
}
