import { Condition } from 'game/core'
import { Memoize } from 'game/util/memoize'

export type WhenHurtTarget = 'self' | 'front_ally' | 'back_ally'

export type WhenHurtState = {
    target: WhenHurtTarget
    percent: number
}

export class WhenHurt extends Condition<WhenHurtState> {

    public static KEY = 'WhenHurt' as const

    @Memoize()
    get hpRatio(): number {
        return this.state.percent / 100
    }

    @Memoize()
    get isBlocking() {
        switch (this.state.target) {
            case 'self':
                return this.character.hpRatio > this.hpRatio
            case 'front_ally':
                if (!this.character.frontAlly) return true
                return this.character.frontAlly.hpRatio > this.hpRatio
            case 'back_ally':
                if (!this.character.behindAlly) return true
                return this.character.behindAlly.hpRatio > this.hpRatio
        }
        return true
    }
}
