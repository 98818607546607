import { AttackRange, DamageType, ItemRarity, Weapon } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './unholy_broken_sword.png'

export const unholy_broken_sword: ItemUI<Weapon> = {
  image,
  label: 'Unholy broken sword',
  value: 150,
  item: {
    key: 'unholy_broken_sword',
    type: 'weapon',
    rarity: ItemRarity.Rare,
    stats: {
      strength: 100,
      agility: 100,
      intelligence: 100,
      health: -100
    },
    two_handed: true,
    category: 'sword',
    damageType: DamageType.Melee,
    range: AttackRange.Melee,
    energy: 1,
    damage: 20
  }
}
