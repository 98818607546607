import { Memoize } from 'game/util/memoize'
import { Requirement } from 'game/core'
import { UnreachableCaseError } from 'game/util/never'

export class RequiredPosition extends Requirement<{
    pos: 'front' | 'support' | 'side' | 'center'
}> {

    static KEY = 'RequiredPosition' as const

    @Memoize()
    get pos() {
        return this.state.pos
    }

    @Memoize()
    get isValid() {
        switch (this.pos) {
            case 'front': return this.character.line.position === 'front'
            case 'support': return this.character.line.position === 'support'
            case 'center': return this.character.neighbours.length === 2
            case 'side': return this.character.neighbours.length < 2
            default: throw new UnreachableCaseError(this.pos)
        }
    }
}
