import { Character, Damage, Percentage, Skill, SkillConfig } from 'game/core'
import { Memoize } from 'game/util/memoize'

export interface DuellerConfig extends SkillConfig {
    damage_reduction: Percentage
}

export class Dueller extends Skill<{}, DuellerConfig> {

    static KEY = 'Dueller' as const

    @Memoize()
    get damageReduction(): Percentage {
        return this.currentConfig.damage_reduction
    }

    // ----- Appliers ----- //
    applyInjuryModifier(amount: number, damage: Damage): number {
        const character = Character.byPath(this.battle, damage.origin)
        if (character && character === this.character.meleeTarget) {
            return amount * (100 - this.damageReduction) / 100
        }
        return amount
    }
}
