import { Ability } from 'game/core'
import { Memoize } from 'game/util/memoize'

import { AbilityConfig } from '../ability'

export type IdleConfig = AbilityConfig

export class Idle extends Ability {

    static KEY = 'Idle' as const
    static FIXED_REQUIREMENTS = []

    @Memoize()
    get targets() {
        return false as const
    }

    // ----- Calculate new State ----- //
    performActionImpl() {
        return this.battle
    }
}
