import { DualSwing, DualSwingConfig } from 'game/extended/heros/barbarian/dual_swing/dual_swing'
import image from 'game/extended/heros/barbarian/dual_swing/dual_swing.png'
import { dual_swing_config } from 'game/extended/heros/barbarian/dual_swing/dual_swing_config'
import { AbilityUI } from 'game/extended/uis/abilities/AbilityUI'
import React from 'react'

export const dual_swing_ui: AbilityUI<'DualSwing'> = {
    model: DualSwing,
    configs: dual_swing_config,
    image,
    label: 'Dual swing',
    renderTargets: () => 'Melee',
    renderDescription: () => <>
        Use both of your melee weapons simultaneously to attack.
    </>,
    renderImpression: (ability: DualSwing, UI) => <>
        <UI.Char character={ability.character} />{' '}draws {ability.character?.isFemale ? 'her' : 'his'} second weapon and strikes both of them simultaneously
        at{' '}<UI.Char character={ability.targets} />.
    </>,
    renderTableStatic: (config: DualSwingConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Damage 1:</UI.TKey>
            <UI.TVal>Equiped weapon</UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Damage 2:</UI.TKey>
            <UI.TVal>Secondary weapon</UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (ability: DualSwing, UI) => <>
        <UI.TRow>
            <UI.TKey>Damage 1:</UI.TKey>
            <UI.TVal><UI.Damage damage={ability.damage1} /></UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Damage 2:</UI.TKey>
            <UI.TVal><UI.Damage damage={ability.damage2} /></UI.TVal>
        </UI.TRow>
    </>
}
