import { ExtendableGameModel } from 'game/core'

export type VariableConf<Config, CoreConfig extends Partial<Config>> =
    Partial<Config> & Omit<Config, keyof CoreConfig>

export type ExtendableGameModelContructor<M extends ExtendableGameModel<any, any, any>> = {
    new(parent: M['parent'], state: M['state']): M
}

export function mergeConfs<F, V>(fixed: F, variables: Array<V>): Array<F & V> {
    return variables.map(v => ({ ...fixed, ...v }))
}

export class Environment {
    private constructors: Map<string, ExtendableGameModelContructor<any>> = new Map()
    private configs: Map<string, object> = new Map()

    public registerModel<M extends ExtendableGameModel<any, any, any>>(
        key: string,
        constructor: ExtendableGameModelContructor<M>,
        config: M['config']
    ) {
        if (!key) {
            console.log(constructor)
            throw new Error(`Trying to register model without a key: ${key}`)
        }
        if (this.constructors.get(key)) throw new Error(`Model is already registered: ${key}`)

        this.constructors.set(key, constructor)
        this.configs.set(key, config)
    }

    public getConfig<C>(key: string): C {
        return (this.configs.get(key) as unknown) as C
    }

    public getConstructor<C extends ExtendableGameModelContructor<any>>(key: string): C {
        return (this.constructors.get(key) as unknown) as C
    }

    public createModel<M extends ExtendableGameModel<any, any, any>>(parent: M['parent'], state: M['state']): M {
        if (!state.key) {
            console.log(state)
            throw new Error(`No key found in given model`)
        }
        const constructor = this.constructors.get(state.key)
        if (!constructor) {
            throw new Error(`No registered model found for key: ${state.key}`)
        }
        return new constructor(parent, state)
    }
}
