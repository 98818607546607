import { MissionConfig } from 'models/campaign/MissionModel'

const asMissionsObjects = <T>(et: { [K in keyof T]: MissionConfig }) => et

export const tutorial_missions = asMissionsObjects({
    tutorial_mission_1: {
        front: [
            'chicky'
        ]
    },
    tutorial_mission_2: {
        front: [
            'turtly'
        ]
    },
    tutorial_mission_3: {
        front: [
            'bully',
        ]
    },
    tutorial_mission_4: {
        front: [
            'bully',
            'turtly',
            'chicky'
        ]
    },
    tutorial_mission_5: {
        front: [
            'hedgy',
            'hedgy'
        ],
        support: [
            'blowfishy'
        ]
    }
})
