import { CoreEffectKey, Requirement } from 'game/core'
import { Memoize } from 'game/util/memoize'

export class RequiredEffect extends Requirement<{
    effect: CoreEffectKey
}> {

    static KEY = 'RequiredEffect' as const

    @Memoize()
    get isValid() {
        return !!this.character.effects.find(effect => effect.key === this.state.effect)
    }
}
