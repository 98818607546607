import { AttackRange, DamageType, ItemRarity, Weapon } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './goblin_bow.png'

export const goblin_bow: ItemUI<Weapon> = {
    image,
    label: 'Goblin bow',
    value: 25,
    item: {
        key: 'goblin_bow',
        type: 'weapon',
        rarity: ItemRarity.Common,
        stats: {
            agility: 25
        },
        two_handed: true,
        category: 'bow',
        damageType: DamageType.Ranged,
        range: AttackRange.Ranged,
        energy: 3,
        damage: 9
    }
}
