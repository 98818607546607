import { Headgear, ItemRarity } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './knights_helmet.png'

export const knights_helmet: ItemUI<Headgear> = {
    image,
    label: 'Knights helmet',
    value: 80,
    item: {
        key: 'knights_helmet',
        type: 'headgear',
        rarity: ItemRarity.Common,
        stats: {
            armor: 20,
            shielding: 20,
            resistance: 20,
            agility: -20
        }
    }
}
