import './level.scss'

import { items } from 'game/extended/types'
import { Nullable } from 'game/util/maybe'
import { HeroModel } from 'models/user/hero/HeroModel'
import { LevelModel } from 'models/user/LevelModel'
import { LineupSlotKey } from 'models/user/LineupSlot'
import React, { useState } from 'react'
import { LeveldotStars } from 'views/ingame/campaign/map/leveldot'
import { useHinter } from 'views/ingame/campaign/tutorial'
import { Lineup, LineupCircle, LineupLine, LineupSpacer } from 'views/ingame/user/levels/lineup'
import { useUser } from 'views/ingame/user/usercontext'
import { Button } from 'views/layout/button'
import { ErrorPrint } from 'views/layout/errors/errorprint'
import { Popup, PopupContent, PopupFullHeader } from 'views/layout/popup'
import { Sidebar, SidebarCollection, SidebarControls, SidebarItem, SidebarTitle } from 'views/layout/sidebar'
import { useLinker } from 'views/link'
import { Icon } from 'views/ui/icon'
import { StatIcon } from 'views/ui/stats/staticon'
import { CharacterVisual } from 'views/ui/visuals/charactervisual'
import { ItemVisual } from 'views/ui/visuals/itemvisual'

export const Level: React.FC<{
  label: string
  level: LevelModel
  toPrev?: () => void
  toNext?: () => void
  onStart: () => void
}> = ({ label, level, onStart, toPrev, toNext }) => {
  const hint = useHinter()
  const { user, setUser } = useUser()
  const { goTo } = useLinker()
  const [selected, setSelected] = useState<Nullable<LineupSlotKey>>(null)
  const [error, setError] = useState<Error | null>(null)
  const lineup = user.lineup
  const slot = lineup.slots.find(slot => slot.key === selected)

  if (error) {
    return <Popup onClose={() => goTo('')}>
      <div className="level_error">
        <ErrorPrint error={error} />
      </div>
    </Popup>
  }

  return <Popup
    onClose={() => goTo('')}
    mod="small"
  >
    <PopupFullHeader icon={{ stat: 'deaths', amount: level.attempts }}>
      {label}
      {level.status.key === 'finished' && <div className="level-stars">
        <LeveldotStars stars={level.status.stars} />
      </div>}
    </PopupFullHeader>
    <PopupContent>
      <Sidebar hidden={!slot} onhide={() => setSelected(null)}>
        <SidebarTitle>
          Select a hero
        </SidebarTitle>
        <SidebarCollection>
          {user.heros.map(hero => (
            <SidebarItem key={hero.id}>
              <CharacterVisual
                type={hero.type}
                visualMod={{ bw: !slot?.canSelectHero(hero) }}
                selected={slot?.hero === hero}
                onClick={() => {
                  if (!slot) return
                  setUser(slot.updateHero(hero))
                }}
                hinted={
                  hint.key === 'level' && hint.mission === level.battle_id &&
                  hint.action === 'lineup' && hint.hero === hero.id
                }
              />
            </SidebarItem>
          ))}
        </SidebarCollection>
        <SidebarControls>
          <Button
            disabled={!slot?.hero}
            onClick={() => {
              if (!slot) return
              setUser(slot.updateHero(null))
            }}
          >
            <span>Deselect</span>
          </Button>
        </SidebarControls>
      </Sidebar>
      {toPrev && <Icon className="level-prev" icon="arrow_left" onClick={toPrev} />}
      {toNext && <Icon className="level-next" icon="arrow_right" onClick={toNext} />}
      <div className="level">
        <div className="level-lineup">
          <Lineup>
            <LineupLine>
              {level.allies.support.map(slotOrHero => (
                <LineupCircle
                  key={slotOrHero.id}
                  active={level.status.key !== 'open' || (!slot || slotOrHero === slot)}
                  type={slotOrHero.type}
                  reward={slotOrHero instanceof HeroModel ? slotOrHero.rewardInBattle(level.battle_id) : undefined}
                  onClick={() => {
                    if (level.status.key !== 'open') return
                    if (slotOrHero instanceof HeroModel) return
                    setSelected(slotOrHero.key)
                  }}
                />
              ))}
            </LineupLine>
            <LineupLine>
              {level.allies.front.map(slotOrHero => (
                <LineupCircle
                  key={slotOrHero.id}
                  active={level.status.key !== 'open' || (!slot || slotOrHero === slot)}
                  type={slotOrHero.type}
                  reward={slotOrHero instanceof HeroModel ? slotOrHero.rewardInBattle(level.battle_id) : undefined}
                  onClick={() => {
                    if (level.status.key !== 'open') return
                    if (slotOrHero instanceof HeroModel) return
                    setSelected(slotOrHero.key)
                  }}
                  hinted={hint.key === 'level' && hint.action === 'lineup' && slotOrHero.index === hint.front && !selected}
                />
              ))}
            </LineupLine>
            <LineupSpacer>vs.</LineupSpacer>
            <LineupLine>
              {level.enemies.front.map((creature, i) => (
                <LineupCircle
                  key={i}
                  mod="mirrored"
                  active={true}
                  type={creature.type}
                  link={`/library/bestiary/${creature.type}/stats?backlink`}
                />
              ))}
            </LineupLine>
            <LineupLine>
              {level.enemies.support.map((creature, i) => (
                <LineupCircle
                  key={i}
                  mod="mirrored"
                  active={true}
                  type={creature.type}
                  link={`/library/bestiary/${creature.type}/stats?backlink`}
                />
              ))}
            </LineupLine>
          </Lineup>
        </div>
        <div className="level-actions">
          <LevelActions
            level={level}
            onStart={() => {
              if (user.lineup.isEmpty) return
              try {
                onStart()
              } catch (error) {
                setError(error)
              }
            }}
          />
        </div>
      </div>
    </PopupContent>
  </Popup>
}

const LevelActions: React.FC<{
  level: LevelModel
  onStart: () => void
}> = ({ level, onStart }) => {
  const hint = useHinter()

  switch (level.status.key) {
    case 'locked':
      return <LevelLabel>Locked</LevelLabel>
    case 'open':
      return <>
        <Button
          onClick={onStart}
          hinted={hint.key === 'level' && hint.mission === level.battle_id && hint.action === 'start'}
        >
          Start
        </Button>
      </>
    case 'finished':
      return <LevelFinished level={level} />
  }
  return null
}

const LevelLabel: React.FC = ({ children }) => {
  return <div className="level_label">
    {children}
  </div>
}

const LevelFinished: React.FC<{ level: LevelModel }> = ({ level }) => {
  if (level.status.key !== 'finished') return null

  return <div className="level_finished">
    <div className="level_finished-item">
      <div className="level_finished-item-label">Attempts</div>
      <div className="level_finished-item-value">{level.attempts}</div>
    </div>
    <div className="level_finished-item">
      <div className="level_finished-item-label">Rounds</div>
      <div className="level_finished-item-value">{level.status.rounds}</div>
    </div>
    <div className="level_finished-item">
      <div className="level_finished-item-label">Reward</div>
      <div className="level_finished-item-value">
        {level.status.reward}
        <StatIcon stat="currency" />
      </div>
    </div>
    <div className="level_finished-loot">
      <div className="level_finished-item-label">Loot</div>
      <div className="level_finished-item-items">
        {level.status.loot.map((key, index) =>
          <ItemVisual item={items[key].item} key={index} />
        )}
      </div>
    </div>
  </div>
}
