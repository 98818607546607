import { WhenEnergyDirection } from 'game/extended/conditions/when_energy/when_energy'
import { ConditionUI } from 'game/extended/uis/conditions/ConditionUI'
import React from 'react'
import { Conditionsetup, ConditionsetupSelect, ConditionsetupSlider } from 'views/ui/conditionsetup'
import { Icon } from 'views/ui/icon'
import { VisualSubscript } from 'views/ui/visual'

import { ReactComponent as WhenEnergytIcon } from './when_energy.svg'

const options = {
    below: ' < ',
    exactly: ' = ',
    above: ' > '
}

const symbols = {
    below: '<',
    exactly: '=',
    above: '>'
}

export const when_energy_ui: ConditionUI<'WhenEnergy'> = {
    icon: WhenEnergytIcon,
    label: 'When energy',
    defaultState: {
        key: 'WhenEnergy',
        direction: 'below',
        amount: 10
    },
    renderDescription: (state) =>
        <span>
            Cast this sequence when your energy is
            {state.direction === 'below' && ' below '}
            {state.direction === 'exactly' && ' exactly '}
            {state.direction === 'above' && ' above '}
            {state.amount}.
        </span>,
    renderVisualIcon: (state) =>
        <VisualSubscript>
            {symbols[state.direction]}{state.amount}
        </VisualSubscript>,
    renderOptions: (state, update) =>
        <Conditionsetup>
            <ConditionsetupSelect<WhenEnergyDirection>
                value={state.direction}
                options={options}
                onchange={value => update({ ...state, direction: value })}
            />
            <ConditionsetupSlider
                min={0}
                max={40}
                step={1}
                unit={<Icon icon="energy" />}
                value={state.amount}
                onchange={value => update({ ...state, amount: value })}
            />
        </Conditionsetup>
}
