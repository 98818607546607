import { AdMobPlugin } from '@capacitor-community/admob'
import { Plugins } from '@capacitor/core'
import { isPlatform } from '@ionic/react'
import { useApp } from 'AppContext'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { AboutConsent } from 'views/home/about/About'
import { Button } from 'views/layout/button'
import { Popup, PopupContent, PopupFullHeader } from 'views/layout/popup'
import { styler } from 'views/layout/util/style'
import './ads.scss'


const AdMob = Plugins.AdMob as AdMobPlugin

const style = styler('ads', ['popup', 'view'])
export const ADS_EFFECT_DURATION = 120

export type AdsState = 'initial' | 'failed' | 'ready'
export type AdsContextType = {
    hasConsent: boolean
    adWatchedTime: number
    timeLeft: false | number
    openPopup: () => void
    closePopup: () => void
    setAdAdWatched: (success: boolean) => void
}

const AdsContext = createContext<AdsContextType | false>(false)
export const AdsProvider: React.FC = ({ children }) => {
    const { adsConsent, adsCode } = useApp()
    const [isOpen, setIsOpen] = useState(false)
    const [adWatchedTime, setAdWatchedTime] = useState<number>(() =>
        localStorage.last_ad_watched ? parseInt(localStorage.last_ad_watched, 10) : 0
    )

    useEffect(() => {
        if (!adsConsent) return
        if (!isPlatform('android')) return
        AdMob.initialize({})
    }, [adsConsent])

    let timeLeft = ADS_EFFECT_DURATION - Math.round((Date.now() - adWatchedTime) / 60 / 1000)
    if (adsCode) timeLeft = 9999
    return <AdsContext.Provider value={{
        hasConsent: adsConsent,
        adWatchedTime,
        timeLeft: adsConsent && timeLeft > 0 ? timeLeft : false,
        closePopup: () => {
            setIsOpen(false)
        },
        openPopup: () => {
            setIsOpen(true)
        },
        setAdAdWatched: (success: boolean) => {
            if (success) {
                const time = Date.now()
                setAdWatchedTime(time)
                localStorage.last_ad_watched = Date.now()
            }
        }
    }}>
        {children}
        {isOpen && <AdsPopup />}
    </AdsContext.Provider>
}

export const useAds = () => {
    const result = useContext(AdsContext)
    if (!result) throw Error(`useAds is only usable within a valid AdsContext`)
    return result
}

export const AdsPopup: React.FC = () => {
    const { hasConsent, timeLeft, closePopup, setAdAdWatched } = useAds()
    const [isShowing, setIsShowing] = useState(false)

    if (isShowing) {
        return <AdsView
            onFinished={(succes: boolean) => {
                setAdAdWatched(succes)
                closePopup()
            }}
        />
    }

    return <Popup onClose={closePopup}>
        <PopupFullHeader>
            Boost your lifes/retries
        </PopupFullHeader>
        <PopupContent>
            <div className={style.popup()}>
                <div className={style.popup('message')}>
                    {!timeLeft && <>
                        Watch a reward ad to gain:<br />
                        - Unlimited retries in campaign missions<br />
                        - 8 total lifes instead of 4, while playing the endless game mode<br />
                        <br />
                        This effect will last for <b>{ADS_EFFECT_DURATION} minutes.</b><br />
                        <br />
                        Note: You can modify your ad settings in the about section on the main menu.
                    </>}
                    {timeLeft && <>
                        You have <b>{timeLeft}</b> minutes left of ad-free time with:<br />
                        - Unlimited retries in campaign missions<br />
                        - 10 total lifes instead of 5 in endless mode<br />
                        - The developer's sincere appreciation
                    </>}
                    <hr />
                    <AboutConsent />
                </div>
                <div className={style.popup('buttons')}>
                    <Button onClick={closePopup}>
                        {timeLeft ? 'Continue' : 'Skip'}
                    </Button>
                    {hasConsent && <Button onClick={() => {
                        if (isPlatform('android')) {
                            setIsShowing(true)
                            AdMob.showRewardVideoAd()
                        } else {
                            alert('Show video on android platform')
                            setAdAdWatched(true)
                            closePopup()
                        }
                    }}>
                        {timeLeft ? 'Refresh timer (watch video)' : 'Watch video'}
                    </Button>}
                </div>
            </div>
        </PopupContent>
    </Popup >
}

export const AdsView: React.FC<{
    onFinished: (success: boolean) => void
}> = ({ onFinished }) => {
    const [state, setState] = useState('initial')

    useEffect(() => {
        AdMob.prepareRewardVideoAd({
            adId: process.env.TEST_ADS ? 'ca-app-pub-3940256099942544/5224354917' : 'ca-app-pub-2319515217218846/5635151975'
        });
        setState('preparing')
        console.log('Preparing reward video')
        AdMob.addListener('onRewardedVideoAdLoaded', (info: boolean) => {
            console.log('RewardedVideoAd Loaded')
            AdMob.showRewardVideoAd()
            setState('showing')
        });
        AdMob.addListener('onRewardedVideoAdFailedToLoad', () => {
            console.log('Failed to load reward video: onRewardedVideoAdFailedToLoad')
            onFinished(false)
        })
        AdMob.addListener('onRewardedVideoAdClosed', () => {
            console.log('Closes before onRewarded was called: onRewardedVideoAdClosed')
            onFinished(false)
        })
        AdMob.addListener('onRewarded', () => {
            console.log('onRewarded')
            onFinished(true)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <div className={style.view()}>
        <div className={style.view('bg')}></div>
        <div className={style.view('status')}>
            {state}
        </div>
    </div>
}