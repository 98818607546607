import { DamageType } from 'game/core'
import { AbilityUI } from 'game/extended/uis/abilities/AbilityUI'
import React from 'react'

import { ChainLightning, ChainLightningConfig } from './chain_lightning'
import image from './chain_lightning.png'
import { chain_lightning_config } from './chain_lightning_config'

export const chain_lightning_ui: AbilityUI<'ChainLightning'> = {
    model: ChainLightning,
    configs: chain_lightning_config,
    image,
    label: 'Chain lightning',
    renderTargets: (config: ChainLightningConfig) => `Lowest hp enemy chained x${config.targets}`,
    renderDescription: () => <>
        Initiale a lightning strike on the most wounded ally. The lightning bolt jumps to the most injured adjecent
        ally. After each jump the beam becomes less effective.
    </>,
    renderImpression: (ability: ChainLightning, UI) => <>
        A chain of lightning bolts strike from the sky, targeting
        {' '}<UI.Char character={ability.targets} />
        {' '}and multiple other nearby enemies.
    </>,
    renderTableStatic: (config: ChainLightningConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Damage:</UI.TKey>
            <UI.TVal><UI.Damage type={DamageType.Magic} damage={config.damage} /></UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Decrease per jump:</UI.TKey>
            <UI.TVal><UI.Healing healing={config.decrease} percentage /></UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Nr. of Targets</UI.TKey>
            <UI.TVal>{config.targets}</UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (ability: ChainLightning, UI) => <>
        <UI.TRow>
            <UI.TKey>First strike damage:</UI.TKey>
            <UI.TVal><UI.Damage damage={ability.initialDamage} /></UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Decrease per jump:</UI.TKey>
            <UI.TVal><UI.Healing healing={ability.decrease} percentage /></UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Nr. of Targets</UI.TKey>
            <UI.TVal>{ability.targets}</UI.TVal>
        </UI.TRow>
    </>
}
