import { Character, CharDamaged, Event, FullDamage, Interrupt, Skill, SkillConfig, SkillTriggered, Turns } from 'game/core'
import { Memoize } from 'game/util/memoize'

export interface ShieldBlockConfig extends SkillConfig {
    frequency: Turns
}

export interface ShieldBlockState {
    blocks: Turns
}

export class ShieldBlock extends Skill<ShieldBlockState, ShieldBlockConfig>{

    static KEY = 'ShieldBlock' as const

    @Memoize()
    get blocks(): number {
        return this.state.blocks || 0
    }

    @Memoize()
    get frequency(): number {
        return this.currentConfig.frequency
    }

    @Memoize()
    get attacksUntilNextBlock(): number {
        if (!this.target.shield) return 999
        return this.frequency - this.blocks - 1
    }

    @Memoize()
    get blockNextAttack(): Boolean {
        if (!this.target.shield) return false
        return this.blocks + 1 >= this.currentConfig.frequency
    }

    // ----- Interrupts ----- //
    interruptOnDamage(target: Character, damage: FullDamage): Interrupt {
        if (target !== this.target) return false
        if (damage.origin.side === this.character.side.position) return false
        if (!this.blockNextAttack) return false

        //Ignore damage and reset blocks to 0
        return this
            .update({ blocks: 0 })
            .addEvent(SkillTriggered, { skill: this.path })
    }

    reactToEvent(event: Event): Interrupt {
        if (!(event instanceof CharDamaged)) return false
        if (event.target !== this.character) return false
        if (event.injury.damage.origin.side === this.character.side.position) return false

        return this.update({
            blocks: this.blocks + 1
        })
    }
}
