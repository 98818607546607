import { Event, FullHealing, Interrupt, Skill, TurnEnded } from 'game/core'
import { Memoize } from 'game/util/memoize'

export type UndeadConfig = {
    recovery: number
}

export class Undead extends Skill<{}, UndeadConfig>{
    static KEY = 'Undead' as const

    @Memoize()
    get recovery(): FullHealing {
        return this.calculateHealing(this.currentConfig.recovery)
    }

    // ----- Appliers ----- //
    reactToEvent(event: Event): Interrupt {
        if (!(event instanceof TurnEnded)) return false
        if (event.character !== this.target) return false

        return this.character.heal(this.recovery)
    }
}
