import { heros } from 'game/extended/heros/heros'
import { ClassKey } from 'game/extended/types'
import { ClassUI } from 'game/extended/uis/types/class_uis'
import { Memoize } from 'game/util/memoize'
import { AcademyModel } from 'models/user/AcademyModel'
import { UserModel } from 'models/user/UserModel'

export class RecruitModel {
    constructor(readonly academy: AcademyModel, readonly key: ClassKey) { }

    @Memoize()
    get user(): UserModel {
        return this.academy.user
    }

    @Memoize()
    get isAvailable(): boolean {
        return !this.academy.user.heros.find(hero => hero.type === this.key)
    }

    @Memoize()
    get canAfford(): boolean {
        return this.user.money >= this.price
    }

    @Memoize()
    get price(): number {
        return 100 + 50 * this.user.heros.length
    }

    @Memoize()
    get ui(): ClassUI {
        return heros.types[this.key]
    }

    // ----- State change ----- //
    recruit(): UserModel {
        if (!this.canAfford) return this.user

        return this.user.update({
            heros: [
                ...this.user.state.heros,
                {
                    id: this.key,
                    type: this.key,
                    name: this.ui.name,
                    level: 1,
                    skill_points: 1,
                    stats: this.ui.definition.stats,
                    rewards: [],
                    strategy: [
                        {
                            abilities: [
                                'Punch'
                            ],
                            conditions: []
                        }
                    ]
                }
            ],
            money: this.user.money - this.price
        })
    }

}
