import { ConditionKey, ConditionState } from 'game/extended/types'
import { getConditionUI } from 'game/extended/uis/conditions/condition_uis'
import { Nullable } from 'game/util/maybe'
import React from 'react'
import { DOMAttrs } from 'views/ui/ui_types'
import { Visual, VisualAttrs, VisualContainer } from 'views/ui/visual'

export const ConditionVisual: React.FC<{
    condition: Nullable<ConditionKey | ConditionState>
} & VisualAttrs & DOMAttrs> = attrs => {
    const { condition } = attrs

    if (!condition) return <Visual {...attrs} />
    const key = typeof condition === 'string' ? condition : condition.key
    const state = typeof condition === 'string' ? null : condition

    const ui = getConditionUI(key)
    if (!ui) return <Visual {...attrs} unknown={true} />

    return <Visual {...attrs}>
        <VisualContainer>
            <ui.icon style={{ width: '100%' }} />
        </VisualContainer>
        {state && ui.renderVisualIcon(state)}
    </Visual>
}
