import { ItemRarity, Shield } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './viking_shield.png'

export const viking_shield: ItemUI<Shield> = {
  image,
  label: 'Viking shield',
  value: 90,
  item: {
    key: 'viking_shield',
    type: 'shield',
    rarity: ItemRarity.Common,
    stats: {
      armor: 20,
      shielding: 30,
      agility: 30,
      strength: 30
    }
  }
}
