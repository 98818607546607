import { SeduceEffect } from 'game/extended/creatures/undead/abilities/seduce/seduce_effect'
import { EffectUI } from 'game/extended/uis/effects/EffectUI'
import React from 'react'

import image from './seduce.png'

export const seduce_effect_ui: EffectUI<'SeduceEffect'> = {
  model: SeduceEffect,
  label: 'Seduce effect',
  image,
  renderDescription: (effect: SeduceEffect) => <>
    This character is unable to target the caster of this enchantment.
  </>
}
