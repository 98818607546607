import { Headgear, ItemRarity } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './red_bandana.png'

export const red_bandana: ItemUI<Headgear> = {
  image,
  label: 'Red bandana',
  value: 15,
  item: {
    key: 'red_bandana',
    type: 'headgear',
    rarity: ItemRarity.Epic,
    stats: {
      agility: 15,
      energy: 2
    }
  }
}
