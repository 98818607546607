import { Skill, SkillConfig, Stats } from 'game/core'
import { Memoize } from 'game/util/memoize'

export interface ScholarConfig extends SkillConfig {
    intelligence: number
}

export class Scholar extends Skill<{}, ScholarConfig> {

    static KEY = 'Scholar' as const

    @Memoize()
    get intelligenceModifier(): number {
        return this.currentConfig.intelligence
    }

    getTargetStats(currentStats: Stats): Stats {
        return {
            ...currentStats,
            intelligence: currentStats.intelligence + this.intelligenceModifier
        }
    }
}
