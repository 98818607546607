import { JudgementHammerConfig } from 'game/extended/heros/paladin/judgement_hammer/judgement_hammer'

export const judgement_hammer_config: Array<JudgementHammerConfig> = [
    {
        available: 1,
        cooldown: 3,
        energy: 4,
        damage: 10
    },
    {
        available: 1,
        cooldown: 3,
        energy: 4,
        damage: 14
    },
    {
        available: 0,
        cooldown: 2,
        energy: 4,
        damage: 18
    }
]