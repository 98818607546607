import { Seduce, SeduceConfig } from 'game/extended/creatures/undead/abilities/seduce/seduce'
import { AbilityUI } from 'game/extended/uis/abilities/AbilityUI'
import React from 'react'

import image from './seduce.png'

export const seduce_ui: AbilityUI<'Seduce'> = {
  model: Seduce,
  configs: [
    {
      available: 0,
      cooldown: 4,
      duration: 3,
      energy: 2
    }
  ],
  image,
  label: 'Seduce',
  renderTargets: () => 'front',
  renderDescription: () => <>
    Seduce your target, making him/her unable to target you in any of his actions.
  </>,
  renderImpression: (ability: Seduce, UI) => <>
    <UI.Char character={ability.character} />{' '}seduces {ability.character.isFemale ? 'her' : 'his'} with an evil
    enchantment.
  </>,
  renderTableStatic: (config: SeduceConfig, UI) => <>
    <UI.TRow>
      <UI.TKey>Duration:</UI.TKey>
      <UI.TVal><UI.Turns turns={config.duration} /></UI.TVal>
    </UI.TRow>
  </>,
  renderTable: (ability: Seduce, UI) => <>
    <UI.TRow>
      <UI.TKey>Duration:</UI.TKey>
      <UI.TVal><UI.Turns turns={ability.duration} /></UI.TVal>
    </UI.TRow>
  </>
}
