import { Character, Event, Rule, RulePath } from 'game/core'
import { Memoize } from 'game/util/memoize'
import { Nullable } from 'game/util/maybe'

export class RuleStarted extends Event<{
    rule: RulePath
}> {

    static KEY = 'RuleStarted' as const

    @Memoize()
    get character(): Nullable<Character> {
        return this.rule?.character
    }

    @Memoize()
    get rule(): Nullable<Rule> {
        return Rule.byPath(this.battle, this.state.rule)
    }
}
