import { RequiredEffect } from 'game/core'
import { RequirementUI } from 'game/extended/uis/requirements/RequirementUI'
import React from 'react'

export const required_effect_ui: RequirementUI<'RequiredEffect'> = {
    model: RequiredEffect,
    label: 'Required effect',
    renderDescription: (state, UI) => <>
        This ability can only be casted when <UI.Effect effect={state.effect} /> effect is present.
    </>
}
