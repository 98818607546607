import { Requirement } from 'game/core'
import { Immobilized } from 'game/core/effects/immobilized'
import { Memoize } from 'game/util/memoize'

export class RequiredMobility extends Requirement {

    static KEY = 'RequiredMobility' as const

    @Memoize()
    get isValid() {
        return !this.character.effects.find(effect => effect instanceof Immobilized)
    }
}
