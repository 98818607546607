import { TypeState } from 'game/core'
import { AbilityKey, ConditionKey, TypeKey } from 'game/extended/types'
import { CreatureUI } from 'game/extended/uis/types/creature_uis'

export function createCreatureType(ui: CreatureUI, type: TypeKey, level: number): TypeState {
  const def = ui.definition(level)
  const abilitiesSet = abilitySetFromCreatureUI(ui, level)

  const rules = def.strategy.map(line => {
    if (Array.isArray(line)) {
      return {
        sequence: line,
        conditions: []
      }
    }

    if (typeof line === 'string') {
      return {
        sequence: [line],
        conditions: []
      }
    }

    return {
      sequence: (Array.isArray(line.abilities) ? line.abilities : [line.abilities]),
      conditions: (Array.isArray(line.conditions) ? line.conditions : [line.conditions]).map(cond => ({
        ...cond,
        key: cond.key as ConditionKey
      }))
    }
  })

  const state: TypeState = {
    kind: 'creature',
    type: type,
    stats: def.stats,
    abilities: Array.from(abilitiesSet).map(key => {
      return {
        key: key as AbilityKey,
        level: 1,
        lastUsage: false,
        lastTargets: [],
        available: true as const
      }
    }),
    skills: (def.skills || []).map(key => ({
      level: level,
      key: key
    })),
    level: level,
    rules: [
      ...rules,
      {
        conditions: [],
        sequence: ['Rest']
      }
    ]
  }

  return state
}

export function abilitySetFromCreatureUI(ui: CreatureUI, level = 1): Set<AbilityKey> {
  const def = ui.definition(level)
  const abilitiesSet: Set<AbilityKey> = new Set(['Rest'] as const)

  if (Array.isArray(def.strategy)) {
    def.strategy.forEach(row => {
      if (Array.isArray(row)) {
        row.forEach(key => abilitiesSet.add(key))
      } else if (typeof row === 'string') {
        abilitiesSet.add(row)
      } else {
        (Array.isArray(row.abilities) ? row.abilities : [row.abilities]).forEach(key =>
          abilitiesSet.add(key)
        )
      }
    })
  }
  return abilitiesSet
}