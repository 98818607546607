import { Character, CharacterPath, Event, FullInjury } from 'game/core'
import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'

export class CharDamaged extends Event<{
    injury: FullInjury,
    full_hp: number,
    old_hp: number,
    hp: number,
    character: CharacterPath
}> {
    static KEY = 'CharDamaged' as const

    @Memoize()
    get oldHp(): number {
        return this.state.old_hp
    }

    @Memoize()
    get newHp(): number {
        return this.state.hp
    }

    @Memoize()
    get injury(): FullInjury {
        return this.state.injury
    }

    @Memoize()
    get origin(): Nullable<Character> {
        return Character.byPath(this.battle, this.state.injury.damage.origin)
    }

    @Memoize()
    get target(): Nullable<Character> {
        return Character.byPath(this.battle, this.state.character)
    }
}
