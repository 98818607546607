import { EnhancedArmor, EnhancedArmorConfig } from 'game/extended/heros/paladin/enhanced_armor/enhanced_armor'
import { SkillUI } from 'game/extended/uis/skills/SkillUI'
import React from 'react'

import image from './enhanced_armor.png'

export const enhanced_armor_ui: SkillUI<'EnhancedArmor'> = {
  model: EnhancedArmor,
  configs: [
    { increase: 30 },
    { increase: 60 },
    { increase: 100 }
  ],
  image,
  label: 'Enhanced armor',
  renderDescription: (config: EnhancedArmorConfig) => <span>
    Your weakest kind of armor (melee, ranged or magical) is boosted by a percentage.
  </span>,
  renderTableStatic: (config: EnhancedArmorConfig, UI) => <>
    <UI.TRow>
      <UI.TKey>Increase:</UI.TKey>
      <UI.TVal>{config.increase}%</UI.TVal>
    </UI.TRow>
  </>,
  renderTable: (skill: EnhancedArmor, UI) => <>
    <UI.TRow>
      <UI.TKey>Increase:</UI.TKey>
      <UI.TVal>
        <UI.Stat stat={skill.stat} amount={skill.increase} percentage />
      </UI.TVal>
    </UI.TRow>
  </>
}
