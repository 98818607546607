import {
    Ability,
    AbilityPath,
    Character,
    CharacterPath,
    CollectEvent,
    Event,
    EventConstructor,
    EventState,
    GameModel,
    Interrupt,
    Modifier,
    Side,
    SideState,
    Skill,
} from 'game/core'
import { Condition } from 'game/core/condition'
import { BattleError } from 'game/core/errors/BattleError'
import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'

import { Effect } from './effect'

export type BattleState = {
    /**
     * Current game time
     */
    time: Turns

    /**
     * S counter used to generate unique id's for models
     */
    counter: number

    /**
     * A list of all events since the last round
     */
    events: Array<EventState>

    /**
     * The heros on the left side of the battle
     */
    left: SideState

    /**
     * The heros on the right side of the battle
     */
    right: SideState

    /**
     * A collection of all events throughout the entirebattle
     */
    history: Array<EventState>
}

export type Time = number
export type Ratio = number
export type Percentage = number
export type Turns = number


export type SetupData = {
    start: Battle,
    end: Battle,
    events: Array<Event>
}

export type TurnData = {
    character: CharacterPath,
    ability: Nullable<AbilityPath>,
    start: Battle,
    end: Battle,
    events: Array<Event>
}

export type RoundData = {
    turn: number,
    start: Battle,
    end: Battle,
    setup: SetupData,
    turns: Array<TurnData>
    finished: boolean
}

export class Battle extends GameModel<BattleState> {
    public static KEY = 'battle'

    public static initialState(): BattleState {
        return {
            time: 0,
            counter: 0,
            events: [],
            left: Side.initialState('left'),
            right: Side.initialState('right'),
            history: []
        }
    }

    @Memoize()
    get directChildren(): Array<GameModel> {
        return [this.left, this.right]
    }

    @Memoize()
    get battle() {
        return this
    }

    @Memoize()
    get history() {
        return this.state.history
    }

    @Memoize()
    get counter(): number {
        return this.state.counter
    }

    @Memoize()
    get time(): Time {
        return this.state.time
    }

    @Memoize()
    get actionsCount(): Time {
        //TODO: implement
        return 15
    }

    @Memoize()
    get isFinished(): boolean {
        if (!this.left.characters.length) return true
        if (!this.right.characters.length) return true
        return false
    }

    @Memoize()
    get left(): Side {
        return new Side(this, this.state.left)
    }

    @Memoize()
    get right(): Side {
        return new Side(this, this.state.right)
    }

    @Memoize()
    get events(): Array<Event> {
        return this.state.events.map(e => this.env.createModel(this, e))
    }

    @Memoize()
    get eventsFlat(): Array<Event> {
        return this.events.reduce(
            (acc: Array<Event>, e: Event) => [...acc, e, ...e.eventsFlat],
            []
        )
    }

    @Memoize()
    get charactersSequence(): Array<Character> {
        return this.characters.sort((a, b) => {
            return b.stats.agility - a.stats.agility
        })
    }

    @Memoize()
    get characters(): Array<Character> {
        return [...this.left.characters, ...this.right.characters]
    }

    @Memoize()
    get effects(): Array<Effect> {
        return this.characters.reduce((acc: Array<Effect>, char) => [...acc, ...char.effects], [])
    }

    @Memoize()
    get abilities(): Array<Ability> {
        return this.characters.reduce((acc: Array<Ability>, char) => [...acc, ...char.type.abilities], [])
    }

    @Memoize()
    get skills(): Array<Skill> {
        return this.characters.reduce((acc: Array<Skill>, char) => [...acc, ...char.type.skills], [])
    }

    @Memoize()
    get conditions(): Array<Condition> {
        return this.characters.reduce((acc: Array<Condition>, char) => [
            ...acc,
            ...char.type.rules.reduce((acc2: Array<Condition>, rule) => [
                ...acc2,
                ...rule.conditions
            ], [])
        ], [])
    }

    @Memoize()
    get modifiers(): Array<Modifier> {
        return this.characters.reduce((acc: Array<Modifier>, char) => [...acc, ...char.modifiers], [])
    }

    @Memoize()
    get activeEvent(): Nullable<Event> {
        const lastEvent = this.events[this.events.length - 1]
        if (lastEvent && lastEvent.activeEvent) return lastEvent.activeEvent
        return null
    }

    searchHistory<E extends Event>(constructor: { KEY: string, new(...args: any): E }): Array<E['state']> {
        const result: Array<E['state']> = []
        const processEvent = (e: EventState) => {
            if (e.key === constructor.KEY) result.push(e)
            e.events.forEach(e => processEvent(e))
        }
        this.history.forEach(e => processEvent(e))
        return result
    }

    // ------ Utility methods to simplify testing ----- //
    charByName(name: string): Character {
        const char = this.characters.find(char => char.name === name)
        if (!char) throw new Error(`No char found under this name: ${name}`)
        return char
    }

    abilityByType<A extends Ability>(constructor: { KEY: string, new(...args: any): A }): A {
        const ability = this.abilities.find(ability => ability.constructor === constructor)
        if (!ability) throw new Error(`No ability found for this type: ${constructor.KEY}`)
        return ability as A
    }

    skillByType<S extends Skill>(constructor: { KEY: string, new(...args: any): S }): S {
        const skill = this.skills.find(ability => ability.constructor === constructor)
        if (!skill) throw new Error(`No skill found for this type: ${constructor.KEY}`)
        return skill as S
    }

    conditionByType<C extends Condition>(constructor: { KEY: string, new(...args: any): C }): C {
        const condition = this.conditions.find(ability => ability.constructor === constructor)
        if (!condition) throw new Error(`No condition found for this type: ${constructor.KEY}`)
        return condition as C
    }

    effectByType<E extends Effect>(constructor: { KEY: string, new(...args: any): E }): E {
        const effect = this.effects.find(effect => effect.constructor === constructor)
        if (!effect) throw new Error(`No effect found for this type: ${constructor.KEY}`)
        return effect as E
    }

    // ----- Perform a turn ----- //
    setupRound(): SetupData {
        let battle: Battle = this

        try {
            const events = battle.events
            battle = battle
                .performIf(Side, { side: 'left' }, side => side.front.isEmpty, side => side.moveSupportToFront())
                .performIf(Side, { side: 'right' }, side => side.front.isEmpty, side => side.moveSupportToFront())
                .clearLogs()

            return {
                start: this,
                end: battle,
                events
            }
        }
        catch (error) {
            throw new BattleError(
                error,
                { key: 'setup', battle: this.state }
            )
        }
    }

    performRound(): RoundData {
        const setup = this.setupRound()
        let battle = setup.end
        let turns: Array<TurnData> = []
        let completed: Array<CharacterPath> = []
        let char: Nullable<Character> = null

        const wasFinishedAtStart = this.isFinished //Sometimes used during testing
        try {
            this.charactersSequence.forEach(ch => {
                if (!wasFinishedAtStart && battle.isFinished) return
                char = Character.byPath(battle, ch.path)
                if (!char) return
                const charging = char.chargingProcess.charging
                battle = char.performTurn()
                const events = battle.events
                battle = battle.clearLogs()
                turns.push({
                    end: battle,
                    character: char.path,
                    events: events,
                    start: char.battle,
                    ability: charging?.ability
                })
                completed.push(ch.path)
            })

            char = null
            battle = battle.update({ time: this.time + 1 })

            return {
                turn: this.time,
                start: this,
                end: battle,
                turns: turns,
                setup: setup,
                finished: battle.isFinished
            }

        } catch (error) {
            throw new BattleError(error, {
                key: 'round',
                battle: this.state,
                completed,
                active: (char as any)?.path || undefined
            })
        }
    }

    // ----- Calculate new State ----- //
    private update(newState: Partial<BattleState>): Battle {
        return new Battle(this.env, {
            ...this.state,
            ...newState
        })
    }

    updateSide(state: SideState): Battle {
        return this.update({
            [state.position]: state
        })
    }

    upCounter(): Battle {
        return this.update({
            counter: this.state.counter + 1
        })
    }

    collectEvent(): Battle {
        return this.startEvent(CollectEvent, {})
    }

    endCollectEvent<E extends Event>(eventClass: EventConstructor<E>, state: Omit<E['state'], keyof EventState>): Battle {
        return (this.activeEvent as CollectEvent)
            .changeType(eventClass, state)
            .endEvent()
    }

    updateEvent(event: EventState, newState: Partial<EventState>): Battle {
        return this.update({
            events: this.state.events.map(e =>
                e !== event ? e : { ...e, ...newState }
            )
        })
    }

    startEvent<E extends Event>(eventClass: EventConstructor<E>, state: Omit<E['state'], keyof EventState>): Battle {
        const eventState = {
            key: eventClass.KEY,
            id: 'E' + this.battle.counter,
            time: this.time,
            ended: false,
            events: [],
            ...state
        }

        if (this.activeEvent) {
            return this
                .activeEvent.addEvent(eventState)
                .upCounter()
        }

        return this
            .update({
                events: [
                    ...this.state.events,
                    eventState
                ]
            })
            .upCounter()
    }

    endEvent(): Battle {
        let newBattle: Interrupt = this
        let nextInterrupt: Interrupt = newBattle
        let handledModifiers = new Set()

        //Keep track of which modifiers responded to an event,
        //to avoid multiple calls (and potentially endless loops)
        while (nextInterrupt !== false) {
            newBattle = nextInterrupt
            nextInterrupt = false

            for (const modifier of newBattle.modifiers) {
                if (handledModifiers.has(modifier.id)) continue
                handledModifiers.add(modifier.id)
                if (!newBattle.activeEvent) throw new Error("Trying to end an event while none is actually active")
                nextInterrupt = modifier.reactToEvent(newBattle.activeEvent)
                if (nextInterrupt !== false) {
                    newBattle = nextInterrupt
                    break
                }
            }
        }

        if (!newBattle.activeEvent) throw new Error("Trying to end an event while none is actually active")
        return newBattle.activeEvent.end()
    }

    addEvent<E extends Event>(eventClass: EventConstructor<E>, state: Omit<E['state'], keyof EventState>): Battle {
        return this
            .startEvent(eventClass, state)
            .endEvent()
    }

    private clearLogs(): Battle {
        return new Battle(this.env, {
            ...this.state,
            events: [],
            history: [...this.state.history, ...this.state.events]
        })
    }
}

