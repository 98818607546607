import { AttackRange, DamageType, ItemRarity, Weapon } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './quick_sword.png'

export const quick_sword: ItemUI<Weapon> = {
    image,
    label: 'Quick sword',
    value: 60,
    item: {
        key: 'quick_sword',
        type: 'weapon',
        rarity: ItemRarity.Rare,
        stats: {
            agility: 40,
        },
        two_handed: false,
        category: 'sword',
        damageType: DamageType.Melee,
        range: AttackRange.Melee,
        energy: 1,
        damage: 8
    }
}
