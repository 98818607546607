import { CharDamageIgnored } from 'game/core'
import { EventUI } from "game/extended/uis/events/EventUI"
import React from 'react'

export const char_damage_ignored_ui: EventUI<'CharDamageIgnored'> = {
    model: CharDamageIgnored,

    render: (event: CharDamageIgnored, UI) => {
        return <>
            <UI.Char character={event.target} />
            {' ignores '}
            <UI.Damage damage={event.damage} />
            {` completely.`}
        </>
    }
}
