import 'views/replay/board/flavour.scss'

import { AbilityKey, EffectKey } from 'game/extended/types'
import { getAbilityUI } from 'game/extended/uis/abilities/ability_uis'
import { getEffectUI } from 'game/extended/uis/effects/effect_uis'
import { ReplayCharTurn } from 'models/replay/ReplayCharTurn'
import { ReplayTurn } from 'models/replay/ReplayTurn'
import React from 'react'
import { CharacterSlot } from 'views/layout/characterslot'
import { Cast, CastImpression, CastRoundVisual, CastText, CastVisual } from 'views/ui/cast'
import { DefaultUI as UI, DefautUIBattleContext } from 'views/ui/default_ui'

export const Flavour: React.FC<{
    turn: ReplayTurn
}> = ({ turn }) => {

    return <div className="flavour">
        <div className="flavour-cast">
            <FlavourCast turn={turn} />
        </div>
        <FlavourSequence turn={turn} />
    </div>
}

export const FlavourCast: React.FC<{
    turn: ReplayTurn
}> = ({ turn }) => {

    if (!(turn instanceof ReplayCharTurn)) {
        return <Cast>
            <CastRoundVisual round={turn.round.time} />
            <CastText>
                <CastImpression>
                    {turn.round.time === 0 ? 'The first round begins. ' : 'The next round starts. '}
                    {turn.start.left.front.isEmpty && 'Your frontline is empty, forcing your support units to fill this gap. '}
                    {turn.start.right.front.isEmpty && 'The enemy frontline is empty, forcing their support units to fill this gap.'}
                </CastImpression>
            </CastText>
        </Cast>
    }


    const ability = turn.activeAbility
    if (!ability) return null
    const key = ability.key as AbilityKey
    const ui = getAbilityUI(key)

    if (turn.interruptingEffect) {
        const effectKey = turn.interruptingEffect.effect.key as EffectKey
        const ui = getEffectUI(effectKey)
        return <Cast>
            <CastVisual src={ui.image} />
            <CastText>
                <CastImpression>
                    {'The '}<UI.Effect effect={effectKey} />
                    {' effect prevents '}<UI.Char character={ability.character} />
                    {' from executing '}
                    <UI.Ability ability={ability} />.
                </CastImpression>
            </CastText>
        </Cast>
    }

    return <Cast>
        <CastVisual src={ui.image} />
        <CastText>
            <CastImpression>
                <DefautUIBattleContext.Provider value={ability.battle}>
                    {ui.renderImpression(ability, UI)}
                </DefautUIBattleContext.Provider>
            </CastImpression>
        </CastText>
    </Cast>
}

export const FlavourSequence: React.FC<{
    turn: ReplayTurn
}> = ({ turn }) => {

    return <div className="flavour_sequence">
        {turn.round.turns.map(t =>
            !!t.activeChar && <CharacterSlot
                key={t.activeChar.id}
                type={t.activeChar.key}
                mod={{ faded: t !== turn }}
            />
        )}
    </div>
}
