import 'views/replay/replay.scss'

import { Nullable } from 'game/util/maybe'
import { MissionModel } from 'models/campaign/MissionModel'
import { ReplayTurn } from 'models/replay/ReplayTurn'
import { TurnChar } from 'models/replay/TurnChar'
import React, { createContext, useContext } from 'react'
import { Controls } from 'views/replay/board/controls'
import { Flavour } from 'views/replay/board/flavour'
import { Snapshot } from 'views/replay/board/snapshot'
import { CharPopup } from 'views/replay/charpopup'
import { Logs } from 'views/replay/logs/logs'
import { styler } from 'views/layout/util/style'
import { ImageType } from 'views/ui/ui_types'

const style = styler('replay')

export type ReplayContextPopupState =
    { char: TurnChar, tab: ReplayContextPopupCharTab }

export type ReplayContextPopupCharTab = 'effects' | 'abilities' | 'strategy' | 'equipment'

export type ReplayContextState = {
    background?: ImageType,
    mission?: MissionModel,
    turn: ReplayTurn
    popup: Nullable<ReplayContextPopupState>
    logs: boolean
    onClose: () => void
    setLogs: (open: boolean) => void
    setTurn: (turn: ReplayTurn) => void
    setPopup: (state: Nullable<ReplayContextPopupState>) => void
}

export const ReplayContext = createContext<ReplayContextState>(null as any)

export const Replay: React.FC = () => {
    const { background, mission, turn, logs, setLogs } = useContext(ReplayContext)

    return <div className={style()} >
        <CharPopup />
        {background && <div className={style('bg')} style={{ backgroundImage: `url("${background}")` }} />}
        <Logs turn={turn} open={logs} setOpen={setLogs} />
        <div className={style('content')}>
            <Flavour turn={turn} />
            <Snapshot background={mission?.chapter?.background} />
            <Controls />
        </div>
        {/*
            {
                turn.isLastTurn && this.finished && <Finished key="finished" onClose={() => this.finished = false}>
                    {victory && <Finished_victory end={this.turn.end}/>}
                </Finished>
            }
            {this.char && <CharacterPopup
                char={this.char}
                focusChar={(char: TurnChar) => this.char = char}
                close={() => this.char = null}
                />}
        */}
    </div>
}

