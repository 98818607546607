import { Ability, AbilityPerformed, Character, Environment, Equipment, ModelID, Modifier, Type } from 'game/core'
import { AbilityKey, TypeKey } from 'game/extended/types'
import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'
import { UnreachableCaseError } from 'game/util/never'
import { BASE_HERO_ABILITIES } from 'models/user/hero/HeroModel'
import { ReplayCharTurn } from 'models/replay/ReplayCharTurn'
import { ReplayTurn } from 'models/replay/ReplayTurn'

export type TurnCharState =
    { key: 'normal', start: Character, end: Character } |
    { key: 'new', end: Character } |
    { key: 'dead', start: Character }

export class TurnChar {
    turn: ReplayTurn
    state: TurnCharState

    constructor(turn: ReplayTurn, state: TurnCharState) {
        this.state = state
        this.turn = turn
    }

    @Memoize()
    get env(): Environment {
        return this.char.env
    }

    @Memoize()
    get characterID(): ModelID {
        return this.char.id
    }

    @Memoize()
    get name(): string {
        return this.char.name
    }

    @Memoize()
    get hp(): number {
        return this.char.hp
    }

    @Memoize()
    get maxHp(): number {
        return this.char.maxHp
    }

    @Memoize()
    get energy(): number {
        return this.char.energy
    }

    @Memoize()
    get maxEnergy(): number {
        return this.char.maxEnergy
    }

    @Memoize()
    get type(): Type {
        return this.char.type
    }

    @Memoize()
    get key(): TypeKey {
        return this.type.key as TypeKey
    }

    @Memoize()
    get equipment(): Equipment {
        return this.char.equipment
    }

    @Memoize()
    get index(): number {
        return this.turn.chars.indexOf(this)
    }

    @Memoize()
    get prev(): TurnChar {
        const prevIndex = this.index > 0 ? this.index - 1 : this.turn.chars.length - 1
        return this.turn.chars[prevIndex]
    }

    @Memoize()
    get next(): TurnChar {
        const nextIndex = this.index < this.turn.chars.length - 1 ? this.index + 1 : 0
        return this.turn.chars[nextIndex]
    }

    @Memoize()
    get hpDiff(): number {
        return this.hpEnd - this.hpStart
    }

    @Memoize()
    get energyDiff(): number {
        return this.energyEnd - this.energyStart
    }

    @Memoize()
    get hpStart(): number {
        switch (this.state.key) {
            case 'normal':
                return this.state.start.hp
            case 'new':
                return this.state.end.maxHp
            case 'dead':
                return this.state.start.hp
            default:
                throw new UnreachableCaseError(this.state)
        }
    }

    @Memoize()
    get hpEnd(): number {
        switch (this.state.key) {
            case 'normal':
                return this.state.end.hp
            case 'new':
                return this.state.end.hp
            case 'dead':
                return 0
            default:
                throw new UnreachableCaseError(this.state)
        }
    }

    @Memoize()
    get energyStart(): number {
        switch (this.state.key) {
            case 'normal':
                return this.state.start.energy
            case 'new':
                return this.state.end.maxEnergy
            case 'dead':
                return this.state.start.energy
            default:
                throw new UnreachableCaseError(this.state)
        }
    }

    @Memoize()
    get energyEnd(): number {
        switch (this.state.key) {
            case 'normal':
                return this.state.end.energy
            case 'new':
                return this.state.end.energy
            case 'dead':
                return this.state.start.energy
            default:
                throw new UnreachableCaseError(this.state)
        }
    }

    @Memoize()
    get abilityEnd(): Nullable<Ability> {
        switch (this.state.key) {
            case 'normal':
                return this.state.end.chargingAbility
            case 'new':
                return this.state.end.chargingAbility
            case 'dead':
                return this.state.start.chargingAbility
            default:
                throw new UnreachableCaseError(this.state)
        }
    }

    @Memoize()
    get abilityStart(): Nullable<Ability> {
        switch (this.state.key) {
            case 'normal':
                return this.state.start.chargingAbility
            case 'new':
                return null
            case 'dead':
                return this.state.start.chargingAbility
            default:
                throw new UnreachableCaseError(this.state)
        }
    }

    @Memoize()
    get id(): string {
        return this.char.id
    }

    @Memoize()
    get isActive(): boolean {
        if (this.turn instanceof ReplayCharTurn) return this.turn.activeChar === this
        return true
    }

    @Memoize()
    get isStunned(): boolean {
        return this.char.isStunned
    }

    @Memoize()
    get isTargeted(): boolean {
        return !!this.turn.events
            .filter(e => e instanceof AbilityPerformed)
            .find(e => !!(e as AbilityPerformed).targets.find(ch => ch.id === this.id))
    }

    @Memoize()
    get isDead(): boolean {
        return this.state.key === 'dead'
    }

    @Memoize()
    get horizontal(): number {
        switch (this.char.side.position) {
            case 'left':
                return this.char.line.position === 'front' ? 1 : 0
            case 'right':
                return this.char.line.position === 'front' ? 2 : 3
            default:
                throw new UnreachableCaseError(this.char.side.position)
        }
    }

    @Memoize()
    get vertical(): number {
        return this.char.pos
    }

    @Memoize()
    get char(): Character {
        switch (this.state.key) {
            case 'normal': return this.state.end
            case 'new': return this.state.end
            case 'dead': return this.state.start
            default: throw new UnreachableCaseError(this.state)
        }
    }

    @Memoize()
    get specializedAbilities(): Array<Ability> {
        const map = new Set(BASE_HERO_ABILITIES)
        return this.char.type.abilities
            .filter(ab => !map.has(ab.key as AbilityKey))
    }

    @Memoize()
    get regularAbilities(): Array<Ability> {
        const map = new Set(BASE_HERO_ABILITIES)
        return this.char.type.abilities
            .filter(ab => map.has(ab.key as AbilityKey))
    }

    @Memoize()
    get modifiers(): Array<Modifier> {
        return this.char.modifiers
    }
}
