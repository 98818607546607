import { DamageType } from 'game/core'
import { ChargeBomb, ChargeBombConfig } from 'game/extended/creatures/goblins/abilities/charge_bomb/charge_bomb'
import { AbilityUI } from 'game/extended/uis/abilities/AbilityUI'
import React from 'react'

import image from './charge_bomb.png'

export const charge_bomb_ui: AbilityUI<'ChargeBomb'> = {
    model: ChargeBomb,
    configs: [
        {
            available: 0,
            cooldown: 1,
            energy: 1,
            explode_damage: 20
        }
    ],
    image,
    label: 'Charge bomb',
    renderTargets: () => 'Yourself',
    renderDescription: () => <>
        Charge your next bomb. Will explode on the spot unless tossed!
    </>,
    renderImpression: (ability: ChargeBomb) => <>
        The goblin lits the fuse of his next bomb.
    </>,
    renderTableStatic: (config: ChargeBombConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Explode damage:</UI.TKey>
            <UI.TVal>
                <UI.Damage damage={config.explode_damage} type={DamageType.Melee} />
            </UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (ability: ChargeBomb, UI) => <>
        <UI.TRow>
            <UI.TKey>Explode damage:</UI.TKey>
            <UI.TVal><UI.Damage damage={ability.damage} /></UI.TVal>
        </UI.TRow>
    </>
}
