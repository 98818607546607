import './leveldot.scss'

import { LevelModel } from 'models/user/LevelModel'
import React from 'react'
import { TutorialHint, useHinter } from 'views/ingame/campaign/tutorial'
import { styler } from 'views/layout/util/style'
import { Link } from 'views/link'
import { DOMAttrs } from 'views/ui/ui_types'

import levelSvg from './goblins_level.svg'
import levelFinishedSvg from './goblins_level_finished.svg'
import starSvg from './star.svg'

const styles = styler('leveldot', ['stars'])

export const Leveldot: React.FC<{
    level: LevelModel
    link: string
    style?: {}
} & DOMAttrs> = ({ level, link, style }) => {
    const isFinished = level.status.key === 'finished'
    const locked = level.status.key === 'locked'
    const svg = isFinished ? levelFinishedSvg : levelSvg
    const hint = useHinter()
    const hinted = hint.key === 'level' && level.battle_id === hint.mission

    return <Link style={style} className={styles().is({ locked, hinted })} to={link}>
        {hinted && <TutorialHint />}
        <img className="leveldot-visual" src={svg} alt="test" />
        <div className="leveldot-number">
            {level.number}
        </div>
        {level.status.key === 'finished' && (
            <div className="leveldot-stars">
                <LeveldotStars stars={level.status.stars} />
            </div>
        )}
    </Link>
}
export const LeveldotStars: React.FC<{
    stars: number
}> = ({ stars }) => {

    return <div className={styles.stars()}>
        {[1, 2, 3].map(amount => {
            if (stars < amount) return null
            return <img
                key={amount}
                className={styles.stars('star').is({ active: stars >= amount })}
                src={starSvg}
                alt="Achievent star"
            />
        })}
    </div>
}
