import { TurnChar } from 'models/replay/TurnChar'
import React, { useContext } from 'react'
import { Button } from 'views/layout/button'
import { Bg } from 'views/layout/util/bg'
import { styler } from 'views/layout/util/style'
import { Card } from 'views/replay/board/card'
import 'views/replay/board/snapshot.scss'
import { ReplayContext } from 'views/replay/replay'
import { ImageType } from 'views/ui/ui_types'


const style = styler('snapshot', ['tile', 'char', 'message'])

export const Snapshot: React.FC<{
    background?: ImageType
}> = ({ background }) => {
    const { turn } = useContext(ReplayContext)

    return <div className="snapshot">
        {background && <Bg image={background} />}
        <div className="snapshot-tiles">
            {[0, 1, 2, 3].map(x =>
                [0, 1, 2, 3].map(y =>
                    <SnapshotTile x={x} y={y} key={x + '_' + y} />
                )
            )}
        </div>
        <div className="snapshot-chars">
            {turn.chars.map(char =>
                <SnapshotChar
                    char={char}
                    key={char.id}
                />
            )}
        </div>
        <SnapshotMessage />
    </div>
}

export const SnapshotTile: React.FC<{
    x: number,
    y: number
}> = ({ x, y }) => {

    const transform = `translate(
        ${x * 100}%,
        ${y * 100}%
    )`
    const mod = {
        odd: (x + y) % 2 === 0,
        even: (x + y) % 2 === 1
    }
    return <div
        className={style.tile().mod(mod)}
        style={{ transform }}
    />
}

export const SnapshotChar: React.FC<{
    char: TurnChar
}> = ({ char }) => {

    const transform = `translate(
        ${char.horizontal * 100}%,
        ${char.vertical * 100 + 150}%
    )`
    const reversed = char.char.side.position === 'left'

    return <div className={style.char()} style={{ transform }} >
        <Card
            char={char}
            reversed={reversed}
        />
    </div>
}

export const SnapshotMessage: React.FC = () => {
    const { turn, onClose } = useContext(ReplayContext)

    const isVisible = !turn.nextTurn
    const isVictorious = turn.round.replay.isVictorious

    return <div className={style.message().mod({ victory: isVictorious }).is({ visible: isVisible })} >
        <div className={style.message('message')}>
            {isVictorious ? 'You win!' : 'You lose!'}
        </div>
        <div className={style.message('button')}>
            <Button mod={{ negative: !isVictorious }} onClick={onClose}>Continue</Button>
        </div>
    </div>
}