import { CharDamaged, DamageNature, DamageType, Event, FullDamage, Interrupt, Skill, SkillConfig, Turns } from 'game/core'
import { AfterburnEffect } from 'game/extended/heros/mage/afterburn/afterburn_effect'
import { Memoize } from 'game/util/memoize'

export interface AfterburnConfig extends SkillConfig {
    damage: number
    vulnerability: number
    turns: Turns
}

export class Afterburn extends Skill<{}, AfterburnConfig>{

    static KEY = 'Afterburn' as const

    @Memoize()
    get damage(): FullDamage {
        return this.character.calculateDamage(this, DamageType.Magic, DamageNature.Recurrent, this.currentConfig.damage)
    }

    @Memoize()
    get turns(): Turns {
        return this.currentConfig.turns
    }

    @Memoize()
    get vulnerability(): number {
        return this.currentConfig.vulnerability
    }

    @Memoize()
    reactToEvent(event: Event): Interrupt {
        if (!(event instanceof CharDamaged)) return false
        if (event.origin !== this.character) return false
        if (event.injury.damage.type !== DamageType.Magic) return false
        if (event.injury.damage.nature === DamageNature.Recurrent) return false
        if (!event.target) return false

        return event.target.addEffect(AfterburnEffect, {
            origin: this.character.path,
            damage: this.damage,
            vulnerability: this.vulnerability,
            turns: this.turns
        })
    }
}