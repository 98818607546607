import { Consume, ConsumeConfig } from 'game/extended/creatures/goblins/abilities/consume/consume'
import { AbilityUI } from 'game/extended/uis/abilities/AbilityUI'
import React from 'react'

import image from './consume.png'
import { consume_config } from 'game/extended/creatures/goblins/abilities/consume/consume_config'

export const consume_ui: AbilityUI<'Consume'> = {
    model: Consume,
    configs: consume_config,
    image,
    label: 'Consume',
    renderTargets: () => 'Allied neighbour',
    renderDescription: () => <>
        Consume the weakest nearby ally to regain full health and energy.
    </>,
    renderImpression: (ability: Consume, UI) => <>
        Without any sign of mercy{' '}<UI.Char character={ability.character} />{' '}consumes the wriggling
        and screaming{' '}<UI.Char character={ability.targets} />.
    </>,
    renderTableStatic: (config: ConsumeConfig, UI) => <></>,
    renderTable: (ability: Consume, UI) => <></>
}
