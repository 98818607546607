import { SkillKey, skills } from 'game/extended/types'
import { getSkillUI } from 'game/extended/uis/skills/skill_uis'
import { keysof } from 'game/util/keysof'
import React from 'react'
import { useParams } from 'react-router'
import { LibraryLink, LibraryList, LibrarySkill, LibraryUI } from 'views/library/library'
import { SkillVisual } from 'views/ui/visuals/skillvisual'

export const Skills: React.FC = () => {
    const { type, tab } = useParams<{ type: SkillKey, tab: string }>()

    const ui = type && getSkillUI(type)
    const level = tab ? parseInt(tab) : false

    return <LibraryUI category="skills">
        <LibraryList flex={0.25}>
            {keysof(skills).map(key => {
                const ui = getSkillUI(key)
                return <LibraryLink
                    to={`/library/skills/${key}/0`}
                    active={type === key}
                    key={key}
                >
                    <SkillVisual skill={key} />
                    {ui.label}
                </LibraryLink>
            })}
        </LibraryList>
        {ui && <LibraryList flex={0.15}>
            {ui.configs.map((conf, index) => (
                <LibraryLink
                    to={`/library/skills/${type}/${index}`}
                    active={level === index}
                    key={index}
                >
                    Level {index + 1}
                </LibraryLink>
            ))}
        </LibraryList>}
        {level !== false && <LibraryList flex={0.6}>
            <LibrarySkill skill={type} level={level + 1} />
        </LibraryList>}
    </LibraryUI>
}