import { ArcaneCurseConfig } from 'game/extended/heros/mage/arcane_curse/arcane_curse'

export const arcane_curse_config: Array<ArcaneCurseConfig> = [
    {
        available: 3,
        cooldown: 4,
        energy: 3,
        max_energy: 5,
        duration: 2
    },
    {
        available: 2,
        cooldown: 4,
        energy: 2,
        max_energy: 4,
        duration: 2
    },
    {
        available: 1,
        cooldown: 4,
        energy: 2,
        max_energy: 3,
        duration: 2
    }
]