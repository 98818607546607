import { Headgear, ItemRarity } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './straw_hat.png'

export const straw_hat: ItemUI<Headgear> = {
    image,
    label: 'Straw hat',
    value: 15,
    item: {
        key: 'straw_hat',
        type: 'headgear',
        rarity: ItemRarity.Common,
        stats: {
            health: 15
        }
    }
}
