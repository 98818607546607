import {
    Character,
    CharDamaged,
    CharDied,
    FullHealing,
    FullInjury,
    Interrupt,
    Percentage,
    Skill,
    SkillTriggered,
    Time,
    Turns,
} from 'game/core'
import { Memoize } from 'game/util/memoize'

export type ReincarnationConfig = {
    health: Percentage
    cooldown: Turns
}

export type ReincarnationState = {
    triggered?: Time
}

export class Reincarnation extends Skill<ReincarnationState, ReincarnationConfig>{

    static KEY = 'Reincarnation' as const

    @Memoize()
    get cooldown(): Turns {
        return this.currentConfig.cooldown
    }

    @Memoize()
    get health(): Percentage {
        return this.currentConfig.health
    }

    @Memoize()
    get healing(): FullHealing {
        const healingAmount = Math.ceil(this.character.maxHp * this.currentConfig.health / 100)
        return {
            origin: this.path,
            amount: healingAmount,
            modifiers: [],
            original_amount: healingAmount
        }
    }

    @Memoize()
    get availableAtTime(): Time | true {
        if (this.state.triggered === undefined) return true
        return this.state.triggered + this.currentConfig.cooldown
    }

    @Memoize()
    get availableIn(): Turns {
        if (this.availableAtTime === true) return 0
        if (this.availableAtTime <= this.time) return 0
        return this.availableAtTime - this.time
    }

    // ----- Interrupts ----- //
    interruptOnDeath(target: Character, injury: FullInjury): Interrupt {
        if (target !== this.character) return false
        if (this.availableIn > 0) return false

        const newHp = this.character.hp - injury.amount
        return this.battle
            .addEvent(CharDamaged, { injury, old_hp: this.character.hp, hp: newHp, character: this.path, full_hp: this.character.stats.health })
            .addEvent(CharDied, { injury, character: this.path, name: this.character.name })
            .perform(Character, this.character.path, char => char.setHp(0))
            .perform(Character, this.character.path, char => char.setEnergy(char.maxEnergy))
            .startEvent(SkillTriggered, { skill: this.path })
            .perform(Character, this.character.path, char => char.heal(this.healing))
            .perform(Reincarnation, this.path, skill => (skill as Reincarnation).update({ triggered: this.time }))
            .endEvent()
    }
}
