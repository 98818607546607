import { AttackRange, DamageType, ItemRarity, Weapon } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './demon_sword.png'

export const demon_sword: ItemUI<Weapon> = {
    image,
    label: 'Demon sword',
    value: 150,
    item: {
        key: 'demon_sword',
        type: 'weapon',
        rarity: ItemRarity.Legendary,
        stats: {
            strength: 65,
            agility: 40,
            intelligence: 40,
            resistance: 30,
            energy: 3
        },
        two_handed: false,
        category: 'sword',
        damageType: DamageType.Melee,
        range: AttackRange.Melee,
        energy: 1,
        damage: 12
    }
}
