import { Character, FullInjury, Interrupt, Skill, SkillTriggered, Stats } from 'game/core'
import { Memoize } from 'game/util/memoize'

export type MountedConfig = {
    unmounted_stats: Stats
}

export class Mounted extends Skill<{}, MountedConfig> {

    static KEY = 'Mounted' as const

    @Memoize()
    get unmountedStats() {
        return this.currentConfig.unmounted_stats
    }

    // ----- Interrupts ----- //
    interruptOnDeath(target: Character, injury: FullInjury): Interrupt {
        if (target !== this.character) return false

        return this.battle
            .startEvent(SkillTriggered, { skill: this.path })
            .perform(Character, this.character.path, char => {
                const type = char.type
                return char.update({
                    type: {
                        ...type.state,
                        type: 'goblin_chief',
                        skills: (type.state.skills || []).filter(state => state.key !== Mounted.KEY),
                        stats: this.unmountedStats
                    },
                    hp: 'full'
                })
            })
            .endEvent()
    }

}  