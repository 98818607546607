import { PainStimulus, PainStimulusConfig } from 'game/extended/heros/barbarian/pain_stimulus/pain_stimulus'
import image from 'game/extended/heros/barbarian/pain_stimulus/pain_stimulus.png'
import { pain_stimulus_config } from 'game/extended/heros/barbarian/pain_stimulus/pain_stimulus_config'
import { SkillUI } from 'game/extended/uis/skills/SkillUI'
import React from 'react'

export const pain_stimulus_ui: SkillUI<'PainStimulus'> = {
    model: PainStimulus,
    configs: pain_stimulus_config,
    image,
    label: 'Pain Stimulus',
    renderDescription: (config: PainStimulusConfig) => <span>
        Whenever your hp drops below a threshold, gain an instant energy boost.
    </span>,
    renderTableStatic: (config: PainStimulusConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Threshold</UI.TKey>
            <UI.TVal><UI.Stat stat="health" amount={config.hp} percentage /></UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Energy</UI.TKey>
            <UI.TVal><UI.Stat stat='energy' amount={config.increase} /></UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (skill: PainStimulus, UI) => <>
        <UI.TRow>
            <UI.TKey>Threshold</UI.TKey>
            <UI.TVal><UI.Stat stat="health" amount={skill.cutoffPercentage} percentage /></UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Energy</UI.TKey>
            <UI.TVal><UI.Stat stat='energy' amount={skill.energyIncrease} /></UI.TVal>
        </UI.TRow>
    </>
}
