import { Item } from 'game/core'
import { ItemKey } from 'game/extended/types'
import { getItemUI } from 'game/extended/uis/item_uis'
import React from 'react'
import { Weaponstats } from 'views/ingame/user/team/equip/weaponstats'
import { ItemStats } from 'views/ingame/user/team/itemstats'
import { Info, InfoCols, InfoDescription, InfoLabel, InfoVisual } from 'views/layout/info'
import { ItemVisual } from 'views/ui/visuals/itemvisual'

export const ItemInfo: React.FC<{
    item: Item
}> = ({ item }) => {

    const ui = getItemUI(item.key as ItemKey)

    return <Info>
        <InfoLabel>{ui ? ui.label : item.key}</InfoLabel>
        <InfoCols>
            <InfoVisual>
                <ItemVisual item={item} />
            </InfoVisual>
            <InfoDescription>
                {item.type === 'weapon' && <Weaponstats weapon={item} />}
                <ItemStats item={item} />
            </InfoDescription>
        </InfoCols>
    </Info>
}
