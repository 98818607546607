import { ClassKey } from 'game/extended/types'
import { Memoize } from 'game/util/memoize'

export type EndlessResultState = {
  levels: number,
  heroes: Array<{
    type: ClassKey,
    achievements: number,
    level: number
  }>
}

export class EndlessResultModel {

  constructor(readonly state: EndlessResultState) { }

  @Memoize()
  get levels() {
    return this.state.levels
  }
}
