import {
  Ability,
  AbilityConfig,
  Character,
  DamageNature,
  DamageType,
  FullDamage,
  Percentage,
  RequiredWeaponState,
  Requirement,
} from 'game/core'
import { Memoize } from 'game/util/memoize'

export type ShieldPushConfig = AbilityConfig & {
  percentage: Percentage
}

export class ShieldPush extends Ability<ShieldPushConfig>{

  static KEY = 'ShieldPush' as const
  static FIXED_REQUIREMENTS = [
    Requirement.init(RequiredWeaponState, { type: 'shield' })
  ]

  @Memoize()
  get targets() {
    return this.character.meleeTarget
  }

  @Memoize()
  get percentage(): Percentage {
    return this.currentConfig.percentage
  }

  @Memoize()
  get damage(): FullDamage {
    const amount = Math.round(this.character.stats.armor * this.percentage / 100)
    return {
      amount,
      type: DamageType.Melee,
      nature: DamageNature.Melee,
      modifiers: [],
      origin: this.path,
      original_amount: amount
    }
  }

  // ----- Calculate new State ----- //
  performActionImpl() {
    if (!this.targets) return this.battle
    return this.targets
      .takeDamage(this.damage)
      .perform(Character, this.targets.path, char => char.moveToSupport())
  }
}


