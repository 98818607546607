import { Ability, AbilityConfig, Character } from 'game/core'
import { Memoize } from 'game/util/memoize'

export type ChaosConfig = AbilityConfig & {
    energy_loss: number
}

export class Chaos extends Ability<ChaosConfig> {

    static KEY = 'Chaos' as const
    static FIXED_REQUIREMENTS = []

    @Memoize()
    get targets() {
        return this.character.allEnemies
    }

    @Memoize()
    get energyLoss() {
        return this.currentConfig.energy_loss
    }

    // ----- Calculate new State ----- //
    performActionImpl() {
        if (!this.targets) return this.battle

        return this.performOnAllTargets(this.applyToCharacter)
    }

    private applyToCharacter = (char: Character) => {
        return char
            .switchLine()
            .perform(Character, char.path, char => char.interrupt())
            .perform(Character, char.path, char => char.changeEnergy(-this.energyLoss))
    }
}