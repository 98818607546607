import { CreatureUI } from 'game/extended/uis/types/creature_uis'
import head from 'game/extended/creatures/goblins/goblin_chief/goblin_chief_head.svg'
import full from 'game/extended/creatures/goblins/goblin_chief/goblin_chief_mounted.svg'
import circle from 'game/extended/creatures/goblins/goblin_chief/goblin_chief_mounted_circle.svg'
import { goblin_chief } from 'game/extended/creatures/goblins/goblin_chief/goblin_chief'

export const goblin_chief_mounted: CreatureUI = {
    label: 'Mounted Goblin chief',
    definition: lvl => {
        const res = goblin_chief.definition(lvl)
        return {
            ...res,
            stats: {
                ...res.stats,
                health: 250 + lvl * 30
            },
            skills: ['Mounted', ...(res.skills || [])]
        }
    },
    full,
    circle: {
        src: circle,
        width: 200,
        height: 150
    },
    head
}
