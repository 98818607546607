import { AttackRange, DamageType, ItemRarity, Weapon } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './bone_club.png'

export const bone_club: ItemUI<Weapon> = {
  image,
  label: 'Bone club',
  value: 10,
  item: {
    key: 'bone_club',
    type: 'weapon',
    rarity: ItemRarity.Common,
    stats: {
      strength: 30,
      intelligence: -20
    },
    two_handed: false,
    category: 'club',
    damageType: DamageType.Melee,
    range: AttackRange.Melee,
    energy: 1,
    damage: 6
  }
}
