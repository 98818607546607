import {
    Ability,
    AbilityConfig,
    Character,
    DamageNature,
    DamageType,
    FullDamage,
    RequiredWeaponState,
    Requirement,
} from 'game/core'
import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'

export type PushbackArrowConfig = AbilityConfig & {
    damage: number
}

export class PushbackArrow extends Ability<PushbackArrowConfig>{

    static KEY = 'PushbackArrow' as const
    static FIXED_REQUIREMENTS = [
        Requirement.init(RequiredWeaponState, { type: 'ranged' })
    ]

    @Memoize()
    get targets(): Nullable<Character> {
        return this.character.frontTarget
    }

    @Memoize()
    get damage(): FullDamage {
        return this.calculateDamage(
            DamageType.Melee,
            DamageNature.Ranged,
            this.currentConfig.damage
        )
    }

    // ----- State change inquiry ----- //
    performActionImpl() {
        if (!this.targets) return this.battle
        return this.targets
            .takeDamage(this.damage)
            .perform(Character, this.targets.path, (char: Character) =>
                char.moveToSupport()
            )
    }
}
