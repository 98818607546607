import { Armor, ItemRarity } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './skeleton_wizard_robe.png'

export const skeleton_wizard_robe: ItemUI<Armor> = {
  image,
  label: 'Skeleton wizard robe',
  value: 55,
  item: {
    key: 'skeleton_wizard_robe',
    type: 'armor',
    rarity: ItemRarity.Epic,
    stats: {
      health: 15,
      shielding: 15,
      agility: 25,
      intelligence: 25
    }
  }
}
