import { Ability, AbilityConfig, Character, RequiredRoom, Requirement, Rest } from 'game/core'
import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'

export type HitAndRunConfig = AbilityConfig

export class HitAndRun extends Ability<HitAndRunConfig> {

    static KEY = 'HitAndRun' as const
    static FIXED_REQUIREMENTS = [
        Requirement.init(RequiredRoom, { pos: 'front', include_self: true }),
        Requirement.init(RequiredRoom, { pos: 'support', include_self: true })
    ]

    @Memoize()
    get targets() {
        return this.character
    }

    @Memoize()
    get abilityToCast(): Nullable<Ability> {
        const battle = this.character.moveToFront()
        const ability = HitAndRun.byPath(battle, this.path)
        if (!ability) return null
        const result = ability.chargingAbilityIgnoringThis
        if (!result) return null
        if (result instanceof Rest) return null
        return result
    }

    // ----- Calculate new State ----- //
    performActionImpl() {
        const nextAbility = this.abilityToCast
        return this.battle
            .perform(Character, this.character.path, char => char.moveToFront())
            .perform(Ability, nextAbility?.path, ab => ab.execute())
            .perform(Character, this.character.path, char => char.moveToSupport())
    }
}