import image from './penetrating_blows.png'
import { PenetratingBlowsEffect } from 'game/extended/heros/paladin/penetrating_blows/penetrating_blows_effect'
import { EffectUI } from 'game/extended/uis/effects/EffectUI'
import React from 'react'

export const penetrating_blow_effect_ui: EffectUI<'PenetratingBlowsEffect'> = {
    model: PenetratingBlowsEffect,
    label: 'Pentrating blows effect',
    image,
    renderDescription: (effect: PenetratingBlowsEffect, UI) => <>
        Your resistance and armor are reduced.
    </>,
    renderTable: (effect: PenetratingBlowsEffect, UI) => <>
        <UI.TRow>
            <UI.TKey>Resistance:</UI.TKey>
            <UI.TVal><UI.Stat stat='resistance' amount={effect.reduction} /></UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Armor:</UI.TKey>
            <UI.TVal><UI.Stat stat='armor' amount={effect.reduction} /></UI.TVal>
        </UI.TRow>
    </>
}
