import 'views/layout/strategy/strategy.scss'
import React from 'react'
import { DOMAttrs } from 'views/ui/ui_types'
import { styler } from 'views/layout/util/style'
import { Icon } from 'views/ui/icon'

const style = styler('strategy', ['items', 'slot'])

export const Strategy: React.FC<{
    index: number
    onUp?: () => void
}> = ({ index, onUp, children }) => {

    return <div className={style()}>
        {onUp && <div className={style('up')} onClick={onUp} >
            <Icon icon="up" />
        </div>}
        <div className={style('number')}>
            {index}
        </div>
        {children}
    </div>
}

export const StrategyItems: React.FC<{
    title: string
}> = ({ title, children }) => {
    return <div className={style.items()}>
        <div className={style.items('title')}>{title}</div>
        <div className={style.items('row')}>
            {children}
        </div>
    </div>
}

export const StrategySlot: React.FC<DOMAttrs> = ({ children, ...attrs }) => {
    return <div {...attrs} className={style.slot().mergeProps(attrs)}>
        {children}
    </div>
}
