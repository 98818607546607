import { Ability, AbilityConfig, Character, DamageNature, DamageType, FullDamage, Percentage } from 'game/core'
import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'

export type ChainLightningConfig = AbilityConfig & {
    targets: number
    damage: number
    decrease: Percentage
}

export class ChainLightning extends Ability<ChainLightningConfig>{

    static KEY = 'ChainLightning' as const
    static FIXED_REQUIREMENTS = []

    @Memoize()
    get targets(): Nullable<Character> {
        const minRatio = Math.min(...this.character.allEnemies.map(char => char.hpRatio))
        const minHpEnemy = this.character.allEnemies.find(char => char.hpRatio === minRatio)
        return minHpEnemy
    }

    @Memoize()
    get decrease(): Percentage {
        return this.currentConfig.decrease
    }

    @Memoize()
    get initialDamage(): FullDamage {
        return this.calculateDamage(
            DamageType.Magic,
            DamageNature.Ranged,
            this.currentConfig.damage
        )
    }

    getDamageForTarget(targetIndex: number): FullDamage {
        const amount = Math.round(this.currentConfig.damage * (100 - this.currentConfig.decrease * targetIndex) / 100)
        return this.calculateDamage(
            DamageType.Magic,
            DamageNature.Ranged,
            amount
        )
    }

    // ----- Calculate new State ----- //
    performActionImpl() {
        if (!this.targets) return this.battle

        let battle = this.battle
        let target = this.targets

        for (let i = 0; i < this.currentConfig.targets; i++) {
            const ability = Ability.byPath(battle, this.path) as Nullable<ChainLightning>
            if (!ability) break

            const damage = ability.getDamageForTarget(i)
            battle = target.takeDamage(damage)

            const targetInNewBattle = Character.byPath(battle, target.path)
            if (!targetInNewBattle) break
            if (!targetInNewBattle.alliesArround.length) break

            const surroundingAllies = targetInNewBattle.alliesArround
            const minRatio = Math.min(...surroundingAllies.map(char => char.hpRatio))
            target = surroundingAllies.find(char => char.hpRatio === minRatio) as Character
        }

        return battle
    }
}
