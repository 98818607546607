import { DamageType } from 'game/core'
import { Inferno, InfernoConfig } from 'game/extended/heros/mage/inferno/inferno'
import image from 'game/extended/heros/mage/inferno/inferno.png'
import { AbilityUI } from 'game/extended/uis/abilities/AbilityUI'
import React from 'react'
import { inferno_config } from 'game/extended/heros/mage/inferno/inferno_config'

export const inferno_ui: AbilityUI<'Inferno'> = {
    model: Inferno,
    configs: inferno_config,
    image,
    label: 'Inferno',
    renderTargets: () => 'Frontline',
    renderDescription: () => <>
        Fire bolts rain from the sky damaging all enemy units in the frontline.
    </>,
    renderImpression: (ability: Inferno, UI) => <>
        <UI.Char character={ability.character} />{' '}lifts his hands into the air and
        a wave of fire bolts rain from the sky hitting{' '}<UI.Char character={ability.targets} />.
    </>,
    renderTableStatic: (config: InfernoConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Damage:</UI.TKey>
            <UI.TVal><UI.Damage type={DamageType.Magic} damage={config.damage} /></UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (ability: Inferno, UI) => <>
        <UI.TRow>
            <UI.TKey>Damage:</UI.TKey>
            <UI.TVal><UI.Damage damage={ability.damage} /></UI.TVal>
        </UI.TRow>
    </>
}
