import { Mod } from 'views/ui/ui_types'

const cache = new Map()

export type Styler = {
    (sub?: string | ClassName): ClassName
} & ClassName

// Fool react typings into accepting this as a className
export type ClassName = string & {
    className: string
} & ClassApi

// Fool typescript into marking these properties as non enumerable so
// we can jsx spread the result
class ClassApi {
    with(...extra: Array<string | Styler>): Styler {
        throw new Error('assert')
    }
    mergeProps(attrs: { [key: string]: any }): Styler {
        throw new Error('assert')
    }
    sub(sub: string): Styler {
        throw new Error('assert')
    }
    is(state: Record<string, boolean | undefined | null>): Styler {
        throw new Error('assert')
    }
    toSelector(): string {
        throw new Error('assert')
    }
    mod(modifier: Mod<any>): Styler {
        throw new Error('assert')
    }
    toString(): string {
        throw new Error('assert')
    }
}

const states = (
    selector: string | Styler,
    name: string,
    state: Record<string, boolean>
) =>
    className(String(selector)).with(
        ...Object.entries(state)
            .map(([cl, active]) => active && cl)
            .filter(v => v)
            .map(cl => `${name}-${cl}`)
    )

const createStyler = (input: Object | Function, value: string) => {

    // @ts-ignore
    input['className'] = String(value)
    Object.defineProperties(input, {
        with: {
            enumerable: false,
            value(...extra: Array<string | ClassName>) {
                return className(
                    []
                        // @ts-ignore
                        .concat(extra)
                        // @ts-ignore
                        .concat(value)

                        // @ts-ignore
                        .filter(v => v)
                        .join(' ')
                )
            }
        },
        mergeProps: {
            enumerable: false,
            value(props: { [key: string]: any }) {
                return className(value).with(props.class, props.className)
            }
        },
        sub: {
            enumerable: false,
            value(sub: string) {
                return styler(`${value}_${sub}`)
            }
        },
        is: {
            enumerable: false,
            value(state: Record<string, boolean>) {
                return states(value, 'is', state)
            }
        },
        mod: {
            enumerable: false,
            value(modifier: Mod<any>) {
                if (!modifier) return this
                if (Array.isArray(modifier))
                    return className(
                        `${modifier.map(c => `mod-${c}`).join(' ')} ${value}`
                    )
                if (typeof modifier === 'object') return states(value, 'mod', modifier)
                return className(`mod-${modifier} ${value}`)
            }
        },
        toSelector: {
            enumerable: false,
            value() {
                const joined = String(value)
                    .split(' ')
                    .join('.')
                if (joined.length > 0) return `.${joined}`
                return ''
            }
        },
        toString: {
            value() {
                return String(value)
            }
        }
    })
}

const className = (selector: string): Styler => {
    if (cache.has(selector)) return cache.get(selector)
    const inst: any = {}
    createStyler(inst, selector)
    cache.set(selector, inst)
    return inst
}

type RootStyler = {
    (selector: string): Styler
    <T extends string>(selector: string, subs: Array<T>): { [K in T]: Styler } &
        Styler
}

export const styler: RootStyler = <T extends string>(
    selector: string,
    subs: Array<T> = []
) => {
    const key = `${selector}//(${subs})`
    if (cache.has(key)) return cache.get(key)
    const inst: any = (sub?: string | ClassName) =>
        !sub ? className(selector) : className(`${selector}-${sub}`)
    createStyler(inst, selector)
    subs.forEach(sub =>
        Object.defineProperty(inst, sub, {
            enumerable: false,
            value: styler(`${selector}_${sub}`)
        })
    )
    cache.set(key, inst)
    return inst
}
