import { Effect, EffectDuration, Item, Turns } from 'game/core'
import { Memoize } from 'game/util/memoize'

export class BurpEffect extends Effect<{
    duration: Turns
}>{
    static KEY = 'BurpEffect' as const

    @Memoize()
    get duration(): EffectDuration {
        return {
            type: 'turns',
            turns: this.state.duration
        }
    }

    // ----- Appliers ----- //
    applyItemModifier(item: Item): Item {
        if (item.type === 'weapon') return item
        return {
            ...item,
            stats: {}
        }
    }
}