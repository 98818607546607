import 'views/layout/info.scss'
import React from 'react'
import {DOMAttrs} from 'views/ui/ui_types'

export const Info: React.FC<DOMAttrs> = ({children, ...attrs}) => {
    return <div className="info" {...attrs}>
        {children}
    </div>
}

export const InfoCols: React.FC = ({children}) => {
    return <div className="info_cols">
        {children}
    </div>
}

export const InfoLabel: React.FC = ({children}) => {
    return <div className="info_label">
        {children}
    </div>
}

export const InfoVisual: React.FC = ({children}) => {
    return <div className="info_visual">
        {children}
    </div>
}

export const InfoDescription: React.FC = ({children}) => {
    return <div className="info_description">
        {children}
    </div>
}
