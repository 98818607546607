import { DamageType } from 'game/core'
import { ArcaneCircle, ArcaneCircleConfig } from 'game/extended/heros/mage/arcane_circle/arcane_circle'
import { AbilityUI } from 'game/extended/uis/abilities/AbilityUI'
import React from 'react'

import image from './arcane_circle.png'

export const arcane_circle_ui: AbilityUI<'ArcaneCircle'> = {
  model: ArcaneCircle,
  configs: [
    {
      available: 3,
      cooldown: 8,
      damage: 5,
      energy: 5
    },
    {
      available: 3,
      cooldown: 6,
      damage: 7,
      energy: 4
    },
    {
      available: 3,
      cooldown: 5,
      damage: 10,
      energy: 4
    }
  ],
  image,
  label: 'Arcane Circle',
  renderTargets: () => 'Yourself',
  renderDescription: () => <>
    Your enemies rotate clockwise, forcing 1 or 2 opponents to switch lines.
    Any opponent to switch lines also takes damage.
  </>,
  renderImpression: (ability: ArcaneCircle, UI) => <>
    <UI.Char character={ability.character} />{' '}forces {ability.character?.isFemale ? ' her ' : ' his '}
    opponents to rotate positions with a magic ritual.
  </>,
  renderTableStatic: (config: ArcaneCircleConfig, UI) => <>
    <UI.TRow>
      <UI.TKey>Damage:</UI.TKey>
      <UI.TVal><UI.Damage type={DamageType.Magic} damage={config.damage} /></UI.TVal>
    </UI.TRow>
  </>,
  renderTable: (ability: ArcaneCircle, UI) => <>
    <UI.TRow>
      <UI.TKey>Damage:</UI.TKey>
      <UI.TVal><UI.Damage damage={ability.damage} /></UI.TVal>
    </UI.TRow>
  </>
}
