import { Battle, ExtendableGameModel, ExtendableGameModelState, Rule, RulePath } from 'game/core'
import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'

export type CoreConditionKey = string

export interface CoreConditionState extends ExtendableGameModelState { }

export type ConditionPath = RulePath & { condition: CoreConditionKey, condition_index: number }

export abstract class Condition<S = {}, C = {}> extends ExtendableGameModel<Rule, S & CoreConditionState, C> {

    static byPath(battle: Battle, path: ConditionPath): Nullable<Condition> {
        const type = Rule.byPath(battle, path)
        return type && type.conditions[path.condition_index]
    }

    @Memoize()
    get rule() {
        return this.parent
    }

    @Memoize()
    get path(): ConditionPath {
        return {
            ...this.rule.path,
            condition: this.key,
            condition_index: this.index
        }
    }

    @Memoize()
    get index(): number {
        return this.rule.conditions.indexOf(this)
    }

    @Memoize()
    get character() {
        return this.rule.character
    }

    @Memoize()
    get key(): CoreConditionKey {
        return this.state.key
    }

    @Memoize()
    get directChildren() {
        return []
    }

    @Memoize()
    get battle() {
        return this.rule.battle
    }

    abstract get isBlocking(): boolean
}
