import 'views/ui/conditionsetup.scss'

import React from 'react'

export const Conditionsetup: React.FC = ({ children }) => {
    return <div className="conditionsetup">{children}</div>
}

export function ConditionsetupSelect<V extends string>(attrs: {
    value: string,
    options: Record<V, string>,
    onchange: (value: V) => void
}) {
    const { value, options, onchange } = attrs

    return <div className="conditionsetup_select">
        <select
            className="conditionsetup_select-input"
            value={value}
            onChange={e => onchange((e.target as any).value)}
            onInput={e => onchange((e.target as any).value)}
        >
            {(Object.keys(options) as Array<V>).map(key =>
                <option value={key} key={key}>{options[key]}</option>
            )}
        </select>
    </div>
}


export const ConditionsetupSlider: React.FC<{
    value: number
    onchange: (value: number) => void
    min: number
    max: number
    step: number
    unit?: any
}> = attrs => {
    const { min, max, step, value, onchange, unit = '' } = attrs

    return <div className="conditionsetup_percent">
        <input
            className="conditionsetup_percent-input"
            type="range"
            min={min}
            max={max}
            step={step}
            value={value}
            onChange={e => onchange(parseInt((e.target as any).value))}
        />
        <div className="conditionsetup_percent-value">
            {value}{unit}
        </div>
    </div>
}
