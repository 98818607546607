import { EquipmentKey } from 'game/core'
import { creatures } from 'game/extended/creatures/creatures'
import { AbilityKey, CreatureKey, ItemKey, SkillKey } from 'game/extended/types'
import { getAbilityUI } from 'game/extended/uis/abilities/ability_uis'
import { getItemUI } from 'game/extended/uis/item_uis'
import { getSkillUI } from 'game/extended/uis/skills/skill_uis'
import { getCreatureUI } from 'game/extended/uis/types/creature_uis'
import { keysof } from 'game/util/keysof'
import React from 'react'
import { useParams } from 'react-router'
import { LibraryCreature, LibrarySkill, LibraryItem, LibraryLink, LibraryList, LibraryUI, LibraryAbility } from 'views/library/library'
import { AbilityVisual } from 'views/ui/visuals/abilityvisual'
import { CharacterVisual } from 'views/ui/visuals/charactervisual'
import { ItemVisual } from 'views/ui/visuals/itemvisual'
import { SkillVisual } from 'views/ui/visuals/skillvisual'
import { abilitySetFromCreatureUI } from 'game/extended/creatures/create_creature'

type Tab = 'stats' | 'items' | 'skills' | 'abilities' | 'strategy'

export const Bestiary: React.FC = () => {
    const { type, tab } = useParams<{ type: CreatureKey, tab: Tab }>()

    const creatureKeys = Object.keys(creatures.types) as CreatureKey[]
    const activeUI = type && getCreatureUI(type)

    return <LibraryUI category="bestiary">
        <LibraryList flex={0.25}>
            {creatureKeys.map(key => {
                const ui = getCreatureUI(key)
                return <LibraryLink
                    to={`/library/bestiary/${key}/${tab || 'stats/1'}`}
                    active={type === key}
                    key={key}
                >
                    <CharacterVisual type={key} />
                    {ui.label}
                </LibraryLink>
            })}
        </LibraryList>
        {activeUI && <LibraryList flex={0.12}>
            <LibraryLink
                to={`/library/bestiary/${type}/stats`}
                active={tab === 'stats'}
            >
                Stats
            </LibraryLink>
            <LibraryLink
                to={`/library/bestiary/${type}/items`}
                active={tab === 'items'}
            >
                Items
            </LibraryLink>
            <LibraryLink
                to={`/library/bestiary/${type}/abilities`}
                active={tab === 'abilities'}
            >
                Abilities
            </LibraryLink>
            {!!activeUI.definition(0).skills?.length && <LibraryLink
                to={`/library/bestiary/${type}/skills`}
                active={tab === 'skills'}
            >
                Skills
            </LibraryLink>}
        </LibraryList>}
        {tab && <BestiaryTab />}
    </LibraryUI>
}

export const BestiaryTab: React.FC = () => {
    const { type, tab, index } = useParams<{ type: CreatureKey, tab: Tab, index: string }>()

    const ui = getCreatureUI(type)
    const def = ui.definition(1)

    switch (tab) {
        case 'stats':
            return <>
                {/*<LibraryList flex={0.1}>
                    {[1, 2, 3, 4, 5, 6].map(level => {
                        return <LibraryLink
                            to={`/library/bestiary/${type}/stats/${level}`}
                            active={(index || '1') === `${level}`}
                            key={level}
                        >
                            Lvl. {level}
                        </LibraryLink>
                    })}
                </LibraryList>*/}
                <LibraryList flex={0.5}>
                    <LibraryCreature creature={type} level={parseInt(index || '1')} />
                </LibraryList>
            </>
        case 'items':
            const equipment = def.equipment || {}
            const activeItem = equipment[index as EquipmentKey] as ItemKey

            return <>
                <LibraryList flex={0.25}>
                    {keysof(equipment).map(key => {
                        const itemUI = getItemUI(equipment[key] as ItemKey)
                        return <LibraryLink
                            to={`/library/bestiary/${type}/items/${key}`}
                            active={index === key}
                            key={key}
                        >
                            <ItemVisual item={itemUI.item} />
                            {itemUI.label}
                        </LibraryLink>
                    })}
                </LibraryList>
                {activeItem && <LibraryList flex={0.35}>
                    <LibraryItem item={activeItem} />
                </LibraryList>}
            </>
        case 'skills':
            const skills = def.skills || []
            const activeSkill = index as SkillKey | null
            return <>
                <LibraryList flex={0.2}>
                    {skills.map(key => {
                        const ui = getSkillUI(key)
                        return <LibraryLink
                            to={`/library/bestiary/${type}/skills/${key}`}
                            active={activeSkill === key}
                            key={key}
                        >
                            <SkillVisual skill={key} />
                            {ui.label}
                        </LibraryLink>
                    })}
                </LibraryList>
                {activeSkill && <LibraryList flex={0.35}>
                    <LibrarySkill skill={activeSkill} level={1} />
                </LibraryList>}
            </>
        case 'abilities':
            const abilities = Array.from(abilitySetFromCreatureUI(ui, 1))
            const activeAbility = index as AbilityKey | null

            return <>
                <LibraryList flex={0.2}>
                    {abilities.map(key => {
                        const ui = getAbilityUI(key)
                        return <LibraryLink
                            to={`/library/bestiary/${type}/abilities/${key}`}
                            active={activeAbility === key}
                            key={key}
                        >
                            <AbilityVisual ability={key} />
                            {ui.label}
                        </LibraryLink>
                    })}
                </LibraryList>
                {activeAbility && <LibraryList flex={0.35}>
                    <LibraryAbility ability={activeAbility} level={1} />
                </LibraryList>}
            </>
    }

    return null
}