import { InfernoConfig } from 'game/extended/heros/mage/inferno/inferno'

export const inferno_config: Array<InfernoConfig> = [
    {
        available: 5,
        cooldown: 9,
        energy: 8,
        damage: 25
    },
    {
        available: 4,
        cooldown: 8,
        energy: 8,
        damage: 40
    }
]