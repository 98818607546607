import { CharDamaged } from 'game/core'
import { AchievementModel } from 'models/user/hero/achievements/Achievement'
import { BattleHistory } from 'models/user/hero/achievements/BattleHistory'

export class OneShotAchievement extends AchievementModel {

    calculateProgress(history: BattleHistory) {
        if (!history.char || !history.victorious) return 0

        return history.search(CharDamaged).filter(state => {
            if (state.injury.damage.origin.character !== history.char?.character) return false

            if (state.injury.amount < state.full_hp) return false
            return true
        }).length
    }
}
