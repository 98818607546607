import './visual.scss'

import React from 'react'
import { TutorialHint } from 'views/ingame/campaign/tutorial'
import { styler } from 'views/layout/util/style'
import { Icon } from 'views/ui/icon'
import { DOMAttrs, ImageType, Mod } from 'views/ui/ui_types'

const style = styler('visual', ['container'])

export type VisualAttrs = {
    selected?: boolean
    visualMod?: Mod<'bw' | 'tiny' | 'transparent'>
    hinted?: boolean
}

export const Visual: React.FC<{
    unknown?: boolean
    highlight?: boolean
} & VisualAttrs & DOMAttrs> = attrs => {
    const { unknown, hinted, highlight, selected, visualMod: mod, children, ...rest } = attrs

    return <span className={style().mod(mod).mod({ highlight })} {...rest}>
        {unknown && <VisualIcon>?</VisualIcon>}
        {children}
        {selected && <span className="visual-selected" />}
        {hinted && <TutorialHint />}
    </span>
}

export const VisualContainer: React.FC<{
    position?: 'center' | 'bottomright'
}> = ({ children, ...attrs }) => {

    const { position } = attrs

    return <span className={style.container().mod(position)} >
        {children}
    </span>
}

export const VisualIcon: React.FC = ({ children }) => {

    return <VisualContainer>
        <div className="visual_icon">
            {children}
        </div>
    </VisualContainer>
}

export const VisualUp: React.FC = () => {

    return <div className="visual_up">
        <Icon icon="up" />
    </div>
}

export const VisualSubscript: React.FC = ({ children }) => {

    return <VisualContainer position="bottomright">
        <span className="visual_subscript">
            {children}
        </span>
    </VisualContainer>
}

export const VisualImage: React.FC<{
    image: ImageType
}> = ({ image }) => {

    return <VisualContainer>
        <img src={image} className="visual_image" alt="" />
    </VisualContainer>
}
