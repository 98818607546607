import { AxeMastery, AxeMasteryConfig } from 'game/extended/heros/barbarian/axe_mastery/axe_mastery'
import { StaffMastery } from 'game/extended/heros/mage/staff_mastery/staff_mastery'
import image from './axe_mastery.png'
import { SkillUI } from 'game/extended/uis/skills/SkillUI'
import React from 'react'

export const axe_mastery_ui: SkillUI<'AxeMastery'> = {
  model: AxeMastery,
  configs: [
    {
      modifier: 15
    },
    {
      modifier: 25
    },
    {
      modifier: 40
    }
  ],
  image,
  label: 'Axe Mastery',
  renderDescription: (config: AxeMasteryConfig) => <>
    All stats and the damage of your axe(s) are increased.
  </>,
  renderTableStatic: (config: AxeMasteryConfig, UI) => <>
    <UI.TRow>
      <UI.TKey>Increase:</UI.TKey>
      <UI.TVal>{config.modifier}%</UI.TVal>
    </UI.TRow>
  </>,
  renderTable: (skill: StaffMastery, UI) => <>
    <UI.TRow>
      <UI.TKey>Increase:</UI.TKey>
      <UI.TVal>{skill.modifier}%</UI.TVal>
    </UI.TRow>
  </>
}
