import { CharWeaponSwitched } from 'game/core'
import { EventUI } from "game/extended/uis/events/EventUI"
import React from 'react'

export const char_weapon_switched_ui: EventUI<'CharWeaponSwitched'> = {
    model: CharWeaponSwitched,

    render: (event: CharWeaponSwitched, UI) => {
        return <>
            <UI.Char character={event.character} />
            {` switches ${event.character?.isFemale ? 'her' : 'his'} weapon to `}
            <UI.Weapon weapon={event.newWeapon} />
            .
        </>
    }
}
