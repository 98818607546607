import {
    Character,
    CharDamaged,
    FullDamage,
    FullInjury,
    Interrupt,
    Skill,
    SkillConfig,
    SkillTriggered,
    Time,
    Turns,
} from 'game/core'
import { Memoize } from 'game/util/memoize'

export interface SelfSacrificeConfig extends SkillConfig {
    cooldown: Turns
}

export type SelfSacrificeState = {
    last_cast?: Time
}

export class SelfSacrifice extends Skill<SelfSacrificeState, SelfSacrificeConfig> {

    static KEY = 'SelfSacrifice' as const

    @Memoize()
    get cooldown(): Turns {
        return this.currentConfig.cooldown
    }

    @Memoize()
    get lastCastTime(): Time | false {
        if (this.state.last_cast === undefined || this.state.last_cast === null) return false
        return this.state.last_cast
    }

    @Memoize()
    get isOnCooldown(): boolean {
        if (this.lastCastTime === false) return false
        return this.lastCastTime + this.cooldown > this.time
    }

    @Memoize()
    get triggerCooldown(): false | Turns {
        if (this.lastCastTime === false) return false
        if (!this.isOnCooldown) return false
        return this.lastCastTime + this.cooldown - this.time
    }

    // ----- State change inquiry ----- //
    interruptOnDamage(target: Character, damage: FullDamage): Interrupt {
        if (this.isOnCooldown) return false
        if (target.side !== this.target.side) return false
        if (target === this.target) return false

        const charAfterDamage = Character.byPath(target.takeDamage(damage, false), target.path)
        if (charAfterDamage) return false

        const injury: FullInjury = {
            amount: 0,
            damage,
            modifiers: [
                { type: 'skill', origin: this.path, before: damage.amount, after: 0 }
            ]
        }

        return this.character.battle
            .addEvent(CharDamaged, { injury, old_hp: target.hp, hp: target.hp, character: target.path, full_hp: target.stats.health })
            .startEvent(SkillTriggered, { skill: this.path })
            .perform(Character, this.path, char => {
                const currentTarget = Character.byPath(char.battle, target.path)
                if (!currentTarget) return char.battle
                return char.switchPosition(currentTarget)
            })
            .perform(Character, this.path, char => char.takeDamage(damage))
            .perform(SelfSacrifice, this.path, ab => (ab as SelfSacrifice).update({ last_cast: this.time }))
            .endEvent()
    }
}
