import { Battle, CoreAbilityKey, Line, RuleState, TypeState } from 'game/core'
import { Equipment, EquipmentKey } from 'game/core/character'
import { Environment } from 'game/core/environment'
import { Item } from 'game/core/item'
import { Stats } from 'game/core/type'
import { AbilityKey, ClassKey, ConditionKey, conditions, ItemKey, SkillKey } from 'game/extended/types'
import { EquipmentDefinition, equipmentFromDef } from 'game/extended/uis/item_uis'
import { ClassDefinition, ClassUI, getClassUI } from 'game/extended/uis/types/class_uis'
import { keysof } from 'game/util/keysof'
import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'
import { AchievementConfig, AchievementKey, AchievementModel, AchievementState } from 'models/user/hero/achievements/Achievement'
import { BattleHistory } from 'models/user/hero/achievements/BattleHistory'
import { achievement_configs } from 'data/user/achievements/configs/achievements_configs'
import * as achievements from 'models/user/hero/achievements/types'
import { AbilitySlot } from 'models/user/hero/strategy/AbilitySlot'
import { ConditionSlot } from 'models/user/hero/strategy/ConditionSlot'
import { StrategyLine, StrategyLineState } from 'models/user/hero/strategy/StrategyLine'
import { AbilityTalent, AbilityTalentState } from 'models/user/hero/talents/AbilityTalent'
import { SkillTalent, SkillTalentState } from 'models/user/hero/talents/SkillTalent'
import { EquippedItemModel, ItemModel } from 'models/user/ItemModel'
import { HeroID, UserModel } from 'models/user/UserModel'

export interface HeroState {
    id: HeroID
    type: ClassKey
    name: string
    level: number
    equipment?: EquipmentDefinition
    stats: Stats
    abilities?: Array<AbilityTalentState>
    skills?: Array<SkillTalentState>
    strategy?: Array<StrategyLineState>
    skill_points: number
    rewards: HeroBattleReward[]
    achievements?: Array<AchievementState>
}

export type HeroBattleReward = {
    battle_id: string,
    victory: boolean,
    levels: number,
    achievements: Array<AchievementKey>
}

export const BASE_HERO_ABILITIES: Array<AbilityKey> = [
    'PrimaryWeaponAttack', 'SecondaryWeaponAttack', 'Punch', 'MoveFront', 'MoveSupport', 'Rest'
]

export class HeroModel {
    constructor(readonly user: UserModel, private state: HeroState) { }

    //TODO: Rename to byModel and invert parameters
    static findIn(user: UserModel, reference: HeroModel): HeroModel {
        const result = user.heros.find(hero => hero.id === reference.id)
        if (!result) throw Error(`Unable to find hero with id: ${reference.id}`)
        return result
    }

    @Memoize()
    get env(): Environment {
        return this.user.env
    }

    @Memoize()
    get id(): string {
        return this.state.id
    }

    @Memoize()
    get name(): string {
        return this.state.name
    }

    @Memoize()
    get type(): ClassKey {
        return this.state.type
    }

    @Memoize()
    get definition(): ClassDefinition {
        return getClassUI(this.type).definition
    }

    @Memoize()
    get ui(): ClassUI {
        return getClassUI(this.type)
    }

    @Memoize()
    get equipment() {
        return equipmentFromDef(this.state.equipment || {})
    }

    @Memoize()
    get level(): number {
        return this.state.level
    }

    @Memoize()
    get suggestedEquipmentSlots(): Array<EquipmentKey> {
        const result: Array<EquipmentKey> = []
        keysof(this.equipment).forEach(key => {
            if (this.equipment[key]) return
            if (key === 'secondary' && this.primaryWeapon?.isTwoHandedWeapon) return
            const suitableItem = this.user.inventory.find(item => item.isEquipableInSlot(key))
            if (suitableItem) result.push(key)
        })
        return result
    }

    @Memoize()
    get achievements(): Array<AchievementModel> {
        const configs = achievement_configs[this.type]
        const result = Object.keys(configs).map(key => {
            const initialState = { key: key as AchievementKey, level: 0, progress: 0 }
            const state = (this.state.achievements || []).find(state => state.key === key)
            const Constructor = achievements[key as AchievementKey]
            const config = configs[key as AchievementKey] as AchievementConfig
            return new Constructor(this, state || initialState, config)
        })
        return result.sort((a, b) => b.prio - a.prio)
    }

    @Memoize()
    get abilityTalents(): Array<AbilityTalent> {
        const keys = [...this.definition.abilities]
        return keys.map(key => {
            const state = (this.state.abilities || []).find(state => state.key === key)
            return new AbilityTalent(this, state || { key, level: 0 })
        })
    }

    @Memoize()
    get skillTalents(): Array<SkillTalent> {
        const keys = [...this.definition.skills]
        return keys.map(key => {
            const state = (this.state.skills || []).find(state => state.key === key)
            return new SkillTalent(this, state || { key, level: 0 })
        })
    }

    @Memoize()
    get baseAbilityTalents(): Array<AbilityTalent> {
        return BASE_HERO_ABILITIES
            .map(key => {
                const state = (this.state.abilities || []).find(state => state.key === key)
                return new AbilityTalent(this, state || { key, level: 1 })
            })
    }

    @Memoize()
    get talents(): Array<AbilityTalent | SkillTalent> {
        return [...this.abilityTalents, ...this.skillTalents, ...this.baseAbilityTalents]
    }

    @Memoize()
    get index(): number {
        return this.user.heros.findIndex(hero => hero === this)
    }

    @Memoize()
    get stats(): Stats {
        return this.achievements.reduce(
            (stats, achievement) => achievement.applyStats(stats),
            this.state.stats
        )
    }

    @Memoize()
    get fullStats(): Stats {
        const result = { ...this.stats }
        keysof(this.equipment).forEach(key => {
            const item = this.equipment[key]
            if (!item) return
            keysof(item.stats).forEach(stat => {
                result[stat] = result[stat] + (item.stats[stat] || 0)
            })
        })
        return result
    }

    @Memoize()
    get isSelectedInStrategy(): boolean {
        return !!this.user.lineup.slots.find(slot => slot.hero === this)
    }

    @Memoize()
    get previous(): HeroModel {
        const prevIndex = this.index === 0 ? this.user.heros.length - 1 : this.index - 1
        return this.user.heros[prevIndex]
    }

    @Memoize()
    get next(): HeroModel {
        const nextIndex = this.index === this.user.heros.length - 1 ? 0 : this.index + 1
        return this.user.heros[nextIndex]
    }

    @Memoize()
    get items(): Array<EquippedItemModel> {
        const eq = this.state.equipment
        if (!eq) return []
        return keysof(eq)
            .filter(key => !!eq[key])
            .map(key => new EquippedItemModel(this, eq[key] as ItemKey, key))
    }

    @Memoize()
    get primaryWeapon(): Nullable<EquippedItemModel> {
        return this.items.find(item => item.slot === 'primary')
    }

    @Memoize()
    get secondaryWeapon(): Nullable<EquippedItemModel> {
        return this.items.find(item => item.slot === 'secondary')
    }

    @Memoize()
    get skills(): Array<SkillKey> {
        return [...this.definition.skills]
    }

    @Memoize()
    get abilities(): Array<AbilityKey> {
        return [...this.definition.abilities, ...BASE_HERO_ABILITIES]
    }

    @Memoize()
    get conditions(): Array<ConditionKey> {
        return keysof(conditions)
    }

    @Memoize()
    get strategyLines(): Array<StrategyLine> {
        const states = this.state.strategy || []
        while (states.length < 6) {
            states.push({
                abilities: [null, null, null, null, null],
                conditions: [null, null]
            })
        }
        return states.map(state => new StrategyLine(this, state))
    }

    @Memoize()
    get slots(): Array<AbilitySlot | ConditionSlot> {
        return this.strategyLines.reduce(
            (acc, line) => [...acc, ...line.slots],
            [] as Array<AbilitySlot | ConditionSlot>
        )
    }

    @Memoize()
    get rules(): Array<RuleState> {
        return this.strategyLines
            .map(strategy => strategy.rule)
            .filter(rule => rule.sequence.length)
    }

    @Memoize()
    get skillPoints(): number {
        return this.state.skill_points
    }

    @Memoize()
    get unusedAbilities(): Array<AbilityTalent> {
        return this.abilityTalents.filter(talent => {
            return talent.level > 0 && talent.isUnused
        })
    }

    @Memoize()
    get suggestions(): number {
        return this.suggestedEquipmentSlots.length + this.state.skill_points + this.unusedAbilities.length
    }

    // ----- Inquiry ----- //
    rewardInBattle(battle_id: string): HeroBattleReward {
        const result: HeroBattleReward = {
            battle_id,
            achievements: [],
            levels: 0,
            victory: false
        }
        this.state.rewards.forEach(reward => {
            if (reward.battle_id !== battle_id) return
            result.achievements = [...result.achievements, ...reward.achievements]
            result.levels = result.levels + reward.levels
            result.victory = result.victory || reward.victory
        })
        return result
    }

    canPerform(key: CoreAbilityKey): boolean {
        const talent = this.talents.find(talent => talent.key === key)
        if (!talent) return false
        return talent.level > 0
    }

    getItem(key: keyof Equipment): Nullable<Item> {
        return this.equipment[key]
    }

    // ----- State enquiry ----- //
    addToBattle(line: Line): Battle {

        const state: TypeState = {
            kind: 'hero',
            type: this.type,
            level: this.state.level,
            stats: this.stats,
            skills: this.skills
                .map(key => {
                    const expertise = this.talents.find(exp => exp.key === key)
                    if (!expertise) throw new Error(`Skill not found in the expertises list of this hero: ${key}`)
                    return {
                        key: key,
                        level: expertise.level
                    }
                })
                .filter(({ level }) => level > 0),
            abilities: this.abilities
                .map(key => {
                    const expertise = this.talents.find(exp => exp.key === key)
                    return {
                        key: key,
                        level: expertise ? expertise.level : 1,
                        lastUsage: false as false,
                        lastTargets: [],
                        available: true as const,
                        //TODO: What about abilities with additional state?
                    }
                })
                .filter(({ level }) => level > 0),
            rules: [
                ...this.rules,
                {
                    conditions: [],
                    sequence: ['Rest']
                }
            ]
        }

        return line.createCharacter(this.state.name, state, {
            equipment: this.equipment,
            pos: 10
        })
    }

    // ----- Calculate new State ----- //
    update(newState: Partial<HeroState>): UserModel {
        return this.user.updateChar(this.state, newState)
    }

    updateAchievements(battle_id: string, battle: Battle): UserModel {
        let result = this.user
        this.achievements.forEach(a => {
            const achievement = result.getHeroById(this.id)?.achievements.find(a2 => a2.key === a.key)
            if (!achievement) return
            const progress = achievement.calculateProgress(new BattleHistory(battle, this))
            result = achievement.increaseProgress(progress)
        })

        return HeroModel.findIn(result, this)
            .saveRewardsComparedTo(battle_id, battle, this)
    }

    private saveRewardsComparedTo(battle_id: string, battle: Battle, previous: HeroModel): UserModel {
        let levels = 0
        let achievements: Array<AchievementKey> = []
        this.achievements.forEach(achievement => {
            const prevAchievement = AchievementModel.byModel(previous.user, achievement)
            if (!prevAchievement) return
            const increasedLevels = achievement.level - prevAchievement.level
            levels += increasedLevels
            for (let i = 0; i < increasedLevels; i++) {
                achievements.push(prevAchievement.key)
            }
        })

        return this.update({
            rewards: [...this.state.rewards, {
                battle_id,
                achievements,
                levels,
                victory: battle.left.isVictorious
            }]
        })
    }

    /*updateRule(state: UserRuleState, update: Partial<UserRuleState>): User {
        const hasRule = new Set(this.state.rules).has(state)
        let rules = this.state.rules.map(currentData =>
            currentData !== state ? currentData : {...state, ...update}
        )
        if(!hasRule) rules = [...rules, {...state, ...update}]

        return this.user.updateChar(this.state, {rules})
    }*/

    equip(key: EquipmentKey, item: ItemModel): UserModel {
        if (item.isEquipped) return this.user

        if (key === 'secondary' && this.equipment.primary?.two_handed) {
            return this.user
        }
        if (key === 'secondary' && item.isTwoHandedWeapon) {
            return this.user
        }

        const newInventory = this.user.inventory.filter(it => it !== item).map(it => it.key)
        const activeItem = this.items.find(item => item.slot === key)
        if (activeItem) newInventory.push(activeItem.key)

        const newUser = this.update({
            equipment: {
                ...this.state.equipment,
                [key]: item.key
            }
        }).update({
            inventory: newInventory
        })

        const newHero = HeroModel.findIn(newUser, this)
        if (newHero.primaryWeapon?.isTwoHandedWeapon && newHero.secondaryWeapon) {
            return newHero.secondaryWeapon?.unequip()
        }

        return newUser
    }

    unequip(key: keyof Equipment): UserModel {
        const itemKey = (this.state.equipment || {})[key] as Nullable<ItemKey>
        if (!itemKey) return this.user

        const newEq = { ...this.state.equipment }
        delete newEq[key]

        return this
            .update({ equipment: newEq })
            .update({ inventory: [...this.user.state.inventory, itemKey] })
    }
}
