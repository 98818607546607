import { Battle, Event, SetupData } from 'game/core'
import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'
import { ReplayRound } from 'models/replay/ReplayRound'
import { ReplayTurn } from 'models/replay/ReplayTurn'

export class ReplaySetupTurn extends ReplayTurn {
    state: SetupData

    @Memoize()
    get start(): Battle {
        return this.state.start
    }

    @Memoize()
    get end(): import("../../game/core").Battle {
        return this.state.end
    }

    @Memoize()
    get events(): Array<Event> {
        return this.state.events
    }

    @Memoize()
    get key(): string {
        return `setup`
    }

    @Memoize()
    get previousTurn(): Nullable<ReplayTurn> {
        return this.round.previousRound?.lastTurn
    }

    @Memoize()
    get nextTurn(): Nullable<ReplayTurn> {
        if (this.round.turns.length === 0) return this.round.nextRound?.setup
        return this.round.turns[0]
    }

    constructor(round: ReplayRound, state: SetupData) {
        super(round)
        this.round = round
        this.state = state
    }
}