import { AbilityConfig } from 'game/core'
import { AbilityKey } from 'game/extended/types'
import { AbilityUI } from 'game/extended/uis/abilities/AbilityUI'
import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'
import { TalentModel, TalentState } from 'models/user/hero/talents/TalentModel'
import { UserModel } from 'models/user/UserModel'
import { getAbilityUI } from 'game/extended/uis/abilities/ability_uis'

export interface AbilityTalentState extends TalentState {
    key: AbilityKey
}

export class AbilityTalent extends TalentModel<AbilityTalentState> {

    @Memoize()
    get configs(): Array<AbilityConfig> {
        return this.user.env.getConfig(this.key)
    }

    @Memoize()
    get config(): Nullable<AbilityConfig> {
        return this.configs[this.level - 1]
    }

    @Memoize()
    get maxLevel(): number {
        return this.configs.length
    }

    @Memoize()
    get isUnused(): boolean {
        return !this.hero.strategyLines.find(line =>
            line.abilities.find(slot => slot.abilityKey === this.key)
        )
    }

    @Memoize()
    get key(): AbilityKey {
        return this.state.key
    }

    @Memoize()
    get isExpert(): boolean {
        return this.key === this.hero.definition.super_ability
    }

    @Memoize()
    get label(): string {
        return this.ui ? this.ui.label : this.key
    }

    @Memoize()
    get ui(): AbilityUI<any> {
        return getAbilityUI(this.state.key)
    }

    get canUpgrade(): boolean {
        if (this.isExpert && this.hero.level < 20) return false
        return super.canUpgrade
    }

    // ----- Calculate new State ----- //
    upgrade(): UserModel {
        if (!this.canUpgrade) return this.user
        return this.hero.update({
            skill_points: this.hero.skillPoints - this.cost,
            abilities: this.hero.abilityTalents.map(talent =>
                talent !== this ? talent.state : {
                    ...this.state,
                    level: this.state.level + 1
                }
            )
        })
    }
}
