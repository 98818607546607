import { ArcaneGrowth, ArcaneGrowthConfig } from 'game/extended/heros/mage/arcane_growth/arcane_growth'
import { SkillUI } from 'game/extended/uis/skills/SkillUI'
import React from 'react'

import image from './arcane_growth.png'
import { arcane_growth_config } from 'game/extended/heros/mage/arcane_growth/arcane_growth_config'

export const arcane_growth_ui: SkillUI<'ArcaneGrowth'> = {
    model: ArcaneGrowth,
    configs: arcane_growth_config,
    image,
    label: 'Arcane Growth',
    renderDescription: (config: ArcaneGrowthConfig) => <span>
        At fixed intervals, slowly increase you intelligence.
    </span>,
    renderTableStatic: (config: ArcaneGrowthConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Increment:</UI.TKey>
            <UI.TVal><UI.Stat stat='intelligence' amount={config.increment} /></UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Interval:</UI.TKey>
            <UI.TVal><UI.Turns turns={config.interval} /></UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (skill: ArcaneGrowth, UI) => <>
        <UI.TRow>
            <UI.TKey>Increment:</UI.TKey>
            <UI.TVal><UI.Stat stat='intelligence' amount={skill.increment} /></UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Interval:</UI.TKey>
            <UI.TVal><UI.Turns turns={skill.interval} /></UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Next stack in:</UI.TKey>
            <UI.TVal><UI.Turns turns={skill.turnsUntilNextStack} /></UI.TVal>
        </UI.TRow>
    </>
}
