import { Ability, AbilityConfig, Character, DamageNature, DamageType, FullDamage, Percentage } from 'game/core'
import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'

export type ExplosiveArrowConfig = AbilityConfig & {
    damage: number
    splash: Percentage
}

export class ExplosiveArrow extends Ability<ExplosiveArrowConfig>{

    static KEY = 'ExplosiveArrow' as const
    static FIXED_REQUIREMENTS = []

    @Memoize()
    get targets(): Nullable<Character> {
        return this.character.rangedTarget || this.character.frontTarget
    }

    @Memoize()
    get allTargets(): Array<Character> {
        if (!this.targets) return []
        return [this.targets, ...this.targets.alliesArround]
    }

    @Memoize()
    get damage(): FullDamage {
        return this.calculateDamage(
            DamageType.Magic,
            DamageNature.Ranged,
            this.currentConfig.damage
        )
    }

    @Memoize()
    get splashDamage(): FullDamage {
        return this.calculateDamage(
            DamageType.Ranged,
            DamageNature.Splash,
            Math.round(this.currentConfig.damage * this.currentConfig.splash / 100)
        )
    }

    // ----- Calculate new State ----- //
    performActionImpl() {
        if (!this.targets) return this.battle

        return this.battle
            .perform(Character, this.targets.path,
                char => char.takeDamage(this.damage)
            )
            .performAll(Character, this.targets.alliesArround.map(ch => ch.path),
                char => char.takeDamage(this.splashDamage)
            )
    }
}
