import { Requirement } from 'game/core/requirement'
import { RequiredWeaponState } from 'game/core/requirements'
import { Memoize } from 'game/util/memoize'

import { WeaponAttack, WeaponAttackConfig } from './weapon_attack'

export type PrimaryWeaponAttackConfig = WeaponAttackConfig

export class PrimaryWeaponAttack extends WeaponAttack {

  static KEY = 'PrimaryWeaponAttack' as const
  static FIXED_REQUIREMENTS = [
    Requirement.init(RequiredWeaponState, { type: 'any' }),
  ]

  @Memoize()
  get weapon() {
    return this.character.primaryWeapon
  }
}
