import { ItemRarity, Shield } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './guard_shield.png'

export const guard_shield: ItemUI<Shield> = {
  image,
  label: 'Guard shield',
  value: 80,
  item: {
    key: 'guard_shield',
    type: 'shield',
    rarity: ItemRarity.Common,
    stats: {
      armor: 25,
      shielding: 30,
      resistance: 15
    }
  }
}
