import { CreatureUI } from 'game/extended/uis/types/creature_uis'

import circle from './hedgy_circle.png'
import head from './hedgy_head.png'

export const hedgy: CreatureUI = {
  label: 'Hedgy',
  definition: lvl => ({
    stats: {
      health: 100,
      energy: 10,
      intelligence: 20,
      strength: 80,
      agility: 25,
      armor: 25,
      shielding: 25,
      resistance: 25
    },
    strategy: [
      'Punch'
    ],
    skills: [
      'Prickly'
    ]
  }),
  circle: {
    src: circle,
    width: 200,
    height: 150
  },
  head
}
