import { Ability, AbilityConfig, Battle, Character, Effect } from 'game/core'
import { Memoize } from 'game/util/memoize'

export type CleansingLightConfig = AbilityConfig

export class CleansingLight extends Ability<CleansingLightConfig>{

    static KEY = 'CleansingLight' as const
    static FIXED_REQUIREMENTS = []

    @Memoize()
    get targets(): Array<Character> {
        const secondTarget = this.character.line.position === 'front' ?
            this.character.behindAlly : this.character.frontAlly
        if (!secondTarget) return [this.character]
        return [this.character, secondTarget]
            .filter(char => char.enemyEffects.length > 0)
    }

    @Memoize()
    get targetsBesideHimself(): Array<Character> {
        return this.targets.filter(char => char !== this.character)
    }

    // ----- Calculate new State ----- //
    performActionImpl() {
        return this.performOnAllTargets(char =>
            this.performOnTarget(char)
        )
    }

    performOnTarget(target: Character): Battle {
        return target.battle.performAll(
            Effect,
            target.enemyEffects.map(effect => effect.path),
            (effect: Effect) => effect.finalize()
        )
    }
}
