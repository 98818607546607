import { Armor, ItemRarity } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './falcon_armor.png'

export const falcon_armor: ItemUI<Armor> = {
    image,
    label: 'Falcon armor',
    value: 90,
    item: {
        key: 'falcon_armor',
        type: 'armor',
        rarity: ItemRarity.Epic,
        stats: {
            health: 40,
            strength: 20,
            armor: 20,
            shielding: 20
        }
    }
}
