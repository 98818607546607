import { Ability, AbilityConfig, Battle, Character, Immobilized, Turns } from 'game/core'
import { Memoize } from 'game/util/memoize'

export type TauntConfig = AbilityConfig & {
  duration: Turns
}

export class Taunt extends Ability<TauntConfig>{

  static KEY = 'Taunt' as const
  static FIXED_REQUIREMENTS = []

  @Memoize()
  get targets() {
    return this.character.supportTarget
  }

  @Memoize()
  get duration(): Turns {
    return this.currentConfig.duration
  }

  // ----- Calculate new State ----- //
  performActionImpl(): Battle {
    if (!this.targets) return this.battle

    return this.targets
      .moveToFront()
      .perform(Character, this.targets.path, char => {
        return char.addEffect(Immobilized, {
          origin: this.character.path,
          duration: this.duration
        })
      })
  }
}

