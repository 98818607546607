import { TurnEnded } from 'game/core'
import { EventUI } from 'game/extended/uis/events/EventUI'
import React from 'react'

export const turn_ended_ui: EventUI<'TurnEnded'> = {
    model: TurnEnded,
    render: (event: TurnEnded, UI) => <>
        The turn of{' '}<UI.Char character={event.state.character} />{' '}is finished.
    </>
}
