import './library.scss'

import { Weapon } from 'game/core'
import { default_env } from 'game/extended/default_env'
import { AbilityKey, CreatureKey, ItemKey, SkillKey } from 'game/extended/types'
import { getItemUI } from 'game/extended/uis/item_uis'
import { getCreatureUI } from 'game/extended/uis/types/creature_uis'
import React from 'react'
import { Route, Switch } from 'react-router'
import { CharacterInfo } from 'views/ingame/user/team/characterinfo'
import { Weaponstats } from 'views/ingame/user/team/equip/weaponstats'
import { ItemStats } from 'views/ingame/user/team/itemstats'
import { AbilitySide } from 'views/layout/sidebar/abilityside'
import { SkillSide } from 'views/layout/sidebar/skillside'
import { styler } from 'views/layout/util/style'
import { Abilities } from 'views/library/types/abilities'
import { Bestiary } from 'views/library/types/bestiary'
import { Items } from 'views/library/types/items'
import { Skills } from 'views/library/types/skills'
import { Link, useLinker } from 'views/link'
import { Icon } from 'views/ui/icon'
import { StatIcon } from 'views/ui/stats/staticon'
import { ItemVisual } from 'views/ui/visuals/itemvisual'

const styles = styler('library', ['list', 'link', 'item'])

export type LibraryContextType = {
    backlink?: string
}

export const LibraryContext = React.createContext<LibraryContextType>({})

export const Library: React.FC = () => {
    const { processTo } = useLinker()

    return <Switch>
        <Route path={processTo("/library/items/:type?/:item?")} component={Items} />
        <Route path={processTo("/library/bestiary/:type?/:tab?/:index?")} component={Bestiary} />
        <Route path={processTo("/library/skills/:type?/:tab?/:index?")} component={Skills} />
        <Route path={processTo("/library/abilities/:type?/:tab?/:index?")} component={Abilities} />
    </Switch>
}

export const LibraryUI: React.FC<{
    category: 'items' | 'bestiary' | 'skills' | 'abilities'
}> = ({ category, children }) => {

    return <div className={styles()}>
        <div className={styles('categories')}>
            <Link
                className={styles('categories-item').is({ active: category === 'bestiary' })}
                to="/library/bestiary"
            >
                Bestiary
            </Link>
            <Link
                className={styles('categories-item').is({ active: category === 'items' })}
                to="/library/items"
            >
                Items
            </Link>
            <Link
                className={styles('categories-item').is({ active: category === 'skills' })}
                to="/library/skills"
            >
                Skills
            </Link>
            <Link
                className={styles('categories-item').is({ active: category === 'abilities' })}
                to="/library/abilities"
            >
                Abilities
            </Link>
        </div>
        <Link className={styles('close')} to="/">
            <Icon icon="close" />
        </Link>
        <div className={styles('content')}>
            {children}
        </div>
    </div>
}

export const LibraryList: React.FC<{
    flex: number
}> = ({ flex, children }) => {

    return <div className={styles.list()} style={{ flex }}>
        {children}
    </div>
}

export const LibraryLink: React.FC<{
    to: string
    active: boolean
}> = ({ to, active, children }) => {

    return <Link className={styles.link().is({ active })} to={to}>
        {children}
    </Link>
}

export const LibraryItem: React.FC<{
    item: ItemKey
}> = ({ item }) => {
    const ui = getItemUI(item)

    return <div className="library_item">
        <div className="library_item-header">
            <div className="library_item-header-visual">
                <ItemVisual item={ui.item} />
            </div>
            <div className="library_item-header-label">
                {ui.label}
            </div>
        </div>
        <div className="library_item-price">
            <u>Price:</u> {ui.value} <StatIcon stat="currency" />
        </div>
        {ui.item.type === 'weapon' && <div className="library_item-stats">
            <u>Stats:</u>
            <Weaponstats weapon={ui.item as Weapon} />
        </div>}
        <div className="library_item-bonus">
            <u>Modifiers:</u>
            <ItemStats item={ui.item} />
        </div>
    </div>
}

export const LibraryCreature: React.FC<{
    creature: CreatureKey
    level: number
}> = ({ creature, level }) => {
    const ui = getCreatureUI(creature)

    return <div className="library_creature">
        <div className="library_creature-stats">
            <CharacterInfo
                type={creature}
                name={ui.label}
                level={level}
                stats={ui.definition(level).stats}
            />
        </div>
    </div>
}

export const LibrarySkill: React.FC<{
    skill: SkillKey
    level: number
}> = ({ skill, level }) => {
    return <div className="library_skill">
        <SkillSide
            env={default_env}
            level={level}
            skill={skill}
            hideLevels={true}
        />
    </div>
}

export const LibraryAbility: React.FC<{
    ability: AbilityKey
    level: number
}> = ({ ability, level }) => {
    return <div className="library_ability">
        <AbilitySide
            env={default_env}
            level={level}
            ability={ability}
            hideLevels={true}
        />
    </div>
}

