import { Ability, AbilityConfig } from 'game/core'
import { Memoize } from 'game/util/memoize'

export type RestConfig = AbilityConfig & {
    recovery: number
}

export class Rest extends Ability<RestConfig> {

    static KEY = 'Rest' as const
    static FIXED_REQUIREMENTS = []

    @Memoize()
    get recovery(): number {
        return this.currentConfig.recovery
    }

    @Memoize()
    get targets() {
        return false as const
    }

    // ----- Calculate new State ----- //
    performActionImpl() {
        return this.character.changeEnergy(this.recovery)
    }
}
