import { AbilityPicked, ChargingAbilitySkipped, ChargingRuleSkipped } from 'game/core'
import { EventUI } from 'game/extended/uis/events/EventUI'
import { UIType } from 'game/extended/uis/UI'
import React from 'react'

export const ability_picked_ui: EventUI<'AbilityPicked'> = {
    model: AbilityPicked,

    render: (event: AbilityPicked, UI) => {
        if (!event.ability) {
            return <>
                <UI.Char character={event.state.character} />
                {' '}could not find any valid ability to cast as
                {' '}{event.character?.isFemale ? 'her' : 'his'}{' '}next ability
            </>
        }

        return <>
            <UI.Char character={event.state.character} />
            {' decides to cast '}
            <UI.Ability ability={event.ability} />
            {' '}as {event.character?.isFemale ? 'her' : 'his'}{' '}next ability
        </>
    },

    renderDetails: (event: AbilityPicked, UI) => {

        if (event.continuesSequence) {
            //Continue charging the current sequence (while skipping one or more intermediate abilities)
            return <UI.Log>
                <UI.LogMsg>
                    <UI.Char character={event.character} />
                    {' continues charging '}
                    <UI.Rule rule={event.rule} />.
                    {event.skippedAbilitiesInActiveRule.length > 0 && <>
                        <br />
                        Note: The next{' '}
                        {event.skippedAbilitiesInActiveRule.length === 1 ?
                            'ability in this sequence was skipped.' :
                            `${event.skippedAbilitiesInNewRule.length} abilities in this new sequence were skipped.`
                        }
                    </>}
                </UI.LogMsg>
                {renderSkippedAbilities(event.skippedAbilitiesInActiveRule, UI)}
            </UI.Log >
        }

        return <>
            <UI.Log>
                <UI.LogMsg>
                    {!event.previousAbility && <>
                        No sequence was currently active.
                    </>}
                    {event.previousAbility && event.skippedAbilitiesInActiveRule.length === 0 && <>
                        The sequence {event.character?.isMale ? 'he' : 'she'} was casting is finished.
                    </>}
                    {event.previousAbility && event.skippedAbilitiesInActiveRule.length >= 1 && <>
                        The sequence {event.character?.isMale ? 'he' : 'she'} was casting could not
                        be finished because all remaining abilities were skipped.
                    </>}
                </UI.LogMsg>
                {renderSkippedAbilities(event.skippedAbilitiesInActiveRule, UI)}
            </UI.Log>
            {event.skippedRulesWhileFindingNewRule.length > 0 && <UI.Log>
                <UI.LogMsg>
                    {event.skippedRulesWhileFindingNewRule.length === 1 ?
                        'The first rule ' : `The first ${event.skippedRulesWhileFindingNewRule.length} rules `
                    } could not be cast because they had a failing condition or none
                    of the abilities in the sequence was valid (expand for details).
                </UI.LogMsg>
                <UI.LogChildren>
                    {renderSkippedRules(event.skippedRulesWhileFindingNewRule, UI)}
                </UI.LogChildren>
            </UI.Log>}
            {event.rule && <UI.Log>
                <UI.LogMsg>
                    <UI.Rule rule={event.rule} />{' '}was selected as the new active sequence.
                    {event.skippedAbilitiesInNewRule.length > 0 && <>
                        The first{' '}
                        {event.skippedAbilitiesInNewRule.length === 1 ?
                            'ability in this new sequence was skipped.' :
                            `${event.skippedAbilitiesInNewRule.length} abilities in this new sequence were skipped.`
                        }
                    </>}
                </UI.LogMsg>
                {renderSkippedAbilities(event.skippedAbilitiesInNewRule, UI)}
            </UI.Log>}
        </>
    }
}

const renderSkippedAbilities = (abilities: Array<ChargingAbilitySkipped>, UI: UIType) => {
    if (!abilities.length) return null

    return <UI.LogChildren>
        {abilities.map(({ ability, reasons }, i) => (
            <UI.Log key={i}>
                <UI.LogMsg>
                    <UI.Ability ability={ability} />
                    {' was skipped because '}
                    <UI.Reasons reasons={reasons} />
                </UI.LogMsg>
            </UI.Log>
        ))}
    </UI.LogChildren>
}

const renderSkippedRules = (rules: Array<ChargingRuleSkipped>, UI: UIType) => {
    if (!rules.length) return null

    return <UI.LogChildren>
        {rules.map((rule, i) => {
            switch (rule.reason.key) {
                case 'condition_failed':
                    return <UI.Log key={i}>
                        <UI.LogMsg>
                            <UI.Rule rule={rule.rule} /> could not be cast because
                            <UI.Condition condition={rule.reason.condition} /> was not met.
                        </UI.LogMsg>
                    </UI.Log>
                case 'no_castable_abilities':
                    return <UI.Log key={i}>
                        <UI.LogMsg>
                            <UI.Rule rule={rule.rule} /> could not be cast because none
                            of the abilities in that sequence were valid.
                        </UI.LogMsg>
                        {renderSkippedAbilities(rule.reason.abilities, UI)}
                    </UI.Log>
            }
            return null
        })}
    </UI.LogChildren>
}