import { EffectUI } from 'game/extended/uis/effects/EffectUI'
import React from 'react'

import image from './inspire.png'
import { InspireEffect } from './inspire_effect'

export const inspire_effect_ui: EffectUI<'InspireEffect'> = {
    image,
    label: 'Inspire effect',
    model: InspireEffect,
    renderDescription: (effect: InspireEffect) => <>
        Your strength is drastically increased.
    </>,
    renderTable: (effect: InspireEffect, UI) => <>
        <UI.TRow>
            <UI.TKey>Increase</UI.TKey>
            <UI.TVal><UI.Stat stat='strength' amount={effect.strengthIncrease} /></UI.TVal>
        </UI.TRow>
    </>
}