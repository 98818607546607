import { Seduce, SeduceConfig } from 'game/extended/creatures/undead/abilities/seduce/seduce'
import { UnholyCurse } from 'game/extended/creatures/undead/abilities/unholy_curse/unholy_curse'
import { AbilityUI } from 'game/extended/uis/abilities/AbilityUI'
import React from 'react'

import image from './unholy_curse.png'

export const unholy_curse_ui: AbilityUI<'UnholyCurse'> = {
  model: UnholyCurse,
  configs: [
    {
      available: 2,
      cooldown: 4,
      duration: 2,
      energy: 2
    }
  ],
  image,
  label: 'Unholy Curse',
  renderTargets: () => 'Ranged',
  renderDescription: () => <>
    Curse your target, causing all healing effects to deal magic damage instead.
  </>,
  renderImpression: (ability: Seduce, UI) => <>
    <UI.Char character={ability.character} />{' '}curses {ability.character.isFemale ? 'her' : 'his'} with an unholy
    enchantment.
  </>,
  renderTableStatic: (config: SeduceConfig, UI) => <>
    <UI.TRow>
      <UI.TKey>Duration:</UI.TKey>
      <UI.TVal><UI.Turns turns={config.duration} /></UI.TVal>
    </UI.TRow>
  </>,
  renderTable: (ability: Seduce, UI) => <>
    <UI.TRow>
      <UI.TKey>Duration:</UI.TKey>
      <UI.TVal><UI.Turns turns={ability.duration} /></UI.TVal>
    </UI.TRow>
  </>
}
