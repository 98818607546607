import {
    Ability,
    AbilityConfig,
    Character,
    DamageNature,
    DamageType,
    FullDamage,
    RequiredWeaponState,
    Requirement,
    Turns,
} from 'game/core'
import { PoisonedShotEffect } from 'game/extended/heros/ranger/poisoned_shot/poisoned_shot_effect'
import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'

export type PoisonedShotConfig = AbilityConfig & {
    energy: 'unknown',
    poison_damage: number,
    poison_duration: Turns
}

export class PoisonedShot extends Ability<PoisonedShotConfig>{

    static KEY = 'PoisonedShot' as const
    static FIXED_REQUIREMENTS = [
        Requirement.init(RequiredWeaponState, { type: 'ranged' })
    ]

    @Memoize()
    get targets(): Nullable<Character> {
        if (this.character.rangedTarget) return this.character.rangedTarget
        if (this.character.meleeTarget) return this.character.meleeTarget
        return null
    }

    @Memoize()
    get energy(): number {
        return this.character.rangedWeapon?.energy || 0
    }

    @Memoize()
    get damage(): FullDamage {
        if (!this.character.rangedWeapon) return this.nullDamage
        return this.calculateDamage(
            this.character.rangedWeapon.damageType,
            DamageNature.Ranged,
            this.character.rangedWeapon.damage
        )
    }

    @Memoize()
    get poisonDamage(): FullDamage {
        return this.calculateDamage(
            DamageType.Magic,
            DamageNature.Recurrent,
            this.currentConfig.poison_damage
        )
    }

    @Memoize()
    get poisonDuration(): Turns {
        return this.currentConfig.poison_duration
    }

    // ----- State change inquiry ----- //
    performActionImpl() {
        if (!this.targets) return this.battle
        return this.targets
            .takeDamage(this.damage)
            .perform(Character, this.targets.path, (char: Character) =>
                char.addEffect(PoisonedShotEffect, {
                    origin: this.character.path,
                    damage: this.poisonDamage,
                    turns: this.poisonDuration
                })
            )
    }
}
