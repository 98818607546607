import { Requirement } from 'game/core'
import { Memoize } from 'game/util/memoize'

export class RequiredValidTarget extends Requirement {

    static KEY = 'RequiredValidTarget' as const

    @Memoize()
    get isValid() {
        if (this.ability.targets === false) return true
        if (!Array.isArray(this.ability.targets)) return !!this.ability.targets
        return this.ability.targets.length > 0
    }
}
