import { Boots, ItemRarity } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './iron_plate_boots.png'

export const iron_plate_boots: ItemUI<Boots> = {
    image,
    label: 'Iron pate boots',
    value: 90,
    item: {
        key: 'iron_plate_boots',
        type: 'boots',
        rarity: ItemRarity.Rare,
        stats: {
            agility: -25,
            armor: 35,
            health: 20
        }
    }
}
