import { Armor, ItemRarity } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './vampire_armor.png'

export const vampire_armor: ItemUI<Armor> = {
  image,
  label: 'Vampire armor',
  value: 70,
  item: {
    key: 'vampire_armor',
    type: 'armor',
    rarity: ItemRarity.Epic,
    stats: {
      health: 30,
      intelligence: 25,
      strength: 25,
      shielding: 10
    }
  }
}
