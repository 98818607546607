import { SelfSacrifice, SelfSacrificeConfig } from 'game/extended/heros/paladin/self_sacrifice/self_sacrifice'
import { self_sacrifice_config } from 'game/extended/heros/paladin/self_sacrifice/self_sacrifice_config'
import { SkillUI } from 'game/extended/uis/skills/SkillUI'
import React from 'react'

import image from './self_sacrifice.png'

export const self_sacrifice_ui: SkillUI<'SelfSacrifice'> = {
    model: SelfSacrifice,
    configs: self_sacrifice_config,
    image,
    label: 'Self sacrifice',
    renderDescription: (config: SelfSacrificeConfig) => <span>
        When an ally would receive lethal damage, switch positions with this ally
        and take the damage yourself.
    </span>,
    renderTableStatic: (config: SelfSacrificeConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Cooldown</UI.TKey>
            <UI.TVal><UI.Turns turns={config.cooldown} /></UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (skill: SelfSacrifice, UI) => <>
        <UI.TRow>
            <UI.TKey>Total cooldown:</UI.TKey>
            <UI.TVal><UI.Turns turns={skill.cooldown} /></UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Ready in:</UI.TKey>
            <UI.TVal>
                {skill.triggerCooldown === false ? 'Ready' : <UI.Turns turns={skill.triggerCooldown} />}
            </UI.TVal>
        </UI.TRow>
    </>
}
