import { Effect, EffectDuration, Turns } from 'game/core'
import { Memoize } from 'game/util/memoize'

export class Stunned extends Effect<{
    duration: Turns
}> {

    static KEY = 'Stunned' as const

    @Memoize()
    get duration(): EffectDuration {
        return {
            type: 'turns',
            turns: this.state.duration
        }
    }
}
