import { Bats } from 'game/extended/creatures/undead/abilities/bats/bats'
import { Bite } from 'game/extended/creatures/undead/abilities/bite/bite'
import { Chaos } from 'game/extended/creatures/undead/abilities/chaos/chaos'
import { HitAndRun } from 'game/extended/creatures/undead/abilities/hit_and_run/hit_and_run'
import { Lifedrain } from 'game/extended/creatures/undead/abilities/lifedrain/lifedrain'
import { LifedrainEffect } from 'game/extended/creatures/undead/abilities/lifedrain/lifedrain_effect'
import { Seduce } from 'game/extended/creatures/undead/abilities/seduce/seduce'
import { SeduceEffect } from 'game/extended/creatures/undead/abilities/seduce/seduce_effect'
import { ShadowStrike } from 'game/extended/creatures/undead/abilities/shadow_strike/shadow_strike'
import { Undead } from 'game/extended/creatures/undead/abilities/undead/undead'
import { UnholyCurse } from 'game/extended/creatures/undead/abilities/unholy_curse/unholy_curse'
import { UnholyCurseEffect } from 'game/extended/creatures/undead/abilities/unholy_curse/unholy_curse_effect'
import { bat } from 'game/extended/creatures/undead/bat/bat'
import { skeleton } from 'game/extended/creatures/undead/skeleton/skeleton'
import { skeleton_archer } from 'game/extended/creatures/undead/skeleton_archer/skeleton_archer'
import { skeleton_king } from 'game/extended/creatures/undead/skeleton_king/skeleton_king'
import { skeleton_wizard } from 'game/extended/creatures/undead/skeleton_wizard/skeleton_wizard'
import { vampire } from 'game/extended/creatures/undead/vampire/vampire'
import { vampiress } from 'game/extended/creatures/undead/vampiress/vampiress'

export default {
    types: {
        bat,
        skeleton_king,
        vampiress,
        vampire,
        skeleton_wizard,
        skeleton_archer,
        skeleton
    },
    abilities: {
        Bite,
        ShadowStrike,
        Seduce,
        Chaos,
        Bats,
        HitAndRun,
        UnholyCurse,
        Lifedrain
    },
    skills: {
        Undead
    },
    effects: {
        SeduceEffect,
        UnholyCurseEffect,
        LifedrainEffect
    }
}
