import 'views/layout/button.scss'

import React from 'react'
import { styler } from 'views/layout/util/style'
import { Icon, IconKey } from 'views/ui/icon'
import { StatIcon } from 'views/ui/stats/staticon'
import { AnchorAttrs, Mod } from 'views/ui/ui_types'
import { TutorialHint } from 'views/ingame/campaign/tutorial'

const style = styler('button', ['price', 'icon'])

export const Button: React.FC<{
    disabled?: boolean
    mod?: Mod<'loading' | 'xp' | 'currency' | 'negative'>
    hinted?: boolean
} & AnchorAttrs> = ({ mod, children, hinted, disabled = false, ...rest }) => {

    return <a {...rest} className={style().mergeProps(rest).mod(mod).is({ disabled })}>
        {children}
        {hinted && <TutorialHint />}
    </a>
}

export const ButtonPrice: React.FC<{
    stat: IconKey
    amount: number
}> = ({ stat, amount }) => {

    return <div className={style.price()}>
        {amount}<StatIcon stat={stat} mod="unit" />
    </div>
}

export const ButtonIcon: React.FC<{
    icon: IconKey
}> = ({ icon }) => {

    return <div className={style.icon()}>
        <Icon icon={icon} />
    </div>
}
