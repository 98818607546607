import { SkillStacksAdded } from 'game/core'
import { EventUI } from "game/extended/uis/events/EventUI"
import React from 'react'

export const skill_stacks_added_ui: EventUI<'SkillStacksAdded'> = {
    model: SkillStacksAdded,

    render: (event: SkillStacksAdded, UI) => {
        return <>
            <UI.Char character={event.character} /> gains
            {event.stacksAdded === 1 && ' an extra stack '}
            {event.stacksAdded > 1 && `${event.stacksAdded} extra stacks `}
            on ${event.character && !event.character.isMale ? 'her' : 'his'}
            {' '}<UI.Skill skill={event.skill} />, giving a total of {event.stacks} stacks.
        </>
    }
}
