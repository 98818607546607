import { AttackRange, DamageType, ItemRarity, Weapon } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './blade_bow.png'

export const blade_bow: ItemUI<Weapon> = {
    image,
    label: 'Blade bow',
    value: 130,
    item: {
        key: 'blade_bow',
        type: 'weapon',
        rarity: ItemRarity.Rare,
        stats: {
            strength: 50,
            health: 40
        },
        two_handed: true,
        category: 'bow',
        damageType: DamageType.Ranged,
        range: AttackRange.Ranged,
        energy: 4,
        damage: 13
    }
}
