import { Boots, ItemRarity } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './bandit_boots.png'

export const bandit_boots: ItemUI<Boots> = {
    image,
    label: 'Bandit boots',
    value: 15,
    item: {
        key: 'bandit_boots',
        type: 'boots',
        rarity: ItemRarity.Common,
        stats: {
            agility: 15,
            strength: 15,
            health: 5,
        }
    }
}
