import { Boots, ItemRarity } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './spirit_boots.png'

export const spirit_boots: ItemUI<Boots> = {
    image,
    label: 'Spirit boots',
    value: 25,
    item: {
        key: 'spirit_boots',
        type: 'boots',
        rarity: ItemRarity.Rare,
        stats: {
            health: 10,
            agility: 10,
            resistance: 10,
            energy: 2
        }
    }
}
