import { TrueSightConfig } from 'game/extended/heros/ranger/true_sight/true_sight'

export const true_sight_config: Array<TrueSightConfig> = [
    {
        available: 4,
        cooldown: 8,
        energy: 4
    },
    {
        available: 3,
        cooldown: 6,
        energy: 3
    },
    {
        available: 1,
        cooldown: 3,
        energy: 2
    }
]