import { Ability, AbilityConfig, Battle, Character, Percentage, Turns } from 'game/core'
import { WarcryEffect } from 'game/extended/heros/barbarian/warcry/warcry_effect'
import { Memoize } from 'game/util/memoize'

export type WarcryConfig = AbilityConfig & {
    damage_increase: Percentage
    energy_increase: number,
    duration: Turns
}

export class Warcry extends Ability<WarcryConfig>{

    static KEY = 'Warcry' as const
    static FIXED_REQUIREMENTS = []

    @Memoize()
    get targets(): Array<Character> {
        return this.character.allAllies
    }

    @Memoize()
    get damageIncrease(): Percentage {
        return this.currentConfig.damage_increase
    }

    @Memoize()
    get energyIncrease(): number {
        return this.currentConfig.energy_increase
    }

    @Memoize()
    get duration(): Turns {
        return this.currentConfig.duration
    }

    // ----- Calculate new State ----- //
    performActionImpl(): Battle {
        return this.performOnAllTargets(this.applyToCharacter)
    }

    private applyToCharacter = (char: Character) => {
        return char
            .addEffect(WarcryEffect, {
                origin: this.character.path,
                damage_increase: this.damageIncrease,
                duration: this.duration
            })
            .perform(Character, char.path, (char: Character) => char.changeEnergy(this.energyIncrease))
    }
}