import { SkillConfig } from 'game/core'
import { SkillKey } from 'game/extended/types'
import { getSkillUI } from 'game/extended/uis/skills/skill_uis'
import { SkillUI } from 'game/extended/uis/skills/SkillUI'
import { Memoize } from 'game/util/memoize'
import { TalentModel, TalentState } from 'models/user/hero/talents/TalentModel'
import { UserModel } from 'models/user/UserModel'

export interface SkillTalentState extends TalentState {
    key: SkillKey
}

export class SkillTalent extends TalentModel<SkillTalentState> {

    @Memoize()
    get configs(): Array<SkillConfig> {
        return this.user.env.getConfig(this.key)
    }

    @Memoize()
    get maxLevel(): number {
        return this.configs.length
    }

    @Memoize()
    get key(): SkillKey {
        return this.state.key
    }

    @Memoize()
    get isExpert(): boolean {
        return this.key === this.hero.definition.super_skill
    }

    @Memoize()
    get label(): string {
        return this.ui ? this.ui.label : this.key
    }

    @Memoize()
    get ui(): SkillUI<any> {
        return getSkillUI(this.state.key)
    }

    get canUpgrade(): boolean {
        if (this.isExpert && this.hero.level < 10) return false
        return super.canUpgrade
    }

    // ----- Calculate new State ----- //
    upgrade(): UserModel {
        if (!this.canUpgrade) return this.user
        return this.hero.update({
            skill_points: this.hero.skillPoints - this.cost,
            skills: this.hero.skillTalents.map(talent =>
                talent !== this ? talent.state : {
                    ...this.state,
                    level: this.state.level + 1
                }
            )
        })
    }
}
