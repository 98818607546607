import { Ability, RequiredMobility, Requirement } from 'game/core'
import { Memoize } from 'game/util/memoize'

import { AbilityConfig } from '../ability'
import { RequiredPosition, RequiredRoom } from '../requirements'

export type MoveFrontConfig = AbilityConfig

export class MoveFront extends Ability<MoveFrontConfig> {

    static KEY = 'MoveFront' as const
    static FIXED_REQUIREMENTS = [
        Requirement.init(RequiredMobility, {}),
        Requirement.init(RequiredPosition, { pos: 'support' }),
        Requirement.init(RequiredRoom, { pos: 'front' })
    ]

    @Memoize()
    get targets() {
        return false as const
    }

    // ----- Calculate new State ----- //
    performActionImpl() {
        return this.character.moveToFront()
    }
}
