import {
    Ability,
    Effect,
    Idle,
    MoveFront,
    MoveSupport,
    PrimaryWeaponAttack,
    Rest,
    SecondaryWeaponAttack,
    Turns,
} from 'game/core'
import { CleansingLight } from 'game/extended/heros/paladin/cleansing_light/cleansing_light'
import { Memoize } from 'game/util/memoize'

export class MagicInterruptEffect extends Effect<{
    duration: Turns
}> {
    static KEY = 'MagicInterruptEffect' as const

    @Memoize()
    get duration() {
        return {
            type: 'turns',
            turns: this.state.duration
        } as const
    }

    // ----- Reactions ----- //
    canPerform(ability: Ability): boolean {
        if (ability instanceof Rest) return true
        if (ability instanceof Idle) return true
        if (ability instanceof PrimaryWeaponAttack) return true
        if (ability instanceof SecondaryWeaponAttack) return true
        if (ability instanceof MoveFront) return true
        if (ability instanceof MoveSupport) return true
        //Exceptional
        if (ability instanceof CleansingLight) return true
        return false
    }
}