import 'views/ingame/user/academy/recruit.scss'

import { StatsKey } from 'game/core'
import { RecruitModel } from 'models/user/RecruitModel'
import React from 'react'
import { useUser } from 'views/ingame/user/usercontext'
import { Button, ButtonPrice } from 'views/layout/button'
import { styler } from 'views/layout/util/style'
import { useLinker } from 'views/link'
import { StatIcon } from 'views/ui/stats/staticon'
import { CharacterVisual } from 'views/ui/visuals/charactervisual'

const styles = styler('recruit', ['stat'])

export const Recruit: React.FC<{
    recruit: RecruitModel
}> = ({ recruit }) => {
    const { setUser } = useUser()

    const { goTo } = useLinker()
    const stats = recruit.ui.definition.stats

    return <div className={styles()}>
        <div className={styles('info')}>
            <div className={styles('label')}>
                {recruit.ui.label}
            </div>
            <div className={styles('top')}>
                <div className={styles('top-image')}>
                    <CharacterVisual
                        type={recruit.key}
                    />
                </div>
            </div>
            <div className={styles('stats')}>
                <RecruitStat value={stats.health} icon="health" />
                <RecruitStat value={stats.energy} icon="energy" />
                <RecruitStat value={stats.strength} icon="strength" />
                <RecruitStat value={stats.armor} icon="armor" />
                <RecruitStat value={stats.agility} icon="agility" />
                <RecruitStat value={stats.shielding} icon="shielding" />
                <RecruitStat value={stats.intelligence} icon="intelligence" />
                <RecruitStat value={stats.resistance} icon="resistance" />
            </div>
        </div>
        <div className={styles('actions')}>
            <Button
                onClick={() => {
                    if (recruit.user.heros.length === 0) {
                        setUser(recruit.recruit().shop.shuffle())
                    } else {
                        setUser(recruit.recruit())
                    }
                    goTo(`/team/${recruit.key}`)
                }}
                mod="currency"
            >
                Recruit
                <ButtonPrice
                    stat="currency"
                    amount={recruit.price}
                />
            </Button>
        </div>
    </div>
}

export const RecruitStat: React.FC<{
    icon: StatsKey
    value: number | string
}> = ({ icon, value }) => {

    return <div className={styles.stat()}>
        {icon && <StatIcon stat={icon} />}
        <div className={styles.stat('value')} >
            {value}
        </div>
    </div>
}
