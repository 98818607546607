import { AttackRange, DamageType, ItemRarity, Weapon } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './viking_bow.png'

export const viking_bow: ItemUI<Weapon> = {
    image,
    label: 'Viking bow',
    value: 120,
    item: {
        key: 'viking_bow',
        type: 'weapon',
        rarity: ItemRarity.Epic,
        stats: {
            agility: 50,
            strength: 50,
            health: 50
        },
        two_handed: true,
        category: 'bow',
        damageType: DamageType.Ranged,
        range: AttackRange.Ranged,
        energy: 2,
        damage: 10
    }
}
