import { ArcaneGrowthConfig } from 'game/extended/heros/mage/arcane_growth/arcane_growth'

export const arcane_growth_config: Array<ArcaneGrowthConfig> = [
    {
        increment: 10,
        interval: 3,
    },
    {
        increment: 8,
        interval: 2,
    },
    {
        increment: 5,
        interval: 1
    }
]