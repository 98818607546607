import { Ruthless, RuthlessConfig } from 'game/extended/heros/barbarian/ruthless/ruthless'
import { SkillUI } from 'game/extended/uis/skills/SkillUI'
import { ruthless_config } from './ruthless_config'
import React from 'react'

import image from './ruthless.png'

export const ruthless_ui: SkillUI<'Ruthless'> = {
    model: Ruthless,
    configs: ruthless_config,
    image,
    label: 'Ruthless',
    renderDescription: () => <>
        For each percent of hp the enemy is missing, increase your own damage by a percentage.
    </>,
    renderTableStatic: (config: RuthlessConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Damage increase</UI.TKey>
            <UI.TVal>{config.increase_per_percent}%</UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (skill: Ruthless, UI) => <>
        <UI.TRow>
            <UI.TKey>Damage increase</UI.TKey>
            <UI.TVal>{skill.increasePerMissingHpPercent}%</UI.TVal>
        </UI.TRow>
    </>
}
