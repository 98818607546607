import {
    Ability,
    AbilityPath,
    Battle,
    ChargingState,
    ChildGameModel,
    Condition,
    CoreAbilityKey,
    CoreConditionState,
    RequirementPath,
    Type,
    TypePath,
} from 'game/core'
import { ConditionPath } from 'game/core/condition'
import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'

export interface RuleState<A extends CoreAbilityKey = CoreAbilityKey> {
    sequence: Array<A>,
    conditions: Array<CoreConditionState>
}

export interface RulePath extends TypePath {
    rule: number
}

/*export type ChargeAttemptFailed =
    | { type: 'current_sequence', abilities: Array<ChargingAbilitySkipped> }
    | { type: 'rules_skipped' } & ChargingRuleSkipped
    | { type: 'abilities_skipped', abilities: Array<ChargingAbilitySkipped> }*/

export type ChargingProcess = {
    current_sequence_skipped: Array<ChargingAbilitySkipped>
    current_sequence_continued: boolean
    rules_skipped: Array<ChargingRuleSkipped>
    abilities_skipped: Array<ChargingAbilitySkipped>
    charging?: ChargingState
}

export type ChargingAbilitySkipped = {
    ability: AbilityPath,
    reasons: Array<RequirementPath>
}

export type ChargingRuleSkipped = {
    rule: RulePath,
    reason: ChargingRuleSkippedReason
}

export type ChargingRuleSkippedReason =
    | { key: 'condition_failed', condition: ConditionPath }
    | { key: 'no_castable_abilities', abilities: Array<ChargingAbilitySkipped> }

export class Rule extends ChildGameModel<Type, RuleState> {

    static byPath(battle: Battle, path: RulePath): Nullable<Rule> {
        const type = Type.byPath(battle, path)
        return type && type.rules[path.rule]
    }

    get type(): Type {
        return this.parent
    }

    @Memoize()
    get directChildren() {
        return []
    }

    @Memoize()
    get path() {
        return { ...this.type.path, rule: this.index }
    }

    @Memoize()
    get index() {
        return this.type.rules.findIndex(rule => rule === this)
    }

    @Memoize()
    get nextRule(): Nullable<Rule> {
        return this.type.rules[this.index + 1]
    }

    @Memoize()
    get character() {
        return this.type.character
    }

    @Memoize()
    get conditions(): Array<Condition> {
        return this.state.conditions
            .filter(cond => cond)
            .map(state => this.env.createModel(this, state as CoreConditionState))
    }

    @Memoize()
    get firstBlockingCondition(): Nullable<Condition> {
        return this.conditions.find(condition => condition.isBlocking)
    }

    @Memoize()
    get abilities(): Array<Ability> {
        return this.state.sequence.map(key => {
            const ability = this.type.abilities.find(ab => ab.key === key)
            if (!ability) {
                throw new Error(`Unknown ability used in sequence: ${key}`)
            }
            return ability
        })
    }

    chargeActiveRule(index: number, process: ChargingProcess): ChargingProcess {
        const ability = this.abilities[index]

        if (!ability) {
            return this.type.rules[0].charge(process)
        }

        if (ability.hasFailingReq) {
            return this.chargeActiveRule(index + 1, {
                ...process,
                abilities_skipped: [...process.abilities_skipped, {
                    ability: ability.path,
                    reasons: ability.failingReqs.map(req => req.path)
                }]
            })
        }

        return {
            ...process,
            charging: { ability: ability.path, index, rule: this.path }
        }
    }

    charge(process: ChargingProcess): ChargingProcess {
        if (this.firstBlockingCondition) {
            const updatedProcess: ChargingProcess = {
                ...process,
                current_sequence_continued: false,
                rules_skipped: [
                    ...process.rules_skipped,
                    {
                        rule: this.path,
                        reason: {
                            key: 'condition_failed',
                            condition: this.firstBlockingCondition?.path
                        }
                    }
                ]
            }
            return this.nextRule?.charge(updatedProcess) || updatedProcess
        }

        const ability = this.abilities.find(ability => !ability.hasFailingReq)
        if (!ability) {
            const updatedProcess: ChargingProcess = {
                ...process,
                current_sequence_continued: false,
                rules_skipped: [...process.rules_skipped, {
                    rule: this.path,
                    reason: {
                        key: 'no_castable_abilities',
                        abilities: this.abilities.map(ab => ({
                            ability: ab.path,
                            reasons: ab.failingReqs.map(req => req.path)
                        }))
                    }
                }]
            }
            return this.nextRule?.charge(updatedProcess) || updatedProcess
        }

        const index = this.abilities.findIndex(ab => ab === ability)
        return {
            ...process,
            current_sequence_continued: false,
            abilities_skipped: this.abilities.slice(0, index).map(ab => ({
                ability: ab.path,
                reasons: ab.failingReqs.map(req => req.path)
            })),
            charging: {
                ability: ability.path,
                index: index,
                rule: this.path
            }
        }
    }
}
