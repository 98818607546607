import { Ability, AbilityConfig, Character, DamageNature, DamageType, FullDamage, Percentage } from 'game/core'
import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'

export type TossConfig = AbilityConfig & {
  percentage: Percentage
}

export class Toss extends Ability<TossConfig>{

  static KEY = 'Toss' as const
  static FIXED_REQUIREMENTS = []

  @Memoize()
  get targets(): Nullable<Character> {
    return this.character.meleeTarget
  }

  @Memoize()
  get secondaryTarget(): Nullable<Character> {
    return this.targets?.behindAlly
  }

  @Memoize()
  get percentage(): Percentage {
    return this.currentConfig.percentage
  }

  @Memoize()
  get damage(): FullDamage {
    const amount = Math.round((this.targets?.maxHp || 0) * this.percentage / 100)
    return this.calculateDamage(DamageType.Ranged, DamageNature.Ranged, amount)
  }

  // ----- State change inquiry ----- //
  performActionImpl() {
    if (!this.targets) return this.battle
    const frontTarget = this.targets
    const rangedTarget = this.secondaryTarget
    return this.battle
      .perform(Character, frontTarget.path, char => char.takeDamage(this.damage))
      .perform(Character, frontTarget.path, char => char.stun(1, this.path))
      .perform(Character, rangedTarget?.path, char => char.takeDamage(this.damage))
      .perform(Character, rangedTarget?.path, char => char.stun(1, this.path))
      .perform(Character, frontTarget.path, char => char.moveToSupport(rangedTarget?.pos || frontTarget.pos))
  }
}
