import { DamageType, Ratio, Stats } from 'game/core'

export const weaponCategories = ['sword', 'staff', 'spear', 'bow', 'axe', 'club', 'wand'] as const

export type WeaponCategory = typeof weaponCategories[number]

export enum AttackRange {
    Melee = "MELEE",
    Ranged = "RANGED",
    Mixed = "MIXED"
}

export enum ItemRarity {
    Common = "COMMON",
    Rare = "RARE",
    Epic = "EPIC",
    Legendary = "LEGENDARY"
}

export type CoreItemKey = string

interface ItemStats {
    key: CoreItemKey
    rarity: ItemRarity
    stats: Partial<Stats>
}

export interface Weapon extends ItemStats {
    type: 'weapon'
    category: WeaponCategory
    damageType: DamageType
    range: AttackRange
    energy: number
    damage: number
    secondary_damage?: {}[]
    two_handed: boolean
    true_damage?: boolean
    splashSide?: Ratio
    splashFront?: Ratio
    splashBehind?: Ratio
}
export interface Armor extends ItemStats {
    type: 'armor'
}
export interface Headgear extends ItemStats {
    type: 'headgear'
}
export interface Boots extends ItemStats {
    type: 'boots'
}
export interface Trinket extends ItemStats {
    type: 'trinket'
}
export interface Shield extends ItemStats {
    type: 'shield'
}

export type Wearable = Armor | Headgear | Trinket | Boots
export type Item = Weapon | Shield | Wearable

export type ItemType = Item['type']
