import { general_achievements } from 'data/user/achievements/configs/general_achievements'
import { ClassKey } from 'game/extended/types'
import { AchievementConfig, AchievementKey } from 'models/user/hero/achievements/Achievement'

export const achievement_configs: Record<ClassKey, Partial<Record<AchievementKey, AchievementConfig>>> = {
    tutorial_barbarian: {
        BattlesWonAchievement: {
            goals: [1, 2, 3, 4],
            reward: {
                health: 10
            }
        }
    },
    barbarian: {
        ...general_achievements
    },
    mage: {
        ...general_achievements
    },
    paladin: {
        ...general_achievements
    },
    ranger: {
        ...general_achievements
    }
}
