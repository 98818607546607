import { CreatureUI } from 'game/extended/uis/types/creature_uis'

import circle from './vampiress_circle.png'
import head from './vampiress_head.png'

export const vampiress: CreatureUI = {
  label: 'Vampiress',
  gender: 'female',
  definition: lvl => ({
    stats: {
      health: 150,
      energy: 20,
      intelligence: 200,
      strength: 100,
      agility: 50,
      armor: 20,
      shielding: 25,
      resistance: 50
    },
    equipment:
    {
      primary: 'vampiress_sword',
      secondary: 'demon_crossbow',
      headgear: 'vampiress_crown',
      trinket: 'vampire_ring'
    },
    skills: [

    ],
    strategy: [
      {
        abilities: ['Seduce'],
        conditions: [
          { key: 'WhenPositioned', position: 'front' }
        ]
      },
      'Bite',
      {
        abilities: ['MoveSupport'],
        conditions: [
          { key: 'WhenHurt', target: 'self', percent: 20 }
        ]
      },
      'PrimaryWeaponAttack',
      'SecondaryWeaponAttack'
    ]
  }),
  circle: {
    src: circle,
    width: 240,
    height: 150
  },
  head
}
