import { Battle, Environment } from 'game/core'
import { CreatureKey, items } from 'game/extended/types'
import { addCreatureToLine } from 'game/extended/uis/types/creature_uis'
import { keysof } from 'game/util/keysof'
import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'
import { LineupHistory } from 'models/campaign/MissionModel'
import { EndlessNextLevelModel } from 'models/endless/EndlessNextLevelModel'
import { EndlessPrevLevelModel } from 'models/endless/EndlessPrevLevelModel'
import { generateEndlessLevel } from 'models/endless/generate_endless_level'
import { EndlessResultModel } from 'models/ranking/EndlessResultModel'
import { ReplayModel } from 'models/replay/ReplayModel'
import { UserModel, UserState } from 'models/user/UserModel'

export type EndlessState = {
  user: UserState
  deaths?: number
  levels_finished?: number
  level: EndlessLevel
  last_history?: {
    lineup: LineupHistory
    level: EndlessLevel
    stars: number
    rounds: number
  }
  last_replay?: ReplayModel //Should not be serialised or stored locally
}

export type EndlessLevel = {
  points: number
  front: Array<EndlessCreature>
  support: Array<EndlessCreature>
}

export type EndlessCreature = {
  type: CreatureKey,
  name: string,
  level: number
}

export class EndlessModel {

  constructor(readonly state: EndlessState, readonly env: Environment) { }

  @Memoize()
  get user(): UserModel {
    return new UserModel(this.state.user, {
      classes: ['barbarian', 'mage', 'paladin', 'ranger'],
      items: keysof(items),
      env: this.env
    })
  }

  @Memoize()
  get previousLevel(): Nullable<EndlessPrevLevelModel> {
    return this.state.last_history && new EndlessPrevLevelModel(this)
  }

  @Memoize()
  get nextLevel(): EndlessNextLevelModel {
    return new EndlessNextLevelModel(this)
  }

  @Memoize()
  get levelsFinished(): number {
    return this.state.levels_finished || 0
  }

  @Memoize()
  get lifes(): number {
    return 8 - this.deaths
  }

  @Memoize()
  get isFinished(): boolean {
    return this.lifes <= 0
  }

  @Memoize()
  get deaths(): number {
    return this.state.deaths || 0
  }

  @Memoize()
  get levelNumber(): number {
    return this.levelsFinished + 1
  }

  @Memoize()
  get level(): EndlessLevel {
    return this.state.level
  }

  @Memoize()
  get lastReplay(): Nullable<ReplayModel> {
    return this.state.last_replay
  }

  @Memoize()
  get result(): EndlessResultModel {
    return new EndlessResultModel({
      levels: this.levelsFinished,
      heroes: this.user.heros.map(hero => ({
        achievements: hero.achievements.length,
        level: hero.level,
        type: hero.type
      }))
    })
  }

  @Memoize()
  get battle(): Battle {
    let battle = new Battle(this.user.env, Battle.initialState())
    this.user.lineup.frontHeros.forEach(hero =>
      battle = hero.addToBattle(battle.left.front)
    )
    this.user.lineup.supportHeros.forEach(hero =>
      battle = hero.addToBattle(battle.left.support)
    )
    this.level.front.forEach(c =>
      battle = addCreatureToLine(battle.right.front, c.type, c.level, c.name)
    )
    this.level.support.forEach(c =>
      battle = addCreatureToLine(battle.right.support, c.type, c.level, c.name)
    )
    return battle
  }

  // ----- Calculate new State ----- //
  update(newState: Partial<EndlessState>): EndlessModel {
    return new EndlessModel({
      ...this.state,
      ...newState
    }, this.env)
  }

  updateUser(user: UserModel): EndlessModel {
    return this.update({ user: user.state })
  }

  shuffleShop(): EndlessModel {
    return this.updateUser(this.user.shop.shuffle())
  }

  fight(): EndlessModel {
    if (this.isFinished) return this

    const battle_id = 'battle_' + this.levelNumber
    const replay = ReplayModel.createFromBattle(this.battle)
    let result = this.update({ last_replay: replay })

    const updatedUser = result.user.updateAchievements(battle_id, replay.result)
    result = result.updateUser(updatedUser)
    return (replay.isVictorious ? result.upLevel(replay) : result.upDeaths())
      .shuffleShop()
  }

  upLevel(replay: ReplayModel): EndlessModel {
    return this.update({
      levels_finished: this.levelsFinished + 1,
      level: generateEndlessLevel(this.levelNumber + 1),
      last_history: {
        level: this.level,
        lineup: {
          front: this.user.lineup.frontHeros.map(hero => hero.id),
          support: this.user.lineup.supportHeros.map(hero => hero.id),
        },
        rounds: replay.rounds.length,
        stars: 3
      },
      user: {
        ...this.user.state,
        money: this.user.state.money + 60
      }
    })
  }

  upDeaths(): EndlessModel {
    return this.update({
      deaths: this.deaths + 1,
    })
  }
}
