import { CharDied } from 'game/core'
import { AchievementModel } from 'models/user/hero/achievements/Achievement'
import { BattleHistory } from 'models/user/hero/achievements/BattleHistory'

export class ComboKillAchievement extends AchievementModel {

    calculateProgress(history: BattleHistory) {
        if (!history.char || !history.victorious) return 0

        const combos = new Map<number, number>()
        history.search(CharDied).forEach(state => {
            if (state.injury.damage.origin.character !== history.char?.character) return
            const combined = (combos.get(state.time) || 0) + 1
            combos.set(state.time, combined)
        })

        const maxCombo = Math.max(...combos.values())
        if (maxCombo <= this.progress) return 0
        return maxCombo - this.progress
    }
}
