import { DamageType } from 'game/core'
import { PushbackArrow, PushbackArrowConfig } from 'game/extended/heros/ranger/pushback_arrow/pushback_arrow'
import { pushback_arrow_config } from 'game/extended/heros/ranger/pushback_arrow/pushback_arrow_config'
import { AbilityUI } from 'game/extended/uis/abilities/AbilityUI'
import React from 'react'

import image from './pushback_arrow.png'

export const pushback_arrow_ui: AbilityUI<'PushbackArrow'> = {
    model: PushbackArrow,
    configs: pushback_arrow_config,
    image,
    label: 'Pushback arrow',
    renderTargets: () => 'Front',
    renderDescription: () => <>
        Fire a powerful arrow at your target, dealing damage and pushing the target
        to the back line.
    </>,
    renderImpression: (ability: PushbackArrow, UI) => <>
        <UI.Char character={ability.character} />{' '}fires a destructive arrow with such force
        that{' '}<UI.Char character={ability.targets} />{' '}gets catapulted away.
    </>,
    renderTableStatic: (config: PushbackArrowConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Damage:</UI.TKey>
            <UI.TVal><UI.Damage type={DamageType.Melee} damage={config.damage} /></UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (ability: PushbackArrow, UI) => <>
        <UI.TRow>
            <UI.TKey>Damage:</UI.TKey>
            <UI.TVal><UI.Damage damage={ability.damage} /></UI.TVal>
        </UI.TRow>
    </>
}
