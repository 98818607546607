import { Immunity, ImmunityConfig } from 'game/extended/heros/paladin/immunity/immunity'
import { immunity_config } from 'game/extended/heros/paladin/immunity/immunity_config'
import { AbilityUI } from 'game/extended/uis/abilities/AbilityUI'
import React from 'react'

import image from './immunity.png'

export const immunity_ui: AbilityUI<'Immunity'> = {
    model: Immunity,
    configs: immunity_config,
    image,
    label: 'Immunity',
    renderTargets: () => 'Yourself',
    renderDescription: () => <>
        Temporarily make yourself immune to all damage.
    </>,
    renderImpression: (ability: Immunity) => <>
        A divine light surrounds {ability.character.name}, mitigating any source
        of damage aimed towards {ability.character.isMale ? 'him' : 'her'}.
    </>,
    renderTableStatic: (config: ImmunityConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Duration:</UI.TKey>
            <UI.TVal><UI.Turns turns={config.duration} /></UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (ability: Immunity, UI) => <>
        <UI.TRow>
            <UI.TKey>Duration:</UI.TKey>
            <UI.TVal><UI.Turns turns={ability.duration} /></UI.TVal>
        </UI.TRow>
    </>
}
