import { Ability, AbilityConfig, RequiredMobility, RequiredRoom, Requirement } from 'game/core'
import { Memoize } from 'game/util/memoize'

import { RequiredPosition } from '../requirements'

export type MoveSupportConfig = AbilityConfig

export class MoveSupport extends Ability<MoveSupportConfig> {

    static KEY = 'MoveSupport' as const
    static FIXED_REQUIREMENTS = [
        Requirement.init(RequiredMobility, {}),
        Requirement.init(RequiredPosition, { pos: 'front' }),
        Requirement.init(RequiredRoom, { pos: 'support' }),
    ]

    @Memoize()
    get targets() {
        return false as const
    }

    // ----- Calculate new State ----- //
    performActionImpl() {
        return this.character.moveToSupport()
    }
}
