import { CursedWeaponsConfig } from 'game/extended/creatures/goblins/abilities/cursed_weapons/cursed_weapons'

export const cursed_weapons_config: Array<CursedWeaponsConfig> = [
    {
        available: 0,
        cooldown: 6,
        energy: 3,
        duration: 2,
        reduction: 50
    }
]