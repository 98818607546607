import { CharDamaged, Event, Interrupt, Percentage, Skill, SkillConfig } from 'game/core'
import { Memoize } from 'game/util/memoize'

export interface PainStimulusConfig extends SkillConfig {
    hp: Percentage
    increase: number
}

export class PainStimulus extends Skill<{}, PainStimulusConfig>{

    static KEY = 'PainStimulus' as const

    @Memoize()
    get cutoffPercentage(): Percentage {
        return this.currentConfig.hp
    }

    @Memoize()
    get cutoffHp(): number {
        return Math.floor(this.cutoffPercentage / 100 * this.character.maxHp)
    }

    @Memoize()
    get energyIncrease(): number {
        return this.currentConfig.increase
    }

    // ----- Interrupts ----- //
    reactToEvent(event: Event): Interrupt {
        if (!(event instanceof CharDamaged)) return false
        if (event.target !== this.character) return false
        if (event.oldHp >= this.cutoffHp && event.newHp < this.cutoffHp) {
            return this.character.changeEnergy(this.energyIncrease)
        }
        return false
    }
}
