import { Item, Percentage, Skill, SkillConfig } from 'game/core'
import { keysof } from 'game/util/keysof'
import { Memoize } from 'game/util/memoize'

export interface AxeMasteryConfig extends SkillConfig {
  modifier: Percentage
}

export class AxeMastery extends Skill<{}, AxeMasteryConfig> {

  static KEY = 'AxeMastery' as const

  @Memoize()
  get modifier(): Percentage {
    return this.currentConfig.modifier
  }

  applyItemModifier(item: Item): Item {
    if (item.type !== 'weapon') return item
    if (item.category !== 'axe') return item
    const inc = (value: number) => Math.round(value * (100 + this.modifier) / 100)
    return {
      ...item,
      damage: inc(item.damage),
      stats: keysof(item.stats).reduce((result, key) =>
        ({ ...result, [key]: inc(item.stats[key] as number) }),
        {}
      )
    }
  }
}
