import { Armor, ItemRarity } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './sniper_vest.png'

export const sniper_vest: ItemUI<Armor> = {
    image,
    label: 'Sniper vest',
    value: 80,
    item: {
        key: 'sniper_vest',
        type: 'armor',
        rarity: ItemRarity.Epic,
        stats: {
            agility: 65,
            strength: 15,
            shielding: 15
        }
    }
}
