import { AbilityPerformed } from 'game/core'
import { EventUI } from 'game/extended/uis/events/EventUI'
import React from 'react'

export const ability_performed_ui: EventUI<'AbilityPerformed'> = {
    model: AbilityPerformed,

    render: (event: AbilityPerformed, UI) => <>
        <UI.Char character={event.character} />
        {' performs '}
        <UI.Ability ability={event.ability} />
    </>
}
