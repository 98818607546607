import 'views/ingame/user/team/itemstats.scss'
import React from 'react'

import { Item } from 'game/core'
import { Stats } from 'game/core'
import { StatIcon } from 'views/ui/stats/staticon'

export const ItemStats: React.FC<{
    item: Item
}> = ({ item }) => {
    const keys = Object.keys(item.stats) as Array<keyof Stats>
    const half = Math.ceil(keys.length / 2)
    const left = keys.slice(0, half)
    const right = keys.slice(half, keys.length)

    return <div className="itemstats">
        <div className="itemstats-left">
            {left.map(key =>
                <ItemstatsStat key={key} stat={key} value={item.stats[key] || 0} />
            )}
        </div>
        <div className="itemstats-right">
            {right.map(key =>
                <ItemstatsStat key={key} stat={key} value={item.stats[key] || 0} />
            )}
        </div>
    </div>
}

export const ItemstatsStat: React.FC<{
    stat: keyof Stats
    value: number
}> = ({ stat, value }) => {

    return <div className="itemstats_stat">
        <div className="itemstats_stat-value">
            {value > 0 ? `+${value}` : value}
        </div>
        <div className="itemstats_stat-icon">
            <StatIcon stat={stat} />
        </div>
    </div>
}
