import { ItemRarity, Trinket } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './water_bag.png'

export const water_bag: ItemUI<Trinket> = {
    image,
    label: 'Water bag',
    value: 10,
    item: {
        key: 'water_bag',
        type: 'trinket',
        rarity: ItemRarity.Common,
        stats: {
            energy: 3
        }
    }
}
