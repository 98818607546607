import { Undead, UndeadConfig } from 'game/extended/creatures/undead/abilities/undead/undead'
import { SkillUI } from 'game/extended/uis/skills/SkillUI'
import React from 'react'

import image from './undead.png'

export const undead_ui: SkillUI<'Undead'> = {
    model: Undead,
    configs: [
        {
            recovery: 3
        }
    ],
    image,
    label: 'Undead',
    renderDescription: (config: UndeadConfig) => <span>
        This undead creature recovers hitpoints at the end of each turn.
    </span>,
    renderTableStatic: (config: UndeadConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Recovery:</UI.TKey>
            <UI.TVal><UI.Healing healing={config.recovery} /></UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (skill: Undead, UI) => <>
        <UI.TRow>
            <UI.TKey>Recovery:</UI.TKey>
            <UI.TVal><UI.Healing healing={skill.recovery} /></UI.TVal>
        </UI.TRow>
    </>
}
