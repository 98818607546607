import { ItemKey, Items, items } from 'game/extended/types'
import { Memoize } from 'game/util/memoize'
import { UserModel } from 'models/user/UserModel'

export interface ProductState<K extends ItemKey = ItemKey> {
    key: K
    discount: boolean
}

export class ProductModel<K extends ItemKey = ItemKey> {

    constructor(readonly user: UserModel, readonly state: ProductState<K>) { }

    @Memoize()
    get key(): K {
        return this.state.key
    }

    @Memoize()
    get ui(): Items[K] {
        const result = items[this.key]
        if (!result) throw new Error(`Can't find UI for key ${this.key}`)
        return result
    }

    @Memoize()
    get item(): Items[K]['item'] {
        return this.ui.item
    }

    @Memoize()
    get isOnSale(): boolean {
        return this.state.discount
    }

    @Memoize()
    get amountOwned(): number {
        return this.user.inventory.filter(item => item.key === this.key).length
    }

    @Memoize()
    get price(): number {
        return Math.floor(this.ui.value * (this.isOnSale ? 0.5 : 1.0))
    }

    // ----- Calculate new State ----- //
    buy(): UserModel {
        if (this.user.money < this.price) return this.user

        return this.user.update({
            inventory: [...this.user.state.inventory, this.key],
            money: this.user.money - this.price
        })
    }
}
