import { UserModel } from 'models/user/UserModel'
import { Memoize } from 'game/util/memoize'
import { StrategyLine } from 'models/user/hero/strategy/StrategyLine'
import { Nullable } from 'game/util/maybe'
import { HeroModel } from 'models/user/hero/HeroModel'
import { AbilityTalent } from 'models/user/hero/talents/AbilityTalent'
import { AbilityKey } from 'game/extended/types'

export type AbilitySlotState = Nullable<AbilityKey>

export class AbilitySlot {

    constructor(readonly strategy: StrategyLine, readonly state: AbilitySlotState) { }

    static findIn(user: UserModel, reference: AbilitySlot): AbilitySlot {
        const hero = HeroModel.findIn(user, reference.strategy.hero)
        const result = hero.slots.find(slot => slot.key === reference.key) as AbilitySlot
        if (!result) throw Error(`Unable to find hero with id: ${reference.key}`)
        return result
    }

    @Memoize()
    get user(): UserModel {
        return this.strategy.user
    }

    @Memoize()
    get index(): number {
        return this.strategy.abilities.findIndex(slot => slot === this)
    }

    @Memoize()
    get label(): string {
        return "ability"
    }

    @Memoize()
    get key(): string {
        return `s${this.strategy.index}a${this.index}`
    }

    @Memoize()
    get abilityKey(): Nullable<AbilityKey> {
        return this.state
    }

    @Memoize()
    get talent(): Nullable<AbilityTalent> {
        return [...this.strategy.hero.abilityTalents, ...this.strategy.hero.baseAbilityTalents]
            .find(talent => talent.key === this.abilityKey)
    }

    @Memoize()
    get isFilled(): boolean {
        return this.state !== null
    }

    @Memoize()
    get isUseable(): boolean {
        if (!this.talent) return false
        return this.talent.level > 0
    }

    // ----- Calculate new State ----- //
    update(state: AbilitySlotState): UserModel {
        return this.strategy.update({
            abilities: this.strategy.abilities.map(ability => {
                if (ability === this) return state
                return ability.state
            })
        })
    }

    remove(): UserModel {
        return this.strategy.update({
            abilities: this.strategy.abilities.map(ability => {
                if (ability === this) return null
                return ability.state
            })
        })
    }
}
