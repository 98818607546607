import { AttackRange, DamageType, ItemRarity, Weapon } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './cleric_wand.png'

export const cleric_wand: ItemUI<Weapon> = {
    image,
    label: 'Cleric wand',
    value: 110,
    item: {
        key: 'cleric_wand',
        type: 'weapon',
        rarity: ItemRarity.Epic,
        stats: {
            intelligence: 40,
            health: 40,
            energy: 3
        },
        two_handed: false,
        category: 'wand',
        damageType: DamageType.Magic,
        range: AttackRange.Mixed,
        energy: 2,
        damage: 6
    }
}
