import { Memoize } from 'game/util/memoize'
import { HeroModel } from 'models/user/hero/HeroModel'
import { LineupSlot, LineupSlotState } from 'models/user/LineupSlot'
import { UserModel } from 'models/user/UserModel'

export interface LineupState {
    slots?: Array<LineupSlotState>
}

export interface LineupType {
    front: number
    support: number
}

export class LineupModel {

    constructor(readonly user: UserModel, readonly state: LineupState) { }

    @Memoize()
    get isEmpty(): boolean {
        return !this.frontHeros.length && !this.supportHeros.length
    }

    @Memoize()
    get frontSlots(): Array<LineupSlot> {
        return this.slots.filter(slot => slot.position === 'front')
    }

    @Memoize()
    get frontHeros(): Array<HeroModel> {
        return this.frontSlots
            .map(slot => slot.hero)
            .filter(hero => hero) as Array<HeroModel>
    }

    @Memoize()
    get supportSlots(): Array<LineupSlot> {
        return this.slots.filter(slot => slot.position === 'support')
    }

    @Memoize()
    get supportHeros(): Array<HeroModel> {
        return this.supportSlots
            .map(slot => slot.hero)
            .filter(hero => hero) as Array<HeroModel>
    }

    @Memoize()
    get slots(): Array<LineupSlot> {
        if (!this.state.slots) {
            return [
                ...[0, 1, 2].map(nr => new LineupSlot(this, { position: 'front', hero: null })),
                ...[0, 1, 2].map(nr => new LineupSlot(this, { position: 'support', hero: null }))
            ]
        }
        return this.state.slots.map(state => new LineupSlot(this, state))
    }

    @Memoize()
    get type(): LineupType {
        return { front: this.frontSlots.length, support: this.supportSlots.length }
    }

    // ----- Calculate new State ----- //
    update(newState: Partial<LineupState>) {
        return this.user.update({
            lineup: { ...this.state, ...newState }
        })
    }
}
