import { Headgear, ItemRarity } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './skull_helmet.png'

export const skull_helmet: ItemUI<Headgear> = {
    image,
    label: 'Skull helmet',
    value: 90,
    item: {
        key: 'skull_helmet',
        type: 'headgear',
        rarity: ItemRarity.Epic,
        stats: {
            resistance: 40,
            intelligence: 40,
            energy: 5
        }
    }
}
