import React, { useContext } from 'react'
import { Link as ReactLink, LinkProps, Redirect as ReactRedirect, useHistory } from 'react-router-dom'

export type LinkContextType = {
  processTo: (to: string) => string
}

export const LinkContext = React.createContext<LinkContextType>({
  processTo: to => to
})

export const useLinker = () => {
  const { processTo } = useContext(LinkContext)
  const history = useHistory()

  return {
    processTo: (to: string) => processTo(to),
    goTo: (to: string) => history.push(processTo(to))
  }
}

export const Link: React.FC<LinkProps & { to: string }> = props => {
  const { processTo } = useContext(LinkContext)
  const { children, to, ...linkProps } = props

  return <ReactLink to={processTo(to)} {...linkProps}>
    {children}
  </ReactLink>
}

export const Redirect: React.FC<{ to: string }> = ({ to }) => {
  const { processTo } = useContext(LinkContext)

  return <ReactRedirect to={processTo(to)} />
}