import { sample_barbarian } from 'data/user/heroes/sample_barbarian'
import { sample_mage } from 'data/user/heroes/sample_mage'
import { sample_paladin } from 'data/user/heroes/sample_paladin'
import { sample_ranger } from 'data/user/heroes/sample_ranger'
import { UserState } from 'models/user/UserModel'

export const sample_user: UserState = {
    money: 500,
    inventory: [
        'goblin_shield',
        'quick_sword',
        'shaman_wand',
        'short_bow'
    ],
    heros: [
        sample_barbarian,
        sample_paladin,
        sample_ranger,
        sample_mage
    ],
    counter: 0,
    lineup: {
        slots: [
            { position: 'front', hero: null },
            { position: 'front', hero: sample_barbarian.id },
            { position: 'front', hero: null },
            { position: 'support', hero: sample_ranger.id },
            { position: 'support', hero: sample_mage.id },
            { position: 'support', hero: sample_paladin.id }
        ]
    }
}
