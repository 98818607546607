import { Ability, AbilityPath, Character, CharacterPath, Event } from 'game/core'
import { Memoize } from 'game/util/memoize'
import { Nullable } from 'game/util/maybe'

export class AbilityPerformed extends Event<{
    targets: Array<CharacterPath>
    ability: AbilityPath
    energy: number
}> {
    static KEY = 'AbilityPerformed' as const

    @Memoize()
    get targets(): Array<Character> {
        return this.state.targets
            .map(path => Character.byPath(this.battle, path))
            .filter(target => target != null) as Array<Character>
    }

    @Memoize()
    get target(): Nullable<Character> {
        if (this.targets.length === 0) return null
        return this.targets[0]
    }

    @Memoize()
    get character(): Nullable<Character> {
        return Character.byPath(this.battle, this.state.ability)
    }

    @Memoize()
    get ability(): Nullable<Ability> {
        return Ability.byPath(this.battle, this.state.ability)
    }
}
