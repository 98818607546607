import { UnholyCurseEffect } from 'game/extended/creatures/undead/abilities/unholy_curse/unholy_curse_effect'
import { EffectUI } from 'game/extended/uis/effects/EffectUI'
import React from 'react'

import image from './unholy_curse.png'

export const unholy_curse_effect_ui: EffectUI<'UnholyCurseEffect'> = {
  model: UnholyCurseEffect,
  label: 'Unnholy curse effect',
  image,
  renderDescription: (effect: UnholyCurseEffect) => <>
    Any healing spells targeted towards this character will deal magic damage instead.
  </>
}
