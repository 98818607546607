import { AttackRange, DamageType, ItemRarity, Weapon } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './training_sword.png'

export const training_sword: ItemUI<Weapon> = {
    image,
    label: 'Training sword',
    value: 30,
    item: {
        key: 'training_sword',
        type: 'weapon',
        rarity: ItemRarity.Rare,
        stats: {
            health: 15
        },
        two_handed: false,
        category: 'sword',
        damageType: DamageType.Melee,
        range: AttackRange.Melee,
        energy: 1,
        damage: 6
    }
}
