import { DualSwingConfig } from 'game/extended/heros/barbarian/dual_swing/dual_swing'

export const dual_swing_config: DualSwingConfig[] = [
    {
        available: 2,
        cooldown: 3,
        energy: 6,
    },
    {
        available: 1,
        cooldown: 3,
        energy: 5,
    },
    {
        available: 0,
        cooldown: 2,
        energy: 5,
    }
]