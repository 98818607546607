import { Headgear, ItemRarity } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './leather_cap.png'

export const leather_cap: ItemUI<Headgear> = {
    image,
    label: 'Leather cap',
    value: 15,
    item: {
        key: 'leather_cap',
        type: 'headgear',
        rarity: ItemRarity.Common,
        stats: {
            health: 10,
            armor: 10
        }
    }
}
