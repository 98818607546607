import { EquipmentKey, Item } from 'game/core'
import { ItemKey, items } from 'game/extended/types'
import { ItemUI } from 'game/extended/uis/item_uis'
import { Memoize } from 'game/util/memoize'
import { HeroModel } from 'models/user/hero/HeroModel'
import { UserModel } from 'models/user/UserModel'

export type ItemState = {
    parent: UserModel
}

export class ItemModel {

    constructor(readonly user: UserModel, readonly key: ItemKey) { }

    @Memoize()
    get isEquipped(): boolean {
        return false
    }

    @Memoize()
    get index(): number {
        return this.user.items.indexOf(this)
    }

    @Memoize()
    get id(): string {
        return 'inventory_' + this.user.inventory.indexOf(this)
    }

    @Memoize()
    get item(): Item {
        return this.ui.item
    }

    @Memoize()
    get isTwoHandedWeapon(): boolean {
        return this.item.type === 'weapon' && !!this.item.two_handed
    }

    @Memoize()
    get ui(): ItemUI {
        return items[this.key]
    }

    isEquipableInSlot(key: EquipmentKey) {
        if (key === 'primary') return this.item.type === 'weapon'
        if (key === 'secondary') {
            return (this.item.type === 'weapon' && !this.item.two_handed) || this.item.type === 'shield'
        }

        return this.item.type === key
    }

    // ----- Calculate new State ----- //
}

export class EquippedItemModel extends ItemModel {

    constructor(readonly hero: HeroModel, readonly key: ItemKey, readonly slot: EquipmentKey) {
        super(hero.user, key)
    }

    @Memoize()
    get isEquipped(): boolean {
        return true
    }

    @Memoize()
    get id(): string {
        return 'hero_' + this.hero.id + '_' + this.hero.items.indexOf(this)
    }

    // ----- Calculate new State ----- //
    unequip(): UserModel {
        console.log('Unequip', this.slot)
        return this.hero.unequip(this.slot)
    }
}
