import { CharWeaponSwitched, DamageType, Effect, Event, Interrupt, Item, Percentage, Turns } from 'game/core'
import { Memoize } from 'game/util/memoize'

export class BurningWeaponEffect extends Effect<{
    increase: Percentage
    duration: Turns
}>{
    static KEY = 'BurningWeaponEffect' as const

    @Memoize()
    get duration() {
        return {
            type: 'turns' as const,
            turns: this.state.duration
        }
    }

    @Memoize()
    get increase(): Percentage {
        return this.state.increase
    }

    applyItemModifier(item: Item): Item {
        if (item.type !== 'weapon') return item
        return {
            ...item,
            damageType: DamageType.Magic,
            damage: Math.round(item.damage * (100 + this.increase) / 100)
        }
    }

    reactToEvent(event: Event): Interrupt {
        if (event instanceof CharWeaponSwitched) {
            if (event.character !== this.target) return false
            if (event.newWeapon === event.previousWeapon) return false
            return this.finalize()
        }

        return false
    }
}