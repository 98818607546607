import { AttackRange, DamageType, ItemRarity, Weapon } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './spiked_club.png'

export const spiked_club: ItemUI<Weapon> = {
    image,
    label: 'Spiked club',
    value: 30,
    item: {
        key: 'spiked_club',
        type: 'weapon',
        rarity: ItemRarity.Legendary,
        stats: {
            agility: -15,
            armor: -10
        },
        two_handed: true,
        category: 'club',
        damageType: DamageType.Melee,
        range: AttackRange.Melee,
        energy: 8,
        damage: 16
    }
}
