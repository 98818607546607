import { Headgear, ItemRarity } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './vampiress_crown.png'

export const vampiress_crown: ItemUI<Headgear> = {
  image,
  label: 'Vampiress crown',
  value: 65,
  item: {
    key: 'vampiress_crown',
    type: 'headgear',
    rarity: ItemRarity.Common,
    stats: {
      health: 25,
      intelligence: 35,
      strength: 20,
      resistance: 15
    }
  }
}
