import { UnreachableCaseError } from 'game/util/never'
import React, { useContext } from 'react'
import { CharacterInfo } from 'views/ingame/user/team/characterinfo'
import { CharEquipment } from 'views/replay/charpop/charequipment'
import { ReplayContext, ReplayContextPopupState } from 'views/replay/replay'
import { Popup, PopupContent, PopupHeader, PopupLeft, PopupRight, PopupTab, PopupTabs } from 'views/layout/popup'

import { CharAbilities } from './charpop/charabilities'
import { CharModifiers } from './charpop/charmodifiers'
import { CharStrategy } from './charpop/charstrategy'

export const CharPopup: React.FC = () => {
    const { popup, setPopup } = useContext(ReplayContext)
    if (!popup) return null

    const { char, tab } = popup

    return <Popup onClose={() => setPopup(null)}>
        <PopupHeader>
            <PopupTabs>
                <PopupTab
                    active={tab === 'effects'}
                    label={'Effects'}
                    onClick={() => setPopup({ char, tab: 'effects' })}
                />
                <PopupTab
                    active={popup.tab === 'abilities'}
                    label={'Abilities'}
                    onClick={() => setPopup({ char, tab: 'abilities' })}
                />
                <PopupTab
                    active={popup.tab === 'equipment'}
                    label={'Equipment'}
                    onClick={() => setPopup({ char, tab: 'equipment' })}
                />
                <PopupTab
                    active={popup.tab === 'strategy'}
                    label={'Strategy'}
                    onClick={() => setPopup({ char, tab: 'strategy' })}
                />
            </PopupTabs>
        </PopupHeader>
        <PopupContent>
            <PopupLeft>
                <CharacterInfo
                    type={char.key}
                    name={char.name}
                    level={char.type.level}
                    stats={char.char.stats}
                    onNext={() => setPopup({ char: char.next, tab })}
                    onPrevious={() => setPopup({ char: char.prev, tab })}
                />
            </PopupLeft>
            <PopupRight>
                <CharpopupTab popup={popup} />
            </PopupRight>
        </PopupContent>
    </Popup>
}

export const CharpopupTab: React.FC<{
    popup: ReplayContextPopupState
}> = ({ popup }) => {

    switch (popup.tab) {
        case 'effects':
            return <CharModifiers char={popup.char} />
        case 'abilities':
            return <CharAbilities char={popup.char} />
        case 'equipment':
            return <CharEquipment char={popup.char} />
        case 'strategy':
            return <CharStrategy char={popup.char} />
        default:
            throw new UnreachableCaseError(popup.tab)
    }
}
