import { Warcry, WarcryConfig } from 'game/extended/heros/barbarian/warcry/warcry'
import image from 'game/extended/heros/barbarian/warcry/warcry.png'
import { warcry_config } from 'game/extended/heros/barbarian/warcry/warcry_config'
import { AbilityUI } from 'game/extended/uis/abilities/AbilityUI'
import React from 'react'

export const warcry_ui: AbilityUI<'Warcry'> = {
    model: Warcry,
    configs: warcry_config,
    image,
    label: 'Warcry',
    renderTargets: () => 'All allies',
    renderDescription: () => <>
        Instantly restore energy and temporarily boost damage output for your entire team
    </>,
    renderImpression: (ability: Warcry, UI) => <>
        With a savage roar{' '}<UI.Char character={ability.character} />{' '}boosts the morale and killer spirit
        of his team.
    </>,
    renderTableStatic: (config: WarcryConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Restored:</UI.TKey>
            <UI.TVal><UI.Stat stat='energy' amount={config.energy_increase} /></UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Damage:</UI.TKey>
            <UI.TVal>{config.damage_increase}%</UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Duration:</UI.TKey>
            <UI.TVal><UI.Turns turns={config.duration} /></UI.TVal>
        </UI.TRow>
    </>
}
