import { AttackRange, DamageType, ItemRarity, Weapon } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './goblin_sword.png'

export const goblin_sword: ItemUI<Weapon> = {
    image,
    label: 'Goblin sword',
    value: 30,
    item: {
        key: 'goblin_sword',
        type: 'weapon',
        rarity: ItemRarity.Common,
        stats: {
            strength: 10,
            armor: 5
        },
        two_handed: false,
        category: 'sword',
        damageType: DamageType.Melee,
        range: AttackRange.Melee,
        energy: 3,
        damage: 9
    }
}
