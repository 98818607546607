import { Scholar, ScholarConfig } from 'game/extended/heros/mage/scholar/scholar'
import image from 'game/extended/heros/mage/scholar/scholar.png'
import { SkillUI } from 'game/extended/uis/skills/SkillUI'
import React from 'react'

export const scholar_ui: SkillUI<'Scholar'> = {
    model: Scholar,
    configs: [
        {
            intelligence: 15,
        },
        {
            intelligence: 30,
        },
        {
            intelligence: 50,
        }
    ],
    image,
    label: 'Scholar',
    renderDescription: (config: ScholarConfig) => <>
        Increases your intelligence
    </>,
    renderTableStatic: (config: ScholarConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Intelligence:</UI.TKey>
            <UI.TVal><UI.Stat stat='intelligence' amount={config.intelligence} /></UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (skill: Scholar, UI) => <>
        <UI.TRow>
            <UI.TKey>Intelligence:</UI.TKey>
            <UI.TVal><UI.Stat stat='intelligence' amount={skill.intelligenceModifier} /></UI.TVal>
        </UI.TRow>
    </>
}
