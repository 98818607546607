import { Ambusher } from 'game/extended/heros/ranger/ambusher/ambusher'
import ambusher_references from 'game/extended/heros/ranger/ambusher/ambusher_references'
import { ArcedShots } from 'game/extended/heros/ranger/arced_shots/arced_shots'
import { Cover } from 'game/extended/heros/ranger/cover/cover'
import cover_references from 'game/extended/heros/ranger/cover/cover_references'
import { ExplosiveArrow } from 'game/extended/heros/ranger/explosive_arrow/explosive_arrow'
import { FrostMastery } from 'game/extended/heros/ranger/frost_mastery/frost_mastery'
import frost_mastery_references from 'game/extended/heros/ranger/frost_mastery/frost_mastery_references'
import { PenetratingArrows } from 'game/extended/heros/ranger/penetrating_arrows/penetrating_arrows'
import penetrating_arrows_references from 'game/extended/heros/ranger/penetrating_arrows/penetrating_arrows_references'
import { PoisonedShot } from 'game/extended/heros/ranger/poisoned_shot/poisoned_shot'
import poisoned_shot_references from 'game/extended/heros/ranger/poisoned_shot/poisoned_shot_references'
import { PushbackArrow } from 'game/extended/heros/ranger/pushback_arrow/pushback_arrow'
import pushback_arrow_references from 'game/extended/heros/ranger/pushback_arrow/pushback_arrow_references'
import { Rush } from 'game/extended/heros/ranger/rush/rush'
import { Swift } from 'game/extended/heros/ranger/swift/swift'
import { TrueShots } from 'game/extended/heros/ranger/true_shots/true_shots'
import true_shots_references from 'game/extended/heros/ranger/true_shots/true_shots_references'
import { TrueSight } from 'game/extended/heros/ranger/true_sight/true_sight'
import true_sight_references from 'game/extended/heros/ranger/true_sight/true_sight_references'
import { Volley } from 'game/extended/heros/ranger/volley/volley'
import volley_references from 'game/extended/heros/ranger/volley/volley_references'

export default {
    abilities: {
        PoisonedShot,
        PushbackArrow,
        ExplosiveArrow,
        Volley,
        TrueSight,
        Rush
    },
    skills: {
        FrostMastery,
        Ambusher,
        Cover,
        ArcedShots,
        TrueShots,
        Swift,
        PenetratingArrows
    },
    effects: {
        ...poisoned_shot_references.effects,
        ...pushback_arrow_references.effects,
        ...true_sight_references.effects,
        ...volley_references.effects,
        ...frost_mastery_references.effects,
        ...ambusher_references.effects,
        ...cover_references.effects,
        ...true_shots_references.effects,
        ...penetrating_arrows_references.effects
    },
    events: {
        ...poisoned_shot_references.events,
        ...pushback_arrow_references.events,
        ...true_sight_references.events,
        ...volley_references.events,
        ...frost_mastery_references.events,
        ...ambusher_references.events,
        ...cover_references.events,
        ...true_shots_references.events,
        ...penetrating_arrows_references.events
    },
    requirements: {
        ...poisoned_shot_references.requirements,
        ...pushback_arrow_references.requirements,
        ...true_sight_references.requirements,
        ...volley_references.requirements,
        ...frost_mastery_references.requirements,
        ...ambusher_references.requirements,
        ...cover_references.requirements,
        ...true_shots_references.requirements,
        ...penetrating_arrows_references.requirements
    }
}
