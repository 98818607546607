import { DamageType, Effect, EffectDuration, Item, Percentage, Turns } from 'game/core'
import { Memoize } from 'game/util/memoize'

export class CursedWeaponsEffect extends Effect<{
    duration: Turns
    reduction: Percentage
}> {
    static KEY = 'CursedWeaponsEffect' as const

    @Memoize()
    get duration(): EffectDuration {
        return {
            type: 'turns',
            turns: this.state.duration
        }
    }

    @Memoize()
    get reduction(): Percentage {
        return this.state.reduction
    }

    // ----- Appliers ----- //
    applyItemModifier(item: Item): Item {
        if (item.type !== 'weapon') return item
        return {
            ...item,
            damage: Math.ceil(item.damage * this.reduction / 100),
            damageType: DamageType.Magic
        }
    }
}