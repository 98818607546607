import { BurningWeaponEffect } from 'game/extended/heros/mage/burning_weapon/burning_weapon_effect'
import { EffectUI } from 'game/extended/uis/effects/EffectUI'
import React from 'react'

import image from './burning_weapon.png'

export const burning_weapon_effect_ui: EffectUI<'BurningWeaponEffect'> = {
    model: BurningWeaponEffect,
    label: 'Burning weapon',
    image,
    renderDescription: (effect) => <>
        Your weapon attacks deal increased, magic damage.
    </>,
    renderTable: (effect: BurningWeaponEffect, UI) => <>
        <UI.TRow>
            <UI.TKey>Damage increase:</UI.TKey>
            <UI.TVal>{effect.increase}%</UI.TVal>
        </UI.TRow>
    </>
}
