import { Condition } from 'game/core'
import { Memoize } from 'game/util/memoize'

export type WhenTurnDirection = 'below' | 'exactly' | 'above'

export type WhenTurnState = {
    direction: WhenTurnDirection
    amount: number
}

export class WhenTurn extends Condition<WhenTurnState> {

    static KEY = 'WhenTurn' as const

    @Memoize()
    get turn(): number {
        return this.state.amount
    }

    @Memoize()
    get characterTurn(): number {
        return this.character.nextTurn
    }

    @Memoize()
    get isBlocking() {
        switch (this.state.direction) {
            case 'above':
                return this.characterTurn <= this.turn
            case 'exactly':
                return this.characterTurn !== this.turn
            case 'below':
                return this.characterTurn >= this.turn
        }
        return true
    }
}
