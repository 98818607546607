import {
  AbilityPath,
  Character,
  DamageNature,
  DamageType,
  Effect,
  EffectDuration,
  EffectTriggered,
  FullHealing,
  Interrupt,
  Turns,
} from 'game/core'
import { Memoize } from 'game/util/memoize'

export class UnholyCurseEffect extends Effect<{
  ability: AbilityPath,
  duration: Turns
}>{
  static KEY = 'UnholyCurseEffect' as const

  @Memoize()
  get duration(): EffectDuration {
    return {
      type: 'turns',
      turns: this.state.duration
    }
  }

  // ----- Reactions ----- //
  interruptOnHeal(target: Character, healing: FullHealing): Interrupt {
    if (target !== this.target) return false

    return this.battle
      .addEvent(EffectTriggered, { effect: this.path })
      .perform(Character, this.target.path, char => {
        return char.takeDamage({
          amount: healing.amount,
          modifiers: [],
          nature: DamageNature.Cursed,
          origin: this.state.ability,
          type: DamageType.Magic,
          original_amount: healing.amount,
        }, false)
      })
  }
}