import { Battle, Effect, EffectDuration, Stats } from 'game/core'
import { Memoize } from 'game/util/memoize'

export class ArcaneGrowthEffect extends Effect<{
    stacks: number
    increase: number
}>{
    static KEY = 'ArcaneGrowthEffect' as const

    @Memoize()
    get duration(): EffectDuration {
        return {
            type: 'unlimited'
        }
    }

    @Memoize()
    get stacks(): number {
        return this.state.stacks
    }

    @Memoize()
    get increase(): number {
        return this.state.increase
    }

    // ----- Appliers ----- //
    getTargetStats(currentStats: Stats): Stats {
        return {
            ...currentStats,
            intelligence: currentStats.intelligence + this.increase
        }
    }

    addStack(increase: number): Battle {
        return this.update({
            stacks: this.stacks + 1,
            increase: this.increase + increase
        })
    }
}
