import { CreatureUI } from 'game/extended/uis/types/creature_uis'

import circle from './bat_circle.png'
import head from './bat_head.png'

export const bat: CreatureUI = {
  label: 'Bat',
  definition: lvl => ({
    stats: {
      health: 50,
      energy: 15 + lvl * 2,
      intelligence: 20 + 10 * lvl,
      strength: 30,
      agility: 100 + lvl * 10,
      armor: 30,
      shielding: 10,
      resistance: 50
    },
    equipment:
    {
    },
    skills: [

    ],
    strategy: [
      {
        abilities: ['HitAndRun'],
        conditions: [
          {
            key: 'WhenPositioned',
            position: 'front'
          },
          {
            key: 'WhenHurt',
            target: 'self',
            percent: 30
          },
          {
            key: 'WhenCrowd',
            line: 'allied_front',
            direction: 'above',
            amount: 1
          }
        ]
      },
      'Bite',
      'Punch',
      {
        abilities: ['HitAndRun'],
        conditions: [{
          key: 'WhenEnergy',
          amount: 8,
          direction: 'above'
        }]
      }
    ]
  }),
  circle: {
    src: circle,
    width: 200,
    height: 150
  },
  head
}
