import { Event } from 'game/core'
import { EventKey } from 'game/extended/types'
import { getEventUI } from 'game/extended/uis/events/event_uis'
import React from 'react'
import { DefaultUI as UI, DefautUIBattleContext } from 'views/ui/default_ui'

export const Message: React.FC<{
    event: Event
}> = ({ event }) => {
    const eventUI = getEventUI(event.key as EventKey)
    const hasChildren = event.events.length > 0 || eventUI.renderDetails

    return <DefautUIBattleContext.Provider value={event.battle}>
        <UI.Log open={event.isFirst && event.key === 'ability_performed'} >
            <UI.LogMsg>
                {eventUI.render(event, UI)}
            </UI.LogMsg>
            {hasChildren && <UI.LogChildren>
                {eventUI.renderDetails && eventUI.renderDetails(event, UI)}
                {event.events.map((event, i) =>
                    <Message event={event} key={event.key + '_' + i} />
                )}
            </UI.LogChildren>}
        </UI.Log>
    </DefautUIBattleContext.Provider>
}
