import { Character, FullDamage, Percentage, Skill } from 'game/core'
import { Memoize } from 'game/util/memoize'

export type RuthlessConfig = {
    increase_per_percent: Percentage
}

export class Ruthless extends Skill<{}, RuthlessConfig> {

    static KEY = 'Ruthless' as const
    static FIXED_REQUIREMENTS = []

    @Memoize()
    get increasePerMissingHpPercent(): Percentage {
        return this.currentConfig.increase_per_percent
    }

    calculateIncreasedDamage(target: Character): Percentage {
        return this.currentConfig.increase_per_percent * (100 - target.hpRatio * 100)
    }

    // ----- Appliers ----- //
    applyOnTargetDamageModifier(damage: FullDamage, target: Character): FullDamage {
        return {
            ...damage,
            amount: Math.round(damage.amount * (100 + this.calculateIncreasedDamage(target)) / 100)
        }
    }
}
