import { Prepared, PreparedConfig } from 'game/extended/heros/mage/prepared/prepared'
import { SkillUI } from 'game/extended/uis/skills/SkillUI'
import React from 'react'

import image from './prepared.png'

export const prepared_ui: SkillUI<'Prepared'> = {
  model: Prepared,
  configs: [
    {
      reduction: 1
    },
    {
      reduction: 2
    },
    {
      reduction: 3
    }
  ],
  image,
  label: 'Prepared',
  renderDescription: (config: PreparedConfig) => <>
    The initial availability of all your abilities is reduced.
  </>,
  renderTableStatic: (config: PreparedConfig, UI) => <>
    <UI.TRow>
      <UI.TKey>Reduction:</UI.TKey>
      <UI.TVal><UI.Turns turns={config.reduction} /></UI.TVal>
    </UI.TRow>
  </>,
  renderTable: (skill: Prepared, UI) => <>
    <UI.TRow>
      <UI.TKey>Reduction:</UI.TKey>
      <UI.TVal><UI.Turns turns={skill.readyReduction} /></UI.TVal>
    </UI.TRow>
  </>
}
