import { RequiredAmountAllies } from 'game/core'
import { RequirementUI } from 'game/extended/uis/requirements/RequirementUI'
import { UnreachableCaseError } from 'game/util/never'
import React from 'react'

export const required_amount_allies_ui: RequirementUI<'RequiredAmountAllies'> = {
    model: RequiredAmountAllies,
    label: 'Required amount of allies',
    renderDescription: (state: RequiredAmountAllies['state'], UI) => {
        switch (state.pos) {
            case 'any':
                return <>
                    This ability can only be casted with at least {state.amount} allies.
                </>
            case 'front':
                return <>
                    This ability can only be casted with at least {state.amount} allies in the front line.
                </>
            case 'support':
                return <>
                    This ability can only be casted with at least {state.amount} allies in the support line.
                </>
            default:
                throw new UnreachableCaseError(state.pos)
        }
    }
}
