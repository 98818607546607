import { DamageType, Punch } from 'game/core'
import { AbilityUI } from 'game/extended/uis/abilities/AbilityUI'
import React from 'react'

import image from './punch.png'

export const punch_ui: AbilityUI<'Punch'> = {
    model: Punch,
    configs: [
        {
            available: 0,
            cooldown: 0,
            energy: 2,
            damage: 4
        }
    ],
    image,
    label: 'Punch',
    renderTargets: () => 'Melee',
    renderDescription: () => <>
        Punch the enemy in front of you.
    </>,
    renderImpression: (ability: Punch, UI) => <>
        <UI.Char character={ability.character} />{' '}punches{' '}<UI.Char character={ability.targets} />.
    </>,
    renderTableStatic: (conf, UI) => <>
        <UI.TRow>
            <UI.TKey>Damage:</UI.TKey>
            <UI.TVal>
                <UI.Damage damage={conf.damage} type={DamageType.Melee} />
            </UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (ability, UI) => <>
        <UI.TRow>
            <UI.TKey>Damage:</UI.TKey>
            <UI.TVal><UI.Damage damage={ability.damage} /></UI.TVal>
        </UI.TRow>
    </>
}
