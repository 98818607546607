import full from 'game/extended/heros/barbarian/barbarian.svg'
import circle from 'game/extended/heros/barbarian/barbarian_circle.svg'
import head from 'game/extended/heros/barbarian/barbarian_head.svg'
import barbarian_references from 'game/extended/heros/barbarian/barbarian_references'
import { ClassUI } from 'game/extended/uis/types/class_uis'
import { keysof } from 'game/util/keysof'

export const barbarian: ClassUI = {
    label: 'Barbarian',
    name: 'Graut',
    definition: {
        stats: {
            health: 100,
            energy: 25,
            strength: 45,
            intelligence: 20,
            agility: 30,
            armor: 20,
            shielding: 10,
            resistance: 10
        },
        masteries: {
            bow: 70,
            staff: 70,
            sword: 90,
            spear: 80,
        },
        abilities: keysof(barbarian_references.abilities),
        skills: keysof(barbarian_references.skills),
        super_ability: 'UndyingFury',
        super_skill: 'Bloodthurst'
    },
    circle: {
        src: circle,
        width: 200,
        height: 150
    },
    full,
    head
}
