import { ItemRarity, Shield } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './goblin_shield.png'

export const goblin_shield: ItemUI<Shield> = {
    image,
    label: 'Goblin shield',
    value: 15,
    item: {
        key: 'goblin_shield',
        type: 'shield',
        rarity: ItemRarity.Common,
        stats: {
            armor: 10,
            shielding: 10
        }
    }
}
