import { Burp, BurpConfig } from 'game/extended/creatures/goblins/abilities/burp/burp'
import { burp_config } from 'game/extended/creatures/goblins/abilities/burp/burp_config'
import { AbilityUI } from 'game/extended/uis/abilities/AbilityUI'
import React from 'react'

import image from './burp.png'

export const burp_ui: AbilityUI<'Burp'> = {
    model: Burp,
    configs: burp_config,
    image,
    label: 'Burp',
    renderTargets: () => 'Enemy frontline',
    renderDescription: () => <>
        Bonuses from equipment are nullified for all affected characters and
        and amount of damage is dealt on top of that.
    </>,
    renderImpression: (ability: Burp, UI) => <>
        <UI.Char character={ability.character} />{' '}opens {ability.character.isFemale ? 'her' : 'his'} mouth and
        and a toxic wave hits{' '}<UI.Char character={ability.targets} />.
    </>,
    renderTableStatic: (config: BurpConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Duration:</UI.TKey>
            <UI.TVal><UI.Turns turns={config.duration} /></UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (ability: Burp, UI) => <>
        <UI.TRow>
            <UI.TKey>Duration:</UI.TKey>
            <UI.TVal><UI.Turns turns={ability.duration} /></UI.TVal>
        </UI.TRow>
    </>
}
