import { Ability, AbilityConfig, Battle, Character, DamageNature, DamageType, FullDamage } from 'game/core'
import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'

export type RecklessBlowConfig = AbilityConfig & {
    target_damage: number,
    own_damage: number
}

export class RecklessBlow extends Ability<RecklessBlowConfig>{

    static KEY = 'RecklessBlow' as const
    static FIXED_REQUIREMENTS = []

    @Memoize()
    get targets(): Nullable<Character> {
        return this.character.meleeTarget
    }

    @Memoize()
    get targetDamage(): FullDamage {
        return this.calculateDamage(
            DamageType.Melee,
            DamageNature.Melee,
            this.currentConfig.target_damage
        )
    }

    @Memoize()
    get ownDamage(): FullDamage {
        return this.calculateDamage(
            DamageType.Melee,
            DamageNature.Melee,
            this.currentConfig.own_damage
        )
    }

    // ----- Calculate new State ----- //
    performActionImpl(): Battle {
        if (!this.targets) return this.battle
        return this
            .perform(Character, this.targets.path, char => char.takeDamage(this.targetDamage))
            .perform(Character, this.targets.path, char => char.stun(1, this.path))
            .perform(Character, this.path, char => char.takeDamage(this.ownDamage))
    }
}
