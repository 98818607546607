import 'views/replay/board/controls.scss'

import React, { useContext } from 'react'
import { styler } from 'views/layout/util/style'
import { ReplayContext } from 'views/replay/replay'
import { Icon, IconKey } from 'views/ui/icon'

const style = styler('controls', ['group', 'button'])

export const Controls: React.FC = ({ children }) => {
    const { turn, setTurn, logs, setLogs, onClose } = useContext(ReplayContext)
    const round = turn.round
    const { previousTurn, nextTurn } = turn
    const lastTurn = round.replay.lastTurn

    return <div className={style()}>
        <ControlsGroup>
            <ControlsButton
                icon="cancel"
                active={true}
                onClick={onClose}
            />
            <ControlsButton
                icon="logs"
                text="View logs"
                active={true}
                onClick={() => setLogs(!logs)}
            />
        </ControlsGroup>
        <ControlsSlider
            value={round.index}
            min={0}
            max={round.replay.rounds.length - 1}
            onchange={index => setTurn(round.replay.rounds[index].setup)}
        />
        <ControlsGroup>
            <ControlsButton
                icon="play"
                reverse={true}
                active={!!previousTurn}
                onClick={() => { if (previousTurn) setTurn(previousTurn) }}
            />
            <ControlsButton
                icon="play"
                active={!!nextTurn}
                onClick={() => { if (nextTurn) setTurn(nextTurn) }}
            />
            <ControlsButton
                icon="end"
                active={!!lastTurn}
                onClick={() => { if (lastTurn) setTurn(lastTurn) }}
            />
        </ControlsGroup>
    </div>
}
export const ControlsGroup: React.FC = ({ children }) => {

    return <div className="controls_group">
        {children}
    </div>
}

export const ControlsButton: React.FC<{
    icon: IconKey
    text?: string
    active: boolean
    reverse?: boolean
    onClick?: () => void
}> = ({ icon, text, active, reverse, onClick, children }) => {

    return <div className={style.button().is({ active })} onClick={onClick}>
        <Icon icon={icon} reverse={reverse} />
        {text && <div className={style.button('text')}>
            {text}
        </div>}
    </div>
}

export const ControlsSlider: React.FC<{
    value: number
    min: number
    max: number
    onchange: (value: number) => void
}> = ({ min, max, value, onchange }) => {

    return <div className="controls_slider">
        <input
            className="controls_slider-input"
            type="range"
            min={min}
            max={max}
            step="1"
            value={value}
            onChange={e => onchange(parseInt((e.target as any).value, 10))}
        />
    </div>
}
