import {
    Character,
    CharDamaged,
    DamageNature,
    Effect,
    EffectDuration,
    EffectStatChanged,
    EffectTriggered,
    FullDamage,
    FullInjury,
    Interrupt,
} from 'game/core'
import { Memoize } from 'game/util/memoize'

export class ImmolationEffect extends Effect<{
    reverse_damage: FullDamage
    shield: number
}>{
    static KEY = 'ImmolationEffect' as const

    @Memoize()
    get duration(): EffectDuration {
        return { type: 'unlimited' }
    }

    @Memoize()
    get reverseDamage(): FullDamage {
        return this.state.reverse_damage
    }

    @Memoize()
    get shield(): number {
        return this.state.shield
    }

    // ----- Reactions ----- //
    interruptOnDamage(target: Character, damage: FullDamage, potentialInjury: FullInjury): Interrupt {
        if (target !== this.target) return false

        let result = this.battle
            .startEvent(EffectTriggered, { effect: this.path })
            .addEvent(CharDamaged, {
                character: this.target.path,
                full_hp: this.target.maxHp,
                hp: this.target.hp,
                old_hp: this.target.hp,
                injury: {
                    amount: 0, damage,
                    modifiers: [
                        ...potentialInjury.modifiers,
                        { type: 'effect', origin: this.path, before: potentialInjury.amount, after: 0 }
                    ]
                }
            })

        if (damage.nature === DamageNature.Melee) {
            result = result.perform(Character, damage.origin, char => char.takeDamage(this.reverseDamage))
        }

        const newShieldValue = this.shield - potentialInjury.amount
        return result
            .perform(ImmolationEffect, this.path, effect => {
                if (newShieldValue <= 0) return effect.finalize()
                return (effect as ImmolationEffect)
                    .update({ shield: newShieldValue })
                    .addEvent(EffectStatChanged, {
                        effect: this.path, stat: 'health',
                        previous_amount: this.shield,
                        new_amount: newShieldValue
                    })
            })
            .endEvent()
    }
}
