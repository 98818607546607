import full from 'game/extended/heros/paladin/paladin.svg'
import circle from 'game/extended/heros/paladin/paladin_circle.svg'
import head from 'game/extended/heros/paladin/paladin_head.svg'
import paladin_references from 'game/extended/heros/paladin/paladin_references'
import { ClassUI } from 'game/extended/uis/types/class_uis'
import { keysof } from 'game/util/keysof'

export const paladin: ClassUI = {
    label: 'Paladin',
    name: 'Garen',
    definition: {
        stats: {
            health: 110,
            energy: 20,
            strength: 30,
            intelligence: 30,
            agility: 30,
            armor: 15,
            shielding: 20,
            resistance: 15
        },
        masteries: {
            staff: 70,
            sword: 45
        },
        abilities: keysof(paladin_references.abilities),
        skills: keysof(paladin_references.skills),
        super_ability: 'HolyIntervention',
        super_skill: 'SelfSacrifice'
    },
    circle: {
        src: circle,
        width: 200,
        height: 150
    },
    full,
    head
}
