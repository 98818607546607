import { getClassUI } from 'game/extended/uis/types/class_uis'
import { HeroState } from 'models/user/hero/HeroModel'

const level = 1
const ui = getClassUI('ranger')

export const sample_ranger: HeroState = {
    id: 'sample_ranger',
    type: 'ranger',
    name: 'Legolas',
    level: level,
    equipment: {
        primary: 'scout_bow'
    },
    strategy: [
        {
            abilities: [
                'PrimaryWeaponAttack'
            ],
            conditions: [
            ]
        }
    ],
    stats: {
        ...ui.definition.stats,
        health: 1000,
        agility: 300
    },
    abilities: [
        { key: 'PushbackArrow', level: 1 }
    ],
    skills: [
        { key: 'TrueShots', level: 1 },
        { key: 'FrostMastery', level: 1 }
    ],
    skill_points: level,
    achievements: [],
    rewards: []
}
