import { FireballConfig } from 'game/extended/heros/mage/fireball/fireball'

export const fireball_config: Array<FireballConfig> = [
    {
        available: 1,
        cooldown: 3,
        energy: 3,
        damage: 15,
        splash: false
    },
    {
        available: 1,
        cooldown: 2,
        energy: 4,
        damage: 20,
        splash: false
    },
    {
        available: 1,
        cooldown: 2,
        energy: 4,
        damage: 20,
        splash: true
    }
]