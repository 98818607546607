import { goblins_missions } from './goblins/goblins_missions'
import { lava_missions } from './lava/lava_missions'
import { tutorial_missions } from './tutorial/tutorial_missions'
import { undead_missions } from './undead/undead_missions'

export const missions = {
    ...goblins_missions,
    ...lava_missions,
    ...undead_missions,
    ...tutorial_missions
}
