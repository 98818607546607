import { AttackRange, DamageType, ItemRarity, Weapon } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './ranger_bow.png'

export const ranger_bow: ItemUI<Weapon> = {
    image,
    label: 'Ranger bow',
    value: 80,
    item: {
        key: 'ranger_bow',
        type: 'weapon',
        rarity: ItemRarity.Rare,
        stats: {
            agility: 40,
            energy: 2
        },
        two_handed: true,
        category: 'bow',
        damageType: DamageType.Ranged,
        range: AttackRange.Ranged,
        energy: 3,
        damage: 10
    }
}
