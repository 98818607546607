import { ItemRarity, Shield } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './tower_shield.png'

export const tower_shield: ItemUI<Shield> = {
  image,
  label: 'Tower shield',
  value: 40,
  item: {
    key: 'tower_shield',
    type: 'shield',
    rarity: ItemRarity.Common,
    stats: {
      shielding: 30,
      armor: 10
    }
  }
}
