import full from 'game/extended/heros/mage/mage.svg'
import circle from 'game/extended/heros/mage/mage_circle.svg'
import head from 'game/extended/heros/mage/mage_head.svg'
import mage_references from 'game/extended/heros/mage/mage_references'
import { ClassUI } from 'game/extended/uis/types/class_uis'
import { keysof } from 'game/util/keysof'

export const mage: ClassUI = {
    label: 'Mage',
    name: 'Red beard',
    definition: {
        stats: {
            health: 80,
            energy: 30,
            strength: 15,
            intelligence: 50,
            agility: 20,
            armor: 5,
            shielding: 5,
            resistance: 20
        },
        masteries: {
            staff: 70,
            sword: 45
        },
        abilities: keysof(mage_references.abilities),
        skills: keysof(mage_references.skills),
        super_ability: 'Inferno',
        super_skill: 'Afterburn'
    },
    circle: {
        src: circle,
        width: 200,
        height: 150
    },
    full,
    head
}
