import {
    Ability,
    AbilityState,
    Battle,
    Character,
    CharacterPath,
    ChildGameModel,
    Rule,
    RuleState,
    Skill,
    SkillState,
} from 'game/core'
import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'

export interface Stats {
    health: number,
    energy: number,
    strength: number,
    intelligence: number,
    agility: number,
    armor: number,
    shielding: number,
    resistance: number
}

export type StatsKey = keyof Stats

export interface TypeState {
    kind: CoreTypeKind //eg. hero, creature
    type: CoreTypeKey //eg. paladin, wolf, shaman, etc
    level: number
    stats: Stats
    skills: Array<SkillState>
    abilities: Array<AbilityState>
    rules: Array<RuleState>
}

export type CoreTypeKind = string
export type CoreTypeKey = string

export interface TypeConfig { }

export interface TypePath extends CharacterPath { }

export class Type<S extends TypeState = TypeState, C extends TypeConfig = TypeConfig> extends ChildGameModel<Character, S> {

    static byPath(battle: Battle, path: TypePath): Nullable<Type> {
        const char = Character.byPath(battle, path)
        if (!char) return null
        return char.type
    }

    @Memoize()
    get character(): Character {
        return this.parent
    }

    @Memoize()
    get path() {
        return this.character.path
    }

    @Memoize()
    get directChildren() {
        return [...this.skills, ...this.abilities]
    }

    @Memoize()
    get baseStats(): Stats {
        return this.state.stats
    }

    @Memoize()
    get skills(): Array<Skill> {
        return this.state.skills.map(s => this.env.createModel(this, s))
    }

    @Memoize()
    get abilities(): Array<Ability> {
        return this.state.abilities.map(s => this.env.createModel(this, s))
    }

    @Memoize()
    get rules(): Array<Rule> {
        return this.state.rules.map(state => new Rule(this, state))
    }

    @Memoize()
    get level() {
        return this.state.level
    }

    @Memoize()
    get key(): CoreTypeKey {
        return this.state.type
    }

    // ----- Calculate new State ----- //
    update(newState: Partial<TypeState>): Battle {
        return this.character.updateType({
            ...this.state,
            ...newState
        })
    }

    addAbility<A extends Ability>(
        state: Omit<A['state'], 'lastUsage'>
    ) {
        return this.update({
            abilities: [
                ...this.state.abilities,
                {
                    lastUsage: false as const,
                    ...state,
                }
            ]
        })
    }

    addSkill<S extends Skill>(
        state: S['state']
    ) {
        return this.update({
            skills: [
                ...this.state.skills,
                state
            ]
        })
    }

    addRule(rule: RuleState): Battle {
        return this.update({
            rules: [
                ...this.state.rules,
                rule
            ]
        })
    }

    updateAbility(state: AbilityState, newState: Partial<AbilityState>): Battle {
        return this.character.updateType({
            ...this.state,
            abilities: this.state.abilities.map(a =>
                a !== state ? a : { ...state, ...newState }
            )
        })
    }

    updateSkill(state: SkillState, newState: Partial<SkillState>): Battle {
        return this.character.updateType({
            ...this.state,
            skills: this.state.skills.map(s =>
                s !== state ? s : { ...state, ...newState }
            )
        })
    }
}

