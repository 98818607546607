import { Headgear, ItemRarity } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './assasins_hood.png'

export const assasins_hood: ItemUI<Headgear> = {
    image,
    label: 'Assasins hood',
    value: 65,
    item: {
        key: 'assasins_hood',
        type: 'headgear',
        rarity: ItemRarity.Rare,
        stats: {
            agility: 30,
            strength: 20,
            intelligence: 20,
            energy: 4
        }
    }
}
