import 'views/ingame/user/team/equip/weaponstats.scss'

import { AttackRange, DamageType, Weapon } from 'game/core'
import React from 'react'
import { IconKey } from 'views/ui/icon'
import { StatIcon } from 'views/ui/stats/staticon'

export const Weaponstats: React.FC<{
    weapon: Weapon
}> = ({ weapon }) => {

    const icon = weapon.damageType === DamageType.Magic ?
        'magic' : (weapon.range === AttackRange.Melee ? 'melee' : 'ranged')

    return <div className="weaponstats">
        <WeaponstatsStat icon={icon} value={weapon.damage} />
        <WeaponstatsStat icon="energy" value={weapon.energy} />
    </div>
}

export const WeaponstatsStat: React.FC<{
    icon: IconKey
    value: number
}> = ({ icon, value }) => {

    return <div className="weaponstats_stat">
        <StatIcon stat={icon} />{value}
    </div>
}
