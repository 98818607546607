import { ConditionUI } from 'game/extended/uis/conditions/ConditionUI'
import React from 'react'
import { Conditionsetup, ConditionsetupSelect, ConditionsetupSlider } from 'views/ui/conditionsetup'
import { VisualSubscript } from 'views/ui/visual'
import { WhenCrowdDirection, WhenCrowdLine } from './when_crowd'
import { ReactComponent as icon } from './when_crowd.svg'


const lineOptions = {
    allied_front: 'Allied front',
    allied_support: 'Allied support',
    enemy_front: 'Enemy front',
    enemy_support: 'Enemy support'
}

const directionOptions = {
    below: ' < ',
    exactly: ' = ',
    above: ' > '
}

const lineSymbols = {
    allied_front: 'AF',
    allied_support: 'AS',
    enemy_front: 'EF',
    enemy_support: 'ES'
}

const directionSymbols = {
    below: '<',
    exactly: '=',
    above: '>'
}

export const when_crowd_ui: ConditionUI<'WhenCrowd'> = {
    icon,
    label: 'When characters on line',
    defaultState: {
        key: 'WhenCrowd',
        line: 'allied_front',
        direction: 'exactly',
        amount: 1
    },
    renderDescription: (state) =>
        <span>
            Cast this sequence when the amount of characters on the given position
            is
            {state.direction === 'below' && ' below '}
            {state.direction === 'exactly' && ' exactly '}
            {state.direction === 'above' && ' above '}
            {state.amount}.
        </span>,
    renderVisualIcon: (state) =>
        <VisualSubscript>
            {lineSymbols[state.line]}{directionSymbols[state.direction]}{state.amount}
        </VisualSubscript>,
    renderOptions: (state, update) =>
        <Conditionsetup>
            <ConditionsetupSelect<WhenCrowdLine>
                value={state.line}
                options={lineOptions}
                onchange={value => update({ ...state, line: value })}
            />
            <ConditionsetupSelect<WhenCrowdDirection>
                value={state.direction}
                options={directionOptions}
                onchange={value => update({ ...state, direction: value })}
            />
            <ConditionsetupSlider
                min={0}
                max={4}
                step={1}
                unit="&nbsp;chars"
                value={state.amount}
                onchange={value => update({ ...state, amount: value })}
            />
        </Conditionsetup>
}
