import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'
import { AchievementModel } from 'models/user/hero/achievements/Achievement'


export class GoalModel {

    constructor(private achievement: AchievementModel, public amount: number) { }

    @Memoize()
    public get index(): number {
        return this.achievement.goals.findIndex(g => g === this)
    }

    @Memoize()
    public get percentage(): number {
        if (this.isReached) return 100
        if (!this.isActive) return 0

        const prevGoal = this.previousGoal?.amount || 0
        const goalDiff = this.amount - prevGoal
        const relativeProgress = this.achievement.progress - prevGoal

        return relativeProgress / goalDiff * 100
    }

    @Memoize()
    public get nextGoal(): Nullable<GoalModel> {
        return this.achievement.goals[this.index + 1]
    }

    @Memoize()
    public get previousGoal(): Nullable<GoalModel> {
        return this.achievement.goals[this.index - 1]
    }

    @Memoize()
    public get isReached() {
        return this.achievement.progress >= this.amount
    }

    @Memoize()
    public get isActive() {
        return (!this.previousGoal || this.previousGoal.isReached) && !this.isReached
    }
}
