import { Modifier, Requirement } from 'game/core'
import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'

export class RequiredNoBlockingModifier extends Requirement {

    static KEY = 'RequiredNoBlockingModifier' as const

    @Memoize()
    get blockingModifier(): Nullable<Modifier> {
        return this.character.modifiers.find(mod => !mod.canPerform(this.ability))
    }

    @Memoize()
    get isValid() {
        return !this.blockingModifier
    }
}
