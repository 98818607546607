import React from 'react'
import { EventUI } from "game/extended/uis/events/EventUI"
import { CharFindsNoAbility } from 'game/core'

export const char_finds_no_ability_ui: EventUI<'CharFindsNoAbility'> = {
    model: CharFindsNoAbility,

    render: (event: CharFindsNoAbility, UI) => {
        return <>
            <UI.Char character={event.target} />
            {` did not find any runnable sequence. ${event.target?.isMale ? 'He' : 'She'} will remain idle.`}
        </>
    }
}
