import { Mounted, MountedConfig } from 'game/extended/creatures/goblins/abilities/mounted/mounted'
import { SkillUI } from 'game/extended/uis/skills/SkillUI'
import React from 'react'

import image from './mounted.png'
import { mounted_config } from './mounted_config'

export const mounted_ui: SkillUI<'Mounted'> = {
    model: Mounted,
    configs: mounted_config,
    image,
    label: 'Mounted',
    renderDescription: (config: MountedConfig) => <>
        When this character takes lethal damage, he will be knocked of his mounted
        and continue fighting unmounted.
    </>,
    renderTableStatic: (config: MountedConfig, UI) => <></>,
    renderTable: (skill: Mounted, UI) => <></>
}
