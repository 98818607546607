import { Armor, ItemRarity } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './iced_armor.png'

export const iced_armor: ItemUI<Armor> = {
    image,
    label: 'Iced armor',
    value: 90,
    item: {
        key: 'iced_armor',
        type: 'armor',
        rarity: ItemRarity.Epic,
        stats: {
            intelligence: 60,
            agility: -20,
            energy: -5,
            armor: 30,
            resistance: 30
        }
    }
}
