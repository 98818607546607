import 'views/layout/sidebar/abilityside.scss'

import { Ability, AbilityConfig, Environment } from 'game/core'
import { AbilityKey } from 'game/extended/types'
import { getAbilityConfigs, getAbilityUI } from 'game/extended/uis/abilities/ability_uis'
import React, { useEffect, useState } from 'react'
import { InfoCols, InfoDescription, InfoVisual } from 'views/layout/info'
import { LevelSelector } from 'views/layout/sidebar/levelselector'
import { Requirements } from 'views/layout/sidebar/requirements'
import { DefaultUI as UI, DefaultUI } from 'views/ui/default_ui'
import { StatIcon } from 'views/ui/stats/staticon'
import { AbilityVisual } from 'views/ui/visuals/abilityvisual'

export const AbilitySide: React.FC<{
    ability: Ability | AbilityKey
    env: Environment
    costs?: Array<number>
    hideLevels?: boolean
    level: number
}> = ({ ability, env, costs, hideLevels, level }) => {
    const [activeLevel, setActiveLevel] = useState(Math.max(1, level))
    useEffect(() => {
        setActiveLevel(Math.max(1, level))
    }, [level])

    const abilityKey = ability instanceof Ability ? ability.key as AbilityKey : ability
    const ui = getAbilityUI(abilityKey)
    const configs: Array<AbilityConfig> = getAbilityConfigs(abilityKey)
    if (!configs) return null

    const config = configs[activeLevel - 1]
    if (!config) return null

    const cost = costs ? costs[activeLevel - 1] : 0

    return <div className="abilityside">
        {configs.length > 1 && !hideLevels && <LevelSelector
            current={activeLevel}
            levels={configs.length}
            onchange={level => setActiveLevel(level)}
        />}
        <InfoCols>
            <InfoVisual>
                <AbilityVisual ability={abilityKey} />
            </InfoVisual>
            <InfoDescription>
                {ui && ui.renderDescription()}
            </InfoDescription>
        </InfoCols>
        {ability instanceof Ability && ui.renderTable && ui.renderTable(ability, DefaultUI)}
        {ability instanceof Ability && !ui.renderTable && ui.renderTableStatic && ui.renderTableStatic(config, DefaultUI)}
        {!(ability instanceof Ability) && ui.renderTableStatic && ui.renderTableStatic(config, DefaultUI)}
        {ability instanceof Ability && <UI.TRow>
            <UI.TKey>Available in:</UI.TKey>
            <UI.TVal>
                {ability.cooldownLeft > 0 ? <UI.Turns turns={ability.cooldownLeft} /> : 'Ready'}
            </UI.TVal>
        </UI.TRow>}
        {ability instanceof Ability && ability.cooldownLeft === 0 && <UI.TRow>
            <UI.TKey>Castable:</UI.TKey>
            <UI.TVal>
                {ability.hasFailingReq ? 'No' : 'Yes'}
            </UI.TVal>
        </UI.TRow>}
        {!(ability instanceof Ability) && <UI.TRow>
            <UI.TKey>Useable after:</UI.TKey>
            <UI.TVal>
                {config.available ? <UI.Turns turns={config.available} /> : 'Instant'}
            </UI.TVal>
        </UI.TRow>}
        <UI.TRow>
            <UI.TKey>Energy:</UI.TKey>
            <UI.TVal>
                {config.energy}
                <StatIcon mod="small" stat="energy" />
            </UI.TVal>
        </UI.TRow>
        {config.cooldown !== 'unknown' && <UI.TRow>
            <UI.TKey>Cooldown:</UI.TKey>
            <UI.TVal>
                <UI.Turns turns={config.cooldown} />
            </UI.TVal>
        </UI.TRow>}
        <UI.TRow>
            <UI.TKey>Target(s):</UI.TKey>
            <UI.TVal>
                {ui.renderTargets(config)}
            </UI.TVal>
        </UI.TRow>
        {(cost || null) && <UI.TRow>
            <UI.TKey>Upgrade cost:</UI.TKey>
            <UI.TVal>
                {cost}<StatIcon stat="xp" mod="unit" />
            </UI.TVal>
        </UI.TRow>}
        <Requirements requirements={ui.model.FIXED_REQUIREMENTS} />
    </div>
}
