import { Character, Event, Skill, SkillPath } from 'game/core'
import { Memoize } from 'game/util/memoize'
import { Nullable } from 'game/util/maybe'


export class SkillStacksAdded extends Event<{
    skill: SkillPath
    stacks_added: number
    total_stacks: number
}> {

    static KEY = 'SkillStacksAdded' as const

    @Memoize()
    get character(): Nullable<Character> {
        return Character.byPath(this.battle, this.state.skill)
    }

    @Memoize()
    get skill(): Nullable<Skill> {
        return Skill.byPath(this.battle, this.state.skill)
    }

    @Memoize()
    get stacksAdded(): number {
        return this.state.stacks_added
    }

    @Memoize()
    get stacks(): number {
        return this.state.total_stacks
    }
}
