import { CharacterPath, Event, FullInjury } from 'game/core'
import { Side } from 'game/core/side'
import { Memoize } from 'game/util/memoize'

export class CharDied extends Event<{
    character: CharacterPath,
    injury: FullInjury,
    name: string
}> {

    static KEY = 'CharDied' as const

    @Memoize()
    get side(): Side {
        return Side.byPath(this.battle, this.state.character)
    }

    @Memoize()
    get name(): string {
        return this.state.name
    }

    @Memoize()
    get injury(): FullInjury {
        return this.state.injury
    }
}
