import { Damage, Skill } from 'game/core'
import { Memoize } from 'game/util/memoize'

export type ShellConfig = {
    threshold: number
}

export class Shell extends Skill<{}, ShellConfig>{
    static KEY = 'Shell' as const

    @Memoize()
    get threshold(): number {
        return this.currentConfig.threshold
    }

    // ----- Appliers ----- //
    applyInjuryModifier(amount: number, damage: Damage): number {
        if (damage.amount < this.threshold) return 0
        return amount
    }
}
