import { AttackRange, DamageType, ItemRarity, Weapon } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './battle_axe.png'

export const battle_axe: ItemUI<Weapon> = {
  image,
  label: 'Battle axe',
  value: 75,
  item: {
    key: 'battle_axe',
    type: 'weapon',
    rarity: ItemRarity.Rare,
    stats: {
    },
    two_handed: true,
    category: 'axe',
    damageType: DamageType.Melee,
    range: AttackRange.Melee,
    energy: 5,
    damage: 15
  }
}
