import { Ability, AbilityConfig, Turns } from 'game/core'
import { SeduceEffect } from 'game/extended/creatures/undead/abilities/seduce/seduce_effect'
import { Memoize } from 'game/util/memoize'

export type SeduceConfig = AbilityConfig & {
    duration: Turns
}

export class Seduce extends Ability<SeduceConfig> {

    static KEY = 'Seduce' as const
    static FIXED_REQUIREMENTS = []

    @Memoize()
    get targets() {
        return this.character.meleeTarget || this.character.rangedTarget
    }

    @Memoize()
    get duration(): Turns {
        return this.currentConfig.duration
    }

    // ----- Calculate new State ----- //
    performActionImpl() {
        if (!this.targets) return this.battle
        return this.targets.addEffect(SeduceEffect, {
            duration: this.duration,
            origin: this.character.path
        })
    }
}
