import { CreatureUI } from 'game/extended/uis/types/creature_uis'

import circle from './turtly_circle.png'
import head from './turtly_head.png'

export const turtly: CreatureUI = {
  label: 'Turtly',
  definition: lvl => ({
    stats: {
      health: 100,
      energy: 10,
      intelligence: 20,
      strength: 20,
      agility: 20,
      armor: 55,
      shielding: 0,
      resistance: 0
    },
    strategy: [
      'Punch'
    ],
    skills: [
      'Shell'
    ]
  }),
  circle: {
    src: circle,
    width: 200,
    height: 150
  },
  head
}
