import { AbilityTalent } from 'models/user/hero/talents/AbilityTalent'
import React from 'react'
import { Info, InfoCols, InfoDescription, InfoLabel, InfoVisual } from 'views/layout/info'
import { AbilityVisual } from 'views/ui/visuals/abilityvisual'

export const Abilityinfo: React.FC<{
    talent: AbilityTalent
}> = ({ talent }) => {
    const ui = talent.ui

    return <Info>
        <InfoLabel>{ui.label}</InfoLabel>
        <InfoCols>
            <InfoVisual>
                <AbilityVisual ability={talent.key} />
            </InfoVisual>
            <InfoDescription>
                {ui.renderDescription()}
            </InfoDescription>
        </InfoCols>
    </Info>
}
