import { Ability, AbilityPath, Battle, Character, ExtendableGameModel, ExtendableGameModelState } from 'game/core'
import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'

export type CoreRequirementKey = string

export interface RequirementState extends ExtendableGameModelState {
    key: CoreRequirementKey
}

export interface RequirementPath extends AbilityPath {
    requirement: CoreRequirementKey
}

export type RequirementConstructor<M extends Requirement> = {
    KEY: string
    new(parent: Ability, state: M['state']): M
}

export abstract class Requirement<S = {}> extends ExtendableGameModel<Ability, S & RequirementState> {

    static byPath(battle: Battle, path: RequirementPath): Nullable<Requirement> {
        const ability = Ability.byPath(battle, path)
        if (!ability) return null
        return ability.requirements.find(req => req.key === path.requirement)
    }

    static init<R extends Requirement>(reqClass: RequirementConstructor<R>, state: Omit<R['state'], 'key'>): RequirementState {
        return {
            key: reqClass.KEY,
            ...state
        }
    }

    @Memoize()
    get ability(): Ability {
        return this.parent
    }

    @Memoize()
    get path(): RequirementPath {
        return { ...this.ability.path, requirement: this.key }
    }

    @Memoize()
    get character(): Character {
        return this.ability.character
    }

    @Memoize()
    get key(): CoreRequirementKey {
        return this.state.key
    }

    @Memoize()
    get directChildren() {
        return []
    }

    @Memoize()
    get battle() {
        return this.ability.battle
    }

    abstract get isValid(): boolean
}
