import { WhenTurnDirection } from 'game/extended/conditions/when_turn/when_turn'
import { ConditionUI } from 'game/extended/uis/conditions/ConditionUI'
import React from 'react'
import { Conditionsetup, ConditionsetupSelect, ConditionsetupSlider } from 'views/ui/conditionsetup'
import { VisualSubscript } from 'views/ui/visual'

import { ReactComponent as icon } from './when_turn.svg'

const options = {
    below: ' < ',
    exactly: ' = ',
    above: ' > '
}

const symbols = {
    below: '<',
    exactly: '=',
    above: '>'
}

export const when_turn_ui: ConditionUI<'WhenTurn'> = {
    icon,
    label: 'When turn',
    defaultState: {
        key: 'WhenTurn',
        direction: 'below',
        amount: 10
    },
    renderDescription: (state) =>
        <span>
            Cast this sequence when the current turn is
            {state.direction === 'below' && ' below '}
            {state.direction === 'exactly' && ' exactly '}
            {state.direction === 'above' && ' above '}
            {state.amount + 1}.
        </span>,
    renderVisualIcon: (state) =>
        <VisualSubscript>
            {symbols[state.direction]}{state.amount + 1}
        </VisualSubscript>,
    renderOptions: (state, update) =>
        <Conditionsetup>
            <ConditionsetupSelect<WhenTurnDirection>
                value={state.direction}
                options={options}
                onchange={value => update({ ...state, direction: value })}
            />
            <ConditionsetupSlider
                min={1}
                max={15}
                step={1}
                unit="&nbsp;turn"
                value={state.amount + 1}
                onchange={value => update({ ...state, amount: value - 1 })}
            />
        </Conditionsetup>
}
