import './logs.scss'

import { ReplayTurn } from 'models/replay/ReplayTurn'
import React from 'react'
import { Message } from 'views/replay/logs/message'
import { Sidebar, SidebarCollection, SidebarTitle } from 'views/layout/sidebar'
import { ErrorBoundary } from 'views/layout/errors/errorboundary'

export const Logs: React.FC<{
    turn: ReplayTurn
    open: boolean
    setOpen: (open: boolean) => void
}> = ({ turn, open, setOpen }) => {

    return <Sidebar
        hidden={!open}
        onhide={() => setOpen(false)}
    >
        <SidebarTitle>
            Logs
        </SidebarTitle>
        <SidebarCollection>
            <div className="logs-messages">
                <ErrorBoundary>
                    {turn.events.map((event, i) =>
                        <Message event={event} key={event.key + '_' + i} />
                    )}
                </ErrorBoundary>
            </div>
        </SidebarCollection>
    </Sidebar>
}
