import { Battle, Character, Event } from 'game/core'
import { Memoize } from 'game/util/memoize'
import { ReplayRound } from 'models/replay/ReplayRound'
import { TurnChar } from 'models/replay/TurnChar'
import { Nullable } from 'game/util/maybe'

export abstract class ReplayTurn {
    round: ReplayRound

    abstract get start(): Battle
    abstract get end(): Battle
    abstract get events(): Array<Event>
    abstract get key(): string
    abstract get previousTurn(): Nullable<ReplayTurn>
    abstract get nextTurn(): Nullable<ReplayTurn>

    constructor(round: ReplayRound) {
        this.round = round
    }

    @Memoize()
    get chars(): Array<TurnChar> {
        const { start, end } = this

        const startIds = new Set(start.characters.map(char => char.id))
        const endIds = new Set(end.characters.map(char => char.id))

        const normals = start.charactersSequence
            .filter(char => startIds.has(char.id) && endIds.has(char.id))
            .map(char => new TurnChar(this, {
                key: 'normal',
                start: char,
                end: end.characters.find(c => char.id === c.id) as Character
            }))

        const news = end.charactersSequence
            .filter(char => !startIds.has(char.id))
            .map(char => new TurnChar(this, {
                key: 'new',
                end: char
            }))

        const deads = start.charactersSequence
            .filter(char => !endIds.has(char.id))
            .map(char => new TurnChar(this, {
                key: 'dead',
                start: char
            }))

        return [...normals, ...news, ...deads]
    }

    @Memoize()
    get unfoldedEvents() {
        let events: Array<Event> = []
        this.events.forEach(event =>
            events = [...events, ...event.eventsFlat]
        )
        return events
    }
}