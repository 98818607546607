import { ItemRarity, Shield } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './militia_shield.png'

export const militia_shield: ItemUI<Shield> = {
  image,
  label: 'Militia shield',
  value: 25,
  item: {
    key: 'militia_shield',
    type: 'shield',
    rarity: ItemRarity.Common,
    stats: {
      armor: 15,
      shielding: 15,
      resistance: 10
    }
  }
}
