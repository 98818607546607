import { AttackRange, DamageType, ItemRarity, Weapon } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './rusty_sword.png'

export const rusty_sword: ItemUI<Weapon> = {
    image,
    label: 'Rusty sword',
    value: 10,
    item: {
        key: 'rusty_sword',
        type: 'weapon',
        rarity: ItemRarity.Common,
        stats: {
            strength: 10
        },
        two_handed: false,
        category: 'sword',
        damageType: DamageType.Melee,
        range: AttackRange.Melee,
        energy: 3,
        damage: 7
    }
}
