import 'views/layout/popup.scss'

import React, { useState } from 'react'
import { Portal } from 'react-portal'
import { Badge } from 'views/layout/badge'
import closeBtnImg from 'views/layout/popup_btn_close.png'
import { styler } from 'views/layout/util/style'
import { Link } from 'views/link'
import { Icon, IconKey } from 'views/ui/icon'
import { StatIcon } from 'views/ui/stats/staticon'
import { AnchorAttrs, Mod } from 'views/ui/ui_types'
import { TutorialHint } from 'views/ingame/campaign/tutorial'

const style = styler('popup', ['portal', 'info', 'actions', 'fullheader', 'header', 'title', 'tabs', 'tab', 'stat', 'content', 'left', 'right'])

export const Popup: React.FC<{
    onClose: () => void
    mod?: Mod<'small' | 'variable'>
}> = ({ mod, onClose, children }) => {

    return <PopupPortal onClose={onClose}>
        <div className={style().mod(mod)}>
            {children}
            <div className={style('close')} onClick={onClose}>
                <img src={closeBtnImg} className={style('close-img')} alt="close" />
            </div>
        </div>
    </PopupPortal>
}

export const PopupPortal: React.FC<{
    onClose: () => void
}> = ({ onClose, children }) => {

    return <Portal>
        <div className={style.portal()}>
            <div onClick={onClose} className={style.portal('bg')} />
            {children}
        </div>
    </Portal>
}

export const PopupFullHeader: React.FC<{
    icon?: {
        stat: IconKey,
        amount: number
    }
    mod?: Mod<'large'>
}> = ({ children, mod, icon }) => {


    return <PopupHeader>
        <span />
        <PopupTitle mod={mod}>
            {children}
        </PopupTitle>
        {icon ? <div className="popup_fullheader-stat">
            <PopupStat {...icon} />
        </div> : <span />}
    </PopupHeader>
}

export const PopupHeader: React.FC = ({ children }) => {
    return <div className={style.header()}>
        {children}
    </div>
}

export const PopupTitle: React.FC<{
    mod?: Mod<'large'>
}> = ({ mod, children }) => {
    return <div className={style.title().mod(mod)}>
        <div className={style.title('bow')} />
        <div className={style.title('label')}>{children}</div>
    </div>
}

export const PopupTabs: React.FC = ({ children }) => {
    return <div className={style.tabs()}>
        {children}
    </div>
}

export const PopupTab: React.FC<{
    icon?: IconKey,
    to?: string,
    label: string,
    badges?: number,
    active: boolean,
    hinted?: boolean
} & AnchorAttrs> = ({ icon, hinted, label, badges, to, active, ...rest }) => {

    if (!to) {
        return <a {...rest} className={style.tab().mergeProps(rest).is({ active })}>
            {icon && <img className={style.tab('icon')} src={icon} alt="" />}
            <div className={style.tab('label')}>{label}</div>
            {!!badges && <div className={style.tab('badges')}>
                <Badge amount={badges} />
            </div>}
            {hinted && !active && <TutorialHint />}
        </a>
    }

    return <Link to={to} {...rest} className={style.tab().mergeProps(rest).is({ active })}>
        {icon && <img className={style.tab('icon')} src={icon} alt="" />}
        <div className={style.tab('label')}>{label}</div>
        {!!badges && <div className={style.tab('badges')}>
            <Badge amount={badges} />
        </div>}
        {hinted && !active && <TutorialHint />}
    </Link>
}

export const PopupStat: React.FC<{
    stat: IconKey,
    amount: number
}> = ({ stat, amount }) => {

    return <div className={style.stat().mod(stat)}>
        <div className={style.stat('amount')}>
            {amount}
        </div>
        <div className={style.stat('icon')}>
            <StatIcon stat={stat} mod='unit' />
        </div>
    </div >
}

export const PopupContent: React.FC = ({ children }) => {

    return <div className={style.content()}>
        {children}
    </div>
}

export const PopupLeft: React.FC = ({ children }) => {

    return <div className={style.left()}>
        {children}
    </div>
}

export const PopupInfo: React.FC = ({ children }) => {
    const [open, setOpen] = useState(false)

    return <div className={style.info()}>
        <div
            className={style.info('icon')}
            onClick={() => setOpen(!open)}
        >
            <Icon icon={open ? 'close' : 'info'} />
        </div>
        <div className={style.info('copy').is({ open })}>
            {children}
        </div>
    </div>
}

export const PopupRight: React.FC = ({ children }) => {

    return <div className={style.right()}>
        <div className={style.right('container')}>
            {children}
        </div>
    </div>
}

export const PopupActions: React.FC = ({ children }) => {

    return <div className={style.actions()}>
        {children}
    </div>
}
