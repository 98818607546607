import { AchievementModel } from 'models/user/hero/achievements/Achievement'
import { BattleHistory } from 'models/user/hero/achievements/BattleHistory'

export class BattlesWonAchievement extends AchievementModel {

    calculateProgress(history: BattleHistory) {
        if (!history.char || !history.victorious) return 0
        return 1
    }
}
