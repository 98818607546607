import 'views/replay/board/card.scss'

import { Effect, Modifier, Skill } from 'game/core'
import { AbilityKey, EffectKey, SkillKey } from 'game/extended/types'
import { Nullable } from 'game/util/maybe'
import { TurnChar } from 'models/replay/TurnChar'
import React, { useContext } from 'react'
import { CharacterSlot } from 'views/layout/characterslot'
import { styler } from 'views/layout/util/style'
import { Hpbar, HpbarBubble } from 'views/replay/board/hpbar'
import { ReplayContext } from 'views/replay/replay'
import { Icon } from 'views/ui/icon'
import { Mod } from 'views/ui/ui_types'
import { AbilityVisual } from 'views/ui/visuals/abilityvisual'
import { EffectVisual } from 'views/ui/visuals/effectvisual'
import { SkillVisual } from 'views/ui/visuals/skillvisual'

const style = styler('card', ['modifier', 'ability'])

export const Card: React.FC<{
    char: TurnChar
    reversed: boolean
}> = ({ char, reversed }) => {
    const { setPopup } = useContext(ReplayContext)
    const abilityStart = char.abilityStart?.key
    const abilityEnd = char.abilityEnd?.key

    return <div
        className={style().is({
            reversed,
            dead: char.isDead,
            stunned: char.isStunned,
            active: char.isActive
        })}
    >
        <div className={style('highlight')} />
        <div className={style('avatar')} onClick={() => {
            setPopup({ char, tab: 'effects' })
        }}>
            <CharacterSlot mod={{ inactive: !char.isActive }} type={char.key}>
                {char.isTargeted && !char.isDead && <div className="card-avatar-icon">
                    <Icon icon="crossmark" />
                </div>}
                {char.isStunned && !char.isDead && <div className="card-avatar-icon" style={{ marginTop: '-2rem' }}>
                    <Icon icon="stunned" />
                </div>}
                {char.isDead && <div className="card-avatar-icon">
                    <Icon icon="skull" />
                </div>}
            </CharacterSlot>
            <div className="card-avatar-ability">
                <CardAbility
                    ability={(abilityStart || abilityEnd) as (AbilityKey | null)}
                />
                {/*(char.isActive || abilityStart !== abilityEnd) && <CardAbility
                    ability={abilityEnd as (AbilityKey | null)}
                    mod="end"
                />*/}
            </div>
        </div>
        <div className={style('stats')} >
            <div className={style('modifiers')} >
                <div className={style('modifiers-icons')} >
                    {char.modifiers.map(modifier =>
                        <CardModifier modifier={modifier} key={modifier.id} />
                    )}
                </div>
            </div>
            <div className={style('stats-hp')} >
                <Hpbar mod="large" value={char.isDead ? 0 : char.hp} max={char.maxHp} reversed={reversed} >
                    {char.hpDiff !== 0 && <HpbarBubble amount={char.hpDiff} />}
                </Hpbar>
            </div>
            <div className={style('stats-energy')} >
                <Hpbar mod="medium" value={char.energy} max={char.maxEnergy} reversed={reversed}>
                    {char.energyDiff !== 0 && <HpbarBubble amount={char.energyDiff} />}
                </Hpbar>
            </div>
            <div className={style('stats-name')}>{char.name}</div>
        </div>
    </div>
}

export const CardModifier: React.FC<{
    modifier: Modifier
}> = ({ modifier }) => {

    return <div className={style.modifier()} >
        <div className={style.modifier('container')} >
            {modifier instanceof Effect && <EffectVisual effect={modifier.key as EffectKey} />}
            {modifier instanceof Skill && <SkillVisual skill={modifier.key as SkillKey} />}
        </div>
    </div>
}


export const CardAbility: React.FC<{
    ability: Nullable<AbilityKey>
    mod?: Mod<'end'>
}> = ({ ability, mod }) => {

    return <div className={style.ability().mod(mod)}>
        <AbilityVisual ability={ability} />
        <Icon className="card_ability-arrow" icon="arrow_down_right" />
    </div>
}
