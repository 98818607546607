import { Character, CharacterPath, Effect, EffectPath, Event } from 'game/core'
import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'

export class TurnSkipped extends Event<{
    character: CharacterPath
    blocker: EffectPath
}> {
    static KEY = 'TurnSkipped' as const

    @Memoize()
    get character(): Nullable<Character> {
        return Character.byPath(this.battle, this.state.character)
    }

    @Memoize()
    get effect(): Nullable<Effect> {
        return Effect.byPath(this.battle, this.state.blocker)
    }
}
