import { CreatureUI } from 'game/extended/uis/types/creature_uis'

import circle from './blowfishy_circle.png'
import head from './blowfishy_head.png'

export const blowfishy: CreatureUI = {
  label: 'Blowfishy',
  definition: lvl => ({
    stats: {
      health: 30,
      energy: 10,
      intelligence: 20,
      strength: 20,
      agility: 20,
      armor: 0,
      shielding: 0,
      resistance: 0
    },
    strategy: [
      'Punch'
    ],
    skills: [
      'Explosive'
    ]
  }),
  circle: {
    src: circle,
    width: 200,
    height: 150
  },
  head
}
