import { ArcaneCircle } from 'game/extended/heros/mage/arcane_circle/arcane_circle'
import { ArcaneCurse } from 'game/extended/heros/mage/arcane_curse/arcane_curse'
import arcane_curse_references from 'game/extended/heros/mage/arcane_curse/arcane_curse_references'
import { EthernalFire } from 'game/extended/heros/mage/ethernal_fire/ethernal_fire'
import ethernal_fire_references from 'game/extended/heros/mage/ethernal_fire/ethernal_fire_references'
import { Prepared } from 'game/extended/heros/mage/prepared/prepared'
import { Afterburn } from './afterburn/afterburn'
import afterburn_references from './afterburn/afterburn_references'
import { ArcaneGrowth } from './arcane_growth/arcane_growth'
import arcane_growth_references from './arcane_growth/arcane_growth_references'
import { BurningWeapon } from './burning_weapon/burning_weapon'
import burning_weapon_references from './burning_weapon/burning_weapon_references'
import { Fireball } from './fireball/fireball'
import fireball_references from './fireball/fireball_references'
import { Immolation } from './immolation/immolation'
import immolation_references from './immolation/immolation_references'
import { Inferno } from './inferno/inferno'
import inferno_references from './inferno/inferno_references'
import { Scholar } from './scholar/scholar'
import scholar_references from './scholar/scholar_references'
import { StaffMastery } from './staff_mastery/staff_mastery'
import staff_mastery_references from './staff_mastery/staff_mastery_references'

export default {
    abilities: {
        BurningWeapon,
        Fireball,
        Immolation,
        ArcaneCurse,
        Inferno,
        ArcaneCircle
    },
    skills: {
        Afterburn,
        ArcaneGrowth,
        StaffMastery,
        Scholar,
        EthernalFire,
        Prepared
    },
    events: {
        ...burning_weapon_references.events,
        ...fireball_references.events,
        ...immolation_references.events,
        ...inferno_references.events,
        ...afterburn_references.events,
        ...arcane_growth_references.events,
        ...staff_mastery_references.events,
        ...scholar_references.events,
        ...ethernal_fire_references.events,
        ...arcane_curse_references.events
    },
    effects: {
        ...burning_weapon_references.effects,
        ...fireball_references.effects,
        ...immolation_references.effects,
        ...inferno_references.effects,
        ...afterburn_references.effects,
        ...arcane_growth_references.effects,
        ...staff_mastery_references.effects,
        ...scholar_references.effects,
        ...ethernal_fire_references.effects,
        ...arcane_curse_references.effects
    },
    requirements: {
        ...burning_weapon_references.requirements,
        ...fireball_references.requirements,
        ...immolation_references.requirements,
        ...inferno_references.requirements,
        ...afterburn_references.requirements,
        ...arcane_growth_references.requirements,
        ...staff_mastery_references.requirements,
        ...scholar_references.requirements,
        ...ethernal_fire_references.requirements,
        ...arcane_curse_references.requirements
    }
}