import { Boots, ItemRarity } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './shaman_boots.png'

export const shaman_boots: ItemUI<Boots> = {
    image,
    label: 'Shaman boots',
    value: 80,
    item: {
        key: 'shaman_boots',
        type: 'boots',
        rarity: ItemRarity.Epic,
        stats: {
            intelligence: 35,
            energy: 5,
            resistance: 10
        }
    }
}
