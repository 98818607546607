import { VolleyConfig } from 'game/extended/heros/ranger/volley/volley'

export const volley_config: Array<VolleyConfig> = [
    {
        available: 2,
        cooldown: 4,
        energy: 5
    },
    {
        available: 1,
        cooldown: 4,
        energy: 4
    },
    {
        available: 0,
        cooldown: 3,
        energy: 4
    }
]