import { Bloodthurst, BloodthurstConfig } from 'game/extended/heros/barbarian/bloodthurst/bloodthurst'
import { SkillUI } from 'game/extended/uis/skills/SkillUI'
import { bloodthurst_config } from './bloodthurst_config'
import React from 'react'

import image from './bloodthurst.png'

export const bloodthurst_ui: SkillUI<'Bloodthurst'> = {
    model: Bloodthurst,
    configs: bloodthurst_config,
    image,
    label: 'Bloodthurst',
    renderDescription: () => <span>
        Whenever you deal damage, regenerate a percentage of that damage dealt as hitpoints.
    </span>,
    renderTableStatic: (config: BloodthurstConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Regeneration</UI.TKey>
            <UI.TVal>{config.regeneration}%</UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (skill: Bloodthurst, UI) => <>
        <UI.TRow>
            <UI.TKey>Regeneration</UI.TKey>
            <UI.TVal>{skill.regeneration}%</UI.TVal>
        </UI.TRow>
    </>
}
