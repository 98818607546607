import { MagicInterruptConfig } from 'game/extended/creatures/goblins/abilities/magic_interrupt/magic_interrupt'
export const magic_interrupt_config: Array<MagicInterruptConfig> = [
    {
        available: 2,
        cooldown: 5,
        duration: 1,
        energy: 5
    },
    {
        available: 2,
        cooldown: 5,
        duration: 2,
        energy: 5
    }
]