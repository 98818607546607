import { Ability, AbilityConfig, Turns } from 'game/core'
import { UnholyCurseEffect } from 'game/extended/creatures/undead/abilities/unholy_curse/unholy_curse_effect'
import { Memoize } from 'game/util/memoize'

export type UnholyCurseConfig = AbilityConfig & {
    duration: Turns
}

export class UnholyCurse extends Ability<UnholyCurseConfig> {

    static KEY = 'UnholyCurse' as const
    static FIXED_REQUIREMENTS = []

    @Memoize()
    get targets() {
        const target = this.character.rangedTarget 
        if (!target) return null
        if (target.effects.find(e => e instanceof UnholyCurseEffect)) return null
        return this.character.rangedTarget
    }

    @Memoize()
    get duration(): Turns {
        return this.currentConfig.duration
    }

    // ----- Calculate new State ----- //
    performActionImpl() {
        if (!this.targets) return this.battle
        return this.targets.addEffect(UnholyCurseEffect, {
            origin: this.character.path,
            ability: this.path,
            duration: this.duration
        })
    }
}
