import { HeroModel } from 'models/user/hero/HeroModel'
import { UserModel } from 'models/user/UserModel'
import { Memoize } from 'game/util/memoize'

export interface TalentState {
    level: number
}

export abstract class TalentModel<S extends TalentState>  {

    constructor(readonly hero: HeroModel, readonly state: S) { }

    @Memoize()
    get user(): UserModel {
        return this.hero.user
    }

    @Memoize()
    get level(): number {
        return this.state.level
    }

    @Memoize()
    get hasMultipleLevels(): boolean {
        return this.maxLevel > 1
    }

    abstract get isExpert(): boolean
    abstract get maxLevel(): number

    @Memoize()
    get costs(): Array<number> {
        if (this.isExpert) return [3, 4]
        return [1, 2, 3]
    }

    @Memoize()
    get isMaxLevel(): boolean {
        return this.level >= this.maxLevel
    }

    @Memoize()
    get cost(): number {
        return this.costs[this.level] || 0
    }

    @Memoize()
    get canUpgrade(): boolean {
        if (this.hero.skillPoints < this.cost) return false
        if (this.level >= this.maxLevel) return false
        return true
    }
}
