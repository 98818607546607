import { Headgear, ItemRarity } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './destroyer_helmet.png'

export const destroyer_helmet: ItemUI<Headgear> = {
    image,
    label: 'Destroyer helmet',
    value: 110,
    item: {
        key: 'destroyer_helmet',
        type: 'headgear',
        rarity: ItemRarity.Common,
        stats: {
            strength: 40,
            health: 30,
            armor: 20,
            shielding: 20,
            resistance: 20
        }
    }
}
