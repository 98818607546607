import { PenetratingBlows, PenetratingBlowsConfig } from 'game/extended/heros/paladin/penetrating_blows/penetrating_blows'
import { SkillUI } from 'game/extended/uis/skills/SkillUI'
import React from 'react'

import image from './penetrating_blows.png'
import { penetrating_blows_config } from 'game/extended/heros/paladin/penetrating_blows/penetrating_blows_config'

export const penetrating_blows_ui: SkillUI<'PenetratingBlows'> = {
    model: PenetratingBlows,
    configs: penetrating_blows_config,
    image,
    label: 'Penetrating blows',
    renderDescription: (config: PenetratingBlowsConfig) => <span>
        Whenever your perform a melee attack the armor/resistance of the target
        is reduced. If the target was already affected, it is interrupted instead.
    </span>,
    renderTableStatic: (config: PenetratingBlowsConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Armor:</UI.TKey>
            <UI.TVal><UI.Stat stat='armor' amount={config.reduction} /></UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Resistance:</UI.TKey>
            <UI.TVal><UI.Stat stat='resistance' amount={config.reduction} /></UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Duration:</UI.TKey>
            <UI.TVal><UI.Turns turns={config.duration} /></UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (skill: PenetratingBlows, UI) => <>
        <UI.TRow>
            <UI.TKey>Armor:</UI.TKey>
            <UI.TVal><UI.Stat stat='armor' amount={skill.reduction} /></UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Resistance:</UI.TKey>
            <UI.TVal><UI.Stat stat='resistance' amount={skill.reduction} /></UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Duration:</UI.TKey>
            <UI.TVal><UI.Turns turns={skill.duration} /></UI.TVal>
        </UI.TRow>
    </>
}
