import { AttackRange, DamageType, ItemRarity, Weapon } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './hermit_staff.png'

export const elder_staff: ItemUI<Weapon> = {
    image,
    label: 'Elder staff',
    value: 180,
    item: {
        key: 'elder_staff',
        type: 'weapon',
        rarity: ItemRarity.Epic,
        stats: {
            health: 50,
            armor: 20,
            shielding: 20,
            resistance: 20
        },
        two_handed: true,
        category: 'staff',
        damageType: DamageType.Magic,
        range: AttackRange.Melee,
        energy: 4,
        damage: 14
    }
}
