import { Character, CoreEffectKey, Effect, EffectPath, Event } from 'game/core'
import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'

export class EffectFinalized extends Event<{
    effectKey: CoreEffectKey
    effect: EffectPath
}> {

    static KEY = 'EffectFinalized' as const

    @Memoize()
    get character(): Nullable<Character> {
        return Character.byPath(this.battle, this.state.effect)
    }

    @Memoize()
    get effect(): Nullable<Effect> {
        return Effect.byPath(this.battle, this.state.effect)
    }

    @Memoize()
    get effectKey(): CoreEffectKey {
        return this.state.effectKey
    }
}
