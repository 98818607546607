import { RuleState } from 'game/core'
import { AbilityKey } from 'game/extended/types'
import { Memoize } from 'game/util/memoize'
import { HeroModel } from 'models/user/hero/HeroModel'
import { AbilitySlot, AbilitySlotState } from 'models/user/hero/strategy/AbilitySlot'
import { ConditionSlot, ConditionSlotState } from 'models/user/hero/strategy/ConditionSlot'
import { UserModel } from 'models/user/UserModel'

export type StrategyLineState = {
    abilities: Array<AbilitySlotState | null>,
    conditions: Array<ConditionSlotState | null>
}

export class StrategyLine {

    constructor(readonly hero: HeroModel, readonly state: StrategyLineState) {
        this.state = {
            ...state,
            abilities: [
                ...state.abilities.slice(0, 5),
                ...(new Array(Math.max(0, 5 - state.abilities.length)).fill(null))
            ],
            conditions: [
                ...state.conditions.slice(0, 2),
                ...(new Array(Math.max(0, 2 - state.conditions.length)).fill(null))
            ]
        }
    }

    @Memoize()
    get user(): UserModel {
        return this.hero.user
    }

    @Memoize()
    get index(): number {
        return this.hero.strategyLines.findIndex(line => line === this)
    }

    @Memoize()
    get rule(): RuleState {
        return {
            sequence: this.abilities
                .filter(slot => slot.isFilled && slot.isUseable)
                .map(slot => slot.state as AbilityKey),
            conditions: this.conditions
                .filter(slot => slot.isFilled)
                .map(slot => {
                    if (!slot.state) throw new Error("")
                    return {
                        ...slot.state,
                        key: slot.state.key
                    }
                })
        }
    }

    @Memoize()
    get abilities(): Array<AbilitySlot> {
        return this.state.abilities.map(state => new AbilitySlot(this, state))
    }

    @Memoize()
    get conditions(): Array<ConditionSlot> {
        return this.state.conditions.map(state => new ConditionSlot(this, state))
    }

    @Memoize()
    get slots(): Array<AbilitySlot | ConditionSlot> {
        return [...this.abilities, ...this.conditions]
    }

    // ----- Calculate new State ----- //
    update(newState: Partial<StrategyLineState>): UserModel {
        return this.hero.update({
            strategy: this.hero.strategyLines.map(strat => {
                if (strat === this) return { ...this.state, ...newState }
                return strat.state
            })
        })
    }

    moveUp(): UserModel {
        const newLines = this.hero.strategyLines.slice()
        newLines.splice(this.index, 1)
        newLines.splice(this.index - 1, 0, this)

        return this.hero.update({
            strategy: newLines.map(strat => strat.state)
        })
    }
}
