import { frost_mastery_config } from 'game/extended/heros/ranger/frost_mastery/frost_master_config'
import { FrostMastery, FrostMasteryConfig } from 'game/extended/heros/ranger/frost_mastery/frost_mastery'
import { SkillUI } from 'game/extended/uis/skills/SkillUI'
import React from 'react'

import image from './frost_mastery.png'

export const frost_mastery_ui: SkillUI<'FrostMastery'> = {
    model: FrostMastery,
    configs: frost_mastery_config,
    image,
    label: 'Frost mastery',
    renderDescription: (config: FrostMasteryConfig) => <>
        After a fixed number of abilities cast, the next one will additionaly stun
        all targets.
    </>,
    renderTableStatic: (config: FrostMasteryConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Frequency:</UI.TKey>
            <UI.TVal>{config.frequency}</UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (skill: FrostMastery, UI) => <>
        <UI.TRow>
            <UI.TKey>Frequency:</UI.TKey>
            <UI.TVal>{skill.frequency}</UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Next stun in:</UI.TKey>
            <UI.TVal>{skill.abilitiesUntilFreeze}</UI.TVal>
        </UI.TRow>
    </>
}
