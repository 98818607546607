import 'views/replay/board/hpbar.scss'
import React from 'react'
import {styler} from 'views/layout/util/style'
import {DOMAttrs} from 'views/ui/ui_types'

const style = styler('hpbar', ['bubble', 'shape', 'left', 'right'])

export const Hpbar: React.FC<{
    value: number
    max: number
    mod: 'large' | 'medium'
    reversed: boolean
    bubble?: {
        amount: number,
        icon: string
    }
}> = attrs => {
    const {value, max, mod, reversed, children} = attrs

    return <div className={style().is({reversed}).mod(mod)} >
        <HpbarShape color='white' stroke="gray" />
        <div className={style('value')} >
            <HpbarShape color='gray' />
            <HpbarShape color='red' style={{width: `${(value/max)*100}%`}} />
            <div className={style('value-amount')}>
                {value}/{max}
            </div>
        </div>
        {children}
    </div>
}

export const HpbarBubble: React.FC<{
    amount: number
}> = ({amount}) =>  {

    return <div className={style.bubble()} >
        <div className={style.bubble('content')} >
            {amount >= 0 ? '+':'-'} {Math.abs(amount)}
        </div>
    </div>
}

export const HpbarShape: React.FC<{
    color: 'white' | 'gray' | 'red' | 'yellow',
    stroke?: 'gray'
} & DOMAttrs> = ({color, stroke, ...rest}) => {

    const modColor = color && `color-${color}`
    const modStroke = stroke && `stroke-${stroke}`

    return <div
        {...rest}
        className={style.shape().mergeProps(rest).mod(modColor).mod(modStroke)}
    >
        <HpbarLeft />
        <div className={style.shape('bar')} />
        <HpbarRight />
    </div>
}

export const HpbarLeft: React.FC = () => {
    return <svg
        className="hpbar_left"
        version="1.1" x="0px" y="0px" width="20px" height="40px"
        viewBox="0 0 20 40"
    >
        <path
            className="hpbar_left-path"
            d="M20,40H9.2c-6.6,0-10.6-5.4-8.8-12l4.3-16C6.5,5.4,13.3,0,19.9,0H20"
        />
    </svg>
}

export const HpbarRight: React.FC = () => {
    return <svg
        className="hpbar_right"
        x="0px" y="0px" width="20px" height="40px"
        viewBox="-155.9 400.9 20 40"
    >
        <path
            className="hpbar_right-path"
            d="M-155.9,400.9h10.7c6.6,0,10.6,5.4,8.8,12l-4.3,16c-1.8,6.6-8.6,12-15.2,12h0"
        />
    </svg>
}
