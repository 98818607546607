import { FrostMasteryConfig } from 'game/extended/heros/ranger/frost_mastery/frost_mastery'

export const frost_mastery_config: Array<FrostMasteryConfig> = [
    {
        frequency: 7
    },
    {
        frequency: 6
    },
    {
        frequency: 5
    }
]