import { Ability, AbilityConfig, Battle, Character, Percentage } from 'game/core'
import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'

export type IntimidateConfig = AbilityConfig & {
    minimum_hp: Percentage
}

export class Intimidate extends Ability<IntimidateConfig> {
    static KEY = 'Intimidate' as const
    static FIXED_REQUIREMENTS = []

    @Memoize()
    get targets(): Nullable<Character> {
        const potentialTargets = this.character.side.enemySide.characters
            .filter(char => char.hpRatio * 100 <= this.minimumHp)
        const minRatio = Math.min(...potentialTargets.map(char => char.hpRatio))
        return potentialTargets.find(char => char.hpRatio === minRatio)
    }

    @Memoize()
    get minimumHp(): Percentage {
        return this.currentConfig.minimum_hp
    }

    // ----- Calculate new State ----- //
    performActionImpl(): Battle {
        if (!this.targets) return this.battle
        return this.targets.stun(1, this.path)
    }
}