import { DamageType } from 'game/core'
import { SkillUI } from 'game/extended/uis/skills/SkillUI'
import React from 'react'

import { Afterburn, AfterburnConfig } from './afterburn'
import image from './afterburn.png'

export const afterburn_ui: SkillUI<'Afterburn'> = {
    model: Afterburn,
    configs: [
        {
            damage: 2,
            vulnerability: 15,
            turns: 2
        },
        {
            damage: 4,
            vulnerability: 30,
            turns: 2
        }
    ],
    label: 'Afterburn',
    image,
    renderDescription: (config: AfterburnConfig) => <>
        Any magic damage you deal will cause an effect on the target.
        For a fixed amount of turns, the target will take damage every time he/she performs an ability.
        Additionaly, the target has decreased magic resistance while the effect is active.
    </>,
    renderTableStatic: (config: AfterburnConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Damage: </UI.TKey>
            <UI.TVal><UI.Damage type={DamageType.Magic} damage={config.damage} /></UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Vulnerability: </UI.TKey>
            <UI.TVal><UI.Stat stat='resistance' amount={config.vulnerability} /></UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Turns: </UI.TKey>
            <UI.TVal>{config.turns}</UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (skill: Afterburn, UI) => <>
        <UI.TRow>
            <UI.TKey>Damage: </UI.TKey>
            <UI.TVal><UI.Damage damage={skill.damage} /></UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Vulnerability: </UI.TKey>
            <UI.TVal><UI.Stat stat='resistance' amount={skill.vulnerability} /></UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Turns: </UI.TKey>
s            <UI.TVal>{skill.turns}</UI.TVal>
        </UI.TRow>
    </>,
}
