import { CreatureUI } from 'game/extended/uis/types/creature_uis'

import circle from './skeleton_wizard_circle.png'
import head from './skeleton_wizard_head.png'

export const skeleton_wizard: CreatureUI = {
  label: 'Skeleton wizard',
  definition: lvl => ({
    stats: {
      health: 70,
      energy: 25,
      intelligence: 100,
      strength: 20,
      agility: 20,
      armor: 0,
      shielding: 30,
      resistance: 40
    },
    equipment:
    {
      primary: 'unholy_wand',
      headgear: 'skeleton_wizard_hood',
      armor: 'skeleton_wizard_robe'
    },
    skills: [
      'Undead'
    ],
    strategy: [
      'Lifedrain',
      'UnholyCurse',
      'MoveSupport',
      'PrimaryWeaponAttack'
    ]
  }),
  circle: {
    src: circle,
    width: 200,
    height: 150
  },
  head
}
