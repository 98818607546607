import 'views/layout/sidebar.scss'

import React, { HTMLAttributes } from 'react'
import { Portal } from 'react-portal'
import { Icon } from 'views/ui/icon'
import { styler } from 'views/layout/util/style'
import { TutorialHint } from 'views/ingame/campaign/tutorial'

const style = styler('sidebar', ['title', 'preview', 'content', 'collection', 'item', 'icon'])

export const Sidebar: React.FC<{
    hidden: boolean
    onhide: () => void
}> = ({ hidden, onhide, children }) => {

    return <Portal>
        <div className={style().is({ hidden })} >
            <div className={style('arrow')} onClick={onhide}>
                <Icon icon="arrow_left" />
            </div>
            {children}
        </div>
    </Portal>
}

export const SidebarTitle: React.FC = ({ children }) => {
    return <div className={style.title()}>
        {children}
    </div>
}

export const SidebarPreview: React.FC<{
    onclose: () => void
    hintedClose?: boolean
}> = ({ hintedClose, children, onclose }) => {

    return <div className={style.preview()} >
        <div className={style.preview('content')} >
            <div className={style.preview('close')} onClick={onclose}>
                <Icon icon="close" />
                {hintedClose && <TutorialHint />}
            </div>
            {children}
        </div>
    </div>
}

export const SidebarContent: React.FC = ({ children }) => {
    return <div className={style.content()} >
        {children}
    </div>
}


export const SidebarCollection: React.FC = ({ children }) => {
    return <div className={style.collection()} >
        {children}
    </div>
}

export const SidebarItem: React.FC<{
    highlighted?: boolean
}> = ({ children, highlighted }) => {

    return <div className={style.item().is({ highlighted })} >
        {children}
    </div>
}

export const SidebarControls: React.FC = ({ children }) => {
    return <div className="sidebar_controls">
        {children}
    </div>
}

export const SidebarIcon: React.FC<{
    active: boolean
} & HTMLAttributes<HTMLDivElement>> = ({ children, active, ...rest }) => {
    return <div {...rest} className={style.icon().is({ active }).mergeProps(rest)} >
        {children}
    </div>
}
