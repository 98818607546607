import { AbilityPerformed, Battle, Character, Event, Interrupt, Skill, SkillConfig, SkillTriggered } from 'game/core'
import { Memoize } from 'game/util/memoize'

export interface FrostMasteryConfig extends SkillConfig {
    frequency: number
}

export interface FrostMasteryState {
    abilities_cast?: number
}

export class FrostMastery extends Skill<FrostMasteryState, FrostMasteryConfig>{

    static KEY = 'FrostMastery' as const

    @Memoize()
    get abilitiesCast(): number {
        return this.state.abilities_cast || 0
    }

    @Memoize()
    get frequency(): number {
        return this.currentConfig.frequency
    }

    @Memoize()
    get abilitiesUntilFreeze(): number {
        return this.frequency - this.abilitiesCast - 1
    }

    @Memoize()
    get performOnNextAbility(): Boolean {
        return this.abilitiesCast + 1 >= this.currentConfig.frequency
    }

    // ----- Interrupts ----- //
    reactToEvent(event: Event): Interrupt {
        if (!(event instanceof AbilityPerformed)) return false
        if (event.character !== this.character) return false
        if (!this.performOnNextAbility) {
            return this.addAbilityCast()
        }

        const targets = event.targets.filter(target => target.side !== this.character.side)
        if (!targets.length) return false

        return this
            .clearAbilitiesCast()
            .startEvent(SkillTriggered, { skill: this.path })
            .performAll(
                Character,
                targets.map(t => t.path),
                (char: Character) => char.stun(1, this.path)
            )
            .endEvent()
    }

    // ----- Calculate new State ----- //
    addAbilityCast(): Battle {
        return this.update({
            abilities_cast: this.abilitiesCast + 1,
        })
    }

    clearAbilitiesCast(): Battle {
        return this.update({
            abilities_cast: 0,
        })
    }
}