import { Damage, Skill, SkillConfig, DamageType, Character, Percentage } from 'game/core'
import { Memoize } from 'game/util/memoize'

export interface ArcedShotsConfig extends SkillConfig {
    increase: Percentage
}

export class ArcedShots extends Skill<{}, ArcedShotsConfig>{

    static KEY = 'ArcedShots' as const

    @Memoize()
    get damageIncrease(): Percentage {
        return this.currentConfig.increase
    }

    // ----- Appliers ----- //
    applyOnTargetDamageModifier(damage: Damage, target: Character): Damage {
        if (damage.type !== DamageType.Ranged) return damage
        if (target.line.position !== 'support') return damage
        return {
            ...damage,
            amount: Math.floor(damage.amount * (100 + this.damageIncrease) / 100)
        }
    }
}
