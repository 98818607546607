import {
    Ability,
    AbilityConfig,
    Battle,
    Character,
    DamageNature,
    FullDamage,
    RequiredWeaponState,
    Requirement,
} from 'game/core'
import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'

export type DualSwingConfig = AbilityConfig

export class DualSwing extends Ability<DualSwingConfig> {

    static KEY = 'DualSwing' as const
    static FIXED_REQUIREMENTS = [
        Requirement.init(RequiredWeaponState, { type: 'double_melee' })
    ]

    @Memoize()
    get targets(): Nullable<Character> {
        return this.character.meleeTarget
    }

    @Memoize()
    get damage1(): FullDamage {
        if (!this.character.primaryWeapon) return this.nullDamage
        return this.calculateDamage(
            this.character.primaryWeapon.damageType,
            DamageNature.Melee,
            this.character.primaryWeapon.damage
        )
    }

    @Memoize()
    get damage2(): FullDamage {
        if (!this.character.secondaryWeapon) return this.nullDamage
        return this.calculateDamage(
            this.character.secondaryWeapon.damageType,
            DamageNature.Melee,
            this.character.secondaryWeapon.damage
        )
    }

    // ----- Calculate new State ----- //
    performActionImpl(): Battle {
        if (!this.targets || !this.character.primaryWeapon || !this.character.secondaryWeapon) {
            return this.battle
        }

        return this.battle
            .perform(Character, this.targets.path, (char: Character) => char.takeDamage(this.damage1))
            .perform(Character, this.targets.path, (char: Character) => char.takeDamage(this.damage2))
    }
}
