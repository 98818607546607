import { AttackRange, DamageType, ItemRarity, Weapon } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './vampire_sword.png'

export const vampire_sword: ItemUI<Weapon> = {
  image,
  label: 'Vampire sword',
  value: 100,
  item: {
    key: 'vampire_sword',
    type: 'weapon',
    rarity: ItemRarity.Epic,
    stats: {
      intelligence: 50
    },
    category: 'sword',
    two_handed: true,
    damageType: DamageType.Melee,
    range: AttackRange.Melee,
    energy: 5,
    damage: 15
  }
}
