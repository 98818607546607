import { BodyCheck } from 'game/extended/creatures/goblins/abilities/body_check/body_check'
import body_check_references from 'game/extended/creatures/goblins/abilities/body_check/body_check_references'
import { Burp } from 'game/extended/creatures/goblins/abilities/burp/burp'
import burp_references from 'game/extended/creatures/goblins/abilities/burp/burp_references'
import { ChainLightning } from 'game/extended/creatures/goblins/abilities/chain_lightning/chain_lightning'
import chain_lightning_references from 'game/extended/creatures/goblins/abilities/chain_lightning/chain_lightning_references'
import { Consume } from 'game/extended/creatures/goblins/abilities/consume/consume'
import consume_references from 'game/extended/creatures/goblins/abilities/consume/consume_references'
import { CursedWeapons } from 'game/extended/creatures/goblins/abilities/cursed_weapons/cursed_weapons'
import cursed_weapons_references from 'game/extended/creatures/goblins/abilities/cursed_weapons/cursed_weapons_references'
import { Inspire } from 'game/extended/creatures/goblins/abilities/inspire/inspire'
import inspire_references from 'game/extended/creatures/goblins/abilities/inspire/inspire_references'
import { Intimidate } from 'game/extended/creatures/goblins/abilities/intimidate/intimidate'
import intimidate_references from 'game/extended/creatures/goblins/abilities/intimidate/intimidate_references'
import { MagicInterrupt } from 'game/extended/creatures/goblins/abilities/magic_interrupt/magic_interrupt'
import magic_interrupt_references from 'game/extended/creatures/goblins/abilities/magic_interrupt/magic_interrupt_references'
import { Mounted } from 'game/extended/creatures/goblins/abilities/mounted/mounted'
import mounted_references from 'game/extended/creatures/goblins/abilities/mounted/mounted_references'
import { Toss } from 'game/extended/creatures/goblins/abilities/toss/toss'
import { goblin_brute } from 'game/extended/creatures/goblins/goblin_brute/goblin_brute'

import { BombToss } from './abilities/bomb_toss/bomb_toss'
import bomb_toss_references from './abilities/bomb_toss/bomb_toss_references'
import { Brutal } from './abilities/brutal/brutal'
import { ChainHeal } from './abilities/chain_heal/chain_heal'
import chain_heal_references from './abilities/chain_heal/chain_heal_references'
import { ChargeBomb } from './abilities/charge_bomb/charge_bomb'
import charge_bomb_references from './abilities/charge_bomb/charge_bomb_references'
import { Reincarnation } from './abilities/reincarnation/reincarnation'
import reincarnation_references from './abilities/reincarnation/reincarnation_references'
import { goblin_axeman } from './goblin_axeman/goblin_axeman'
import { goblin_big_momma } from './goblin_big_momma/goblin_big_momma'
import { goblin_bomber } from './goblin_bomber/goblin_bomber'
import { goblin_chief } from './goblin_chief/goblin_chief'
import { goblin_chief_mounted } from './goblin_chief/goblin_chief_mounted'
import { goblin_shaman } from './goblin_shaman/goblin_shaman'
import { goblin_swordman } from './goblin_swordman/goblin_swordman'

export default {
    types: {
        goblin_axeman,
        goblin_chief,
        goblin_chief_mounted,
        goblin_bomber,
        goblin_swordman,
        goblin_shaman,
        goblin_big_momma,
        goblin_brute
    },
    abilities: {
        ChargeBomb,
        BombToss,
        ChainHeal,
        Inspire,
        Intimidate,
        BodyCheck,
        Consume,
        Burp,
        MagicInterrupt,
        CursedWeapons,
        ChainLightning,
        Toss
    },
    skills: {
        Reincarnation,
        Mounted,
        Brutal
    },
    events: {
        ...charge_bomb_references.events,
        ...bomb_toss_references.events,
        ...chain_heal_references.events,
        ...reincarnation_references.events,
        ...inspire_references.events,
        ...mounted_references.events,
        ...intimidate_references.events,
        ...body_check_references.events,
        ...consume_references.events,
        ...burp_references.events,
        ...magic_interrupt_references.events,
        ...cursed_weapons_references.events,
        ...chain_lightning_references.events
    },
    effects: {
        ...charge_bomb_references.effects,
        ...bomb_toss_references.effects,
        ...chain_heal_references.effects,
        ...reincarnation_references.effects,
        ...inspire_references.effects,
        ...mounted_references.effects,
        ...intimidate_references.effects,
        ...body_check_references.effects,
        ...consume_references.effects,
        ...burp_references.effects,
        ...magic_interrupt_references.effects,
        ...cursed_weapons_references.effects,
        ...chain_lightning_references.effects
    }
}
