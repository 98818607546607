import { AchievementConfig, AchievementKey } from 'models/user/hero/achievements/Achievement'

export const general_achievements: Partial<Record<AchievementKey, AchievementConfig>> = {
    BattlesWonAchievement: {
        goals: [2, 6, 10, 15, 20],
        reward: {
            intelligence: 10,
            agility: 10,
            strength: 10,
            energy: 1
        }
    },
    KillsAchievement: {
        goals: [1, 3, 5, 10, 20, 50],
        reward: {
            health: 5,
            agility: 5,
            strength: 10
        }
    },
    AssistsAchievement: {
        goals: [2, 5, 10, 25, 50],
        reward: {
            health: 5,
            agility: 5,
            intelligence: 10,
        }
    },
    HealingAchievement: {
        goals: [100, 500, 2000, 5000],
        reward: {
            intelligence: 10
        }
    },
    MeleeDamageAchievement: {
        goals: [100, 300, 1000, 2500, 5000],
        reward: {
            strength: 15
        }
    },
    RangedDamageAchievement: {
        goals: [100, 300, 1000, 2500, 5000],
        reward: {
            agility: 15
        }
    },
    MagicDamageAchievement: {
        goals: [100, 300, 1000, 2500, 5000],
        reward: {
            intelligence: 15
        }
    },
    MeleeDamageReceivedAchievement: {
        goals: [100, 500, 2000, 5000],
        reward: {
            armor: 6
        }
    },
    RangedDamageReceivedAchievement: {
        goals: [100, 500, 2000, 5000],
        reward: {
            shielding: 6
        }
    },
    MagicDamageReceivedAchievement: {
        goals: [100, 500, 2000, 5000],
        reward: {
            resistance: 6
        }
    },
    ComboKillAchievement: {
        goals: [2, 3, 4],
        reward: {
            strength: 10,
            intelligence: 10,
            agility: 10,
            energy: 1
        }
    },
    OneShotAchievement: {
        goals: [1, 10],
        reward: {
            agility: 10,
            energy: 2
        }
    }
}
