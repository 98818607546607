import { PushbackArrowConfig } from 'game/extended/heros/ranger/pushback_arrow/pushback_arrow'

export const pushback_arrow_config: Array<PushbackArrowConfig> = [
    {
        available: 2,
        cooldown: 5,
        energy: 4,
        damage: 10
    },
    {
        available: 1,
        cooldown: 4,
        energy: 4,
        damage: 15
    },
    {
        available: 1,
        cooldown: 3,
        energy: 4,
        damage: 25
    }
]