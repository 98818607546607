import { Headgear, ItemRarity } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './pathfinder_hood.png'

export const pathfinder_hood: ItemUI<Headgear> = {
    image,
    label: 'Pathfinder hood',
    value: 115,
    item: {
        key: 'pathfinder_hood',
        type: 'headgear',
        rarity: ItemRarity.Epic,
        stats: {
            agility: 50,
            strength: 30,
            health: 50,
            energy: 5
        }
    }
}
