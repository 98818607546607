import { BattleError } from 'game/core/errors/BattleError'
import React from 'react'
import { styler } from 'views/layout/util/style'

const style = styler('errorprint', ['msg', 'children'])

export const ErrorPrint: React.FC<{
    error: BattleError | Error
}> = ({ error }) => {

    if (!(error instanceof BattleError)) {
        return <div className={style()} >
            <h1>An error occured</h1>
            <h2>{error.name} {error.message}</h2>
            {error.stack}
        </div>
    }

    const type = error.type
    switch (type.key) {
        case 'setup':
            return <div className={style()} >
                <h1>An error occured</h1>
                <h2>{error.error.name} {error.error.message}</h2>
                Type: Round Setup<br />
                Round: {error.round}<br />
                {error.error.stack}
            </div>

        case 'round':
            return <div className={style()} >
                <h1>An error occured</h1>
                <h2>{error.error.name} {error.error.message}</h2>
                Type: Round<br />
                Round: {error.round}<br />
                Completed: {type.completed.map(ch => ch.character).join(', ')}<br />
                Active: {type.active?.character}<br />
                {error.error.stack}
            </div>
    }
}