import { Ambusher, AmbusherConfig } from 'game/extended/heros/ranger/ambusher/ambusher'
import { ambusher_config } from 'game/extended/heros/ranger/ambusher/ambusher_config'
import { SkillUI } from 'game/extended/uis/skills/SkillUI'
import React from 'react'

import image from './ambusher.png'

export const ambusher_ui: SkillUI<'Ambusher'> = {
    model: Ambusher,
    configs: ambusher_config,
    image,
    label: 'Ambusher',
    renderDescription: (config: AmbusherConfig) => <>
        Periodically gain stacks. Each stack increases agility.
        Whenever you take damage all stacks are cleared.
    </>,
    renderTableStatic: (config: AmbusherConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Interval:</UI.TKey>
            <UI.TVal><UI.Turns turns={config.interval} /></UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Increase:</UI.TKey>
            <UI.TVal><UI.Stat stat='agility' amount={config.increase} /></UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (skill: Ambusher, UI) => <>
        <UI.TRow>
            <UI.TKey>Interval:</UI.TKey>
            <UI.TVal><UI.Turns turns={skill.interval} /></UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Increase:</UI.TKey>
            <UI.TVal><UI.Stat stat='agility' amount={skill.increase} /></UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Next stack in:</UI.TKey>
            <UI.TVal><UI.Turns turns={skill.turnsUntilNextStack} /></UI.TVal>
        </UI.TRow>
    </>
}
