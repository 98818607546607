import { ArcedShotsConfig } from 'game/extended/heros/ranger/arced_shots/arced_shots'

export const arced_shots_config: Array<ArcedShotsConfig> = [
    {
        increase: 30
    },
    {
        increase: 60
    },
    {
        increase: 100
    }
]