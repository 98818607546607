import { ItemRarity, Trinket } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './skeleton_gloves.png'

export const skeleton_gloves: ItemUI<Trinket> = {
  image,
  label: 'Skeleton gloves',
  value: 10,
  item: {
    key: 'skeleton_gloves',
    type: 'trinket',
    rarity: ItemRarity.Epic,
    stats: {
      armor: 5,
      shielding: 5,
      health: 5
    }
  }
}
