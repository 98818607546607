import { Ability, AbilityConfig, Character, Turns } from 'game/core'
import { MagicInterruptEffect } from 'game/extended/creatures/goblins/abilities/magic_interrupt/magic_interrupt_effect'
import { Memoize } from 'game/util/memoize'

export type MagicInterruptConfig = AbilityConfig & {
    duration: Turns
}

export class MagicInterrupt extends Ability<MagicInterruptConfig>{

    static KEY = 'MagicInterrupt' as const
    static FIXED_REQUIREMENTS = []

    @Memoize()
    get targets(): Array<Character> {
        return this.character.side.enemySide.characters
            .filter(char => !char.effects.find(effect =>
                effect instanceof MagicInterruptEffect && effect.duration.turns === this.duration
            ))
    }

    @Memoize()
    get duration(): Turns {
        return this.currentConfig.duration
    }

    // ----- Calculate new State ----- //
    performActionImpl() {
        return this.performOnAllTargets(char =>
            char.addEffect(MagicInterruptEffect, { origin: this.path, duration: this.duration })
        )
    }
}
