import { Armor, ItemRarity } from 'game/core'
import image from './fire_robe.png'
import { ItemUI } from 'game/extended/uis/item_uis'

export const fire_robe: ItemUI<Armor> = {
    image,
    label: 'Fire robe',
    value: 55,
    item: {
        key: 'fire_robe',
        type: 'armor',
        rarity: ItemRarity.Rare,
        stats: {
            health: 10,
            agility: 10,
            intelligence: 40,
            resistance: 10
        }
    }
}
