import { PrimaryWeaponAttack, PrimaryWeaponAttackConfig } from 'game/core'
import { AbilityUI } from 'game/extended/uis/abilities/AbilityUI'
import React from 'react'

import { primary_weapon_attack_config } from './primary_weapon_attack_config'
import image from './primary_weapon_attack.png'

export const primary_weapon_attack_ui: AbilityUI<'PrimaryWeaponAttack'> = {
    model: PrimaryWeaponAttack,
    configs: primary_weapon_attack_config,
    image,
    label: 'Primary Weapon Attack',
    renderTargets: () => 'Weapon target',
    renderDescription: () => <>
        Use your primary weapon to attack.
    </>,
    renderImpression: (ability: PrimaryWeaponAttack, UI) => <>
        <UI.Char character={ability.character} />{' '}lifts {ability.character?.isFemale ? 'her' : 'his'} {ability.weapon?.category} and
        strikes{' '}<UI.Char character={ability.targets} />.
    </>,
    renderTableStatic: (ability: PrimaryWeaponAttackConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Damage:</UI.TKey>
            <UI.TVal>Weapon damage</UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (ability, UI) => <>
        <UI.TRow>
            <UI.TKey>Damage:</UI.TKey>
            <UI.TVal><UI.Damage damage={ability.damage} /></UI.TVal>
        </UI.TRow>
    </>
}
