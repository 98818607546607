import { Headgear, ItemRarity } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './stormcaller_crown.png'

export const stormcaller_crown: ItemUI<Headgear> = {
    image,
    label: 'Stormcaller crown',
    value: 180,
    item: {
        key: 'stormcaller_crown',
        type: 'headgear',
        rarity: ItemRarity.Legendary,
        stats: {
            intelligence: 80,
            agility: 50,
            health: 80,
            resistance: 50
        }
    }
}
