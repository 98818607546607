import { MoveFront } from 'game/core'
import image from 'game/extended/core/abilities/move_front/move_front.png'
import { AbilityUI } from 'game/extended/uis/abilities/AbilityUI'
import React from 'react'
import { move_front_config } from 'game/extended/core/abilities/move_front/move_front_config'

export const move_front_ui: AbilityUI<'MoveFront'> = {
    model: MoveFront,
    configs: move_front_config,
    image,
    label: 'Move Front',
    renderTargets: () => 'Yourself',
    renderDescription: () => <span>
        Move to the front line.
    </span>,
    renderImpression: (ability: MoveFront, UI) => <span>
        <UI.Char character={ability.character} />{' '}steps forward, into the front line.
    </span>
}
