import { Ability, AbilityConfig, Character, DamageNature, DamageType, FullHealing } from 'game/core'
import { Memoize } from 'game/util/memoize'

export type BiteConfig = AbilityConfig & {
    damage: number
}

export class Bite extends Ability<BiteConfig> {

    static KEY = 'Bite' as const
    static FIXED_REQUIREMENTS = []

    @Memoize()
    get targets() {
        return this.character.meleeTarget
    }

    @Memoize()
    get damage() {
        return this.calculateDamage(DamageType.Melee, DamageNature.Melee, this.currentConfig.damage)
    }

    // ----- Calculate new State ----- //
    performActionImpl() {
        if (!this.targets) return this.battle

        let result = this.targets.takeDamage(this.damage)
        const targetAfterDamageDealt = Character.byPath(result, this.targets.path)
        const charAfterDamageDealt = Character.byPath(result, this.path)
        const damageDealt = this.targets.hp - (targetAfterDamageDealt?.hp || 0)

        if (damageDealt <= 0 || !charAfterDamageDealt) return result

        const fullHealing = this.calculateHealing(damageDealt)
        const amountAfter = Math.round(fullHealing.amount * (1 - this.targets.hpRatio))
        const fullHealingAfter: FullHealing = {
            ...fullHealing,
            modifiers: [
                ...fullHealing.modifiers,
                { type: 'ability', origin: this.path, before: fullHealing.amount, after: amountAfter }
            ],
            amount: amountAfter
        }

        return charAfterDamageDealt.heal(fullHealingAfter)
    }
}