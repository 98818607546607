import {
    Character,
    CharDamageIgnored,
    Effect,
    EffectDuration,
    EffectTriggered,
    FullDamage,
    Interrupt,
    Turns,
} from 'game/core'
import { Memoize } from 'game/util/memoize'

export class ImmunityEffect extends Effect<{
    duration: Turns
}> {
    static KEY = 'ImmunityEffect' as const

    @Memoize()
    get duration(): EffectDuration {
        return {
            type: 'turns',
            turns: this.state.duration
        }
    }

    // ----- Interrupts ----- //
    interruptOnDamage(target: Character, damage: FullDamage): Interrupt {
        if (target !== this.target)
            return false
        return this.battle
            .startEvent(EffectTriggered, { effect: this.path })
            .addEvent(CharDamageIgnored, {
                damage,
                character: this.target.path
            })
            .endEvent()
    }
}
