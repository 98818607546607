import {
    Battle,
    Character,
    CharacterPath,
    EffectFinalized,
    EffectRefreshed,
    ModelID,
    Modifier,
    ModifierState,
    Side,
    Turns,
} from 'game/core'
import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'

export type CoreEffectKey = string

export type EffectConstructor<M extends Effect> = {
    KEY: string
    STACKS: boolean
    new(parent: Character, state: M['state']): M
}

export type EffectDuration =
    | { type: 'turns', turns: number }
    | { type: 'unlimited' }

export interface EffectPath extends CharacterPath {
    effect: {
        key: CoreEffectKey,
        id: string
    }
}

export interface EffectState extends ModifierState {
    key: CoreEffectKey
    id: ModelID
    time: number
    turnsPassed: Turns
    origin: CharacterPath
}

export type GeneratedEffectStateKeys = 'key' | 'id' | 'time' | 'turnsPassed'

export abstract class Effect<S = {}> extends Modifier<Character, S & EffectState> {
    static STACKS: boolean = false

    static byPath(battle: Battle, path: EffectPath): Nullable<Effect> {
        const character = Character.byPath(battle, path)
        if (!character) return null
        return character.effects.find(eff => eff.id === path.effect.id)
    }

    abstract get duration(): EffectDuration

    @Memoize()
    get prio(): number {
        return 5
    }

    @Memoize()
    get target(): Character {
        return this.parent
    }

    @Memoize()
    get origin(): Nullable<Character> {
        return Character.byPath(this.battle, this.state.origin)
    }

    @Memoize()
    get originSide(): Side {
        return Side.byPath(this.battle, this.state.origin)
    }

    @Memoize()
    get key() {
        return this.state.key
    }

    @Memoize()
    get id() {
        return this.state.id
    }

    @Memoize()
    get path(): EffectPath {
        return { ...this.target.path, effect: { id: this.id, key: this.key } }
    }

    @Memoize()
    get turnsPassed(): Turns {
        return this.state.turnsPassed
    }

    @Memoize()
    get directChildren() {
        return []
    }

    // ----- Reactions ----- //
    reactToFinalize(): Battle {
        return this.battle
    }

    // ----- Calculate new State ----- //
    update(newState: Partial<S>) {
        return this.target.updateEffect({ ...this.state, ...newState })
    }

    updateAndRefresh(newState: Partial<S>) {
        return this.target.updateEffect({
            ...this.state,
            time: this.time,
            turnsPassed: 0,
            ...newState
        })
    }

    increaseTurn(): Battle {
        if (this.duration.type === 'turns' && this.turnsPassed + 1 >= this.duration.turns) {
            return this.finalize()
        }
        return this.target.updateEffect({ ...this.state, turnsPassed: this.turnsPassed + 1 })
    }

    finalize(): Battle {
        return this.battle
            .startEvent(EffectFinalized, { effect: this.path, effectKey: this.key })
            .perform(Effect, this.path, effect => effect.reactToFinalize())
            .perform(Character, this.path, char => char.removeEffect(this.state))
            .endEvent()
    }

    refresh(state: S): Battle {
        return this.battle
            .startEvent(EffectRefreshed, { effect: this.path })
            .perform(Effect, this.path, (effect: Effect) =>
                effect.parent.updateEffect({
                    ...effect.state,
                    time: effect.time,
                    ...state
                })
            )
            .endEvent()
    }
}
