import {
    Ability,
    Battle,
    DamageModifier,
    DamageType,
    Effect,
    InjuryModifier,
    Requirement,
    RequirementPath,
    Rule,
    Skill,
    Weapon,
} from 'game/core'
import { AbilityKey, ConditionKey, EffectKey, RequirementKey, SkillKey, WeaponKey, weapons } from 'game/extended/types'
import { getAbilityUI } from 'game/extended/uis/abilities/ability_uis'
import { getConditionUI } from 'game/extended/uis/conditions/condition_uis'
import { getEffectUI } from 'game/extended/uis/effects/effect_uis'
import { ItemUI } from 'game/extended/uis/item_uis'
import { getRequirementUI } from 'game/extended/uis/requirements/requirement_uis'
import { getSkillUI } from 'game/extended/uis/skills/skill_uis'
import {
    AbilityViewType,
    CharViewType,
    ConditionViewType,
    DamageTypeViewType,
    DamageViewType,
    EffectViewType,
    HealingViewType,
    InjuryViewType,
    ReasonsViewType,
    RuleViewType,
    SkillViewType,
    StatViewType,
    TurnsViewType,
    UIType,
    WeaponViewType,
} from 'game/extended/uis/UI'
import React, { createContext, useContext } from 'react'
import { Log, LogChildren, LogMod, LogMsg } from 'views/replay/logs/log'
import { StatIcon } from 'views/ui/stats/staticon'
import { TableKey, TableRow, TableValue } from 'views/ui/table'
import { AbilityVisual } from 'views/ui/visuals/abilityvisual'
import { CharacterVisual } from 'views/ui/visuals/charactervisual'
import { ConditionVisual } from 'views/ui/visuals/conditionvisual'
import { EffectVisual } from 'views/ui/visuals/effectvisual'
import { SkillVisual } from 'views/ui/visuals/skillvisual'

export const DefautUIBattleContext = createContext<Battle | undefined>(undefined)

export const useDefaultUIBattle = () => {
    const battle = useContext(DefautUIBattleContext)
    if (!battle) throw new Error('DefautUIBattleContext was not defined')
    return battle
}

const CharView: CharViewType = ({ character }) => {
    if (!character) return null

    if (!character) return <>noone</>
    if (!Array.isArray(character)) {
        return <span className="message_character">

            <CharacterVisual type={character.type} />
            {character.name}
        </span>
    }

    switch (character.length) {
        case 0:
            return <>noone</>
        case 1:
            return <CharView character={character[0]} />
        case 2:
            return <>
                <CharView character={character[0]} />{' and '}
                <CharView character={character[1]} />
            </>
        default:
            return <>
                {character.slice(0, -2).map((char, i) => <Fragment key={i}>
                    <CharView character={char} />{', '}
                </Fragment>)}
                <CharView character={character.slice(-2)} />
            </>
    }
}

const RuleView: RuleViewType = ({ rule: ruleInput }) => {
    const battle = useContext(DefautUIBattleContext)
    if (!ruleInput) return null
    const rule = ruleInput instanceof Rule ? ruleInput : (battle && Rule.byPath(battle, ruleInput))
    if (!rule) return null

    return <span className="message_rule">
        {rule.abilities.map((ability, i) =>
            <AbilityVisual ability={ability.key as AbilityKey} key={i} />
        )}
        Seq {rule.index + 1}
    </span>
}

const AbilityView: AbilityViewType = ({ ability }) => {
    if (!ability) return null

    if (!Array.isArray(ability)) {
        const key = (ability instanceof Ability ? ability.key : ability.ability) as AbilityKey
        const ui = getAbilityUI(key)
        return <span className="message_character">
            <AbilityVisual ability={key} />
            {ui.label}
        </span>
    }

    switch (ability.length) {
        case 0:
            return null
        case 1:
            return <AbilityView ability={ability[0]} />
        case 2:
            return <>
                <AbilityView ability={ability[0]} />{' and '}
                <AbilityView ability={ability[1]} />
            </>
        default:
            return <>
                {ability.slice(0, -2).map((ab, i) => <Fragment key={i}>
                    <AbilityView ability={ab} />{', '}
                </Fragment>)}
                <AbilityView ability={ability.slice(-2)} />
            </>
    }
}

const EffectView: EffectViewType = ({ effect }) => {
    if (!effect) return null

    const key = (typeof effect === 'string' ? effect : effect.key) as EffectKey
    const ui = getEffectUI(key)

    return <span className="message_character">
        <EffectVisual effect={key} />
        {ui.label}
    </span>
}

const SkillView: SkillViewType = ({ skill }) => {
    if (!skill) return null

    const key = (typeof skill === 'string' ? skill : skill.key) as SkillKey
    const ui = getSkillUI(key)
    return <span className="message_character">
        <SkillVisual skill={key} />
        {ui?.label}
    </span>
}

const ConditionView: ConditionViewType = ({ condition }) => {
    if (!condition) return null

    const key = condition.condition as ConditionKey
    const ui = getConditionUI(key)
    return <span className="message_condition">
        <ConditionVisual condition={key} />
        {ui && ui.label}
    </span>
}

const TurnsView: TurnsViewType = ({ turns }) => {
    return <span>
        {turns} turns
    </span>
}

const DamageView: DamageViewType = attrs => {
    const battle = useContext(DefautUIBattleContext)
    const { percentage } = attrs

    if ('type' in attrs) {
        return <span className="message_damage">
            {attrs.damage}{percentage ? '%' : ''}<DamageTypeView type={attrs.type} unit={true} />
        </span>
    }

    const { damage: { amount, type, modifiers } } = attrs

    if (!battle) {
        return <span className="message_damage">
            {amount}{percentage ? '%' : ''}<DamageTypeView type={type} unit={true} />
        </span>
    }

    let infoString = `${amount}`
    if (modifiers.length > 0)
        infoString += '\n ' + modifiers.map(mod => printDamageModifier(battle, mod)).join(', ')
    return <span className="message_damage" title={infoString}>
        {amount}{percentage ? '%' : ''}<DamageTypeView type={type} unit={true} />
    </span>
}

const DamageTypeView: DamageTypeViewType = ({ type, unit }) => {
    switch (type) {
        case DamageType.Melee: return <StatIcon stat="melee" mod={{ unit }} />
        case DamageType.Magic: return <StatIcon stat="magic" mod={{ unit }} />
        case DamageType.Ranged: return <StatIcon stat="ranged" mod={{ unit }} />
    }
}

const HealingView: HealingViewType = ({ healing, percentage }) => {
    if (typeof healing === 'number') {
        return <span className="message_healing">
            <StatView stat="health" amount={healing} percentage={percentage} />
        </span>
    }

    return <span className="message_healing">
        <StatView stat="health" amount={healing.amount} percentage={percentage} />
    </span>
}

const InjuryView: InjuryViewType = ({ injury }) => {
    const battle = useDefaultUIBattle()

    let infoString = `${injury.damage.amount}`
    if (injury.damage.modifiers.length > 0)
        infoString += '\n ' + injury.damage.modifiers.map(mod =>
            printDamageModifier(battle, mod)
        ).join(', ')
    if (injury.modifiers.length > 0)
        infoString += '\n ' + injury.modifiers.map(mod =>
            viewInjuryModifier(battle, mod)
        ).join(', ')

    return <span className="message_injury" title={infoString}>
        {`${injury.amount} ${injury.damage.type} damage`}
    </span>
}

const ReasonsView: ReasonsViewType = ({ reasons }) => {
    const battle = useDefaultUIBattle()
    const renderReqDescription = (req: Requirement | RequirementPath) => {
        const requirement = req instanceof Requirement ? req : Requirement.byPath(battle, req)
        if (!requirement) return ''
        return getRequirementUI(requirement.key as RequirementKey).renderDescription(requirement.state, DefaultUI)
    }

    switch (reasons.length) {
        case 0:
            return null
        case 1:
            const reason = reasons[reasons.length - 1]
            return <span>
                the requirement
                '{renderReqDescription(reason)}'
                was not fulfilled
            </span>
        default:
            const lastReason = reasons[reasons.length - 1]
            const reasonsInList = reasons.slice(0, -1)
            return <span>
                the requirements {' '}
                <>
                    {reasonsInList.map((req, i) => <span key={i}>
                        '{renderReqDescription(req)}'
                        {i < reasonsInList.length - 1 && ', '}
                    </span>)}
                </> {' '}
                and '{renderReqDescription(lastReason)}'
                where not fulfilled
            </span >
    }
}

const StatView: StatViewType = ({ stat, amount, percentage }) => {
    if (amount === undefined) {
        return <StatIcon stat={stat} />
    }

    if (percentage) {
        return <>
            {amount}%<StatIcon stat={stat} mod="unit" />
        </>
    }

    return <>
        {amount}<StatIcon stat={stat} mod="unit" />
    </>
}

const WeaponView: WeaponViewType = ({ weapon }) => {
    if (!weapon) return null
    const ui: ItemUI<Weapon> = weapons[weapon.key as WeaponKey]
    return <>
        {ui.label}
    </>

}

export const Fragment: React.FC = ({ children }) => <>{children}</>

export const DefaultUI: UIType = {
    Char: CharView,
    Rule: RuleView,
    Turns: TurnsView,
    Condition: ConditionView,
    Skill: SkillView,
    Effect: EffectView,
    Ability: AbilityView,
    Damage: DamageView,
    DamageType: DamageTypeView,
    Injury: InjuryView,
    Reasons: ReasonsView,
    Healing: HealingView,
    Stat: StatView,
    Weapon: WeaponView,

    TRow: TableRow,
    TKey: TableKey,
    TVal: TableValue,

    Log: Log,
    LogMsg: LogMsg,
    LogChildren: LogChildren,
    LogMod: LogMod
}

const printDamageModifier = (battle: Battle, mod: DamageModifier) => {
    const modification = mod.after.amount - mod.before.amount
    const amount = (modification >= 0 ? '+' : '-') + Math.abs(modification)
    switch (mod.type) {
        case 'stat':
            return `${amount} from ${mod.stat} ${mod.origin}`
        case 'effect':
            const effect = Effect.byPath(battle, mod.origin)
            if (!effect) return `${amount}`
            const effectUI = getEffectUI(effect.key as EffectKey)
            return `${amount} from ${effectUI ? effectUI.label : effect.key}`
        case 'skill':
            const skill = Skill.byPath(battle, mod.origin)
            if (!skill) return `${amount}`
            const skillUI = getSkillUI(skill.key as SkillKey)
            return `${amount} from ${skillUI ? skillUI.label : skill.key}`
    }
}


const viewInjuryModifier = (battle: Battle, mod: InjuryModifier) => {
    const modification = mod.after - mod.before
    const amount = (modification >= 0 ? '+' : '-') + Math.abs(modification)
    switch (mod.type) {
        case 'stat':
            return `${amount} from ${mod.stat} target's ${mod.origin}`
        case 'effect':
            const effect = Effect.byPath(battle, mod.origin)
            if (!effect) return `${amount}`
            const effectUI = getEffectUI(effect.key as EffectKey)
            return `${amount} from ${effectUI ? effectUI.label : effect.key}`
        case 'skill':
            const skill = Skill.byPath(battle, mod.origin)
            if (!skill) return `${amount}`
            const skillUI = getSkillUI(skill.key as SkillKey)
            return `${amount} from ${skillUI?.label || skill.key}`
    }
}
