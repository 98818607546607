import 'views/ingame/user/shop/itemspopup.scss'

import { ProductModel } from 'models/user/ProductModel'
import React from 'react'
import { useParams } from 'react-router-dom'
import { Product } from 'views/ingame/user/shop/product'
import { useUser } from 'views/ingame/user/usercontext'
import { Popup, PopupContent, PopupHeader, PopupStat, PopupTab, PopupTabs } from 'views/layout/popup'
import { useLinker } from 'views/link'
import { useHinter } from 'views/ingame/campaign/tutorial'

function tabKeyFromString(key?: string): 'weapons' | 'wearables' {
    if (key === 'wearables') return 'wearables'
    return 'weapons'
}

export const ItemsPopup: React.FC<{
    tab?: string
}> = params => {
    const { tab: tabKey } = useParams<{ tab: string }>()
    const { user } = useUser()
    const { goTo } = useLinker()

    const hint = useHinter()
    const tab = tabKeyFromString(tabKey)

    let products: Array<ProductModel> = []
    if (tab === 'weapons') products = user.shop.weaponProducts
    if (tab === 'wearables') products = user.shop.wearableProducts

    return < Popup onClose={() => goTo('/')}>
        <PopupHeader>
            <PopupTabs>
                <PopupTab
                    label="Weapons"
                    active={tab === 'weapons'}
                    to={`/shop/weapons`}
                    hinted={hint && hint.key === 'shop' && hint.tab === 'weapons'}
                />
                <PopupTab
                    label="Items"
                    active={tab === 'wearables'}
                    to={`/shop/wearables`}
                    hinted={hint && hint.key === 'shop' && hint.tab === 'armor'}
                />
            </PopupTabs>
            <PopupStat
                stat="currency"
                amount={user.money}
            />
        </PopupHeader>
        <PopupContent>
            {<div className="itemspopup-products">
                {products.map(product =>
                    <Product product={product} key={product.key} />
                )}
            </div>}
        </PopupContent>
    </Popup >
}
