import { ChargingState, Ability, Character, CharacterPath, Event } from 'game/core'
import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'

export class CharInterrupted extends Event<{
    character: CharacterPath,
    charging: ChargingState
}> {
    static KEY = 'CharInterrupted' as const

    @Memoize()
    get interruptedAbility(): Nullable<Ability> {
        if (!this.state.charging) return null
        return Ability.byPath(this.battle, this.state.charging.ability)
    }

    @Memoize()
    get target(): Nullable<Character> {
        return Character.byPath(this.battle, this.state.character)
    }
}
