import { Ability, AbilityConfig, Character } from 'game/core'
import { Memoize } from 'game/util/memoize'

import { TrueSightEffect } from './true_sight_effect'

export type TrueSightConfig = AbilityConfig

export class TrueSight extends Ability<TrueSightConfig>{

    static KEY = 'TrueSight' as const
    static FIXED_REQUIREMENTS = []

    @Memoize()
    get targets(): Array<Character> {
        return this.character.allAllies
    }


    // ----- State change inquiry ----- //
    performActionImpl() {
        return this.performOnAllTargets(this.applyToCharacter)
    }

    private applyToCharacter = (char: Character) => {
        return char.addEffect(TrueSightEffect, {
            origin: this.character.path
        })
    }
}


