import { LifedrainEffect } from 'game/extended/creatures/undead/abilities/lifedrain/lifedrain_effect'
import { EffectUI } from 'game/extended/uis/effects/EffectUI'
import React from 'react'

import image from './lifedrain.png'

export const lifedrain_effect_ui: EffectUI<'LifedrainEffect'> = {
  model: LifedrainEffect,
  label: 'Lifedrain effect',
  image,
  renderDescription: (effect: LifedrainEffect) => <>
    Each turn this character takes a percentage of his maxhp as damage.
    Damage increases each turn and is bestowed as healing upon the caster's front allies.
  </>,
  renderTable: (effect: LifedrainEffect, UI) => <>
    <UI.TRow>
      <UI.TKey>Next damage:</UI.TKey>
      <UI.TVal><UI.Damage damage={effect.damage} /></UI.TVal>
    </UI.TRow>
  </>
}
