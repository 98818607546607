import { AttackRange, DamageType, ItemRarity, Weapon } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './lion_sword.png'

export const lion_sword: ItemUI<Weapon> = {
    image,
    label: 'Lion sword',
    value: 280,
    item: {
        key: 'lion_sword',
        type: 'weapon',
        rarity: ItemRarity.Legendary,
        stats: {
            strength: 125,
            health: 60,
            energy: 5
        },
        two_handed: false,
        category: 'sword',
        damageType: DamageType.Melee,
        range: AttackRange.Melee,
        energy: 2,
        damage: 17
    }
}
