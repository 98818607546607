import { UnitedEffect } from 'game/extended/heros/paladin/united/united_effect'
import { EffectUI } from 'game/extended/uis/effects/EffectUI'
import React from 'react'

import image from './united_effect.png'

export const united_effect_ui: EffectUI<'UnitedEffect'> = {
    model: UnitedEffect,
    label: 'United bonus',
    image,
    renderDescription: (effect: UnitedEffect) => <>
        Your strength is increased and each additional stack will further increase this.
    </>,
    renderTable: (effect: UnitedEffect, UI) => <>
        <UI.TRow >
            <UI.TKey>Stacks: </UI.TKey>
            <UI.TVal>{effect.stacks}</UI.TVal>
        </UI.TRow>
        <UI.TRow >
            <UI.TKey>Increase: </UI.TKey>
            <UI.TVal><UI.Stat stat='strength' amount={effect.increase} /></UI.TVal>
        </UI.TRow>
    </>
}
