import {
    AbilityPerformed,
    Battle,
    Character,
    CharacterPath,
    DamageNature,
    DamageType,
    ExtendableGameModel,
    ExtendableGameModelState,
    FullDamage,
    FullHealing,
    GameModel,
    getInterrupt,
    RequiredCooldown,
    RequiredEnergy,
    RequiredNoBlockingModifier,
    RequiredValidTarget,
    Requirement,
    RequirementState,
    Time,
    Turns,
    Type,
} from 'game/core'
import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'

export type CoreAbilityKey = string

export interface AbilityPath extends CharacterPath {
    ability: CoreAbilityKey
}

export interface AbilityState extends ExtendableGameModelState {
    level: number,
    lastUsage: Time | false,
    available: Time | true
}

export interface AbilityConfig {
    available: Turns
    cooldown: Turns | 'unknown'
    energy: number | 'unknown'
}

export type AbilityConstructor<M extends Ability = any> = {
    new(parent: M['parent'], state: M['state']): M
}

export abstract class Ability<C extends AbilityConfig = AbilityConfig> extends ExtendableGameModel<Type, AbilityState, Array<C>> {

    static byPath(battle: Battle, path: AbilityPath): Nullable<Ability> {
        const char = Character.byPath(battle, path)
        if (!char) return null
        return char.type.abilities.find(ability => ability.key === path.ability)
    }


    abstract get targets(): Nullable<Character> | Array<Character> | false

    @Memoize()
    get targetsArray(): Array<Character> {
        if (!this.targets) return []
        if (!Array.isArray(this.targets)) return [this.targets]
        return this.targets
    }


    @Memoize()
    get targetsPaths(): Array<CharacterPath> {
        return this.targetsArray.map(char => char.path)
    }

    @Memoize()
    get currentConfig(): C & AbilityConfig {
        return this.config[this.state.level - 1] || this.config[this.config.length - 1]
    }

    @Memoize()
    get key(): CoreAbilityKey {
        return this.state.key
    }

    @Memoize()
    get type(): Type {
        return this.parent
    }

    @Memoize()
    get character(): Character {
        return this.type.character
    }

    @Memoize()
    get requirements(): Array<Requirement> {
        const constructor = this.constructor as { FIXED_REQUIREMENTS?: Array<RequirementState> }

        return [
            this.env.createModel(this, { key: RequiredNoBlockingModifier.KEY }),
            this.env.createModel(this, { key: RequiredCooldown.KEY }),
            this.env.createModel(this, { key: RequiredEnergy.KEY }),
            this.env.createModel(this, { key: RequiredValidTarget.KEY }),
            ...(constructor.FIXED_REQUIREMENTS || []).map(state =>
                this.env.createModel(this, state) as Requirement
            )
        ]
    }

    @Memoize()
    get path(): AbilityPath {
        return { ...this.character.path, ability: this.state.key }
    }

    @Memoize()
    get directChildren(): Array<GameModel> {
        return []
    }

    @Memoize()
    get level(): number {
        return this.state.level
    }

    @Memoize()
    get index(): number {
        return this.type.abilities.findIndex(ab => ab === this)
    }

    @Memoize()
    get energy(): number {
        if (this.currentConfig.energy === 'unknown') throw new Error('Implement in subclass')
        return this.currentConfig.energy
    }

    @Memoize()
    get cooldown(): Turns {
        if (this.currentConfig.cooldown === 'unknown') throw new Error('Implement in subclass')
        return this.currentConfig.cooldown
    }

    @Memoize()
    get cooldownLeft(): Turns {
        if (this.character.nextTurn >= this.available) return 0
        return this.available - this.character.nextTurn
    }

    @Memoize()
    get nullDamage(): FullDamage {
        return {
            origin: this.path,
            nature: DamageNature.Melee,
            type: DamageType.Melee,
            amount: 0,
            original_amount: 0,
            modifiers: []
        }
    }

    @Memoize()
    get initialAvailablity(): Turns {
        const base = this.currentConfig.available
        return this.character.type.skills.reduce((turns, skill) => skill.applyInitialAvailablityModifier(turns), base)
    }

    @Memoize()
    get available(): Turns {
        if (this.state.available === true) {
            return this.initialAvailablity
        }
        return this.state.available
    }

    @Memoize()
    get hasFailingReq(): boolean {
        return this.failingReqs.length > 0
    }

    @Memoize()
    get failingReqs(): Array<Requirement> {
        return this.requirements.filter(req => !req.isValid)
    }

    @Memoize()
    get chargingAbilityIgnoringThis(): Nullable<Ability> {
        const battle = this.update({ available: 9999 })
        const char = Character.byPath(battle, this.character.path)
        if (!char) return null
        return char.chargingAbility
    }

    calculateHealing(amount: number): FullHealing {
        return this.character.calculateHealing({ amount, origin: this.path, original_amount: amount })
    }

    calculateDamage(type: DamageType, nature: DamageNature, amount: number): FullDamage {
        return this.character.calculateDamage(this, type, nature, amount)
    }

    // ----- Calculate new State ----- //
    abstract performActionImpl(): Battle

    protected update(newState: Partial<AbilityState>) {
        return this.type.updateAbility(this.state, newState)
    }

    protected performOnAllTargets(func: (char: Character) => Battle) {
        if (!this.targets || !Array.isArray(this.targets)) return this.battle
        return this.battle
            .performAll(Character, this.targets.map(ch => ch.path), func)
    }

    public interrupt(): Battle {
        return this.finalizePerform()
    }

    public execute(): Battle {
        const interrupt = getInterrupt(this.battle.modifiers, mod => mod.interruptOnCast(this))
        if (interrupt !== false) return interrupt

        return this.battle
            .startEvent(AbilityPerformed, {
                ability: this.path,
                energy: this.energy,
                targets: this.targetsPaths
            })
            .perform(Ability, this.path, (ability: Ability) =>
                ability.character.setEnergy(ability.character.energy - ability.energy)
            )
            .perform(Ability, this.path, (ability: Ability) => ability.performActionImpl())
            .perform(Ability, this.path, (ability: Ability) => ability.finalizePerform())
            .endEvent()
    }

    private finalizePerform(): Battle {
        return this.update({
            lastUsage: this.time,
            available: this.time + this.cooldown
        })
    }
}
