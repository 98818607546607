import { CreatureUI } from 'game/extended/uis/types/creature_uis'

import circle from './vampire_circle.png'
import head from './vampire_head.png'

export const vampire: CreatureUI = {
  label: 'Vampire',
  definition: lvl => ({
    stats: {
      health: 200,
      energy: 20,
      intelligence: 50,
      strength: 200,
      agility: 50,
      armor: 45,
      shielding: 25,
      resistance: 25
    },
    equipment:
    {
      primary: 'vampire_sword',
      armor: 'vampire_armor',
      trinket: 'vampire_ring'
    },
    skills: [

    ],
    strategy: [
      'ShadowStrike',
      'Bite',
      'PrimaryWeaponAttack',
      'MoveFront'
    ]
  }),
  circle: {
    src: circle,
    width: 240,
    height: 150
  },
  head
}
