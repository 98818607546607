import 'views/ingame/user/academy/academy.scss'

import React from 'react'
import { Recruit } from 'views/ingame/user/academy/recruit'
import { useUser } from 'views/ingame/user/usercontext'
import { Popup, PopupContent, PopupFullHeader } from 'views/layout/popup'
import { Redirect, useLinker } from 'views/link'

export const Academy: React.FC = () => {
    const { user } = useUser()
    const { goTo, processTo } = useLinker()

    let title = 'Recruit hero'
    if (user.heros.length === 0) title = 'Recruit your primary hero'

    if (user.academy.isEmpty) {
        return <Redirect to={processTo("/")} />
    }

    return <Popup onClose={() => goTo('')}>
        <PopupFullHeader
            icon={{ stat: "currency", amount: user.money }}
        >
            {title}
        </PopupFullHeader>
        <PopupContent>
            <div className="academy-recruits">
                {user.academy.recruits.map(recruit =>
                    <Recruit recruit={recruit} key={recruit.key} />
                )}
            </div>
        </PopupContent>
    </Popup >
}
