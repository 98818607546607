import { App as CapApp, Plugins } from '@capacitor/core'
import { IonApp, isPlatform } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import { AdsProvider } from 'ads/ads'
import { AppContextProvider } from 'AppContext'
import React, { useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { About } from 'views/home/about/About'
import { Chapters } from 'views/home/chapters/Chapters'
import { Home } from 'views/home/home'
import { Campaign } from 'views/ingame/campaign/Campaign'
import { Endless } from 'views/ingame/endless/Endless'
import { Library } from 'views/library/library'
import './index.scss'
import './style.scss'

/*import './theme/variables.css'
import '@ionic/react/css/core.css'
import '@ionic/react/css/display.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/padding.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/typography.css'*/

const onFocus = () => {
  if (!isPlatform('android')) return
  Plugins.StatusBar.setOverlaysWebView({ overlay: true })
  Plugins.StatusBar.hide()
};

const App: React.FC = () => {
  useEffect(() => {
    onFocus()
    CapApp.addListener('appStateChange', onFocus)
    const interval = setInterval(onFocus, 4000)
    return () => {
      CapApp.removeAllListeners();
      clearInterval(interval)
    };
  }, [])

  return <IonApp>
    <AppContextProvider>
        <IonReactRouter>
          <AdsProvider>
              <Switch>
                <Route path="/home" component={Home} />
                <Route path="/about" component={About} />
                <Route path="/endless" component={Endless} />
                <Route path="/chapters" component={Chapters} />
                <Route path="/campaign/:chapterKey" component={Campaign} />
                <Route path="/library" component={Library} />
                <Route exact path="/" render={() => <Redirect to="/home" />} />
              </Switch>
          </AdsProvider>
        </IonReactRouter>
    </AppContextProvider>
  </IonApp>
}

export default App
