import { AfterburnEffect } from 'game/extended/heros/mage/afterburn/afterburn_effect'
import { EffectUI } from 'game/extended/uis/effects/EffectUI'
import React from 'react'

import image from './afterburn.png'

export const afterburn_effect_ui: EffectUI<'AfterburnEffect'> = {
    model: AfterburnEffect,
    label: 'Afterburn',
    image,
    renderDescription: (effect: AfterburnEffect) => <>
        Take magic damage at the end of your turn.
        Additionally your magic resistance is also decreased.
    </>,
    renderTable: (effect: AfterburnEffect, UI) => <>
        <UI.TRow>
            <UI.TKey>Damage</UI.TKey>
            <UI.TVal><UI.Damage damage={effect.damage} /></UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Decrease:</UI.TKey>
            <UI.TVal><UI.Stat stat='resistance' amount={effect.vulnerability} /></UI.TVal>
        </UI.TRow>
    </>
}
