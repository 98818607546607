import { Idle } from 'game/core'
import image from 'game/extended/core/abilities/idle/idle.png'
import { idle_config } from 'game/extended/core/abilities/idle/idle_config'
import { AbilityUI } from 'game/extended/uis/abilities/AbilityUI'
import React from 'react'

export const idle_ui: AbilityUI<'Idle'> = {
    model: Idle,
    configs: idle_config,
    image,
    label: 'Idle',
    renderTargets: () => 'Yourself',
    renderDescription: () => <>
        Do not perform any action for now.
    </>,
    renderImpression: (ability: Idle, UI) => <>
        <UI.Char character={ability.character} />{' '}remains idle while awaiting a better moment to make a move.
    </>
}
