import { CreatureKey } from 'game/extended/types'

export type EndlessData = {
  points: number,
  position: 'front' | 'support' | 'random'
} | false

export const endless_data: Record<CreatureKey, EndlessData> = {
  blowfishy: false,
  turtly: false,
  bully: false,
  chicky: false,
  hedgy: false,
  goblin_axeman: {
    points: 2,
    position: 'random'
  },
  goblin_swordman: {
    points: 2,
    position: 'front'
  },
  goblin_brute: {
    points: 12,
    position: 'front'
  },
  goblin_big_momma: {
    points: 30,
    position: 'random'
  },
  goblin_chief: {
    points: 8,
    position: 'random'
  },
  goblin_bomber: {
    points: 3,
    position: 'support'
  },
  goblin_shaman: {
    points: 5,
    position: 'support'
  },
  goblin_chief_mounted: {
    points: 12,
    position: 'front'
  },
  bat: {
    points: 1.5,
    position: 'random'
  },
  vampire: {
    points: 10,
    position: 'front'
  },
  vampiress: {
    points: 8,
    position: 'random'
  },
  skeleton_king: {
    points: 40,
    position: 'random'
  },
  skeleton: {
    points: 3,
    position: 'front'
  },
  skeleton_archer: {
    points: 3,
    position: 'support'
  },
  skeleton_wizard: {
    points: 6,
    position: 'support'
  }
}