import { RequiredNoBlockingModifier } from 'game/core'
import { RequirementUI } from 'game/extended/uis/requirements/RequirementUI'
import React from 'react'

export const required_no_blocking_modifier_ui: RequirementUI<'RequiredNoBlockingModifier'> = {
    model: RequiredNoBlockingModifier,
    label: 'Requires no blocking effect',
    renderDescription: (state, UI) => <>
        An effect, which makes this ability unavailable, is present on this character.
    </>
}
