import { ChainLightningConfig } from './chain_lightning'

export const chain_lightning_config: Array<ChainLightningConfig> = [
    {
        available: 1,
        cooldown: 4,
        energy: 5,
        damage: 10,
        decrease: 20,
        targets: 4
    },
    {
        available: 1,
        cooldown: 4,
        energy: 5,
        damage: 15,
        decrease: 15,
        targets: 5
    }
]