import { Ability, AbilityConfig, Character, DamageNature, DamageType, FullDamage, Percentage } from 'game/core'
import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'

export type BodyCheckConfig = AbilityConfig & {
    percentage: Percentage
}

export class BodyCheck extends Ability<BodyCheckConfig>{

    static KEY = 'BodyCheck' as const
    static FIXED_REQUIREMENTS = []

    @Memoize()
    get targets(): Nullable<Character> {
        return this.character.meleeTarget
    }

    @Memoize()
    get damage(): FullDamage {
        const amount = Math.round(this.character.hp * this.percentage / 100)
        return {
            amount: amount,
            original_amount: amount,
            modifiers: [],
            nature: DamageNature.Melee,
            type: DamageType.Melee,
            origin: this.path
        }
    }

    @Memoize()
    get percentage(): Percentage {
        return this.currentConfig.percentage
    }

    // ----- State change inquiry ----- //
    performActionImpl() {
        if (!this.targets) return this.battle
        return this.targets
            .takeDamage(this.damage)
            .perform(Character, this.targets.path, (char: Character) =>
                char.moveToSupport()
            )
    }
}
