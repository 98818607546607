import {
    Ability,
    AbilityConfig,
    Character,
    DamageNature,
    DamageType,
    FullDamage,
    Percentage,
    Ratio,
    RequiredWeaponState,
    Requirement,
} from 'game/core'
import { Memoize } from 'game/util/memoize'

export type WhirlwindConfig = AbilityConfig & {
    increase: Percentage
}

export class Whirlwind extends Ability<WhirlwindConfig>{

    static KEY = 'Whirlwind' as const
    static FIXED_REQUIREMENTS = [
        Requirement.init(RequiredWeaponState, { type: 'melee' })
    ]

    @Memoize()
    get targets(): Array<Character> {
        if (!this.character.meleeTarget) return []
        return [this.character.meleeTarget, ...this.character.meleeTarget.neighbours]
    }

    @Memoize()
    get increase(): Percentage {
        return this.currentConfig.increase
    }

    @Memoize()
    get modifier(): Ratio {
        return (100 + this.increase) / 100
    }

    @Memoize()
    get damage(): FullDamage {
        const weapon = this.character.meleeWeapon
        return this.calculateDamage(
            DamageType.Melee,
            DamageNature.Melee,
            weapon ? weapon.damage * this.modifier : 0
        )
    }

    // ----- Calculate new State ----- //
    performActionImpl() {
        return this.performAll(
            Character,
            this.targets.map(ch => ch.path),
            char => char.takeDamage(this.damage)
        )
    }
}
