import {
    Ability,
    Battle,
    Character,
    Damage,
    Event,
    ExtendableGameModel,
    ExtendableGameModelState,
    FullDamage,
    GameModel,
    ModelID,
    Stats,
    FullInjury,
    FullHealing
} from 'game/core'
import { Item } from 'game/core/item'

export type Interrupt = false | Battle

export interface ModifierState extends ExtendableGameModelState { }

export function getInterrupt<E>(modifiers: Array<Modifier>, interruptFunction: (modifier: Modifier) => Interrupt): Interrupt {
    for (const modifier of modifiers) {
        const interrupt = interruptFunction(modifier)
        if (interrupt !== false) return interrupt
    }
    return false
}

export abstract class Modifier<P extends GameModel = GameModel, S extends ModifierState = ModifierState, C = {}> extends ExtendableGameModel<P, S, C> {

    abstract get target(): Character
    abstract get id(): ModelID

    get blocksMobility() {
        return false
    }

    // ----- Appliers ----- //
    getTargetStats(currentStats: Stats): Stats {
        return currentStats
    }

    applyDamageModifier(damage: Damage): Damage {
        return damage
    }

    applyGlobalDamageModifier(caster: Character, damage: Damage): Damage {
        return damage
    }

    applyOnTargetDamageModifier(damage: Damage, target: Character): Damage {
        return damage
    }

    applyInjuryModifier(amount: number, damage: Damage): number {
        return amount
    }

    applyGlobalInjuryModifier(target: Character, amount: number, damage: Damage): number {
        return amount
    }

    applyHealingModifier(amount: number): number {
        return amount
    }

    applyItemModifier(item: Item): Item {
        return item
    }

    // ----- Reactions ----- //
    reactToEvent(event: Event): Interrupt {
        return false
    }

    canPerform(ability: Ability): boolean {
        return true
    }

    // ----- Interrupts ----- //
    interruptOnCast(ability: Ability): Interrupt {
        return false
    }

    interruptOnDamage(target: Character, damage: FullDamage, potentialInjury: FullInjury): Interrupt {
        return false
    }

    interruptOnHeal(target: Character, healing: FullHealing): Interrupt {
        return false
    }

    interruptOnDeath(target: Character, injury: FullInjury): Interrupt {
        return false
    }
}
