import { Ability, AbilityConfig, Character } from 'game/core'
import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'

export type HolyInterventionConfig = AbilityConfig

export class HolyIntervention extends Ability<HolyInterventionConfig>{

    static KEY = 'HolyIntervention' as const
    static FIXED_REQUIREMENTS = []

    @Memoize()
    get targets(): Nullable<Character> {
        const target = this.character.side.minHpCharacter
        if (target?.hpRatio === 1) return null
        return target
    }

    @Memoize()
    get targeters(): Array<Character> {
        const target = this.targets
        if (!target) return []
        
        return target.side.enemySide.characters
            .filter(char => char.getIsTargeting(target))
    }

    // ----- Calculate new State ----- //
    performActionImpl() {
        if (!this.targets) return this.battle
        const targeters = this.targeters.map(t => t.path)
        const amount = this.targets.maxHp - this.targets.hp
        return this.targets
            .heal({ amount, modifiers: [], origin: this.path, original_amount: amount })
            .performAll(Character, targeters, (char: Character) => char.stun(1, this.path))
    }
}
