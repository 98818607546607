import { ChainHeal, ChainHealConfig } from 'game/extended/creatures/goblins/abilities/chain_heal/chain_heal'
import { chain_heal_config } from 'game/extended/creatures/goblins/abilities/chain_heal/chain_heal_config'
import { AbilityUI } from 'game/extended/uis/abilities/AbilityUI'
import React from 'react'

import image from './chain_heal.png'

export const chain_heal_ui: AbilityUI<'ChainHeal'> = {
    model: ChainHeal,
    configs: chain_heal_config,
    image,
    label: 'Chain heal',
    renderTargets: (config: ChainHealConfig) => `Lowest hp ally chained x${config.targets}`,
    renderDescription: () => <>
        Initiale a healing beam on the most wounded ally. The beams jumps to the most injured adjecent
        ally.
    </>,
    renderImpression: (ability: ChainHeal, UI) => <>
        A mystical beam flows from <UI.Char character={ability.character} />'s staff, healing
        {' '}<UI.Char character={ability.targets} />{' '}and multiple other allies.
    </>,
    renderTableStatic: (config: ChainHealConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Healing:</UI.TKey>
            <UI.TVal><UI.Healing healing={config.healing} /></UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Max chain:</UI.TKey>
            <UI.TVal>{config.targets}</UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (ability: ChainHeal, UI) => <>
        <UI.TRow>
            <UI.TKey>Healing:</UI.TKey>
            <UI.TVal><UI.Healing healing={ability.healing} /></UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Max chain:</UI.TKey>
            <UI.TVal>{ability.targets}</UI.TVal>
        </UI.TRow>
    </>
}
