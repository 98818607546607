import { AttackRange, Requirement } from 'game/core'
import { Memoize } from 'game/util/memoize'
import { UnreachableCaseError } from 'game/util/never'

export type RequiredWeaponStateType =
    'any' | 'melee' | 'ranged' | 'double_melee' | 'primary' | 'secondary' | 'shield'

export class RequiredWeaponState extends Requirement<{
    type: RequiredWeaponStateType
}> {

    static KEY = 'RequiredWeaponState' as const

    @Memoize()
    get type() {
        return this.state.type
    }

    @Memoize()
    get isValid() {
        switch (this.state.type) {
            case 'any':
                return !!this.character.primaryWeapon || !!this.character.secondaryWeapon

            case 'melee':
                return !!this.character.meleeWeapon

            case 'ranged':
                return !!this.character.rangedWeapon

            case 'double_melee':
                if (!this.character.primaryWeapon) return false
                if (!this.character.secondaryWeapon) return false
                if (this.character.primaryWeapon.range === AttackRange.Ranged) return false
                if (this.character.secondaryWeapon.range === AttackRange.Ranged) return false
                return true

            case 'primary':
                return !!this.character.primaryWeapon

            case 'secondary':
                return !!this.character.secondaryWeapon

            case 'shield':
                return !!this.character.shield

            default:
                throw new UnreachableCaseError(this.state.type)
        }
    }
}
