import { ItemRarity, Trinket } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './royal_ring.png'

export const vampire_ring: ItemUI<Trinket> = {
  image,
  label: 'Vampire ring',
  value: 60,
  item: {
    key: 'vampire_ring',
    type: 'trinket',
    rarity: ItemRarity.Rare,
    stats: {
      health: 40
    }
  }
}
