import { SkillTriggered } from 'game/core'
import { EventUI } from 'game/extended/uis/events/EventUI'
import React from 'react'

export const skill_triggered_ui: EventUI<'SkillTriggered'> = {
    model: SkillTriggered,

    render: (event: SkillTriggered, UI) => {
        return <>
            <UI.Skill skill={event.skill} />
            {` was triggered onto `}
            <UI.Char character={event.character} />
        </>
    }
}
