import { Battle, ExtendableGameModel, ExtendableGameModelState, GameModel } from 'game/core'
import { Memoize } from 'game/util/memoize'

export type CoreEventKey = string

export type EventParent = Event<any> | Battle

export interface EventState extends ExtendableGameModelState {
    key: CoreEventKey,
    time: number,
    ended: boolean,
    events: Array<EventState>
}

export type EventConstructor<M extends Event> = {
    KEY: string
    new(parent: EventParent, state: M['state']): M
}

export abstract class Event<S = {}> extends ExtendableGameModel<EventParent, S & EventState> {

    @Memoize()
    get directChildren(): Array<GameModel> {
        return [...this.events]
    }

    @Memoize()
    get battle(): Battle {
        return this.parent.battle
    }

    @Memoize()
    get key(): CoreEventKey {
        return this.state.key
    }

    @Memoize()
    get events(): Array<Event> {
        return this.state.events.map(state =>
            this.env.createModel(this, state)
        )
    }

    @Memoize()
    get eventsFlat(): Array<Event> {
        return this.events.reduce((acc: Array<Event>, e: Event) => {
            return [...acc, e, ...e.eventsFlat]
        }, [])
    }

    @Memoize()
    get ended(): boolean {
        return this.state.ended
    }

    @Memoize()
    get isFirst(): boolean {
        return this.battle.events[0] === this
    }

    @Memoize()
    get activeEvent(): Event | null {
        const lastEvent = this.events[this.events.length - 1]
        if (lastEvent && lastEvent.activeEvent) return lastEvent.activeEvent
        if (!this.ended) return this
        return null
    }

    // ----- Calculate new State ----- //
    private update(newState: Partial<EventState>): Battle {
        return this.parent.updateEvent(this.state, newState)
    }

    updateEvent(event: EventState, newState: Partial<EventState>): Battle {
        return this.update({
            events: this.state.events.map(e =>
                e !== event ? e : { ...e, ...newState }
            )
        })
    }

    addEvent(eventState: EventState): Battle {
        return this.update({
            events: [
                ...this.state.events,
                eventState
            ]
        })
    }

    remove(): Battle {
        return this.update({})
    }

    end(): Battle {
        return this.update({
            ended: true
        })
    }
}
