import { DamageType } from 'game/core'
import { Immolation, ImmolationConfig } from 'game/extended/heros/mage/immolation/immolation'
import { immolation_config } from 'game/extended/heros/mage/immolation/immolation_config'
import { AbilityUI } from 'game/extended/uis/abilities/AbilityUI'
import React from 'react'

import image from './immolation.png'

export const immolation_ui: AbilityUI<'Immolation'> = {
    model: Immolation,
    configs: immolation_config,
    image,
    label: 'Immolation',
    renderTargets: () => 'Front Ally | Yourself',
    renderDescription: () => <>
        Protect yourself with an aura of flames. Incoming damage is absorbed
        untill the shield is broken and reverse damage is inflicted to all
        incoming melee attacks.
    </>,
    renderImpression: (ability: Immolation, UI) => <>
        <UI.Char character={ability.character} />{' '}calls forth an aura of seething flames to protect
        {` `}
        {ability.targets === ability.character ?
            'himself' :
            <UI.Char character={ability.targets} />
        }.
    </>,
    renderTableStatic: (conf: ImmolationConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Shield:</UI.TKey>
            <UI.TVal>
                <UI.Stat stat="health" amount={conf.shield} />
            </UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Reverse damage:</UI.TKey>
            <UI.TVal>
                <UI.Damage damage={conf.reverse_damage} type={DamageType.Magic} />
            </UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (ability: Immolation, UI) => <>
        <UI.TRow>
            <UI.TKey>Shield:</UI.TKey>
            <UI.TVal>
                <UI.Stat stat="health" amount={ability.shield} />
            </UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Reverse dam:</UI.TKey>
            <UI.TVal>
                <UI.Damage damage={ability.reverseDamage} />
            </UI.TVal>
        </UI.TRow>
    </>
}

