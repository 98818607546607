import { DuellerConfig } from 'game/extended/heros/paladin/dueller/dueller'

export const dueller_config: Array<DuellerConfig> = [
    {
        damage_reduction: 20
    },
    {
        damage_reduction: 35
    },
    {
        damage_reduction: 50
    }
]