import { Battle, CharacterPath, CharDamaged, CharDied, DamageType, Event, EventState } from 'game/core'
import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'
import { HeroModel } from 'models/user/hero/HeroModel'

export class BattleHistory {
    battle: Battle
    hero: HeroModel

    constructor(battle: Battle, hero: HeroModel) {
        this.battle = battle
        this.hero = hero
    }

    @Memoize()
    get char(): Nullable<CharacterPath> {
        return this.findCharacter(this.hero.name)
    }

    @Memoize()
    get victorious(): boolean {
        return this.battle.left.isVictorious
    }

    @Memoize()
    get killed(): Array<CharacterPath> {
        if (!this.char) return []
        return this.findKilledEnemies(this.char)
    }

    @Memoize()
    get wounded(): Array<CharacterPath> {
        if (!this.char) return []
        return this.findWoundedEnemies(this.char)
    }

    search<E extends Event>(constructor: { KEY: string, new(...args: any): E }): Array<E['state']> {
        const result: Array<E['state']> = []
        const processEvent = (e: EventState) => {
            if (e.key === constructor.KEY) result.push(e)
            e.events.forEach(e => processEvent(e))
        }
        this.battle.history.forEach(e => processEvent(e))
        return result
    }

    allDamageForType(type: DamageType): number {
        if (!this.char) return 0

        let result = 0
        this.search(CharDamaged).forEach(state => {
            if (state.injury.damage.origin.character !== this.char?.character) return
            if (state.injury.damage.type !== type) return
            result += state.injury.amount
        })
        return result
    }

    allDamageReceivedForType(type: DamageType): number {
        if (!this.char) return 0

        let result = 0
        this.search(CharDamaged).forEach(state => {
            if (state.character.character !== this.char?.character) return
            if (state.injury.damage.type !== type) return
            result += state.injury.amount
        })
        return result
    }

    findCharacter(name: string): Nullable<CharacterPath> {
        return this.battle.characters.find(char => char.name === name)?.path
    }

    findKilledEnemies = (char: CharacterPath) => {
        let killed: Array<CharacterPath> = []
        this.search(CharDied).forEach(state => {
            if (state.character.side === char.side) return
            if (state.injury.damage.origin.character !== char.character) return
            killed.push(state.character)
        })
        return killed
    }

    findWoundedEnemies = (char: CharacterPath) => {
        let wounded: Array<CharacterPath> = []
        this.search(CharDamaged).forEach(state => {
            if (state.character.side === char.side) return
            if (state.injury.damage.origin.character !== char.character) return
            wounded.push(state.character)
        })
        return wounded
    }


}
