import { Character, CharacterPath, Event, FullHealing } from 'game/core'
import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'

export class CharHealed extends Event<{
    character: CharacterPath,
    full_hp: number,
    healing: FullHealing,
    hp: number
}> {

    static KEY = 'CharHealed' as const

    @Memoize()
    get target(): Nullable<Character> {
        return Character.byPath(this.battle, this.state.character)
    }

    @Memoize()
    get healing(): FullHealing {
        return this.state.healing
    }
}
