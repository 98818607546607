import 'views/ingame/user/team/abilities/talentstab.scss'
import React, { useEffect, useState } from 'react'
import { HeroModel } from 'models/user/hero/HeroModel'
import { styler } from 'views/layout/util/style'
import { Popuptab } from 'views/ingame/user/team/popuptab'
import { AbilityVisual } from 'views/ui/visuals/abilityvisual'
import { VisualSubscript, VisualUp } from 'views/ui/visual'
import { Nullable } from 'game/util/maybe'
import { DOMAttrs, Mod } from 'views/ui/ui_types'
import { SkillVisual } from 'views/ui/visuals/skillvisual'
import { SkillTalent } from 'models/user/hero/talents/SkillTalent'
import { AbilityTalent } from 'models/user/hero/talents/AbilityTalent'
import { Sidebar, SidebarContent, SidebarControls, SidebarTitle } from 'views/layout/sidebar'
import { AbilitySide } from 'views/layout/sidebar/abilityside'
import { Button, ButtonPrice } from 'views/layout/button'
import { useUser } from 'views/ingame/user/usercontext'
import { SkillSide } from 'views/layout/sidebar/skillside'
import { PopupInfo } from 'views/layout/popup'
import { TutorialHint, useHinter } from 'views/ingame/campaign/tutorial'

const style = styler('talentstab', ['item', 'group'])

export const Talentstab: React.FC<{
    hero: HeroModel
}> = ({ hero }) => {
    const { setUser } = useUser()
    const [talent, setTalent] = useState<Nullable<AbilityTalent | SkillTalent>>(null)
    const hint = useHinter()
    useEffect(() => {
        setTalent(hero.talents.find(t => t.key === talent?.key))
    }, [hero, talent])

    return <Popuptab>
        <PopupInfo>
            <p>Each character has a unique set of active and passive abilities.
            Talent points can be used to unlock or upgrade abilities.
            Talent points are earned whenever a character finishes an achievement.</p>
            
            <p>Active abilities can be performed during the characters turn in combat,
            they should be scheduled in the strategy tab. Passive abilities are always
            active during combat and supply permanent bonuses or modifiers.</p>
            
            <p>Default abilities are available for all characters and can not be upgraded.
            They include basic actions like manouvring (forward/backward), resting or
            performing melee attacks with your equiped primary/secondary weapon</p>
        </PopupInfo>
        <Sidebar hidden={!talent} onhide={() => setTalent(null)}>
            <SidebarTitle>
                {talent?.label}
            </SidebarTitle>
            <SidebarContent>
                {talent && talent instanceof AbilityTalent && (
                    <AbilitySide
                        costs={talent.costs}
                        ability={talent.key}
                        level={talent.level}
                        env={talent.hero.env}
                    />
                )}
                {talent && talent instanceof SkillTalent && (
                    <SkillSide
                        costs={talent.costs}
                        skill={talent.key}
                        level={talent.level}
                        env={talent.hero.env}
                    />
                )}
            </SidebarContent>
            {talent && !talent.isMaxLevel && (
                <SidebarControls>
                    <Button
                        disabled={!talent.canUpgrade}
                        onClick={() => setUser(talent.upgrade())}
                        mod="xp"
                        hinted={
                            hint &&
                            hint.key === 'teampopup' && hint.tab === 'abilities' &&
                            hint.ability === talent.key
                        }
                    >
                        <span>Upgrade</span>
                        <ButtonPrice stat="xp" amount={talent.cost} />
                    </Button>
                </SidebarControls>
            )}
        </Sidebar>
        <TalentstabGroup
            label="Active abilities"
            expert_label="Min lvl. 20"
            talents={hero.abilityTalents}
            selectedTalent={talent}
            onSelect={setTalent}
        />
        <TalentstabGroup
            label="Passive skills"
            expert_label="Min lvl. 10"
            talents={hero.skillTalents}
            selectedTalent={talent}
            onSelect={setTalent}
        />
        <TalentstabGroup
            label="Default abilities"
            talents={hero.baseAbilityTalents}
            selectedTalent={talent}
            onSelect={setTalent}
            mod="small"
        />
    </Popuptab>
}


export const TalentstabGroup: React.FC<{
    label: string
    expert_label?: string
    talents: Array<AbilityTalent | SkillTalent>
    selectedTalent: Nullable<AbilityTalent | SkillTalent>
    onSelect: (talent: AbilityTalent | SkillTalent) => void
    mod?: Mod<'small'>
}> = ({ label, expert_label, talents, mod, selectedTalent, onSelect }) => {
    const hint = useHinter()
    const expertTalents = talents.filter(talent => talent.isExpert)

    return <div className={style.group()}>
        <div className={style.group('items')}>
            <div className={style.group('talents')}>
                <div className={style.group('talents-title')}>
                    {label}
                </div>
                <div className={style.group('talents-items')}>
                    {talents.filter(talent => !talent.isExpert).map(talent =>
                        <TalentstabItem
                            key={talent.key}
                            talent={talent}
                            selected={talent === selectedTalent}
                            onClick={() => onSelect(talent)}
                            mod={mod}
                            hinted={
                                hint &&
                                hint.key === 'teampopup' && hint.tab === 'abilities' &&
                                hint.ability === talent.key
                            }
                        />
                    )}
                </div>
            </div>
            {expertTalents.length > 0 && <div className={style.group('talents').mod('expert')}>
                <div className={style.group('talents-title')}>
                    {expert_label}
                </div>
                <div className={style.group('talents-items')}>
                    {talents.filter(talent => talent.isExpert).map(talent =>
                        <TalentstabItem
                            key={talent.key}
                            talent={talent}
                            selected={talent === selectedTalent}
                            onClick={() => onSelect(talent)}
                            mod={mod}
                            hinted={
                                hint &&
                                hint.key === 'teampopup' && hint.tab === 'abilities' &&
                                hint.ability === talent.key
                            }
                        />
                    )}
                </div>
            </div>}
        </div>
    </div>
}

export const TalentstabItem: React.FC<{
    talent: AbilityTalent | SkillTalent
    selected: boolean
    mod?: Mod<'small'>
    hinted?: boolean
} & DOMAttrs> = ({ talent, selected, mod, hinted, ...rest }) => {

    const VisualComp = talent instanceof AbilityTalent ? AbilityVisual : SkillVisual
    const attrs = talent instanceof AbilityTalent ? { ability: talent.key } : { skill: talent.key }

    return <div {...rest} className={style.item().mergeProps(rest).mod(mod).is({ selected })}>
        <VisualComp
            visualMod={{ bw: talent.level === 0 }}
            {...(attrs as any)}
        >
            {talent.hasMultipleLevels && talent.level > 0 && (
                <VisualSubscript>Lvl {talent.level}</VisualSubscript>
            )}
            {!talent.isMaxLevel && talent.canUpgrade && (
                <VisualUp />
            )}
        </VisualComp>
        {hinted && <TutorialHint />}
    </div>
}
