import { AttackRange, DamageType, ItemRarity, Weapon } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './goblin_chief_club.png'

export const goblin_chief_club: ItemUI<Weapon> = {
    image,
    label: 'Goblin chief club',
    value: 100,
    item: {
        key: 'goblin_chief_club',
        type: 'weapon',
        rarity: ItemRarity.Epic,
        stats: {
            strength: 20,
            intelligence: 20,
            energy: 4,
            health: 20
        },
        two_handed: false,
        category: 'club',
        damageType: DamageType.Melee,
        range: AttackRange.Melee,
        energy: 4,
        damage: 12
    }
}
