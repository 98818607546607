import { Inspire, InspireConfig } from 'game/extended/creatures/goblins/abilities/inspire/inspire'
import { AbilityUI } from 'game/extended/uis/abilities/AbilityUI'
import React from 'react'

import image from './inspire.png'

export const inspire_ui: AbilityUI<'Inspire'> = {
    model: Inspire,
    configs: [
        {
            available: 0,
            cooldown: 4,
            energy: 4,
            duration: 5,
            energy_increase: 3,
            strength_increase: 60
        }
    ],
    image,
    label: 'Inspire',
    renderTargets: () => 'Allies',
    renderDescription: () => <>
        Increases the strength and energy of all allies.
    </>,
    renderImpression: (ability: Inspire, UI) => <>
        A brutal goblin scream echoes, invigorating all of his allies.
    </>,
    renderTableStatic: (config: InspireConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Strength increase</UI.TKey>
            <UI.TVal><UI.Stat stat='strength' amount={config.strength_increase} /></UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Energy increase</UI.TKey>
            <UI.TVal><UI.Stat stat='energy' amount={config.energy_increase} /></UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Turns:</UI.TKey>
            <UI.TVal>{config.duration}</UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (ability: Inspire, UI) => <>
        <UI.TRow>
            <UI.TKey>Strength increase</UI.TKey>
            <UI.TVal><UI.Stat stat='strength' amount={ability.strengthIncrease} /></UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Energy increase</UI.TKey>
            <UI.TVal><UI.Stat stat='energy' amount={ability.energyIncrease} /></UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Turns:</UI.TKey>
            <UI.TVal>{ability.duration}</UI.TVal>
        </UI.TRow>
    </>
}
