import {
    Ability,
    Character,
    CharacterPath,
    ChargingAbilitySkipped,
    ChargingProcess,
    ChargingRuleSkipped,
    ChargingState,
    Event,
    Rule,
} from 'game/core'
import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'

export class AbilityPicked extends Event<{
    character: CharacterPath
    process: ChargingProcess
    last_charge: Nullable<ChargingState>
}> {

    static KEY = 'AbilityPicked' as const

    @Memoize()
    get process(): ChargingProcess {
        return this.state.process
    }

    @Memoize()
    get character(): Nullable<Character> {
        return Character.byPath(this.battle, this.state.character)
    }

    @Memoize()
    get ability(): Nullable<Ability> {
        if (!this.state.process.charging) return null
        return Ability.byPath(this.battle, this.state.process.charging.ability)
    }

    @Memoize()
    get previousAbility(): Nullable<Ability> {
        if (!this.state.last_charge) return null
        return Ability.byPath(this.battle, this.state.last_charge.ability)
    }

    @Memoize()
    get rule(): Nullable<Rule> {
        if (!this.state.process.charging) return null
        return Rule.byPath(this.battle, this.state.process.charging.rule)
    }

    @Memoize()
    get skippedAbilitiesInActiveRule(): Array<ChargingAbilitySkipped> {
        return this.state.process.current_sequence_skipped
    }

    @Memoize()
    get skippedRulesWhileFindingNewRule(): Array<ChargingRuleSkipped> {
        return this.state.process.rules_skipped
    }

    @Memoize()
    get skippedAbilitiesInNewRule(): Array<ChargingAbilitySkipped> {
        return this.state.process.abilities_skipped
    }

    @Memoize()
    get continuesSequence(): boolean {
        return this.process.current_sequence_continued
    }
}
