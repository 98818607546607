import { Prickly, PricklyConfig } from 'game/extended/creatures/tutorial/abilities/prickly/prickly'
import { SkillUI } from 'game/extended/uis/skills/SkillUI'
import React from 'react'

import image from './prickly.png'

export const prickly_ui: SkillUI<'Prickly'> = {
    model: Prickly,
    configs: [
        {}
    ],
    image,
    label: 'Prickly',
    renderDescription: (config: PricklyConfig) => <span>
        Whenever this creature is damaged with melee damage, the attacker is killed.
    </span>,
    renderTable: () => null,
    renderTableStatic: () => null
}
