import { Headgear, ItemRarity } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './ice_wizard_hood.png'

export const ice_wizard_hood: ItemUI<Headgear> = {
    image,
    label: 'Ice wizard hood',
    value: 100,
    item: {
        key: 'ice_wizard_hood',
        type: 'headgear',
        rarity: ItemRarity.Rare,
        stats: {
            intelligence: 50,
            agility: 20,
            resistance: 40,
            energy: 4
        }
    }
}
