import { Ability, AbilityConfig, AbilityPath } from 'game/core'
import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'

export type RushConfig = AbilityConfig & {
  abilities: number
}

export class Rush extends Ability<RushConfig> {

  static KEY = 'Rush' as const
  static FIXED_REQUIREMENTS = []

  @Memoize()
  get targets() {
    return this.character
  }

  @Memoize()
  get nrOfAbilities() {
    return this.currentConfig.abilities
  }

  @Memoize()
  get abilitiesToCast(): Array<AbilityPath> {
    const result: Array<AbilityPath> = []
    let lastAbility: Nullable<Ability> = this
    for (let i = 0; i < this.nrOfAbilities; i++) {
      lastAbility = lastAbility.chargingAbilityIgnoringThis
      if (!lastAbility) {
        break
      }
      result.push(lastAbility.path)
    }
    return result
  }

  // ----- Calculate new State ----- //
  performActionImpl() {
    return this.battle
      .performAll(Ability, this.abilitiesToCast, ab => ab.execute())
  }
}