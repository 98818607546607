import { Battle, CharDamaged, Effect, EffectDuration, Event, Interrupt, Stats } from 'game/core'
import { Memoize } from 'game/util/memoize'

export class AmbusherEffect extends Effect<{
    stacks: number
    increase: number
}>{
    static KEY = 'AmbusherEffect' as const

    @Memoize()
    get duration(): EffectDuration {
        return {
            type: 'unlimited'
        }
    }

    @Memoize()
    get stacks(): number {
        return this.state.stacks
    }

    @Memoize()
    get increase(): number {
        return this.state.increase
    }

    // ----- Interrupts ----- //
    reactToEvent(event: Event): Interrupt {
        if (!(event instanceof CharDamaged)) return false
        if (event.target !== this.target) return false
        return this.finalize()
    }

    // ----- Appliers ----- //
    getTargetStats(currentStats: Stats): Stats {
        return {
            ...currentStats,
            agility: Math.max(0, currentStats.agility + this.increase)
        }
    }

    addStack(increase: number): Battle {
        return this.update({
            stacks: this.stacks + 1,
            increase: this.increase + increase
        })
    }
}
