import { AbilityConfig, Environment } from 'game/core'
import { idle_ui } from 'game/extended/core/abilities/idle/idle_ui'
import { move_front_ui } from 'game/extended/core/abilities/move_front/move_front_ui'
import { move_support_ui } from 'game/extended/core/abilities/move_support/move_support_ui'
import { primary_weapon_attack_ui } from 'game/extended/core/abilities/primary_weapon_attack/primary_weapon_attack_ui'
import { punch_ui } from 'game/extended/core/abilities/punch/punch_ui'
import { rest_ui } from 'game/extended/core/abilities/rest/rest_ui'
import { secondary_weapon_attack_ui } from 'game/extended/core/abilities/secondary_weapon_attack/secondary_weapon_attack_ui'
import { body_check_ui } from 'game/extended/creatures/goblins/abilities/body_check/body_check_ui'
import { bomb_toss_ui } from 'game/extended/creatures/goblins/abilities/bomb_toss/bomb_toss_ui'
import { burp_ui } from 'game/extended/creatures/goblins/abilities/burp/burp_ui'
import { chain_heal_ui } from 'game/extended/creatures/goblins/abilities/chain_heal/chain_heal_ui'
import { chain_lightning_ui } from 'game/extended/creatures/goblins/abilities/chain_lightning/chain_lightning_ui'
import { charge_bomb_ui } from 'game/extended/creatures/goblins/abilities/charge_bomb/charge_bomb_ui'
import { consume_ui } from 'game/extended/creatures/goblins/abilities/consume/consume_ui'
import { cursed_weapons_ui } from 'game/extended/creatures/goblins/abilities/cursed_weapons/cursed_weapons_ui'
import { inspire_ui } from 'game/extended/creatures/goblins/abilities/inspire/inspire_ui'
import { intimidate_ui } from 'game/extended/creatures/goblins/abilities/intimidate/intimidate_ui'
import { magic_interrupt_ui } from 'game/extended/creatures/goblins/abilities/magic_interrupt/magic_interrupt_ui'
import { toss_ui } from 'game/extended/creatures/goblins/abilities/toss/toss_ui'
import { bats_ui } from 'game/extended/creatures/undead/abilities/bats/bats_ui'
import { bite_ui } from 'game/extended/creatures/undead/abilities/bite/bite_ui'
import { chaos_ui } from 'game/extended/creatures/undead/abilities/chaos/chaos_ui'
import { hit_and_run_ui } from 'game/extended/creatures/undead/abilities/hit_and_run/hit_and_run_ui'
import { lifedrain_ui } from 'game/extended/creatures/undead/abilities/lifedrain/lifedrain_ui'
import { seduce_ui } from 'game/extended/creatures/undead/abilities/seduce/seduce_ui'
import { shadow_strike_ui } from 'game/extended/creatures/undead/abilities/shadow_strike/shadow_strike_ui'
import { unholy_curse_ui } from 'game/extended/creatures/undead/abilities/unholy_curse/unholy_curse_ui'
import { dual_swing_ui } from 'game/extended/heros/barbarian/dual_swing/dual_swing_ui'
import { reckless_blow_ui } from 'game/extended/heros/barbarian/reckless_blow/reckless_blow_ui'
import { taunt_ui } from 'game/extended/heros/barbarian/taunt/taunt_ui'
import { undying_fury_ui } from 'game/extended/heros/barbarian/undying_fury/undying_fury_ui'
import { warcry_ui } from 'game/extended/heros/barbarian/warcry/warcry_ui'
import { whirlwind_ui } from 'game/extended/heros/barbarian/whirlwind/whirlwind_ui'
import { arcane_circle_ui } from 'game/extended/heros/mage/arcane_circle/arcane_circle_ui'
import { arcane_curse_ui } from 'game/extended/heros/mage/arcane_curse/arcane_curse_ui'
import { burning_weapon_ui } from 'game/extended/heros/mage/burning_weapon/burning_weapon_ui'
import { fireball_ui } from 'game/extended/heros/mage/fireball/fireball_ui'
import { immolation_ui } from 'game/extended/heros/mage/immolation/immolation_ui'
import { inferno_ui } from 'game/extended/heros/mage/inferno/inferno_ui'
import { cleansing_light_ui } from 'game/extended/heros/paladin/cleansing_light/cleansing_light_ui'
import { cure_ui } from 'game/extended/heros/paladin/cure/cure_ui'
import { holy_intervention_ui } from 'game/extended/heros/paladin/holy_intervention/holy_intervention_ui'
import { immunity_ui } from 'game/extended/heros/paladin/immunity/immunity_ui'
import { judgement_hammer_ui } from 'game/extended/heros/paladin/judgement_hammer/judgement_hammer_ui'
import { shield_push_ui } from 'game/extended/heros/paladin/shield_push/shield_push_ui'
import { explosive_arrow_ui } from 'game/extended/heros/ranger/explosive_arrow/explosive_arrow_ui'
import { poisoned_shot_ui } from 'game/extended/heros/ranger/poisoned_shot/poisoned_shot_ui'
import { pushback_arrow_ui } from 'game/extended/heros/ranger/pushback_arrow/pushback_arrow_ui'
import { rush_ui } from 'game/extended/heros/ranger/rush/rush_ui'
import { true_sight_ui } from 'game/extended/heros/ranger/true_sight/true_sight_ui'
import { volley_ui } from 'game/extended/heros/ranger/volley/volley_ui'
import { pushback_ui } from 'game/extended/creatures/tutorial/abilities/pushback/pushback_ui'
import { abilities, AbilityKey } from 'game/extended/types'
import { AbilityUI } from 'game/extended/uis/abilities/AbilityUI'
import { keysof } from 'game/util/keysof'

const ability_uis: {
    [K in AbilityKey]: AbilityUI<K>
} = {
    //Tutorial
    Pushback: pushback_ui,

    //Mage
    Fireball: fireball_ui,
    Inferno: inferno_ui,
    BurningWeapon: burning_weapon_ui,
    Immolation: immolation_ui,
    ArcaneCurse: arcane_curse_ui,
    ArcaneCircle: arcane_circle_ui,

    //Ranger
    PoisonedShot: poisoned_shot_ui,
    Volley: volley_ui,
    PushbackArrow: pushback_arrow_ui,
    TrueSight: true_sight_ui,
    ExplosiveArrow: explosive_arrow_ui,
    Rush: rush_ui,

    //Barbarian
    Whirlwind: whirlwind_ui,
    RecklessBlow: reckless_blow_ui,
    Warcry: warcry_ui,
    UndyingFury: undying_fury_ui,
    DualSwing: dual_swing_ui,
    Taunt: taunt_ui,

    //Paladin
    Cure: cure_ui,
    CleansingLight: cleansing_light_ui,
    Immunity: immunity_ui,
    HolyIntervention: holy_intervention_ui,
    JudgementHammer: judgement_hammer_ui,
    ShieldPush: shield_push_ui,

    //Goblins
    ChargeBomb: charge_bomb_ui,
    BombToss: bomb_toss_ui,
    ChainHeal: chain_heal_ui,
    Inspire: inspire_ui,
    Intimidate: intimidate_ui,
    BodyCheck: body_check_ui,
    Consume: consume_ui,
    Burp: burp_ui,
    MagicInterrupt: magic_interrupt_ui,
    CursedWeapons: cursed_weapons_ui,
    ChainLightning: chain_lightning_ui,
    Toss: toss_ui,

    //Undead
    Bite: bite_ui,
    Seduce: seduce_ui,
    ShadowStrike: shadow_strike_ui,
    Bats: bats_ui,
    Chaos: chaos_ui,
    HitAndRun: hit_and_run_ui,
    UnholyCurse: unholy_curse_ui,
    Lifedrain: lifedrain_ui,

    //General
    PrimaryWeaponAttack: primary_weapon_attack_ui,
    SecondaryWeaponAttack: secondary_weapon_attack_ui,
    Rest: rest_ui,
    Idle: idle_ui,
    MoveFront: move_front_ui,
    MoveSupport: move_support_ui,
    Punch: punch_ui
}

export function registerAbilities(env: Environment) {
    keysof(ability_uis).forEach(key => {
        const ui = getAbilityUI(key)
        env.registerModel(key, abilities[key] as any, ui.configs)
    })
}

export function getAbilityUI(key: AbilityKey): AbilityUI<any> {
    return ability_uis[key]
}

export function getAbilityConfigs(key: AbilityKey): Array<AbilityConfig> {
    const ui = getAbilityUI(key)
    return ui.configs
}