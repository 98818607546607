import { Percentage } from 'game/core'
import { ClassKey } from 'game/extended/types'
import { Memoize } from 'game/util/memoize'
import { ChapterKey, Difficulty } from 'models/campaign/ChapterModel'

export type CampaignResultState = {
  chapter: ChapterKey
  difficulty: Difficulty
  heroes: Array<{
    type: ClassKey,
    achievements: number,
    level: number
  }>,
  missions: Array<{
    stars: number,
    deaths: number,
    speed: Percentage
  }>
}

export class CampaignResultModel {

  constructor(readonly state: CampaignResultState) { }

  @Memoize()
  get difficulty() {
    return this.state.difficulty
  }

  @Memoize()
  get chapter() {
    return this.state.chapter
  }

  @Memoize()
  get score(): number {
    return this.starsScore + this.speedScore + this.achievementsScore
  }

  @Memoize()
  get missions() {
    return this.state.missions
  }

  @Memoize()
  get heroes() {
    return this.state.heroes
  }

  @Memoize()
  get stars(): number {
    return this.missions.reduce((res, mission) => res + (mission.stars || 0), 0)
  }

  @Memoize()
  get starsScore(): number {
    return this.stars * 50
  }

  @Memoize()
  get speed(): Percentage {
    if(this.missions.length === 0) return 0

    return Math.round(
      this.missions.reduce((res, mission) => res + (mission.speed || 0), 0) / this.missions.length
    )
  }

  @Memoize()
  get speedScore(): number {
    return Math.round(this.missions.length * 100 * (this.speed / 100))
  }

  @Memoize()
  get achievements(): number {
    return this.heroes.reduce((res, hero) => res + (hero.level || 0), 0)
  }

  @Memoize()
  get achievementsScore(): number {
    return this.achievements * 10
  }
}
