import { Taunt, TauntConfig } from 'game/extended/heros/barbarian/taunt/taunt'
import image from './taunt.png'
import { AbilityUI } from 'game/extended/uis/abilities/AbilityUI'
import React from 'react'

export const taunt_ui: AbilityUI<'Taunt'> = {
  model: Taunt,
  configs: [
    {
      available: 1,
      energy: 4,
      cooldown: 5,
      duration: 1
    },
    {
      available: 1,
      energy: 4,
      cooldown: 4,
      duration: 2
    },
    {
      available: 0,
      energy: 3,
      cooldown: 3,
      duration: 3
    }
  ],
  image,
  label: 'Taunt',
  renderTargets: () => 'Enemy support',
  renderDescription: () => <>
    Lure your target into the front line and render him immobilized for a period of time.
  </>,
  renderImpression: (ability: Taunt, UI) => <>
    <UI.Char character={ability.character} />{' '}triggers{' '}<UI.Char character={ability.targets} />{' '}
    with a blind rage, forcing {ability.targets?.isFemale ? 'her' : 'him'} into the front line.
  </>,
  renderTableStatic: (config: TauntConfig, UI) => <>
    <UI.TRow>
      <UI.TKey>Duration</UI.TKey>
      <UI.TVal><UI.Turns turns={config.duration} /></UI.TVal>
    </UI.TRow>
  </>,
  renderTable: (ability: Taunt, UI) => <>
    <UI.TRow>
      <UI.TKey>Duration</UI.TKey>
      <UI.TVal><UI.Turns turns={ability.duration} /></UI.TVal>
    </UI.TRow>
  </>
}
