import { RequiredRoom } from 'game/core'
import { RequirementUI } from 'game/extended/uis/requirements/RequirementUI'
import React from 'react'

export const required_room_ui: RequirementUI<'RequiredRoom'> = {
    model: RequiredRoom,
    label: 'Required room',
    renderDescription: (state, UI) => <>
        This ability can only be casted when there is a free
        spot in the {state.pos === 'front' ? 'front' : 'support'} line.
    </>
}
