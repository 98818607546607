import { Armor, ItemRarity } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './knight_armor.png'

export const knight_armor: ItemUI<Armor> = {
    image,
    label: 'Knight armor',
    value: 95,
    item: {
        key: 'knight_armor',
        type: 'armor',
        rarity: ItemRarity.Rare,
        stats: {
            strength: 20,
            agility: -10,
            armor: 20,
            shielding: 35
        }
    }
}
