import { Environment } from 'game/core'
import { alternating_ui } from 'game/extended/conditions/alternating/alternating_ui'
import { when_crowd_ui } from 'game/extended/conditions/when_crowd/when_crowd_ui'
import { when_energy_ui } from 'game/extended/conditions/when_energy/when_energy_ui'
import { when_hurt_ui } from 'game/extended/conditions/when_hurt/when_hurt_ui'
import { when_positioned_ui } from 'game/extended/conditions/when_positioned/when_positioned_ui'
import { when_sequence_ready_ui } from 'game/extended/conditions/when_sequence_ready/when_sequence_ready_ui'
import { when_turn_ui } from 'game/extended/conditions/when_turn/when_turn_ui'
import { ConditionKey, conditions } from 'game/extended/types'
import { keysof } from 'game/util/keysof'

import { ConditionUI } from './ConditionUI'

const condition_uis: {
    [K in ConditionKey]: ConditionUI<K>
} = {
    WhenHurt: when_hurt_ui,
    Alternating: alternating_ui,
    WhenPositioned: when_positioned_ui,
    WhenEnergy: when_energy_ui,
    WhenTurn: when_turn_ui,
    WhenCrowd: when_crowd_ui,
    WhenSequenceReady: when_sequence_ready_ui
}

export function registerConditions(env: Environment) {
    keysof(conditions).forEach(key => {
        env.registerModel(key, conditions[key] as any, {})
    })
}

export function getConditionUI(key: ConditionKey): ConditionUI<any> {
    return condition_uis[key]
}