import { Dueller, DuellerConfig } from 'game/extended/heros/paladin/dueller/dueller'
import { dueller_config } from 'game/extended/heros/paladin/dueller/dueller_config'
import { SkillUI } from 'game/extended/uis/skills/SkillUI'
import React from 'react'

import image from './dueller.png'

export const dueller_ui: SkillUI<'Dueller'> = {
    model: Dueller,
    configs: dueller_config,
    image,
    label: 'Dueller',
    renderDescription: (config: DuellerConfig) => <span>
        All damage dealt to you by your current melee target is reduced by a percentage.
    </span>,
    renderTableStatic: (config: DuellerConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Reduction:</UI.TKey>
            <UI.TVal>{config.damage_reduction}%</UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (skill: Dueller, UI) => <>
        <UI.TRow>
            <UI.TKey>Reduction:</UI.TKey>
            <UI.TVal>{skill.damageReduction}%</UI.TVal>
        </UI.TRow>
    </>
}
