import {
    MagicInterrupt,
    MagicInterruptConfig,
} from 'game/extended/creatures/goblins/abilities/magic_interrupt/magic_interrupt'
import { magic_interrupt_config } from 'game/extended/creatures/goblins/abilities/magic_interrupt/magic_interrupt_config'
import { AbilityUI } from 'game/extended/uis/abilities/AbilityUI'
import React from 'react'

import image from './magic_interrupt.png'

export const magic_interrupt_ui: AbilityUI<'MagicInterrupt'> = {
    model: MagicInterrupt,
    configs: magic_interrupt_config,
    image,
    label: 'Magic interrupt',
    renderTargets: () => 'Enemies',
    renderDescription: () => <>
        Applies a mystical barrier on all enemies. For the duration of this effect
        they are only able to perform regular abilities (resting, regular attacks & moving).
    </>,
    renderImpression: (ability: MagicInterrupt, UI) => <>
        Mystical waves are submitted towards{' '}<UI.Char character={ability.targets} />.
    </>,
    renderTableStatic: (config: MagicInterruptConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Duration</UI.TKey>
            <UI.TVal><UI.Turns turns={config.duration} /></UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (ability: MagicInterrupt, UI) => <>
        <UI.TRow>
            <UI.TKey>Turns:</UI.TKey>
            <UI.TVal>{ability.duration}</UI.TVal>
        </UI.TRow>
    </>
}
