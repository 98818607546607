import { DamageType } from 'game/core'
import { PoisonedShot, PoisonedShotConfig } from 'game/extended/heros/ranger/poisoned_shot/poisoned_shot'
import { poisoned_shot_config } from 'game/extended/heros/ranger/poisoned_shot/poisoned_shot_config'
import { AbilityUI } from 'game/extended/uis/abilities/AbilityUI'
import React from 'react'

import image from './poisoned_shot.png'

export const poisoned_shot_ui: AbilityUI<'PoisonedShot'> = {
    model: PoisonedShot,
    configs: poisoned_shot_config,
    image,
    label: 'Poisoned Shot',
    renderTargets: () => 'Ranged',
    renderDescription: () => <>
        Hit your enemy with a regular ranged attack, extended with a source of poison.
        The poison deals damage each time the target performs a turn.
    </>,
    renderImpression: (ability: PoisonedShot, UI) => <>
        <UI.Char character={ability.character} />{' '}fires a poisoned shot towards{' '}<UI.Char character={ability.targets} />.
    </>,
    renderTableStatic: (config: PoisonedShotConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Poison damage:</UI.TKey>
            <UI.TVal><UI.Damage type={DamageType.Magic} damage={config.poison_damage} /></UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Turns:</UI.TKey>
            <UI.TVal>{config.poison_duration}</UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (ability: PoisonedShot, UI) => <>
        <UI.TRow>
            <UI.TKey>Poison damage:</UI.TKey>
            <UI.TVal><UI.Damage damage={ability.poisonDamage} /></UI.TVal>
        </UI.TRow>,
        <UI.TRow>
            <UI.TKey>Poison damage:</UI.TKey>
            <UI.TVal><UI.Damage damage={ability.poisonDamage} /></UI.TVal>
        </UI.TRow>,
        <UI.TRow>
            <UI.TKey>Turns:</UI.TKey>
            <UI.TVal>{ability.poisonDuration}</UI.TVal>
        </UI.TRow>
    </>
}
