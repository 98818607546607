import { EffectKey, ModifierKey, SkillKey } from 'game/extended/types'
import { getEffectUI } from 'game/extended/uis/effects/effect_uis'
import { getSkillUI } from 'game/extended/uis/skills/skill_uis'
import { Nullable } from 'game/util/maybe'
import { UnreachableCaseError } from 'game/util/never'
import { TurnChar } from 'models/replay/TurnChar'
import React, { useState } from 'react'
import { Popuptab, PopuptabItems } from 'views/ingame/user/team/popuptab'
import { Sidebar, SidebarContent, SidebarTitle } from 'views/layout/sidebar'
import { EffectSide } from 'views/layout/sidebar/effectside'
import { SkillSide } from 'views/layout/sidebar/skillside'
import { VisualSubscript } from 'views/ui/visual'
import { EffectVisual } from 'views/ui/visuals/effectvisual'
import { SkillVisual } from 'views/ui/visuals/skillvisual'

export const CharModifiers: React.FC<{
    char: TurnChar
}> = ({ char }) => {
    const [selected, setSelected] = useState<Nullable<ModifierKey>>(null)

    return <Popuptab>
        <Sidebar hidden={!selected} onhide={() => setSelected(null)}>
            {selected && <CharModifiersSidebar
                char={char}
                modifier={selected}
            />}
        </Sidebar>
        {char.modifiers.length === 0 && 'No special modifiers apply to this character.'}
        <PopuptabItems>
            {char.char.effects.map(effect => {
                const key = effect.key as EffectKey
                return <EffectVisual
                    effect={key}
                    key={key}
                    onClick={() => {
                        setSelected({ type: 'effect', effect: key })
                    }}
                />
            })}
            {char.char.type.skills.map(skill => {
                const key = skill.key as SkillKey
                return <SkillVisual
                    skill={key}
                    key={key}
                    visualMod={{ bw: skill.level === 0 }}
                    onClick={() => {
                        setSelected({ type: 'skill', skill: key })
                    }}
                >
                    <VisualSubscript>
                        Lvl {skill.level}
                    </VisualSubscript>
                </SkillVisual>
            })}
        </PopuptabItems>
    </Popuptab>
}

export const CharModifiersSidebar: React.FC<{
    char: TurnChar
    modifier: ModifierKey
}> = ({ char, modifier }) => {

    switch (modifier.type) {
        case 'effect':
            const effect = char.char.effects.find(e => e.key === modifier.effect)
            if (!effect) return null
            const effectUI = getEffectUI(modifier.effect)
            return <>
                <SidebarTitle>
                    {effectUI.label}
                </SidebarTitle>
                <SidebarContent>
                    <EffectSide
                        effect={effect}
                    />
                </SidebarContent>
            </>
        case 'skill':
            const skill = char.char.type.skills.find(skill =>
                skill.key === modifier.skill
            )
            if (!skill) return null
            const skillUI = getSkillUI(modifier.skill)
            return <>
                <SidebarTitle>
                    {skillUI.label}
                </SidebarTitle>
                <SidebarContent>
                    <SkillSide
                        skill={skill}
                        env={skill.env}
                        hideLevels={true}
                        level={skill.level}
                    />
                </SidebarContent>
            </>
        default:
            throw new UnreachableCaseError(modifier)
    }
}
