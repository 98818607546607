import {
    Battle,
    Character,
    CharacterInit,
    CharacterState,
    ChildGameModel,
    GameModel,
    ModelID,
    Side,
    SidePath,
    TypeState,
} from 'game/core'
import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'
import objectMap from 'game/util/objectMap'

export type LinePosition = 'front' | 'support'

export interface LinePath extends SidePath {
    line: LinePosition
}

export interface LineState {
    position: LinePosition
    characters: Record<string, CharacterState>
}


export class Line extends ChildGameModel<Side, LineState> {

    static byPath(battle: Battle, path: LinePath): Line {
        return Side.byPath(battle, path)[path.line]
    }

    public static initialState(position: LinePosition): LineState {
        return {
            position: position,
            characters: {}
        }
    }

    @Memoize()
    get side(): Side {
        return this.parent
    }

    @Memoize()
    get path(): LinePath {
        return { ...this.side.path, line: this.position }
    }

    @Memoize()
    get directChildren(): Array<GameModel> {
        return [...this.characters]
    }

    @Memoize()
    get position(): LinePosition {
        return this.state.position
    }

    @Memoize()
    get top(): Nullable<Character> {
        return this.characters[0]
    }

    @Memoize()
    get bottom(): Nullable<Character> {
        return this.characters[this.characters.length - 1]
    }

    @Memoize()
    get characters(): Array<Character> {
        return objectMap(this.state.characters, ch => new Character(this, ch))
    }

    @Memoize()
    get isFull(): boolean {
        return this.characters.length === 4
    }

    @Memoize()
    get isEmpty(): boolean {
        return this.characters.length === 0
    }

    getNeighbours(index: number): Array<Character> {
        const result: Array<Character> = []
        if (index > 0) result.push(this.characters[index - 1])
        if (index < this.characters.length - 1) result.push(this.characters[index + 1])
        return result
    }

    getMeleeTarget(pos: number): Character | null {
        if (this.position === 'support') return null
        return this.getFrontTarget(pos)
    }

    getRangedTarget(pos: number): Character | null {
        switch (this.position) {
            case 'front': return this.getSupportTarget(pos)
            case 'support': return this.getFrontTarget(pos)
        }
    }

    getFrontTarget(pos: number): Character | null {
        return this.side.enemySide.front.getClosestCharacter(pos)
    }

    getSupportTarget(pos: number): Character | null {
        return this.side.enemySide.support.getClosestCharacter(pos)
    }

    getFrontAlly(pos: number): Character | null {
        switch (this.position) {
            case 'front': return null
            case 'support': return this.side.front.getClosestCharacter(pos)
        }
    }

    getBehindAlly(pos: number): Character | null {
        switch (this.position) {
            case 'front': return this.side.support.getClosestCharacter(pos)
            case 'support': return null
        }
    }

    getClosestCharacter(pos: number): Character | null {
        if (this.characters.length === 0) return null
        return this.characters.reduce(
            (prev: Character, current: Character) => {
                const prevDist = Math.abs(prev.pos - pos)
                const currentDist = Math.abs(current.pos - pos)
                return prevDist <= currentDist ? prev : current
            }
        )
    }

    // ----- Calculate new State ----- //
    createCharacter(suggestedName: string, type: TypeState, init: CharacterInit = {}): Battle {
        const prefixes = ['2nd', '3rd', '4th', '5th', '6th', '7th', '8th']
        let name = suggestedName
        prefixes.forEach(prefix => {
            if (!this.characters.find(char => char.name === name)) return
            name = `${prefix} ${suggestedName}`
        })

        const { pos = 0, equipment, owner } = init

        return this.battle
            .upCounter()
            .perform(Line, this.path, line => {
                return line.moveCharacter({
                    id: 'C' + line.battle.counter,
                    gender: init.gender || 'male',
                    name,
                    next_turn: this.time,
                    hp: 'full' as const,
                    energy: 'full' as const,
                    equipment,
                    type,
                    effects: {},
                    owner
                }, pos)
            })
    }

    removeCharacter(charToRemove: Character): Battle {
        const newChars: Record<ModelID, CharacterState> = {}
        this.characters
            .filter(char => char !== charToRemove)
            .forEach(char => newChars[char.id] = char.state)

        return this.side.updateLine({
            ...this.state,
            characters: newChars
        })
    }

    updateCharacter(state: CharacterState): Battle {
        return this.side.updateLine({
            ...this.state,
            characters: {
                ...this.state.characters,
                [state.id]: state
            }
        })
    }

    moveCharacter(state: CharacterState, pos: number = 0): Battle {
        const newChars: Record<ModelID, CharacterState> = {}
        this.characters
            .filter(char => char.pos < pos)
            .forEach(char => newChars[char.id] = char.state)
        newChars[state.id] = state
        this.characters
            .filter(char => char.pos >= pos)
            .forEach(char => newChars[char.id] = char.state)

        return this.side.updateLine({
            ...this.state,
            characters: newChars
        })
    }
}
