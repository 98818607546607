import { EffectTriggered } from 'game/core'
import { EventUI } from 'game/extended/uis/events/EventUI'
import React from 'react'

export const effect_triggered_ui: EventUI<'EffectTriggered'> = {
    model: EffectTriggered,
    render: (event: EffectTriggered, UI) => {
        return <>
            <UI.Effect effect={event.effect || event.state.effect.effect.key} />
            {` was triggered onto `}
            <UI.Char character={event.character} />
        </>
    }
}
