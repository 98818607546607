import { ModelID } from 'game/core'
import { ChapterKey, MissionKey } from 'models/campaign/ChapterModel'
import React, { useState } from 'react'
import { useParams } from 'react-router'
import { useEndless } from 'views/ingame/endless/Endless'
import { useLinker } from 'views/link'
import bgSrc from 'data/campaign/lava/laval_background.png'
import { Replay, ReplayContext, ReplayContextPopupCharTab } from 'views/replay/replay'

export type MissionLocation = {
  chapter: ChapterKey,
  mission: MissionKey,
  round?: string,
  turn?: string,
  char?: ModelID,
  tab?: ReplayContextPopupCharTab
}

export const EndlessReplay: React.FC = (params) => {
  const { endless } = useEndless()
  const { goTo } = useLinker()
  const [logs, setLogs] = useState(false)
  const {
    round: roundKey,
    turn: turnKey,
    char: charID,
    tab
  } = useParams<MissionLocation>()
  if (!endless.lastReplay) return null
  const replay = endless.lastReplay

  const round = replay.getRoundByKey(roundKey)
  const turn = round.getTurnByKey(turnKey)
  const char = turn.chars.find(char => char.id === charID)
  const popup = (char && tab) ? { char, tab: tab as ReplayContextPopupCharTab } : null

  if (!turn) return null
  return <ReplayContext.Provider value={{
    background: bgSrc,
    turn: turn,
    logs: logs,
    popup: popup,
    onClose: () => {
      if(endless.isFinished) goTo('/finished')
      else goTo(replay.isVictorious ? `/prev` : `/next`)
    },
    setLogs: setLogs,
    setTurn: (turn) => {
      goTo(`/replay/${turn.round.key}/${turn.key}`)
    },
    setPopup: (state) => {
      if (!state) {
        goTo(`/replay/${turn.round.key}/${turn.key}`)
      } else {
        goTo(`/replay/${turn.round.key}/${turn.key}/${state.char.id}/${state.tab}`)
      }
    }
  }}>
    <Replay />
  </ReplayContext.Provider>
}
