import 'views/ingame/user/team/achievements/achievement.scss'

import { AchievementModel } from 'models/user/hero/achievements/Achievement'
import React from 'react'
import { achievement_uis } from 'data/user/achievements/achievements'
import { ItemstatsStat } from 'views/ingame/user/team/itemstats'
import { Icon } from 'views/ui/icon'
import { styler } from 'views/layout/util/style'

const style = styler('achievement', ['info', 'levels', 'rewards'])

export const Achievement: React.FC<{
    achievement: AchievementModel
}> = ({ achievement }) => {

    const rewardPieces = []
    if (achievement.rewards.length <= 2) {
        rewardPieces.push(achievement.rewards)
    } else {
        const halfway = Math.round(achievement.rewards.length / 2)
        rewardPieces.push(achievement.rewards.slice(0, halfway))
        rewardPieces.push(achievement.rewards.slice(halfway))
    }

    const ui = achievement_uis[achievement.key]
    return <div className={style().is({ active: achievement.level > 0 })}>
        <div className={style('icon')}>
            <Icon icon={ui.icon} />
        </div>
        <div className={style('info')}>
            <AchievementInfo achievement={achievement} />
        </div>
        <div className={style('levels')}>
            <AchievementLevels achievement={achievement} />
        </div>
    </div>
}

export const AchievementInfo: React.FC<{
    achievement: AchievementModel
}> = ({ achievement }) => {

    const ui = achievement_uis[achievement.key]
    return <div className={style.info()}>
        <div className={style.info('top')}>
            <span className={style.info('label')}>
                {ui.label}
            </span>
        </div>
        <div className={style.info('bot')}>
            (<AchievementRewards achievement={achievement} />) x{achievement.level}
        </div>
    </div>
}

export const AchievementRewards: React.FC<{
    achievement: AchievementModel
}> = ({ achievement }) => {

    return <div className={style.rewards()}>
        {achievement.rewards.map(stat =>
            <ItemstatsStat key={stat.key} stat={stat.key} value={stat.value} />
        )}
    </div>
}

export const AchievementLevels: React.FC<{
    achievement: AchievementModel
}> = ({ achievement }) => {
    return <div className={style.levels()}>
        {achievement.goals.map(goal => {
            const active = goal.isActive
            const finished = goal.isReached && !goal.isActive
            return <div className={style.levels('item').is({ active, finished })} key={goal.index}>
                <div className={style.levels('item-circle')} >
                    <div
                        className={style.levels('item-circle-fill')}
                        style={{ width: `${goal.percentage}%` }}
                    />
                </div>
                {active && <div className={style.levels('item-status')}>
                    {Math.floor(achievement.progress)}/{goal.amount}
                </div>}
            </div>
        })}
    </div>
}
