import { AttackRange, DamageType, ItemRarity, Weapon } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './skeleton_bow.png'

export const skeleton_bow: ItemUI<Weapon> = {
  image,
  label: 'Skeleton bow',
  value: 50,
  item: {
    key: 'skeleton_bow',
    type: 'weapon',
    rarity: ItemRarity.Rare,
    stats: {
      energy: 4
    },
    two_handed: true,
    category: 'bow',
    damageType: DamageType.Ranged,
    range: AttackRange.Ranged,
    energy: 4,
    damage: 11
  }
}
