import { AttackRange, DamageType, ItemRarity, Weapon } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './unholy_wand.png'

export const unholy_wand: ItemUI<Weapon> = {
  image,
  label: 'Unholy wand',
  value: 80,
  item: {
    key: 'unholy_wand',
    type: 'weapon',
    rarity: ItemRarity.Epic,
    stats: {
      intelligence: 25,
      shielding: -20,
      energy: -5
    },
    two_handed: true,
    category: 'wand',
    damageType: DamageType.Magic,
    range: AttackRange.Mixed,
    energy: 3,
    damage: 9
  }
}
