import { DamageType } from 'game/core'
import { Fireball, FireballConfig } from 'game/extended/heros/mage/fireball/fireball'
import image from 'game/extended/heros/mage/fireball/fireball.png'
import { fireball_config } from 'game/extended/heros/mage/fireball/fireball_config'
import { AbilityUI } from 'game/extended/uis/abilities/AbilityUI'
import React from 'react'

export const fireball_ui: AbilityUI<'Fireball'> = {
    model: Fireball,
    configs: fireball_config,
    image,
    label: 'Fireball',
    renderTargets: (conf: FireballConfig) => conf.splash ? 'Front + Support' : 'Front',
    renderDescription: () => <>
        Deal magic damage by shotting a ball of fire towards the target.
    </>,
    renderImpression: (ability: Fireball, UI) => <>
        A bright streak flashes from{' '}<UI.Char character={ability.character} />{' '}{ability.character.isMale ? 'his' : 'her'}
        pointing finger towards{' '}<UI.Char character={ability.targets} />{' '}causing an explosion of flames upon impact.
    </>,
    renderTableStatic: (conf: FireballConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Damage:</UI.TKey>
            <UI.TVal>
                <UI.Damage damage={conf.damage} type={DamageType.Magic} />
            </UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (ability: Fireball, UI) => <>
        <UI.TRow>
            <UI.TKey>Damage:</UI.TKey>
            <UI.TVal><UI.Damage damage={ability.damage} /></UI.TVal>
        </UI.TRow>
    </>,
}
