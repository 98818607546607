import full from 'game/extended/creatures/goblins/goblin_chief/goblin_chief.svg'
import circle from 'game/extended/creatures/goblins/goblin_chief/goblin_chief_circle.svg'
import head from 'game/extended/creatures/goblins/goblin_chief/goblin_chief_head.svg'
import { CreatureUI } from 'game/extended/uis/types/creature_uis'

export const goblin_chief: CreatureUI = {
    label: 'Goblin chief',
    definition: lvl => ({
        stats: {
            health: 130 + lvl * 20,
            energy: 23 + lvl * 2,
            intelligence: 40 + lvl * 5,
            strength: 75 + lvl * 15,
            agility: 90 + lvl * 10,
            armor: 35,
            shielding: 40,
            resistance: 35
        },
        equipment: {
            headgear: 'skull_helmet',
            primary: 'goblin_chief_club',
            boots: 'goblin_boots'
        },
        strategy: [
            {
                abilities: ['PrimaryWeaponAttack'],
                conditions: [{ key: 'WhenEnergy', direction: 'below', amount: 9 }]
            },
            'Inspire',
            'CursedWeapons',
            'PrimaryWeaponAttack',
            'MoveFront'
        ]
    }),
    full,
    circle: {
        src: circle,
        width: 200,
        height: 150
    },
    head
}
