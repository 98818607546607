import { BodyCheck, BodyCheckConfig } from 'game/extended/creatures/goblins/abilities/body_check/body_check'
import { body_check_config } from 'game/extended/creatures/goblins/abilities/body_check/body_check_config'
import { AbilityUI } from 'game/extended/uis/abilities/AbilityUI'
import React from 'react'

import image from './body_check.png'

export const body_check_ui: AbilityUI<'BodyCheck'> = {
    model: BodyCheck,
    configs: body_check_config,
    image,
    label: 'Body check',
    renderTargets: (config: BodyCheckConfig) => `Melee`,
    renderDescription: () => <>
        Damage your target with a percentage of your current hp and propel it backwards.
    </>,
    renderImpression: (ability: BodyCheck, UI) => <>
        <UI.Char character={ability.character} />{' '}throws {ability.character?.isMale ? 'himself' : 'herself'} at
        {' '}<UI.Char character={ability.targets} />, catapulting
        {ability.targets?.isMale ? ' him' : ' her'} away.
    </>,
    renderTableStatic: (config: BodyCheckConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Percentage:</UI.TKey>
            <UI.TVal>{config.percentage}%</UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (ability: BodyCheck, UI) => <>
        <UI.TRow>
            <UI.TKey>Percentage:</UI.TKey>
            <UI.TVal><UI.Damage damage={ability.damage} /></UI.TVal>
        </UI.TRow>
    </>
}
