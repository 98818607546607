import { Battle, CharHealed, Event, Interrupt, Skill, SkillConfig } from 'game/core'
import { UnitedEffect } from 'game/extended/heros/paladin/united/united_effect'
import { Memoize } from 'game/util/memoize'

export interface UnitedConfig extends SkillConfig {
    increase: number
}

export class United extends Skill<{}, UnitedConfig> {

    static KEY = 'United' as const

    @Memoize()
    get increase(): number {
        return this.currentConfig.increase
    }

    // ----- Interrupts ----- //
    reactToEvent(event: Event): Interrupt {
        if (!(event instanceof CharHealed)) return false
        if (!event.target) return false
        if (event.target.side !== this.character.side) return false
        return this.addStack()
    }

    // ----- Calculate new State ----- //
    addStack(): Battle {
        const effect = this.character.findEffect(UnitedEffect)

        if (!effect) {
            return this.character.addEffect(UnitedEffect, {
                stacks: 1,
                increase: this.increase,
                origin: this.path
            })
        }
        return effect.addStack(this.increase)
    }
}
