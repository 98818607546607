import './icon.scss'

import React, { FunctionComponent, SVGProps } from 'react'
import * as icons from 'views/ui/icons'
import { styler } from 'views/layout/util/style'

const style = styler('icon')

export type IconKey = keyof typeof icons

export const Icon: React.FC<{
    className?: string,
    onClick?: () => void,
    reverse?: boolean,
    style?: any,
    icon: IconKey | FunctionComponent<SVGProps<SVGSVGElement>>
}> = ({ icon, reverse, onClick, ...rest }) => {

    const Icon = typeof icon === 'string' ? icons[icon] : icon
    if (!Icon) {
        console.warn(`Missing icon: ${icon}`)
        return <div>Missing icon: {icon}</div>
    }
    return <Icon {...rest}
        className={style().mergeProps(rest).mod({ reverse })}
        onClick={onClick}
    />
}
