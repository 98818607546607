import { Toss, TossConfig } from 'game/extended/creatures/goblins/abilities/toss/toss'
import { AbilityUI } from 'game/extended/uis/abilities/AbilityUI'
import React from 'react'

import image from './toss.png'

export const toss_ui: AbilityUI<'Toss'> = {
  model: Toss,
  configs: [
    {
      available: 2,
      cooldown: 5,
      energy: 6,
      percentage: 20
    }
  ],
  image,
  label: 'Toss',
  renderTargets: () => 'Melee',
  renderDescription: () => <>
    Tosses the target to the supportline (onto the ally standing behind).
    Both characters are damaged and stunned.
    Damage is a percentage of the maxhp of the tossed character.
  </>,
  renderImpression: (ability: Toss, UI) => <>
    <UI.Char character={ability.character} />{' '}grabs{' '}<UI.Char character={ability.targets} />{' '}
    and flicks {ability.targets?.isFemale ? 'her' : 'him'}
    {ability.targets?.behindAlly && <>
      {' '}onto{' '}<UI.Char character={ability.targets?.behindAlly} />
    </>}.
  </>,
  renderTableStatic: (config: TossConfig, UI) => <>
    <UI.TRow>
      <UI.TKey>Percentage of maxhp:</UI.TKey>
      <UI.TVal>{config.percentage}%</UI.TVal>
    </UI.TRow>
  </>,
  renderTable: (ability: Toss, UI) => <>
    <UI.TRow>
      <UI.TKey>Damage:</UI.TKey>
      <UI.TVal><UI.Damage damage={ability.damage} /></UI.TVal>
    </UI.TRow>
  </>
}
