import { ShieldPush, ShieldPushConfig } from 'game/extended/heros/paladin/shield_push/shield_push'
import { AbilityUI } from 'game/extended/uis/abilities/AbilityUI'
import React from 'react'

import image from './shield_push.png'

export const shield_push_ui: AbilityUI<'ShieldPush'> = {
  model: ShieldPush,
  configs: [
    {
      available: 2,
      cooldown: 5,
      energy: 5,
      percentage: 30
    },
    {
      available: 1,
      cooldown: 4,
      energy: 5,
      percentage: 50
    },
    {
      available: 0,
      cooldown: 4,
      energy: 4,
      percentage: 70
    }
  ],
  image,
  label: 'Shield push',
  renderTargets: () => 'Melee',
  renderDescription: () => <>
    Hit your target and push it to the support line. The damage dealt is a percentage
    of your total armor.
  </>,
  renderImpression: (ability: ShieldPush, UI) => <>
    <UI.Char character={ability.character} /> lashes out with his massive shield,
    knocking{' '}<UI.Char character={ability.targets} />{' '}back.
  </>,
  renderTableStatic: (config: ShieldPushConfig, UI) => <>
    <UI.TRow>
      <UI.TKey>Armor percentage:</UI.TKey>
      <UI.TVal>{config.percentage}%</UI.TVal>
    </UI.TRow>
  </>,
  renderTable: (ability: ShieldPush, UI) => <>
    <UI.TRow>
      <UI.TKey>Armor percentage:</UI.TKey>
      <UI.TVal>{ability.percentage}%</UI.TVal>
    </UI.TRow>
    <UI.TRow>
      <UI.TKey>Total damage:</UI.TKey>
      <UI.TVal><UI.Damage damage={ability.damage} /></UI.TVal>
    </UI.TRow>
  </>
}
