import { TrueShotsConfig } from 'game/extended/heros/ranger/true_shots/true_shots'

export const true_shots_config: Array<TrueShotsConfig> = [
    {
        damage: 3
    },
    {
        damage: 6
    },
    {
        damage: 10
    }
]