import { Headgear, ItemRarity } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './jester_hat.png'

export const jester_hat: ItemUI<Headgear> = {
    image,
    label: 'Jester hat',
    value: 40,
    item: {
        key: 'jester_hat',
        type: 'headgear',
        rarity: ItemRarity.Common,
        stats: {
            intelligence: -30,
            health: -30,
            energy: 10
        }
    }
}
