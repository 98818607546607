import { ChainHealConfig } from 'game/extended/creatures/goblins/abilities/chain_heal/chain_heal'

export const chain_heal_config: Array<ChainHealConfig> = [
    {
        available: 2,
        cooldown: 5,
        energy: 4,
        targets: 3,
        healing: 7,
    }
]