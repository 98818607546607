import { Rampage, RampageConfig } from 'game/extended/heros/barbarian/rampage/rampage'
import { rampage_config } from 'game/extended/heros/barbarian/rampage/rampage_config'
import { SkillUI } from 'game/extended/uis/skills/SkillUI'
import React from 'react'

import image from './rampage.png'

export const rampage_ui: SkillUI<'Rampage'> = {
    model: Rampage,
    configs: rampage_config,
    image,
    label: 'Rampage',
    renderDescription: () => <>
        Restore a large amount of health and energy whenever you kill an enemy
    </>,
    renderTableStatic: (config: RampageConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Restored</UI.TKey>
            <UI.TVal><UI.Stat stat="health" amount={config.hp} percentage /></UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Energy restored</UI.TKey>
            <UI.TVal><UI.Stat stat="energy" amount={config.energy} /></UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (skill: Rampage, UI) => <>
        <UI.TRow>
            <UI.TKey>Restored</UI.TKey>
            <UI.TVal><UI.Stat stat="health" amount={skill.hpRegenerated} /></UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Energy restored</UI.TKey>
            <UI.TVal><UI.Stat stat="energy" amount={skill.energyIncrease} /></UI.TVal>
        </UI.TRow>
    </>
}
