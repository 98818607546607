import './about.scss'

import { ADS_EFFECT_DURATION, useAds } from 'ads/ads'
import { useApp } from 'AppContext'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Popup, PopupContent, PopupFullHeader } from 'views/layout/popup'
import { Bg } from 'views/layout/util/bg'
import { styler } from 'views/layout/util/style'
import { Icon } from 'views/ui/icon'

import bgImage from './../splash_goblins.png'

const style = styler('about', ['consent', 'ads', 'codepopup'])

export const About: React.FC = () => {
    const { openPopup } = useAds()
    const [codePopupIsOpen, setCodePopupIsOpen] = useState(false)
    const [logPopupIsOpen, setLogPopupIsOpen] = useState(false)

    return <div className={style()}>
        <Link className={style('close')} to="/">
            <Icon icon="close" />
        </Link>
        <Bg image={bgImage} />
        <div className={style('overlay')} />
        <div className={style('menu')}>
            <div>
                This project is an indie game project by a Belgian webdeveloper. I hope to:
                <ul>
                    <li>Add more hero's & campaigns</li>
                    <li>Perform bugfixing and balancing</li>
                    <li>Start a spin-off, multiplayer project to battle against other players in a league format.</li>
                </ul>
            </div>
            <AboutAds />
            <AboutConsent />
            <div className={style('show')}>
                <span
                    onClick={() => setCodePopupIsOpen(true)}
                >
                    <u>Fill code to disable ads</u>
                </span>{'\u00A0'}{'\u00A0'}{'\u00A0'}|{'\u00A0'}{'\u00A0'}{'\u00A0'}<span
                    onClick={openPopup}
                >
                    <u>Open popup</u>
                </span>{'\u00A0'}{'\u00A0'}{'\u00A0'}|{'\u00A0'}{'\u00A0'}{'\u00A0'}<span
                    onClick={() => setLogPopupIsOpen(true)}
                >
                    <u>Send bug report</u>
                </span>
            </div>
            {codePopupIsOpen && <AboutCodePopup
                close={() => setCodePopupIsOpen(false)}
            />}
            {logPopupIsOpen && <AboutReportPopup
                close={() => setLogPopupIsOpen(false)}
            />}
        </div>
    </div >
}

export const AboutConsent: React.FC = () => {
    const { adsConsent, setAdsConsent } = useApp()

    return <div className={style.consent()}>
        <label className={style.consent('checkbox')}>
            <input
                type="checkbox"
                name="consent"
                checked={adsConsent}
                onChange={(e) => setAdsConsent(!!e.target.checked)}
            />
            Explicitly agree to terms and policies stated below to enable reward ads.
        </label>
        <p>
            <br />
            <a href="https://www.battlecoach.app/terms-and-conditions.html" target="_blank" rel="noopener noreferrer">Terms & conditions</a>
            {' - '}
            <a href="https://www.battlecoach.app/terms-and-conditions.html" target="_blank" rel="noopener noreferrer">privacy policy</a>
        </p>
        <p style={{ fontSize: "0.7em" }}>
            By playing this game I assume you agree to the terms and policies stated above.
            Please uninstall this game if you do not agree.
            That said, Google AdMob will currently only be loaded and used when you explicitly agree to this by using the checkbox above.
        </p>
    </div >
}

const AboutAds: React.FC = () => {
    const { adsCode, adsConsent } = useApp()

    const status = adsConsent ? 'enabled' : 'disabled'
    const icon = adsConsent ? 'checkmark' : 'interdiction'
    const color = adsConsent ? 'green' : 'red'

    if (adsCode) {
        return <div className={style.ads()}>
            The code you entered permanently disabled ads for you.
        </div>
    }

    return <div className={style.ads()}>
        <p className={style.ads('status')}>
            Currently reward-ads are <b>{status}</b>.
        </p>
        <div className={style.ads('boxes')}>
            <div className={style.ads('boxes-item')}>
                <Icon icon={icon} style={{ color }} /> Trigger an ad to gain unlimited replays for {ADS_EFFECT_DURATION} minutes in campaign mode.
            </div>
            <div className={style.ads('boxes-item')}>
                <Icon icon={icon} style={{ color }} /> Trigger an ad to gain double lives for {ADS_EFFECT_DURATION} minutes in endless mode.
            </div>
        </div>
    </div>
}

const AboutCodePopup: React.FC<{
    close: () => void
}> = ({ close }) => {
    const { adsCode, setAdsCode } = useApp()

    return <Popup onClose={close}>
        <PopupFullHeader>
            Fill a code to disable ads permanently
        </PopupFullHeader>
        <PopupContent>
            <div className={style.codepopup()}>
                {!adsCode && <form onSubmit={(e) => {
                    e.preventDefault()
                    console.log(e.target)
                    const formData = new FormData(e.target as HTMLFormElement);
                    const code = formData.get('code') as string
                    fetch(`https://www.battlecoach.app/api/validate-code?code=${code}`)
                        .then(res => res.json()).then((response: boolean) => {
                            if (response) {
                                setAdsCode(code as string)
                                alert('Success!')
                                close()
                            } else {
                                alert('Invalid code entered')
                            }
                        })
                }}>
                    <input placeholder="Fill code" className={style.codepopup('input')} id="code" name="code" />
                    <br />
                    <br />
                    <input type="submit" value="Submit" />
                </form>}
                {adsCode && <input
                    type="submit"
                    value="Retract code"
                    onClick={() => {
                        setAdsCode('')
                    }}
                />}
            </div>
        </PopupContent>
    </Popup >
}


const AboutReportPopup: React.FC<{
    close: () => void
}> = ({ close }) => {

    return <Popup onClose={close}>
        <PopupFullHeader>
            Fill your data
        </PopupFullHeader>
        <PopupContent>
            <div className={style.codepopup()}>
                <form onSubmit={(e) => {
                    e.preventDefault()

                    const state = {
                        endless: JSON.parse(localStorage.endless || null),
                        campaign: JSON.parse(localStorage.campaign || null)
                    }
                    const data: Record<string, string> = {
                        email: (document.getElementById('email') as any).value,
                        comment: (document.getElementById('comment') as any).value,
                        key: 'l'+Math.ceil(Math.random() * 100000),
                        state: JSON.stringify(state)
                    };
                    
                    const formBodyPieces: string[] = [];
                    for (var property in data) {
                      var encodedKey = encodeURIComponent(property);
                      var encodedValue = encodeURIComponent(data[property]);
                      formBodyPieces.push(encodedKey + "=" + encodedValue);
                    }
                    const formBody = formBodyPieces.join("&");
                    
                    fetch(`https://www.battlecoach.app/api/logs/post`, {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                      },
                      body: formBody
                    }).then(() => {
                        close()
                    })
                }}>
                    <p>
                        Any reports send will have your game state attached. If you get stuck during an endless
                        level or campign, please send a report BEFORE you restart your campaign or endless run.
                        That way I can easily reproduce your last fight locally.
                    </p>
                    <br />
                    <input placeholder="Email" className={style.codepopup('input')}  id="email" name="email" />
                    <br />
                    <input placeholder="Message" className={style.codepopup('input')} id="comment" name="comment" />
                    <br />
                    <br />
                    <input type="submit" value="Submit" />
                </form>
            </div>
        </PopupContent>
    </Popup >
}