import { AttackRange, DamageType, ItemRarity, Weapon } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './goblin_bow.png'

export const dragon_bow: ItemUI<Weapon> = {
    image,
    label: 'Dragon bow',
    value: 250,
    item: {
        key: 'dragon_bow',
        type: 'weapon',
        rarity: ItemRarity.Legendary,
        stats: {
            agility: 75,
            intelligence: 75,
            health: 50,
            energy: 5
        },
        two_handed: true,
        category: 'bow',
        damageType: DamageType.Ranged,
        range: AttackRange.Ranged,
        energy: 4,
        damage: 19
    }
}
