import { Damage, Effect, EffectDuration } from 'game/core'
import { Memoize } from 'game/util/memoize'

export class TrueSightEffect extends Effect {

    static KEY = 'TrueSightEffect' as const

    @Memoize()
    get duration(): EffectDuration {
        return {
            type: 'turns',
            turns: 1
        }
    }

    // ----- Appliers ----- //
    applyDamageModifier(damage: Damage): Damage {
        return {
            ...damage,
            true_damage: true
        }
    }
}
