import { Boots, ItemRarity } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './blazewing_boots.png'

export const blazewing_boots: ItemUI<Boots> = {
    image,
    label: 'Blazewing boots',
    value: 25,
    item: {
        key: 'blazewing_boots',
        type: 'boots',
        rarity: ItemRarity.Rare,
        stats: {
            agility: 35,
            energy: 3
        }
    }
}
