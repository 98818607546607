import { Battle, Event, Interrupt, Skill, SkillConfig, SkillTriggered, TurnEnded, Turns } from 'game/core'
import { ArcaneGrowthEffect } from 'game/extended/heros/mage/arcane_growth/arcane_growth_effect'
import { Memoize } from 'game/util/memoize'

export interface ArcaneGrowthConfig extends SkillConfig {
    increment: number,
    interval: Turns
}

export type ArcaneGrowthState = {
    turnsPassed?: number
}

export class ArcaneGrowth extends Skill<ArcaneGrowthState, ArcaneGrowthConfig> {

    static KEY = 'ArcaneGrowth' as const

    @Memoize()
    get increment(): number {
        return this.currentConfig.increment
    }

    @Memoize()
    get interval(): Turns {
        return this.currentConfig.interval
    }

    @Memoize()
    get turnsPassed(): Turns {
        return this.state.turnsPassed || 0
    }

    @Memoize()
    get turnsUntilNextStack(): Turns {
        return this.interval - this.turnsPassed
    }

    // ----- State change inquiry ----- //
    reactToEvent(event: Event): Interrupt {
        if (!(event instanceof TurnEnded)) return false
        if (event.character !== this.target) return false

        if (this.turnsPassed + 1 === this.interval) {
            return this.battle
                .startEvent(SkillTriggered, { skill: this.path })
                .perform(Skill, this.path, skill => (skill as ArcaneGrowth).trigger())
                .perform(Skill, this.path, skill => (skill as ArcaneGrowth).update({ turnsPassed: 0 }))
                .endEvent()
        }

        return this.update({ turnsPassed: this.turnsPassed + 1 })
    }

    trigger(): Battle {
        const effect = this.character.findEffect(ArcaneGrowthEffect)
        if (!effect) {
            return this.character.addEffect(ArcaneGrowthEffect, {
                stacks: 1,
                increase: this.increment,
                origin: this.path
            })
        }
        return effect.addStack(this.increment)
    }
}
