import image from './true_sight.png'
import { TrueSightEffect } from 'game/extended/heros/ranger/true_sight/true_sight_effect'
import { EffectUI } from 'game/extended/uis/effects/EffectUI'
import React from 'react'

export const true_sight_effect_ui: EffectUI<'TrueSightEffect'> = {
    model: TrueSightEffect,
    label: 'True Sight effect',
    image,
    renderDescription: (effect: TrueSightEffect, UI) => <>
        Your next ability will deal true damage
    </>
}
