import { CharDamaged, Event, Interrupt, Percentage, Skill, SkillConfig } from 'game/core'
import { Memoize } from 'game/util/memoize'

export interface BloodthurstConfig extends SkillConfig {
    regeneration: Percentage
}

export class Bloodthurst extends Skill<{}, BloodthurstConfig>{

    static KEY = 'Bloodthurst' as const

    @Memoize()
    get regeneration(): Percentage {
        return this.currentConfig.regeneration
    }

    // ----- Interrupts ----- //
    reactToEvent(event: Event): Interrupt {
        if (!(event instanceof CharDamaged)) return false
        if (event.origin !== this.character) return false
        const hpRegenerated = Math.round(event.injury.amount * this.regeneration / 100)
        return this.character.heal({
            origin: this.path,
            amount: hpRegenerated,
            modifiers: [],
            original_amount: hpRegenerated
        })
    }
}
