import { PoisonedShotEffect } from 'game/extended/heros/ranger/poisoned_shot/poisoned_shot_effect'
import { EffectUI } from 'game/extended/uis/effects/EffectUI'
import React from 'react'

import image from './poisoned.png'

export const poisoned_shot_effect_ui: EffectUI<'PoisonedShotEffect'> = {
    model: PoisonedShotEffect,
    label: 'Poisened',
    image,
    renderDescription: (effect: PoisonedShotEffect) => <span>
        This character is Poisoned and will receive damage each turn
        after he/she performs an action.
    </span>,
    renderTable: (effect: PoisonedShotEffect, UI) => <>
        <UI.TRow>
            <UI.TKey>Damage</UI.TKey>
            <UI.TVal><UI.Damage damage={effect.damage} /></UI.TVal>
        </UI.TRow>
    </>
}
