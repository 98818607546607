import { Cure, CureConfig } from 'game/extended/heros/paladin/cure/cure'
import { cure_config } from 'game/extended/heros/paladin/cure/cure_config'
import { AbilityUI } from 'game/extended/uis/abilities/AbilityUI'
import React from 'react'

import image from './cure.png'

export const cure_ui: AbilityUI<'Cure'> = {
    model: Cure,
    configs: cure_config,
    image,
    label: 'Cure',
    renderTargets: () => 'Self + Front / Support',
    renderDescription: () => <>
        Heal yourself and the ally behind or in front of you
    </>,
    renderImpression: (ability: Cure, UI) => <>
        <UI.Char character={ability.character} />{' '}channels his healing powers to cure his own wounds
        {ability.targetsBesideHimself.length > 0 &&
            <>
                {' '}and that of <UI.Char character={ability.targetsBesideHimself} />
            </>}
        .
    </>,
    renderTableStatic: (config: CureConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Healing:</UI.TKey>
            <UI.TVal><UI.Healing healing={config.healing} /></UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (ability: Cure, UI) => <>
        <UI.TRow>
            <UI.TKey>Healing:</UI.TKey>
            <UI.TVal><UI.Healing healing={ability.healing} /></UI.TVal>
        </UI.TRow>
    </>
}
