import { DamageType, Item, Percentage, Skill, SkillConfig } from 'game/core'
import { Memoize } from 'game/util/memoize'

export interface PenetratingArrowsConfig extends SkillConfig {
    splash: Percentage
}

export class PenetratingArrows extends Skill<{}, PenetratingArrowsConfig>{

    static KEY = 'PenetratingArrows' as const

    @Memoize()
    get splash(): Percentage {
        return this.currentConfig.splash
    }

    // ----- Appliers ----- //
    applyItemModifier(item: Item): Item {
        if (item.type !== 'weapon') return item
        if (item.damageType !== DamageType.Ranged) return item

        return {
            ...item,
            splashBehind: item.splashBehind || 0 + this.splash / 100
        }
    }
}