import { Armor, ItemRarity } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './rags.png'

export const rags: ItemUI<Armor> = {
    image,
    label: 'Rags',
    value: 10,
    item: {
        key: 'rags',
        type: 'armor',
        rarity: ItemRarity.Common,
        stats: {
            health: 10
        }
    }
}
