import { Battle, Event, Interrupt, Skill, SkillConfig, SkillTriggered, TurnEnded, Turns } from 'game/core'
import { AmbusherEffect } from 'game/extended/heros/ranger/ambusher/ambusher_effect'
import { Memoize } from 'game/util/memoize'

export interface AmbusherConfig extends SkillConfig {
    increase: number
    interval: Turns
}

export type AmbusherState = {
    turnsPassed?: number
}

export class Ambusher extends Skill<AmbusherState, AmbusherConfig>{

    static KEY = 'Ambusher' as const

    @Memoize()
    get increase(): number {
        return this.currentConfig.increase
    }

    @Memoize()
    get interval(): Turns {
        return this.currentConfig.interval
    }

    @Memoize()
    get turnsPassed(): Turns {
        return this.state.turnsPassed || 0
    }

    @Memoize()
    get turnsUntilNextStack(): Turns {
        return this.interval - this.turnsPassed
    }

    // ----- Interrupts ----- //
    reactToEvent(event: Event): Interrupt {
        if (!(event instanceof TurnEnded)) return false
        if (event.character !== this.target) return false

        if (this.turnsPassed + 1 === this.interval) {
            return this.battle
                .startEvent(SkillTriggered, { skill: this.path })
                .perform(Skill, this.path, skill => (skill as Ambusher).trigger())
                .perform(Skill, this.path, skill => (skill as Ambusher).update({ turnsPassed: 0 }))
                .endEvent()
        }

        return this.update({ turnsPassed: this.turnsPassed + 1 })
    }

    // ----- Calculate new State ----- //
    trigger(): Battle {
        const effect = this.character.findEffect(AmbusherEffect)

        if (!effect) {
            return this.character.addEffect(AmbusherEffect, {
                stacks: 1,
                increase: this.increase,
                origin: this.path
            })
        }
        return effect.addStack(this.increase)
    }
}
