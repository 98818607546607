import './itemvisual.less'

import { Item, ItemRarity } from 'game/core'
import { ItemKey } from 'game/extended/types'
import { getItemUI } from 'game/extended/uis/item_uis'
import { Nullable } from 'game/util/maybe'
import React from 'react'
import { styler } from 'views/layout/util/style'
import { Icon } from 'views/ui/icon'
import { DOMAttrs } from 'views/ui/ui_types'
import { Visual, VisualAttrs, VisualContainer, VisualIcon, VisualImage, VisualSubscript } from 'views/ui/visual'

const style = styler('itemvisual')

export type ItemPreviewColor =
    'transparent' | 'gray' | 'blue' | 'purple' | 'gold'

export const ItemVisual: React.FC<{
    item: Nullable<Item>,
    selected?: boolean,
    locked?: boolean,
    highlight?: boolean
} & VisualAttrs & DOMAttrs> = attrs => {

    const { item, locked, highlight, ...rest } = attrs

    if (!item) return <Visual {...rest} />

    const ui = getItemUI(item.key as ItemKey)
    if (!ui) return <Visual {...attrs} unknown={true} />

    const color = item ? glowColor(item.rarity) : 'transparent'

    return <Visual highlight={highlight} {...rest}>
        <VisualContainer>
            <div className={style('glow').mod(color)} />
        </VisualContainer>
        <VisualImage image={ui.image} />
        {locked && <VisualIcon>
            <Icon icon="forbidden" />
        </VisualIcon>}
        {ui.item.type === 'weapon' && ui.item.two_handed && <VisualSubscript>
            2
        </VisualSubscript>}
    </Visual>
}

export function glowColor(rarity: ItemRarity): ItemPreviewColor {
    switch (rarity) {
        case ItemRarity.Common: return 'gray'
        case ItemRarity.Rare: return 'blue'
        case ItemRarity.Epic: return 'purple'
        case ItemRarity.Legendary: return 'gold'
    }
}
