import { Requirement } from 'game/core'
import { Memoize } from 'game/util/memoize'
import { UnreachableCaseError } from 'game/util/never'

export class RequiredAmountAllies extends Requirement<{
    amount: number,
    pos: 'front' | 'support' | 'any'
}> {
    static KEY = 'RequiredAmountAllies' as const

    @Memoize()
    get isValid() {
        switch (this.state.pos) {
            case 'any':
                return this.character.side.characters.length >= this.state.amount
            case 'front':
                return this.character.side.front.characters.length >= this.state.amount
            case 'support':
                return this.character.side.support.characters.length >= this.state.amount
            default:
                throw new UnreachableCaseError(this.state.pos)
        }
    }
}
