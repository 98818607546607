import { DamageType } from 'game/core'
import { Pushback, PushbackConfig } from 'game/extended/creatures/tutorial/abilities/pushback/pushback'
import { AbilityUI } from 'game/extended/uis/abilities/AbilityUI'
import React from 'react'

import image from './pushback.png'

export const pushback_ui: AbilityUI<'Pushback'> = {
  model: Pushback,
  configs: [
    {
      available: 0,
      cooldown: 1,
      energy: 3,
      damage: 12
    }
  ],
  image,
  label: 'Pushback',
  renderTargets: () => 'Melee',
  renderDescription: () => <>
    Hit your target and push it to the support line.
  </>,
  renderImpression: (ability: Pushback, UI) => <>
    <UI.Char character={ability.character} /> charges into
    <UI.Char character={ability.targets} /> like a hurricane.
  </>,
  renderTableStatic: (config: PushbackConfig, UI) => <>
    <UI.TRow>
      <UI.TKey>Damage:</UI.TKey>
      <UI.TVal><UI.Damage type={DamageType.Melee} damage={config.damage} /></UI.TVal>
    </UI.TRow>
  </>,
  renderTable: (ability: Pushback, UI) => <>
    <UI.TRow>
      <UI.TKey>Damage:</UI.TKey>
      <UI.TVal><UI.Damage damage={ability.damage} /></UI.TVal>
    </UI.TRow>
  </>
}
