import { WhenHurtTarget } from 'game/extended/conditions/when_hurt/when_hurt'
import { ConditionUI } from 'game/extended/uis/conditions/ConditionUI'
import React from 'react'
import { Conditionsetup, ConditionsetupSelect, ConditionsetupSlider } from 'views/ui/conditionsetup'
import { VisualSubscript } from 'views/ui/visual'

import { ReactComponent as WhenHurtIcon } from './when_hurt.svg'

const options = {
    self: 'Self',
    front_ally: 'Front ally',
    back_ally: 'Back ally'
}

const symbols = {
    self: 'S',
    front_ally: 'FA',
    back_ally: 'BA'
}

export const when_hurt_ui: ConditionUI<'WhenHurt'> = {
    icon: WhenHurtIcon,
    label: 'When hurt',
    defaultState: {
        key: 'WhenHurt',
        target: 'self',
        percent: 50
    },
    renderDescription: (state) =>
        <span>
            Cast this sequence when
            {state.target === 'self' && ' your hp is '}
            {state.target === 'front_ally' && ' the hp of the ally in front of you is '}
            {state.target === 'back_ally' && ' the hp of the ally behind you is '}
            below {state.percent}%
        </span>,
    renderVisualIcon: (state) =>
        <VisualSubscript>
            {symbols[state.target]} - {state.percent}%
        </VisualSubscript>,
    renderOptions: (state, update) =>
        <Conditionsetup>
            <ConditionsetupSelect<WhenHurtTarget>
                value={state.target}
                options={options}
                onchange={value => update({ ...state, target: value })}
            />
            <ConditionsetupSlider
                min={0}
                max={100}
                step={10}
                unit="%"
                value={state.percent}
                onchange={value => update({ ...state, percent: value })}
            />
        </Conditionsetup>
}
