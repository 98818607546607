import { BurpEffect } from 'game/extended/creatures/goblins/abilities/burp/burp_effect'
import { EffectUI } from 'game/extended/uis/effects/EffectUI'
import React from 'react'

import image from './burp.png'

export const burp_effect_ui: EffectUI<'BurpEffect'> = {
    model: BurpEffect,
    label: 'Burp effect',
    image,
    renderDescription: (effect: BurpEffect) => <>
        This character's equipment is compromised and rendered useless.
    </>
}
