import { Memoize } from 'game/util/memoize'
import { EndlessModel } from 'models/endless/EndlessModel'
import { LevelModel, LevelStatus } from 'models/user/LevelModel'
import { UserModel } from 'models/user/UserModel'

export class EndlessNextLevelModel implements LevelModel {

  constructor(readonly endless: EndlessModel) { }

  @Memoize()
  get battle_id(): string {
    return 'battle_' + this.number
  }

  @Memoize()
  get allies() {
    return {
      front: this.user.lineup.frontSlots,
      support: this.user.lineup.supportSlots
    }
  }

  @Memoize()
  get enemies() {
    return {
      front: this.endless.level.front,
      support: this.endless.level.support
    }
  }

  @Memoize()
  get number(): number {
    return this.endless.levelNumber
  }

  @Memoize()
  get attempts(): number {
    return 0
  }

  @Memoize()
  get user(): UserModel {
    return this.endless.user
  }

  @Memoize()
  get status(): LevelStatus {
    return {
      key: 'open'
    }
  }
}
