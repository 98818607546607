import { Battle, Event, EventConstructor, EventState } from 'game/core'

export class CollectEvent extends Event {
    static KEY = 'CollectEvent' as const

    // ----- Calculate new State ----- //
    changeType<E extends Event>(constructor: EventConstructor<E>, state: Omit<E['state'], keyof EventState>): Battle {
        const eventState = {
            ...this.state,
            key: constructor.KEY,
            ...state
        }
        return this.parent.updateEvent(this.state, eventState)
    }

}
