import { Armor, ItemRarity } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './crusador_chainmail.png'

export const crusador_chainmail: ItemUI<Armor> = {
    image,
    label: 'Crusador chainmail',
    value: 105,
    item: {
        key: 'crusador_chainmail',
        type: 'armor',
        rarity: ItemRarity.Rare,
        stats: {
            armor: 25,
            shielding: 25,
            health: 30,
            resistance: 15
        }
    }
}
