import { EthernalFire, EthernalFireConfig } from 'game/extended/heros/mage/ethernal_fire/ethernal_fire'
import { ethernal_fire_config } from 'game/extended/heros/mage/ethernal_fire/ethernal_fire_config'
import { SkillUI } from 'game/extended/uis/skills/SkillUI'
import React from 'react'

import image from './ethernal_fire.png'

export const ethernal_fire_ui: SkillUI<'EthernalFire'> = {
    model: EthernalFire,
    configs: ethernal_fire_config,
    image,
    label: 'Ethernal fire',
    renderDescription: (config: EthernalFireConfig) => <>
        When this character takes lethal damage, he will temporarily be resurrected with
        a percentage of his hitpoints and boosted intelligence. When the duration is over
        the character dies.
    </>,
    renderTableStatic: (config: EthernalFireConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Intelligence:</UI.TKey>
            <UI.TVal><UI.Stat stat='intelligence' amount={config.intelligence} /></UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Hitpoints:</UI.TKey>
            <UI.TVal><UI.Stat stat='health' amount={config.health} percentage /></UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Duration:</UI.TKey>
            <UI.TVal><UI.Turns turns={config.duration} /></UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (skill: EthernalFire, UI) => <>
        <UI.TRow>
            <UI.TKey>Intelligence:</UI.TKey>
            <UI.TVal><UI.Stat stat='intelligence' amount={skill.intelligenceModifier} /></UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Hitpoints:</UI.TKey>
            <UI.TVal><UI.Stat stat='health' amount={skill.healthRecovery} percentage /></UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Duration:</UI.TKey>
            <UI.TVal><UI.Turns turns={skill.duration} /></UI.TVal>
        </UI.TRow>
    </>
}
