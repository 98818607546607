import image from 'game/extended/heros/barbarian/warcry/warcry.png'
import { WarcryEffect } from 'game/extended/heros/barbarian/warcry/warcry_effect'
import { EffectUI } from 'game/extended/uis/effects/EffectUI'
import React from 'react'

export const warcry_effect_ui: EffectUI<'WarcryEffect'> = {
    model: WarcryEffect,
    label: 'Warcry boost',
    image,
    renderDescription: (effect: WarcryEffect) => <>
        Gain an increase in all your damage output.
    </>,
    renderTable: (effect: WarcryEffect, UI) => <>
        <UI.TRow>
            <UI.TKey>Increase:</UI.TKey>
            <UI.TVal>{effect.damageIncrease}%</UI.TVal>
        </UI.TRow>
    </>
}
