import * as chapters from 'data/campaign'
import { missions } from 'data/campaign/missions'
import { ClassKey, ItemKey } from 'game/extended/types'
import { Memoize } from 'game/util/memoize'
import { CampaignModel } from 'models/campaign/CampaignModel'
import { MissionModel } from 'models/campaign/MissionModel'
import { UserModel } from 'models/user/UserModel'
import { ImageType } from 'views/ui/ui_types'

export type MissionKey = keyof typeof missions
export type ChapterKey = keyof typeof chapters

export const difficulties = ['regular', 'hard', 'insane'] as const
export type Difficulty = typeof difficulties[number]

export interface ChapterConfig {
    label: string
    background: ImageType
    missions: Array<MissionKey>
    classes: Array<ClassKey>
    items: Array<ItemKey>
    initial: (difficulty: Difficulty) => CampaignModel
}

export interface ChapterPath {
    chapter: ChapterKey
}

export class ChapterModel {

    constructor(readonly campaign: CampaignModel, readonly key: ChapterKey) { }

    @Memoize()
    get user(): UserModel {
        return this.campaign.user
    }

    @Memoize()
    get path(): ChapterPath {
        return { chapter: this.key }
    }

    @Memoize()
    get label(): string {
        return this.config.label
    }

    @Memoize()
    get missions(): Array<MissionModel> {
        return this.config.missions.map((key: MissionKey) =>
            new MissionModel(this, key, this.campaign.state.missions[key] || { attempts: 0 })
        )
    }

    @Memoize()
    get background(): ImageType {
        return this.config.background
    }

    @Memoize()
    get config(): ChapterConfig {
        return chapters[this.key]
    }

    @Memoize()
    get isFinished(): boolean {
        return !this.missions.find(mission => !mission.isFinished)
    }
}
