import { Immobilized, Stunned } from 'game/core'
import image from './immobilized.png'
import { EffectUI } from 'game/extended/uis/effects/EffectUI'
import React from 'react'

export const immobilized_ui: EffectUI<'Immobilized'> = {
  model: Immobilized,
  label: 'Immobilized',
  image,
  renderDescription: (effect: Stunned) => <span>
    This character is stunned and can not take any action until
    recovered
    </span>
}
