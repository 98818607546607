import { EffectUI } from 'game/extended/uis/effects/EffectUI'
import React from 'react'

import { AmbusherEffect } from './ambusher_effect'
import image from './ambusher_effect.png'

export const ambusher_effect_ui: EffectUI<'AmbusherEffect'> = {
    model: AmbusherEffect,
    label: 'Ambusher bonus',
    image,
    renderDescription: (effect: AmbusherEffect) => <>
        Your agility is increased and each additional stack will further increase this.
    </>,
    renderTable: (effect: AmbusherEffect, UI) => <>
        <UI.TRow >
            <UI.TKey>Stacks: </UI.TKey>
            <UI.TVal>{effect.stacks}</UI.TVal>
        </UI.TRow>
        <UI.TRow >
            <UI.TKey>Increase: </UI.TKey>
            <UI.TVal><UI.Stat stat='agility' amount={effect.increase} /></UI.TVal>
        </UI.TRow>
    </>
}
