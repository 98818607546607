import { HitAndRun, HitAndRunConfig } from 'game/extended/creatures/undead/abilities/hit_and_run/hit_and_run'
import { AbilityUI } from 'game/extended/uis/abilities/AbilityUI'
import React from 'react'

import image from './hit_and_run.png'

export const hit_and_run_ui: AbilityUI<'HitAndRun'> = {
    model: HitAndRun,
    configs: [
        {
            available: 0,
            cooldown: 0,
            energy: 2
        }
    ],
    image,
    label: 'Hit and run',
    renderTargets: () => 'Self',
    renderDescription: () => <>
        Move to the frontline, attempt to perform your next action and move to the
        support line.
    </>,
    renderImpression: (ability: HitAndRun, UI) => <>
        <UI.Char character={ability.character} />{' '}pops up out of nowhere, performs{' '}
        <UI.Ability ability={ability.abilityToCast} />{' '}and hides back in
        the shadows.
    </>,
    renderTableStatic: (config: HitAndRunConfig, UI) => <>
    </>,
    renderTable: (ability: HitAndRun, UI) => <>
    </>,
}
