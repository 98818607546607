import { Headgear, ItemRarity } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './divine_helmet.png'

export const divine_helmet: ItemUI<Headgear> = {
    image,
    label: 'Divine helmet',
    value: 160,
    item: {
        key: 'divine_helmet',
        type: 'headgear',
        rarity: ItemRarity.Common,
        stats: {
            strength: 60,
            agility: 60,
            intelligence: 60,
            health: 50,
            armor: 30,
            resistance: 40
        }
    }
}
