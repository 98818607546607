import { ArcaneCurse, ArcaneCurseConfig } from 'game/extended/heros/mage/arcane_curse/arcane_curse'
import { arcane_curse_config } from 'game/extended/heros/mage/arcane_curse/arcane_curse_config'
import { AbilityUI } from 'game/extended/uis/abilities/AbilityUI'
import React from 'react'

import image from './arcane_curse.png'

export const arcane_curse_ui: AbilityUI<'ArcaneCurse'> = {
    model: ArcaneCurse,
    configs: arcane_curse_config,
    image,
    label: 'Arcane Curse',
    renderTargets: () => 'Ranged',
    renderDescription: () => <>
        The target is only able to perform abilities with an energy cost below
        a given threshold.
    </>,
    renderImpression: (ability: ArcaneCurse, UI) => <>
        <UI.Char character={ability.character} />{' '}murmures a verce of ancient origin,
        thereby cursing{' '}<UI.Char character={ability.targets} />.
    </>,
    renderTableStatic: (config: ArcaneCurseConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Duration:</UI.TKey>
            <UI.TVal>
                <UI.Turns turns={config.duration} />
            </UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Energy threshold:</UI.TKey>
            <UI.TVal>
                <UI.Stat stat="energy" amount={config.max_energy} />
            </UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (ability: ArcaneCurse, UI) => <>
        <UI.TRow>
            <UI.TKey>Duration:</UI.TKey>
            <UI.TVal>
                <UI.Turns turns={ability.duration} />
            </UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Energy threshold:</UI.TKey>
            <UI.TVal>
                <UI.Stat stat="energy" amount={ability.maxEnergy} />
            </UI.TVal>
        </UI.TRow>
    </>
}
