import { Headgear, ItemRarity } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './half_moon_hat.png'

export const half_moon_hat: ItemUI<Headgear> = {
    image,
    label: 'Half moon hat',
    value: 75,
    item: {
        key: 'half_moon_hat',
        type: 'headgear',
        rarity: ItemRarity.Rare,
        stats: {
            intelligence: 60
        }
    }
}
