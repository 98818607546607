import { Character, CharDamaged, Event, Interrupt, Skill, SkillTriggered } from 'game/core'

export type PricklyConfig = {}

export class Prickly extends Skill<{}, PricklyConfig>{
    static KEY = 'Prickly' as const

    // ----- Appliers ----- //
    reactToEvent(event: Event): Interrupt {
        if (!(event instanceof CharDamaged)) return false
        if (event.target !== this.character) return false
        if (!event.origin) return false

        return this.battle
            .addEvent(SkillTriggered, { skill: this.path })
            .perform(Character, event.origin.path, char => char.instantKill(this.path))
    }
}
