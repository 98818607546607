import { Stats, WeaponCategory } from 'game/core'
import { barbarian } from 'game/extended/heros/barbarian/barbarian'
import { tutorial_barbarian } from 'game/extended/heros/barbarian/tutorial_barbarian'
import { mage } from 'game/extended/heros/mage/mage'
import { paladin } from 'game/extended/heros/paladin/paladin'
import { ranger } from 'game/extended/heros/ranger/ranger'
import { AbilityKey, ClassKey, SkillKey } from 'game/extended/types'
import { ImageType } from 'views/ui/ui_types'

export type ClassDefinition = {
    stats: Stats,
    masteries: Partial<Record<WeaponCategory, number>>,
    abilities: Array<AbilityKey>,
    skills: Array<SkillKey>,
    super_ability?: AbilityKey,
    super_skill?: SkillKey
}

export interface ClassUI {
    name: string
    label: string
    definition: ClassDefinition
    circle: {
        src: string,
        width: number,
        height: number
    }
    full: ImageType
    head: ImageType
}

const class_uis: {
    [K in ClassKey]: ClassUI
} = {
    tutorial_barbarian: tutorial_barbarian,
    mage: mage,
    barbarian: barbarian,
    ranger: ranger,
    paladin: paladin
}

export function getClassUI(type: ClassKey): ClassUI {
    return class_uis[type]
}
