import { Ability, AbilityConfig, Battle, Character, Percentage, Turns } from 'game/core'
import { CursedWeaponsEffect } from 'game/extended/creatures/goblins/abilities/cursed_weapons/cursed_weapons_effect'
import { Memoize } from 'game/util/memoize'

export type CursedWeaponsConfig = AbilityConfig & {
    duration: Turns
    reduction: Percentage
}

export class CursedWeapons extends Ability<CursedWeaponsConfig>{

    static KEY = 'CursedWeapons' as const
    static FIXED_REQUIREMENTS = []

    @Memoize()
    get targets(): Array<Character> {
        return this.character.side.enemySide.characters
    }

    @Memoize()
    get duration(): Turns {
        return this.currentConfig.duration
    }

    @Memoize()
    get reduction(): Percentage {
        return this.currentConfig.reduction
    }

    // ----- Calculate new State ----- //
    performActionImpl(): Battle {
        return this.performOnAllTargets(char => char.addEffect(CursedWeaponsEffect, {
            origin: this.character.path,
            duration: this.duration,
            reduction: this.reduction
        }))
    }
}