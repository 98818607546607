import { Ability, AbilityConfig, Line } from 'game/core'
import { createCreatureType } from 'game/extended/creatures/create_creature'
import { bat } from 'game/extended/creatures/undead/bat/bat'
import { Memoize } from 'game/util/memoize'

export type BatsConfig = AbilityConfig


export class Bats extends Ability<BatsConfig> {

    static KEY = 'Bats' as const
    static FIXED_REQUIREMENTS = []

    @Memoize()
    get targets() {
        return this.character.allEnemies
    }

    // ----- Calculate new State ----- //
    performActionImpl() {
        if (!this.targets) return this.battle

        let nrOfSummons = 4 - this.character.side.front.characters.length
        if (this.character.line.position === 'front') {
            nrOfSummons += 1
        }

        let result = this.character.moveToSupport()
        for (let i = 0; i < nrOfSummons; i++) {
            result = result.perform(Line, this.character.side.front.path, line => {
                const typeState = createCreatureType(bat, 'bat', 1)
                return line.createCharacter('Summoned bat', typeState, { owner: this.character.path })
            })
        }

        return result
    }
}