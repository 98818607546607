import { RequiredMobility } from 'game/core'
import { RequirementUI } from 'game/extended/uis/requirements/RequirementUI'
import React from 'react'

export const required_mobility_ui: RequirementUI<'RequiredMobility'> = {
    model: RequiredMobility,
    label: 'Required mobility',
    renderDescription: (state) => <>
        The ability requires the caster to be mobile (not immobilized).
    </>
}
