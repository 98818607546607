import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'
import { ChapterKey, Difficulty } from 'models/campaign/ChapterModel'
import { CampaignResultModel, CampaignResultState } from 'models/ranking/CampaignResultModel'
import { EndlessResultState, EndlessResultModel } from 'models/ranking/EndlessResultModel'

export type RankingState = {
  campaigns: CampaignResultState[]
  endlesss: EndlessResultState,
  name?: string
}

export class RankingModel {

  constructor(readonly state: RankingState) { }

  @Memoize()
  get campaignResults() {
    return this.state.campaigns
      .map(data => new CampaignResultModel(data))
      .sort((a, b) => b.score - a.score)
  }

  @Memoize()
  get endlessResult() {
    return new EndlessResultModel(this.state.endlesss)
  }

  getBestResultForChapter(chapter: ChapterKey, difficulty: Difficulty): Nullable<CampaignResultModel> {
    return this.campaignResults
      .find(res => res.chapter === chapter && res.difficulty === difficulty)
  }

  // ----- Calculate new State ----- //
  update(newState: Partial<RankingState>): RankingModel {
    return new RankingModel({
      ...this.state,
      ...newState
    })
  }

  addCampaignResult(result: CampaignResultModel): RankingModel {
    return this.update({
      campaigns: [...this.state.campaigns, result.state]
    })
  }

  updateEndlessResult(result: EndlessResultModel): RankingModel {
    if (result.levels <= this.endlessResult.levels) return this
    return this.update({
      endlesss: result.state
    })
  }
}