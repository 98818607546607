import { AttackRange, DamageType, ItemRarity, Weapon } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './warden_sword.png'

export const warden_sword: ItemUI<Weapon> = {
    image,
    label: 'Warden sword',
    value: 100,
    item: {
        key: 'warden_sword',
        type: 'weapon',
        rarity: ItemRarity.Rare,
        stats: {
            health: 40,
            armor: 20,
            shielding: 15
        },
        two_handed: true,
        category: 'sword',
        damageType: DamageType.Melee,
        range: AttackRange.Melee,
        energy: 4,
        damage: 12
    }
}
