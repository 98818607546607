import './cast.scss'

import React from 'react'

export const Cast: React.FC = ({ children }) => {

    return <div className="cast">
        {children}
    </div>
}

export const CastVisual: React.FC<{
    src: string
    label?: string
}> = ({ src, label }) => {

    return <img className="cast_visual" src={src} height="512" width="512" alt={label} />
}

export const CastRoundVisual: React.FC<{
    round: number
}> = ({ round }) => {

    return <div className="cast_roundvisual">
        <div className="cast_roundvisual-number">{round + 1}</div>
    </div>
}

export const CastText: React.FC = ({ children }) => {

    return <div className="cast_text">
        {children}
    </div>
}

export const CastImpression: React.FC = ({ children }) => {

    return <span className="cast_impression">
        {children}
    </span>
}

export const CastEffect: React.FC = ({ children }) => {

    return <span className="cast_effect">
        [{children}]
    </span>
}
