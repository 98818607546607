import { MoveSupport } from 'game/core'
import image from 'game/extended/core/abilities/move_support/move_support.png'
import { move_support_config } from 'game/extended/core/abilities/move_support/move_support_config'
import { AbilityUI } from 'game/extended/uis/abilities/AbilityUI'
import React from 'react'

export const move_support_ui: AbilityUI<'MoveSupport'> = {
    model: MoveSupport,
    configs: move_support_config,
    label: 'Move Support',
    image,
    renderTargets: () => 'Yourself',
    renderDescription: () => <>
        Move to the support line.
    </>,
    renderImpression: (ability: MoveSupport, UI) => <>
        <UI.Char character={ability.character} />{' '}steps backward, into the support line.
    </>
}
