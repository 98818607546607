import { Battle, Equipment, Line, TypeState } from 'game/core'
import { goblin_axeman } from 'game/extended/creatures/goblins/goblin_axeman/goblin_axeman'
import { goblin_big_momma } from 'game/extended/creatures/goblins/goblin_big_momma/goblin_big_momma'
import { goblin_bomber } from 'game/extended/creatures/goblins/goblin_bomber/goblin_bomber'
import { goblin_brute } from 'game/extended/creatures/goblins/goblin_brute/goblin_brute'
import { goblin_chief } from 'game/extended/creatures/goblins/goblin_chief/goblin_chief'
import { goblin_chief_mounted } from 'game/extended/creatures/goblins/goblin_chief/goblin_chief_mounted'
import { goblin_shaman } from 'game/extended/creatures/goblins/goblin_shaman/goblin_shaman'
import { goblin_swordman } from 'game/extended/creatures/goblins/goblin_swordman/goblin_swordman'
import { blowfishy } from 'game/extended/creatures/tutorial/blowfishy/blowfishy'
import { bully } from 'game/extended/creatures/tutorial/bully/bully'
import { chicky } from 'game/extended/creatures/tutorial/chicky/chicky'
import { hedgy } from 'game/extended/creatures/tutorial/hedgy/hedgy'
import { turtly } from 'game/extended/creatures/tutorial/turtly/turtly'
import { bat } from 'game/extended/creatures/undead/bat/bat'
import { skeleton } from 'game/extended/creatures/undead/skeleton/skeleton'
import { skeleton_archer } from 'game/extended/creatures/undead/skeleton_archer/skeleton_archer'
import { skeleton_king } from 'game/extended/creatures/undead/skeleton_king/skeleton_king'
import { skeleton_wizard } from 'game/extended/creatures/undead/skeleton_wizard/skeleton_wizard'
import { vampire } from 'game/extended/creatures/undead/vampire/vampire'
import { vampiress } from 'game/extended/creatures/undead/vampiress/vampiress'
import { abilities, AbilityKey, ConditionKey, ConditionState, CreatureKey, items, SkillKey } from 'game/extended/types'
import { EquipmentDefinition } from 'game/extended/uis/item_uis'
import { isArray } from 'util'
import { ImageType } from 'views/ui/ui_types'

export type CreatureDefinition = {
    stats: CreatureDefinitionStats
    skills?: Array<SkillKey>
    equipment?: EquipmentDefinition,
    strategy: Array<
        AbilityKey |
        Array<AbilityKey> |
        {
            abilities: AbilityKey | Array<AbilityKey>,
            conditions: ConditionState | Array<ConditionState>
        }
    >
}

export type CreatureDefinitionStats = {
    health: number,
    energy: number,
    strength: number,
    intelligence: number,
    agility: number,
    armor: number,
    shielding: number,
    resistance: number
}

export interface CreatureUI {
    definition: (lvl: number) => CreatureDefinition
    label: string
    gender?: 'male' | 'female'
    circle: {
        src: string,
        width: number,
        height: number
    }
    full?: ImageType
    head: ImageType
}

const creature_uis: {
    [K in CreatureKey]: CreatureUI
} = {
    hedgy: hedgy,
    bully: bully,
    blowfishy: blowfishy,
    turtly: turtly,
    chicky: chicky,
    goblin_axeman: goblin_axeman,
    goblin_big_momma: goblin_big_momma,
    goblin_bomber: goblin_bomber,
    goblin_chief: goblin_chief,
    goblin_chief_mounted: goblin_chief_mounted,
    goblin_shaman: goblin_shaman,
    goblin_swordman: goblin_swordman,
    bat: bat,
    skeleton_king: skeleton_king,
    vampiress: vampiress,
    vampire: vampire,
    skeleton_archer: skeleton_archer,
    skeleton: skeleton,
    skeleton_wizard: skeleton_wizard,
    goblin_brute: goblin_brute
}

export function addCreatureToLine(line: Line, type: CreatureKey, level: number, name: string): Battle {
    const ui: CreatureUI = creature_uis[type]
    const def = ui.definition(level)
    const abilitiesSet: Set<AbilityKey> = new Set(['Rest'] as const)

    if (isArray(def.strategy)) {
        def.strategy.forEach(row => {
            if (isArray(row)) {
                row.forEach(key => abilitiesSet.add(key))
            } else if (typeof row === 'string') {
                abilitiesSet.add(row)
            } else {
                (isArray(row.abilities) ? row.abilities : [row.abilities]).forEach(key =>
                    abilitiesSet.add(key)
                )
            }
        })
    }

    const rules = def.strategy.map(line => {
        if (isArray(line)) {
            return {
                sequence: line,
                conditions: []
            }
        }

        if (typeof line === 'string') {
            return {
                sequence: [line],
                conditions: []
            }
        }

        return {
            sequence: (isArray(line.abilities) ? line.abilities : [line.abilities]),
            conditions: (isArray(line.conditions) ? line.conditions : [line.conditions]).map(cond => ({
                ...cond,
                key: cond.key as ConditionKey
            }))
        }
    })

    const state: TypeState = {
        kind: 'creature',
        type: type,
        stats: def.stats,
        abilities: Array.from(abilitiesSet).map(key => {
            return {
                key: key as AbilityKey,
                level: 1,
                lastUsage: false,
                lastTargets: [],
                available: true as const
            }
        }),
        skills: (def.skills || []).map(key => ({
            level: level,
            key: key
        })),
        level: level,
        rules: [
            ...rules,
            {
                conditions: [],
                sequence: ['Rest']
            }
        ]
    }

    const equipment: Equipment = {}
    if (def.equipment?.primary) {
        equipment.primary = items[def.equipment.primary].item
    }
    if (def.equipment?.secondary) {
        equipment.secondary = items[def.equipment.secondary].item
    }

    return line.createCharacter(name, state, { equipment, gender: ui.gender, pos: 10 })
}

export function getCreatureUI(type: CreatureKey): CreatureUI {
    return creature_uis[type]
}
