import { Ability, AbilityConfig, Battle, Character, Turns } from 'game/core'
import { UndyingFuryEffect } from 'game/extended/heros/barbarian/undying_fury/undying_fury_effect'
import { Memoize } from 'game/util/memoize'

export type UndyingFuryConfig = AbilityConfig & {
    duration: Turns
}

export class UndyingFury extends Ability<UndyingFuryConfig>{

    static KEY = 'UndyingFury' as const
    static FIXED_REQUIREMENTS = []

    @Memoize()
    get targets(): Character {
        return this.character
    }

    @Memoize()
    get duration(): Turns {
        return this.currentConfig.duration
    }

    // ----- Calculate new State ----- //
    performActionImpl(): Battle {
        return this.targets.addEffect(UndyingFuryEffect, {
            origin: this.character.path,
            duration: this.duration
        })
    }
}

