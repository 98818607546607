import { ImmunityConfig } from 'game/extended/heros/paladin/immunity/immunity'

export const immunity_config: Array<ImmunityConfig> = [
    {
        available: 4,
        cooldown: 8,
        energy: 3,
        duration: 1
    },
    {
        available: 4,
        cooldown: 8,
        energy: 4,
        duration: 2
    },
    {
        available: 3,
        cooldown: 6,
        energy: 5,
        duration: 3
    }
]