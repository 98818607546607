import './tutorial.scss'

import { useApp } from 'AppContext'
import { EquipmentKey } from 'game/core'
import { AbilityKey, ConditionKey, ItemKey } from 'game/extended/types'
import { CampaignModel } from 'models/campaign/CampaignModel'
import { MissionKey } from 'models/campaign/ChapterModel'
import { HeroID } from 'models/user/UserModel'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { Portal } from 'react-portal'
import { CampaignContext, CampaignContextProvider } from 'views/ingame/campaign/Campaign'
import { Button } from 'views/layout/button'
import closeBtnImg from 'views/layout/popup_btn_close.png'
import { Icon } from 'views/ui/icon'
import { StatIcon } from 'views/ui/stats/staticon'
import { AbilityVisual } from 'views/ui/visuals/abilityvisual'
import { CharacterVisual } from 'views/ui/visuals/charactervisual'
import { ConditionVisual } from 'views/ui/visuals/conditionvisual'
import { useUser } from '../user/usercontext'

export type TutorialStep = number

export type TutorialState = { view: () => React.ReactElement<any, any> } & (
  { confirm: string } |
  {
    hint: HintState,
    toNext: (campaign: CampaignModel) => boolean
  }
)

export type HintState =
  { key: 'none' } |
  { key: 'level', mission: MissionKey, action: 'lineup', front: number, hero: HeroID } |
  { key: 'level', mission: MissionKey, action: 'start' } |
  { key: 'teampopup', char: HeroID, tab: 'achievements' } |
  { key: 'teampopup', char: HeroID, tab: 'abilities', ability: AbilityKey } |
  { key: 'teampopup', char: HeroID, tab: 'strategy', type: 'ability', rule: number, pos: number, ability: AbilityKey | false } |
  { key: 'teampopup', char: HeroID, tab: 'strategy', type: 'condition', rule: number, pos: number, condition: ConditionKey | false } |
  { key: 'teampopup', char: HeroID, tab: 'equipment', slot: EquipmentKey, item: ItemKey } |
  { key: 'shop', tab: 'weapons' | 'armor', item: ItemKey }


export const TutorialContext = createContext<TutorialState | false>(false)

export const useTutorial = () => {
  return useContext(TutorialContext)
}

export const useHinter = () => {
  const tutorial = useTutorial()
  if(!tutorial) return {key: 'none' as const}
  if(!('hint' in tutorial)) return {key: 'none' as const}
  return tutorial.hint
}

export const TutorialHint: React.FC = () => {
  return <div className="tutorial_hint">
    <Icon icon="click" />
  </div>
}

export const Tutorial: React.FC = ({ children }) => {
  const { tutorial, setTutorial } = useApp()
  const regularCampaignContext = useContext(CampaignContext)
  const {finalize} = useUser()

  const step = tutorial || 0
  const state = steps[step]

  const advance = () => {
    setTutorial(step + 1)
  }

  return <TutorialContext.Provider value={state || false}>
    <CampaignContextProvider
      campaign={regularCampaignContext.campaign}
      setCampaign={(campaign: CampaignModel | null) => {
        if (campaign) {
          if ('toNext' in state && state.toNext(campaign)) {
            regularCampaignContext.setCampaign(campaign, step + 1)
          }
        } else {
          finalize()
        }
      }}
    >
      {children}
      <TutorialUI state={state} advance={advance} />
    </CampaignContextProvider>
  </TutorialContext.Provider>
}

const TutorialUI: React.FC<{
  state: TutorialState
  advance: () => void
}> = ({ state, advance }) => {
  const [hidden, setHidden] = useState(false)
  useEffect(() => {
    setHidden(false)
  }, [state])

  if (!state || hidden) return null

  return <Portal>
    <div
      className="tutorial_ui"
      onClick={(e) => !('confirm' in state) && setHidden(true)}
    >
      <TutorialMsg
        confirm={'confirm' in state ? { label: state.confirm, action: advance } : undefined}
        hide={() => !('confirm' in state) && setHidden(true)}
      >
        {state.view()}
      </TutorialMsg>
    </div>
  </Portal>
}

const TutorialMsg: React.FC<{
  confirm?: {
    label: string,
    action: () => void
  },
  hide: () => void
}> = ({ children, confirm, hide }) => {

  return <div className="tutorial_msg" onClick={e => e.stopPropagation()}>
    {!confirm && <div className="tutorial_msg-close" onClick={hide}>
      <img src={closeBtnImg} className="tutorial_msg-close-img" alt="close" />
    </div>}
    <div className="tutorial_msg-text">
      {children}
    </div>
    {confirm && <div className="tutorial_msg-confirm">
      <Button onClick={confirm.action}>{confirm.label}</Button>
    </div>}
  </div>
}

const steps: TutorialState[] = [
  {
    view: () => <>
      Nice to meet you coach! I assigned <CharacterVisual type="barbarian" />Graut under
      your service to teach you the basics of this game.
    </>,
    confirm: `Let's do this!`
  },
  {
    view: () => <>
      Press the first level icon and and position your hero for the next battle.
    </>,
    hint: { key: 'level', mission: 'tutorial_mission_1', action: 'lineup', front: 1, hero: 'tutorial_barbarian' },
    toNext: (campaign) => !campaign.user.lineup.isEmpty
  },
  {
    view: () => <>
      Great! Now go ahead and fight that chicken! During combat there is nothing you can do,
      simply scroll through the battle report to see how it went.
    </>,
    hint: { key: 'level', mission: 'tutorial_mission_1', action: 'start' },
    toNext: (campaign) => campaign.nextMission!.deaths > 0
  },
  {
    view: () => <>
      How could you lose to a simple chicken? Simple, you diddent assign any commands to
      {' '}<CharacterVisual type="barbarian" />Graut. Which left him standing idle during
      that battle.
    </>,
    confirm: `Commands?`
  },
  {
    view: () => <>
      Press the characters button and select <CharacterVisual type="barbarian" />. Go to the strategy
      tab. Select the first command of the primary sequence and assign <AbilityVisual ability="Punch" />Punch.
    </>,
    hint: { key: 'teampopup', char: 'tutorial_barbarian', tab: 'strategy', type: 'ability', rule: 0, pos: 0, ability: 'Punch' },
    toNext: (campaign) => {
      const hero = campaign.user.getHeroById('barbarian')!
      return hero.rules[0]?.sequence[0] === 'Punch'
    }
  },
  {
    view: () => <>
      Perfect! Now try to fight that chicken ones again.
    </>,
    hint: { key: 'level', mission: 'tutorial_mission_1', action: 'start' },
    toNext: (campaign) => campaign.nextMission!.key === 'tutorial_mission_2'
  },
  {
    view: () => <>
      Nice! On to the next enemy.
    </>,
    hint: { key: 'level', mission: 'tutorial_mission_2', action: 'start' },
    toNext: (campaign) => campaign.nextMission!.deaths >= 0
  },
  {
    view: () => <>
      This enemy is a bit toucher. It looks like Hand to hand combat will not be enough.
      Head over to the shop to buy a weapon.
    </>,
    hint: { key: 'shop', tab: 'weapons', item: 'heaxy_axe' },
    toNext: (campaign) => !!campaign.user.inventory.find(item => item.key === 'heaxy_axe')
  },
  {
    view: () => <>
      Now equip this weapon on <CharacterVisual type="barbarian" />Graut.
    </>,
    hint: { key: 'teampopup', char: 'tutorial_barbarian', tab: 'equipment', slot: 'primary', item: 'heaxy_axe' },
    toNext: (campaign) => campaign.user.getHeroById('barbarian')!.equipment.primary?.key === 'heaxy_axe'
  },
  {
    view: () => <>
      Instead of using <AbilityVisual ability="Punch" />{' '}Punch, change your strategy to
      hitting with your primary weapon <AbilityVisual ability="PrimaryWeaponAttack" />.
    </>,
    hint: { key: 'teampopup', char: 'tutorial_barbarian', tab: 'strategy', type: 'ability', rule: 0, pos: 0, ability: 'PrimaryWeaponAttack' },
    toNext: (campaign) => campaign.user.getHeroById('barbarian')!.rules[0]?.sequence[0] === 'PrimaryWeaponAttack'
  },
  {
    view: () => <>
      Try to fight the turtle creature ones again!
    </>,
    hint: { key: 'level', mission: 'tutorial_mission_2', action: 'start' },
    toNext: (campaign) => campaign.nextMission!.key === 'tutorial_mission_3'
  },
  {
    view: () => <>
      Excellent, the shield of the turtle was not strong enough to hold off our shiny new axe.
      Give the next fight a go and make sure to take a glimse at the battle report.
    </>,
    hint: { key: 'level', mission: 'tutorial_mission_3', action: 'start' },
    toNext: (campaign) => campaign.nextMission!.deaths >= 0
  },
  {
    view: () => <>
      As you might have seen in the battle report, the <CharacterVisual type="bully" />{' '}bully
      kept knocking us back, making it impossible to land any melee attack.
    </>,
    confirm: `What can we do?`
  },
  {
    view: () => <>
      By increasing our <StatIcon stat="agility" /> agility we will be able to act first in combat.
      Let's head back to the shop and look for a suitable item.
    </>,
    hint: { key: 'shop', tab: 'armor', item: 'blazewing_boots' },
    toNext: (campaign) => !!campaign.user.inventory.find(item => item.key === 'blazewing_boots')
  },
  {
    view: () => <>
      Now equip them, to gain those bonus stats.
    </>,
    hint: { key: 'teampopup', char: 'tutorial_barbarian', tab: 'equipment', slot: 'boots', item: 'blazewing_boots' },
    toNext: (campaign) => campaign.user.getHeroById('barbarian')!.equipment.boots?.key === 'blazewing_boots'
  },
  {
    view: () => <>
      Try again, and watch the difference. The <CharacterVisual type="bully" />{' '}bully will
      still knock our hero back, but before the start of each turn the frontlines are closed
      and we will be able to land melee attacks.
    </>,
    hint: { key: 'level', mission: 'tutorial_mission_3', action: 'start' },
    toNext: (campaign) => campaign.nextMission!.key === 'tutorial_mission_4'
  },
  {
    view: () => <>
      That worked out nicely, on to the next!
    </>,
    hint: { key: 'level', mission: 'tutorial_mission_4', action: 'start' },
    toNext: (campaign) => campaign.nextMission!.deaths >= 0
  },
  {
    view: () => <>
      Tricky: they are using the turtly to soak all the damage while the
      enemies on the flanks are dishing out a lot of damage.
    </>,
    confirm: `Teach me`
  },
  {
    view: () => <>
      Let's learn a new skill to deal with this. Performing a <AbilityVisual ability="Whirlwind" /> Whirlwind
      attack will allow us to damage multiple enemies.
    </>,
    hint: { key: 'teampopup', char: 'tutorial_barbarian', tab: 'abilities', ability: 'Whirlwind' },
    toNext: (campaign) =>
      campaign.user.getHeroById('barbarian')!.abilityTalents.find(tal => tal.key === 'Whirlwind')?.level === 1
  },
  {
    view: () => <>
      Note that upgrading an ability or skill costs skill points, which are earned by completing
      achievements. Let's give <AbilityVisual ability="Whirlwind" /> Whirlwind an additional upgrade to decrease
      the cooldown and increase the damage output.
    </>,
    hint: { key: 'teampopup', char: 'tutorial_barbarian', tab: 'abilities', ability: 'Whirlwind' },
    toNext: (campaign) =>
      campaign.user.getHeroById('barbarian')!.abilityTalents.find(tal => tal.key === 'Whirlwind')?.level === 2
  },
  {
    view: () => <>
      Whenever this ability is available (not on cooldown) we want to perform it, thats why we should
      schedule it in the primary sequence. 
    </>,
    hint: { key: 'teampopup', char: 'tutorial_barbarian', tab: 'strategy', type: 'ability', rule: 0, pos: 0, ability: 'Whirlwind' },
    toNext: (campaign) => campaign.user.getHeroById('barbarian')!.rules[0]?.sequence[0] === 'Whirlwind'
  },
  {
    view: () => <>
      Good. However, on turns when we dont have <AbilityVisual ability="Whirlwind" /> Whirlwind available we
      still want to perform a regular <AbilityVisual ability="PrimaryWeaponAttack" /> attack.
    </>,
    hint: { key: 'teampopup', char: 'tutorial_barbarian', tab: 'strategy', type: 'ability', rule: 1, pos: 0, ability: 'PrimaryWeaponAttack' },
    toNext: (campaign) => campaign.user.getHeroById('barbarian')!.rules[1]?.sequence[0] === 'PrimaryWeaponAttack'
  },
  {
    view: () => <>
      Let's see dive into battle again! 
    </>,
    hint: { key: 'level', mission: 'tutorial_mission_4', action: 'start' },
    toNext: (campaign) => campaign.nextMission!.key === 'tutorial_mission_5'
  },
  {
    view: () => <>
      Great! Off to the final battle.
    </>,
    hint: { key: 'level', mission: 'tutorial_mission_5', action: 'start' },
    toNext: (campaign) => campaign.nextMission!.deaths > 0
  },
  {
    view: () => <>
      The enemy <CharacterVisual type="hedgy" />{' '}hedgies have a reverse damage
      skill which kills any units targeting them directly with a melee attack.
      We should avoid that.
    </>,
    confirm: `How can we beat them then?`
  },
  {
    view: () => <>
      I spotted a <CharacterVisual type="blowfishy" />{' '}blowfishy in the support line
      behind the enemies. When you manage to kill it, it will blow up and kill any nearby
      enemies.
    </>,
    confirm: `Dont we need ranged damage for that?`
  },
  {
    view: () => <>
      Ranged damage would work, but our barbarian has another trick for this kind of
      situation. Go ahead and enable the <AbilityVisual ability="Taunt" />{' '}Taunt ability.
    </>,
    hint: { key: 'teampopup', char: 'tutorial_barbarian', tab: 'abilities', ability: 'Taunt' },
    toNext: (campaign) =>
      campaign.user.getHeroById('barbarian')!.abilityTalents.find(tal => tal.key === 'Taunt')?.level === 1
  },
  {
    view: () => <>
      Assign the taunt command as primary ability.
    </>,
    hint: { key: 'teampopup', char: 'tutorial_barbarian', tab: 'strategy', type: 'ability', rule: 0, pos: 0, ability: 'Taunt' },
    toNext: (campaign) => campaign.user.getHeroById('barbarian')!.rules[0]?.sequence[0] === 'Taunt'
  },
  {
    view: () => <>
      And a melee attacks right after it.
    </>,
    hint: { key: 'teampopup', char: 'tutorial_barbarian', tab: 'strategy', type: 'ability', rule: 0, pos: 1, ability: 'PrimaryWeaponAttack' },
    toNext: (campaign) => campaign.user.getHeroById('barbarian')!.rules[0]?.sequence[1] === 'PrimaryWeaponAttack'
  },
  {
    view: () => <>
      And another one, which should be enough to finish off a <CharacterVisual type="blowfishy" />{' '}blowfishy.
    </>,
    hint: { key: 'teampopup', char: 'tutorial_barbarian', tab: 'strategy', type: 'ability', rule: 0, pos: 2, ability: 'PrimaryWeaponAttack' },
    toNext: (campaign) => campaign.user.getHeroById('barbarian')!.rules[0]?.sequence[2] === 'PrimaryWeaponAttack'
  },
  {
    view: () => <>
      And clear the secondary rule.
    </>,
    hint: { key: 'teampopup', char: 'tutorial_barbarian', tab: 'strategy', type: 'ability', rule: 1, pos: 0, ability: false },
    toNext: (campaign) => campaign.user.getHeroById('barbarian')!.rules.length === 1
  },
  {
    view: () => <>
      Let's try this out.
    </>,
    hint: { key: 'level', mission: 'tutorial_mission_5', action: 'start' },
    toNext: (campaign) => campaign.nextMission!.deaths > 0
  },
  {
    view: () => <>
      Hm, close but no sigare. We schedueled <AbilityVisual ability="Taunt" /> properly,
      but we should avoid hitting any enemies before it gets triggered.
    </>,
    confirm: `But the attack was set behind the taunt?`
  },
  {
    view: () => <>
      By default a rule is performed when at least 1 ability in the sequence is castable.
      In this case <AbilityVisual ability="Taunt" /> was skipped (because it has an initial
      cooldown), but <AbilityVisual ability="PrimaryWeaponAttack" /> was performed.
    </>,
    confirm: `How can we fix this?`
  },
  {
    view: () => <>
      We can add various conditions to a rule. For example to only enable it after a certain
      turn. We should opt for a <ConditionVisual condition="WhenSequenceReady" /> Full sequence ready
      condition, to only activate this rule when all abilities are off cooldown.
    </>,
    hint: { key: 'teampopup', char: 'tutorial_barbarian', tab: 'strategy', type: 'condition', rule: 0, pos: 0, condition: 'WhenSequenceReady' },
    toNext: (campaign) => campaign.user.getHeroById('barbarian')!.rules[0]?.conditions[0]?.key === 'WhenSequenceReady'
  },
  {
    view: () => <>
      See how that works out.
    </>,
    hint: { key: 'level', mission: 'tutorial_mission_5', action: 'start' },
    toNext: (campaign) => !campaign.nextMission
  },
  {
    view: () => <>
      You now know the basics of this game. During the next missions you are completely free to choose whichever
      heros, items and abilities will suit your playstyle. Good luck!
    </>,
    confirm: `Awsome!`
  }
]