import { DamageType } from 'game/core'
import { ShadowStrike, ShadowStrikeConfig } from 'game/extended/creatures/undead/abilities/shadow_strike/shadow_strike'
import { AbilityUI } from 'game/extended/uis/abilities/AbilityUI'
import React from 'react'

import image from './shadow_strike.png'

export const shadow_strike_ui: AbilityUI<'ShadowStrike'> = {
    model: ShadowStrike,
    configs: [
        {
            available: 2,
            cooldown: 4,
            energy: 6
        }
    ],
    image,
    label: 'Shadow strike',
    renderTargets: () => 'Melee',
    renderDescription: () => <>
        Use your primary weapon to strike the enemy, with the the combined
        strength of all your allies (halfed).
    </>,
    renderImpression: (ability: ShadowStrike, UI) => <>
        <UI.Char character={ability.character} />{' '}charges a dark energy and strikes at {' '}<UI.Char character={ability.targets} />
        with an unnatural force.
    </>,
    renderTableStatic: (config: ShadowStrikeConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Damage:</UI.TKey>
            <UI.TVal>Boosted weapon damage</UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (ability: ShadowStrike, UI) => <>
        <UI.TRow>
            <UI.TKey>Bonus:</UI.TKey>
            <UI.TVal><UI.Stat stat="strength" amount={ability.bonusStrength} /></UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Damage:</UI.TKey>
            <UI.TVal><UI.Damage type={DamageType.Melee} damage={ability.damage} /></UI.TVal>
        </UI.TRow>
    </>,
}
