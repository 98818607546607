import { Damage, Effect, EffectDuration, Percentage, Turns } from 'game/core'
import { Memoize } from 'game/util/memoize'

export class WarcryEffect extends Effect<{
    damage_increase: Percentage
    duration: Turns
}>{
    static KEY = 'WarcryEffect' as const

    @Memoize()
    get duration(): EffectDuration {
        return {
            type: 'turns',
            turns: this.state.duration
        }
    }

    @Memoize()
    get damageIncrease(): Percentage {
        return this.state.damage_increase
    }

    // ----- Appliers ----- //
    applyDamageModifier(damage: Damage): Damage {
        return {
            ...damage,
            amount: damage.amount * (1 + this.damageIncrease / 100)
        }
    }
}
