import { Battle, Environment, Time } from 'game/core'
import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'

export interface Action<M, P> {
    time: number
    resolver: { byPath: (battle: Battle, path: P) => M }
    path: P
    callback: (model: M) => Battle
}

export type ModelKey = string
export type ModelID = string

export interface ExtendableGameModelState {
    key: ModelKey
}

export abstract class GameModel<S = {}> {
    env: Environment
    state: S

    constructor(env: Environment, state: S) {
        this.env = env
        this.state = state
    }

    abstract get battle(): Battle

    abstract get directChildren(): Array<GameModel>

    @Memoize()
    get time(): Time {
        return this.battle.time
    }

    @Memoize()
    get children(): Array<GameModel> {
        const result: Array<GameModel> = []
        this.directChildren.forEach(directChild => {
            result.push(directChild)
            directChild.children.forEach(child => result.push(child))
        })
        return result
    }

    performIf<P, M extends GameModel & { path: P }>(
        resolver: { byPath: (battle: Battle, path: M['path']) => Nullable<M> },
        path: M['path'],
        condition: (model: M) => boolean,
        callback: (model: M) => Battle
    ): Battle {
        if (!path) return this.battle
        const model: Nullable<M> = resolver.byPath(this.battle, path)
        if (!model) return this.battle
        if (!condition(model)) return this.battle
        const newBattle = callback(model)
        if (!newBattle) {
            console.log(resolver, path, callback)
            throw new Error(`Callback did not return a valid 'Battle'-object`)
        }
        return newBattle
    }

    perform<P, M extends GameModel & { path: P }>(
        resolver: { byPath: (battle: Battle, path: M['path']) => Nullable<M> },
        path: Nullable<M['path']>,
        callback: (model: M) => Battle
    ): Battle {
        if (!path) return this.battle
        const model: Nullable<M> = resolver.byPath(this.battle, path)
        if (!model) return this.battle
        const newBattle = callback(model)
        if (!newBattle) {
            console.log(resolver, path, callback)
            throw new Error(`Callback did not return a valid 'Battle'-object`)
        }
        return newBattle
    }

    performAlways<P, M extends GameModel & { path: P }>(
        resolver: { byPath: (battle: Battle, path: M['path']) => Nullable<M> },
        path: M['path'],
        callback: (model: Nullable<M>, battle: Battle) => Battle
    ): Battle {
        const model: Nullable<M> = resolver.byPath(this.battle, path)
        const newBattle = callback(model, this.battle)
        if (!newBattle) {
            console.log(resolver, path, callback)
            throw new Error(`Callback did not return a valid 'Battle'-object`)
        }
        return newBattle
    }

    performAll<P, M extends GameModel & { path: P }>(
        resolver: { byPath: (battle: Battle, path: M['path']) => Nullable<M> },
        paths: Array<M['path']>,
        callback: (model: M) => Battle
    ): Battle {
        let newBattle = this.battle
        paths.forEach(path => {
            newBattle = newBattle.perform(resolver, path, callback)
        })
        return newBattle
    }
}

export abstract class ChildGameModel<P extends GameModel, S = {}> extends GameModel<S> {
    parent: P

    constructor(parent: P, state: S) {
        super(parent.env, state)
        this.parent = parent
    }

    @Memoize()
    get battle(): Battle {
        return this.parent.battle
    }
}

export abstract class ExtendableGameModel<
    P extends GameModel = Battle,
    S extends ExtendableGameModelState = ExtendableGameModelState,
    C = {}
    > extends ChildGameModel<P, S> {

    @Memoize()
    get key(): string {
        return this.state.key
    }

    @Memoize()
    get config(): C {
        return this.env.getConfig(this.key)
    }
}
