import { Memoize } from 'game/util/memoize'
import { Requirement } from 'game/core'

export class RequiredEnergy extends Requirement {

    static KEY = 'RequiredEnergy' as const

    @Memoize()
    get isValid() {
        return this.character.energy >= this.ability.energy
    }
}
