import 'views/layout/sidebar/abilityside.scss'

import { Effect } from 'game/core'
import { EffectKey } from 'game/extended/types'
import { getEffectUI } from 'game/extended/uis/effects/effect_uis'
import React from 'react'
import { InfoCols, InfoDescription, InfoVisual } from 'views/layout/info'
import { DefaultUI as UI } from 'views/ui/default_ui'
import { EffectVisual } from 'views/ui/visuals/effectvisual'

export const EffectSide: React.FC<{
    effect: Effect
}> = ({ effect }) => {

    const key = effect.key as EffectKey
    const ui = getEffectUI(key)

    return <div className="effectside">
        <InfoCols>
            <InfoVisual>
                <EffectVisual effect={key} />
            </InfoVisual>
            <InfoDescription>
                {ui && ui.renderDescription(effect, UI)}
            </InfoDescription>
        </InfoCols>
        {ui.renderTable && ui.renderTable(effect, UI)}
        {effect.duration.type === 'turns' && <>
            <UI.TRow>
                <UI.TKey>Duration:</UI.TKey>
                <UI.TVal>
                    <UI.Turns turns={effect.duration.turns} />
                </UI.TVal>
            </UI.TRow>
            <UI.TRow>
                <UI.TKey>Turns left:</UI.TKey>
                <UI.TVal>
                    <UI.Turns turns={effect.duration.turns - effect.turnsPassed} />
                </UI.TVal>
            </UI.TRow>
        </>}
        {effect.duration.type === 'unlimited' && <UI.TRow>
            <UI.TKey>Duration:</UI.TKey>
            <UI.TVal>
                Unlimited
            </UI.TVal>
        </UI.TRow>}
    </div>
}
