import {ConditionKey, ConditionState} from 'game/extended/types'
import {getConditionUI} from 'game/extended/uis/conditions/condition_uis'
import {Nullable} from 'game/util/maybe'
import {Memoize} from 'game/util/memoize'
import {StrategyLine} from 'models/user/hero/strategy/StrategyLine'
import {UserModel} from 'models/user/UserModel'

export type ConditionSlotState = ConditionState | null

export class ConditionSlot  {

    constructor(readonly strategy: StrategyLine, readonly state: ConditionSlotState) {}

    @Memoize()
    get user(): UserModel {
        return this.strategy.user
    }

    @Memoize()
    get label(): string {
        return "condition"
    }

    @Memoize()
    get index(): number {
        return this.strategy.conditions.findIndex(slot => slot === this)
    }

    @Memoize()
    get key(): string {
        return `s${this.strategy.index}c${this.index}`
    }

    @Memoize()
    get conditionKey(): Nullable<ConditionKey> {
        return this.state?.key
    }

    @Memoize()
    get isFilled(): boolean {
        return this.state !== null
    }

    // ----- Calculate new State ----- //
    setKey(key: ConditionKey): UserModel {
        if (key === this.conditionKey) return this.user
        const ui = getConditionUI(key)
        return this.update(ui.defaultState)
    }

    update(state: ConditionState): UserModel {
        return this.strategy.update({
            conditions: this.strategy.conditions.map(slot => {
                if (slot === this) return state
                return slot.state
            })
        })
    }

    remove(): UserModel {
        return this.strategy.update({
            conditions: this.strategy.conditions.map(condition => {
                if (condition === this) return null
                return condition.state
            })
        })
    }
}
