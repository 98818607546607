import { getClassUI } from 'game/extended/uis/types/class_uis'
import { HeroState } from 'models/user/hero/HeroModel'

const level = 1
const ui = getClassUI('barbarian')

export const sample_barbarian: HeroState = {
    id: 'sample_barbarian',
    type: 'barbarian',
    name: 'Graut',
    level: level,
    equipment: {
        primary: 'lion_sword'
    },
    strategy: [
        {
            abilities: [
            ],
            conditions: []
        }
    ],
    stats: {
        ...ui.definition.stats
    },
    abilities: [
        { key: 'DualSwing', level: 1 },
        { key: 'Whirlwind', level: 1 },
        { key: 'RecklessBlow', level: 1 }
    ],
    skills: [
        { key: 'Rampage', level: 1 },
    ],
    skill_points: level,
    achievements: [],
    rewards: []
}
