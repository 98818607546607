import React from 'react'
import { EventUI } from "game/extended/uis/events/EventUI"
import { CharHasNoActiveRule } from 'game/core'

export const char_has_no_active_rule_ui: EventUI<'CharHasNoActiveRule'> = {
    model: CharHasNoActiveRule,

    render: (event: CharHasNoActiveRule, UI) => {
        return <>
            <UI.Char character={event.character} />
            {' has no active rule to cast.'}
        </>
    }
}
