import { Ability, AbilityConfig, Character, DamageNature, DamageType, FullDamage } from 'game/core'
import { Memoize } from 'game/util/memoize'

export type ArcaneCircleConfig = AbilityConfig & {
  damage: number
}

export class ArcaneCircle extends Ability<ArcaneCircleConfig>{
  static KEY = 'ArcaneCircle' as const
  static FIXED_REQUIREMENTS = []

  @Memoize()
  get targets() {
    const result = []
    const enemySide = this.character.side.enemySide
    if (enemySide.front.bottom) result.push(enemySide.front.bottom)
    if (enemySide.support.top) result.push(enemySide.support.top)
    return result
  }

  @Memoize()
  get damage(): FullDamage {
    return this.calculateDamage(
      DamageType.Magic,
      DamageNature.Ranged,
      this.currentConfig.damage
    )
  }

  // ----- Calculate new State ----- //
  performActionImpl() {
    return this.performOnAllTargets(char => {
      if (char.line.position === 'support') {
        return char
          .moveToFront(-10)
          .perform(Character, char.path, char => char.takeDamage(this.damage))
      }
      else {
        return char
          .moveToSupport(10)
          .perform(Character, char.path, char => char.takeDamage(this.damage))
      }
    })
  }
}
