import 'views/ingame/user/team/equip/equiptab.scss'

import { EquipmentKey } from 'game/core'
import { Nullable } from 'game/util/maybe'
import { HeroModel } from 'models/user/hero/HeroModel'
import { EquippedItemModel, ItemModel } from 'models/user/ItemModel'
import React, { useState } from 'react'
import { Equipslot } from 'views/ingame/user/team/equip/equipslot'
import { ItemInfo } from 'views/ingame/user/team/iteminfo'
import { Popuptab } from 'views/ingame/user/team/popuptab'
import { useUser } from 'views/ingame/user/usercontext'
import { Sidebar, SidebarCollection, SidebarItem, SidebarPreview, SidebarTitle } from 'views/layout/sidebar'
import { ItemVisual } from 'views/ui/visuals/itemvisual'
import { useHinter } from 'views/ingame/campaign/tutorial'
import { PopupInfo } from 'views/layout/popup'
import { StatIcon } from 'views/ui/stats/staticon'
import { AbilityVisual } from 'views/ui/visuals/abilityvisual'

export const EquipTab: React.FC<{
    hero: HeroModel
}> = ({ hero }) => {
    const [activeKey, setActiveKey] = useState<Nullable<EquipmentKey>>(null)
    const [sidebarItemID, setSidebarItemID] = useState<null | number>(null)
    const { setUser } = useUser()
    const hint = useHinter()

    const sidebarItem: Nullable<ItemModel> = sidebarItemID === null ? null : hero.user.items[sidebarItemID]

    const itemAttrs = (key: EquipmentKey) => ({
        slot: key,
        hero: hero,
        active: activeKey === key,
        onSelect: () => {
            setActiveKey(activeKey === key ? null : key)
            const item = hero.items.find(item => item.slot === key)
            setSidebarItemID(item ? item.index : null)
        },
        hinted:
            hint.key === 'teampopup' && hint.char === hero.type &&
            hint.tab === 'equipment' && hint.slot === key && !activeKey
    })

    return <Popuptab>
        <PopupInfo>
            <p>Your character has a variety of different stats. They
            can be enhanced by using equipment. A character can equip
            different kinds of armor and 1 or 2 weapons (note that the
            secondary weapon can also be replaced by a shield).</p>

            <p>In addition to supplying certain stats, a weapon can also
            be used to attack during combat. Use
            the <AbilityVisual ability="PrimaryWeaponAttack" /> or <AbilityVisual ability="SecondaryWeaponAttack" /> ability
            to trigger. The <StatIcon stat="energy" />-cost and the range/target of these attacks are
            determined by your currently equiped primary/secondary weapon.</p>
            
            <ul>
                <li>
                    <StatIcon stat="health"/> is the amount of damage you can take, before dieing.
                </li>
                <li>
                    <StatIcon stat="energy" /> is spent whenever you perform a turn, when it runs out,
                    you are forced to <AbilityVisual ability="Rest" />.
                </li>
                <li>
                    <StatIcon stat="strength"/> increases your <StatIcon stat="melee"/>-damage.
                </li>
                <li>
                    <StatIcon stat="agility"/> increases your <StatIcon stat="ranged"/>-damage and determines attack order.
                </li>
                <li>
                    <StatIcon stat="intelligence"/> increases your <StatIcon stat="magic"/>-damage.
                </li>
                <li>
                    <StatIcon stat="armor"/> reduces enemy <StatIcon stat="melee"/>-damage.
                </li>
                <li>
                    <StatIcon stat="shielding"/> reduces enemy <StatIcon stat="ranged"/>-damage.
                </li>
                <li>
                    <StatIcon stat="resistance"/> reduces enemy <StatIcon stat="magic"/>-damage.
                </li>
            </ul>
        </PopupInfo>
        <Sidebar hidden={!activeKey} onhide={() => setActiveKey(null)}>
            <SidebarTitle>
                Select item
            </SidebarTitle>
            <SidebarCollection>
                {hero.user.items.map(item => {
                    if (!activeKey) return null
                    const selected = item === sidebarItem
                    const locked = (item instanceof EquippedItemModel && item.hero !== hero) || !item.isEquipableInSlot(activeKey)
                    return <SidebarItem key={item.id}>
                        <ItemVisual
                            item={item.item}
                            selected={selected}
                            visualMod={{ bw: locked }}
                            locked={locked}
                            highlight={!locked}
                            hinted={
                                hint.key === 'teampopup' &&
                                hint.char === hero.type &&
                                hint.tab === 'equipment' &&
                                hint.item === item.key
                            }
                            onClick={() => {
                                if (locked) {
                                    setSidebarItemID(item.index)
                                    return
                                }
                                const newUser = hero.equip(activeKey as EquipmentKey, item)
                                setUser(newUser)
                                const newHero = newUser.heros.find(h => h.id === hero.id)
                                const newItem = newHero?.items.find(item => item.slot === activeKey)
                                setSidebarItemID(newItem ? newItem.index : item.index)
                            }}
                        />
                    </SidebarItem>
                })}
            </SidebarCollection>
            {!!sidebarItem && <SidebarPreview
                onclose={() => {
                    setUser(hero.unequip(activeKey as EquipmentKey))
                    setSidebarItemID(null)
                }}
            >
                {<ItemInfo item={sidebarItem.item} />}
            </SidebarPreview>}
        </Sidebar>
        <div className="equiptab-items">
            <div className="equiptab-items-stub" />
            <EquiptabSlot {...itemAttrs('headgear')} label="Headgear" />
            <div className="equiptab-items-stub" />
            <EquiptabSlot {...itemAttrs('primary')} label="Primary weapon" />
            <EquiptabSlot {...itemAttrs('armor')} label="Body armor" />
            <EquiptabSlot {...itemAttrs('secondary')}
                locked={hero.equipment.primary?.two_handed}
                label="Secondary weapon"
            />
            <div className="equiptab-items-stub" />
            <EquiptabSlot {...itemAttrs('boots')} label="Boots" />
            <EquiptabSlot {...itemAttrs('trinket')} label="Trinket" />
        </div>
    </Popuptab>
}

export const EquiptabSlot: React.FC<{
    label: string
    slot: EquipmentKey
    hero: HeroModel
    active: boolean
    locked?: boolean
    hinted?: boolean
    onSelect: () => void
}> = ({ label, slot, hero, hinted, locked, active, onSelect }) => {
    const item = hero.equipment[slot]

    return <Equipslot
        slot={slot}
        label={label}
        item={item}
        hinted={hinted}
        locked={locked}
        badge={!!hero.suggestedEquipmentSlots.find(s => s === slot)}
        active={active}
        onClick={onSelect}
    />
}
