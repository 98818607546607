import { Headgear, ItemRarity } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './goblin_helmet.png'

export const goblin_helmet: ItemUI<Headgear> = {
    image,
    label: 'Goblin helmet',
    value: 25,
    item: {
        key: 'goblin_helmet',
        type: 'headgear',
        rarity: ItemRarity.Common,
        stats: {
            strength: 10,
            armor: 12
        }
    }
}
