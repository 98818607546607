import { Character, CharDied, Event, Interrupt, Percentage, Skill, SkillTriggered } from 'game/core'
import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'

export type RampageConfig = {
    hp: Percentage
    energy: number
}

export class Rampage extends Skill<{}, RampageConfig> {

    static KEY = 'Rampage' as const
    static FIXED_REQUIREMENTS = []

    @Memoize()
    get targets(): Nullable<Character> {
        return this.character.meleeTarget
    }

    @Memoize()
    get hpRegenerated(): number {
        return Math.floor(this.currentConfig.hp / 100 * this.character.maxHp)
    }

    @Memoize()
    get energyIncrease(): number {
        return this.currentConfig.energy
    }

    // ----- Interrupts ----- //
    reactToEvent(event: Event): Interrupt {
        if (!(event instanceof CharDied)) return false
        if (event.injury.damage.origin.character !== this.character.id) return false
        return this.battle
            .startEvent(SkillTriggered, { skill: this.path })
            .perform(Character, this.character.path, char =>
                char.heal({
                    origin: this.path,
                    amount: this.hpRegenerated,
                    modifiers: [],
                    original_amount: this.hpRegenerated
                })
            )
            .perform(Character, this.character.path, char =>
                char.changeEnergy(this.energyIncrease)
            )
            .endEvent()
    }
}
