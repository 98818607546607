import { HolyInterventionConfig } from 'game/extended/heros/paladin/holy_intervention/holy_intervention'

export const holy_intervention_config: Array<HolyInterventionConfig> = [
    {
        available: 5,
        cooldown: 6,
        energy: 6
    },
    {
        available: 2,
        cooldown: 5,
        energy: 5
    }
]