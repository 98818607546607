import 'views/ingame/user/team/characterinfo.scss'

import { CoreTypeKey, Stats } from 'game/core'
import { TypeKey } from 'game/extended/types'
import React from 'react'
import { CharacterSlot } from 'views/layout/characterslot'
import { Icon } from 'views/ui/icon'
import { StatIcon } from 'views/ui/stats/staticon'
import { styler } from 'views/layout/util/style'

const style = styler('characterinfo', ['stat'])

export const CharacterInfo: React.FC<{
    type: CoreTypeKey,
    name: string,
    level: number,
    stats: Stats,
    onNext?: false | (() => void)
    onPrevious?: false | (() => void)
}> = ({ type, name, level, stats, onNext, onPrevious }) => {

    return <div className={style()}>
        <div className={style('top')}>
            <div className={style('avatar')}>
                <CharacterSlot type={type as TypeKey} />
            </div>
            {onPrevious && <div className={style('prev')} onClick={onPrevious}>
                <Icon icon="arrow_left" />
            </div>}
            {onNext && <div className={style('next')} onClick={onNext}>
                <Icon icon="arrow_right" />
            </div>}
            <div className={style('info')}>
                <div className={style('info-name')}>{name}</div>
                <div className={style('info-level')}>Lv. {level}</div>
            </div>
        </div>
        <div className={style('stats')}>
            <CharacterinfoStat label="Health" value={stats.health} icon="health" />
            <CharacterinfoStat label="Energy" value={stats.energy} icon="energy" />
            <div className={style('stats-spacer')} />
            <CharacterinfoStat label="Strength" value={stats.strength} icon="strength" />
            <CharacterinfoStat label="Agility" value={stats.agility} icon="agility" />
            <CharacterinfoStat label="Intelligence" value={stats.intelligence} icon="intelligence" />
            <div className={style('stats-spacer')} />
            <CharacterinfoStat label="Armor" value={stats.armor} icon="armor" />
            <CharacterinfoStat label="Shielding" value={stats.shielding} icon="shielding" />
            <CharacterinfoStat label="Resistance" value={stats.resistance} icon="resistance" />
        </div>
    </div>
}

export const CharacterinfoStat: React.FC<{
    icon: keyof Stats
    label: string
    value: number | string
}> = ({ icon, label, value }) => {

    return <div className={style.stat()}>
        {icon && <StatIcon stat={icon} />}
        <div className={style.stat('label')} >
            {label}
        </div>
        <div className={style.stat('value')} >
            {value}
        </div>
    </div>
}
