import { useApp } from 'AppContext'
import { sample_endless } from 'data/sample/sample_endless'
import { default_env } from 'game/extended/default_env'
import { EndlessModel } from 'models/endless/EndlessModel'
import { generateEndlessLevel } from 'models/endless/generate_endless_level'
import { UserModel } from 'models/user/UserModel'
import React, { createContext, useContext, useEffect } from 'react'
import { DndProvider } from 'react-dnd'
import TouchBackend from 'react-dnd-touch-backend'
import { Route, Switch, useHistory } from 'react-router'
import { Finished } from 'views/ingame/endless/Finished'
import { MissionPopup } from 'views/ingame/campaign/missions/missionpopup'
import { EndlessUI } from 'views/ingame/endless/EndlessUI'
import { EndlessNextLevel } from 'views/ingame/endless/level/EndlessNextLevel'
import { EndlessPrevLevel } from 'views/ingame/endless/level/EndlessPrevLevel'
import { EndlessReplay } from 'views/ingame/endless/level/endlessreplay'
import { Academy } from 'views/ingame/user/academy/academy'
import { ItemsPopup } from 'views/ingame/user/shop/itemspopup'
import { Surrender } from 'views/ingame/user/surrender'
import { Teampopup } from 'views/ingame/user/team/teampopup'
import { UserContextProvider } from 'views/ingame/user/usercontext'
import { Library } from 'views/library/library'
import { LinkContext } from 'views/link'

export const EndlessContext = createContext<{
    endless: EndlessModel,
    setEndless: (endless: EndlessModel) => void
}>(null as any)

export const EndlessContextProvider: React.FC<{
    endless: EndlessModel,
    setEndless: (endless: EndlessModel | null) => void
}> = ({ endless, setEndless, children }) => {
    const history = useHistory()
    return (
        <EndlessContext.Provider value={{ endless, setEndless }}>
            <LinkContext.Provider value={{
                processTo: to => `/endless${to}`
            }}>
                <UserContextProvider
                    user={endless.user}
                    setUser={user => setEndless(endless.updateUser(user))}
                    finalize={() => {
                        setEndless(null)
                        history.push('/')
                    }}
                >
                    {children}
                </UserContextProvider>
            </LinkContext.Provider>
        </EndlessContext.Provider >
    )
}

export const useEndless = () => {
    const { endless, setEndless } = useContext(EndlessContext)

    return {
        endless,
        setEndless,
        user: endless.user,
        setUser: (user: UserModel) => setEndless(endless.updateUser(user))
    }
}

export const Endless: React.FC = () => {
    const { endless, setEndless } = useApp()

    useEffect(() => {
        if (endless) return
        if (process.env.REACT_APP_CAMPAIGN === 'test') {
            setEndless(new EndlessModel(sample_endless, default_env).shuffleShop())
            return
        }
        setEndless(new EndlessModel({
            user: {
                counter: 0,
                heros: [],
                inventory: [],
                money: 225
            },
            level: generateEndlessLevel(1),
            levels_finished: 0
        }, default_env).shuffleShop())
    }, [endless, setEndless])

    if (!endless) return null

    return <DndProvider backend={TouchBackend} options={{ enableHoverOutsideTarget: true }}>
        <EndlessContextProvider
            endless={endless}
            setEndless={setEndless}
        >
            <Switch>
                <Route path="/endless/replay/:round?/:turn?/:char?/:tab?" component={EndlessReplay} />
                <Route path="/endless/library" component={Library} />
                <Route
                    render={() => <EndlessUI>
                        <Switch>
                            <Route path="/endless/finished" component={Finished} />
                            <Route path="/endless/surrender" component={Surrender} />
                            <Route path="/endless/prev" component={EndlessPrevLevel} />
                            <Route path="/endless/next" component={EndlessNextLevel} />
                            <Route path="/endless/team/:char/:tab?" component={Teampopup} />
                            <Route path="/endless/shop/:tab?" component={ItemsPopup} />
                            <Route path="/endless/academy" component={Academy} />
                            <Route path="/endless/mission/:mission" component={MissionPopup} />
                        </Switch>
                    </EndlessUI>}>
                </Route>
            </Switch>
        </EndlessContextProvider>
    </DndProvider>
}
