import { Armor, ItemRarity } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './inquisitor_armor.png'

export const inquisitor_armor: ItemUI<Armor> = {
    image,
    label: 'Inquisitor armor',
    value: 85,
    item: {
        key: 'inquisitor_armor',
        type: 'armor',
        rarity: ItemRarity.Epic,
        stats: {
            strength: 50,
            agility: 20,
            health: 35,
            energy: 2
        }
    }
}
