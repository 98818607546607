import { Ability, AbilityConfig, DamageNature, DamageType, FullDamage, Character } from 'game/core'
import { Memoize } from 'game/util/memoize'

export type JudgementHammerConfig = AbilityConfig & {
    damage: number
}

export class JudgementHammer extends Ability<JudgementHammerConfig>{

    static KEY = 'JudgementHammer' as const
    static FIXED_REQUIREMENTS = []

    @Memoize()
    get targets() {
        return this.character.rangedTarget
    }

    @Memoize()
    get damage(): FullDamage {
        return this.calculateDamage(
            DamageType.Ranged,
            DamageNature.Ranged,
            this.currentConfig.damage
        )
    }

    // ----- Calculate new State ----- //
    performActionImpl() {
        if (!this.targets) return this.battle
        return this.battle
            .perform(Character, this.targets.path, char => char.takeDamage(this.damage))
            .performIf(Character, this.targets.path,
                (char: Character) => char.line.position === 'support',
                char => char.stun(1, this.path)
            )
    }
}
