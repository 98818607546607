import { CreatureUI } from 'game/extended/uis/types/creature_uis'

import head from './bully_head.png'
import circle from './bully_circle.png'

export const bully: CreatureUI = {
  label: 'Bully',
  definition: lvl => ({
    stats: {
      health: 30,
      energy: 30,
      intelligence: 20,
      strength: 80,
      agility: 20,
      armor: 0,
      shielding: 0,
      resistance: 0
    },
    strategy: [
      'Pushback',
      'Punch'
    ],
    skills: []
  }),
  circle: {
    src: circle,
    width: 200,
    height: 150
  },
  head
}
