import { Environment } from 'game/core'
import { immobilized_ui } from 'game/extended/core/effects/immobilized/immobilized_ui'
import { stunned_ui } from 'game/extended/core/effects/stunned/stunned_ui'
import { burp_effect_ui } from 'game/extended/creatures/goblins/abilities/burp/burp_effect_ui'
import { charged_bomb_ui } from 'game/extended/creatures/goblins/abilities/charge_bomb/charged_bomb_ui'
import { cursed_weapons_effect_ui } from 'game/extended/creatures/goblins/abilities/cursed_weapons/cursed_weapons_effect_ui'
import { inspire_effect_ui } from 'game/extended/creatures/goblins/abilities/inspire/inspire_effect_ui'
import {
    magic_interrupt_effect_ui,
} from 'game/extended/creatures/goblins/abilities/magic_interrupt/magic_interrupt_effect_ui'
import { lifedrain_effect_ui } from 'game/extended/creatures/undead/abilities/lifedrain/lifedrain_effect_ui'
import { seduce_effect_ui } from 'game/extended/creatures/undead/abilities/seduce/seduce_effect_ui'
import { unholy_curse_effect_ui } from 'game/extended/creatures/undead/abilities/unholy_curse/unholy_curse_effect_ui'
import { berserk_effect_ui } from 'game/extended/heros/barbarian/berserk/berserk_effect_ui'
import { undying_fury_effect_ui } from 'game/extended/heros/barbarian/undying_fury/undying_fury_effect_ui'
import { warcry_effect_ui } from 'game/extended/heros/barbarian/warcry/warcry_effect_ui'
import { afterburn_effect_ui } from 'game/extended/heros/mage/afterburn/afterburn_effect_ui'
import { arcane_curse_effect_ui } from 'game/extended/heros/mage/arcane_curse/arcane_curse_effect_ui'
import { arcane_growth_effect_ui } from 'game/extended/heros/mage/arcane_growth/arcane_growth_effect_ui'
import { burning_weapon_effect_ui } from 'game/extended/heros/mage/burning_weapon/burning_weapon_effect_ui'
import { ethernal_fire_effect_ui } from 'game/extended/heros/mage/ethernal_fire/ethernal_fire_effect_ui'
import { immolation_effect_ui } from 'game/extended/heros/mage/immolation/immolation_effect_ui'
import { immunity_effect_ui } from 'game/extended/heros/paladin/immunity/immunity_effect_ui'
import { penetrating_blow_effect_ui } from 'game/extended/heros/paladin/penetrating_blows/penetrating_blows_effect_ui'
import { united_effect_ui } from 'game/extended/heros/paladin/united/united_effect_ui'
import { ambusher_effect_ui } from 'game/extended/heros/ranger/ambusher/ambusher_effect_ui'
import { poisoned_shot_effect_ui } from 'game/extended/heros/ranger/poisoned_shot/poisoned_shot_effect_ui'
import { true_sight_effect_ui } from 'game/extended/heros/ranger/true_sight/true_sight_effect_ui'
import { EffectKey, effects } from 'game/extended/types'
import { EffectUI } from 'game/extended/uis/effects/EffectUI'
import { keysof } from 'game/util/keysof'

export const effect_uis: {
    [K in EffectKey]: EffectUI<K>
} = {
    //Mage
    BurningWeaponEffect: burning_weapon_effect_ui,
    AfterburnEffect: afterburn_effect_ui,
    ImmolationEffect: immolation_effect_ui,
    ArcaneGrowthEffect: arcane_growth_effect_ui,
    EthernalFireEffect: ethernal_fire_effect_ui,
    ArcaneCurseEffect: arcane_curse_effect_ui,

    //Barbarian
    WarcryEffect: warcry_effect_ui,
    UndyingFuryEffect: undying_fury_effect_ui,
    BerserkEffect: berserk_effect_ui,

    //Ranger
    PoisonedShotEffect: poisoned_shot_effect_ui,
    AmbusherEffect: ambusher_effect_ui,

    //Paladin
    TrueSightEffect: true_sight_effect_ui,
    ImmunityEffect: immunity_effect_ui,
    PenetratingBlowsEffect: penetrating_blow_effect_ui,
    UnitedEffect: united_effect_ui,

    //Goblins
    ChargedBomb: charged_bomb_ui,
    InspireEffect: inspire_effect_ui,
    BurpEffect: burp_effect_ui,
    MagicInterruptEffect: magic_interrupt_effect_ui,
    CursedWeaponsEffect: cursed_weapons_effect_ui,

    //Undead
    SeduceEffect: seduce_effect_ui,
    UnholyCurseEffect: unholy_curse_effect_ui,
    LifedrainEffect: lifedrain_effect_ui,

    //General
    Stunned: stunned_ui,
    Immobilized: immobilized_ui
}

export function registerEffects(env: Environment) {
    keysof(effects).forEach(key => {
        env.registerModel(key, effects[key] as any, {})
    })
}

export function getEffectUI(key: EffectKey): EffectUI<any> {
    return effect_uis[key]
}
