import { PoisonedShotConfig } from 'game/extended/heros/ranger/poisoned_shot/poisoned_shot'

export const poisoned_shot_config: Array<PoisonedShotConfig> = [
    {
        available: 1,
        cooldown: 5,
        poison_damage: 3,
        poison_duration: 3,
        energy: 'unknown' as const
    },
    {
        available: 1,
        cooldown: 4,
        poison_damage: 5,
        poison_duration: 4,
        energy: 'unknown' as const
    },
    {
        available: 0,
        cooldown: 4,
        poison_damage: 7,
        poison_duration: 5,
        energy: 'unknown' as const
    }
]