import { Armor, ItemRarity } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './nightmare_armor.png'

export const nightmare_armor: ItemUI<Armor> = {
    image,
    label: 'Nightmare armor',
    value: 110,
    item: {
        key: 'nightmare_armor',
        type: 'armor',
        rarity: ItemRarity.Rare,
        stats: {
            strength: 80,
            agility: 40,
            armor: 50,
            resistance: -40,
            energy: -3
        }
    }
}
