import { useAds } from 'ads/ads'
import { MissionKey } from 'models/campaign/ChapterModel'
import React from 'react'
import { useParams } from 'react-router'
import { useCampaign } from 'views/ingame/campaign/Campaign'
import { Level } from 'views/ingame/user/levels/level'
import { Redirect, useLinker } from 'views/link'

export const MissionPopup: React.FC = () => {
    const { campaign, setCampaign } = useCampaign()
    const { mission: missionKey } = useParams<{ mission: MissionKey }>()
    const { goTo } = useLinker()
    const { timeLeft, openPopup } = useAds()

    const chapter = campaign.chapter
    const mission = chapter.missions.find(mission => mission.key === missionKey)
    if (!mission) return <Redirect to='/' />

    return <Level
        label={mission.label}
        level={mission}
        toNext={!mission.nextMission ? undefined : () => {
            goTo(`/mission/${mission.nextMission?.key}`)
        }}
        toPrev={!mission.previousMission ? undefined : () => {
            goTo(`/mission/${mission.previousMission?.key}`)
        }}
        onStart={() => {
            if (!timeLeft && mission.deaths >= 3) {
                openPopup()
                return
            }
            const newCampaign = mission.fight()
            setCampaign(newCampaign)
            goTo(`/replay`)
        }}
    />
}
