import { sample_user } from 'data/sample/sample_user'
import { EndlessState } from 'models/endless/EndlessModel'
import { generateEndlessLevel } from 'models/endless/generate_endless_level'

export const sample_endless: EndlessState = {
  user: {
    ...sample_user,
    money: 500,
  },
  deaths: 2,
  level: generateEndlessLevel(1),
  levels_finished: 3,
}
