import { Headgear, ItemRarity } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './skeleton_wizard_hood.png'

export const skeleton_wizard_hood: ItemUI<Headgear> = {
  image,
  label: 'Skeleton wizard hood',
  value: 65,
  item: {
    key: 'skeleton_wizard_hood',
    type: 'headgear',
    rarity: ItemRarity.Epic,
    stats: {
      resistance: 20,
      intelligence: 30,
      health: 10
    }
  }
}
