import { Ability, AbilityConfig, DamageNature, DamageType, FullDamage, RequiredMobility, Requirement } from 'game/core'
import { Memoize } from 'game/util/memoize'

export type PunchConfig = AbilityConfig & {
    damage: number
}

export class Punch extends Ability<PunchConfig>{

    static KEY = 'Punch' as const
    static FIXED_REQUIREMENTS = [
        Requirement.init(RequiredMobility, {})
    ]

    @Memoize()
    get targets() {
        return this.character.meleeTarget
    }

    @Memoize()
    get damage(): FullDamage {
        return this.calculateDamage(
            DamageType.Melee,
            DamageNature.Melee,
            this.currentConfig.damage
        )
    }

    // ----- Calculate new State ----- //
    performActionImpl() {
        if (!this.targets) return this.battle
        return this.targets.takeDamage(this.damage)
    }
}
