import { Ability, AbilityConfig, Character, FullHealing } from 'game/core'
import { Memoize } from 'game/util/memoize'

export type CureConfig = AbilityConfig & {
    healing: number
}

export class Cure extends Ability<CureConfig>{

    static KEY = 'Cure' as const
    static FIXED_REQUIREMENTS = []

    @Memoize()
    get targets(): Array<Character> {
        const secondTarget = this.character.line.position === 'front' ?
            this.character.behindAlly : this.character.frontAlly
        if (!secondTarget) return [this.character]
        return [this.character, secondTarget]
    }

    @Memoize()
    get healing(): FullHealing {
        return this.calculateHealing(
            this.currentConfig.healing
        )
    }

    @Memoize()
    get targetsBesideHimself(): Array<Character> {
        return this.targets.filter(char => char !== this.character)
    }

    // ----- Calculate new State ----- //
    performActionImpl() {
        return this.performOnAllTargets(char =>
            char.heal(this.healing)
        )
    }
}
