import { ChargedBomb } from 'game/extended/creatures/goblins/abilities/charge_bomb/charged_bomb'
import image from './charge_bomb.png'
import { EffectUI } from 'game/extended/uis/effects/EffectUI'
import React from 'react'

export const charged_bomb_ui: EffectUI<'ChargedBomb'> = {
    model: ChargedBomb,
    label: 'Charged bomb',
    image: image,
    renderDescription: (effect: ChargedBomb) => <span>
        This goblin has an infused bomb, ready to be tossed.
    </span>
}
