import './lineup.scss'

import { achievement_uis } from 'data/user/achievements/achievements'
import { TypeKey } from 'game/extended/types'
import { Nullable } from 'game/util/maybe'
import { HeroBattleReward } from 'models/user/hero/HeroModel'
import React from 'react'
import { CharacterSlot } from 'views/layout/characterslot'
import { styler } from 'views/layout/util/style'
import { useLinker } from 'views/link'
import { Icon } from 'views/ui/icon'
import { DOMAttrs, Mod } from 'views/ui/ui_types'
import { TutorialHint } from 'views/ingame/campaign/tutorial'

const style = styler('lineup', ['line', 'spacer', 'circle', 'rewards'])

export const Lineup: React.FC = ({ children }) => {

    return <div className={style()}>
        {children}
    </div>
}

export const LineupSpacer: React.FC = ({ children }) => {
    return <div className={style.spacer()} >
        {children}
    </div>
}

export const LineupLine: React.FC = ({ children }) => {

    return <div className={style.line()}>
        {children}
    </div>
}

export const LineupCircle: React.FC<{
    type: Nullable<TypeKey>
    mod?: Mod<'mirrored' | 'grayscale'>
    active: boolean
    reward?: HeroBattleReward
    link?: string
    hinted?: boolean
} & DOMAttrs> = ({ type, mod, hinted, active, reward, link, ...attrs }) => {
    const hasRewards = !!reward?.levels
    const { goTo } = useLinker()

    return <div className={style.circle().mergeProps(attrs).mod(mod).mod({ rewards: hasRewards }).is({ active })} {...attrs}>
        <div className={style.circle('slot')} onClick={() => {
            if (!link) return
            goTo(link)
        }}>
            <CharacterSlot type={type} />
        </div>
        {hinted && <TutorialHint />}
        {hasRewards && <div className={style.circle('rewards')} >
            <LineupRewards reward={reward as HeroBattleReward} />
        </div>}
    </div>
}

export const LineupRewards: React.FC<{
    reward: HeroBattleReward
}> = ({ reward }) => {
    if (!reward || !reward.levels) return null

    return <div className={style.rewards()}>
        <div className={style.rewards('achievements')}>
            {reward.achievements.map((key, i) => {
                const ui = achievement_uis[key]
                return <div className={style.rewards('achievements-item')} key={i}>
                    <Icon icon={ui.icon} />
                </div>
            })}
        </div>
        <div className={style.rewards('levels')}>
            +{reward.levels} Level{reward.levels > 1 ? 's' : ''}
        </div>
    </div>
}
