import { CoreTypeKey, Type } from 'game/core'
import { TypeKey } from 'game/extended/types'
import { getTypeUI } from 'game/extended/uis/types/type_uis'
import { Nullable } from 'game/util/maybe'
import React from 'react'
import { DOMAttrs } from 'views/ui/ui_types'
import { Visual, VisualAttrs } from 'views/ui/visual'

export const CharacterVisual: React.FC<{
    type: Nullable<Type | CoreTypeKey>
} & VisualAttrs & DOMAttrs> = attrs => {

    const { type, ...rest } = attrs

    if (!type) return <Visual {...rest} />

    const key = (typeof type === 'string' ? type : type.key) as TypeKey
    const ui = getTypeUI(key)

    if (!ui) return <Visual {...attrs} unknown={true} />

    return <Visual {...attrs}>
        <img src={ui.head} alt="head" style={{ height: '1em', width: 'auto' }} />
    </Visual>
}
