import './header.scss'

import React from 'react'
import { Link as ReactLink } from 'react-router-dom'
import { styler } from 'views/layout/util/style'
import { Icon, IconKey } from 'views/ui/icon'
import { AnchorAttrs, Mod } from 'views/ui/ui_types'

const style = styler(
    'header',
    ['hamburger', 'group', 'stat', 'home']
)

export const Header: React.FC<{
    mod?: Mod<'home'>
}> = ({ children, mod }) => {

    return <div className={style().mod(mod)} >
        {children}
    </div>
}

export const HeaderGroup: React.FC = ({ children }) => {

    return <div className="header_group">
        {children}
    </div>
}

export const HeaderHome: React.FC = () => {

    return <ReactLink to="/" className={style.home()}>
        <Icon icon="hamburger" />
    </ReactLink>
}


export const HeaderStat: React.FC<{
    icon: IconKey,
    value: number
} & AnchorAttrs> = ({ icon, value, children, ...rest }) => {

    return <a className={style.stat()} {...rest}>
        <div className={style.stat('value')}>
            {value}
        </div>
        <Icon className={style.stat('icon')} icon={icon} />
    </a>
}
