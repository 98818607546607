import { Damage, Skill, SkillConfig, DamageType } from 'game/core'
import { Memoize } from 'game/util/memoize'

export interface TrueShotsConfig extends SkillConfig {
    damage: number
}

export class TrueShots extends Skill<{}, TrueShotsConfig>{

    static KEY = 'TrueShots' as const

    @Memoize()
    get bonusDamage(): number {
        return this.currentConfig.damage
    }

    // ----- Appliers ----- //
    applyDamageModifier(damage: Damage): Damage {
        if (damage.type !== DamageType.Ranged) return damage
        return {
            ...damage,
            amount: damage.amount + this.bonusDamage
        }
    }
}
