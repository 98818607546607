import { tutorial_barbarian } from 'game/extended/heros/barbarian/tutorial_barbarian'
import { barbarian } from 'game/extended/heros/barbarian/barbarian'
import barbarian_references from 'game/extended/heros/barbarian/barbarian_references'
import { mage } from 'game/extended/heros/mage/mage'
import mage_references from 'game/extended/heros/mage/mage_references'
import { paladin } from 'game/extended/heros/paladin/paladin'
import paladin_references from 'game/extended/heros/paladin/paladin_references'
import { ranger } from 'game/extended/heros/ranger/ranger'
import ranger_references from 'game/extended/heros/ranger/ranger_references'

export const heros = {
    types: {
        tutorial_barbarian,
        barbarian,
        mage,
        paladin,
        ranger
    },
    abilities: {
        ...barbarian_references.abilities,
        ...mage_references.abilities,
        ...paladin_references.abilities,
        ...ranger_references.abilities
    },
    skills: {
        ...barbarian_references.skills,
        ...mage_references.skills,
        ...paladin_references.skills,
        ...ranger_references.skills
    },
    events: {
        ...barbarian_references.events,
        ...mage_references.events,
        ...paladin_references.events,
        ...ranger_references.events
    },
    effects: {
        ...barbarian_references.effects,
        ...mage_references.effects,
        ...paladin_references.effects,
        ...ranger_references.effects
    },
    requirements: {
        ...barbarian_references.requirements,
        ...mage_references.requirements,
        ...paladin_references.requirements,
        ...ranger_references.requirements
    }
}
