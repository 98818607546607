import { Ability, AbilityConfig, Character, Turns } from 'game/core'
import { Memoize } from 'game/util/memoize'

import { ImmunityEffect } from './immunity_effect'

export type ImmunityConfig = AbilityConfig & {
    duration: Turns
}

export class Immunity extends Ability<ImmunityConfig>{

    static KEY = 'Immunity' as const
    static FIXED_REQUIREMENTS = []

    @Memoize()
    get targets(): Character {
        return this.character
    }

    @Memoize()
    get duration(): Turns {
        return this.currentConfig.duration
    }

    // ----- Calculate new State ----- //
    performActionImpl() {
        if (!this.targets) return this.battle
        return this.targets.addEffect(ImmunityEffect, {
            origin: this.path,
            duration: this.duration
        })
    }
}


