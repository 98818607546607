import { RecklessBlowConfig } from 'game/extended/heros/barbarian/reckless_blow/reckless_blow'

export const reckless_blow_config: Array<RecklessBlowConfig> = [
    {
        available: 2,
        cooldown: 4,
        energy: 4,
        target_damage: 10,
        own_damage: 8
    },
    {
        available: 2,
        cooldown: 4,
        energy: 4,
        target_damage: 15,
        own_damage: 6
    },
    {
        available: 1,
        cooldown: 3,
        energy: 4,
        target_damage: 20,
        own_damage: 5
    }
]