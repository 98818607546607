import 'views/ingame/user/team/equip/equipslot.scss'

import { EquipmentKey, Item } from 'game/core'
import { Nullable } from 'game/util/maybe'
import React from 'react'
import { TutorialHint } from 'views/ingame/campaign/tutorial'
import { Weaponstats } from 'views/ingame/user/team/equip/weaponstats'
import { ItemStats } from 'views/ingame/user/team/itemstats'
import { BadgeFloating } from 'views/layout/badge'
import { styler } from 'views/layout/util/style'
import { Icon } from 'views/ui/icon'
import { DOMAttrs } from 'views/ui/ui_types'
import { ItemVisual } from 'views/ui/visuals/itemvisual'

const style = styler('equipslot', ['item'])

export const Equipslot: React.FC<{
    slot: EquipmentKey
    label: string
    item: Nullable<Item>
    active: boolean
    badge?: boolean
    locked?: boolean
    hinted?: boolean
} & DOMAttrs> = ({ slot, label, hinted, item, active, badge, locked, ...rest }) => {
    return <div {...rest} className={style().is({ filled: !!item, locked, active }).mod(slot).mergeProps(rest)}>
        {hinted && <TutorialHint />}
        {badge && <BadgeFloating amount={true} />}
        {!item && <div className={style('content')}>
            <div className={style('icon')}>
                <Icon icon={slot === 'armor' ? 'body' : slot} />
            </div>
            <div className={style('label')}>{label}</div>
        </div>}
        {item && <EquipslotItem item={item} />}
    </div>
}

export const EquipslotItem: React.FC<{
    item: Item
}> = ({ item }) => {
    return <div className={style.item()}>
        <div className={style.item('header')}>
            <div className={style.item('header-visual')}>
                <ItemVisual item={item} />
            </div>
            {item.type === 'weapon' && <div className={style.item('header-extra')}>
                <Weaponstats weapon={item} />
            </div>}
        </div>
        <div className={style.item('stats')}>
            <ItemStats item={item} />
        </div>
    </div>
}
