import { Item } from 'game/core'
import {
    ArmorKey,
    armors,
    boots,
    BootsKey,
    headgear,
    HeadgearKey,
    ItemKey,
    items,
    shields,
    TrinketKey,
    trinkets,
    WeaponKey,
    ShieldKey,
    weapons,
} from 'game/extended/types'
import { Nullable } from 'game/util/maybe'
import { ImageType } from 'views/ui/ui_types'

export type ItemUIKey = keyof typeof items

export type ItemUI<I extends Item = Item> = {
    image: ImageType
    label: string
    value: number
    item: I
}

export type EquipmentDefinition = {
    primary?: WeaponKey,
    secondary?: WeaponKey | ShieldKey,
    armor?: ArmorKey,
    headgear?: HeadgearKey,
    trinket?: TrinketKey,
    boots?: BootsKey
}

export const equipmentFromDef = (eq: EquipmentDefinition) => {
    return {
        primary: eq.primary && weapons[eq.primary].item,
        secondary: eq.secondary && { ...weapons, ...shields }[eq.secondary].item,
        armor: eq.armor && armors[eq.armor].item,
        boots: eq.boots && boots[eq.boots].item,
        headgear: eq.headgear && headgear[eq.headgear].item,
        trinket: eq.trinket && trinkets[eq.trinket].item
    }
}

export const getItem = (key: ItemKey) => {
    return items[key].item
}

export const getItemUI = (key: ItemKey) => {
    const item: Nullable<ItemUI<any>> = items[key as ItemUIKey]
    return item
}
