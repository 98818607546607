import { Character, CharDied, Event, Interrupt, Skill, SkillTriggered } from 'game/core'

export type ExplosiveConfig = {}

export class Explosive extends Skill<{}, ExplosiveConfig>{
    static KEY = 'Explosive' as const

    // ----- Appliers ----- //
    reactToEvent(event: Event): Interrupt {
        if (!(event instanceof CharDied)) return false
        if (event.name !== this.character.name) return false

        return this.battle
            .startEvent(SkillTriggered, { skill: this.path })
            .performAll(
                Character,
                this.character.surroundings.map(char => char.path),
                char => char.instantKill(this.path)
            )
            .endEvent()
    }
}
