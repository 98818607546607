import { Battle, Effect, Skill, SkillConfig, SkillPath, Stats, Turns } from 'game/core'
import { Memoize } from 'game/util/memoize'

export interface EthernalFireEffectConfig extends SkillConfig {
    skill: SkillPath
    intelligence: number
    duration: Turns
}

export class EthernalFireEffect extends Effect<EthernalFireEffectConfig> {

    static KEY = 'EthernalFireEffect' as const

    @Memoize()
    get duration() {
        return {
            type: 'turns' as const,
            turns: this.state.duration
        }
    }

    @Memoize()
    get bonusIntelligence(): number {
        return this.state.intelligence
    }

    @Memoize()
    get skill() {
        return Skill.byPath(this.battle, this.state.skill)
    }

    // ----- Appliers ----- //
    getTargetStats(stats: Stats): Stats {
        return {
            ...stats,
            intelligence: stats.intelligence + this.bonusIntelligence
        }
    }

    // ----- Reactions ----- //
    reactToFinalize(): Battle {
        return this.target.instantKill(this.state.skill)
    }
}
