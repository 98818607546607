import { ConditionUI } from 'game/extended/uis/conditions/ConditionUI'
import React from 'react'

import { ReactComponent as icon } from './alternating.svg'

export const alternating_ui: ConditionUI<'Alternating'> = {
    icon,
    label: 'Alternating',
    defaultState: {
        key: 'Alternating'
    },
    renderDescription: (state) =>
        <span>
            This sequence can not be cast twice in a row.
        </span>,
    renderVisualIcon: (state) => {
        return null
    },
    renderOptions: (state, update) => {
        return null
    }
}