import { ModelID } from 'game/core'
import { ChapterKey, MissionKey } from 'models/campaign/ChapterModel'
import React, { useState } from 'react'
import { useParams } from 'react-router'
import { useCampaign } from 'views/ingame/campaign/Campaign'
import { Redirect, useLinker } from 'views/link'
import { Replay, ReplayContext, ReplayContextPopupCharTab } from 'views/replay/replay'

export type MissionLocation = {
    chapter: ChapterKey,
    mission: MissionKey,
    round?: string,
    turn?: string,
    char?: ModelID,
    tab?: ReplayContextPopupCharTab
}

export const Mission: React.FC = (params) => {
    const { campaign } = useCampaign()
    const { goTo } = useLinker()
    const [logs, setLogs] = useState(false)
    const {
        round: roundKey,
        turn: turnKey,
        char: charID,
        tab
    } = useParams<MissionLocation>()
    if (!campaign.lastReplay) return <Redirect to="/" />
    const { mission, replay } = campaign.lastReplay

    const round = replay.getRoundByKey(roundKey)
    const turn = round.getTurnByKey(turnKey)
    const char = turn.chars.find(char => char.id === charID)
    const popup = (char && tab) ? { char, tab: tab as ReplayContextPopupCharTab } : null

    if (!mission || !turn) return null
    return <ReplayContext.Provider value={{
        mission: mission,
        background: campaign.chapter.background,
        turn: turn,
        logs: logs,
        popup: popup,
        onClose: () => {
            if (campaign.isFinished) {
                goTo(`/score`)
            } else {
                if (mission.deaths === 3) {
                    goTo(`/mission/ad`)
                } else {
                    goTo(`/mission/${mission.key}`)
                }
            }
        },
        setLogs: setLogs,
        setTurn: (turn) => {
            goTo(`/replay/${turn.round.key}/${turn.key}`)
        },
        setPopup: (state) => {
            if (!state) {
                goTo(`/replay/${turn.round.key}/${turn.key}`)
            } else {
                goTo(`/replay/${turn.round.key}/${turn.key}/${state.char.id}/${state.tab}`)
            }
        }
    }}>
        <Replay />
    </ReplayContext.Provider>
}
