import { Visual, VisualAttrs, VisualIcon, VisualImage } from 'views/ui/visual'
import { getAbilityUI } from 'game/extended/uis/abilities/ability_uis'
import { Nullable } from 'game/util/maybe'
import { DOMAttrs, Mod } from 'views/ui/ui_types'
import React from 'react'
import { Icon } from 'views/ui/icon'
import { AbilityKey } from 'game/extended/types'

export const AbilityVisual: React.FC<{
    ability: Nullable<AbilityKey>
    mod?: Mod<'highlight'>
    filled?: number,
    locked?: boolean
} & VisualAttrs & DOMAttrs> = attrs => {
    const { ability, mod, locked, hinted, filled, children, ...rest } = attrs

    if (!ability) return <Visual {...rest} />
    const ui = getAbilityUI(ability)

    return <Visual {...rest} hinted={hinted && !rest.selected}>
        <VisualImage image={ui.image} />
        {children}
        {locked && <VisualIcon>
            <Icon icon="forbidden" />
        </VisualIcon>}
    </Visual>
}
