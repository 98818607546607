import { RequiredValidTarget } from 'game/core'
import { RequirementUI } from 'game/extended/uis/requirements/RequirementUI'
import React from 'react'

export const required_valid_target_ui: RequirementUI<'RequiredValidTarget'> = {
    model: RequiredValidTarget,
    label: 'Required valid target',
    renderDescription: (state) => <>
        This ability requires one or more valid targets to be castable.
    </>
}
