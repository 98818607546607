import { Battle, Environment } from 'game/core'
import { ClassKey, ItemKey } from 'game/extended/types'
import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'
import { AcademyModel } from 'models/user/AcademyModel'
import { HeroModel, HeroState } from 'models/user/hero/HeroModel'
import { ItemModel } from 'models/user/ItemModel'
import { LineupModel, LineupState, LineupType } from 'models/user/LineupModel'
import { ShopModel, ShopState } from 'models/user/ShopModel'

export type HeroID = string

export interface UserState {
    heros: Array<HeroState>
    inventory: Array<ItemKey>
    shop?: ShopState
    money: number
    counter: number
    lineup?: LineupState
}

export type UserConfig = {
    classes: Array<ClassKey>
    items: Array<ItemKey>
    env: Environment
}

export class UserModel {

    constructor(readonly state: UserState, readonly config: UserConfig) { }

    @Memoize()
    get user(): UserModel {
        return this
    }

    @Memoize()
    get env(): Environment {
        return this.config.env
    }

    @Memoize()
    get hasLineup(): boolean {
        return this.lineup.frontHeros.length > 0 || this.lineup.supportHeros.length > 0
    }

    @Memoize()
    get shop(): ShopModel {
        return new ShopModel(this, this.state.shop || { products: [], offering: 0 })
    }

    @Memoize()
    get academy(): AcademyModel {
        return new AcademyModel(this)
    }

    @Memoize()
    get money(): number {
        return this.state.money
    }

    @Memoize()
    get heros(): Array<HeroModel> {
        return this.state.heros.map(state => new HeroModel(this, state))
    }

    @Memoize()
    get inventory(): Array<ItemModel> {
        return this.state.inventory.map(item => new ItemModel(this, item))
    }

    @Memoize()
    get items(): Array<ItemModel> {
        const equipedItems: Array<ItemModel> = []
        this.heros.forEach(hero => {
            hero.items.forEach(item => equipedItems.push(item))
        })

        return [...equipedItems, ...this.inventory]
            .sort((a, b) => {
                if (a.key === b.key) return b.id.localeCompare(a.id)
                return b.key.localeCompare(a.key)
            })
    }

    @Memoize()
    get suggestions(): number {
        return this.heros.reduce((acc, hero) => acc + hero.suggestions, 0)
    }

    @Memoize()
    get lineup(): LineupModel {
        return new LineupModel(this, this.state.lineup || {})
    }

    @Memoize()
    get possibleLineups(): Array<LineupType> {
        const amount = Math.min(this.heros.length, 4)
        const maxPerLine = Math.min(amount, 3)
        const minPerLine = amount - maxPerLine

        const results: Array<LineupType> = []
        for (let front = Math.max(1, minPerLine); front <= maxPerLine; front++) {
            const support = amount - front
            results.push({ front, support })
        }
        return results
    }

    perform<M, P>(resolver: { byPath: (user: UserModel, path: P) => Nullable<M> }, path: P, callback: (model: M) => UserModel): UserModel {
        const model = resolver.byPath(this.user, path)
        if (!model) return this.user
        const newRoot = callback(model)
        if (!newRoot) throw new Error(`Callback did not return a valid 'User'-object`)
        return newRoot
    }

    // ----- Inquiry ----- //
    getHeroById(id: HeroID): Nullable<HeroModel> {
        return this.heros.find(hero => hero.id === id)
    }

    // ----- Calculate new State ----- //
    update(newState: Partial<UserState>) {
        return new UserModel({
            ...this.state,
            ...newState
        }, this.config)
    }

    updateAchievements(battle_id: string, battle: Battle) {
        let result: UserModel = this
        this.heros.forEach(h => {
            const hero = result.getHeroById(h.id)
            if (!hero) return
            result = hero.updateAchievements(battle_id, battle)
        })
        return result
    }

    updateChar(heroState: HeroState, newHeroState: Partial<HeroState>): UserModel {
        return this.update({
            heros: this.state.heros.map(h =>
                h !== heroState ? h : { ...heroState, ...newHeroState }
            )
        })
    }
}
