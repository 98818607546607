import './speeddial.scss'

import React, { createContext, useContext, useState } from 'react'
import { TutorialHint } from 'views/ingame/campaign/tutorial'
import { Badge } from 'views/layout/badge'
import { styler } from 'views/layout/util/style'
import { Link } from 'views/link'
import { Icon, IconKey } from 'views/ui/icon'
import { Mod } from 'views/ui/ui_types'

const styles = styler('speeddial', ['action', 'icon'])
const SpeeddialContext = createContext<{
    state: boolean,
    setState: (newState: boolean) => void
}>({ state: false, setState: () => { } })

export const Speeddial: React.FC<{
    icon: IconKey
    badges?: boolean | number
    mod?: 'mirrored'
    hinted?: boolean
}> = ({ icon, mod, hinted, children, badges, ...attrs }) => {
    const [state, setState] = useState(false)

    return <div {...attrs} className={styles().mergeProps(attrs).mod(mod).is({ open: state })}>
        <div className={styles('button')} onClick={() => setState(!state)}>
            <Icon icon={icon} />
            {!!badges && <div className={styles('button-badges')}>
                <Badge amount={badges} />
            </div>}
            {hinted && !state && <TutorialHint />}
        </div>
        <SpeeddialContext.Provider value={{ state, setState }}>
            <div className={styles('children')}>
                {children}
            </div>
        </SpeeddialContext.Provider>
    </div >
}

export const SpeeddialAction: React.FC<{
    to: string
    badges?: boolean | number
    mod?: Mod<'round'>
    hinted?: boolean
    onClick?: () => void
}> = ({ children, to, onClick, hinted, badges, mod }) => {
    const { state, setState } = useContext(SpeeddialContext)

    return <Link
        to={to}
        className={styles.action().is({ open: state }).mod(mod)}
        onClick={() => {
            if (onClick) onClick()
            setState(false)
        }}
    >
        {children}
        {badges !== undefined && badges !== false && <div className={styles.action('badges')}>
            <Badge amount={badges} />
        </div>}
        {hinted && <TutorialHint />}
    </Link>
}
