import { UndyingFuryConfig } from 'game/extended/heros/barbarian/undying_fury/undying_fury'

export const undying_fury_config: Array<UndyingFuryConfig> = [
    {
        available: 5,
        cooldown: 10,
        energy: 2,
        duration: 2,
    },
    {
        available: 4,
        cooldown: 10,
        energy: 2,
        duration: 3
    }
]