import { Boots, ItemRarity } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './nightmare_boots.png'

export const nightmare_boots: ItemUI<Boots> = {
    image,
    label: 'Nightmare boots',
    value: 90,
    item: {
        key: 'nightmare_boots',
        type: 'boots',
        rarity: ItemRarity.Legendary,
        stats: {
            strength: 85,
            agility: 60,
            health: 15,
            resistance: -40,
            energy: -2
        }
    }
}
