import { EffectAdded } from 'game/core'
import { EventUI } from "game/extended/uis/events/EventUI"
import React from 'react'

export const effect_added_ui: EventUI<'EffectAdded'> = {
    model: EffectAdded,

    render: (event: EffectAdded, UI) => {
        return <>
            <UI.Effect effect={event.effect} />
            {` effect was added to `}
            <UI.Char character={event.character} />
        </>
    }
}
