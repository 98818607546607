import 'views/layout/dnd/draggable.scss'
import React from 'react'
import { styler } from 'views/layout/util/style'
import { useDrag } from 'react-dnd'
import { Portal } from 'react-portal'
import { DNDObject } from 'views/layout/dnd/dndtype'
import { Nullable } from 'game/util/maybe'

const style = styler('draggable', ['clone'])

export const Draggable: React.FC<{
    item: Nullable<DNDObject>,
}> = ({ children, item }) => {
    const [{ clientPos, dragging }, dragRef] = useDrag({
        item: item || { type: 'empty' },
        collect: monitor => {
            monitor.getDropResult()
            return {
                clientPos: !monitor.isDragging() ? null : monitor.getClientOffset(),
                dragging: monitor.isDragging()
            }
        }
    })

    return <div ref={dragRef} className={style().is({ dragging })}>
        <div className={style('original')}>
            {children}
        </div>
        <DraggableClone pos={dragging ? clientPos : null} >
            {children}
        </DraggableClone>
    </div>
}

export const DraggableClone: React.FC<{
    pos: null | { x: number, y: number }
}> = ({ pos, children }) => {

    if (!pos) return null

    const divStyle = {
        transform: `translate3d(${pos.x}px,${pos.y}px, 0)`,
    }

    return <Portal>
        <div className={style.clone()} style={divStyle}>
            {children}
        </div>
    </Portal>
}
