import { Ability, AbilityConfig, Character, DamageNature, DamageType, FullDamage, Turns } from 'game/core'
import { BurpEffect } from 'game/extended/creatures/goblins/abilities/burp/burp_effect'
import { Memoize } from 'game/util/memoize'

export type BurpConfig = AbilityConfig & {
    damage: number,
    duration: Turns
}

export class Burp extends Ability<BurpConfig>{

    static KEY = 'Burp' as const
    static FIXED_REQUIREMENTS = []

    @Memoize()
    get targets(): Array<Character> {
        return this.character.side.enemySide.front.characters
    }

    @Memoize()
    get damage(): FullDamage {
        return this.calculateDamage(
            DamageType.Magic,
            DamageNature.Ranged,
            this.currentConfig.damage
        )
    }

    @Memoize()
    get duration(): Turns {
        return this.currentConfig.duration
    }

    // ----- Calculate new State ----- //
    performActionImpl() {
        return this.performOnAllTargets(char => this.performOnChar(char))
    }

    performOnChar(char: Character) {
        return char
            .perform(Character, char.path, (char: Character) =>
                char.addEffect(BurpEffect, {
                    origin: this.character.path,
                    duration: this.duration
                })
            )
            .perform(Character, char.path, (char: Character) =>
                char.takeDamage(this.damage)
            )
    }
}
