import { Skill, SkillConfig, Turns } from 'game/core'
import { Memoize } from 'game/util/memoize'

export interface PreparedConfig extends SkillConfig {
  reduction: Turns
}

export class Prepared extends Skill<{}, PreparedConfig> {

  static KEY = 'Prepared' as const

  @Memoize()
  get readyReduction(): Turns {
    return this.currentConfig.reduction
  }

  // ----- Appliers ----- //
  applyInitialAvailablityModifier(available: Turns): Turns {
    return Math.max(0, available - this.readyReduction)
  }
}
