import 'views/layout/dnd/droppable.scss'
import React from 'react'
import { styler } from 'views/layout/util/style'
import { DNDObject, DNDType } from 'views/layout/dnd/dndtype'
import { useDrop } from 'react-dnd'
import { DOMAttrs } from 'views/ui/ui_types'
import { UserModel } from 'models/user/UserModel'
import { useUser } from 'views/ingame/user/usercontext'

const style = styler('droppable')

export const Droppable: React.FC<{
    type: DNDType | Array<DNDType>
    performDrop: (object: DNDObject) => UserModel | false
} & DOMAttrs> = ({ type, performDrop, children, ...rest }) => {
    const { setUser } = useUser()
    const [{ hovering }, dropRef] = useDrop({
        accept: type,
        drop: (object: DNDObject) => {
            const newState = performDrop(object)
            if (newState) setUser(newState)
        },
        collect: (monitor) => {
            return {
                hovering: monitor.isOver()
            }
        }
    })

    return <div {...rest} ref={dropRef} className={style().mergeProps(rest).is({ hovering })}>
        {children}
        <div className={style('overlay').is({ hovering })} />
    </div>
}


/*
export const DroppableSimulating: React.FC<{
    type: DNDType | Array<DNDType>
    performDrop: (object: DNDObject) => User | false
} & DOMAttrs> = ({type, performDrop, children, ...rest}) => {
    const [user, setUser] = useUser()
    const [refusedObject, setRefusedObject] = useState<DNDObject | null>(null)
    const [tmpUser, setTmpUser] = useState<User | null>(null)

    const [{object}, dropRef] = useDrop({
        accept: type,
        drop: (object: DNDObject) => {
            if(!tmpUser) {
                const dropResult = performDrop(object)
                if(dropResult !== false) setUser(dropResult)
            }
            setRefusedObject(null)
            setTmpUser(null)
        },
        collect: (monitor) => {
            return {
                object: monitor.isOver() && monitor.getItem() as DNDObject
            }
        }
    })

    useEffect(() => {
        if(!object) {
            setRefusedObject(null)
        }

        if(!object || object === refusedObject) {
            if(tmpUser) {
                setUser(tmpUser)
                setTmpUser(null)
            }
            return
        }

        if(object && !tmpUser) {
            const dropResult = performDrop(object)

            if(dropResult === false){
                setRefusedObject(object)
            } else {
                setTmpUser(user)
                setUser(dropResult)
            }
        }
    }, [object])

    return <div {...rest} ref={dropRef} className={style().mergeProps(rest).is({hovering: !!object})}>
        {children}
    </div>
}
*/
