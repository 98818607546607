import { CharInterrupted } from 'game/core'
import { EventUI } from "game/extended/uis/events/EventUI"
import React from 'react'

export const char_interrupted_ui: EventUI<'CharInterrupted'> = {
    model: CharInterrupted,

    render: (event: CharInterrupted, UI) => {
        return <>
            <UI.Char character={event.target} />
            {' is interrupted and will not be able to finish his current action.'}
        </>
    }
}
