import { PenetratingBlowsConfig } from 'game/extended/heros/paladin/penetrating_blows/penetrating_blows'

export const penetrating_blows_config: Array<PenetratingBlowsConfig> = [
    {
        duration: 2,
        reduction: 15
    },
    {
        duration: 3,
        reduction: 30
    },
    {
        duration: 4,
        reduction: 45
    }
]