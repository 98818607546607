import { Battle, Effect, EffectTriggered, Stats, Turns } from 'game/core'
import { Memoize } from 'game/util/memoize'

export class BerserkEffect extends Effect<{
    stacks: number
    increase: number
    duration: Turns
}>{
    static KEY = 'BerserkEffect' as const

    @Memoize()
    get duration() {
        return {
            type: 'turns' as const,
            turns: this.state.duration
        }
    }

    @Memoize()
    get stacks(): number {
        return this.state.stacks
    }

    @Memoize()
    get increase(): number {
        return this.state.increase
    }

    // ----- Appliers ----- //
    getTargetStats(currentStats: Stats): Stats {
        return {
            ...currentStats,
            strength: Math.max(0, currentStats.strength + this.increase)
        }
    }

    addStack(increase: number): Battle {
        return this.battle
            .startEvent(EffectTriggered, { effect: this.path })
            .perform(Effect, this.path, effect => {
                return (effect as BerserkEffect).updateAndRefresh({
                    stacks: this.stacks + 1,
                    increase: this.increase + increase
                })
            })
            .endEvent()
    }
}
