import 'views/layout/sidebar/abilityside.scss'

import { Environment, Skill, SkillConfig } from 'game/core'
import { SkillKey } from 'game/extended/types'
import { getSkillUI } from 'game/extended/uis/skills/skill_uis'
import { InfoCols, InfoDescription, InfoVisual } from 'views/layout/info'
import { LevelSelector } from 'views/layout/sidebar/levelselector'
import React, { useEffect, useState } from 'react'
import { StatIcon } from 'views/ui/stats/staticon'
import { DefaultUI as UI, DefaultUI } from 'views/ui/default_ui'
import { SkillVisual } from 'views/ui/visuals/skillvisual'

export const SkillSide: React.FC<{
    skill: Skill | SkillKey
    env: Environment
    costs?: Array<number>
    hideLevels?: boolean
    level: number
}> = ({ skill, env, costs, hideLevels, level }) => {

    const skillKey: SkillKey = skill instanceof Skill ? (skill.key as SkillKey) : skill
    const [activeLevel, setActiveLevel] = useState(Math.max(1, level))
    useEffect(() => {
        setActiveLevel(Math.max(1, level))
    }, [level])

    const ui = getSkillUI(skillKey)
    const configs: Array<SkillConfig> = env.getConfig(skillKey)
    if (!configs) return null

    const config = configs[activeLevel - 1]
    if (!config) return null

    const cost = costs ? costs[activeLevel - 1] : 0

    return <div className="skillside">
        {configs.length > 1 && !hideLevels && <LevelSelector
            current={activeLevel}
            levels={configs.length}
            onchange={level => setActiveLevel(level)}
        />}
        <InfoCols>
            <InfoVisual>
                <SkillVisual skill={skillKey} />
            </InfoVisual>
            <InfoDescription>
                {ui && ui.renderDescription(config, DefaultUI)}
            </InfoDescription>
        </InfoCols>
        {skill instanceof Skill && ui.renderTable ?
            ui.renderTable(skill, DefaultUI) :
            ui.renderTableStatic && ui.renderTableStatic(config, DefaultUI)
        }
        {!!cost && <UI.TRow>
            <UI.TKey>Upgrade cost:</UI.TKey>
            <UI.TVal>
                {cost}<StatIcon stat="xp" mod="unit" />
            </UI.TVal>
        </UI.TRow>}
    </div>
}
