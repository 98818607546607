import { CreatureUI } from 'game/extended/uis/types/creature_uis'

import circle from './skeleton_king_circle.png'
import head from './skeleton_king_head.png'

export const skeleton_king: CreatureUI = {
  label: 'Skeleton King',
  definition: lvl => ({
    stats: {
      health: 550,
      energy: 40,
      intelligence: 150,
      strength: 300,
      agility: 120,
      armor: 50,
      shielding: 90,
      resistance: 30
    },
    equipment:
    {
      primary: 'unholy_broken_sword'
    },
    skills: [
      'Undead'
    ],
    strategy: [
      {
        abilities: ['Bats'],
        conditions: [
          { key: 'WhenHurt', percent: 20, target: 'self' }
        ]
      },
      'ShadowStrike',
      'Bats',
      'Chaos',
      'PrimaryWeaponAttack'
    ]
  }),
  circle: {
    src: circle,
    width: 240,
    height: 150
  },
  head
}
