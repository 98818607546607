import { Chaos, ChaosConfig } from 'game/extended/creatures/undead/abilities/chaos/chaos'
import { AbilityUI } from 'game/extended/uis/abilities/AbilityUI'
import React from 'react'

import image from './chaos.png'

export const chaos_ui: AbilityUI<'Chaos'> = {
    model: Chaos,
    configs: [
        {
            available: 1,
            cooldown: 7,
            energy: 6,
            energy_loss: 4
        }
    ],
    image,
    label: 'Chaos',
    renderTargets: () => 'All enemies',
    renderDescription: () => <>
        The enemy supportline is forced into the front and vica versa.
        Additionally they are also interrupted and left with a loss of energy.
    </>,
    renderImpression: (ability: Chaos, UI) => <>
        <UI.Char character={ability.character} />{' '}sole presence terrifies his enemies causing
        chaotic positioning and drained energy.
    </>,
    renderTableStatic: (config: ChaosConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Loss:</UI.TKey>
            <UI.TVal><UI.Stat stat="energy" amount={config.energy_loss} /></UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (ability: Chaos, UI) => <>
        <UI.TRow>
            <UI.TKey>Loss:</UI.TKey>
            <UI.TVal><UI.Stat stat="energy" amount={ability.energyLoss} /></UI.TVal>
        </UI.TRow>
    </>,
}
