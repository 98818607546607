import {
    Character,
    CharDamaged,
    CharDied,
    FullHealing,
    FullInjury,
    Interrupt,
    Percentage,
    Skill,
    SkillConfig,
    SkillTriggered,
    Turns,
} from 'game/core'
import { EthernalFireEffect } from 'game/extended/heros/mage/ethernal_fire/ethernal_fire_effect'
import { Memoize } from 'game/util/memoize'

export interface EthernalFireConfig extends SkillConfig {
    intelligence: number
    duration: Turns
    health: number
}

export type EthernalFireState = {
    triggered?: boolean
}

export class EthernalFire extends Skill<EthernalFireState, EthernalFireConfig> {

    static KEY = 'EthernalFire' as const

    @Memoize()
    get isActive(): boolean {
        return !this.state.triggered
    }

    @Memoize()
    get intelligenceModifier(): number {
        return this.currentConfig.intelligence
    }

    @Memoize()
    get duration(): Turns {
        return this.currentConfig.duration
    }

    @Memoize()
    get healthRecovery(): Percentage {
        return this.currentConfig.health
    }

    @Memoize()
    get healing(): FullHealing {
        const healingAmount = Math.ceil(this.character.maxHp * this.healthRecovery / 100)
        return {
            origin: this.path,
            amount: healingAmount,
            modifiers: [],
            original_amount: healingAmount
        }
    }

    // ----- Interrupts ----- //
    interruptOnDeath(target: Character, injury: FullInjury): Interrupt {
        if (target !== this.character) return false
        if (!this.isActive) return false

        const newHp = this.character.hp - injury.amount
        return this.battle
            .addEvent(CharDamaged, { injury, old_hp: this.character.hp, hp: newHp, character: this.path, full_hp: this.character.stats.health })
            .addEvent(CharDied, { injury, character: this.path, name: this.character.name })
            .perform(Character, this.character.path, char => char.setHp(0))
            .perform(Character, this.character.path, char => char.setEnergy(char.maxEnergy))
            .startEvent(SkillTriggered, { skill: this.path })
            .perform(Character, this.character.path, char => char.heal(this.healing))
            .perform(Character, this.character.path, char => char.addEffect(EthernalFireEffect, {
                duration: this.duration,
                intelligence: this.intelligenceModifier,
                origin: this.path,
                skill: this.path
            }))
            .perform(EthernalFire, this.path, skill => (skill as EthernalFire).update({ triggered: true }))
            .endEvent()
    }
}
