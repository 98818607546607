import {
    AbilityPerformed,
    Event,
    Interrupt,
    PrimaryWeaponAttack,
    SecondaryWeaponAttack,
    Skill,
    SkillConfig,
    Turns,
} from 'game/core'
import { Memoize } from 'game/util/memoize'

import { PenetratingBlowsEffect } from './penetrating_blows_effect'

export interface PenetratingBlowsConfig extends SkillConfig {
    reduction: number,
    duration: number
}

export class PenetratingBlows extends Skill<{}, PenetratingBlowsConfig>{

    static KEY = 'PenetratingBlows' as const

    @Memoize()
    get reduction(): Turns {
        return this.currentConfig.reduction
    }

    @Memoize()
    get duration(): Turns {
        return this.currentConfig.duration
    }

    // ----- Reactions ----- //
    reactToEvent(event: Event): Interrupt {
        if (!(event instanceof AbilityPerformed)) return false
        if (!event.ability) return false
        if (!(
            event.ability instanceof PrimaryWeaponAttack ||
            event.ability instanceof SecondaryWeaponAttack
        )) return false
        if (event.ability.character !== this.target) return false
        if (!event.target) return false

        console.log('Nr of effects', event.target.effects.length)

        if (event.target.effects.find(effect => effect instanceof PenetratingBlowsEffect)) {
            return event.target.interrupt()
        }

        return event.target.addEffect(PenetratingBlowsEffect, {
            origin: this.path,
            reduction: this.reduction,
            duration: this.duration
        })
    }
}


