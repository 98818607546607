import { Boots, ItemRarity } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './fire_boots.png'

export const fire_boots: ItemUI<Boots> = {
    image,
    label: 'Fire boots',
    value: 60,
    item: {
        key: 'fire_boots',
        type: 'boots',
        rarity: ItemRarity.Epic,
        stats: {
            intelligence: 50,
            energy: 2
        }
    }
}
