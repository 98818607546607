import { Armor, ItemRarity } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './royal_guard_armor.png'

export const royal_guard_armor: ItemUI<Armor> = {
    image,
    label: 'Royal guard armor',
    value: 150,
    item: {
        key: 'royal_guard_armor',
        type: 'armor',
        rarity: ItemRarity.Epic,
        stats: {
            health: 50,
            intelligence: 50,
            agility: 50,
            strength: 50,
            armor: 20,
            shielding: 20,
            resistance: 20
        }
    }
}
