import React from 'react'
import { EventUI } from "game/extended/uis/events/EventUI"
import { EffectFinalized } from 'game/core'

export const effect_finalized_ui: EventUI<'EffectFinalized'> = {
    model: EffectFinalized,

    render: (event: EffectFinalized, UI) => {
        return <>
            {`The `}
            <UI.Effect effect={event.effectKey} />
            {` on `}
            <UI.Char character={event.character} />
            {` was finalized and is no longer active.`}
        </>
    }
}
