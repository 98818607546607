import 'views/layout/characterslot.scss'

import { TypeKey } from 'game/extended/types'
import { getTypeUI } from 'game/extended/uis/types/type_uis'
import { Nullable } from 'game/util/maybe'
import React from 'react'
import { styler } from 'views/layout/util/style'
import { Mod } from 'views/ui/ui_types'

const style = styler('characterslot')

export const CharacterSlot: React.FC<{
    mod?: Mod<'inactive' | 'faded'>
    type: Nullable<TypeKey>
}> = ({ mod, type, children }) => {

    if (!type) {
        return <div className={style().mod(mod)} ></div>
    }

    const ui = getTypeUI(type)
    if (!ui) return null

    const charStyle = {
        width: ui.circle.width + '%',
        height: ui.circle.height + '%',
        top: -(ui.circle.height - 100) / 2 + '%',
        left: -(ui.circle.width - 100) / 2 + '%',
    }

    return <div className={style().mod(mod)}>
        <div className={style('foreground')} style={charStyle}>
            <img src={ui.circle.src} alt={ui.label} />
        </div>
        {children}
    </div>
}
