import { HolyIntervention } from 'game/extended/heros/paladin/holy_intervention/holy_intervention'
import { holy_intervention_config } from 'game/extended/heros/paladin/holy_intervention/holy_intervention_config'
import { AbilityUI } from 'game/extended/uis/abilities/AbilityUI'
import React from 'react'

import image from './holy_intervention.png'

export const holy_intervention_ui: AbilityUI<'HolyIntervention'> = {
    model: HolyIntervention,
    configs: holy_intervention_config,
    image,
    label: 'Holy intervention',
    renderTargets: () => 'Self / Front',
    renderDescription: () => <>
        Call forth a holy intervention on the most damaged ally.
        The intervention fully heals this character and immediatly stuns all enemies
        planning to target this character on their next turn.
    </>,
    renderImpression: (ability: HolyIntervention, UI) => <>
        <UI.Char character={ability.character} />{' '}kneels down while a blinding holy light surrounds{' '}
        {ability.targets === ability.character ?
            'him' :
            <UI.Char character={ability.targets} />
        }.
    </>
}
