import { BattleState, CharacterPath } from 'game/core'

type SetupError = {
    battle: BattleState
}

type RoundError = {
    battle: BattleState
    completed: Array<CharacterPath>
    active?: CharacterPath
}

export type BattleErrorType =
    | { key: 'setup' } & SetupError
    | { key: 'round' } & RoundError


export class BattleError extends Error {

    constructor(readonly error: Error, readonly type: BattleErrorType) {
        super()
        console.log(type)
        console.log(error)
    }

    get round(): number {
        return this.type.battle.time
    }
}