import { Battle, RoundData } from 'game/core'
import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'
import { ReplayRound } from 'models/replay/ReplayRound'
import { ReplayTurn } from 'models/replay/ReplayTurn'

export type ReplayData = {
    start: Battle,
    finish: Battle,
    rounds: Array<RoundData>
}

export class ReplayModel {
    data: ReplayData

    static createFromBattle(initialBattle: Battle): ReplayModel {
        const rounds: Array<RoundData> = []
        let battle = initialBattle

        for (let i = 0; i <= 50 && !battle.isFinished; i++) {
            const roundData: RoundData = battle.performRound()
            rounds.push(roundData)
            battle = roundData.end
        }

        const finish = rounds[rounds.length - 1].end

        const state = {
            start: initialBattle,
            finish,
            rounds
        }

        return new ReplayModel(state)
    }

    constructor(data: ReplayData) {
        this.data = data
    }

    @Memoize()
    get result(): Battle {
        return this.data.finish
    }

    @Memoize()
    get isVictorious(): boolean {
        return this.data.finish.left.isVictorious
    }

    @Memoize()
    get rounds(): Array<ReplayRound> {
        return this.data.rounds.map(data => new ReplayRound(this, data))
    }

    @Memoize()
    get lastRound(): ReplayRound {
        return this.rounds[this.rounds.length - 1]
    }

    @Memoize()
    get lastTurn(): Nullable<ReplayTurn> {
        return this.lastRound?.lastTurn
    }

    getRoundByKey(key?: string): ReplayRound {
        if (!key) return this.rounds[0]
        const round = this.rounds.find(round => round.key === key)
        if (round) return round
        return this.rounds[0]
    }
}