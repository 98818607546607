import { RuleStarted } from 'game/core'
import { EventUI } from "game/extended/uis/events/EventUI"
import React from 'react'

export const rule_started_ui: EventUI<'RuleStarted'> = {
    model: RuleStarted,

    render: (event: RuleStarted, UI) => {
        return <>
            <UI.Char character={event.character} />
            {' will run '}
            <UI.Rule rule={event.rule} />
            {` because all conditions were met.`}
        </>
    }
}
