import { LinePosition } from 'game/core'
import { ConditionUI } from 'game/extended/uis/conditions/ConditionUI'
import React from 'react'
import { Conditionsetup, ConditionsetupSelect } from 'views/ui/conditionsetup'
import { VisualSubscript } from 'views/ui/visual'

import { ReactComponent as icon } from './when_positioned.svg'

const options: Record<LinePosition, string> = {
    front: 'Front',
    support: 'Support'
}

const symbols: Record<LinePosition, string> = {
    front: 'F',
    support: 'S'
}

export const when_positioned_ui: ConditionUI<'WhenPositioned'> = {
    icon,
    label: 'When positioned',
    defaultState: {
        key: 'WhenPositioned',
        position: 'front'
    },
    renderDescription: (state) =>
        <span>
            Cast this sequence when this units is positioned in the {state.position} line
        </span>,
    renderVisualIcon: (state) =>
        <VisualSubscript>
            {symbols[state.position]}
        </VisualSubscript>,
    renderOptions: (state, update) =>
        <Conditionsetup>
            <ConditionsetupSelect<LinePosition>
                value={state.position}
                options={options}
                onchange={value => update({ ...state, position: value })}
            />
        </Conditionsetup>
}
