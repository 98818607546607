import { getClassUI } from 'game/extended/uis/types/class_uis'
import { HeroState } from 'models/user/hero/HeroModel'

const level = 1
const ui = getClassUI('paladin')

export const sample_paladin: HeroState = {
    id: 'sample_paladin',
    type: 'paladin',
    name: 'Garen',
    level: level,
    equipment: {
        boots: 'blazewing_boots'
    },
    strategy: [
        {
            abilities: [
                'JudgementHammer'
            ],
            conditions: []
        }
    ],
    stats: {
        ...ui.definition.stats
    },
    abilities: [
        {
            key: 'JudgementHammer',
            level: 1
        }
    ],
    skills: [
        {
            key: 'Dueller',
            level: 1
        }
    ],
    skill_points: 0,
    achievements: [],
    rewards: []
}
