import { EffectUI } from 'game/extended/uis/effects/EffectUI'
import React from 'react'

import image from './magic_interrupt.png'
import { MagicInterruptEffect } from './magic_interrupt_effect'

export const magic_interrupt_effect_ui: EffectUI<'MagicInterruptEffect'> = {
    image,
    label: 'Magic barrier',
    model: MagicInterruptEffect,
    renderDescription: (effect: MagicInterruptEffect) => <>
        Only regular abilities (resting, regular attacks & moving) are allowed.
    </>,
    renderTable: (effect: MagicInterruptEffect, UI) => <></>
}