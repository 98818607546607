import { ItemRarity, Shield } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './steel_shield.png'

export const steel_shield: ItemUI<Shield> = {
  image,
  label: 'Steel shield',
  value: 80,
  item: {
    key: 'steel_shield',
    type: 'shield',
    rarity: ItemRarity.Common,
    stats: {
      armor: 55
    }
  }
}
