import { DamageType } from 'game/core'
import { Bite, BiteConfig } from 'game/extended/creatures/undead/abilities/bite/bite'
import { AbilityUI } from 'game/extended/uis/abilities/AbilityUI'
import React from 'react'

import image from './bite.png'

export const bite_ui: AbilityUI<'Bite'> = {
    model: Bite,
    configs: [
        {
            available: 1,
            cooldown: 2,
            energy: 4,
            damage: 10
        }
    ],
    image,
    label: 'Bite',
    renderTargets: () => 'Melee or Neighbour',
    renderDescription: () => <>
        Deal damage by biting your target and recover a percentage of the damage dealt.
        This percentage is equal to the health percentage the target is missing (the more
        wounded the target is, the greater the effect).
    </>,
    renderImpression: (ability: Bite, UI) => <>
        <UI.Char character={ability.character} />{' '}lashes out and bites{' '}<UI.Char character={ability.targets} />.
    </>,
    renderTableStatic: (config: BiteConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Damage:</UI.TKey>
            <UI.TVal><UI.Damage type={DamageType.Melee} damage={config.damage} /></UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (ability: Bite, UI) => <>
        <UI.TRow>
            <UI.TKey>Damage:</UI.TKey>
            <UI.TVal><UI.Damage type={DamageType.Melee} damage={ability.damage} /></UI.TVal>
        </UI.TRow>
    </>,
}
