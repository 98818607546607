import * as coreAbilities from 'game/core/abilities'
import * as coreEffects from 'game/core/effects'
import * as coreEvents from 'game/core/events'
import * as coreRequirements from 'game/core/requirements'
import * as baseConditions from 'game/extended/conditions'
import { creatures } from 'game/extended/creatures/creatures'
import { heros } from 'game/extended/heros/heros'
import * as items from 'game/extended/items'
import * as armors from 'game/extended/items/armors'
import * as boots from 'game/extended/items/boots'
import * as headgear from 'game/extended/items/headgear'
import * as shields from 'game/extended/items/shields'
import * as trinkets from 'game/extended/items/trinket'
import * as weapons from 'game/extended/items/weapons'

export { items, armors, boots, headgear, trinkets, shields, weapons }

type ValueOf<T> = T[keyof T]

export const abilities = {
    ...coreAbilities,
    ...heros.abilities,
    ...creatures.abilities,
}

export const skills = {
    ...heros.skills,
    ...creatures.skills
}

export const effects = {
    ...coreEffects,
    ...heros.effects,
    ...creatures.effects,
}

export const events = {
    ...coreEvents,
    ...heros.events,
    ...creatures.events
}

export const requirements = {
    ...coreRequirements,
    ...heros.requirements
}

export const conditions = {
    ...baseConditions
}

export type ClassKey = keyof typeof heros.types
export type CreatureKey = keyof typeof creatures.types
export type TypeKey = ClassKey | CreatureKey

export type ItemKey = keyof typeof items
export type WeaponKey = keyof typeof weapons
export type ArmorKey = keyof typeof armors
export type TrinketKey = keyof typeof trinkets
export type BootsKey = keyof typeof boots
export type HeadgearKey = keyof typeof headgear
export type ShieldKey = keyof typeof shields
export type Items = {
    [K in ItemKey]: (typeof items)[K]
}

export type AbilityKey = keyof typeof abilities
export type Abilities = {
    [K in AbilityKey]: InstanceType<(typeof abilities)[K]>
}
export type AbilityConfigs = {
    [K in AbilityKey]: InstanceType<(typeof abilities)[K]>['currentConfig']
}
export type SkillKey = keyof typeof skills
export type Skills = {
    [K in SkillKey]: InstanceType<(typeof skills)[K]>
}
export type SkillConfigs = {
    [K in SkillKey]: InstanceType<(typeof skills)[K]>['currentConfig']
}

export type ConditionKey = keyof typeof conditions
export type Conditions = {
    [K in ConditionKey]: InstanceType<(typeof conditions)[K]>
}
export type ConditionStates = {
    [K in ConditionKey]: InstanceType<(typeof conditions)[K]>['state'] & { key: K }
}
export type ConditionState = ValueOf<ConditionStates>

export type RequirementKey = keyof typeof requirements
export type Requirements = {
    [K in RequirementKey]: InstanceType<(typeof requirements)[K]>
}
export type RequirementStates = {
    [K in RequirementKey]: InstanceType<(typeof requirements)[K]>['state']
}

export type EventKey = keyof typeof events
export type Events = {
    [K in EventKey]: InstanceType<(typeof events)[K]>
}
export type EventStates = {
    [K in EventKey]: InstanceType<(typeof events)[K]>['state']
}

export type EffectKey = keyof typeof effects
export type Effects = {
    [K in EffectKey]: InstanceType<(typeof effects)[K]>
}
export type ModifierKey =
    | { type: 'effect', effect: EffectKey }
    | { type: 'skill', skill: SkillKey }