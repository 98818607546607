import { Environment } from 'game/core'
import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'
import { ChapterKey, ChapterModel, Difficulty, MissionKey } from 'models/campaign/ChapterModel'
import { MissionModel, MissionPath, MissionState } from 'models/campaign/MissionModel'
import { CampaignResultModel } from 'models/ranking/CampaignResultModel'
import { ReplayModel } from 'models/replay/ReplayModel'
import { UserModel, UserState } from 'models/user/UserModel'

export interface CampaignState {
  user: UserState
  chapter: ChapterKey
  difficulty: Difficulty
  missions: Partial<Record<MissionKey, MissionState>>
  last_replay?: MissionReplay //Should not be serialised or stored locally
}

export type MissionReplay = {
  path: MissionPath
  replay: ReplayModel
}

export class CampaignModel {

  constructor(readonly state: CampaignState, readonly env: Environment) { }

  @Memoize()
  get user(): UserModel {
    return new UserModel(this.state.user, {
      classes: this.chapter.config.classes,
      items: this.chapter.config.items,
      env: this.env
    })
  }

  @Memoize()
  get difficulty(): Difficulty {
    return this.state.difficulty
  }

  @Memoize()
  get isFinished(): boolean {
    return this.chapter.isFinished
  }

  @Memoize()
  get nextMission(): Nullable<MissionModel> {
    return this.chapter.missions.find(mission => mission.isOpen)
  }

  @Memoize()
  get lastReplay(): Nullable<{ mission: MissionModel, replay: ReplayModel }> {
    if (!this.state.last_replay) return null
    return {
      mission: MissionModel.byPath(this, this.state.last_replay.path) as MissionModel,
      replay: this.state.last_replay.replay
    }
  }

  @Memoize()
  get chapter(): ChapterModel {
    return new ChapterModel(this, this.state.chapter)
  }

  @Memoize()
  get result(): CampaignResultModel {
    return new CampaignResultModel({
      chapter: this.chapter.key,
      difficulty: this.difficulty,
      heroes: this.user.heros.map(hero => ({
        achievements: hero.achievements.length,
        level: hero.level,
        type: hero.type
      })),
      missions: this.chapter.missions
        .filter(mission => mission.isFinished)
        .map(mission => ({
          deaths: mission.deaths,
          speed: mission.speed,
          stars: mission.stars || 0
        })),
    })
  }

  // ----- Inquiry ----- //
  findMission(chapterKey: ChapterKey, missionKey: MissionKey): Nullable<MissionModel> {
    if (chapterKey !== this.chapter.key) return null
    const mission = this.chapter.missions.find(mission => mission.key === missionKey)
    return mission
  }

  // ----- Calculate new State ----- //
  update(newState: Partial<CampaignState>): CampaignModel {
    return new CampaignModel({
      ...this.state,
      ...newState
    }, this.env)
  }

  updateUser(user: UserModel): CampaignModel {
    return this.update({ user: user.state })
  }

  shuffleShop(): CampaignModel {
    return this.updateUser(this.user.shop.shuffle())
  }
}
