import goblins_references from 'game/extended/creatures/goblins/goblins_references'
import tutorial_references from 'game/extended/creatures/tutorial/tutorial_references'
import undead_references from 'game/extended/creatures/undead/undead_references'

export const creatures = {
    types: {
        ...goblins_references.types,
        ...undead_references.types,
        ...tutorial_references.types
    },
    abilities: {
        ...goblins_references.abilities,
        ...undead_references.abilities,
        ...tutorial_references.abilities
    },
    skills: {
        ...goblins_references.skills,
        ...undead_references.skills,
        ...tutorial_references.skills
    },
    events: {
        ...goblins_references.events,
        ...tutorial_references.events
    },
    effects: {
        ...goblins_references.effects,
        ...undead_references.effects,
        ...tutorial_references.effects
    }
}