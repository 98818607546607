import { WarcryConfig } from 'game/extended/heros/barbarian/warcry/warcry'

export const warcry_config: Array<WarcryConfig> = [
    {
        available: 2,
        cooldown: 7,
        energy: 4,
        damage_increase: 25,
        energy_increase: 4,
        duration: 2
    },
    {
        available: 1,
        cooldown: 7,
        energy: 4,
        damage_increase: 30,
        energy_increase: 5,
        duration: 3
    },
    {
        available: 0,
        cooldown: 6,
        energy: 5,
        damage_increase: 40,
        energy_increase: 6,
        duration: 3
    }
]