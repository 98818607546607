import { Requirement } from 'game/core'
import { Memoize } from 'game/util/memoize'
import { UnreachableCaseError } from 'game/util/never'

export class RequiredRoom extends Requirement<{
    pos: 'front' | 'support'
    include_self?: boolean
}> {

    static KEY = 'RequiredRoom' as const

    @Memoize()
    get isValid() {
        if (this.state.include_self) {
            switch (this.state.pos) {
                case 'front':
                    return this.character.side.front.characters.filter(ch => ch !== this.character).length < 4
                case 'support':
                    return this.character.side.support.characters.filter(ch => ch !== this.character).length < 4
                default:
                    throw new UnreachableCaseError(this.state.pos)
            }
        }

        switch (this.state.pos) {
            case 'front':
                return !this.character.side.front.isFull
            case 'support':
                return !this.character.side.support.isFull
            default:
                throw new UnreachableCaseError(this.state.pos)
        }
    }
}
