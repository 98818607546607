import { Condition } from 'game/core'
import { Memoize } from 'game/util/memoize'

export type WhenEnergyDirection = 'below' | 'exactly' | 'above'

export type WhenEnergyState = {
    direction: WhenEnergyDirection
    amount: number
}

export class WhenEnergy extends Condition<WhenEnergyState> {

    static KEY = 'WhenEnergy' as const

    @Memoize()
    get amount(): number {
        return this.state.amount
    }

    @Memoize()
    get isBlocking() {
        switch (this.state.direction) {
            case 'above':
                return this.character.energy < this.amount
            case 'exactly':
                return this.character.energy !== this.amount
            case 'below':
                return this.character.energy > this.amount
        }
        return true
    }
}
