import { AttackRange, DamageType, ItemRarity, Weapon } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './berserk_axe.png'

export const blood_axe: ItemUI<Weapon> = {
    image,
    label: 'Blood axe',
    value: 250,
    item: {
        key: 'berserk_axe',
        type: 'weapon',
        rarity: ItemRarity.Legendary,
        stats: {
            strength: 80,
            agility: 50,
            intelligence: 50,
            health: 20,
            energy: 5
        },
        two_handed: true,
        category: 'axe',
        damageType: DamageType.Melee,
        range: AttackRange.Melee,
        energy: 3,
        damage: 20
    }
}
