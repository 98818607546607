import 'views/layout/util/bg.scss'
import React from 'react'
import { ImageType } from 'views/ui/ui_types'
import { styler } from 'views/layout/util/style'

const style = styler('bg')

export const Bg: React.FC<{
    image: ImageType
} & Record<string, any>> = ({ image }) => {

    return <div
        className={style()}
        style={{
            backgroundImage: `url("${image}")`
        }}
    />
}
