import { AchievementModel } from 'models/user/hero/achievements/Achievement'
import { BattleHistory } from 'models/user/hero/achievements/BattleHistory'

export class AssistsAchievement extends AchievementModel {

    calculateProgress(history: BattleHistory) {
        if (!history.char || !history.victorious) return 0

        const killedSet = new Set(history.killed.map(ch => ch.character))
        return history.wounded.filter(char => !killedSet.has(char.character)).length
    }
}
