import { ImmolationConfig } from 'game/extended/heros/mage/immolation/immolation'

export const immolation_config: Array<ImmolationConfig> = [

    {
        available: 3,
        cooldown: 7,
        energy: 3,
        reverse_damage: 4,
        shield: 20
    },
    {
        available: 2,
        cooldown: 6,
        energy: 3,
        reverse_damage: 5,
        shield: 35
    },
    {
        available: 2,
        cooldown: 5,
        energy: 4,
        reverse_damage: 8,
        shield: 50
    }
]