import './label.scss'

import React from 'react'
import { styler } from 'views/layout/util/style'

const style = styler('label')

export const Label: React.FC = ({ children }) => {

  return <div className={style()}>
    {children}
  </div>
}