import { ConditionUI } from 'game/extended/uis/conditions/ConditionUI'
import React from 'react'

import { ReactComponent as icon } from './when_sequence_ready.svg'

export const when_sequence_ready_ui: ConditionUI<'WhenSequenceReady'> = {
  icon,
  label: 'Full sequence ready',
  defaultState: {
    key: 'WhenSequenceReady'
  },
  renderDescription: (state) =>
    <span>
      Cast this sequence only when the first ability is castable and all
      following abilities will be off cooldown when its their turn.
    </span>,
  renderVisualIcon: () => null,
  renderOptions: () => null
}
