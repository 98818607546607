import { Memoize } from 'game/util/memoize'
import { Requirement } from 'game/core'

export class RequiredCooldown extends Requirement {

    static KEY = 'RequiredCooldown' as const

    @Memoize()
    get isValid() {
        return this.ability.cooldownLeft === 0
    }
}
