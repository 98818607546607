import { RequirementUI } from "game/extended/uis/requirements/RequirementUI"
import React from 'react'
import { RequiredCooldown } from 'game/core'

export const required_cooldown_ui: RequirementUI<'RequiredCooldown'> = {
    model: RequiredCooldown,
    label: 'Required cooldown',
    renderDescription: () => <>
        This ability can not be casted again while still on cooldown
    </>
}
