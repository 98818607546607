import { AbilityPerformed, Battle, Effect, EffectTriggered, Event, FullDamage, Interrupt } from 'game/core'
import { BombToss } from 'game/extended/creatures/goblins/abilities/bomb_toss/bomb_toss'
import { ChargeBomb } from 'game/extended/creatures/goblins/abilities/charge_bomb/charge_bomb'
import { Memoize } from 'game/util/memoize'

export class ChargedBomb extends Effect<{
    damage: FullDamage,
    tossed: boolean
}> {
    static KEY = 'ChargedBomb' as const

    @Memoize()
    get duration() {
        return { type: 'turns' as const, turns: 1 }
    }

    @Memoize()
    get damage(): FullDamage {
        return this.state.damage
    }

    // ----- Reactions ----- //
    reactToEvent(event: Event): Interrupt {
        if (!(event instanceof AbilityPerformed)) return false
        if (event.ability instanceof ChargeBomb) return false
        if (event.character !== this.target) return false
        if (!(event.ability instanceof BombToss)) return false
        
        return this.update({ tossed: true })
            .perform(Effect, this.path, e => e.finalize())
    }

    reactToFinalize(): Battle {
        if(this.state.tossed) return this.battle

        return this.battle
            .startEvent(EffectTriggered, { effect: this.path })
            .perform(Effect, this.path, effect => (effect as ChargedBomb).explode())
            .endEvent()
    }

    // ----- Calculate new State ----- //
    explode(): Battle {
        return this.target.takeDamage(this.damage)
    }
}