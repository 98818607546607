import { Condition, ConditionPath } from 'game/core'
import { AbilityKey, ConditionState } from 'game/extended/types'
import { Nullable } from 'game/util/maybe'
import { TurnChar } from 'models/replay/TurnChar'
import React, { useState } from 'react'
import { Popuptab } from 'views/ingame/user/team/popuptab'
import { Sidebar } from 'views/layout/sidebar'
import { Strategy, StrategyItems } from 'views/layout/strategy/strategy'
import { CharAbilitiesSidebar } from 'views/replay/charpop/charabilities'
import { ConditionSidebar } from 'views/replay/sidebars/conditionsidebar'
import { AbilityVisual } from 'views/ui/visuals/abilityvisual'
import { ConditionVisual } from 'views/ui/visuals/conditionvisual'

type CharStrategySelection =
    { type: 'ability', ability: AbilityKey } |
    { type: 'condition', condition: ConditionPath }

export const CharStrategy: React.FC<{
    char: TurnChar
}> = ({ char }) => {
    const [selected, setSelected] = useState<Nullable<CharStrategySelection>>()

    return <Popuptab>
        <Sidebar hidden={!selected} onhide={() => setSelected(null)}>
            {selected && selected.type === 'ability' && <CharAbilitiesSidebar
                char={char}
                abilityKey={selected.ability}
            />}
            {selected && selected.type === 'condition' && <ConditionSidebar
                condition={Condition.byPath(char.char.battle, selected.condition)}
            />}
        </Sidebar>
        {char.char.type.rules.map(rule => {
            return <Strategy index={rule.index} key={rule.index}>
                <StrategyItems title="Abilities:">
                    {rule.abilities.map(ab => <AbilityVisual
                        ability={ab.key as AbilityKey}
                        key={ab.key}
                        onClick={() => {
                            const abilityKey = ab.key as AbilityKey
                            if (selected && selected.type === 'ability' && selected.ability === abilityKey) {
                                setSelected(null)
                            } else {
                                setSelected({ type: 'ability', ability: abilityKey })
                            }
                        }}
                    />)}
                </StrategyItems>
                <StrategyItems title="Conditions:">
                    {rule.conditions.map(cond => <ConditionVisual
                        condition={cond.state as ConditionState}
                        key={cond.key}
                        onClick={() => {
                            if (selected && selected.type === 'condition' && selected.condition === cond.path) {
                                setSelected(null)
                            } else {
                                setSelected({ type: 'condition', condition: cond.path })
                            }
                        }}
                    />)}
                </StrategyItems>
            </Strategy>
        })}
    </Popuptab>
}
