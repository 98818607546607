import { Explosive, ExplosiveConfig } from 'game/extended/creatures/tutorial/abilities/explosive/explosive'
import { SkillUI } from 'game/extended/uis/skills/SkillUI'
import React from 'react'

import image from './explosive.png'

export const explosive_ui: SkillUI<'Explosive'> = {
    model: Explosive,
    configs: [
        {}
    ],
    image,
    label: 'Explosive',
    renderDescription: (config: ExplosiveConfig) => <span>
        When this creature dies, it explodes and damages all surrounding allies.
    </span>,
    renderTableStatic: () => null,
    renderTable: () => null
}
