import { Bats, BatsConfig } from 'game/extended/creatures/undead/abilities/bats/bats'
import { AbilityUI } from 'game/extended/uis/abilities/AbilityUI'
import React from 'react'

import image from './bats.png'

export const bats_ui: AbilityUI<'Bats'> = {
    model: Bats,
    configs: [
        {
            available: 2,
            cooldown: 12,
            energy: 8
        }
    ],
    image,
    label: 'Bats',
    renderTargets: () => 'All enemies',
    renderDescription: () => <>
        Move to the support line and fill the frontline with bats to hold off enemies.
    </>,
    renderImpression: (ability: Bats, UI) => <>
        <UI.Char character={ability.character} />{' '}summons a horde of bats while
        dissapearing into the shadows
    </>,
    renderTableStatic: (config: BatsConfig, UI) => <>
    </>,
    renderTable: (ability: Bats, UI) => <>
    </>,
}
