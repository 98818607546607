import './staticon.scss'
import React from 'react'
import { Mod } from 'views/ui/ui_types'
import { styler } from 'views/layout/util/style'
import { Icon, IconKey } from 'views/ui/icon'

const style = styler('staticon')

export const StatIcon: React.FC<{
    className?: string,
    stat: IconKey
    mod?: Mod<'small' | 'unit'>
}> = ({ stat, mod, ...rest }) => {

    return <span className={style().mergeProps(rest).mod(mod).mod(stat)} >
        <span className={style('icon')} >
            <Icon icon={stat} />
        </span>
    </span>
}
