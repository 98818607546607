import { Percentage, Skill, SkillConfig, Stats } from 'game/core'
import { Memoize } from 'game/util/memoize'

export interface EnhancedArmorConfig extends SkillConfig {
  increase: Percentage
}

export class EnhancedArmor extends Skill<{}, EnhancedArmorConfig> {

  static KEY = 'EnhancedArmor' as const

  @Memoize()
  get stat(): 'armor' | 'resistance' | 'shielding' {
    const armor = this.character.baseStats.armor
    const resistance = this.character.baseStats.resistance
    const shielding = this.character.baseStats.shielding

    if (armor <= resistance && armor <= shielding) return 'armor'
    if (shielding <= resistance) return 'shielding'
    return 'resistance'
  }

  @Memoize()
  get increase(): Percentage {
    return this.currentConfig.increase
  }

  // ----- Appliers ----- //
  getTargetStats(currentStats: Stats): Stats {
    return {
      ...currentStats,
      [this.stat]: Math.round(currentStats[this.stat] * (100 + this.increase) / 100)
    }
  }
}
