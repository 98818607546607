import { Boots, ItemRarity } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './goblin_boots.png'

export const goblin_boots: ItemUI<Boots> = {
    image,
    label: 'Goblin boots',
    value: 25,
    item: {
        key: 'goblin_boots',
        type: 'boots',
        rarity: ItemRarity.Common,
        stats: {
            agility: 20,
            strength: 10,
            energy: 2
        }
    }
}
