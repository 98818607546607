import { Ability, AbilityConfig, Character, Turns } from 'game/core'
import { InspireEffect } from 'game/extended/creatures/goblins/abilities/inspire/inspire_effect'
import { Memoize } from 'game/util/memoize'

export type InspireConfig = AbilityConfig & {
    strength_increase: number
    energy_increase: number
    duration: Turns
}

export class Inspire extends Ability<InspireConfig>{

    static KEY = 'Inspire' as const
    static FIXED_REQUIREMENTS = []

    @Memoize()
    get targets(): Array<Character> {
        return this.character.side.characters
            .filter(char => !char.effects.find(effect => effect instanceof InspireEffect))
    }

    @Memoize()
    get strengthIncrease(): number {
        return this.currentConfig.strength_increase
    }

    @Memoize()
    get duration(): Turns {
        return this.currentConfig.duration
    }

    @Memoize()
    get energyIncrease(): number {
        return this.currentConfig.energy_increase
    }

    // ----- Calculate new State ----- //
    performActionImpl() {
        if (!this.targets) return this.battle

        return this.battle
            .performAll(Character, this.targets.map(ch => ch.path),
                char => char.addEffect(InspireEffect, {
                    strengthIncrease: this.strengthIncrease,
                    origin: this.path,
                    duration: this.duration
                })
            )
            .performAll(Character, this.targets.map(ch => ch.path),
                char => char.setEnergy(char.energy + this.energyIncrease)
            )
    }
}
