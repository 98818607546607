import 'views/layout/sidebar/requirements.scss'

import { Requirement, RequirementState } from 'game/core'
import { RequirementKey } from 'game/extended/types'
import { getRequirementUI } from 'game/extended/uis/requirements/requirement_uis'
import { DefaultUI as UI } from 'views/ui/default_ui'

import React from 'react'

export const Requirements: React.FC<{
    requirements: Array<RequirementState | Requirement>
}> = ({ requirements }) => {

    if (!requirements.length) return null

    return <div className="requirements">
        <UI.TKey>
            Requirements:
        </UI.TKey>
        <ul className="requirements-list">
            {requirements.map((req, i) =>
                <div className="requirements-list-item" key={i} >
                    <RequirementsItem
                        state={req instanceof Requirement ? req.state : req}
                        valid={req instanceof Requirement ? req.isValid : undefined}
                    />
                </div>
            )}
        </ul>
    </div>
}

export const RequirementsItem: React.FC<{
    state: RequirementState,
    valid?: boolean
}> = ({ state, valid }) => {
    const key = state.key as RequirementKey

    return <div className={"requirements_item" + (valid ? ' is-valid' : '')}>
        <div className="requirements_item-symbol">○</div>
        <div className="requirements_item-description">
            {getRequirementUI(key).renderDescription(state, UI)}
        </div>
        {valid !== undefined && <div className={'requirements_item-valid' + (valid ? ' is-valid' : ' is-invalid')}>
            {valid ? '✓' : '✖'}
        </div>}
    </div>
}
