import React from 'react'

export class ErrorBoundary extends React.Component<{}, { hasError: boolean }> {
    constructor(props: Readonly<{}>) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError(error: Error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true }
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <p>An error occured while rendering.</p>
        }

        return this.props.children || null
    }
}