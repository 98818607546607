import { Damage, Effect, EffectDuration, Turns } from 'game/core'
import { Memoize } from 'game/util/memoize'

export class UndyingFuryEffect extends Effect<{
    duration: Turns
}> {
    static KEY = 'UndyingFuryEffect' as const

    @Memoize()
    get duration(): EffectDuration {
        return {
            type: 'turns',
            turns: this.state.duration
        }
    }

    // ----- Appliers ----- //
    applyInjuryModifier(amount: number, damage: Damage): number {
        return amount ? 1 : 0
    }
}
