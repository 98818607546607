import { ItemRarity, Trinket } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './bomb.png'

export const bomb: ItemUI<Trinket> = {
    image,
    label: 'Goblin bomb',
    value: 30,
    item: {
        key: 'bomb',
        type: 'trinket',
        rarity: ItemRarity.Rare,
        stats: {
            health: -20,
            energy: 6
        }
    }
}
