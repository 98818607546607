import { ReincarnationConfig, Reincarnation } from 'game/extended/creatures/goblins/abilities/reincarnation/reincarnation'
import { SkillUI } from 'game/extended/uis/skills/SkillUI'
import React from 'react'
import image from './reincarnation.png'

export const reincarnation_ui: SkillUI<'Reincarnation'> = {
    model: Reincarnation,
    configs: [
        {
            cooldown: 10,
            health: 50,
        }
    ],
    image,
    label: 'Reincarnation',
    renderDescription: (config: ReincarnationConfig) => <span>
        When this character takes lethal damage, he will instantly be resurrected
        with a percentage of his hp intact and full energy.
    </span>,
    renderTableStatic: (config: ReincarnationConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Cooldown:</UI.TKey>
            <UI.TVal><UI.Turns turns={config.cooldown} /></UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Health:</UI.TKey>
            <UI.TVal><UI.Stat stat='health' amount={config.health} percentage /></UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (skill: Reincarnation, UI) => <>
        <UI.TRow>
            <UI.TKey>Cooldown:</UI.TKey>
            <UI.TVal><UI.Turns turns={skill.cooldown} /></UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Health:</UI.TKey>
            <UI.TVal><UI.Stat stat='health' amount={skill.health} percentage /></UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Ready in:</UI.TKey>
            <UI.TVal>
                {skill.availableIn === 0 ? 'ready' : <UI.Turns turns={skill.availableIn} />}
            </UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Hp recovery:</UI.TKey>
            <UI.TVal>{skill.currentConfig.health}%</UI.TVal>
        </UI.TRow>
    </>
}
