import { ShieldBlock, ShieldBlockConfig } from 'game/extended/heros/paladin/shield_block/shield_block'
import { SkillUI } from 'game/extended/uis/skills/SkillUI'
import React from 'react'

import image from './shield_block.png'

export const shield_block_ui: SkillUI<'ShieldBlock'> = {
    model: ShieldBlock,
    configs: [
        {
            frequency: 5
        },
        {
            frequency: 4
        },
        {
            frequency: 3
        }
    ],
    image,
    label: 'Shield block',
    renderDescription: (config: ShieldBlockConfig) => <span>
        After a fixed number of incoming, enemy attacks, the next one is averted.
    </span>,
    renderTableStatic: (config: ShieldBlockConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Frequency:</UI.TKey>
            <UI.TVal>{config.frequency}</UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (skill: ShieldBlock, UI) => <>
        <UI.TRow>
            <UI.TKey>Frequency:</UI.TKey>
            <UI.TVal>{skill.frequency}</UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Next block in:</UI.TKey>
            <UI.TVal>{skill.target.shield ? 'Shield required' : skill.attacksUntilNextBlock}</UI.TVal>
        </UI.TRow>
    </>
}
