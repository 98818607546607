import { Memoize } from 'game/util/memoize'
import { EndlessModel } from 'models/endless/EndlessModel'
import { HeroModel } from 'models/user/hero/HeroModel'
import { LevelModel, LevelStatus } from 'models/user/LevelModel'
import { UserModel } from 'models/user/UserModel'

export class EndlessPrevLevelModel implements LevelModel {

  constructor(readonly endless: EndlessModel) { }

  @Memoize()
  get battle_id(): string {
    return 'battle_' + this.number
  }

  @Memoize()
  get history() {
    const result = this.endless.state.last_history
    if (!result) throw new Error('Only construct this model when a history is present')
    return result
  }

  @Memoize()
  get allies() {
    return {
      front: this.history.lineup.front.map(id => this.user.getHeroById(id) as HeroModel),
      support: this.history.lineup.support.map(id => this.user.getHeroById(id) as HeroModel)
    }
  }

  @Memoize()
  get enemies() {
    return {
      front: this.history.level.front,
      support: this.history.level.support
    }
  }

  @Memoize()
  get number(): number {
    return this.endless.levelNumber - 1
  }

  @Memoize()
  get attempts(): number {
    return 0
  }

  @Memoize()
  get rounds(): number {
    return this.history.rounds
  }

  @Memoize()
  get user(): UserModel {
    return this.endless.user
  }

  @Memoize()
  get status(): LevelStatus {
    return {
      key: 'finished',
      attempts: this.attempts,
      rounds: this.rounds,
      reward: 999,
      loot: [],
      stars: 3
    }
  }
}
