import { DamageType } from 'game/core'
import { BombToss, BombTossConfig } from 'game/extended/creatures/goblins/abilities/bomb_toss/bomb_toss'
import { AbilityUI } from 'game/extended/uis/abilities/AbilityUI'
import React from 'react'

import image from './bomb_toss.png'

export const bomb_toss_ui: AbilityUI<'BombToss'> = {
    model: BombToss,
    configs: [
        {
            available: 0,
            cooldown: 1,
            damage: 22,
            energy: 5,
            splash: 50
        }
    ],
    image,
    label: 'Bomb toss',
    renderTargets: () => 'Front + Surrounding',
    renderDescription: () => <>
        Toss an explosive bomb towards an enemy. The explosion impacts everyone around the target.
    </>,
    renderImpression: (ability: BombToss, UI) => <>
        With a wide grin the goblin tosses his bombs into the air. A fraction later
        an explosion occurs impacting{' '}<UI.Char character={ability.allTargets} />.
    </>,
    renderTableStatic: (config: BombTossConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Damage:</UI.TKey>
            <UI.TVal><UI.Damage damage={config.damage} type={DamageType.Magic} /></UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Splash:</UI.TKey>
            <UI.TVal>{config.splash}%</UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (ability: BombToss, UI) => <>
        <UI.TRow>
            <UI.TKey>Damage:</UI.TKey>
            <UI.TVal><UI.Damage damage={ability.damage} /></UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Splash:</UI.TKey>
            <UI.TVal><UI.Damage damage={ability.splashDamage} /></UI.TVal>
        </UI.TRow>
    </>
}
