import { HeroModel } from 'models/user/hero/HeroModel'
import React from 'react'
import { useParams } from 'react-router'
import { useHinter } from 'views/ingame/campaign/tutorial'
import { Strategytab } from 'views/ingame/user/team/abilities/strategytab'
import { Talentstab } from 'views/ingame/user/team/abilities/talentstab'
import { AchievementsTab } from 'views/ingame/user/team/achievements/achievementstab'
import { CharacterInfo } from 'views/ingame/user/team/characterinfo'
import { EquipTab } from 'views/ingame/user/team/equip/equiptab'
import { useUser } from 'views/ingame/user/usercontext'
import { Popup, PopupContent, PopupHeader, PopupLeft, PopupRight, PopupStat, PopupTab, PopupTabs } from 'views/layout/popup'
import { Redirect, useLinker } from 'views/link'

export const Teampopup: React.FC = () => {
    const { tab, char } = useParams<{ tab: string, char: string }>()
    const { user } = useUser()
    const { goTo } = useLinker()

    const hint = useHinter()
    const hero = user.heros.find(hero => hero.id === char)
    if (!hero) return null

    return <Popup onClose={() => goTo('')}>
        <PopupHeader>
            <PopupTabs>
                <PopupTab
                    label="Equipment"
                    active={tab === 'items'}
                    badges={hero.suggestedEquipmentSlots.length}
                    to={`/team/${hero.id}/items`}
                    hinted={hint.key === 'teampopup' && hint.tab === 'equipment'}
                />
                <PopupTab
                    label="Achievements"
                    active={tab === 'achievements'}
                    to={`/team/${hero.id}/achievements`}
                    hinted={hint.key === 'teampopup' && hint.tab === 'achievements'}
                />
                <PopupTab
                    label="Abilities"
                    active={tab === 'abilities'}
                    badges={hero.skillPoints}
                    to={`/team/${hero.id}/abilities`}
                    hinted={hint.key === 'teampopup' && hint.tab === 'abilities'}
                />
                <PopupTab
                    label="Strategy"
                    active={tab === 'strategy'}
                    badges={hero.unusedAbilities.length}
                    to={`/team/${hero.id}/strategy`}
                    hinted={hint.key === 'teampopup' && hint.tab === 'strategy'}
                />
            </PopupTabs>
            <PopupStat amount={hero.skillPoints} stat="xp" />
        </PopupHeader>
        <PopupContent>
            <PopupLeft>
                <CharacterInfo
                    type={hero.type}
                    name={hero.name}
                    level={hero.level}
                    stats={hero.fullStats}
                    onPrevious={user.heros.length > 1 && (() => {
                        goTo(`/team/${hero.previous.id}/${tab}`)
                    })}
                    onNext={user.heros.length > 1 && (() => {
                        goTo(`/team/${hero.next.id}/${tab}`)
                    })}
                />
            </PopupLeft>
            <PopupRight>
                <TeampopupTab hero={hero} tab={tab as string} />
            </PopupRight>
        </PopupContent>
    </Popup>
}

export const TeampopupTab: React.FC<{
    hero: HeroModel
    tab: string
}> = ({ hero, tab }) => {

    switch (tab) {
        case 'items':
            return <EquipTab hero={hero} />
        case 'achievements':
            return <AchievementsTab hero={hero} />
        case 'abilities':
            return <Talentstab hero={hero} />
        case 'strategy':
            return <Strategytab hero={hero} />
    }

    return <Redirect to={`/team/${hero.id}/items`} />
}
