import { StaffMastery, StaffMasteryConfig } from 'game/extended/heros/mage/staff_mastery/staff_mastery'
import image from 'game/extended/heros/mage/staff_mastery/staff_mastery.png'
import { SkillUI } from 'game/extended/uis/skills/SkillUI'
import React from 'react'

export const staff_mastery_ui: SkillUI<'StaffMastery'> = {
    model: StaffMastery,
    configs: [
        {
            modifier: 40
        },
        {
            modifier: 65
        },
        {
            modifier: 100
        }
    ],
    image,
    label: 'Staff Mastery',
    renderDescription: (config: StaffMasteryConfig) => <>
        All stats and the damage of your staff are increased.
    </>,
    renderTableStatic: (config: StaffMasteryConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Increase:</UI.TKey>
            <UI.TVal>{config.modifier}%</UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (skill: StaffMastery, UI) => <>
        <UI.TRow>
            <UI.TKey>Increase:</UI.TKey>
            <UI.TVal>{skill.modifier}%</UI.TVal>
        </UI.TRow>
    </>
}
