import full from 'game/extended/creatures/goblins/goblin_swordman/goblin_swordman.svg'
import circle from 'game/extended/creatures/goblins/goblin_swordman/goblin_swordman_circle.svg'
import head from 'game/extended/creatures/goblins/goblin_swordman/goblin_swordman_head.svg'
import { CreatureUI } from 'game/extended/uis/types/creature_uis'

export const goblin_swordman: CreatureUI = {
    label: 'Goblin swordman',
    definition: lvl => ({
        stats: {
            health: 60 + lvl * 15,
            energy: 12 + lvl,
            intelligence: 18 + lvl * 2,
            strength: 35 + lvl * 10,
            agility: 25 + lvl * 5,
            armor: 40,
            shielding: 40,
            resistance: 15
        },
        equipment: {
            primary: 'goblin_sword',
            secondary: 'goblin_shield',
            headgear: 'goblin_helmet',
            boots: 'goblin_boots'
        },
        abilities: [],
        skills: ['ShieldBlock'],
        strategy: [
            'MoveFront',
            'PrimaryWeaponAttack'
        ]
    }),
    full,
    circle: {
        src: circle,
        width: 200,
        height: 150
    },
    head
}
