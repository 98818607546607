import { BurningWeapon, BurningWeaponConfig } from 'game/extended/heros/mage/burning_weapon/burning_weapon'
import { burning_weapon_config } from 'game/extended/heros/mage/burning_weapon/burning_weapon_config'
import { AbilityUI } from 'game/extended/uis/abilities/AbilityUI'
import React from 'react'

import image from './burning_weapon.png'

export const burning_weapon_ui: AbilityUI<'BurningWeapon'> = {
    model: BurningWeapon,
    configs: burning_weapon_config,
    image,
    label: 'Burning Weapon',
    renderTargets: () => 'Yourself',
    renderDescription: () => <>
        Your weapon attacks will deal increased, magic damage.
    </>,
    renderImpression: (ability: BurningWeapon, UI) => <>
        <UI.Char character={ability.character} />{' '}ignites {ability.character.isMale ? 'his' : 'her'} weapon in
        soothing flames.
    </>,
    renderTableStatic: (config: BurningWeaponConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Damage increase:</UI.TKey>
            <UI.TVal>
                {config.increase}%
            </UI.TVal>
        </UI.TRow>
        <UI.TRow>
            <UI.TKey>Duration:</UI.TKey>
            <UI.TVal>
                <UI.Turns turns={config.duration} />
            </UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (ability: BurningWeapon, UI) => <>
        <UI.TRow>
            <UI.TKey>Damage increase:</UI.TKey>
            <UI.TVal>
                {ability.increase}%
            </UI.TVal>
            <UI.TVal>
                <UI.Turns turns={ability.duration} />
            </UI.TVal>
        </UI.TRow>
    </>
}
