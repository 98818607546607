import { Effect, Stats, Turns } from 'game/core'
import { Memoize } from 'game/util/memoize'

export class InspireEffect extends Effect<{
    strengthIncrease: number,
    duration: Turns
}> {
    static KEY = 'InspireEffect' as const

    @Memoize()
    get duration() {
        return {
            type: 'turns',
            turns: this.state.duration
        } as const
    }

    @Memoize()
    get strengthIncrease() {
        return this.state.strengthIncrease
    }

    // ----- Appliers ----- //
    getTargetStats(stats: Stats): Stats {
        return {
            ...stats,
            strength: stats.strength + this.strengthIncrease
        }
    }
}