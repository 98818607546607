import { getClassUI } from 'game/extended/uis/types/class_uis'
import { HeroState } from 'models/user/hero/HeroModel'

const level = 20
const ui = getClassUI('mage')

export const sample_mage: HeroState = {
    id: 'sample_mage',
    type: 'mage',
    name: 'Red beard',
    level: level,
    equipment: {
        primary: 'lion_sword',
        armor: 'royal_guard_armor'
    },
    strategy: [
        {
            abilities: ['Punch'],
            conditions: []
        }
    ],
    stats: {
        ...ui.definition.stats,
        health: 500,
        intelligence: 300,
        agility: 500
    },
    abilities: [
        { key: 'Immolation', level: 1 },
    ],
    skills: [
        { key: 'Prepared', level: 3 }
    ],
    skill_points: level,
    achievements: [],
    rewards: []
}
