import { Ability, AbilityConfig, Character, DamageNature, FullDamage, RequiredWeaponState, Requirement } from 'game/core'
import { Memoize } from 'game/util/memoize'

export type VolleyConfig = AbilityConfig

export class Volley extends Ability<VolleyConfig> {

    static KEY = 'Volley' as const
    static FIXED_REQUIREMENTS = [
        Requirement.init(RequiredWeaponState, { type: 'ranged' })
    ]

    @Memoize()
    get targets(): Array<Character> {
        return this.character.side.enemySide.front.characters
    }

    @Memoize()
    get damage(): FullDamage {
        if (!this.character.rangedWeapon) return this.nullDamage
        return this.calculateDamage(
            this.character.rangedWeapon.damageType,
            DamageNature.Ranged,
            this.character.rangedWeapon.damage
        )
    }

    // ----- State change inquiry ----- //
    performActionImpl() {
        return this.performOnAllTargets(this.applyToCharacter)
    }

    private applyToCharacter = (char: Character) => {
        return char.takeDamage(this.damage)
    }
}
