import { EffectStatChanged } from 'game/core'
import { EventUI } from 'game/extended/uis/events/EventUI'
import React from 'react'

export const effect_stat_changed_ui: EventUI<'EffectStatChanged'> = {
  model: EffectStatChanged,

  render: (event: EffectStatChanged, UI) => {
    return <>
      <UI.Effect effect={event.effect} />
      {event.amountChanged >= 0 ?
        ` gains ${event.amountChanged} ` :
        ` loses ${-event.amountChanged} `}
      <UI.Stat stat={event.stat} /> and is now at{' '}
      <UI.Stat stat={event.stat} amount={event.state.new_amount} />
    </>
  }
}
