import { MissionConfig } from 'models/campaign/MissionModel'

const asMissionsObjects = <T>(et: { [K in keyof T]: MissionConfig }) => et

export const undead_missions = asMissionsObjects({
    undead_mission_1: {
        front: [
            'bat'
        ]
    },
    undead_mission_2: {
        front: [
            'skeleton'
        ]
    },
    undead_mission_3: {
        front: [
            'skeleton',
            'bat'
        ]
    },
    undead_mission_4: {
        front: [
            'skeleton'
        ],
        support: [
            'skeleton_archer'
        ]
    },
    undead_mission_5: {
        front: [
            'vampiress'
        ]
    },
    undead_mission_6: {
        front: [
            'vampire'
        ]
    },
    undead_mission_7: {
        front: [
            'skeleton',
            'bat'
        ],
        support: [
            'bat',
            'skeleton_archer'
        ]
    },
    undead_mission_8: {
        front: [
            'skeleton',
            'skeleton'
        ],
        support: [
            'vampiress'
        ]
    },
    undead_mission_9: {
        front: [
            'vampiress'
        ],
        support: [
            'skeleton_wizard'
        ]
    },
    undead_mission_10: {
        front: [
            'vampiress',
            'bat',
            'vampire',
        ],
        support: [
            'skeleton_archer'
        ]
    },
    undead_mission_11: {
        front: [
            'skeleton',
            'vampire',
            'vampiress'
        ],
        support: [
            'skeleton_wizard'
        ]
    },
    undead_mission_12: {
        front: [
            'skeleton_king',
        ]
    },
    undead_mission_13: {
        front: [
            'skeleton',
            'skeleton',
            'skeleton'
        ],
        support: [
            'vampiress',
            'skeleton_wizard',
            'skeleton_archer'
        ]
    },
    undead_mission_14: {
        front: [
            'skeleton',
            'skeleton',
            'vampire'
        ],
        support: [
            'vampiress',
            'skeleton_archer',
            'skeleton_wizard'
        ]
    },
    undead_mission_15: {
        front: [
            'vampiress',
            'vampiress',
            'vampiress'
        ],
        support: [
            'skeleton_wizard',
            'skeleton_archer',
            'skeleton_archer'
        ]
    },
    undead_mission_16: {
        front: [
            'vampire',
            'vampire',
            'vampiress'
        ],
        support: [
            'skeleton_wizard',
            'skeleton_archer',
            'skeleton_wizard'
        ]
    },
    undead_mission_17: {
        front: [
            'vampire',
            'vampire',
            'vampire'
        ],
        support: [
            'skeleton_wizard',
            'skeleton_wizard',
            'skeleton_wizard'
        ]
    },
    undead_mission_18: {
        front: [
            'skeleton_king',
            'skeleton',
            'vampire'
        ],
        support: [
            'skeleton_archer',
            'vampiress',
            'skeleton_wizard'
        ]
    }
})
