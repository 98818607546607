import { AbilityKey } from 'game/extended/types'
import { getAbilityUI } from 'game/extended/uis/abilities/ability_uis'
import { Nullable } from 'game/util/maybe'
import { Sidebar, SidebarContent, SidebarTitle } from 'views/layout/sidebar'
import { AbilitySide } from 'views/layout/sidebar/abilityside'
import { TurnChar } from 'models/replay/TurnChar'
import React, { useState } from 'react'
import { AbilityVisual } from 'views/ui/visuals/abilityvisual'
import { Popuptab, PopuptabItems } from 'views/ingame/user/team/popuptab'

export const CharAbilities: React.FC<{
    char: TurnChar
}> = ({ char }) => {
    const [selected, setSelected] = useState<Nullable<AbilityKey>>(null)

    return <Popuptab>
        <Sidebar hidden={!selected} onhide={() => setSelected(null)}>
            {selected && <CharAbilitiesSidebar
                char={char}
                abilityKey={selected}
            />}
        </Sidebar>
        <PopuptabItems>
            {char.char.type.abilities.map(ability => {
                const key = ability.key as AbilityKey
                return <AbilityVisual
                    ability={key}
                    key={key}
                    onClick={() => {
                        setSelected(key)
                    }}
                />
            })}
        </PopuptabItems>
    </Popuptab>
}

export const CharAbilitiesSidebar: React.FC<{
    char: TurnChar
    abilityKey: AbilityKey
}> = ({ char, abilityKey }) => {

    const ability = char.char.type.abilities.find(ab =>
        ab.key === abilityKey
    )
    if (!ability) return null

    const ui = getAbilityUI(abilityKey)

    return <>
        <SidebarTitle>
            {ui.label}
        </SidebarTitle>
        <SidebarContent>
            <AbilitySide
                ability={ability}
                env={ability.env}
                level={ability.level}
                hideLevels={true}
            />
        </SidebarContent>
    </>
}
