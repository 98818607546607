import { Boots, ItemRarity } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './druid_boots.png'

export const druid_boots: ItemUI<Boots> = {
    image,
    label: 'Druid boots',
    value: 60,
    item: {
        key: 'druid_boots',
        type: 'boots',
        rarity: ItemRarity.Rare,
        stats: {
            agility: 40,
            intelligence: 20,
            energy: 4
        }
    }
}
