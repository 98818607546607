import { AttackRange, DamageType, ItemRarity, Weapon } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './hermit_staff.png'

export const hermit_staff: ItemUI<Weapon> = {
    image,
    label: 'Hermit staff',
    value: 100,
    item: {
        key: 'hermit_staff',
        type: 'weapon',
        rarity: ItemRarity.Epic,
        stats: {
            intelligence: 50,
            health: 40
        },
        two_handed: false,
        category: 'staff',
        damageType: DamageType.Melee,
        range: AttackRange.Melee,
        energy: 3,
        damage: 10
    }
}
