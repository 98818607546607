import { DamageType } from 'game/core'
import { TrueShots, TrueShotsConfig } from 'game/extended/heros/ranger/true_shots/true_shots'
import { true_shots_config } from 'game/extended/heros/ranger/true_shots/true_shots_config'
import { SkillUI } from 'game/extended/uis/skills/SkillUI'
import React from 'react'

import image from './true_shots.png'

export const true_shots_ui: SkillUI<'TrueShots'> = {
    model: TrueShots,
    configs: true_shots_config,
    image,
    label: 'True shots',
    renderDescription: (config: TrueShotsConfig) => <span>
        Gain a fixed damage increase whenever you deal ranged damage.
    </span>,
    renderTableStatic: (config: TrueShotsConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Increase:</UI.TKey>
            <UI.TVal><UI.Damage type={DamageType.Ranged} damage={config.damage} /></UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (ability: TrueShots, UI) => <>
        <UI.TRow>
            <UI.TKey>Increase:</UI.TKey>
            <UI.TVal><UI.Damage type={DamageType.Ranged} damage={ability.bonusDamage} /></UI.TVal>
        </UI.TRow>
    </>
}
