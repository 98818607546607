import { Condition } from 'game/core'
import { ConditionKey, ConditionState } from 'game/extended/types'
import { getConditionUI } from 'game/extended/uis/conditions/condition_uis'
import { Nullable } from 'game/util/maybe'
import React from 'react'
import { InfoCols, InfoVisual, InfoDescription } from 'views/layout/info'
import { SidebarContent, SidebarTitle } from 'views/layout/sidebar'
import { ConditionVisual } from 'views/ui/visuals/conditionvisual'

export const ConditionSidebar: React.FC<{
    condition: Nullable<Condition>
}> = ({ condition }) => {
    if (!condition) return null

    const key = condition.key as ConditionKey
    const ui = getConditionUI(key)
    if (!ui) return null

    return <>
        <SidebarTitle>{ui.label}</SidebarTitle>
        <SidebarContent>
            <InfoCols>
                <InfoVisual>
                    <ConditionVisual condition={condition.state as ConditionState} />
                </InfoVisual>
                <InfoDescription>
                    {ui.renderDescription(condition.state)}
                </InfoDescription>
            </InfoCols>
        </SidebarContent>
    </>
}