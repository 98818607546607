import {
    Ability,
    AbilityPath,
    AbilityPicked,
    Armor,
    Battle,
    Boots,
    calculateFullDamage,
    calculateHealing,
    calculateInjury,
    CharDamaged,
    CharDied,
    CharEnergyChanged,
    ChargingProcess,
    CharHealed,
    ChildGameModel,
    CoreTypeKey,
    DamageNature,
    DamageType,
    Effect,
    EffectAdded,
    EffectConstructor,
    EffectState,
    FullDamage,
    FullHealing,
    FullInjury,
    GameModel,
    GeneratedEffectStateKeys,
    getInterrupt,
    Headgear,
    Healing,
    Line,
    ModelID,
    Modifier,
    Rule,
    RulePath,
    Shield,
    Side,
    SidePath,
    Skill,
    SkillPath,
    Stats,
    Stunned,
    Trinket,
    TurnEnded,
    TurnSkipped,
    Type,
    TypeState,
    Weapon,
    Time,
    calculateFullDamageOnTarget,
    CharInterrupted,
    AttackRange, Item
} from 'game/core'
import { Interrupt } from 'game/core/modifier'
import { keysof } from 'game/util/keysof'
import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'
import objectMap from 'game/util/objectMap'

export type ChargingState = {
    ability: AbilityPath,
    rule: RulePath,
    index: number
}

export interface Equipment {
    primary?: Weapon
    secondary?: Weapon | Shield
    armor?: Armor
    headgear?: Headgear
    boots?: Boots
    trinket?: Trinket
}

export type EquipmentKey = keyof Equipment

export interface CharacterPath extends SidePath {
    name: string
    character: ModelID
    type: CoreTypeKey
    level: number
}

export interface CharacterInit {
    gender?: 'male' | 'female'
    equipment?: Equipment
    owner?: CharacterPath
    pos?: number
}

export interface CharacterState {
    id: ModelID
    gender: 'male' | 'female'
    name: string
    hp: number | 'full'
    energy: number | 'full'
    next_turn: Time
    equipment?: Equipment
    last_charge?: ChargingState
    type: TypeState
    effects: Record<string, EffectState>
    owner?: CharacterPath
}

export class Character<S extends CharacterState = CharacterState> extends ChildGameModel<Line, S> {

    static byPath(battle: Battle, path: CharacterPath): Nullable<Character> {
        return Side.byPath(battle, path).characters.find(ch => ch.id === path.character)
    }

    get line(): Line {
        return this.parent
    }

    @Memoize()
    get path(): CharacterPath {
        return {
            ...this.line.path,
            character: this.id,
            name: this.name,
            type: this.type.key,
            level: this.type.level
        }
    }

    @Memoize()
    get directChildren(): Array<GameModel> {
        return [this.type, ...this.effects]
    }

    @Memoize()
    get alliedEffects(): Array<Effect> {
        return this.effects.filter(effect => effect.originSide === this.side)
    }

    @Memoize()
    get enemyEffects(): Array<Effect> {
        return this.effects.filter(effect => effect.originSide !== this.side)
    }

    @Memoize()
    get id(): ModelID {
        return this.state.id
    }

    @Memoize()
    get battle(): Battle {
        return this.side.battle
    }

    @Memoize()
    get name(): string {
        return this.state.name
    }

    @Memoize()
    get lastCharge(): Nullable<ChargingState> {
        return this.state.last_charge
    }

    @Memoize()
    get nextTurn(): Time {
        return this.state.next_turn
    }

    @Memoize()
    get isMale(): boolean {
        return this.state.gender === 'male'
    }

    @Memoize()
    get isFemale(): boolean {
        return !this.isMale
    }

    @Memoize()
    get equipment(): Equipment {
        const baseEquipment = this.state.equipment || {}
        let result: Equipment = {}
        keysof(baseEquipment).forEach(key => {
            const baseItem = baseEquipment[key]
            if (!baseItem) return
            const item = this.applyEquipmentModifiers(baseItem)
            result = { ...result, [key]: item }
        })
        return result
    }

    @Memoize()
    get primaryWeapon(): Nullable<Weapon> {
        return this.equipment.primary
    }

    @Memoize()
    get secondaryWeapon(): Nullable<Weapon> {
        if (!this.equipment.secondary) return null
        if (this.equipment.secondary.type === 'shield') return null
        return this.equipment.secondary
    }

    @Memoize()
    get shield(): Nullable<Shield> {
        if (!this.equipment.secondary) return null
        if (this.equipment.secondary.type !== 'shield') return null
        return this.equipment.secondary
    }

    @Memoize()
    get meleeWeapon(): Nullable<Weapon> {
        if (this.primaryWeapon?.range === AttackRange.Melee) return this.primaryWeapon
        if (this.primaryWeapon?.range === AttackRange.Mixed) return this.primaryWeapon
        if (this.secondaryWeapon?.range === AttackRange.Melee) return this.secondaryWeapon
        if (this.secondaryWeapon?.range === AttackRange.Mixed) return this.secondaryWeapon
        return null
    }

    @Memoize()
    get rangedWeapon(): Nullable<Weapon> {
        if (this.primaryWeapon?.range === AttackRange.Ranged) return this.primaryWeapon
        if (this.primaryWeapon?.range === AttackRange.Mixed) return this.primaryWeapon
        if (this.secondaryWeapon?.range === AttackRange.Ranged) return this.secondaryWeapon
        if (this.secondaryWeapon?.range === AttackRange.Mixed) return this.secondaryWeapon
        return null
    }

    @Memoize()
    get side(): Side {
        return this.line.side
    }

    @Memoize()
    get allAllies(): Array<Character> {
        return this.side.characters
    }

    @Memoize()
    get allEnemies(): Array<Character> {
        return this.side.enemySide.characters
    }

    @Memoize()
    get index(): number {
        return this.line.characters.findIndex(ch => ch === this)
    }

    @Memoize()
    get pos() {
        return this.index - this.line.characters.length / 2 + 0.5
    }

    @Memoize()
    get hp(): number {
        if (this.state.hp === 'full') return this.maxHp
        if (this.state.hp > this.maxHp) return this.maxHp
        return this.state.hp
    }

    @Memoize()
    get hpRatio(): number {
        return this.hp / this.maxHp
    }

    @Memoize()
    get maxHp(): number {
        return this.stats.health
    }

    @Memoize()
    get energy(): number {
        if (this.state.energy === 'full') return this.maxEnergy
        return this.state.energy
    }

    @Memoize()
    get maxEnergy(): number {
        return this.stats.energy
    }

    @Memoize()
    get type(): Type {
        return new Type(this, this.state.type)
    }

    @Memoize()
    get modifiers(): Array<Modifier> {
        return [...this.type.skills, ...this.effects]
    }

    @Memoize()
    get effects(): Array<Effect<any>> {
        return objectMap(this.state.effects, e => this.env.createModel(this, e))
    }

    @Memoize()
    get chargingProcess(): ChargingProcess {
        if (!this.type.rules.length) {
            return {
                current_sequence_skipped: [],
                current_sequence_continued: false,
                rules_skipped: [],
                abilities_skipped: []
            }
        }

        if (this.lastCharge) {
            const rule = Rule.byPath(this.battle, this.lastCharge.rule) as Rule
            return rule.chargeActiveRule(this.lastCharge.index + 1, {
                current_sequence_skipped: [],
                current_sequence_continued: true,
                abilities_skipped: [],
                rules_skipped: []
            })
        }

        return this.type.rules[0].charge({
            current_sequence_skipped: [],
            current_sequence_continued: false,
            abilities_skipped: [],
            rules_skipped: []
        })
    }

    @Memoize()
    get chargingAbility(): Nullable<Ability> {
        const process = this.chargingProcess
        if (!process.charging) return null
        return Ability.byPath(this.battle, process.charging.ability)
    }

    @Memoize()
    get owner(): Nullable<Character> {
        if (!this.state.owner) return null
        return Character.byPath(this.battle, this.state.owner)
    }

    @Memoize()
    get summons(): Array<Character> {
        return this.side.characters.filter(ch => ch.owner === this)
    }

    @Memoize()
    get neighbours(): Array<Character> {
        return this.line.getNeighbours(this.index)
    }

    @Memoize()
    get surroundings(): Array<Character> {
        let result = this.line.getNeighbours(this.index)
        if (this.frontAlly) result = [...result, this.frontAlly]
        if (this.behindAlly) result = [...result, this.behindAlly]
        return result
    }

    @Memoize()
    get meleeTarget(): Nullable<Character> {
        return this.line.getMeleeTarget(this.pos)
    }

    @Memoize()
    get rangedTarget(): Nullable<Character> {
        return this.line.getRangedTarget(this.pos)
    }

    @Memoize()
    get frontTarget(): Nullable<Character> {
        return this.line.getFrontTarget(this.pos)
    }

    @Memoize()
    get alliesArround(): Array<Character> {
        const result: Array<Character> = []
        if (this.behindAlly) result.push(this.behindAlly)
        if (this.frontAlly) result.push(this.frontAlly)
        this.neighbours.forEach(char => result.push(char))
        return result
    }

    @Memoize()
    get frontAlly(): Nullable<Character> {
        if (this.line.position === 'front') return null
        return this.line.getFrontAlly(this.pos)
    }

    @Memoize()
    get behindAlly(): Nullable<Character> {
        if (this.line.position === 'support') return null
        return this.line.getBehindAlly(this.pos)
    }

    @Memoize()
    get supportTarget(): Nullable<Character> {
        return this.line.getSupportTarget(this.pos)
    }

    @Memoize()
    get baseStats(): Stats {
        const result = { ...this.type.baseStats }
        keysof(this.equipment).forEach(key => {
            const item = this.equipment[key]
            if (!item) return
            keysof(item.stats).forEach(stat => {
                result[stat] = result[stat] + (item.stats[stat] || 0)
            })
        })
        return result
    }

    @Memoize()
    get stats(): Stats {
        return this.modifiers.reduce((v, e) => e.getTargetStats(v), this.baseStats)
    }

    @Memoize()
    get canMoveBack(): boolean {
        return this.line.position !== 'support' && !this.side.support.isFull
    }

    @Memoize()
    get isStunned(): boolean {
        return !!this.turnBlocker
    }

    @Memoize()
    get turnBlocker(): Nullable<Effect> {
        return this.effects.find(effect => effect instanceof Stunned)
    }

    findEffect<E extends Effect>(constructor: { new(...args: any): E }): Nullable<E> {
        return this.effects.find(effect => effect.constructor === constructor) as Nullable<E>
    }

    getIsSummonOf(char: Character): boolean {
        return !!char.summons.find(summon => summon === this)
    }

    getIsTargeting(char: Character): boolean {
        if (!this.chargingAbility) return false
        return !!this.chargingAbility.targetsArray.find(target => target === char)
    }

    calculateDamage(origin: Ability | Skill, type: DamageType, nature: DamageNature, amount: number): FullDamage {
        return calculateFullDamage(this, { origin: origin.path, type, nature, amount, original_amount: amount }, this.stats)
    }

    calculateInjury(damage: FullDamage): FullInjury {
        return calculateInjury(this, damage)
    }

    calculateHealing(healing: Healing): FullHealing {
        return calculateHealing(this, healing)
    }

    applyEquipmentModifiers(item: Item): Item {
        return this.modifiers.reduce((item, effect) => effect.applyItemModifier(item), item)
    }

    // ----- Calculate new State ----- //
    update(newState: Partial<CharacterState>): Battle {
        return this.line.updateCharacter({
            ...this.state,
            ...newState
        })
    }

    heal(healing: FullHealing) {
        if (this.hp === this.maxHp) return this.battle

        const newHp = Math.min(this.hp + healing.amount, this.maxHp)
        return this.battle
            .addEvent(CharHealed, { healing, hp: newHp, character: this.path, full_hp: this.stats.health })
            .perform(Character, this.path, char => {
                const interrupt: Interrupt = getInterrupt(char.battle.modifiers, e => e.interruptOnHeal(char, healing))
                if (interrupt !== false) return interrupt
                return char.setHp(newHp)
            })
    }

    instantKill(origin: AbilityPath | SkillPath): Battle {
        const injury: FullInjury = {
            modifiers: [],
            amount: this.hp,
            damage: {
                origin,
                original_amount: this.hp,
                amount: this.hp,
                modifiers: [],
                nature: DamageNature.Instant, //TODO
                type: DamageType.Ranged //TODO
            }
        }
        return this.battle
            .addEvent(CharDied, { injury, character: this.path, name: this.name })
            .perform(Character, this.path, (char: Character) => char.line.removeCharacter(char))
    }

    takeDamage(originalDamage: FullDamage, allowInterrupt = true): Battle {
        const damage = calculateFullDamageOnTarget(originalDamage, this)

        const injury = this.calculateInjury(damage)

        if (allowInterrupt) {
            const interrupt = getInterrupt(this.battle.modifiers, e => e.interruptOnDamage(this, damage, injury))
            if (interrupt !== false) return interrupt
        }

        const newHp = this.hp - injury.amount
        if (newHp <= 0) {
            const interrupt = getInterrupt(this.modifiers, e => e.interruptOnDeath(this, injury))
            if (interrupt !== false) return interrupt

            return this.battle
                .addEvent(CharDamaged, { injury, old_hp: this.hp, hp: newHp, character: this.path, full_hp: this.stats.health })
                .addEvent(CharDied, { injury, character: this.path, name: this.name })
                .perform(Character, this.path, (char: Character) => char.line.removeCharacter(char))
        }

        return this.battle
            .addEvent(CharDamaged, { injury, old_hp: this.hp, hp: newHp, character: this.path, full_hp: this.stats.health })
            .perform(Character, this.path, char => char.setHp(newHp))
    }

    updateType(state: TypeState): Battle {
        return this.line.updateCharacter({
            ...this.state,
            type: state
        })
    }

    interrupt() {
        if (!this.chargingAbility) return this.battle
        return this.battle
            .startEvent(CharInterrupted, { character: this.path, charging: this.chargingProcess.charging as ChargingState })
            .perform(Ability, this.chargingAbility.path, ability => ability.interrupt())
            .endEvent()
    }

    stun(duration: number, origin: CharacterPath) {
        return this.addEffect(Stunned, { origin, duration })
    }

    addEffect<E extends Effect>(constructor: EffectConstructor<E>, state: Omit<E['state'], GeneratedEffectStateKeys>): Battle {
        const id = 'E' + this.battle.counter

        if (!constructor.STACKS) {
            const existingEvent = this.effects.find(effect => effect.constructor === constructor)
            if (existingEvent) return existingEvent.refresh(state)
        }

        return this
            .updateEffect({
                key: constructor.KEY,
                id: id,
                time: this.time,
                turnsPassed: 0,
                ...state
            })
            .upCounter()
            .perform(Effect, { ...this.path, effect: { key: constructor.KEY, id } }, (effect: Effect) =>
                effect.battle.addEvent(EffectAdded, { effect: effect.path })
            )
    }

    updateEffect<S>(state: S & EffectState): Battle {
        return this.line.updateCharacter({
            ...this.state,
            effects: {
                ...this.state.effects,
                [state.id]: state
            }
        })
    }

    removeEffect(state: EffectState): Battle {
        const newEffects: Record<ModelID, EffectState> = {}
        this.effects
            .filter(effect => effect.id !== state.id)
            .forEach(effect => newEffects[effect.id] = effect.state)

        return this.line.updateCharacter({
            ...this.state,
            effects: newEffects
        })
    }

    setCharging(charging: ChargingState | null): Battle {
        return this.line.updateCharacter({
            ...this.state,
            charging
        })
    }

    performTurn(): Battle {
        const process = this.chargingProcess
        const charging = process.charging

        let battle = this.battle
        if (this.turnBlocker) {
            battle = battle
                .addEvent(TurnSkipped, { character: this.path, blocker: this.turnBlocker.path })
        } else {
            battle = battle
                .addEvent(AbilityPicked, { character: this.path, process, last_charge: this.state.last_charge })
                .perform(Ability, charging?.ability, ability => ability.execute())
                .perform(Character, this.path, ch => charging ? ch.update({ last_charge: charging }) : ch.battle)
        }

        return battle
            .addEvent(TurnEnded, { character: this.path })
            .perform(Character, this.path, char => char.update({ next_turn: this.time + 1 }))
            .performAll(Effect, this.effects.map(e => e.path), effect => effect.increaseTurn())
    }

    switchLine(): Battle {
        return this.line.position === 'front' ? this.moveToSupport() : this.moveToFront()
    }

    moveToFront(pos = this.pos): Battle {
        if (this.line.position === 'front') return this.battle
        return this.battle
            .perform(Line, this.line.path, (line: Line) => line.removeCharacter(this))
            .perform(Line, this.side.front.path, (line: Line) => line.moveCharacter(this.state, pos || this.pos))
    }

    moveToSupport(pos = this.pos): Battle {
        if (this.line.position === 'support') return this.battle
        if (this.side.support.isFull) return this.battle
        return this.battle
            .perform(Line, this.line.path, (line: Line) => line.removeCharacter(this))
            .perform(Line, this.side.support.path, (line: Line) => {
                return line.moveCharacter(this.state, pos || this.pos)
            })
    }

    switchPosition<S>(target: Character): Battle {
        if (this.side !== target.side) return this.battle

        let result = this.battle
            .perform(Character, this.path, (char: Character) => char.line.removeCharacter(char))
            .perform(Character, target.path, (char: Character) => char.line.removeCharacter(char))

        if (target.line.position === 'support' && this.line.position === 'front') {
            return result
                .perform(Line, this.line.path, (line: Line) => line.moveCharacter(target.state, this.pos))
                .perform(Line, target.line.path, (line: Line) => line.moveCharacter(this.state, target.pos))
        }

        return result
            .perform(Line, target.line.path, (line: Line) => line.moveCharacter(this.state, target.pos))
            .perform(Line, this.line.path, (line: Line) => line.moveCharacter(target.state, this.pos))
    }

    setHp(newHp: number) {
        return this.line.updateCharacter({
            ...this.state,
            hp: newHp
        })
    }

    changeEnergy(increase: number): Battle {
        const newEnergy = this.energy + increase
        if (newEnergy > this.maxEnergy) return this.setEnergy(this.maxEnergy)
        if (newEnergy < 0) return this.setEnergy(0)
        return this.setEnergy(newEnergy)
    }

    setEnergy(energy: number): Battle {
        const currentEnergy = this.energy
        const newEnergy = Math.max(0, energy)
        if (currentEnergy === newEnergy) return this.battle

        return this
            .update({ energy: newEnergy })
            .addEvent(CharEnergyChanged, {
                character: this.path,
                previous_energy: currentEnergy,
                new_energy: newEnergy
            })
    }
}
