import { AttackRange, DamageType, ItemRarity, Weapon } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './shaman_wand.png'

export const shaman_wand: ItemUI<Weapon> = {
    image,
    label: 'Shaman wand',
    value: 70,
    item: {
        key: 'shaman_wand',
        type: 'weapon',
        rarity: ItemRarity.Rare,
        stats: {
            intelligence: 15,
            resistance: 10
        },
        two_handed: true,
        category: 'wand',
        damageType: DamageType.Magic,
        range: AttackRange.Mixed,
        energy: 2,
        damage: 7
    }
}
