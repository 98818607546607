import { RoundData, Time } from 'game/core/battle'
import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'
import { ReplayCharTurn } from 'models/replay/ReplayCharTurn'
import { ReplayModel } from 'models/replay/ReplayModel'
import { ReplaySetupTurn } from 'models/replay/ReplaySetupTurn'
import { ReplayTurn } from 'models/replay/ReplayTurn'

export class ReplayRound {
    replay: ReplayModel
    data: RoundData

    constructor(replay: ReplayModel, data: RoundData) {
        this.replay = replay
        this.data = data
    }

    @Memoize()
    get index() {
        return this.replay.rounds.indexOf(this)
    }

    @Memoize()
    get nextRound(): Nullable<ReplayRound> {
        return this.replay.rounds[this.index + 1]
    }

    @Memoize()
    get previousRound(): Nullable<ReplayRound> {
        return this.replay.rounds[this.index - 1]
    }

    @Memoize()
    get lastTurn(): Nullable<ReplayTurn> {
        if (this.turns.length === 0) return this.setup
        return this.turns[this.turns.length - 1]
    }

    @Memoize()
    get isFirstRound(): boolean {
        return this.index === 0
    }

    @Memoize()
    get isLastRound(): boolean {
        return !this.nextRound
    }

    @Memoize()
    get time(): Time {
        return this.start.time
    }

    @Memoize()
    get start() {
        return this.data.start
    }

    @Memoize()
    get end() {
        return this.data.end
    }

    @Memoize()
    get setup() {
        return new ReplaySetupTurn(this, this.data.setup)
    }

    @Memoize()
    get turns(): Array<ReplayCharTurn> {
        return this.data.turns.map(data => new ReplayCharTurn(this, data))
    }

    @Memoize()
    get key(): string {
        return `R${this.index}`
    }

    getTurnByKey(key?: string): ReplayTurn {
        if (!key) return this.setup
        if (key === this.setup.key) return this.setup
        const turn = this.turns.find(turn => turn.key === key)
        if (turn) return turn
        return this.setup
    }
}
