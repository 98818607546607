import { ClassKey } from 'game/extended/types'
import { Nullable } from 'game/util/maybe'
import { Memoize } from 'game/util/memoize'
import { HeroModel } from 'models/user/hero/HeroModel'
import { LineupModel } from 'models/user/LineupModel'
import { HeroID, UserModel } from 'models/user/UserModel'

export type LineupSlotState = {
    position: LineupSlotPosition
    hero: Nullable<HeroID>
}

export type LineupSlotPosition = 'front' | 'support'

export type LineupSlotKey = string

export class LineupSlot {

    constructor(readonly lineup: LineupModel, readonly state: LineupSlotState) { }

    @Memoize()
    get user(): UserModel {
        return this.lineup.user
    }

    @Memoize()
    get hero(): Nullable<HeroModel> {
        return this.user.heros.find(hero => hero.id === this.state.hero)
    }

    @Memoize()
    get position(): LineupSlotPosition {
        return this.state.position
    }

    @Memoize()
    get type(): Nullable<ClassKey> {
        return this.hero?.type
    }

    @Memoize()
    get id(): string {
        return `slot_${this.index}`
    }

    @Memoize()
    get index(): number {
        return this.lineup.slots.indexOf(this)
    }

    @Memoize()
    get key(): LineupSlotKey {
        return `${this.position}_${this.index}`
    }

    @Memoize()
    canSelectHero(hero: HeroModel) {
        return hero === this.hero || !hero.isSelectedInStrategy
    }

    // ----- Calculate new State ----- //
    update(newState: Partial<LineupSlotState>): UserModel {
        return this.lineup.update({
            slots: this.lineup.slots.map(slot =>
                slot === this ? { ...this.state, ...newState } : slot.state
            )
        })
    }

    updateHero(hero: Nullable<HeroModel>): UserModel {
        if (hero?.isSelectedInStrategy) return this.user
        return this.update({ hero: hero?.id })
    }
}
