import { AttackRange, DamageType, ItemRarity, Weapon } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './scout_bow.png'

export const scout_bow: ItemUI<Weapon> = {
    image,
    label: 'Scout bow',
    value: 25,
    item: {
        key: 'scout_bow',
        type: 'weapon',
        rarity: ItemRarity.Common,
        stats: {
            agility: 10
        },
        two_handed: true,
        category: 'bow',
        damageType: DamageType.Ranged,
        range: AttackRange.Ranged,
        energy: 2,
        damage: 6
    }
}
