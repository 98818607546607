import { Ability, Effect, EffectDuration, Turns } from 'game/core'
import { Memoize } from 'game/util/memoize'

export type ArcaneCurseEffectState = {
    max_energy: number
    duration: Turns
}

export class ArcaneCurseEffect extends Effect<ArcaneCurseEffectState>{

    static KEY = 'ArcaneCurseEffect' as const

    @Memoize()
    get duration(): EffectDuration {
        return {
            type: 'turns',
            turns: this.state.duration
        }
    }

    @Memoize()
    get maxEnergy(): number {
        return this.state.max_energy
    }

    // ----- Reactions ----- //
    canPerform(ability: Ability): boolean {
        return ability.energy <= this.maxEnergy
    }
}