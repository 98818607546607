import { EffectKey } from 'game/extended/types';
import { getEffectUI } from 'game/extended/uis/effects/effect_uis';
import React from 'react';
import { Icon } from 'views/ui/icon';
import { DOMAttrs, Mod } from 'views/ui/ui_types';
import { Visual, VisualAttrs, VisualIcon, VisualImage } from 'views/ui/visual';

export const EffectVisual: React.FC<{
    effect: EffectKey
    mod?: Mod<'highlight'>
    filled?: number,
    locked?: boolean
} & VisualAttrs & DOMAttrs> = attrs => {

    const {effect, mod, locked, filled, children, ...rest} = attrs

    if(!effect) return <Visual {...rest} />
    const ui = getEffectUI(effect)

    return <Visual {...rest}>
        <VisualImage image={ui.image} />
        {children}
        {locked && <VisualIcon>
            <Icon icon="forbidden" />
        </VisualIcon>}
    </Visual>
}
