import { AttackRange, DamageType, ItemRarity, Weapon } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './fire_adept_staff.png'

export const fire_adept_staff: ItemUI<Weapon> = {
    image,
    label: 'Fire adept staff',
    value: 85,
    item: {
        key: 'fire_adept_staff',
        type: 'weapon',
        rarity: ItemRarity.Rare,
        stats: {
            intelligence: 40,
            health: 20,
            resistance: 20
        },
        two_handed: true,
        category: 'staff',
        damageType: DamageType.Melee,
        range: AttackRange.Melee,
        energy: 3,
        damage: 11
    }
}
