import { Condition, LinePosition } from 'game/core'
import { Memoize } from 'game/util/memoize'

export type WhenPositionedState = {
    position: LinePosition
}

export class WhenPositioned extends Condition<WhenPositionedState> {

    public static KEY = 'WhenPositioned' as const

    @Memoize()
    get position(): LinePosition {
        return this.state.position
    }

    @Memoize()
    get isBlocking() {
        return this.character.line.position !== this.position
    }
}
