import { abilities, AbilityKey } from 'game/extended/types'
import { getAbilityUI } from 'game/extended/uis/abilities/ability_uis'
import { keysof } from 'game/util/keysof'
import React from 'react'
import { useParams } from 'react-router'
import { LibraryAbility, LibraryLink, LibraryList, LibraryUI } from 'views/library/library'
import { AbilityVisual } from 'views/ui/visuals/abilityvisual'

export const Abilities: React.FC = () => {
  const { type, tab } = useParams<{ type: AbilityKey, tab: string }>()

  const ui = type && getAbilityUI(type)
  const level = tab ? parseInt(tab) : false

  return <LibraryUI category="abilities">
    <LibraryList flex={0.25}>
      {keysof(abilities).map(key => {
        const ui = getAbilityUI(key)
        return <LibraryLink
          to={`/library/abilities/${key}/0`}
          active={type === key}
          key={key}
        >
          <AbilityVisual ability={key} />
          {ui.label}
        </LibraryLink>
      })}
    </LibraryList>
    {ui && <LibraryList flex={0.15}>
      {ui.configs.map((conf, index) => (
        <LibraryLink
          to={`/library/abilities/${type}/${index}`}
          active={level === index}
          key={index}
        >
          Level {index + 1}
        </LibraryLink>
      ))}
    </LibraryList>}
    {level !== false && <LibraryList flex={0.6}>
      <LibraryAbility ability={type} level={level + 1} />
    </LibraryList>}
  </LibraryUI>
}