import './endlessui.scss'

import React from 'react'
import { useEndless } from 'views/ingame/endless/Endless'
import { Stage } from 'views/ingame/endless/level/stage'
import { Speeddial, SpeeddialAction } from 'views/layout/components/speeddial'
import { Header, HeaderGroup, HeaderHome, HeaderStat } from 'views/layout/header'
import { Bg } from 'views/layout/util/bg'
import { styler } from 'views/layout/util/style'
import { Icon } from 'views/ui/icon'
import { CharacterVisual } from 'views/ui/visuals/charactervisual'

import bgSrc from 'data/campaign/lava/laval_background.png'
import { useAds } from 'ads/ads'

const style = styler('endlessui')

export const EndlessUI: React.FC = ({ children }) => {
    const { endless, user } = useEndless()
    const { timeLeft } = useAds()

    return (
        <div className={style()}>
            <Bg image={bgSrc} />
            <div className={style('stage')}>
                <Stage />
            </div>
            <Header>
                <HeaderGroup>
                    <HeaderHome />
                </HeaderGroup>
                <HeaderGroup>
                    <HeaderStat icon="health" value={timeLeft ? endless.lifes : endless.lifes - 4} />
                    <HeaderStat icon="trophy" value={endless.levelsFinished} />
                    <HeaderStat icon="currency" value={user.money} />
                </HeaderGroup>
            </Header>
            <div className={style('ui')}>
                <div className={style('ui-units')}>
                    <Speeddial
                        icon="hero"
                        badges={user.suggestions}
                    >
                        {user.heros.map(hero => (
                            <SpeeddialAction
                                to={`/team/${hero.id}/achievements`}
                                key={hero.id}
                                badges={hero.suggestions}
                            >
                                <CharacterVisual type={hero.type} />
                            </SpeeddialAction>
                        ))}
                        {!user.academy.isEmpty && <SpeeddialAction to={`/academy`} mod="round">
                            <Icon icon="recruit" />
                        </SpeeddialAction>}
                    </Speeddial>
                </div>
                <div className={style('ui-market')}>
                    <Speeddial
                        mod="mirrored"
                        icon="options"
                    >
                        {endless.lastReplay && <SpeeddialAction to={`/replay`} mod="round">
                            <Icon icon="replay" />
                        </SpeeddialAction>}
                        <SpeeddialAction to={`/shop`} mod="round">
                            <Icon icon="shop" />
                        </SpeeddialAction>
                        <SpeeddialAction to={`/surrender`} mod="round">
                            <Icon icon="surrender" />
                        </SpeeddialAction>
                    </Speeddial>
                </div>
            </div>
            <div className={style('children')}>
                {children}
            </div>
        </div>
    )
}
