import { ItemRarity, Shield } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './wooden_shield.png'

export const wooden_shield: ItemUI<Shield> = {
    image,
    label: 'Wooden shield',
    value: 10,
    item: {
        key: 'wooden_shield',
        type: 'shield',
        rarity: ItemRarity.Common,
        stats: {
            armor: 8,
            shielding: 8,
            resistance: 8
        }
    }
}
