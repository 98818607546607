import { CreatureUI } from 'game/extended/uis/types/creature_uis'

import circle from './skeleton_circle.png'
import head from './skeleton_head.png'

export const skeleton: CreatureUI = {
  label: 'Skeleton',
  definition: lvl => ({
    stats: {
      health: 70 + lvl * 10,
      energy: 18 + lvl * 2,
      intelligence: 15 + 5 * lvl,
      strength: 35 + lvl * 5,
      agility: 50 + lvl * 10,
      armor: 23,
      shielding: 80,
      resistance: 15
    },
    equipment: {
      primary: 'skeleton_sword',
      trinket: 'skeleton_gloves'
    },
    skills: [
      'Undead'
    ],
    strategy: [
      'MoveFront',
      'PrimaryWeaponAttack'
    ]
  }),
  circle: {
    src: circle,
    width: 200,
    height: 150
  },
  head
}
