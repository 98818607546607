import { CreatureUI } from 'game/extended/uis/types/creature_uis'

import circle from './skeleton_archer_circle.png'
import head from './skeleton_archer_head.png'

export const skeleton_archer: CreatureUI = {
  label: 'Skeleton archer',
  definition: lvl => ({
    stats: {
      health: 70,
      energy: 20,
      intelligence: 20 + 10 * lvl,
      strength: 30,
      agility: 90 + lvl * 10,
      armor: 20,
      shielding: 120,
      resistance: 30
    },
    equipment:
    {
      primary: 'skeleton_bow',
      headgear: 'red_bandana',
      trinket: 'skeleton_gloves'
    },
    skills: [
      'Undead'
    ],
    strategy: [
      {
        abilities: 'MoveSupport',
        conditions: [{
          key: 'WhenCrowd',
          line: 'allied_front',
          direction: 'above',
          amount: 1
        }, {
          key: 'WhenCrowd',
          line: 'enemy_support',
          direction: 'exactly',
          amount: 0
        }]
      },
      'PrimaryWeaponAttack'
    ]
  }),
  circle: {
    src: circle,
    width: 200,
    height: 150
  },
  head
}
