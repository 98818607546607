import { Shell, ShellConfig } from 'game/extended/creatures/tutorial/abilities/shell/shell'
import { SkillUI } from 'game/extended/uis/skills/SkillUI'
import React from 'react'

import image from './shell.png'

export const shell_ui: SkillUI<'Shell'> = {
    model: Shell,
    configs: [
        {
            threshold: 10
        }
    ],
    image,
    label: 'Shell',
    renderDescription: (config: ShellConfig) => <span>
        When this creature receives damage, less than a given threshold, the damage is discarded.
    </span>,
    renderTableStatic: (config: ShellConfig, UI) => <>
        <UI.TRow>
            <UI.TKey>Threshold:</UI.TKey>
            <UI.TVal>{config.threshold}</UI.TVal>
        </UI.TRow>
    </>,
    renderTable: (skill: Shell, UI) => <>
        <UI.TRow>
            <UI.TKey>Threshold:</UI.TKey>
            <UI.TVal>{skill.threshold}</UI.TVal>
        </UI.TRow>
    </>
}
