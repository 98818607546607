import { Headgear, ItemRarity } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './skull_helmet.png'

export const lone_wolf_helmet: ItemUI<Headgear> = {
    image,
    label: 'Lone wolf helmet',
    value: 80,
    item: {
        key: 'lone_wolf_helmet',
        type: 'headgear',
        rarity: ItemRarity.Rare,
        stats: {
            agility: 50,
            strength: 30,
            health: 30
        }
    }
}
