import { ItemRarity, Shield } from 'game/core'
import { ItemUI } from 'game/extended/uis/item_uis'

import image from './epic_shield.png'

export const epic_shield: ItemUI<Shield> = {
    image,
    label: 'Epic shield',
    value: 300,
    item: {
        key: 'epic_shield',
        type: 'shield',
        rarity: ItemRarity.Rare,
        stats: {
            strength: 50,
            armor: 60,
            shielding: 60,
            resistance: 60,
            energy: 5
        }
    }
}
